import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';


export default StyleSheet.create({
    stepCont: {
        flex: 1,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    lblCont: {
        flexDirection:'row',
        alignItems:'flex-start',
        marginBottom: 10,
    },
    lblSubStep:{
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    lblLink: {
        color: Color.TEXT_COLOR_BLUE
    }
});


