import { Typography,Spin, Tabs } from 'antd';
import React from 'react';
// import { router } from 'umi';
import { formatMessage } from "components/src/utils";
// import { PlusOutlined } from '@ant-design/icons';
// import { Mutation } from 'react-apollo';
// import { ADMIN_CREATE_SUPER_USER } from '../../../../../../mutation/AdminMutation';
// import { USER_TYPE } from '../../../../utils/constant';

import './addofficeceo.scss';
import AddOfficesDetails from './Components/officesdetails';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ServiecesToBuy from './Components/serviestobuy';
// import { from } from 'apollo-link';
import{useHistory} from "react-router";

const { Title } = Typography;
const { TabPane } = Tabs;

// const onFinish = (values, createSuperUser) => {
//   console.log('Success:', values);
//   // TODO: add the data from the success form response below
//   createSuperUser({
//     variables: {
//       user_profile: values.profilepicture.fileList[0].originFileObj,
//       first_name: values.firstname,
//       last_name: values.lastname,
//       email: values.email,
//       mobile: values.phone,
//       title: values.title,
//       user_type: USER_TYPE.ADMIN,
//       access_level: 'CEO',
//     },
//   });
// };

// const onFinishFailed = errorInfo => {
//   console.log('Failed:', errorInfo);
// };
// const uploadButton = (
//   <div>
//     <PlusOutlined className="uploadplus" />
//     <div className="ant-upload-text label-texts">
//       {formatMessage({
//         id: 'component.form.upload',
//       })}
//     </div>
//   </div>
// );

const AddOffices = () => {
    let router=useHistory();
    console.log('AddOffic');
    return (
        <>
            <div className="tablayout">
                <Tabs defaultActiveKey="1" className="tabView">
                    <TabPane
                        tab={
                            <Title level={4}>
                                {formatMessage({
                                    id: 'component.offices.officedetails',
                                })}
                            </Title>
                        }
                        key="1"
                    >
                        <div className="contentshadow" style={{ marginLeft: 0, marginRight: 0 }}>
                            <div className="text-primary main">
                                <AddOfficesDetails />
                            </div>
                        </div>
                    </TabPane>

                </Tabs>
            </div>
        </>
    );
};
export default AddOffices;
