import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.PRIMARY_LIGHT,
        justifyContent: 'center'
    },
    dialogCont: {
        marginHorizontal: 20,
        borderRadius: 8,
        backgroundColor: Color.WHITE
    },
    divider: {
        width: 1,
        backgroundColor: Color.PRIMARY_LIGHTER
    },
    btnCont: {
        flexDirection: 'row',
    },
    lblTitle: {
        paddingTop: 10
    },
    MainView: {
        marginTop: 10,
    },
    btn: {
        flex: 1,
        height: 45,
        alignItems: 'center',
        justifyContent: 'center'
    },
    pickerCont: {
        flexDirection: 'row',
        marginBottom: 20,
        marginTop: 10

    },
    imagePicker: {
        marginStart:20,
        backgroundColor: Color.GRAY,
        height: ThemeUtils.responsiveScale(110),
        width: ThemeUtils.responsiveScale(110),
        maxHeight: 210,
        maxWidth: 210,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center'
    },

})
