import { LoadingOutlined } from '@ant-design/icons';
import { Col,Spin, Row, Tabs, Typography } from 'antd';
import React from 'react';

import { decryptId } from '../../../../../utils/utils';
import { Query } from 'react-apollo';
import { ADMIN_AGENT_DETAIL } from '../../../../../../query/AdminQuery';
import AgentDetailForm from './component/agentdetails/components/agentdetailform';
// eslint-disable-next-line import/no-named-as-default
import EditAgentFeedBack from './component/agentdetails/components/editagentfeedback';
// eslint-disable-next-line import/no-named-as-default
import Media from './component/agentdetails/components/media';
import {formatMessage} from "components/src/utils";

const { TabPane } = Tabs;
const { Title } = Typography;

let formData;

export const AllAgentTab = props => {
    const { id } = props.match.params;
    console.log('prop-->:', decryptId(id));
    return (
        <div className="tablayout">
            <Query
                query={ADMIN_AGENT_DETAIL}
                variables={{
                    id: decryptId(id),
                }}
                fetchPolicy="no-cache"
            >
                {({ data, error, loading }) => {
                    console.log(error);
                    console.log('id=', decryptId(id));
                    if (data && data.agent_detail) {
                        console.log('full list ->', data.agent_detail);

                        formData = data.agent_detail;
                        console.log('ormatdata on tab page ->', formData);
                        return (
                            <Row>
                                <Col xs={24} lg={24}>
                                    <Tabs defaultActiveKey="agentdetails">
                                        <TabPane
                                            tab={
                                                <Title level={4}>
                                                    {formatMessage({
                                                        id: 'component.allagents.tab1',
                                                    })}
                                                </Title>
                                            }
                                            key="agentdetails"
                                        >
                                            <div className="contentshadow" style={{ marginLeft: 0, marginRight: 0 }}>
                                                <div className="text-primary main">
                                                    <AgentDetailForm objectData={formData} />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane
                                            tab={
                                                <Title level={4}>
                                                    {formatMessage({
                                                        id: 'component.allagents.tab2',
                                                    })}
                                                </Title>
                                            }
                                            key="editagentfeedback"
                                        >
                                            <EditAgentFeedBack objectData={formData} />
                                        </TabPane>
                                        <TabPane tab={<Title level={4}>{`Presse `}</Title>} key="media">
                                            <Media objectData={formData} />
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        );
                    }
                    if (loading && !error) {
                        return (
                            <div className="loader-wrapper">
                                <Spin />
                            </div>
                        );
                    }
                    if (!loading && error) {
                        return (
                            <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>
                        );
                    }
                    return <React.Fragment />;
                }}
            </Query>
        </div>
    );
};

export default AllAgentTab;
