import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {AddServiceView, CustomButton, CustomTextArea, DatePicker, Header, Label} from "components/src/components";
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//Network
import {useMutation, useQuery} from "@apollo/react-hooks";
import {SELLER_ADD_DISPLAY, SELLER_EDIT_DISPLAY} from "components/src/api/sellerMutation";
//Utils
import {
    add1Day,
    add4Hours,
    checkDiff,
    Color,
    CommonStyle,
    DateUtils,
    formatPrice,
    IS_WEB,
    Strings
} from "components/src/utils";
import styles from './styles';
import moment from "moment";
import {SELLER_BROKER_ON_VIEW, SELLER_PROPERTY_DISPLAY} from "../../../../../../api/sellerQuery";
import {connect} from "react-redux";

function AddView(props) {
    const {history, navigation} = props;
    let scrollY = new Animated.Value(0)
    const [displayDate, setDisplayDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [comment, setComment] = useState(null);
    const [isBrokerOnView, setBrokerOnView] = useState(false);
    const [service, setService] = useState([]);
    const [displayData, setDisplayData] = useState(IS_WEB ? history.location.state?.displayData ?? {} : navigation.getParam('displayData', {}))

    const isEditView = IS_WEB ? history.location.state?.isEditView ?? false : navigation.getParam('isEditView', false);

    const [addView, addViewMutation] = useMutation(isEditView ? SELLER_EDIT_DISPLAY : SELLER_ADD_DISPLAY, {
        refetchQueries: [{
            query: SELLER_PROPERTY_DISPLAY,
            variables: {
                propertyId: props.property?.id
            }
        }]
    });

    const {loading, data, error} = useQuery(SELLER_BROKER_ON_VIEW, {
        variables: {
            propertyId: props.property?.id
        }
    });

    useEffect(() => {
        if (data?.office_broker_on_view_service) {
            setService([data.office_broker_on_view_service])
        }
    }, [data])

    useEffect(() => {
        if (displayData) {
            setComment(displayData.comment);
            setBrokerOnView(displayData.is_broker_on_view === 'Broker on View');
            if (displayData.display_date) {
                setDisplayDate(new Date(moment(displayData.display_date, DateUtils.yyyy_mm_dd)))
            }
            if (displayData.earliest_start_time) {
                setStartTime(new Date(moment(displayData.earliest_start_time, DateUtils.hh_mm_ss)))
            }
            if (displayData.latest_start_time) {
                setEndTime(new Date(moment(displayData.latest_start_time, DateUtils.hh_mm_ss)))
            }
        }
    }, []);

    const addDisplayView = () => {

        if (displayDate && startTime && endTime) {
            let variables = isEditView ? {
                input: {
                    id: displayData.id,
                    fields: {
                        display_date: moment(displayDate).format(DateUtils.yyyy_mm_dd),
                        earliest_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                        latest_start_time: moment(endTime).format(DateUtils.hh_mm_ss),
                        comment: comment,
                    },
                    // is_broker_on_view: isBrokerOnView
                }
            } : {
                input: {
                    property_id: props.property?.id,
                    display_date: moment(displayDate).format(DateUtils.yyyy_mm_dd),
                    earliest_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                    latest_start_time: moment(endTime).format(DateUtils.hh_mm_ss),
                    comment: comment,
                    is_broker_on_view: isBrokerOnView
                }
            }
            addView({
                variables: variables
            }).then(() => {
                IS_WEB ? history.goBack() : navigation.goBack()
            }).catch(() => {

            })
        } else {

        }
    }
    return (
        <View style={CommonStyle.container}>
            <Provider locale={enUS}>
                <Header
                    initialMarginLeft={12}
                    animatedTitle={isEditView ? Strings.editView : Strings.addView}
                    animatedValue={scrollY}
                    navigation={IS_WEB ? history : navigation}/>
                <View style={styles.cont}>
                    <Animated.ScrollView showsVerticalScrollIndicator={false}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                             {useNativeDriver: true})}>
                        <Label xlarge
                               font_medium
                               mt={30}>

                        </Label>
                        {isEditView ? (
                            <>
                                <Label small
                                       mt={10}
                                       font_medium>
                                    {Strings.status}
                                </Label>
                                <Label small>
                                    {displayData.is_broker_on_view === "Broker on View" ? Strings.brokerOnView : Strings.sellerOnView}
                                </Label>
                                <Label small
                                       mt={10}
                                       font_medium>
                                    {Strings.description}
                                </Label>
                                <Label small>
                                    {displayData.comment}
                                </Label>
                            </>
                        ) : null}
                        <DatePicker min={new Date(moment().add(1, 'd'))} title={Strings.date}
                                    selectedDate={displayData.display_date}
                                    onSelectDate={setDisplayDate}
                                    placeholder={Strings.selectDate}/>

                        <DatePicker title={Strings.startTime}
                                    selectedDate={displayData.earliest_start_time}
                                    minTime={new Date(moment().add(1, 'd').set({hours: 0, minutes: 0, seconds: 0}))}
                                    maxTime={add1Day()}
                                    onSelectDate={(date) => {
                                        setStartTime(date)
                                        setEndTime(checkDiff(startTime) ? add1Day() : add4Hours(date))
                                    }}
                                    mode={'time'}
                                    placeholder={Strings.selectTime}/>

                        <DatePicker title={Strings.endTime}
                                    selectedDate={startTime && !endTime ? checkDiff(startTime) ? add1Day() : add4Hours(startTime) : endTime ? new Date(moment(endTime)) : null}
                                    minTime={checkDiff(startTime) ? add1Day() : add4Hours(startTime)}
                                    maxTime={add1Day()}
                                    onSelectDate={setEndTime}
                                    mode={'time'}
                                    placeholder={Strings.selectTime}/>
                        <CustomTextArea labelText={Strings.comments}
                                        onChange={setComment}
                                        value={comment}
                                        placeholder={Strings.writeYourComments}
                                        mb={15}
                                        mt={15}/>


                        {loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (<>
                            <Label small
                                   font_medium
                                   mb={10}>
                                {Strings.service}
                            </Label>
                            {service?.map(item => (

                                <AddServiceView image={item.image}
                                                isSelected={isBrokerOnView}
                                                title={item.name}
                                                desc={item.description}
                                                onPress={() => {
                                                    if (!isEditView || displayData.is_broker_on_view !== "Broker on View") {
                                                        setBrokerOnView(prevVal => !prevVal)
                                                    }
                                                }}
                                                name={'disclaimer'}
                                                price={`Kr ${formatPrice(item?.service_selected ? item?.service_selected?.price : item?.price)},-`}/>

                            ))}
                        </>)}

                        <View style={styles.btnCont}>
                            <CustomButton font_medium
                                          textColor={Color.WHITE}
                                          style={{flex: 1}}
                                          mr={5}
                                          onPress={addDisplayView}
                                          loading={addViewMutation.loading}
                                          title={isEditView ? Strings.update : Strings.Save}/>
                            <CustomButton font_medium
                                          ml={5}
                                          onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}
                                          style={{flex: 1}}
                                          borderColor={Color.PRIMARY_LIGHT}
                                          borderWidth={1}
                                          bgColor={Color.WHITE}
                                          textColor={Color.PRIMARY}
                                          title={Strings.cancel}/>
                        </View>
                    </Animated.ScrollView>
                </View>
            </Provider>
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStateToProps)(AddView);
