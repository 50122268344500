import { Col, Row, Tabs, Typography } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { formatMessage } from 'components/src/utils';
// eslint-disable-next-line import/no-named-as-default
import AdsAndPopUpsContract from './Component/contract';
// eslint-disable-next-line import/no-named-as-default
import AdsAndPopUpsMarketing from './Component/marketing';
// eslint-disable-next-line import/no-named-as-default
import AdsAndPopUpsSales from './Component/sales';

const { Title } = Typography;
const { TabPane } = Tabs;

// const onFinishSales = (values, addAdsAndPopUpsSales) => {
//   console.log('Success:', values);
//   addAdsAndPopUpsSales({
//     variables: {
//       ads_image: [values.image1_sales.fileList[0].originFileObj, values.image2_sales.fileList[0].originFileObj] ,
//       redirect_url: [values.redirect_address1_sales,values.redirect_address2_sales],
//       place: "down",
//     },
//   });
//   // router.push(`markedsføringsalg`);
// };

// const onFinishFailedSales = errorInfo => {
//   console.log('Failed:', errorInfo);
// };
// const onFinishMarketing = (values, addAdsAndPopUpsMarketing) => {
//   console.log('Success:', values);
//   addAdsAndPopUpsMarketing({
//     variables: {
//       ads_image: [
//         values.image1_marketing.fileList[0].originFileObj,
//         values.image2_marketing.fileList[0].originFileObj,
//       ],
//       redirect_url: [values.redirect_address1_marketing, values.redirect_address2_marketing],
//       place: 'down',
//     },
//   });
// };

// const onFinishFailedMarketing = errorInfo => {
//   console.log('Failed:', errorInfo);
// };

// const onFinishContract = (values, addAdsAndPopUpsContract) => {
//   console.log('Success:', values);
//   addAdsAndPopUpsContract({
//     variables: {
//       ads_image: [values.image1_contract.fileList[0].originFileObj, values.image2_contract],
//       redirect_url: [values.redirect_address1_contract, values.redirect_address2_contract],
//       place: 'down',
//     },
//   });
// };

// const onFinishFailedContract = errorInfo => {
//   console.log('Failed:', errorInfo);
// };
// const uploadButton = (
//   <div>
//     <PlusOutlined style={{ fontSize: '40px', opacity: 0.3 }} />
//     <div className="ant-upload-text label-texts">
//       {formatMessage({
//         id: 'component.form.upload',
//       })}
//     </div>
//   </div>
// );

export const AdsAndPopUps = () => {
    console.log('');

    return (
        <div className="contentshadow">
            <div className="text-primary main">
                <Row>
                    <Col lg={24} md={24} xs={24}>
                        <Title className="text-primary" level={3}>
                            {formatMessage({
                                id: 'component.adsandpopups.title',
                            })}
                        </Title>
                        <p className="text-primary">
                            Her legger du til reklamebanner for de tre forskjellige fasene på selger sitt
                            dashboard. Du kan enten legge til et banner, da må det være full vidde. Dersom du
                            legger til to må de ha halv bredde.
                        </p>
                    </Col>
                </Row>
                <Tabs defaultActiveKey="agentdetails" className="tabView" size="large">
                    <TabPane
                        tab={formatMessage({
                            id: 'component.adsandpopups.salespreparations',
                        })}
                        key="1"
                    >
                        <AdsAndPopUpsSales />
                    </TabPane>

                    <TabPane
                        tab={formatMessage({
                            id: 'component.marketingandsales.title',
                        })}
                        key="2"
                    >
                        <AdsAndPopUpsMarketing />
                    </TabPane>
                    <TabPane
                        tab={formatMessage({
                            id: 'component.adsandpopups.contract&settlement',
                        })}
                        key="3"
                    >
                        <AdsAndPopUpsContract />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default AdsAndPopUps;
