import {StyleSheet} from 'react-native';
import {Color, IS_IOS, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,
        backgroundColor: Color.WHITE,
    },
    flexGrow: {
        paddingBottom: IS_IOS ? 40 : 0
    },
    contentCont: {
        marginHorizontal: 20,
    },
    titleCont: {
        marginTop: 20,
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    lblInst: {
        lineHeight: 18,
    },
    btnAddView: {
        paddingVertical: 15,
        backgroundColor: Color.PRIMARY
    },
    orderCont: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginTop: 15,
    },
    billCont: {
        // backgroundColor: Color.PRIMARY,
        paddingVertical: 10,
    },
    lineStyle: {
        // backgroundColor: Color.LIGHT_GRAY,
        marginHorizontal: ThemeUtils.relativeRealWidth(3),
        marginVertical: 10
    },
    btnOkay: {
        marginHorizontal: ThemeUtils.relativeRealWidth(2)
    },
    billListCont: {
        marginVertical: 5
    }
})
