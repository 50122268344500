import React from 'react'
import {ActivityIndicator, Animated, TouchableOpacity, View} from 'react-native'
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    Icon,
    IS_WEB,
    Routes, showMessage,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";


import Provider from "@ant-design/react-native/lib/provider";

import {logout, setCurrentProperty, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
import {
    CheckBox,
    CustomButton,
    DatePicker,
    Header,
    Hr,
    InputField,
    Label,
    PopOver,
    PropertyList,
    RadioGroup,
    Toast
} from "components/src/components";

import {Mutation, Query} from "@apollo/react-components";
import {SELLER_PROPERTY_LIST, SELLER_SETTLEMENT_INFO} from "components/src/api/sellerQuery";
import {SELLER_ADD_SETTLEMENT_INFO} from "components/src/api/sellerMutation";


import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import {styles} from './styles.js';
import moment from "moment";


class Settlement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fraDate: null,
            isPopOverVisible: false,
            propertyListVisible: false,
            startTime: undefined,
            endTime: undefined,
            isLoanApplicable: true,
            receivables: [{
                accountNo: '',
                errAccountNo: null,
                percentage: '',
                errPercentage: null
            }],
            loans: [{
                denominationkr: '',
                errordenominationkr: null,
                residualdebtkr: '',
                errorresidualdebtkr: null,
                contact: '',
                errorcontact: null,
                phone: '',
                errorphone: null,
                email: '',
                erroremail: null,
                loanno: '',
                errorloanno: null,
                isRepaid: false
            }],
            address: '',
            erroraddress: null,
            city: '',
            errorCity: null,
            zipcode: '',
            errorZipcode: null,

        };
        this.scrollY = new Animated.Value(0)
        if (!this.props.property?.property_buyer_info) {
            IS_WEB ? props.history.goBack() : props.navigation.goBack();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.property?.id !== this.props.property?.id) {
            if (!(this.props.property?.property_buyer_info && !this.props.property?.settlementInfo)) {
                IS_WEB ? this.props.history.push(Routes.SalesPreparation) : this.props.navigation.push(Routes.SalesPreparation)
            }
        }
    }

    togglePopover = () => {
        this.setState(prevState => ({isPopOverVisible: !prevState.isPopOverVisible}))
    };

    togglePropertyList = () => {
        this.setState(prevState => ({
            propertyListVisible: !prevState.propertyListVisible
        }))
    };

    handleChangeText = (index, text, fieldId) => {
        let updatedLoans = this.state.loans.slice();
        switch (fieldId) {
            case 'dp':
                updatedLoans[index].reminder_date = text;
                break;
            case 'cb':
                updatedLoans[index].isRepaid = text.includes(1);
                break;
            case Constants.FieldId.DENOMINATION:

                updatedLoans[index].denominationkr = text;
                updatedLoans[index].errordenominationkr = null;

                break;

            case Constants.FieldId.BANK_NAME:

                updatedLoans[index].bankName = text;
                updatedLoans[index].errBankName = null;

                break;
            case Constants.FieldId.RESIDUAL_DEB_TKR:

                updatedLoans[index].residualdebtkr = text;
                updatedLoans[index].errorresidualdebtkr = null;

                break;

            case Constants.FieldId.CONTACT:

                updatedLoans[index].contact = text;
                updatedLoans[index].errorcontact = null;

                break;
            case Constants.FieldId.PHONE:

                updatedLoans[index].phone = text;
                updatedLoans[index].errorphone = null;

                break;

            case Constants.FieldId.EMAIL:

                updatedLoans[index].email = text;
                updatedLoans[index].erroremail = null;

                break;

            case Constants.FieldId.LOAN_NO:

                updatedLoans[index].loanno = text;
                updatedLoans[index].errorloanno = null;

                break;
            case Constants.FieldId.ACCOUNT_NO_FOR_TRANSFER:

                updatedLoans[index].accountnofortransfer = text;
                updatedLoans[index].erroraccountfortransfer = null;

                break;
        }
        this.setState({loans: updatedLoans})
    };

    handleOnFocus = (index, fieldId) => {
        let updatedLoans = this.state.loans.slice();
        switch (fieldId) {
            case Constants.FieldId.ADDRESS:
                updatedLoans[index].erroraddress = null;

                break;
            case Constants.FieldId.CITY:
                updatedLoans[index].errorCity = null;

                break;
            case Constants.FieldId.ZIP_CODE:
                updatedLoans[index].errorZipcode = null;

                break;
            case Constants.FieldId.DENOMINATION:
                updatedLoans[index].errordenominationkr = null;

                break;

            case Constants.FieldId.RESIDUAL_DEB_TKR:
                updatedLoans[index].errorresidualdebtkr = null;

                break;

            case Constants.FieldId.CONTACT:
                updatedLoans[index].errorcontact = null;

                break;
            case Constants.FieldId.PHONE:
                updatedLoans[index].errorphone = null;

                break;

            case Constants.FieldId.EMAIL:
                updatedLoans[index].erroremail = null;

                break;

            case Constants.FieldId.LOAN_NO:
                updatedLoans[index].errorloanno = null;
                break;
            case Constants.FieldId.BANK_NAME:
                updatedLoans[index].errBankName = null;

                break;
            case Constants.FieldId.ACCOUNT_NO_FOR_TRANSFER:
                updatedLoans[index].erroraccountfortransfer = null;

                break;
        }
        this.setState({loans: updatedLoans})
    };

    handleReceivablesTextChange = (index, text, fieldId) => {
        let updatedReceivables = this.state.receivables.slice();
        switch (fieldId) {
            case Constants.FieldId.ACCOUNT_NO_FOR_TRANSFER:
                updatedReceivables[index].accountNo = text;
                updatedReceivables[index].errAccountNo = null;
                break;
            case Constants.FieldId.PERCENTAGE:
                updatedReceivables[index].percentage = text;
                updatedReceivables[index].errPercentage = null;
                break;
        }
        this.setState({
            receivable: updatedReceivables
        })
    }

    handleReceivablesFocus = (index, fieldId) => {
        let updatedReceivables = this.state.receivables.slice();
        switch (fieldId) {
            case Constants.FieldId.ACCOUNT_NO_FOR_TRANSFER:
                updatedReceivables[index].errAccountNo = null;
                break;
            case Constants.FieldId.PERCENTAGE:
                updatedReceivables[index].errPercentage = null;
                break;
        }
        this.setState({
            receivable: updatedReceivables
        })
    }

    settlementbutton = (saveSettlementInfo) => {
        const {address, city, zipcode, denominationkr, residualdebtkr, contact, phone, email, loanno, accountnofortransfer} = this.state;
        let updatedLoans = this.state.loans.slice();
        let updatedReceivables = this.state.receivables.slice();

        let isError = false;
        let isReceivablesError = false
        if (this.state.isLoanApplicable) {
            for (let i = 0; i < updatedLoans.length; i++) {
                updatedLoans[i].errBankName = validation('name', updatedLoans[i].bankName);
                // updatedLoans[i].errordenominationkr = validation('DenominationKr', updatedLoans[i].denominationkr);
                // updatedLoans[i].errorresidualdebtkr = validation('ResidualDebt', updatedLoans[i].residualdebtkr);
                updatedLoans[i].errorcontact = validation('Contacts', updatedLoans[i].contact);
                updatedLoans[i].errorphone = validation('phoneNo', updatedLoans[i].phone);
                updatedLoans[i].erroremail = validation('email', updatedLoans[i].email);
                updatedLoans[i].errorloanno = validation('Loanno', updatedLoans[i].loanno);

                if (updatedLoans[i].errordenominationkr ||
                    updatedLoans[i].errBankName ||
                    // updatedLoans[i].errorresidualdebtkr ||
                    // updatedLoans[i].errorcontact ||
                    updatedLoans[i].errorphone ||
                    updatedLoans[i].erroremail ||
                    updatedLoans[i].errorloanno ||
                    !updatedLoans[i].reminder_date) {
                    isError = true;
                }
            }
        }
        for (let i = 0; i < updatedReceivables.length; i++) {
            updatedReceivables[i].errAccountNo = validation('AccountNoForTransfer', updatedReceivables[i].accountNo);
            updatedReceivables[i].errPercentage = validation('percentage', updatedReceivables[i].percentage);

            if (updatedReceivables[i].errAccountNo ||
                updatedReceivables[i].errPercentage) {
                isReceivablesError = true;
            }
        }

        if (!this.state.fraDate) {
            isError = true;
            showMessage("Please select from date.",Constants.MessageType.FAILED)

        }
        this.setState({
            erroraddress: validation('address', address.trim()),
            errorCity: validation('city', city),
            errorZipcode: validation('zipCode', zipcode),
        }, () => {
            if (!isError && !isReceivablesError && !this.state.erroraddress && !this.state.errorCity && !this.state.errorZipcode) {
                let req = {
                    new_address: {
                        property_id: this.props.property?.id,
                        address: address,
                        city: city,
                        fra_date: moment(this.state.fraDate).format(DateUtils.yyyy_mm_dd),
                        zipcode: zipcode,
                        loan_status: this.state.isLoanApplicable ? 1 : 0

                    },
                    receivables: updatedReceivables.map(item => ({
                        receivables_account_no: item.accountNo,
                        percentage: item.percentage
                    })),

                }
                if (this.state.isLoanApplicable) {
                    req = {
                        ...req,
                        loans: updatedLoans.map(item => ({
                            denomination_kr: item.denominationkr,
                            residual_debt_kr: item.denominationkr,
                            reminder_date: moment(item.reminder_ate).format(DateUtils.yyyy_mm_dd),
                            bank_name: item.bankName,
                            bank_contact: item.contact,
                            bank_phone: item.phone,
                            bank_email: item.email,
                            loan_no: item.loanno,
                            is_repaid: item.isRepaid,
                        }))
                    }
                }
                saveSettlementInfo({
                    variables: {
                        input: req
                    }
                })
            }
        })
    };

    removeLoan = (index) => {
        let updatedLoans = this.state.loans.slice();
        updatedLoans.splice(index, 1)
        this.setState({loans: updatedLoans})
    }

    addLoans = () => {
        let updatedLoans = this.state.loans.slice();
        updatedLoans.push({
            denominationkr: '',
            errordenominationkr: null,
            residualdebtkr: '',
            errorresidualdebtkr: null,
            contact: '',
            errorcontact: null,
            phone: '',
            errorphone: null,
            email: '',
            erroremail: null,
            loanno: '',
            errorloanno: null,
            isRepaid: false,
        });
        this.setState({loans: updatedLoans})
    };

    removeReceivables = (index) => {
        let updatedReceivables = this.state.receivables.slice();
        updatedReceivables.splice(index, 1)
        this.setState({receivables: updatedReceivables})
    }

    addReceivables = () => {
        let updatedReceivables = this.state.receivables.slice();
        if (updatedReceivables.length < this.state.loans.length) {
            updatedReceivables.push({
                accountNo: '',
                errAccountNo: null,
                percentage: '',
                errPercentage: null
            });
            this.setState({receivables: updatedReceivables})
        }

    };

    renderReceivables = (item, index) => {
        return (
            <View style={styles.receivablesCont}>
                {index > 0 ? <Hr/> : null}
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginVertical: 15,
                }}>
                    <Label
                        small
                        style={CommonStyle.flex}
                        font_medium
                        align={'left'}>
                        {/*{index > 0 ? `${Strings.additionalReceivables} ${index}` : Strings.Receivables}*/}
                        {`${Strings.Receivables} ${index + 1}`}
                    </Label>
                    {index > 0 ? (
                        <TouchableOpacity activeOpacity={0.5}
                                          onPress={() => this.removeReceivables(index)}>
                            <Icon name={'order-cancel'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.RED}/>
                        </TouchableOpacity>) : null}
                </View>


                <View style={styles.fieldView}>
                    <Label small
                           font_medium
                           mb={0}

                           align={'left'}>{Strings.AccountNoforTransferofReceivable}</Label>

                </View>
                <InputField
                    onChange={(text) => this.handleReceivablesTextChange(index, text, Constants.FieldId.ACCOUNT_NO_FOR_TRANSFER)}
                    onFocus={() => this.handleReceivablesFocus(index, Constants.FieldId.ACCOUNT_NO_FOR_TRANSFER)}
                    error={item.errAccountNo}
                    value={item.accountNo}
                    mt={12}
                    type={"number"}
                    placeholder={Strings.enteraccountnumber}/>

                <View style={styles.fieldView}>
                    <Label small
                           font_medium
                           mr={5}
                           align={'left'}>
                        {"Prosentdel"}
                    </Label>
                    <TouchableOpacity ref={r => this.touchableRef = r}
                                      onPress={this.togglePopover}>
                        <Icon name={"faq"}
                              style={CommonStyle.fontNormal}/>
                    </TouchableOpacity>
                </View>
                <InputField
                    onChange={(text) => this.handleReceivablesTextChange(index, text, Constants.FieldId.PERCENTAGE)}
                    onFocus={() => this.handleReceivablesFocus(index, Constants.FieldId.PERCENTAGE)}
                    error={item.errPercentage}
                    value={item.percentage}
                    mt={12}
                    mb={5}
                    type={"number"}
                    placeholder={Strings.enterPercentage}/>

            </View>
        )
    };

    renderLoanDetails = (item, index) => {
        return (
            <>
                <Hr/>

                <View style={styles.topContainer}>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 15,
                    }}>
                        <Label
                            small
                            style={CommonStyle.flex}
                            font_medium
                            align={'left'}>
                            {/*{index > 0 ? `${Strings.additionalLoan} ${index}` : Strings.Loan}*/}
                            {`${Strings.Loan} ${index + 1}`}
                        </Label>
                        {index > 0 ? (
                            <TouchableOpacity activeOpacity={0.5}
                                              onPress={() => this.removeLoan(index)}>
                                <Icon name={'order-cancel'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>) : null}
                    </View>

                    <View style={styles.fieldView}>
                        <Label small
                               font_medium
                               mb={0}
                               align={'left'}>{Strings.DenominationKr}</Label>

                    </View>
                    <InputField
                        onChange={(text) => this.handleChangeText(index, text, Constants.FieldId.DENOMINATION)}
                        onFocus={() => this.handleOnFocus(index, Constants.FieldId.DENOMINATION)}
                        error={item.errordenominationkr}
                        value={item.denominationkr}
                        mt={12} type="text"
                        placeholder={Strings.enterdenominationamount}/>

                    <View style={styles.fieldView}>
                        <Label small
                               font_medium
                               mb={0}
                               align={'left'}>{Strings.ResidualDebtKr}</Label>

                    </View>
                    <InputField
                        onChange={(text) => this.handleChangeText(index, text, Constants.FieldId.RESIDUAL_DEB_TKR)}
                        onFocus={() => this.handleOnFocus(index, Constants.FieldId.RESIDUAL_DEB_TKR)}
                        error={item.errorresidualdebtkr}
                        value={item.residualdebtkr}
                        mt={12} type="text"
                        placeholder={Strings.enterresidualdebtamount}/>
                    <View style={styles.fieldView}>
                        <Label small
                               font_medium
                               mb={15}
                               align={'left'}>{Strings.ReminderonDate}</Label>

                    </View>
                    <DatePicker min={new Date(moment().add(1, 'd'))}
                                selectedDate={item.reminder_date ? new Date(moment(item.reminder_date, DateUtils.yyyy_mm_dd)) : null}
                                placeholder={Strings.selectDate}
                                onSelectDate={(date) => this.handleChangeText(index, date, 'dp')}/>


                    {/*<Label
                        mt={30}
                        mb={15}
                        small
                        font_medium
                        align={'left'}>{Strings.Bank}</Label>
*/}
                    <View style={styles.fieldView}>
                        <Label small
                               font_medium
                               mt={30}
                               mb={0}
                               align={'left'}>{Strings.Bank}</Label>

                    </View>
                    <InputField
                        onChange={(text) => this.handleChangeText(index, text, Constants.FieldId.BANK_NAME)}
                        onFocus={() => this.handleOnFocus(index, Constants.FieldId.BANK_NAME)}
                        error={item.errBankName}
                        value={item.bankName}
                        type="text"
                        mt={12}
                        placeholder={Strings.enterBankName}/>


                    <View style={styles.fieldView}>
                        <Label small
                               font_medium
                               mb={0}
                               align={'left'}>{Strings.contactPerson}</Label>

                    </View>
                    <InputField
                        onChange={(text) => this.handleChangeText(index, text, Constants.FieldId.CONTACT)}
                        onFocus={() => this.handleOnFocus(index, Constants.FieldId.CONTACT)}
                        error={item.errorcontact}
                        value={item.contact}
                        mt={12} type="text" placeholder={Strings.entercontact}/>

                    <View style={styles.fieldView}>
                        <Label small
                               font_medium
                               mb={0}
                               align={'left'}>{Strings.phone}</Label>

                    </View>
                    <InputField
                        onChange={(text) => this.handleChangeText(index, text, Constants.FieldId.PHONE)}
                        onFocus={() => this.handleOnFocus(index, Constants.FieldId.PHONE)}
                        error={item.errorphone}
                        value={item.phone}
                        mt={12} type="text"
                        placeholder={"Oppgi telefon"}/>
                    <View style={styles.fieldView}>
                        <Label small
                               font_medium
                               mb={0}

                               align={'left'}>{Strings.email}</Label>
                    </View>
                    <InputField
                        onChange={(text) => this.handleChangeText(index, text, Constants.FieldId.EMAIL)}
                        onFocus={() => this.handleOnFocus(index, Constants.FieldId.EMAIL)}
                        error={item.erroremail}
                        value={item.email}
                        mt={12} type="text"
                        placeholder={"Oppgi epost"}/>
                    <View style={styles.fieldView}>
                        <Label small
                               font_medium
                               mb={0}
                               align={'left'}>{Strings.LoanNo}</Label>

                    </View>
                    <InputField mt={12}
                                onChange={(text) => this.handleChangeText(index, text, Constants.FieldId.LOAN_NO)}
                                onFocus={() => this.handleOnFocus(index, Constants.FieldId.LOAN_NO)}
                                error={item.errorloanno}
                                value={item.loanno}
                                type="number"
                                placeholder={"Oppgi lånenummer"}/>
                    <Label
                        mt={15}
                        mb={10}
                        small
                        font_medium
                        align={'left'}>{Strings.ShouldtheLoanberepaid}</Label>
                    <View>
                        <View style={styles.checkbox}>
                            <RadioGroup changeOnProp
                                        options={Constants.RADIO_OPTIONS}
                                        selectedOptions={item.isRepaid ? Constants.RADIO_OPTIONS[0].value : Constants.RADIO_OPTIONS[1].value}
                                        style={styles.SettlementRadio}
                                        onSelectOption={(val) => this.handleChangeText(index, val, 'cb')}/>

                        </View>
                    </View>
                </View>
            </>
        )
    };

    renderPropertyListModal = () => {
        const {propertyListVisible, currentUserType} = this.state;
        return (
            <Query query={SELLER_PROPERTY_LIST}
                   onCompleted={(data) => {
                       this.props.setCurrentProperty(data.seller_property_list.find(item => item.id === this.props.property.id))
                   }}
                   variables={{
                       status: 1
                   }}>
                {({loading, data, error, refetch}) => {
                    this.refetchProperties = refetch;
                    return (
                        <PropertyList visible={propertyListVisible}
                                      loading={loading}
                                      data={data?.seller_property_list}
                                      onClose={this.togglePropertyList}/>
                    )
                }}
            </Query>
        )
    };

    render() {
        return (
            <View style={CommonStyle.container}>
                <Toast ref={r => this.toast = r}/>
                <Provider locale={enUS}>
                    <Header animatedTitle={Strings.Settlementinformation}
                            onPressLeft={() => {
                                if (!(this.props.property?.property_buyer_info && !this.props.property?.settlementInfo)) {
                                    IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack();
                                }
                            }}
                            headerRightFirst={this.props.property?.property_buyer_info && !this.props.property?.settlementInfo ? 'ds-property' : null}
                            onPressRightFirst={this.togglePropertyList}
                            navigation={IS_WEB ? this.props.history : this.props.navigation}
                            animatedValue={this.scrollY}/>
                    <PopOver fromView={this.touchableRef}
                             onRequestClose={this.togglePopover}
                             isVisible={this.state.isPopOverVisible}>
                        <Label small
                               ml={10}
                               mr={10}
                               mt={10}
                               mb={10}>
                            {"Dersom tilgodehavende skal utbetales til flere kontonummer må du oppgi hvilken prosentandel som skal betales til hvert kontonummer. Skal alt betales til ett kontonummer skriver du 100%."}
                        </Label>
                    </PopOver>
                    {this.props.property?.property_buyer_info && !this.props.property?.settlementInfo ? this.renderPropertyListModal() : null}
                    <Query query={SELLER_SETTLEMENT_INFO}
                           onCompleted={(data) => {
                               if (data?.property_settlement_information) {
                                   this.setState({
                                       isLoanApplicable: data?.property_settlement_information?.loan_status !== 0,
                                       address: data?.property_settlement_information?.address,
                                       city: data?.property_settlement_information?.city,
                                       zipcode: data?.property_settlement_information?.zipcode,
                                       fraDate: data?.property_settlement_information?.fra_date ? new Date(moment(data?.property_settlement_information?.fra_date, DateUtils.yyyy_mm_dd)) : null,
                                       loans: data?.property_settlement_information?.settlement_loans?.length ?
                                           data?.property_settlement_information?.settlement_loans?.map(item => ({
                                               bankName: item?.bank_name,
                                               errBank: null,
                                               denominationkr: item?.denomination_kr,
                                               errordenominationkr: null,
                                               residualdebtkr: item?.residual_debt_kr,
                                               errorresidualdebtkr: null,
                                               contact: item?.bank_contact,
                                               errorcontact: null,
                                               phone: item?.bank_phone,
                                               errorphone: null,
                                               email: item?.bank_email,
                                               erroremail: null,
                                               loanno: item?.loan_no,
                                               errorloanno: null,
                                               reminder_date: item.reminder_date,
                                               isRepaid: item?.is_repaid
                                           })) : [{
                                               denominationkr: '',
                                               errordenominationkr: null,
                                               residualdebtkr: '',
                                               errorresidualdebtkr: null,
                                               bankName: '',
                                               errBank: null,
                                               contact: '',
                                               errorcontact: null,
                                               phone: '',
                                               errorphone: null,
                                               email: '',
                                               erroremail: null,
                                               loanno: '',
                                               errorloanno: null,
                                               isRepaid: false,
                                               reminder_date: null
                                           }],
                                       receivables: data?.property_settlement_information?.settlement_receivable?.map(item => ({
                                           accountNo: item?.receivables_account_no,
                                           errAccountNo: null,
                                           percentage: item?.percentage,
                                           errPercentage: null
                                       })),
                                   })
                               }
                           }}
                           variables={{
                               propertyId: this.props.property?.id
                           }}>
                        {({data, loading, refetch, error}) => {
                            return (
                                <Animated.ScrollView scrollEventThrottle={1}
                                                     contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                                     onScroll={Animated.event(
                                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                                         {useNativeDriver: true})}>

                                    <View style={styles.container}>
                                        <View style={styles.labeliconContainer}>
                                            <Icon
                                                size={ThemeUtils.fontXXLarge}
                                                name={'settlement-information'}
                                                color={Color.PRIMARY_DARK}/>

                                            <Label
                                                mt={15}
                                                small
                                                font_regular
                                                align={'left'}>{Strings.Settlementinfodetails}</Label>
                                        </View>
                                        {loading && !data ? (
                                            <ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>
                                        ) : (
                                            <>
                                                <View style={styles.topContainer}>

                                                    <Label
                                                        mb={10}
                                                        mt={10}
                                                        font_medium
                                                        align={'left'}>{Strings.NewAddress}</Label>

                                                    <View style={styles.fieldView}>
                                                        <Label small
                                                               font_medium
                                                               mb={0}
                                                               align={'left'}>{Strings.address}</Label>

                                                    </View>
                                                    <InputField
                                                        mt={12} type="text"
                                                        placeholder={Strings.enterAddress}
                                                        onChange={(text) => this.setState({
                                                            address: text,
                                                            erroraddress: null
                                                        })}
                                                        onFocus={() => this.setState({erroraddress: null})}
                                                        error={this.state.erroraddress}
                                                        value={this.state.address}

                                                    />

                                                    <View style={styles.fieldView}>
                                                        <Label small
                                                               font_medium
                                                               mb={0}
                                                               align={'left'}>{Strings.city}</Label>

                                                    </View>
                                                    <InputField
                                                        mt={12} type="text"
                                                        placeholder={Strings.enterCity}
                                                        onChange={(text) => this.setState({
                                                            city: text,
                                                            errorCity: null
                                                        })}
                                                        onFocus={() => this.setState({errorCity: null})}
                                                        error={this.state.errorCity}
                                                        value={this.state.city}/>
                                                    <View style={styles.fieldView}>
                                                        <Label small
                                                               font_medium
                                                               mb={0}
                                                               align={'left'}>{Strings.zipcode}</Label>

                                                    </View>
                                                    <InputField
                                                        mt={12}
                                                        type="text"
                                                        placeholder={Strings.enterZipCode}
                                                        onChange={(text) => this.setState({
                                                            zipcode: text,
                                                            errorZipcode: null
                                                        })}
                                                        onFocus={() => this.setState({errorZipcode: null})}
                                                        error={this.state.errorZipcode}
                                                        value={this.state.zipcode}/>
                                                    <DatePicker title={"Fra date"}
                                                                min={new Date(moment())}
                                                                selectedDate={this.state.fraDate}
                                                                placeholder={Strings.selectDate}
                                                                onSelectDate={(date) => this.setState({fraDate: date})}/>
                                                </View>


                                                <Label small
                                                       font_medium
                                                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                                    {"Oversikt over lån"}
                                                </Label>

                                                {console.log(this.state.isLoanApplicable, "CAL")}
                                                <CheckBox
                                                    defaultChecked={[data?.property_settlement_information?.loan_status ?? 1]}
                                                    onCheckChanged={(val) => this.setState({isLoanApplicable: !val.includes(0)})}
                                                    style={{
                                                        marginVertical: 10,
                                                        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
                                                    }}
                                                    options={[{
                                                        name: "Det er ingen lån på eiendommen",
                                                        value: 0
                                                    }]}/>
                                                {this.state.isLoanApplicable ? (
                                                    <>
                                                        {this.state.loans.map(this.renderLoanDetails)}
                                                        <CustomButton onPress={this.addLoans}
                                                                      ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                                      mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                                      mb={15}
                                                                      title={Strings.AddmoreLoans}/>
                                                    </>
                                                ) : null}

                                                <Hr/>
                                                {this.state.receivables.map(this.renderReceivables)}
                                                <CustomButton mt={10}
                                                              ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                              mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                              mb={10}
                                                              title={Strings.addMoreReceivables}
                                                              onPress={() => this.addReceivables()}/>
                                                <Mutation mutation={SELLER_ADD_SETTLEMENT_INFO}
                                                          onCompleted={() => {
                                                              refetch && refetch();

                                                              this.refetchProperties && this.refetchProperties().then(({data}) => {
                                                                  this.props.setCurrentProperty(data.seller_property_list.find(item => item.id === this.props.property.id))
                                                              });
                                                              IS_WEB ? this.props.history.replace(Routes.ContractAndSettlement) : this.props.navigation.goBack();
                                                          }}
                                                          onError={() => {
                                                          }}>
                                                    {(saveSettlementInfo, {loading, error, data}) => (
                                                        <CustomButton mt={23}
                                                                      loading={loading}
                                                                      ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                                      mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                                      mb={10}
                                                                      title={Strings.Save}
                                                                      onPress={() => this.settlementbutton(saveSettlementInfo)}/>
                                                    )}

                                                </Mutation>
                                            </>
                                        )}

                                    </View>


                                </Animated.ScrollView>
                            )
                        }}
                    </Query>
                </Provider>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout()),
        setCurrentProperty: (property) => dispatch(setCurrentProperty(property)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settlement);
