import React, {useEffect} from 'react';
//components
import {ActivityIndicator, ScrollView, View} from 'react-native';
import {Label} from "components/src/components";
//Utils
import styles from './styles';
import {Color, CommonStyle, DateUtils, Icon, Strings, ThemeUtils} from 'components/src/utils';
import moment from "moment";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {SELLER_TIMELINE} from "components/src/api/sellerQuery";
import {UPDATE_PROPERTY_TIME_LINE} from "components/src/api/sellerMutation";
import {connect} from "react-redux";


function TimeLine(props) {
    const {property} = props;

    const [updateTimeline] = useMutation(UPDATE_PROPERTY_TIME_LINE)

    const {loading, data, refetch, error} = useQuery(SELLER_TIMELINE, {
        variables: {
            propertyId: property?.id,
            status: 1
        }
    });

    useEffect(() => {
        updateTimeline({
            variables: {
                propertyId: property?.id,
                status: 1
            }
        }).then(() => {
            refetch && refetch()
        }).catch(() => {

        })
    }, [])

    const getPlaceholderText = (item) => {
        switch (item.id) {
            case 1:
            case 2:
            case 3:
            case 6:
            case 7:
                return item.completed_date ? (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.TERNARY_TEXT_COLOR}>
                            {`Bestilt : ${moment(item.completed_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                        </Label>
                    </View>
                ) : null
            case 5:
                return item.completed_date ? (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.TERNARY_TEXT_COLOR}>
                            {`Planlagt : ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(2, "d").format(DateUtils.dd_mm_yyyy)} - ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(7, "d").format(DateUtils.dd_mm_yyyy)}`}
                        </Label>
                    </View>
                ) : null
            case 8: //Contract
                return (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.TERNARY_TEXT_COLOR}>
                            {item.completed_date ? `Planlagt : ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(2, "d").format(DateUtils.dd_mm_yyyy)} - ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(7, "d").format(DateUtils.dd_mm_yyyy)}` : 'Etter avtale'}
                        </Label>
                    </View>
                )
            case 9: //Takover
                return (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.TERNARY_TEXT_COLOR}>
                            {item.completed_date ? `${moment(item.completed_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}` : "Etter avtale"}
                        </Label>
                    </View>
                )
            case 4:

                return (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.TERNARY_TEXT_COLOR}>
                            {"Fra oppdragsignering til markedsføring"}
                        </Label>
                    </View>
                )

        }
    };


    const renderStepperCustom = (item, index, self) => {
        return (
            <View style={styles.stepperCont}
                  key={index.toString()}>
                <View style={styles.dotCont}>
                    <View style={styles.dot}/>
                    {index === self.length - 1 ? null : (<View style={styles.line}/>)}
                </View>
                <View style={styles.lblCont}>
                    <View style={styles.stepperTitleCont}>

                        <Icon name={item?.timeline?.icon?.replace("icon-", "")}
                              color={Color.SECONDARY}
                              size={ThemeUtils.fontXLarge}/>
                        <Label large
                               ml={10}
                               font_medium>
                            {item?.timeline?.name}
                        </Label>
                    </View>
                    {item.is_completed ?
                        (<View style={styles.lblCompleted}>
                            <Label xsmall
                                   color={Color.TERNARY_TEXT_COLOR}>
                                {`Gjennomført : ${moment.utc(item.completed_date + " " + item.completed_time).format(DateUtils.hh_mm_dd_mm_yyy)}`}
                            </Label>
                        </View>) : getPlaceholderText(item)}
                    {/*<Label small
                           style={CommonStyle.flex}
                           mt={15}
                           mb={20}>
                        {item.timeline.description}
                    </Label>*/}
                </View>
            </View>
        );
    };

    return (
        <ScrollView>
            <View style={styles.cont}>
                <View style={styles.titleCont}>
                    <Icon
                        size={ThemeUtils.fontXXLarge}
                        name={'timeline'}
                        color={Color.PRIMARY_DARK}/>

                    <Label xlarge
                           ml={9}
                           font_medium
                           align={'left'}>
                        {Strings.timeline}
                    </Label>
                </View>
                <Label small
                       style={CommonStyle.flex}
                       mb={20}>
                    {Strings.timeLineInst}
                </Label>
                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>
                ) : data?.seller_timeline?.map(renderStepperCustom)}

            </View>
        </ScrollView>
    )

}

const mapStateToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStateToProps)(TimeLine)

