/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */
import { STORAGE_URL } from '../../../../../../utils/constant';
import { formatCurrency } from '../../../../../../utils/utils';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, List, Modal, notification, Row, Typography } from 'antd';
// import { router } from 'umi';
import moment from 'moment';
import React, { useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { formatMessage } from "components/src/utils";
import { REMOVE_B2BSERVICES, SUPERUSER_PURCHASEB2BSERVICES } from '../../../../../../../mutation/SuperuserMutation';
import { ADMIN_OFFICES_B2B_SERIVICES } from '../../../../../../../query/AdminQuery';
// import DronePhoto from '../../../assets/myorders/Drone_Photo.png';
// import ExtendedPhoto from '../../../assets/myorders/ExtendedPhoto.png';
// import Floorplan from '../../../assets/myorders/Floorplan.png';
// import SocialMedia from '../../../assets/myorders/Social_Media_flims.png';

const { Title } = Typography;
const { TextArea } = Input;

// const servicedata = [
//   {
//     title: 'Social Media Viewing',
//     image: SocialMedia,
//     price: ' 900',
//     tag: '',
//   },
//   {
//     title: 'Extended Photo Package',
//     image: ExtendedPhoto,
//     price: '1 200',
//     tag: '',
//   },
//   {
//     title: '2D / 3D Floorplan',
//     image: Floorplan,
//     price: '0.490',
//     tag: '',
//   },
//   {
//     title: 'Drone Photo',
//     image: DronePhoto,
//     price: '3.000',
//     tag: '',
//   },
//   {
//     title: '360 View',
//     image: DronePhoto,
//     price: '3.000',
//     tag: '',
//   },
// ];

let serviceRefetch;
const ServicesToBuy = () => {
  const [purchaseService, setpurchaseService] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [unUsedserviceList, setUnUsedServiceList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [purchasedTotalAmount, setPurchasedTotalAmount] = useState(28500);
  const [startTime, setStateTime] = useState({});
  const [latestTime, setLatestTime] = useState({});
  const [checkedlist, setCheckedlist] = useState('');
  const [purchasedServices, setPurchasedServices] = useState([]);
  const [additionalServices, setadditionalServices] = useState([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [responsiveModal, setResponsiveModal] = useState('');

  const onFinish = values => {
    console.log('Success:', values, '\nadditionalservices', values.additionalservices);
    // console.log('purchaseService::', servicesToBePurchased);
    // servicesToBePurchased({
    //   variables: {
    //     service_id: values.additionalServices,
    //   },
    // }).then(({ data }) => {
    //   console.log('Data submitted===>>>', data);
    //   if (data && data.purchase_b2b_services && data.purchase_b2b_services.meta) {
    //     notification.success({
    //       message: data.purchase_b2b_services.meta.message,
    //       description: '',
    //       duration: 3,
    //     });
    //   }
    // });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onAdditionalServiceChange = checkedValues => {
    console.log('Checked value', checkedValues);
    const selectedService = serviceList.filter(item => checkedValues.includes(item.id));
    console.log('Selected Services===>>>', selectedService);
    let total = 0;
    selectedService.forEach(item => {
      if (!item.is_free) {
        total += parseInt(item.price, 10);
      }
    });
    total += 35000;
    setTotalAmount(total);
    setSelectedServices(selectedService);
    setCheckedlist(checkedValues);
  };

  let latestDisabledHours = [];
  let earliestDisabledHours = [];

  const setDisabledHours = e => {
    for (let i = 0; i < moment(e).hour(); i += 1) {
      latestDisabledHours.push(i);
    }
    latestDisabledHours = [...new Set(latestDisabledHours)];
    return latestDisabledHours;
  };

  const setEarliestDisabledHours = e => {
    for (let i = 23; i > moment(e).hour(); i -= 1) {
      earliestDisabledHours.push(i);
    }
    console.log(earliestDisabledHours);
    earliestDisabledHours = [...new Set(earliestDisabledHours)];
    return earliestDisabledHours;
  };
  const onremoveSelected = value => {
    console.log('onremove=', value);
    // setCheckedlist(checkedlist.filter(i => i !== value));
    // onAdditionalServiceChange(checkedlist);
  };
  const handleConfirmModalOk = addService => {
    setpurchaseService(false);
    console.log(checkedlist);
    addService({
      variables: {
        service_id: checkedlist, // TODO:Change service id here
        // propertyId: getSelectedProperty().id,
      },
    }).then(({ refetch, data }) => {
      console.log('Data submitted===>>>', data);
      if (data && data.purchase_b2b_services && data.purchase_b2b_services.meta) {
        notification.success({
          message: data.purchase_b2b_services.meta.message,
          description: '',
          duration: 3,
        });
        // serviceRefetch = refetch;
        if (serviceRefetch) {
          serviceRefetch();
        }
      }
    });
  };
  const onRemove = value => {
    setDeleteId(value);
    setDeleteModalVisible(true);
  };
  const handleDeleteModalOk = removeService => {
    setDeleteModalVisible(false);
    removeService({
      variables: {
        service_subscribe_id: deleteId, // TODO:Change service id here
        status: false,
      },
    }).then(({ refetch, data }) => {
      console.log('Data submitted===>>>', data);
      if (data && data.remove_b2b_service && data.remove_b2b_service.meta) {
        notification.success({
          message: data.remove_b2b_service.meta.message,
          description: '',
          duration: 3,
        });
        if (serviceRefetch) {
          serviceRefetch();
        }
      }
    });
  };

  return (
      <>
        <>
          <div className="text-primary main">
            <Row>
              <Col xs={24}>
                <Title level={3} className="text-primary">
                  {formatMessage({
                    id: 'component.b2bservice.purchase.title',
                  })}
                </Title>
                <p className="text-primary">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                  tempor invidunt ut labore et dolore magna aliquyam
                </p>
              </Col>
            </Row>
            <div>
              {/* <p className="label-texts">Print Marketing Services</p>
        <p className="text-small">Lookbook / Sales Task :</p>        */}
              <Row>
                <Col lg={24}>
                  <Query
                      query={ADMIN_OFFICES_B2B_SERIVICES}
                      variables={{
                        office_id: 1,
                        status: 1,
                      }}
                      fetchPolicy="cache-and-network"
                      onCompleted={data => {
                        console.log(
                            'data list',
                            data.office_b2b_services.filter(item => item.office_service !== null),
                        );
                        setServiceList(data.office_b2b_services);
                        const usedServices = data.office_b2b_services.filter(
                            item => item.office_service !== null,
                        );
                        // usedServices.forEach(i=>setPurchasedTotalAmount(purchasedTotalAmount+=i.price))
                        console.log('service->', usedServices);
                        const unUsedServices = data.office_b2b_services.filter(
                            item => item.office_service === null,
                        );
                        setUnUsedServiceList(unUsedServices);
                        data.office_b2b_services.map(i => console.log('used services:', i));
                        // console.log('used services:');
                        setPurchasedServices(usedServices);
                      }}
                  >
                    {({ data, error, loading, refetch }) => {
                      serviceRefetch = refetch;
                      // console.log('Query Called==>>', data, error, loading);
                      if (data && data.office_b2b_services && !loading) {
                        const usedServices = data.office_b2b_services.filter(
                            item => item.is_use === 1,
                        );
                        // console.log('i==>', usedServices);
                        let finalTotal = 0;
                        // purchasedServices.map(i => {
                        //   console.log(finalToal=parseInt(finalToal,10) + parseInt(i.price,10));
                        //   (finalToal = +parseInt(i.price,10))
                        //   // return(purchasedTotalAmount)
                        // });
                        purchasedServices.forEach(item => {
                          if (!item.is_free) {
                            finalTotal += parseInt(item.price, 10);
                          }
                        });
                        // finalTotal += 28500;
                        setPurchasedTotalAmount(finalTotal);
                        // setPurchasedTotalAmount(finalToal)
                        return (
                            <List
                                itemLayout="horizontal"
                                // dataSource={data.purchased_services}
                                dataSource={purchasedServices}
                                renderItem={item => (
                                    <List.Item className="list-item-without-checkbox mb-3 w-100">
                                      <List.Item.Meta
                                          avatar={<img src={`${STORAGE_URL}${item.image}`} alt="avatar" />}
                                          title={
                                            <div className="flex-wrapper space-between">
                                              <div className="flex-wrapper">
                                                <p className="text-medium mb-0 title">{item.name}</p>
                                                {/* <p className="text-medium mb-0 title">Social Media Viewing</p> */}
                                              </div>
                                              <h4 className="text-bold mr-3">
                                                {item.free_text
                                                    ? item.is_free
                                                        ? `${item.free_text}`
                                                        : `Kr ${formatCurrency(Math.round(item.price))},- ${
                                                            item.free_text
                                                            }`
                                                    : `Kr ${formatCurrency(Math.round(item.price))},-`}
                                              </h4>
                                              {/* <h4 className="text-bold">900</h4> */}
                                            </div>
                                          }
                                          description={item.description}
                                          // description="I fastprisen inkluderer vi vanligvis 15 bilder. Dersom du ønsker opptil 30 bilder for bedre presentasjon av boligen din, kan det være lurt å oppgradere."
                                      />
                                    </List.Item>
                                )}
                            />
                        );
                      }
                      return (
                          <div className="loader-wrapper">
                            <LoadingOutlined />
                          </div>
                      );
                    }}
                  </Query>
                </Col>
              </Row>
            </div>
            <Row className="offer-total-section admin-total-section">
              <Col xs={24}>
                {purchasedServices.map(i => (
                    <div className="total-box" key={Math.random()}>
                      {/* {console.log('is_default:>', i)} */}
                      <div>
                        {i.is_default === 0 ? (
                            <>
                              <Mutation mutation={REMOVE_B2BSERVICES}>
                                {(removeService, { loading, data }) => {
                                  if (data) {
                                    // console.log('Data submitted===>>>', data, loading);
                                    if (data && data.remove_b2b_service && data.remove_b2b_service.meta) {
                                      notification.success({
                                        message: data.remove_b2b_service.meta.message,
                                        description: '',
                                        duration: 3,
                                      });
                                      if (serviceRefetch) {
                                        serviceRefetch();
                                      }
                                    }
                                  }
                                  return (
                                      <i
                                          className="iconList-delete icon-order-cancel"
                                          // onClick={value =>
                                          //   removeService({
                                          //     variables: {
                                          //       service_subscribe_id: i.id,
                                          //       status: false,
                                          //     },
                                          //   })
                                          // }
                                          onClick={value => onRemove(i.id)}
                                          value={i.id}
                                      />
                                  );
                                }}
                              </Mutation>
                            </>
                        ) : (
                            ''
                        )}
                        <span>{i.name}</span>
                      </div>
                      <div>{!i.is_free && i.price}</div>
                    </div>
                ))}
                {/* <div className="total-box">
                <div>
                  <i className="iconList-delete icon-order-cancel" />
                  <span>Home sales fixed low price</span>
                </div>
                <div>Kr 35.000,-</div>
              </div> */}
              </Col>
              {/* <Divider style={{ background: '#fff', opacity: '0.5' }} />
            <Col xs={24} >
              <div className="total-box" key={Math.random()}>
                <div>
                  <p className="no-margin text-secondary"> Totalpris</p>
                </div>
                <div>
                  <p className="no-margin text-secondary">Kr. {purchasedTotalAmount},-</p>
                </div>
              </div>
            </Col> */}
            </Row>
          </div>
        </>
        <>
          <div className="text-primary main mt-3">
            <div style={{ marginBottom: '0px' }}>
              {/* <Mutation mutation={SELLER_PHOTO_ORDER}>
            {(orderPhoto, { loading, data }) => {
              if (data) {
                console.log('Data submitted===>>>', data, loading);
                if (data && data.order_photo && data.order_photo.meta) {
                  notification.success({
                    message: data.order_photo.meta.message,
                    description: '',
                    duration: 3,
                  });
                }
              }
              return ( */}
              <Row>
                <Col xs={24}>
                  <Title level={3} className="text-primary">
                    {formatMessage({
                      id: 'component.form.additionalservices',
                    })}
                  </Title>
                  <p className="text-primary">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                    tempor invidunt ut labore et dolore magna aliquyam
                  </p>
                </Col>
              </Row>

              <Form
                  className="user-forms"
                  name="b2bservice"
                  initialValues={{
                    remember: true,
                  }}
                  colon={false}
                  onFinish={values => onFinish(values)}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
              >
                <Row>
                  <Col xs={24} className="checkbox-checklist">
                    {/* <Query
                    query={SELLER_GET_SERVICES}
                    variables={{
                      status: 1,
                      property_id: getSelectedProperty().id,
                      service_type: 'PHOTO',
                    }}
                    onCompleted={data => {
                      setServiceList(data.purchased_services);
                    }}
                  >
                    {({ data, error, loading }) => {
                      console.log('Query Called==>>', data, error, loading);
                      if (data && data.purchased_services && !loading) {
                        return ( */}
                    <Form.Item
                        name="additionalservices"
                        rules={[
                          {
                            required: true,
                            message: `Vennligst velg  ${formatMessage({
                              id: 'component.form.additionalservices',
                            })}!`,
                          },
                        ]}
                    >
                      <Checkbox.Group
                          style={{ width: '100%' }}
                          className="d-none d-lg-block"
                          onChange={onAdditionalServiceChange}
                      >
                        <Row>
                          <Col xs={24}>
                            <List
                                itemLayout="horizontal"
                                // dataSource={data.purchased_services}
                                dataSource={unUsedserviceList}
                                renderItem={item => (
                                    <List.Item className="listview-with-checkbox">
                                      <Checkbox
                                          value={item.id}
                                          checked={
                                            item.usableServiceClass && item.usableServiceClass.length > 0
                                          }
                                          disabled={
                                            item.usableServiceClass && item.usableServiceClass.length > 0
                                          }
                                      >
                                        <List.Item.Meta
                                            avatar={
                                              <img
                                                  src={`${STORAGE_URL}${item.image}`}
                                                  // src={item.image}
                                                  alt="avatar"
                                              />
                                            }
                                            title={
                                              <div className="flex-wrapper space-between">
                                                <div className="flex-wrapper">
                                                  <h4 className="tabs-list-heading">
                                                    {item.name}
                                                    {/* {item.title} */}
                                                  </h4>
                                                </div>
                                                <h4 className="text-bold">
                                                  {item.is_free
                                                      ? item.free_text
                                                      : `Kr ${formatCurrency(Math.round(item.price))},-`}
                                                </h4>
                                              </div>
                                            }
                                            description={item.description}
                                            // description="I fastprisen inkluderer vi vanligvis 15 bilder. Dersom du ønsker opptil 30 bilder for bedre presentasjon av boligen din, kan det være lurt å oppgradere."
                                        />
                                        {/* <div className="price-text">{`Kr ${item.price},-`}</div> */}
                                      </Checkbox>
                                    </List.Item>
                                )}
                            />
                          </Col>
                        </Row>
                      </Checkbox.Group>

                      {/* for mobile responsive */}

                      <Checkbox.Group
                          style={{ width: '100%' }}
                          className="d-lg-none d-block"
                          onChange={onAdditionalServiceChange}
                      >
                        <Row>
                          <Col xs={24}>
                            <List
                                itemLayout="horizontal"
                                // dataSource={data.purchased_services}
                                dataSource={unUsedserviceList}
                                renderItem={item => (
                                    <List.Item className="listview-with-checkbox">
                                      <Checkbox
                                          value={item.id}
                                          checked={
                                            item.usableServiceClass && item.usableServiceClass.length > 0
                                          }
                                          disabled={
                                            item.usableServiceClass && item.usableServiceClass.length > 0
                                          }
                                      >
                                        <List.Item.Meta
                                            avatar={
                                              <img
                                                  src={`${STORAGE_URL}${item.image}`}
                                                  // src={item.image}
                                                  alt="avatar"
                                              />
                                            }
                                            // title={
                                            //   <div className="flex-wrapper space-between">
                                            //     <div className="flex-wrapper">
                                            //       <h4 className="tabs-list-heading">
                                            //         {item.name}
                                            //         {/* {item.title} */}
                                            //       </h4>
                                            //     </div>
                                            //     <h4 className="text-bold">
                                            //       {item.is_free
                                            //         ? item.free_text
                                            //         : `Kr ${formatCurrency(Math.round(item.price))},-`}
                                            //     </h4>
                                            //   </div>
                                            // }
                                            title={
                                              <a href="#" className="text-primary">
                                                {item.name}
                                              </a>
                                            }
                                            description={item.description}
                                            // description="I fastprisen inkluderer vi vanligvis 15 bilder. Dersom du ønsker opptil 30 bilder for bedre presentasjon av boligen din, kan det være lurt å oppgradere."
                                        />
                                        <div className="price-text">
                                          <InfoCircleOutlined onClick={this.showModal} />
                                        </div>
                                      </Checkbox>
                                    </List.Item>
                                )}
                            />
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>

                    {/* Additional service modal */}
                    <Modal
                        visible={responsiveModal}
                        title="Broker On View"
                        onOk={() => setResponsiveModal(false)}
                        onCancel={() => setResponsiveModal(false)}
                        footer={[
                          <Button type="primary" onClick={setResponsiveModal(false)}>
                            Greit
                          </Button>,
                        ]}
                    >
                      <p className="text-large">Kr 1.950,-</p>
                      <p>
                        If you do not want to keep the view yourself, we are happy to help. Price per
                        viewing. Currently only available in Hordaland and Sogn & Fjordane.
                      </p>
                    </Modal>

                    {/* );
                      }
                      return (
                        <div className="loader-wrapper">
                          <LoadingOutlined />
                        </div>
                      );
                    }}
                  </Query> */}
                  </Col>
                </Row>
                {/* {console.log("first:",selectedServices)}
              {selectedServices!==[]?"hello":"byeee"} */}
                <Row className="admin-total-section offer-total-section">
                  <Col xs={24}>
                    {/* <div className="total-box" key={Math.random()}>
                    <div>
                      <i className="iconList-delete icon-order-cancel" />
                      <span>Home sales fixed low price</span>
                    </div>
                    <div>Kr 35.000,-</div>
                  </div> */}
                    {selectedServices &&
                    selectedServices.map(item => (
                        <div className="total-box" key={item.id}>
                          <div>
                            <i
                                className="iconList-delete icon-order-cancel"
                                onClick={value => console.log(item.id)}
                                value={item.id}
                            />
                            <span>{item.name}</span>
                          </div>
                          <div>{item.is_free ? '' : `Kr ${item.price},-`}</div>
                        </div>
                    ))}
                  </Col>
                  <Divider style={{ background: '#fff', opacity: '0.5' }} />
                  <Col xs={24}>
                    <div className="total-box" key={Math.random()}>
                      <div>
                        <p className="text-secondary no-margin"> Totalpris</p>
                      </div>
                      <div>
                        <p className="text-secondary no-margin">{`Kr. ${totalAmount},-`}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={24} style={{ paddingTop: 16 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-secondary buttons admin-forms-btn"
                        size="large"
                        onClick={() => setpurchaseService(true)}
                    >
                      {formatMessage({
                        id: 'component.buyer.purchase',
                      })}
                    </Button>
                    <Button
                        className="text-primary admin-forms-btn"
                        style={{ marginLeft: 8 }}
                        size="large"
                    >
                      <Link to="/selger/salgsforberedelser">
                        {formatMessage({
                          id: 'component.form.cancel',
                        })}
                      </Link>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </>
        <Mutation mutation={SUPERUSER_PURCHASEB2BSERVICES}>
          {(servicesToBePurchased, { loading, data }) => (
              <div className="modalclass">
                <Modal
                    title="Purchase Service"
                    visible={purchaseService}
                    onOk={() => handleConfirmModalOk(false)}
                    onCancel={() => setpurchaseService(false)}
                    footer={[
                      <Button
                          key="submit"
                          type="primary"
                          className="btn-secondary admin-forms-btn"
                          onClick={() => handleConfirmModalOk(servicesToBePurchased)}
                      >
                        Ja
                      </Button>,
                      <Button
                          className="cancelbutton admin-forms-btn"
                          key="back"
                          onClick={() => setpurchaseService(false)}
                      >
                        Nei
                      </Button>,
                    ]}
                >
                  <p>Er du sikker på at du vil bestille denne tjenesten?</p>
                </Modal>
              </div>
          )}
        </Mutation>
        <Mutation mutation={REMOVE_B2BSERVICES}>
          {(servicesToBePurchased, { loading, data }) => (
              <div className="modalclass">
                <Modal
                    title="Purchase Service"
                    visible={deleteModalVisible}
                    onOk={() => handleDeleteModalOk(false)}
                    onCancel={() => setDeleteModalVisible(false)}
                    footer={[
                      <Button
                          key="submit"
                          type="primary"
                          className="btn-secondary admin-forms-btn"
                          onClick={() => handleDeleteModalOk(servicesToBePurchased)}
                      >
                        Ja
                      </Button>,
                      <Button
                          className="cancelbutton admin-forms-btn"
                          key="back"
                          onClick={() => setpurchaseService(false)}
                      >
                        Nei
                      </Button>,
                    ]}
                >
                  <p>Er du sikker på at du vil slette denne tjenesten?</p>
                </Modal>
              </div>
          )}
        </Mutation>
      </>
  );
};
export default ServicesToBuy;
