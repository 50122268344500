import {StyleSheet} from 'react-native';
import {ThemeUtils,Color} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex:1,
        backgroundColor:Color.WHITE,
    },
    contentCont:{

        marginHorizontal: 20,
    },
    titleCont: {
        marginTop:5,
        marginBottom:10,
        flexDirection:'row',
        alignItems:'center'
    },
    lblInst:{
        lineHeight:18,
    },
    displayTimeCont:{
        flex:1,
        marginTop:10,
        flexDirection:'row'
    },
    displayContentCont:{
        flex:1,
        flexDirection:'row'
    },
    accordionHeader:{
        marginBottom: 0,
        backgroundColor: Color.WHITE,
        borderRadius: 0
    },
    accordionContent:{
        marginTop: 0,
        paddingVertical: 2,
        backgroundColor: Color.WHITE,
        borderRadius: 0
    },
    upComingCont:{
        flexDirection:'row',
        alignItems: 'center',
        marginTop:10
    },
    icDrop:{
        paddingHorizontal:10
    }
})
