import React, {useEffect, useRef, useState} from 'react';

import {TouchableOpacity, View} from 'react-native';
import {Label} from "components/src/components";

import PropsType from "prop-types";

import {Color, CommonStyle, Icon, ThemeUtils} from "components/src/utils";
import styles from './styles';


function RadioGroup(props) {

    const {onSelectOption,disabled,changeOnProp, contentStyle, selectedOptions, style, checkedColor, unCheckedColor, small, xsmall, large, options, size, textStyle, ml, mr, mt, mb} = props;
    const index = options.findIndex(item => item.value === selectedOptions)
    const isFirstRun = useRef(true);
    const [selectedIndex, setSelectedIndex] = useState(index !== -1 ? index : 3);

    useEffect(() => {
        if (changeOnProp) {
            console.log("HERE",selectedOptions)
            setSelectedIndex(options.findIndex(item => item.value === selectedOptions || item.id === selectedOptions))
        }
    }, [selectedOptions])

    useEffect(() => {
        if (!isFirstRun.current) {
            onSelectOption && options[selectedIndex] && onSelectOption(options[selectedIndex].value)
        } else{
            isFirstRun.current=false
        }
    }, [selectedIndex]);

    return (
        <View style={[contentStyle, {opacity: disabled ? 0.5 : 1}]}>
            {options.map((item, index) => (
                <TouchableOpacity activeOpacity={1}
                                  style={style}
                                  disabled={disabled}
                                  onPress={() => setSelectedIndex(index)}>
                    <View style={[styles.container]}>
                        <Icon name={index === selectedIndex ? 'radio-select' : 'radio-normal'}
                              size={size}
                              color={index === selectedIndex ? checkedColor : unCheckedColor}/>
                        <Label small={small}
                               mr={15}
                               xsmall={xsmall}
                               large={large}
                               font_regular
                               color={props.textColor}
                               style={[styles.rightText, textStyle]}>
                            {item.name}
                        </Label>
                        {item.icon}
                    </View>
                </TouchableOpacity>
            ))}
        </View>
    )
}

RadioGroup.defaultProps = {
    checkedColor: Color.PRIMARY,
    unCheckedColor: Color.PRIMARY_LIGHT,
    size: ThemeUtils.fontNormal,
    small: true,
    disabled:false,

};

RadioGroup.propTypes = {
    checkedColor: PropsType.string,
    unCheckedColor: PropsType.string,
    size: PropsType.number,
    onSelectOption: PropsType.func,
    textStyle: PropsType.object,
    options: PropsType.array.isRequired,


};

export default RadioGroup
