import React, {useState} from 'react'
//Components
import {ScrollView, TouchableOpacity, View} from 'react-native'
//Third party
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Routes, Strings, ThemeUtils, validation} from "components/src/utils";
import {styles} from './styles';
import {
    CustomButton,
    DatePicker,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label,
    Modal,
    PagerTabIndicator,
    RadioGroup
} from "components/src/components";

const TABS = [{
    text: Strings.details
}, {
    text: Strings.inspection
}];


const PROPERTY_DETAILS = [{
    name: Strings.clientName,
    value: 'Daniel'
}, {
    name: Strings.clientEmail,
    value: 'test@yahoo.com'
}, {
    name: Strings.clientPhone,
    value: '1234567890'
}, {
    name: Strings.agentName,
    value: 'Daniel'
}, {
    name: Strings.propertyaddress,
    value: 'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350'
}];

const options = [{
    id: 1,
    name: Strings.personal,
}, {
    id: 2,
    name: Strings.company,
}];

function PropertyInspectionDetails(props) {
    const {history, navigation} = props;

    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [template, setTemplate] = useState(null);
    const [selectedService, setSelectedService] = useState([]);

    const [editServiceVisible, setEditServiceVisible] = useState(false);
    const [sendOfferVisible, setSendOfferVisible] = useState(false);

    const [serviceName, setServiceName] = useState("");
    const [errServiceName, setErrService] = useState(null);
    const [servicePrice, setServicePrice] = useState("");
    const [errServicePrice, setErrServicePrice] = useState(null);
    const [summary, setSummary] = useState("");
    const [errSummary, setErrSummary] = useState(null);
    const [comment, setComment] = useState("");
    const [errComment, setErrComment] = useState(null);

    const validateSendOffer = () => {

        let errHeading = validation('heading', summary);
        if (errHeading) {
            setErrSummary(errHeading)
        } else {
            //TODO: Make API call here
        }
    };

    const validateEditService = () => {
        let errService = validation('serviceName', serviceName);
        let errPrice = validation('price', servicePrice);

        if (errPrice || errService) {
            setErrService(errService);
            setErrServicePrice(errPrice);

        } else {
            //TODO: Make API call here
        }
    };

    const addOrRemoveService = (index) => {
        let updatedServices = selectedService.slice();
        if (updatedServices.includes(index)) {
            updatedServices.splice(updatedServices.indexOf(index), 1)
        } else {
            updatedServices.push(index)
        }
        setSelectedService(updatedServices)
    };

    const renderLabel = (item, index) => {
        return (
            <View key={index.toString()}
                  style={styles.lblCont}>
                <Label small
                       font_medium
                       mb={5}>
                    {item.name}
                </Label>
                <Label small>
                    {item.value}
                </Label>
            </View>
        )
    };

    const renderSendOfferModal = () => {
        return (
            <Modal visible={sendOfferVisible}
                   onRequestClose={() => setSendOfferVisible(false)}>
                <Header onPressLeft={() => setSendOfferVisible(false)}
                        style={CommonStyle.blankHeader}/>
                <View style={CommonStyle.container}>
                    <ScrollView>
                        <View style={styles.sendOfferCont}>
                            <Label xlarge
                                   font_medium
                                   mb={20}>
                                {Strings.summaryFromBefaring}
                            </Label>
                            <InputField type={'text'}
                                        onChange={setSummary}
                                        value={summary}
                                        error={errSummary}
                                        onFocus={() => setErrSummary(null)}
                                        labelText={Strings.headLine}
                                        placeholder={Strings.headLine}/>
                            <InputField textArea
                                        type={'text'}
                                        onChange={setComment}
                                        value={comment}
                                        labelText={Strings.comments}
                                        placeholder={Strings.writeYourComments}/>
                        </View>
                    </ScrollView>
                </View>
                <View style={styles.btnCont}>
                    <CustomButton title={Strings.submit}
                                  onPress={validateSendOffer}
                                  style={{flex: 1}}
                                  mr={10}/>
                    <CustomButton title={Strings.cancel}
                                  onPress={() => setSendOfferVisible(false)}
                                  bgColor={Color.WHITE}
                                  style={{flex: 1}}
                                  textColor={Color.PRIMARY_TEXT_COLOR}
                                  borderColor={Color.PRIMARY_TEXT_COLOR}
                                  borderWidth={1}
                                  ml={10}/>

                </View>


            </Modal>
        )
    };
    const renderEditServiceModal = () => {
        return (
            <Modal visible={editServiceVisible}
                   onRequestClose={() => setEditServiceVisible(false)}>
                <Header onPressLeft={() => setEditServiceVisible(false)}
                        style={CommonStyle.blankHeader}/>
                <View style={CommonStyle.container}>
                    <ScrollView>
                        <View style={styles.sendOfferCont}>
                            <Label xlarge
                                   font_medium
                                   mb={20}>
                                {Strings.editService}
                            </Label>
                            <InputField type={'text'}
                                        onChange={setServiceName}
                                        value={serviceName}
                                        error={errServiceName}
                                        onFocus={() => setErrService(null)}
                                        labelText={Strings.serviceName}
                                        placeholder={Strings.serviceName}/>
                            <InputField type={'text'}
                                        onChange={setServicePrice}
                                        value={servicePrice}
                                        error={errServicePrice}
                                        onFocus={() => setErrServicePrice(null)}
                                        labelText={Strings.servicePrice}
                                        placeholder={Strings.servicePrice}/>
                        </View>
                    </ScrollView>
                </View>
                <View style={styles.btnCont}>
                    <CustomButton title={Strings.submit}
                                  onPress={validateEditService}
                                  style={{flex: 1}}
                                  mr={10}/>
                    <CustomButton title={Strings.cancel}
                                  onPress={() => setEditServiceVisible(false)}
                                  bgColor={Color.WHITE}
                                  style={{flex: 1}}
                                  textColor={Color.PRIMARY_TEXT_COLOR}
                                  borderColor={Color.PRIMARY_TEXT_COLOR}
                                  borderWidth={1}
                                  ml={10}/>

                </View>


            </Modal>
        )
    };

    const renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>
    };

    const renderServices = (item, index) => {
        return (
            <>
                <View key={index.toString()}
                      onPress={() => addOrRemoveService(index)}
                      style={styles.utlegCont}>
                    <TouchableOpacity activeOpacity={0.5}
                                      onPress={() => addOrRemoveService(index)}>
                        <Icon name={selectedService.includes(index) ? 'tickmark' : 'radio-normal'}
                              size={ThemeUtils.fontLarge}
                              color={selectedService.includes(index) ? Color.PRIMARY : Color.DARK_GRAY}/>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.5}
                                      onPress={() => setEditServiceVisible(true)}
                                      style={styles.serviceLblCont}>
                        <Label small
                               font_medium
                               mb={5}>
                            {`Service Name ${index}`}
                        </Label>
                        <Label small>
                            {'Kr 8.000,-'}
                        </Label>
                    </TouchableOpacity>
                    <Icon name={'drop-normal'}
                          color={Color.DARK_GRAY}/>
                </View>
                <Hr/>
            </>
        )
    };

    return (
        <View style={styles.container}>
            {renderSendOfferModal()}
            {renderEditServiceModal()}
            <Header navigation={IS_WEB ? history : navigation}
                    style={CommonStyle.blankHeader}/>

            <Label xlarge
                   font_medium
                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                {Strings.propertyDetails}
            </Label>
            <IndicatorViewPager style={CommonStyle.flex}
                                indicator={renderTabIndicator()}>

                <View>
                    <ScrollView>
                        <View style={styles.propertyDetailsCont}>
                            {PROPERTY_DETAILS.map(renderLabel)}
                        </View>
                    </ScrollView>
                </View>

                <View>
                    <ScrollView>
                        <View>
                            <View style={styles.inspectionDetailsCont}>
                                <DatePicker title={Strings.date}
                                            onSelectDate={setDate}/>

                                <DatePicker title={Strings.startTime}
                                            onSelectDate={setTime}
                                            mode={'time'}/>

                                <Label small
                                       mt={15}>
                                    {Strings.inspectionDetailsInst}
                                </Label>
                                <CustomButton mt={10}
                                              title={Strings.sendInspectionConfirmation}/>
                                <Label small
                                       font_medium
                                       mt={20}
                                       mb={10}>
                                    {Strings.chooseInpectionTemplate}
                                </Label>
                                <RadioGroup onSelectOption={setTemplate}
                                            contentStyle={{flexDirection: 'row', marginBottom: 20}}
                                            options={options}/>

                            </View>
                            <Hr/>
                            <View>
                                <Label small
                                       font_medium
                                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                       mt={15}
                                       mb={10}>
                                    {Strings.chooseInpectionTemplate}
                                </Label>
                                {new Array(5).fill(1).map(renderServices)}
                            </View>
                            <View style={styles.btnCont}>
                                <CustomButton title={Strings.startInspection}
                                              onPress={() => IS_WEB ? history.push(Routes.StartInspection) : navigation.navigate(Routes.StartInspection)}
                                              style={{flex: 1}}
                                              mr={10}/>
                                <CustomButton title={Strings.sendOffer}
                                              onPress={() => setSendOfferVisible(true)}
                                              bgColor={Color.WHITE}
                                              style={{flex: 1}}
                                              textColor={Color.PRIMARY_TEXT_COLOR}
                                              borderColor={Color.PRIMARY_TEXT_COLOR}
                                              borderWidth={1}
                                              ml={10}/>

                            </View>
                        </View>
                    </ScrollView>
                </View>
            </IndicatorViewPager>
        </View>
    )
}


const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PropertyInspectionDetails);
