import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';
import {Label} from '../..';

import {Color, CommonStyle, Icon, ThemeUtils} from '../../../utils';

const CostingAddServiceView = (props) => {

    const {color, iconColor} = props;
    const onPress = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    const getStyleArray = () => {
        let stylesArray = [];

        stylesArray.push({
            marginTop: props.mt,
            marginBottom: props.mb,
            marginLeft: props.ml,
            marginRight: props.mr,

            width: '100%',
            backgroundColor: Color.TRANSPARENT
        });

        return stylesArray;
    }

    return (
        <View style={[getStyleArray(),
            styles.mainContainer, props.style]}>
            {!props.isTotalAmount &&
            props.isDeletable ?
                (<TouchableOpacity
                    style={styles.iconContainer}
                    onPress={props.onDelete}>
                    <Icon
                        size={ThemeUtils.fontNormal}
                        name={'order-cancel'}
                        color={iconColor}/>
                </TouchableOpacity>) : null}
            <View style={styles.detailsContainer}>
                <View style={styles.titleContainer}>
                    <Label font_medium={props.isTotalAmount}
                           small
                           style={CommonStyle.flex}
                           color={color}
                           align={'left'}>{props.title}</Label>
                </View>
                <View style={styles.priceContainer}>
                    <Label font_medium={props.isTotalAmount}
                           bold={props.isTotalAmount}
                           small
                           color={color}
                           align={'right'}>
                        {props.price}
                    </Label>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'space-between',
        paddingHorizontal: 14,
    },

    iconContainer: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginEnd: 10
    },

    detailsContainer: {
        // backgroundColor: '#00f',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',

    },

    titleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },

    priceContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignContent: 'flex-start'
    }
});

CostingAddServiceView.defaultProps = {
    isTotalAmount: false,
    color: Color.PRIMARY,
    iconColor: Color.PRIMARY_DARK,
    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
    isDeletable:true
};

CostingAddServiceView.propTypes = {
    isTotalAmount: PropTypes.bool,
    isDeletable: PropTypes.bool,
    color: PropTypes.string,
    iconColor: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    mt: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
    mr: PropTypes.number,
};

export default CostingAddServiceView;
