import {StyleSheet} from 'react-native';
import {Color} from '../../utils';

const containerHorizontalMargin = 20;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    titleCont: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },

    fieldContainer: {
        alignContent: 'center',
    }
});
export {styles};
