export default {
  'component.photographer.title': 'Fotografers',
  'component.photographer.description': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit',
  'component.photographer.addphotographer': 'Legg til fotograf',
  'component.photographer.editphotographer': 'Rediger fotograf',
  'component.photographer.assignedzipcodes': 'Tildelte postnumre',
  'component.photographer.tablesearch':
    'Søk etter navn, e-post, telefon, eiendomsadresse og postnummer',
  'component.photographer.deletemodal.title': 'Slette Photo',
  'component.photographer.deletemodal.text': 'Er du sikker på at du vil slette dette dildet?',
};
