import gql from 'graphql-tag';


export const PHOTOGRAPHER_UPDATE_PROFILE = gql`
mutation updatePhotgrapherProfile($input: UpdateProfileInput) {
  update_profile(input: $input) {
    user {
      id
      user_type_id
      first_name
      last_name
      email
      title
      address
      mobile
      profile_image
      city
      zipcode
      status

      access_level
    }
  }
}`;

export const ATTACH_VIDEO_LINK = gql`
mutation attachVideoLink($input: AttachLinkInput!) {
  attach_video_link(input: $input) {
    attach_video {
      id
      property_id
      video_url
    }
  }
}
`
export const ATTACH_360_LINK = gql`
mutation attach360Link($input: AttachLinkInput!) {
  attach_360_view_link(input: $input) {
    attach_video {
      id
      order_photo_id
      view_360_url
    }
  }
}`;

export const UPLOAD_PHOTO = gql`
mutation uploadPhoto($photo: [Upload!], $photoOrderId: Int) {
  upload_photo(photo: $photo, photo_order_id: $photoOrderId) {
    photos {
      id
      order_photo_id
    }
  }
}`;

export const PHOTO_UPDATE_ORDER = gql`
  mutation update_photo_order($input: OrderUpdateInput) {
    update_photo_order(input: $input) {
      order {
        id
        property_id
        photo_id
        order_date
        earliest_start_time
        latest_start_time
        comment
        order_status
        property {
          id
          seller_id
          oppdrags_nummer
          street_adress
          city
          zipcode
          seller{
            id
            first_name
            last_name
            email
            mobile
          }
        }
        photo {
          id
          user_type_id
          first_name
          last_name
          email
          title
          address
          mobile
          profile_image
          city
          zipcode
          status
          access_level
        }
      }
    }
  }
`;


export const DELETE_PHOTOS = gql`
mutation deletePhoto($ids:[Int!]!){
  delete_photo(ids:$ids){
    meta{
      status
      message
      code
    }
  }
}`

export const REPLACE_PHOTO = gql`
mutation replacePhoto($image:Upload!,$orderId:Int!,$id:Int!){
  replace_photo(photo:$image,photo_order_id:$orderId,photo_id:$id){
    photo{
      id
      order_photo_id
    }
  }
}`
