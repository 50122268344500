import {StyleSheet} from 'react-native'
import {ThemeUtils} from "components/src/utils";


export default StyleSheet.create({
    notiCont: {
        flexDirection: 'row',
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: ThemeUtils.relativeRealHeight(2)
    },
    icCont: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginTop: 19
    },
    dot: {
        marginTop: 5,
        marginEnd: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        borderRadius: 4,
        height: 8,
        width: 8
    }
})
