import React from 'react'
//Components
import {Image, ScrollView, View} from 'react-native'
import {CustomButton, FilePicker, Hr, InputField, Label} from "components/src/components";

import Provider from "@ant-design/react-native/lib/provider";
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation} from "@apollo/react-components";
import {CHANGE_PROFILE_PIC} from "../../../../api/mutation";
//Utils
import {Color, Constants, Icon, IS_WEB, Routes, Strings, ThemeUtils, validation} from "components/src/utils";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import {styles} from './styles.js';
import {BUYER_UPDATE_PROFILE} from "../../../../api/buyerMutation";


const INPUTS = [{
    id: Constants.FieldId.FIRST_NAME,
    title: Strings.firstName,
    placeholder: Strings.enterFirstName,
    type: 'text',
    validationType: 'firstName'
}, {
    id: Constants.FieldId.LAST_NAME,
    title: Strings.lastName,
    placeholder: Strings.enterLastName,
    type: 'text',
    validationType: 'lastName'
}, {
    id: Constants.FieldId.EMAIL,
    title: Strings.email,
    placeholder: Strings.enterEmail,
    type: 'email',
    editable: false,
    validationType: 'email'
}, {
    id: Constants.FieldId.PHONE,
    title: Strings.phone,
    placeholder: Strings.enterPhone,
    type: 'number',
    validationType: 'phoneNo'
}, {
    id: Constants.FieldId.ADDRESS,
    title: Strings.address,
    placeholder: Strings.enterAddress,
    type: 'textArea',
    validationType: 'address'
}, {
    id: Constants.FieldId.CITY,
    title: Strings.city,
    placeholder: Strings.enterCity,
    type: 'text',
    validationType: 'city'
}, {
    id: Constants.FieldId.ZIP_CODE,
    title: Strings.zipcode,
    placeholder: Strings.enterZipCode,
    type: 'number',
    validationType: 'zipCode'
},];

class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: undefined,
            values: {
                [Constants.FieldId.FIRST_NAME]: props.user?.first_name ?? '',
                [Constants.FieldId.LAST_NAME]: props.user?.last_name ?? '',
                [Constants.FieldId.EMAIL]: props.user?.email ?? '',
                [Constants.FieldId.PHONE]: props.user?.mobile ?? '',
                [Constants.FieldId.ADDRESS]: props.user?.address ?? '',
                [Constants.FieldId.CITY]: props.user?.city ?? '',
                [Constants.FieldId.ZIP_CODE]: props.user?.zipcode ?? '',
            },
            errors: {}

        }
    }

    handleChangeText = (text, fieldId) => {
        let values = {...this.state.values};
        values[fieldId] = text;
        this.setState({values})
    };

    updateProfile = (changeProfile) => {
        const {values} = this.state;
        const errors = {...this.state.errors};
        let isError = false;
        for (let i = 0; i < INPUTS.length; i++) {
            let item = INPUTS[i];
            if (item.id === Constants.FieldId.PHONE) {
                continue;
            }
            errors[item.id] = validation(item.validationType, values[item.id] ? values[item.id].trim() : '');
            if (errors[item.id]) {
                isError = true;
            }
        }
        if (isError) {
            this.setState({errors})
        } else {
            changeProfile({
                variables: {
                    input: {
                        first_name: values[Constants.FieldId.FIRST_NAME],
                        last_name: values[Constants.FieldId.LAST_NAME],
                        address: values[Constants.FieldId.ADDRESS],
                        city: values[Constants.FieldId.CITY],
                        zipcode: values[Constants.FieldId.ZIP_CODE],
                        mobile: this.props.user.mobile ?? "1234567891"
                    }
                }

            })
        }
    };

    onFocus = (id) => {
        let errors = {...this.state.errors};
        errors[id] = null;
        this.setState({
            errors
        })
    };

    renderInput = (item, index) => {
        const {errors, values} = this.state;

        return (
            <InputField key={item.id.toString()}
                        mt={index === 0 ? 20 : 0}
                        textArea={item.type === 'textArea'}
                        type={item.type}
                        labelProps={{
                            font_medium: true,
                        }}
                        editable={item.editable}
                        onChange={(text) => this.handleChangeText(text, item.id)}
                        onFocus={() => this.onFocus(item.id)}
                        error={errors[item.id]}
                        value={values[item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };

    myProfilechangedpassword = () => {
        if (IS_WEB) {
            this.props.history.push(Routes.ChangePassword)
        } else {
            this.props.navigation.navigate(Routes.ChangePassword);
        }
    };

    render() {
        return (
            <Provider locale={enUS}>
                <ScrollView>

                    <View style={styles.container}>
                        <Mutation mutation={CHANGE_PROFILE_PIC}
                                  onCompleted={(data) => {
                                      let updatedUser = {...this.props.user};
                                      updatedUser.profile_image = data?.change_profile_pic
                                      this.props.setUser(updatedUser)
                                  }}>
                            {(changeProfilePic, {loading}) => (
                                <View style={styles.topContainer}>
                                    <View style={styles.labeliconContainer}>
                                        <Label large
                                               font_medium
                                               align={'left'}>{Strings.ProfilePicture}</Label>

                                        <FilePicker isCameraOptionRequired
                                                    onFilePicked={(data, file) => {
                                            changeProfilePic({
                                                variables: {
                                                    file: file[0]
                                                }
                                            })
                                        }}>

                                            <Icon
                                                size={ThemeUtils.fontLarge}
                                                name={'edit'}
                                                color={Color.EDIT_ICON}/>
                                        </FilePicker>

                                    </View>
                                    <View style={styles.labeliconContainer}>

                                        <Image style={styles.imageProfile}
                                               source={{uri: `${Constants.APIConfig.STORAGE_URL}${this.props.user?.profile_image}`}}/>

                                    </View>
                                    <CustomButton
                                        mt={20}
                                        mb={18}
                                        title={Strings.changePassword}
                                        onPress={() => {
                                            this.myProfilechangedpassword()
                                        }}/>

                                </View>
                            )}

                        </Mutation>
                        <Hr/>
                        <View style={styles.topContainer}>

                            {INPUTS.map(this.renderInput)}
                            <Mutation mutation={BUYER_UPDATE_PROFILE}>
                                {(changeProfile, {loading, error, data}) => (
                                    <CustomButton loading={loading}
                                                  mt={20}
                                                  mb={10}
                                                  onPress={() => this.updateProfile(changeProfile)}
                                                  title={Strings.Save}/>
                                )}

                            </Mutation>

                        </View>

                    </View>
                </ScrollView>
            </Provider>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        token: state.token,
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
