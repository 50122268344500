import React, {Component} from 'react';

import {Animated, FlatList, TouchableOpacity, View} from 'react-native';
//Third Party
import PropTypes from 'prop-types';
//Custom Components
import {Label, Modal, Ripple} from 'components/src/components';
//Utils
import {Color, CommonStyle, Icon, Strings, ThemeUtils} from 'components/src/utils';
import styles from './styles';

class DropDown extends Component {

    constructor(props) {
        super(props);
        let selectedIndex = props.options.findIndex(item => item.value === props.selectedOption || item.id===props.selectedOption)
        this.state = {
            selectedOption: selectedIndex !== -1 ? selectedIndex : null,
            visible: false
        };
        this.animatedHeight = new Animated.Value(0);
    }

    componentDidUpdate(prevProps) {
console.log(prevProps.selectedOption,this.props.selectedOption,"ChANGE")
        if (this.props.changeOnProp && prevProps.selectedOption !== this.props.selectedOption) {
            let selectedIndex = this.props.options.findIndex(item => item.value === this.props.selectedOption)
            this.setState({
                selectedIndex: selectedIndex
            })
        }

    }


    //utility
    startAnimation = () => {
        Animated.timing(this.animatedHeight, {
            toValue: this.state.height + (ThemeUtils.isTablet() ? 55 : 50),
            duration: 200,
        }).start();
    };

    handleHardwareBack = () => {
        this.closePopUp();
        return true;
    };

    //User interaction
    closePopUp = (isSaved) => {
        Animated.timing(this.animatedHeight, {
            toValue: 0,
            duration: 200,

        }).start(() => {
            this.setState({
                visible: false
            }, () => {
                this.state.selectedOption != null && this.props.onSelectOptions(this.props.options[this.state.selectedOption].value ? this.props.options[this.state.selectedOption].value : this.props.options[this.state.selectedOption].id)
            })
        });
    };

    onSortOptionSelected = (item, index) => {
        this.setState({
            selectedOption: index,
        });
    };

    renderItem = ({item, index}) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              onLayout={e => {
                                  if (!this.state.height) {
                                      this.setState({
                                          height: e.nativeEvent.layout.height * this.props.options.length
                                      }, () => this.startAnimation())
                                  }
                              }}
                              style={[styles.itemCont, this.state.selectedOption === index ? styles.selectedItem : null]}
                              onPress={() => this.onSortOptionSelected(item, index)}
                              key={index.toString()}>
                <Label small
                       font_regular
                       style={CommonStyle.flex}>
                    {item.name}
                </Label>
                {this.state.selectedOption === index &&
                <Icon name={'tick-simple'}
                      size={12}
                      color={Color.LIGHT_BLUE}/>
                }
            </TouchableOpacity>
        );
    };


    render() {
        const {options, title, style, placeholder} = this.props;
        return (
            <View style={style}>
                {title ? (<Label small
                                 font_medium
                                 mt={5}
                                 mb={10}>
                    {title}
                </Label>) : null}
                <Ripple style={styles.dropDownCont}
                        onPress={() => this.setState({visible: true}, this.state.height ? this.startAnimation : null)}>
                    <Label small
                           mt={2}
                           font_regular
                           style={CommonStyle.flex}
                           color={this.state.selectedOption !== null ? Color.PRIMARY : Color.SECONDARY_TEXT_COLOR}>
                        {this.state.selectedOption !== null ? options[this.state.selectedOption].name : placeholder}
                    </Label>
                    <Icon name={'drop-down'}
                          size={12}
                          color={Color.PRIMARY}/>
                </Ripple>
                <Modal transparent
                       hardwareAccelerated
                       visible={this.state.visible}
                       onRequestClose={this.handleHardwareBack}>
                    <View style={{flex: 1, justifyContent: 'center'}}>
                        <TouchableOpacity activeOpacity={1}
                                          onPress={this.handleHardwareBack}
                                          style={styles.container}/>
                        <Animated.View style={[styles.bottomViewContainer,
                            {height: this.animatedHeight,}]}>
                            <View style={styles.titleContainer}>
                                <Label small
                                       font_regular>
                                    {this.props.title}
                                </Label>
                                <TouchableOpacity activeOpacity={0.5}
                                                  onPress={() => this.closePopUp(true)}>
                                    <Label small
                                           font_regular
                                           color={Color.LIGHT_BLUE}>
                                        {Strings.done}
                                    </Label>
                                </TouchableOpacity>
                            </View>
                            <FlatList
                                extraData={this.state}
                                data={options}
                                renderItem={this.renderItem}
                                keyExtractor={item => item}
                            />
                        </Animated.View>
                    </View>

                </Modal>
            </View>
        );
    }
}

DropDown.defaultProps = {
    placeholder: Strings.selectOptions,
    sortOptions: [],
    options: 0,
    onSelectOptions: null,
};

DropDown.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    selectedOption: PropTypes.number,
    options: PropTypes.array,
    onSelectOptions: PropTypes.func,
};

export default DropDown;
