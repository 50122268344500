export default {
  'component.digitalsalesassignment.title': 'Digital salgsoppgave',
  'component.digitalsalesassignment.description':
    'Her kan du se og godkjenne utkastet til digital salgsoppgave. Hvis du har kommentarer, send dem til megleren nedenfor.',
  'component.digitalsalesassignment.button': 'Se digital salgsoppgave',
  'component.ds.view.signup.title': 'Meld deg på visning',
  'component.ds.view.signup.description':
    'Hvis du planlegger å delta på en av de planlagte visningene, må du gi beskjed her her, slik at vi kan planlegge visningen optimalt i forhold til antall besøkende slik at alle får god oppfølging.',
  'component.ds.view.signup.formtitle': 'Skriv inn kontaktinformasjonen din',
  'component.ds.view.signup.checkbox1':
    'Jeg ønsker at dere kontakter meg for tidspunkt om privatvisning.',
  'component.ds.view.signup.checkbox2': 'Jeg planlegger å delta på de oppsatte fellesvisningene',
  'component.ds.view.signup.checkbox3':
    'Hold meg oppdatert vedrørende bud eller annen relevant informasjon på denne boligen.',
  'component.ds.sales.assignment.title': 'Salgsoppgave PDF',
  'component.ds.sales.assignment.description':
    'Vær oppmerksom på at salgsoppgaven åpner seg i nytt vindu. Dersom du har blokkert pop-up vinduer vil den ikke åpne seg.',
  'component.ds.sales.assignment.formtitle': 'Skriv inn kontaktinformasjonen din',
  'component.ds.sales.assignment.checkbox1': 'Send meg informasjon om denne eiendommen.',
  'component.ds.sales.assignment.checkbox2': 'Budvarsel på SMS.',
  'component.ds.sales.assignment.checkbox3': 'Bli oppdatert på lignende hjem til salgs.',
  'component.ds.sales.assignment.checkbox4': 'Motta vårt nyhetsbrev.',
  'component.ds.get.updated.title': 'Bli oppdatert',
  'component.ds.get.updated.description': 'Fyll ut skjemaet nedenfor, så holder vi deg oppdatert.',
  'component.ds.contact.broker.title': 'Har du noen spørsmål? Kontakt megler',
};
