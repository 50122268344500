/* eslint-disable no-shadow */
import { Button, Col, Form, Input, Row, Typography, Select, Modal, notification } from 'antd';
import React, { useEffect,useState } from 'react';
import { Link,useHistory } from 'react-router-dom';
import { formatMessage } from 'components/src/utils';
import './addmanagevendors.scss';
import { CaretDownFilled, LoadingOutlined } from '@ant-design/icons';
import { Mutation, Query } from 'react-apollo';
import { ADMIN_CREATE_MOOVING_VENDORS } from '../../../../../../mutation/AdminMutation';
import {
  ADMIN_ZIPCODE_LIST,
  ADMIN_MOOVIN_GUIDE_MANAGE_LINKS,
  ADMIN_COMPANY_TYPE_LIST,
} from '../../../../../../query/AdminQuery';
import { USER_TYPE_SERVER } from '../../../../../utils/constant';
// import { from } from 'apollo-link';

const { Title } = Typography;

const companytypeoptionlist = [
  { label: 'Mortgage', value: 1 },
  { label: 'Insurance', value: 2 },
  { label: 'Home security', value: 3 },
  { label: 'TV / Cable', value: 4 },
  { label: 'Cleaning', value: 5 },
  { label: 'Moving', value: 6 },
  { label: 'Sunscreening', value: 7 },
  { label: 'Phone', value: 8 },
  { label: 'Varmepumper', value: 9 },
  { label: 'Short term loans', value: 10 },
];
// const zipcodesdata = [
//   { value: '0182' },
//   { value: '0235' },
//   { value: '0178' },
//   { value: '0188' },
//   { value: '1350' },
//   { value: '0183' },
//   { value: '4730' },
//   { value: '4457' },
//   { value: '5073' },
//   { value: '1024' },
//   { value: '2058' },
//   { value: '0048' },
//   { value: '1044' },
//   { value: '0154' },
//   { value: '0882' },
//   { value: '4288' },
//   { value: '4115' },
//   { value: '0015' },
// ];

const serviceoptionlist = [
  { label: 'Moovin Agency', value: 1 },
  { label: 'Book the Internet', value: 2 },
  { label: 'Order Tv Package', value: 3 },
  { label: 'Order A Home Alarm', value: 4 },
];
let history;

const Addmanagevendors = () => {
  let router=useHistory();
  const options = [];

  const [addVendorSuccessful, setaddVendorSuccessful] = useState(false);

  const onFinish = (values, createMangeVendors) => {
    console.log('Success:', values);
    createMangeVendors({
      variables: {
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        phone: values.phone,
        company_name: values.companyname,
        company_type_id: values.companytype,
        assigned_zipcode: values.assignedzipcodes,
        service_type_id: values.service,
      },
    }).then(data => {
      console.log('Data submitted===>>>', data);
      if (data && data.data.create_moving_vendor && data.data.create_moving_vendor.meta) {
        notification.success({
          message: data.data.create_moving_vendor.meta.message,
          description: '',
          duration: 3,
        });
        setaddVendorSuccessful(true);
      }
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const goBackfun = () => {
    setaddVendorSuccessful(false);
    router.goBack();
  };

  return (
      <div className="contentshadow">
        <div className="text-primary main">
          <Row>
            <Col xs={24}>
              <Title level={3} className="text-primary">
                {formatMessage({
                  id: 'component.managevendors.addmanagevendors',
                })}
              </Title>
              <p>
                Her registerer du leverandører. Husk å legge til de postnummer de skal få henvendelser
                tilsendt fra. Det er kun mulig med en leverandør for hvert postnummer.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Mutation mutation={ADMIN_CREATE_MOOVING_VENDORS}>
                {createMangeVendors => {
                  // if
                  return (
                      <Form
                          layout="vertical"
                          className="user-forms"
                          name="managevendors"
                          initialValues={{
                            remember: true,
                          }}
                          colon={false}
                          onFinish={values => onFinish(values, createMangeVendors)}
                          onFinishFailed={onFinishFailed}
                      >
                        <Row gutter={16}>
                          <Col xs={24} lg={8}>
                            <Form.Item
                                name="firstname"
                                label={formatMessage({
                                  id: 'component.form.firstname',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Vennligst fyll inn fornavn',
                                  },
                                ]}
                            >
                              <Input
                                  placeholder={`Angi ${formatMessage({
                                    id: 'component.form.firstname',
                                  }).toLowerCase()}`}
                                  size="large"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={8}>
                            <Form.Item
                                name="lastname"
                                label={formatMessage({
                                  id: 'component.form.lastname',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Vennligst fyll inn etternavn',
                                  },
                                ]}
                            >
                              <Input
                                  placeholder={`Angi ${formatMessage({
                                    id: 'component.form.lastname',
                                  }).toLowerCase()}`}
                                  size="large"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} lg={8}>
                            <Form.Item
                                name="email"
                                label={formatMessage({
                                  id: 'component.form.email',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Vennligst fyll inn epost',
                                  },
                                ]}
                            >
                              <Input
                                  placeholder={`Angi ${formatMessage({
                                    id: 'component.form.email',
                                  }).toLowerCase()}`}
                                  size="large"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={8}>
                            <Form.Item
                                name="phone"
                                label={formatMessage({
                                  id: 'component.form.phone',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Vennligst fyll inn telefon',
                                  },
                                ]}
                            >
                              <Input
                                  placeholder={`Angi ${formatMessage({
                                    id: 'component.form.phone',
                                  }).toLowerCase()}`}
                                  size="large"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} lg={8}>
                            <Form.Item
                                name="companyname"
                                label={formatMessage({
                                  id: 'component.managevendors.companyname',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Vennligst angi selskapsnavn',
                                  },
                                ]}
                            >
                              <Input
                                  placeholder={`${formatMessage({
                                    id: 'component.managevendors.companyname',
                                  })}`}
                                  size="large"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={8}>
                            <Query
                                query={ADMIN_COMPANY_TYPE_LIST}
                                variables={{
                                  status: 1,
                                }}
                            >
                              {({ data, loading }) => {
                                if (data && !loading) {
                                  if (data && data.company_type_list) {
                                    console.log('insodwe;', data.company_type_list);
                                    return (
                                        <>
                                          <Form.Item
                                              name="companytype"
                                              label={formatMessage({
                                                id: 'component.managevendors.companytype',
                                              })}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: `Vennligst velg ${formatMessage({
                                                    id: 'component.managevendors.companytype',
                                                  }).toLowerCase()}`,
                                                },
                                              ]}
                                          >
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder={`${formatMessage({
                                                  id: 'component.managevendors.companytype',
                                                })}`}
                                                size="large"
                                                suffixIcon={<CaretDownFilled className="caret-style" />}
                                                className="pickerwidth"
                                            >
                                              {data?.company_type_list?.map(i => (
                                                  <Select.Option value={i.id}>{i.name}</Select.Option>
                                              ))}
                                            </Select>
                                          </Form.Item>
                                        </>
                                    );
                                  }
                                  return <div>{formatMessage({ id: 'component.nodata' })}</div>;
                                }
                                return (
                                    <div className="loader-wrapper-inline">
                                      <LoadingOutlined />
                                    </div>
                                );
                              }}
                            </Query>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} lg={16}>
                            <Query
                                query={ADMIN_MOOVIN_GUIDE_MANAGE_LINKS}
                                variables={{
                                  status: 1,
                                }}
                            >
                              {({ data }) => {
                                if (data && data.manage_link_moving_guide_list) {
                                  console.log('insodwe;', data.manage_link_moving_guide_list);
                                  return (
                                      <>
                                        <Form.Item
                                            name="service"
                                            label={formatMessage({
                                              id: 'component.managevendors.service',
                                            })}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Vennligst oppgi hvilke tjenester som leveres',
                                              },
                                            ]}
                                        >
                                          <Select
                                              style={{ width: '100%' }}
                                              placeholder={`Velg ${formatMessage({
                                                id: 'component.managevendors.service',
                                              })}`}
                                              size="large"
                                              suffixIcon={<CaretDownFilled className="caret-style" />}
                                              className="pickerwidth"
                                          >
                                            {data?.manage_link_moving_guide_list?.filter(j=>j.is_service===1).map(i => (
                                                <Select.Option value={i.id}>{i.name}</Select.Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </>
                                  );
                                }
                                return (
                                    <div className="loader-wrapper-inline">
                                      <LoadingOutlined />
                                    </div>
                                );
                              }}
                            </Query>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} lg={16}>
                            <Query
                                query={ADMIN_ZIPCODE_LIST}
                                variables={{
                                  status: 1,
                                  user_type_id: USER_TYPE_SERVER.ADMIN,
                                  user_id: 1,
                                }}
                            >
                              {({ data }) => {
                                if (data && data.zipcode_list) {
                                  console.log('insodwe;', data.zipcode_list);
                                  data.zipcode_list.map(i => options.push(i.postnummer));
                                  return (
                                      <>
                                        <Form.Item
                                            name="assignedzipcodes"
                                            label={formatMessage({
                                              id: 'component.form.assignedzipcode',
                                            })}
                                            rules={[
                                              {
                                                required: true,
                                                message: `Vennligst fyll inn ${formatMessage({
                                                  id: 'component.form.zipcode',
                                                }).toLowerCase()}`,
                                              },
                                            ]}
                                        >
                                          <Select
                                              mode="multiple"

                                              size="large"
                                              style={{ width: '100%' }}
                                              placeholder={`Velg ${formatMessage({
                                                id: 'component.form.zipcode',
                                              }).toLowerCase()}`}
                                          >
                                            {data.zipcode_list.map(i => (
                                                <Select.Option value={i.postnummer}>
                                                  {i.postnummer}
                                                </Select.Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </>
                                  );
                                }
                                return (
                                    <div className="loader-wrapper-inline">
                                      <LoadingOutlined />
                                    </div>
                                );
                              }}
                            </Query>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btn-secondary buttons admin-forms-btn"
                                size="large"
                            >
                              {formatMessage({
                                id: 'component.form.add',
                              })}
                            </Button>
                            <Button
                                className="text-primary admin-forms-btn"
                                style={{ marginLeft: 8 }}
                                size="large"
                                onClick={router.goBack}
                            >
                              {formatMessage({
                                id: 'component.form.cancel',
                              })}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                  );
                }}
              </Mutation>
            </Col>
          </Row>
          <Modal
              centered
              title={formatMessage({
                id: 'component.managevendors.modal.title',
              })}
              visible={addVendorSuccessful}
              onOk={() => setaddVendorSuccessful(false)}
              onCancel={() => [
                setaddVendorSuccessful(false),
                router.push('/admin/administrereleverandører'),
              ]}
              width="450px"
              footer={[
                <Link to="/admin/administrereleverandører">
                  <Button
                      key="submit"
                      type="primary"
                      className="admin-forms-btn  mr_15"
                      size="large"
                      onClick={() => goBackfun}
                  >
                    {formatMessage({
                      id: 'component.form.okay',
                    })}
                  </Button>
                </Link>,
              ]}
          >
            <p>
              {formatMessage({
                id: 'component.managevendors.modal.text',
              })}
            </p>
          </Modal>
        </div>
      </div>
  );
};
export default Addmanagevendors;
