import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,

    },
    drawerHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 20
    },
    drawerItemCont: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingVertical: 15,
        paddingHorizontal: ThemeUtils.relativeWidth(8)
    },
    accordionHeaderCont: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    accordionContainer:{
      backgroundColor:Color.WHITE,
        paddingVertical:5,
        paddingHorizontal: 0,
    },
    accordionItem:{
        paddingVertical:10
    },
    selectedAccordionItem:{
        backgroundColor:Color.GRAY
    },
    accordionHeader: {
        borderRadius: 0,
        backgroundColor: Color.PRIMARY,
        paddingHorizontal: 0,
        paddingEnd:ThemeUtils.relativeWidth(8),
        paddingVertical: 0,
    },
    txtRegular: {
        fontFamily: 'Maax'
    }
})
