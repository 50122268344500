export let string = {

    changePassword: 'Bytt Passord',
    CurrentPassword: 'Nåværende Passord',
    enterCurrentPassword: 'Skriv inn Nåværende Passord',
    enterNewPassword: 'Skriv inn Nytt Passord',
    enterConfirmPassword: 'Skriv inn Bekreft Passord',
    NewPassword: 'Nytt Passord',
    ConfirmPassword: 'Bekreft Passord',
    update: 'Oppdater',
    cancel: 'Hjem',
    purchasingContract: 'Innkjøpskontrakt',
    insurance: 'Forsikring',
    energy: 'Energi',
    contractInformation: 'Kontraktsinformasjon',
    appointmentInfoInst: 'Her finner du all kontakt informasjon for kjøperen av din/deres eiendom samt informasjon om hva som er bindende avtalt.',
    appointmentDetails: 'Avtaledetaljer',
    orderinginformation: 'Bestillingsiformasjon',
    uploadphotos: 'Last opp bilder',
    ProfilePicture: 'Profilbilde',
    stylingtype: 'Stylingtype',
    orderlist: 'Order List',
    taxinformation: 'Avgift informasjon',
    photos: 'Foto',
    taxdate: 'Skattedato',
    taxinformationdata: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    orderaccepteddata: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    entertaxdate: 'Tast inn Skattedato',
    enterloanno: 'Lånenummer',
    disclaimer: 'Ansvarsfraskrivelse',
    ordercomments: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    uploadintroduction: 'Last opp alle bildene ved å trykke på knappen nedenfor. Vi ber om at du ikke laster opp bilder i større størrelse en det som maksimalt er nødvendig for print av bilder i A5 format. Merk også at det kan ta litt tid å laste opp alle bildene dersom du laster opp mange på en gang.',
    status: 'Status',
    zipcode: 'Post kode',
    city: 'By',
    attachvideolink: 'Legg ved videolink',
    entercontact: 'Kontaktperson',
    enterdenominationamount: 'Pålydende kr. ',
    view: 'Utsikt',
    search: 'Søk',
    enteraccountnumber: 'Skriv inn kontonr.',
    uploadenergycertificate: 'Last opp energiattest',
    selectlettercode: 'Velg bokstavkode',
    colorcode: 'Fargekode',
    selectcolorcode: 'Velg Fargekode',
    upload: 'Laste opp',
    takeover: 'Overtagelse',
    iarrangetheappraiseronmyown: 'Jeg ordner takstmannen på egen hånd',
    startacquisitionbutton: 'Start overtagelse',
    photo: 'Fotos',
    date: 'Dato',
    time: 'Tid',
    startTime: 'Starttid',
    endTime: 'Sluttid',
    writeYourComments: 'Skriv dine kommentarer her ...',
    comments: 'Kommentar',
    additionalService: 'Bestill ytterligere tjenester',
    order: 'Rekkefølge',
    oppdragno: 'Oppdrag No.',
    sellername: 'Selgernavn',
    valuerate: 'Verdifrekvens',
    entervaluerate: 'Tast inn Verdifrekvens',
    loanrate: 'Lånesats',
    confirm: 'Bekrefte',
    enterloanrate: 'Tast inn Lånesats',
    btatitle: 'BTA',
    enterbta: 'Tast inn BTA',
    bratitle: 'BRA',
    enterbra: 'Tast inn BRA',
    prom: 'PROM',
    enterprom: 'Tast inn PROM',
    constructionyear: 'Byggeår',
    enterconstructionyear: 'Tast inn Byggeår',
    uploadtariffdocument: 'Last opp tariffdokument',
    bookInspectionInstruction1: 'Når vi selger hjemmet ditt, er det lovpålagt at en av våre meglere kommer til ditt hjem og vedlikeholder hjemmet ditt. Du bestiller tid for dette på denne siden. Megleren vil da kontakte deg for å bekrefte klokkeslettet.',
    booksInspection: 'Book befaring',
    energyLabeling: 'Energimerking',
    letterCode: 'Bokstavkode',
    FAQ: 'FAQ',
    rate: 'Takst',
    styling: 'Styling',
    signIn: 'Logg inn',
    send: 'Send',
    forgotPasswordIns: 'Send e-postadressen for kontoen, så sender vi deg en e-post for å tilbakestille passordet ditt',
    email: 'Email',
    password: 'Passord',
    thanksForTheOrder: 'Takk for ordren!',
    viewDetails: 'Vis detaljer',
    taxDate: 'Skattedato',
    valueRate: 'Verdifrekvens',
    loanRate: 'Lånesats',
    bta: 'BTA',
    bra: 'BRA',
    constructionYear: 'Byggeår',
    viewPDF: 'Se PDF',
    salesinformation: 'Salgsinformasjon',
    salesintroduction: 'Her finner du all kontakt informasjon for kjøperen av din/deres eiendom samt informasjon om hva som er bindende avtalt.',
    salesbuyersdetails: 'Kjøpers detaljer',
    ChooseYourStyling: 'Velg din styling',
    lastName: 'Etternavn',
    firstName: 'Fornavn',
    Address: 'Adresse',
    City: 'By',
    Phone: 'Telefon',
    Email: 'Email',
    PurchasePrice: 'Kjøpesum',
    TakeoverDate: 'Overtagelsesdato',
    Settlementinformation: 'Oppgjørs informasjon',
    NewAddress: 'Ny addresse',
    Loan: 'Lån',
    DenominationKr: 'Pålydende kr.',
    ResidualDebtKr: 'Restgjeld Kr',
    ReminderonDate: 'Restgjeld pr dato',
    Bank: 'Bank',
    Contact: 'Kontakt',
    LoanNo: 'Lånenummer.',
    ShouldtheLoanberepaid: 'Skal lånet innfris?',
    Receivables: 'Tilgodehavende',
    AccountNoforTransferofReceivable: 'Kontonummer for overføring av tilgodehavende',
    AddmoreLoans: 'Legg til flere lån',
    Save: 'Lagre',
    FinancingTitle: 'Finansiering',
    AgreeFinancing: 'Jeg samtykker med dette til å bli kontaktet av lånerådgivere for informasjon om boliglån.',
    no: 'Nei',
    yes: 'Ja',
    dates: 'Dato',
    address: 'Adresse',
    times: 'Tid',
    propertyaddress: 'Eiendomsadresse :',
    comment: 'Kommentar',
    sellersInterview: 'Selgers intervju',
    sellersInterviewInst: 'Dette er spørsmål som kan v��re viktige for potensielle kjøpere. Ikke alle spørsmål vil være så relevante for alle typer boliger, men svarene vil være så omfattende som mulig.',
    ownerShip: 'Eie',
    next: 'Neste',
    myOrders: 'Mine bestillinger',
    calendar: 'Kalender',
    instructional: 'Instruksjonsvideoer',
    documents: 'Dokument',
    salesPreparation: 'Salgsforberedelser',
    marketingSales: 'Markedsføring og salg',
    contractSettlements: 'Kontrakt & Oppgjør',
    chat: 'Chat',
    timeline: 'Tidslinje',
    phone: 'Telefon',
    help: 'Help',
    enterFirstName: 'Skriv inn Fornavn',
    enterLastName: 'Skriv inn Etternavn',
    enterEmail: 'Skriv inn Epost',
    enterPhone: 'Skriv inn telefon',
    enterAddress: 'Skriv inn Adresse',
    enterCity: 'Skriv inn By',
    seeFinnAdd: 'Se Finn annonse',
    digitalSalesAssignment: 'Digital Salgsoppgave',
    marketingMaterials: 'Markedsmateriell',
    display: 'Visning',
    bids: 'Bud',
    approve: 'Godkjenn',
    okay: 'Greit',
    dsInst: 'Her kan du se og godkjenne utkastet til den digitale salgsoppgaven. Har du kommentarer sender du de til megler nedenfor.',
    finnInst: 'Her kan du se og godkjenne utkastet til finn annonsen. Har du kommentarer sender du de til megler nedenfor.',
    seeFinnNoAd: 'Se Finn.no annonse',
    addView: 'Legg til visning',
    service: 'Service',
    yourBrokerageServices: 'Dine meglertjenester',
    marketing: 'Markedsføring',
    contract: 'Kontrakt',
    yourProperties: 'Din Salgsprosess',
    experiencedAgent: 'Erfaren eiendomsmegler',
    displayPackage: 'Visningspakke',
    location: 'Plassering',
    standard: 'Standard',
    vvs: 'VVS',
    other: 'Annen',
    name: 'Navn',
    enterName: 'Skriv inn navn',
    whatCanWeHelp: 'Hva kan vi hjelpe deg med?',
    webMeglingAs: 'Webmegling AS',
    newMortage: 'Nytt boliglån',
    submit: 'Send inn',
    dashBoard: 'Dashbord',
    inspectionApplications: 'Inspeksjonsapplikasjoner',
    yourFeedback: 'Din tilbakemelding',
    movingGuide: 'Flytteguiden',
    seller: 'Selger',
    buyer: 'Kjøper',
    brokerOnView: 'Megler på visning',
    sellerOnView: 'Selger på visning',
    readContractDraft: 'Les utkast til kontrakt',
    congratulationsOnNewHouse: 'Gratulerer med nye boliger',
    createPassword: 'Lag passord',
    homeBuyerInsurance: 'Boligkjøperforsikring',
    nameBuyer1: 'Navnekjøper 1',
    nameBuyer2: 'Navnekjøper 2',
    ownershipInterestInHernia: 'Eierinteresse i Hernia',
    readMoreAboutInsurance: 'Les mer om boligkjøperforsikring',
    thankYouAreNowDone: 'Thanak-yau re nova donne!',
    saveLogin: 'Lagre og logg inn',
    nameOfBank: 'Navn på bank',
    contactBank: 'Kontakt bank',
    accountNo: 'Kontonummer',
    addMoreBank: 'Legg til mer bank',
    additionalBank: 'Tilleggsbank',
    gnr: 'GNR',
    bnr: 'BNR',
    fnr: 'FNR',
    bankDetails: 'Bankinformasjon',
    webmeglingMoooving: 'Webmegling Moovin(g)',
    movingGuideInst: "Moving can be an energy and time consuming process. We Webmegling want to help make it as simple and effective as possible. That's why we've created a guide with things to keep in mind about the relocation process, as well as how you can easily obtain offers for services you may need.",
    seeMovie: 'Se film',
    active: 'Aktiv',
    history: 'Historie',
    amount: 'Beløp',
    dateTime: 'Dato & tid',
    enterPercentage: 'Skriv inn prosentdel',
    contactMeForMoreInformation: 'Contact Me for More Information',
    infoBuyerPurchasingContract: 'Når kontrakten er klar for signering, kan du klikke på knappen nedenfor for å få en elektronisk signatur av både kontrakt og andre dokumenter relatert til salget.',
    buttonPurchasing: 'Signer en kjøpekontrakt',
    getBetterHomeInsurance: 'Få bedre innboforsikring',
    otherComment: 'Annen kommentar',
    writehere: 'Skriv her...',
    EnterLocationPlace: 'Angi Plassering',
    WriteYourCommentHere: 'Skriv Din Kommentar Her ...',
    RecommendedValueAddedUpgrades: 'Anbefalte verdiøkende oppgraderinger',
    KeySalesPoints: 'Viktige salgspoeng',
    GeneralImpressionofTheProperty: 'Generelt inntrykk av eiendommen',
    buttonSendReport: 'Send Rapport',
    DialogTitle: 'Vellykket !',
    InspectionReportDesc: 'Inspeksjonsrapport ble sendt!',
    RoomName: 'Romnavn',
    EnterFloorType: 'Angi gulvtype',
    EnterWallType: 'Angi veggtype',
    Hamling: 'Hamling',
    WriteImportantDetails: 'Skriv viktige detaljer / kommentar her ...',
    Add: 'Legg til',
    floor: 'Gulv',
    linoleum: 'Linoleum',
    ceiling: 'Tak',
    paintedPlate: 'Malt plate',
    panel: 'Panel',
    tiles: 'Fliser',
    takees: 'Takess',
    workTop: 'Benkeplate',
    three: 'Tre',
    concrete: 'Betong',
    marble: 'Marmor',
    composite: 'Sammensatte',
    pottery: 'Keramikk',
    enterWorkTopType: 'Angi Benkeplate Type',
    refrigerator: 'Kjøleskap',
    oven: 'Stekeovn',
    attach: 'Feste',
    reSchedule: 'Planlegge på nytt',
    watchVideo: 'Se på video',
    loadMore: 'Last mer',
    attach360viewlink: 'Legg ved 360 graders visningslink',
    firstStep: {
        q1: 'Hva er grunnen til at dere skal selge boligen?',
        q2: 'Hva liker dere best ved boligen?'
    },
    secondStep: {
        q1: 'Hvilke fasiliteter gjelder for din bolig?',
        q2: 'Hva liker dere best med beliggenheten til boligen?',
        q3: 'Er det noen turområder/aktivitetsmuligheter som bør fremheves?',
        q4: 'Beskriv kort hvem de nærmeste naboene er, eksempelvis: «i leiligheten til høyre bor det et hyggelig par i midten av 30-årene»',
        q5: 'Bor det mange barnefamilier i området?',
        q6: 'Hvordan er solforholdene?',

        airConditioning: 'Air Condition',
        alarm: 'Alarm',
        resorts: 'Alpinanlegg',
        balconyTerrace: 'Balkong/Terrasse',
        kidFriendly: 'Barnevennlig',
        broadband: 'Bredbånd',
        batplass: 'Båtplass',
        communalWashing: 'Fellesvask',
        fishing: 'Fiskemuligheter',
        garage: 'Garasje/P-plass',
        parkingPlace: 'Parking place',
        golfCourse: 'Golfbane',
        garden: 'Hage',
        elevator: 'Heis',
        petsAllowed: 'Husdyr tillatt',
        noResidents: 'Ingen gjenboere',
        cableTV: 'Kabel-TV',
        offWaterAndWasteWater: 'Off.vann og avløp',
        parquet: 'Parkett',
        fireplace: 'Peis/Ildsted',
        hearth: 'hearth',
        receptionQuietCentral: 'Resepsjon Rolig Sentralt',
        shoreline: 'Strandlinje',
        roof: 'Takterasse',
        hiking: 'Turterreng',
        view: 'Utsikt',
        expandability: 'Utvidelsesmuligheter',
        janitorial: 'Vaktmester-/vektertjeneste',
        securityService: 'security service',
        guestParking: 'Gjesteparkering',
        midSummer: 'Midtsommers',
        midWinter: 'Vinterstid'
    },
    thirdStep: {
        q1: 'Beskriv hva, når og av hvem',
        q2: 'Er det andre kvaliteter ved boligen dere gjerne vil fremheve?',
        q3: 'Er det gjort oppgraderinger/vedlikehold som bør fremheves?'
    },
    fourthStep: {
        q1: 'Er det pliktig medlemskap i velforening?',
        q2: 'Kontaktinfo til lederen i velforeningen',
        q3: 'Kontaktinfo til styreleder',
        q4: 'Hvor mye betaler dere i felleskostnader?',
        q5: 'Hva inkluderer felleskostnadene?',
        q6: 'Er det noen tilhørende plikter som dugnad/brøyting eller lignende?',
        perMonth: 'Pr måned',
        perYear: 'Pr år',
        perQuarter: 'Pr kvartal'
    },
    fifthStep: {
        q1: 'Foreligger det avtaler, rettigheter eller forpliktelser tilknyttet eiendommen?',
        q2: 'Er det tilknyttet garasje-/parkeringsplass til boligen?',
        q3: 'Kan dette i så tilfelle dokumenteres?',
        q4: 'Er det tilknyttet fellesarealer/realsameie (lekeplass, ballbane etc.)?',
        q5: 'Hva og hvor er dette?'
    },
    sixthStep: {
        q1: 'Hvilke varmekilder er det i boligen?',
        q2: 'Hvilke varmekilder er det i boligen?',
        q3: 'Er rør i boligen skiftet?',
        q4: 'Hvor er det skifet og når?',
        q5: 'Hvor er varmtvannstanken plassert?',
        heatOptions: 'Varmekabler',
        radiator: 'Radiator',
        heatPump: 'Varmepumpe',
        balancedVentilation: 'Balansert ventilasjon med varmegjenvinning',
        heaters: 'Panelovner',
        woodStove: 'Vedovn',
        oilHeating: 'Oljefyring',
        waterborneHeat: 'Vannboren varme',
        airToAir: 'Luft til luft',
        airToWater: 'Luft til vann',
        waterToWater: 'Vann til vann',
        bioFirePlace: 'Biopeis',
        gasFirePlace: 'Gasspeis'
    },
    seventhStep: {
        q1: 'Hvem er leverandør av internett',
        q2: 'Hvilken type internett er det?',
        q3: 'Hvem er leverandør av TV',
        q4: 'Hvordan leveres TV signalene?',
        q5: 'Hvor høyt er årlig gjennomsnittlig strømforbruk?',
        q6: 'Hvor er boligens sikringsskap plassert?',
        altiBox: 'AltiBox',
        nextGenTel: 'NextGenTel',
        telenor: 'Telenor',
        broadnet: 'Broadnet',
        iceNet: 'Ice.net',
        breibandNo: 'Breiband.no',
        homen: 'Homen',
        telio: 'Telio',
        eidsivaBroadband: 'Eidsiva Broadband',
        globalConnect: 'Global Connect',
        viaBroadband: 'Via Broadband',
        get: 'Get',
        other: 'Other',
        broadband: 'Broadband',
        fiber: 'Fiber',
        canalDigital: 'Canal Digital',
        viasat: 'Viasat',
        riksTv: 'RiksTV',
        cableTv: 'Cable TV',
        satelliteDish: 'Satellite Dish',
        igniter: 'Igniter'
    },
    eighthStep: {
        q1: 'Er det hvitevarer som ikke skal medfølge i handelen?',
        q2: 'Hvor er det skifet og når?',
        q3: 'Skal vaskemaskin eller tørketrommel medfølge i handelen?'
    },
    ninethStep: {
        q1: 'Norges Eiendomsmeglerforbund har utarbeidet en liste over løsøre og innbo som skal følge med boligen ved salg: https://www.nef.no/losore-og-tilbehor/ Er det noe på denne listen som ikke skal medfølge når dere nå skal selge?',
        q2: 'Er det andre opplysninger dere mener er relevant å opplyse eiendomsmegler og kjøper om?',
        q3: 'Når ønsker dere overtakelse av boligen?'
    },

    appointmentInfo: 'Informasjon om avtale',
    enterresidualdebtamount: 'Legg inn restgjeld Kr',
    howisthehouseheated: 'Hvordan er boligen varmet opp?',
    takeoverintroduction: 'Når du/dere går gjennom boligen med kjøper må dere fylle ut en digital overtagelsesprotokoll. Den tar deg blant annet gjennom kontroll av boligen, avlesing av strømmåler og overlevering av nøkler.',


    photoServiceInstruction1: 'Standard fotopakke er inkludert i den faste prisen. Her kan du bestille tid for fotograf, og også bestille utvidede fototjenester.',
    photoServiceInstruction2: 'Angi ønsket vindu for når du vil at megleren skal besøke deg. Minimumstidsvindu er 4 timer.',


    additionalServiceordered: 'Tilleggstjenester bestilt',

    bookInspectionInstruction2: 'Angi ønsket vindu for når du vil at megleren skal besøke deg. Minimumstidsvindu er 4 timer.',
    energyLabelingInstruction: "Du som selger er ansvarlig for at boligen selges med en energiattest. Dette gjør du enkelt via Enova sin portal for energimerking. Klikk på knappen nedenfor og du blir videresendt til innlogging for energimerking. Når du har gjennomført prosessen fyller du inn informasjonen nedenfor og laster opp energiattesten.",
    loginEnergyMerking: 'Logg inn til energimerking.no',
    rateInstruction1: 'Vi anbefaler alle å selge hjem med en pris- eller tilstandsrapport. Hvis du ordner takstmannen ypurself, sjekk dette i skjemaet nedenfor, og du vil gi et nytt skjema for å legge inn nøkkelinformasjonen og løsningen for å laste opp takstdokumentet. Hvis du vil at vi skal kommunisere med takstmann, velger du tid og bestiller her. Bestillingen er uforpliktende og din bil vil bli kontaktet av og takstmann for ytterligere avklaring av de forskjellige takstproduktene og bekreftelse av tid.',
    rateInstruction2: 'Angi ønsket vindu for når du vil at Taksmann skal besøke deg. Minimumstidsvindu er 4 timer.',
    forgotPassword: 'Glemt passord?',
    forgotYourPassword: 'Glemt passord?',
    problemWithLogin: 'Problemer med innloggingen ?',
    thanksInstructionstyling: 'Lorem ipsum dolor sit amet, consectetuer adipicing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',


    instructionStyling2: 'Angi ønsket vindu for når du vil at Taksmann skal besøke deg. Minimumstidsvindu er 4 timer.',


    Settlementinfodetails: 'For at vi skal kunne gjennomføre oppgjøret trenger vi litt informasjon fra deg.',

    FinancingInformation: 'Har du behov for boliglån, eller ønsker å refinansiere for bedre betingelser? La vår samarbeidspartnere gi deg et godt tilbud!',


    housing: 'Boligen',
    property: 'eiendom',
    prev: 'Forrige',
    rewards: 'Belønninger',
    logout: 'Logg ut',
    home: 'Home',
    profile: 'Profil',
    enterZipCode: 'Skriv inn Post kode',
    freeConsultation: 'Gratis konsultasjon online',
    consultationInHome: 'Konsultasjon i hjemmet',

    approvedOn: 'Godkjent',
    notApprovedYet: 'Ikke godkjent ennå',
    displayInst: 'Her har du og over synspunkter. Hvis du vil at en megler skal se for deg, kan du bestille den ved å trykke på knappen nedenfor.',
    upComingView: 'Kommende visninger',
    completedView: 'Gjennomførte visninger',
    myOrderInst: 'Her finner du en oversikt over de tjenestene som er bestilt av selger.',

    timeLineInst: 'Her finner du en tidslinje som forteller deg om prosessen du går gjennom i forbindelse med hjemmesalgsprosessen.',
    addNewProperty: 'Legg til nye egenskaper',

    professionalPhotographer: 'Profesjonell fotograf',
    digitalSalesAssignmentFinnAd: 'Digital salgsoppgave og Finn-annonse',
    bidRoundHandledByBroker: 'Budrunde håndtert av megler',
    obtainingCheckingDoc: 'Innhenting og kontroll av dokumentasjon',
    smartDigitalMarket: 'Smart digital markedsføring',


    own: 'Eie',
    share: 'Share',

    sammie: 'Sameie',

    veilag: 'Veilag',
    rights: 'Rights',


    contactInst: 'Lurer du på hva boligen din er verdt, eller kanskje du er førstegangskjøper og vil vite mer om prosessen rundt kjøp av bolig? Vi hjelper deg gjerne. Send oss en melding via skjemaet nedenfor, eller kontakt oss via chat nede i høyre hjørne. Så vil vi hjelpe deg så snart som mulig!',
    financeInst: 'Har du behov for boliglån, eller ønsker å refinansiere for bedre betingelser? La vår samarbeidspartnere gi deg et godt tilbud!',
    refinancing: 'Refinansiering',

    yesIWantInsurance: 'Ja, jeg vil ha forsikring.',
    noIDontWantInsurance: "Nei, jeg vil ikke ha forsikring",
    phoneEmailContact: 'Telefon / e-postkontakt',
    contactInfoBank: 'Kontaktinfo Bankk',
    municipality: 'Municipality',
    orgNr: 'Org.Nr',
    snrShareNo: 'SNR / Share No',


    bidJournal: 'Budjournal',

    bid: 'Bud',

    additionalLoan: 'Ekstra lån',

    reservation: 'Reservasjoner',
    infoBuyerDocument: 'Her finner du alt av relevante dokumenter etter som de blir gjort tilgjengelige.',
    timeLineInstruction: 'Here you will find a timeline that tells you about the process you are going through in connection with the home sales process.',

    inspectionReport: 'Inspeksjonsrapport',
    Sellersinterview: "Selgers intervju",

    advertiseExtraRoom: 'Annonser ekstra rom',
    EnterRoomName: 'Romnavn',
    walls: 'Vegger',
    EnterHamlingType: 'Tast inn Hamling Type',
    Importantdetailscomment: 'Viktige detaljer / kommentar',
    paraquet: 'Paraquet',
    laminate: 'Laminatet',
    ceramicTile: 'Keramisk flis',
    slate: 'Skifer',
    theLoss: 'Tapet',
    applianceIncluded: 'Hvitevarer inkludert',
    microwaveOven: 'Mikrobølgeovn',
    dishwasher: 'dishwasher',

    fullView: '360 ​​visning',


    /*----------------_Remaining----------------------------------*/

    dsAssignment: 'DS Assignment',
    seeDigitalSalesTasks: 'See Digital Sales Tasks',
    purchasedService: 'Purchased Services',
    addService: 'Add Service',
    addPropertyInst: "Tailor your home sale here, and sign the assignment agreement with BankID - you're done! You do not need to contact a broker in advance, but feel free to use the chat if you are wondering something along the way.",
    fixedPrice: 'Fixed Price',
    everythingYouNeedToInclude: 'Everything you need is included',
    homeSalesFixedLowPrice: 'Home Sales Fixed Low Price',
    selfOwner: 'Self Owner',
    housingType: 'Housing Type',
    shareApartment: 'Share Apartment',
    chooseTheTypeOfRemuneration: 'Choose the Type of Remuneration',
    remunerationInst: 'In addition, you can choose to order additional services such as an expanded marketing or viewing broker. Your home sale your choice.',
    chooseOwner: "Choose the Owner's Form of Ownership",
    getRealEstateInfo: "Choose the Owner's Form of Ownership",
    registeredProperties: 'Registered Properties',
    registeredPropertiesInst: 'Select the property you want to sell through Webmegling',
    propertyNotMetioned: "The property I'm going to sell is not mentioned above",
    registeredPropQue1: 'The following clients have been working on the same assignment for the past 3 months.',
    registeredPropQue2: 'Are you a politically exposed person?',
    nameOfBrokerageFirm: 'Name of Brokerage Firm',
    gotoAssignment: 'Go to Assignment Agreement',
    congratulations: 'Congratulations!',
    contractSigned: 'The contract agreement has now been signed.',
    contracting: 'Contracting',
    signRemaining: "Co-Owner's Sign Remaining",
    acceptAgreement: 'I confirm having read and understood the contents of the assignment agreement.',
    signAgreementWithBankID: 'Sign Assignment Agreement with BankID',
    mediaNotAvailableForSign: "Media is not available to sign now. Email the assignment agreement for co-owner's signature.",
    additionalServiceInst: 'In addition, you can choose to order additional services such as an expanded marketing or viewing broker. Your home sale your choice.',
    getStartedWithYourSales: 'Get started with your home sales',
    skinAssociation: 'Skin Association',
    technicalInstallations: 'Technical installations',
    appliances: 'Appliances',
    doNotKnow: 'do Not Know',
    editView: 'Edit View',
    description: 'Description',
    fullStyling: 'Full Styling ',
    getDeals: 'Get Deals',
    webmeglingMooovingInst: 'Our digital platform, which we have called moovin, can also be used for mooving. In addition to assisting with the checklist for relocation, we can assist with full service relocation services at a fixed price. We take care of everything from washing up to ...',
    accepted: 'Accepted',
    expired: 'Expired',
    expiredOn: 'Expired On',
    additionalReceivables: 'Additional Receivables',
    addMoreReceivables: 'Add More Receivables',
    inspectionTitle: '1005619',
    enterOtherApplianced: 'Enter Other Appliances',
    choosePhoto: 'Choose Photo',
    takePhoto: 'Take Photo',
    uploadFromGallery: 'Upload From Gallery',
    removePhoto: 'Remove Photo',
    noOrder: 'No Order Available',
    areYouSureYouWantToDelete: 'Are you sure you want to delete?',
    noBids: 'No bids to show',
    clickForMortageInfo: 'Click for mortage info',
    clickForInsuranceInfo: 'Click for insurance info',
    gotoMovingGuide: 'Goto moving guide',
    propertyUpgrades: 'Property Upgrades',
    sellersDetails: "Seller's Details",
    megling: 'megling',
    extPhotoPackage: 'Extended Photo Package',
    dronePhoto: 'Drone Photo',
    orderdetails: 'Order Details',
    socialMediaViewingFilms: 'Social Media Viewing Films',
    infoAdded: 'Info Added',
    ordered: 'Ordered',
    projectCompleted: 'Project Completed',
    photoTaken: 'Photo Taken',
    photoUploaded: 'Photo Uploaded',
    timeBooked: 'Time Booked',
    photoselected: ' Photo Selected',
    downloadtariffdocument: 'Download Tariff Document',
    filter: 'Filter',
    uploadnewphoto: 'Upload New Photo',
    getbettermortgageterms: 'Get Better Mortgage Terms',
    orderaccepted: 'Order Accepted!',
    apply: 'Apply',
    reset: 'Reset',
    energycertifiacatepdf: 'Energy Certificate.pdf',
    floorPlan: 'Floorplan',
    totalCosting: 'Total Costing',
    selectDate: 'Select Date',
    selectDateone: 'Select Reminder Date',
    selectTime: 'Select Time',
    ones: '1005619',
    emiliepettersen: 'Emilie Pettersen',
    thanksInstructionrate: 'The appraiser will now contact you to arrange further details. When the tariff is completed, the assessor will upload the tariff info document and you will find all of the information available here. ',
    instructionStyling: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commondo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. ',
    documentInstruction: 'Here you find all relevant documents as they become available.',
    InstructionalContent: 'Here you find all relevant instructional videos as they become available.',
    emilieP12: 'emilieP12@webmegling.no',
    num: '955-536-65',
    done: 'Done',
    yourSalesProgress: 'Your Sales Progress',
}
