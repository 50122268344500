//Common Reducer
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_TOUR_SHOWN = 'SET_TOUR_SHOWN';

export const SET_BUYER_FORM = 'SET_BUYER_FORM'

export const SET_CURRENT_PROPERTY = 'SET_CURRENT_PROPERTY';
export const SET_COLOR = 'SET_COLOR';




