import gql from 'graphql-tag'

export const SIGN_USER = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      user {
        id
        
        first_name
        last_name
        email
        address
        
        mobile
        profile_image
        city
        zipcode
        status
      }
      token {
        access_token
        token_type
        expires_in
      }
    }
  }
`;

export const SELLER_INIT_SIGNANT_SESSION = gql`
mutation bankIdInitSession($input: BankIdInput) {
  bankIdInitSession(input: $input) {
    initial_session {
      AuthenticationUrl
      RequestID
      TrackingID
    }
  }
}
`;


export const SELLER_UPDATE_PROFILE = gql`
mutation update_seller_profile($input: UpdateSellerProfileInput) {
  update_seller_profile(input: $input) {
    user {
      id
      
      first_name
      last_name
      email
      address
      
      mobile
      profile_image
      city
      zipcode
      status
      access_level
    }
  }
}

`;
export const SELLER_BOOK_INSPECTION = gql`
  mutation book_inspection($input: BookInspectionInput!) {
    book_inspection(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_ENERGY_LABELING = gql`
  mutation property_energy_labeling($input: EnergyLabelingInput!) {
    property_energy_labeling(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_STYLISH_ORDER = gql`
  mutation order_stylish($input: OrderStylishInput!) {
    order_stylish(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_RATE = gql`
  mutation order_rate($input: OrderRateInput!) {
    order_rate(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_DETAILED_RATE = gql`
  mutation order_rate_detail($input: OrderRateDetailInput!) {
    order_rate_detail(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_PHOTO_ORDER = gql`
  mutation order_photo($input: OrderPhotoInput!) {
    order_photo(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_LOGOUT = gql`
  mutation logout {
    logout {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_CHANGE_PASSWORD = gql`
  mutation change_password($input: ChangePasswordInput!) {
    change_password(input: $input) {
      user {
        id
        
        first_name
        last_name
        email
        address
        
        mobile
        profile_image
        city
        zipcode
        status
        userZipCodes {
          user_id
          user_type_id
          zipcode
        }
      }
      token {
        access_token
        token_type
        expires_in
      }
    }
  }
`;

export const SELLER_ADD_FINN_COMMENT = gql`
mutation add_finn_ad_comment($id:Int!,$propertyId:Int!,$comment:String!){
  add_finn_ad_comment(id:$id,property_id:$propertyId,comment:$comment){
    id
    
  }
}
`;

export const SELLER_APPROVE_FINN_AD = gql`
mutation approve_Finn_ad($id: Int!, $propertyId: Int!) {
  approve_Finn_ad(id: $id, property_id: $propertyId) {
    id
    property_id
    url
    is_approved
    approved_date
    finn_comments {
      id
      property_finn_ad_id
      comment
      created_at
      updated_at
    }
  }
}
`;

export const SELLER_APPROVE_DS = gql`
mutation approve_digital_sales_assignment($id: Int!, $propertyId: Int!) {
  approve_digital_sales_assignment(id: $id, property_id: $propertyId) {
    id
    property_id
    url
    is_approved
    approved_date
    digital_sales_assi_comment {
      id
      digital_sales_assi_id
      comment
      created_at
      updated_at
    }
  }
}
`;

export const SELLER_SUBMIT_AGREEMENT = gql`
mutation owner_sign_agreement($input: ownerSignInput) {
  owner_sign_agreement(input: $input) {
    ownerAgreement_sign
  }
}
`;

export const SELLER_SUBMIT_AGREEMENT_SECOND = gql`
mutation agreementSubmit($input: sellerSignupInput) {
  agreementSubmit(input: $input) {
    ownerAgreement_sign
    meta {
      status
      message
      code
    }
  }
}
`;


export const SELLER_PROPERTY_AGREEMENT = gql`
mutation propertyAgreement($input: propertyAgreementInput) {
  propertyAgreement(input: $input) {
    owners_list
    owner_number
    meta {
      status
      message
      code
    }
  }
}
`;
export const SELLER_DS_ADD_COMMENT = gql`
mutation add_digital_sales_assi_comment(
  $id: Int!
  $propertyId: Int!
  $comment: String!
) {
  add_digital_sales_assi_comment(
    id: $id
    property_id: $propertyId
    comment: $comment
  ) {
    id
    digital_sales_assi_id
    comment
    created_at
    updated_at
    digital_sales_assigment {
      id
      property_id
      url
      is_approved
      approved_date
      digital_sales_assi_comment {
        id
        digital_sales_assi_id
        comment
        created_at
        updated_at
      }
    }
  }
}
`;

export const SELLER_MARKETING_MATERIAL_ADD_COMMENT = gql`
mutation addMarketingMaterialComment($id: Int!,$propertyId: Int!,$comment: String!) {
  add_marketing_material_comment(id: $id,property_id: $propertyId,comment: $comment) {
    id
    marketing_materials_id
  }
}
`;

export const SELLER_APPROVE_MATERIAL = gql`
mutation approveMarketingaMaterial($id: Int!, $propertyId: Int!) {
  approve_marketing_material(id: $id, property_id: $propertyId) {
    id
    document_id
    is_approved
    approved_date
    document {
      id
      property_id
      title
      doc_url
      doc_type
      is_buyer_seller_doc
      status
      property {
        id
        seller_id
        oppdrags_nummer
        seller {
          id
          
          first_name
          last_name
          email
          address
          
          mobile
          profile_image
          city
          zipcode
          status
          access_level
        }
      }
    }
  }
}`;

export const SELLER_ADD_DISPLAY = gql`
mutation addPropertyDisplay($input: PropertyDisplayInput!) {
  add_property_display(input: $input) {
    id
    property_id
  }
}`;

export const SELLER_EDIT_DISPLAY = gql`
mutation editPropertyDisplay($input: EditPropertyDisplayInput!) {
  edit_property_display(input: $input) {
    id
    property_id
  }
}`;

export const SELLER_ADD_PHOTO_SERVICE = gql`
mutation orderPhotoService($input: OrderPhotoInput!) {
  order_photo(input: $input) {
    meta {
      status
      message
      code
    }
  }
}`

export const SELLER_INTERVIEW_ANSWER = gql`
mutation interviewAnswer($input:InterviewAnswerInput!){
  interview_answer(input:$input){
    id
    seller_id
    property_id
    answer_json
  }
}`;

export const SELLER_ADD_SETTLEMENT_INFO = gql`
mutation settlementInfo($input:SettlementInfoInput!){
  property_settlement_info(input:$input){
    id
    property_id
  }
}`;

export const SELLER_ADD_FINANCING = gql`
mutation addFinancing($financingType: [Int]!, $isAgree: Int!, $propertyId: Int!) {
  add_financing(Financingtype: $financingType, is_agree: $isAgree, property_id: $propertyId) {
    id
    user_id
  }
}`;

export const SELLER_PURCHASE_SERVICE = gql`
  mutation property_services($input:SavePropertyServices!){
    property_services(input:$input)
      {
        id
        property_id
        usable_service_id
        usable_service_class
        price
        quantity
      }
}`;

export const SELLER_MOVING_GUIDE_GET_DEALS = gql`
mutation getDeals($id:Int!){
  get_deals_mail(moving_guide_id: $id) {
    meta {
      status
      message
      code
    }
  }
}`;

export const SELLER_MOVING_GUIDE_COMPLETE_STEP = gql`
mutation completeMovingGuide($id: Int!, $propertyId: Int!) {
  complete_moving_guide_seller(moving_guide_id: $id, property_id: $propertyId) {
    meta {
      status
      message
      code
    }
  }
}`;

export const SELLER_ADD_PHOTO_COMMENT = gql`
mutation addCommentPhoto($input: PhotoCommentInput!) {
  photo_comment(input: $input) {
    id
    order_photo_photos_id
    seller_id
    comment
  }
}`
export const SELLER_DELETE_PHOTO_COMMENT = gql`
mutation addCommentPhoto($input: DeletePhotoCommentInput!) {
  delete_photo_comment(input: $input) {
   id
  }
}
`
export const SELLER_RATE_ADD_COMMENT = gql`
mutation rateAddComment($input: OrderRateCommentInput!) {
  order_rate_detail_comment(input: $input) {
    id
    order_rate_detail_id
    seller_id
    comment
  }
}`;

export const SELLER_RATE_DELETE_COMMENT = gql`
mutation rateDeleteComment($input: DeleteRateDetailCommentInput!) {
  delete_rate_detail_comment(input: $input) {
    id
  }
}`;

export const UPDATE_PROPERTY_TIME_LINE = gql`
mutation updatePropertyTimeline($propertyId: ID!, $status: Int!) {
  update_property_timeline(property_id: $propertyId, status: $status) {
    success
  }
}`;
