import React from 'react'
import {ActivityIndicator, Image, ScrollView, View} from 'react-native'

import {
    Color,
    CommonStyle,
    Constants,
    Icon,
    IS_WEB, MessageUtils,
    Routes, showMessage,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
// import { Checkbox, Radio,WhiteSpace } from '@ant-design/react-native/lib/';
// import List from "@ant-design/react-native/lib/list";
import Provider from "@ant-design/react-native/lib/provider";
import {setUser} from "components/src/redux/action";
import {connect} from "react-redux";
import {CustomButton, FilePicker, FileType, Hr, InputField, Label} from "components/src/components";

import {Mutation} from "@apollo/react-components";
import {CHANGE_PROFILE_PIC} from 'components/src/api/mutation';

import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import userPlaceHolder from 'components/src/image/user_placeholder.png'
import {styles} from './styles.js';
import {PHOTOGRAPHER_UPDATE_PROFILE} from "components/src/api/photographerMutation";

// import {Button, InputItem} from "@ant-design/react-native";

const INPUTS = [{
    id: Constants.FieldId.FIRST_NAME,
    title: Strings.firstName,
    placeholder: Strings.enterFirstName,
    type: 'text',
    validationType: 'firstName'
}, {
    id: Constants.FieldId.LAST_NAME,
    title: Strings.lastName,
    placeholder: Strings.enterFirstName,
    type: 'text',
    validationType: 'lastName'
}, {
    id: Constants.FieldId.EMAIL,
    title: Strings.email,
    placeholder: Strings.enterEmail,
    type: 'email',
    validationType: 'email',
    editable: false

}, {
    id: Constants.FieldId.PHONE,
    title: Strings.phone,
    placeholder: Strings.enterPhone,
    type: 'number',
    validationType: 'phoneNo',

}, {
    id: Constants.FieldId.ADDRESS,
    title: Strings.address,
    placeholder: Strings.enterAddress,
    type: 'textArea',
    validationType: 'address'
}, {
    id: Constants.FieldId.CITY,
    title: Strings.city,
    placeholder: Strings.enterCity,
    type: "text",
    validationType: "city",
}, {
    id: Constants.FieldId.ZIP_CODE,
    title: Strings.zipcode,
    placeholder: Strings.enterZipCode,
    type: "text",
    validationType: "zipCode",
},];

class PhotoGrapherMyProfile extends React.Component {
    constructor(props) {
        super(props);
        console.log(props.user)
        this.state = {
            comments: undefined,
            imagePickerVisible: false,
            values: {
                [Constants.FieldId.FIRST_NAME]: props.user?.first_name ?? '',
                [Constants.FieldId.LAST_NAME]: props.user?.last_name ?? '',
                [Constants.FieldId.EMAIL]: props.user?.email ?? '',
                [Constants.FieldId.PHONE]: props.user?.mobile ?? '',
                [Constants.FieldId.ADDRESS]: props.user?.address ?? '',
                [Constants.FieldId.CITY]: props.user?.city ?? '',
                [Constants.FieldId.ZIP_CODE]: props.user?.zipcode?.toString() ?? '',
            },
            errors: {}

        }
    }

    handleChangeText = (text, fieldId) => {
        let values = {...this.state.values};
        values[fieldId] = text;
        this.setState({values})
    };

    toggleImagePicker = () => {
        this.setState(prevState => ({imagePickerVisible: !prevState.imagePickerVisible}))
    };

    updateProfile = (updateProfile) => {
        const {values} = this.state;
        const errors = {...this.state.errors};
        let isError = false;
        for (let i = 0; i < INPUTS.length; i++) {
            let item = INPUTS[i];
            errors[item.id] = validation(item.validationType, values[item.id] ? values[item.id].trim() : '');
            if (errors[item.id]) {
                isError = true;
            }
        }
        if (isError) {
            this.setState({errors})
        } else {
            updateProfile({
                variables: {
                    input: {
                        first_name: values[Constants.FieldId.FIRST_NAME],
                        last_name: values[Constants.FieldId.LAST_NAME],
                        address: values[Constants.FieldId.ADDRESS],
                        city: values[Constants.FieldId.CITY],
                        zipcode: parseInt(values[Constants.FieldId.ZIP_CODE]),
                        mobile: values[Constants.FieldId.PHONE],
                    }
                }
            }).catch(()=>{})
        }
    };

    onFocus = (id) => {
        let errors = {...this.state.errors};
        errors[id] = null;
        this.setState({
            errors
        })
    };

    renderInput = (item, index) => {
        const {errors, values} = this.state;
        return (
            <InputField key={item.id.toString()}
                        textArea={item.type === 'textArea'}
                        editable={item.editable}
                        type={item.type}
                        labelProps={{
                            font_medium: true,

                        }}
                        onChange={(text) => this.handleChangeText(text, item.id)}
                        onFocus={() => this.onFocus(item.id)}
                        error={errors[item.id]}
                        value={values[item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>)
    };

    myProfilechangedpassword = () => {
        if (IS_WEB) {
            this.props.history.push(Routes.ChangePassword)
        } else {
            this.props.navigation.navigate(Routes.ChangePassword);
        }
    };

    render() {
        return (
            <Provider locale={enUS}>


                <ScrollView>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.labeliconContainer}>
                                <Label large
                                       font_medium
                                       mt={15}
                                       style={CommonStyle.flex}
                                       align={'left'}>{Strings.ProfilePicture}</Label>
                                <Mutation mutation={CHANGE_PROFILE_PIC}
                                          onCompleted={(data) => {
                                              let user = {...this.props.user};
                                              user.profile_image = data.change_profile_pic;
                                              this.props.setUser(user)
                                          }}>
                                    {(uploadImage, {loading}) => {
                                        if (loading) {
                                            return (<ActivityIndicator color={Color.PRIMARY}
                                                                       style={CommonStyle.loader}/>)
                                        }
                                        return (
                                            <>
                                                <FilePicker type={FileType.IMAGE}
                                                            style={styles.uploadImage}
                                                            rippleContainerBorderRadius={20}
                                                            onFilePicked={(data, file) => {
                                                                if (file.length) {
                                                                    uploadImage({
                                                                        variables: {
                                                                            file: file[0]
                                                                        }
                                                                    }).catch(() => {
                                                                    })
                                                                }
                                                            }}>

                                                    <Icon size={ThemeUtils.fontLarge}
                                                          name={'edit'}
                                                          color={Color.EDIT_ICON}/>
                                                </FilePicker>
                                            </>
                                        )
                                    }}
                                </Mutation>


                            </View>
                            <View style={styles.labeliconContainer}>

                                <Image style={styles.imageProfile}
                                       source={this.props.user?.profile_image ? {uri: `${Constants.APIConfig.STORAGE_URL}${this.props.user?.profile_image}`} : userPlaceHolder}/>

                            </View>

                            <CustomButton
                                mt={15}
                                mb={15}
                                title={Strings.changePassword}
                                onPress={this.myProfilechangedpassword}/>

                        </View>
                        <Hr/>
                        <View style={styles.topContainers}>
                            {INPUTS.map(this.renderInput)}
                            <Mutation mutation={PHOTOGRAPHER_UPDATE_PROFILE}
                                      onCompleted={(data) => {
                                          showMessage(MessageUtils.Message.profileUpdatedSuccess,Constants.MessageType.SUCCESS)
                                          this.props.setUser({...data?.update_profile?.user,...{userType:this.props.user.userType}});
                                      }}>
                                {(updateProfile, {loading}) => (
                                    <CustomButton title={Strings.Save}
                                                  loading={loading}
                                                  onPress={() => this.updateProfile(updateProfile)}
                                                  mb={10}/>
                                )}
                            </Mutation>
                        </View>

                    </View>
                </ScrollView>
            </Provider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PhotoGrapherMyProfile);
