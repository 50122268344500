import { StyleSheet } from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        backgroundColor: Color.SECONDARY_BG_COLOR,
        marginTop:20
    },
    loader:{
        marginTop:50
    },
    htmlCont:{
        marginTop:14
    }


});
export { styles };
