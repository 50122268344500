import React from 'react'
//Components
import {ActivityIndicator, Animated, FlatList, Image, TouchableOpacity, View} from 'react-native'

import {
    AddServiceView,
    CustomButton,
    DatePicker,
    Dialog,
    FilePicker,
    FileType,
    Header,
    Hr,
    IndicatorViewPager,
    InputDialog,
    Label,
    Modal,
    PagerTabIndicator
} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {PHOTOGRAPHER_ORDER_DETAILS, PHOTOS} from "components/src/api/photographerQuery";
import {
    ATTACH_360_LINK,
    ATTACH_VIDEO_LINK,
    DELETE_PHOTOS,
    PHOTO_UPDATE_ORDER,
    UPLOAD_PHOTO
} from "components/src/api/photographerMutation";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    formatPrice,
    Icon,
    IS_WEB,
    MessageUtils,
    Routes,
    showMessage,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';
import moment from "moment";
import PhotoDetails from "./PhotoDetails";
import {SELLER_PHOTO_SERVICES} from "../../../api/sellerQuery";
import {decryptId} from "web/src/utils";
import {PURCHASED_SERVICES} from "../../../api/query";

const TABS = [{
    text: Strings.orderinginformation
}, {
    text: Strings.uploadphotos
}];

class OrderDetails extends React.Component {
    constructor(props) {
        super(props);

        const order = IS_WEB ? props.history.location.state?.order ?? {
            id: parseInt(decryptId(props.match.params.id), 10),
        } : props.navigation.getParam('order', {})
        this.state = {
            orderDate: order?.order_date ? new Date(moment(order.order_date, DateUtils.yyyy_mm_dd)) : undefined,
            startTime: order?.earliest_start_time ? new Date(moment(order.earliest_start_time, DateUtils.hh_mm_ss)) : undefined,
            endTime: null,
            deleteDialogVisible: false,

            uploadPhotoShow: false,
            inspectionDate: undefined,
            inspectionDateDisplay: '',

            earlierStartTime: undefined,
            earlierStartTimeDisplay: '',

            latestStartTime: undefined,
            latestStartTimeDisplay: '',

            comments: undefined,
            selectedAddServices: [],

            totalAmount: 0,
            displayAmount: undefined,
            selectionEnable: false,
            selectedImages: [],
            count: 0,

            orderDetails: order,
            viewLinkVisible: false,
            photoDetailsVisible: false,
            videoLinkVisible: false,
            selectedImageForDelete: []

        };
        this.scrollY = new Animated.Value(0)
    }

    toggleImageDetailsModal = (image) => {
        this.setState(prevState => ({
            photoDetailsVisible: !prevState.photoDetailsVisible,
            selectedImage: image,
        }))
    }

    toggleModelVideo = () => {
        this.setState({
            videoLinkVisible: !this.state.videoLinkVisible,

        })
    };

    toggleDeleteDialog = () => {
        this.setState({
            deleteDialogVisible: !this.state.deleteDialogVisible,

        })
    };
    toggleModelView = () => {
        this.setState({

            viewLinkVisible: !this.state.viewLinkVisible
        })
    };

    _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };

    renderAdditionalServiceItem = (item) => (
        <AddServiceView desc={item.description}
                        name={'disclaimer'}
                        title={item.name}
                        price={`Kr ${formatPrice(item.price)},-`}
                        image={item.image}
                        mb={9}
                        isSelected={item.isSelected}

        />
    );

    renderAdditionalService = ({item, index}) => {
        let data=[]
        if(item.vendor_services){
            data = item.vendor_services.filter(item => item.service_selected)
        } else {
            data = item.purchased_office_template.purchased_office_services.filter(item => item.service_selected)
        }

        if(!data.length){
            return;
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       mb={10}>
                    {item.name}
                </Label>
                {data.map((item, childIndex) => this.renderAdditionalServiceItem(item, childIndex, index))}
            </View>
        )
    }

    renderPhotoDetailsModal = () => {
        return (
            <Modal visible={this.state.photoDetailsVisible}>
                <PhotoDetails image={this.state.selectedImage}
                              editable={false}
                              user={this.props.user}
                              refetch={this.refetch}
                              onClose={this.toggleImageDetailsModal}/>
            </Modal>
        )
    }

    OrderingInformation = () => {
        return (
            <View>

                <Query query={PHOTOGRAPHER_ORDER_DETAILS}
                       onCompleted={(data) => {
                           this.setState({
                               videoLink: data?.photo_order?.order?.photo_order_video_view?.video_url ?? "",
                               viewLink: data?.photo_order?.order?.photo_order_360_view?.view_360_url ?? "",
                               orderDate: data?.photo_order?.order?.order_date ? new Date(moment(data?.photo_order?.order?.order_date, DateUtils.yyyy_mm_dd)) : undefined,
                               startTime: data?.photo_order?.order?.earliest_start_time ? new Date(moment(data?.photo_order?.order?.earliest_start_time, DateUtils.hh_mm_ss)) : undefined,
                               orderDetails: data?.photo_order?.order
                           })
                       }}
                       variables={{
                           id: this.state.orderDetails.id
                       }}>
                    {({loading, data, error,refetch}) => {
                        this.refetchOrder=refetch;
                        if (loading && !data) {
                            return <ActivityIndicator color={Color.PRIMARY}
                                                      style={CommonStyle.loader}/>
                        }
                        if (!data?.photo_order?.order) {
                            return (
                                <Label mt={20}
                                       font_medium
                                       align={'center'}>
                                    {"Ingen data"}
                                </Label>
                            )
                        }
                        return (
                            <>
                                <Mutation mutation={ATTACH_360_LINK}
                                          onError={() => {

                                          }}
                                          onCompleted={this.toggleModelView}>
                                    {(addLink, {loading, error}) => (
                                        <InputDialog key={"1"}
                                                     visible={this.state.viewLinkVisible}
                                                     value={data?.photo_order?.order?.photo_order_360_view?.view_360_url}
                                                     onNegativePress={this.toggleModelView}
                                                     loading={loading}
                                                     onPositivePress={(link) => {
                                                         addLink({
                                                             variables: {
                                                                 input: {
                                                                     photo_order_id: this.state.orderDetails?.id,
                                                                     url: link
                                                                 }
                                                             }
                                                         })
                                                     }}
                                                     title={Strings.attach360viewlink}
                                                     placeholder={Strings.attach360viewlink}
                                        />
                                    )}

                                </Mutation>
                                <Mutation mutation={ATTACH_VIDEO_LINK}
                                          onError={() => {

                                          }}
                                          onCompleted={this.toggleModelVideo}>
                                    {(addLink, {loading, error}) => (
                                        <InputDialog key={"2"}
                                                     visible={this.state.videoLinkVisible}
                                                     onNegativePress={this.toggleModelVideo}
                                                     value={data?.photo_order?.order?.photo_order_video_view?.video_url}


                                                     loading={loading}
                                                     onPositivePress={(link) => {
                                                         addLink({
                                                             variables: {
                                                                 input: {
                                                                     photo_order_id: this.state.orderDetails?.id,
                                                                     url: link
                                                                 }
                                                             }
                                                         })
                                                     }}
                                                     title={Strings.attachvideolink}
                                                     placeholder={Strings.attachvideolink}
                                        />
                                    )}
                                </Mutation>
                                {this.state.orderDetails?.order_status === Constants.ORDER_STATUS.ORDERED ||
                                this.state.orderDetails?.order_status === Constants.ORDER_STATUS.TIMEBOOKED
                                    ? (
                                        <Mutation mutation={PHOTO_UPDATE_ORDER}
                                                  onCompleted={(data) => {
                                                      /*if (IS_WEB) {
                                                          this.props.history.replace(Routes.PhotoGrapherOrderAccepted, {
                                                              order: data?.update_photo_order?.order
                                                          })
                                                      } else {
                                                          this.props.navigation.navigate(Routes.PhotoGrapherOrderAccepted, {
                                                              order: data?.update_photo_order?.order
                                                          })
                                                      }*/
                                                      this.refetchOrder && this.refetchOrder();
                                                  }}>
                                            {(updateOrder, {data, loading,error}) => (

                                                <View style={styles.topContainer}>
                                                    <DatePicker min={new Date(moment().add(1, 'd'))}
                                                                selectedDate={this.state.orderDate}
                                                                title={Strings.date}
                                                                onSelectDate={(date) => this.setState({orderDate: date})}
                                                                placeholder={Strings.selectDate}/>

                                                    <DatePicker title={Strings.startTime}
                                                                selectedDate={this.state.startTime}
                                                                placeholder={Strings.selectTime}
                                                                onSelectDate={(date) => this.setState({startTime: date})}
                                                                mode={'time'}/>

                                                    <CustomButton loading={loading}
                                                                  mt={30}
                                                                  mb={30}
                                                                  title={this.state.orderDetails?.order_status === Constants.ORDER_STATUS.ORDERED ? Strings.confirm : Strings.reSchedule}
                                                                  onPress={() => {
                                                                      if (this.state.orderDate) {
                                                                          if (this.state.startTime) {
                                                                              let variables = {
                                                                                  order_date: moment(this.state.orderDate).format(DateUtils.yyyy_mm_dd),
                                                                                  earliest_start_time: moment(this.state.startTime).format(DateUtils.hh_mm_ss),
                                                                              };
                                                                              if (this.state.orderDetails?.order_status === Constants.ORDER_STATUS.ORDERED) {
                                                                                  variables.order_status = Constants.ORDER_STATUS_SERVER.TIMEBOOKED;
                                                                              }
                                                                              updateOrder({
                                                                                  variables: {
                                                                                      input: {
                                                                                          id: this.state.orderDetails?.id,
                                                                                          fields: variables,
                                                                                      },
                                                                                  },
                                                                              })
                                                                          } else {
                                                                              showMessage(MessageUtils.Errors.startTime,Constants.MessageType.FAILED)
                                                                          }
                                                                      } else {
                                                                          showMessage(MessageUtils.Errors.orderDate,Constants.MessageType.FAILED)
                                                                      }

                                                                  }}/>
                                                </View>
                                            )}
                                        </Mutation>
                                    ) : null}

                                <Hr/>
                                <View style={styles.topContainer}>
                                    <View style={styles.DetailsView}>
                                        {this.Details(data?.photo_order?.order)}
                                    </View>
                                    {data?.photo_order?.order?.comment ?
                                        (<View>
                                            <Label
                                                mt={5}
                                                font_medium
                                                small
                                                align={'left'}>{Strings.comment}</Label>
                                            <Label
                                                mt={12}
                                                font_regular
                                                small
                                                align={'left'}>
                                                {data?.photo_order?.order?.comment ?? ""}
                                            </Label>
                                        </View>) : null}

                                    <View>

                                        <Label
                                            mt={18}
                                            mb={13}
                                            small
                                            font_medium
                                            align={'left'}>
                                            {Strings.additionalServiceordered}
                                        </Label>

                                        <Query query={PURCHASED_SERVICES}
                                               skip={!data}
                                               variables={{
                                                   status: 1,
                                                   property_id: data?.photo_order?.order?.property_id,
                                                   service_category:"PS"
                                               }}>
                                            {({loading, data, error}) => {
                                                if (loading && !data) {
                                                    return <ActivityIndicator color={Color.PRIMARY}
                                                                              style={CommonStyle.loader}/>
                                                }
                                                let additionalServices = [];
                                                data?.purchased_services?.purchased_services?.map((service, index) => {
                                                    let vendorServices = [];
                                                    if (index === 0) {

                                                        vendorServices = data?.purchased_services?.service_types[0];
                                                    }
                                                    if (index === 1) {
                                                        // vendorServices = vendorServicesQuery.data?.purchased_services?.service_types[1];
                                                        return;
                                                    }
                                                    if (index === 3) {
                                                        vendorServices = data?.purchased_services?.service_types[2];
                                                    }
                                                    if (index === 4) {
                                                        vendorServices = data?.purchased_services?.service_types[3];
                                                    }
                                                    if (vendorServices?.vendor_services?.length) {
                                                        if (vendorServices?.vendor_services?.filter(item => item.service_selected).length) {
                                                            additionalServices.push(vendorServices)
                                                        }
                                                    } else if (service.purchased_office_template?.purchased_office_services?.filter(item => item.service_selected).length) {
                                                        additionalServices.push(service)
                                                    }
                                                })
                                                return (
                                                    <FlatList
                                                        data={additionalServices}
                                                        keyExtractor={(item, index) => `${index}`}
                                                        renderItem={this.renderAdditionalService}
                                                    />
                                                )
                                            }}
                                        </Query>


                                    </View>
                                </View>
                            </>
                        )
                    }}
                </Query>
            </View>
        );
    };


    /*renderUploadPhotos = () => {
        return (
            <View style={styles.topContainer}>

                <View>
                    <Label small font_regular mt={15} mb={15}>
                        {Strings.uploadintroduction}
                    </Label>
                </View>


                <View>
                    <Label small font_medium mt={15} mb={15}>
                        {Strings.uploadnewphoto}
                    </Label>
                    <View style={styles.SubViewIcon}>
                        <Icon name={'add'} size={ThemeUtils.relativeWidth(10)} color={Color.INPUT_BORDER}/>

                    </View>

                </View>
                <View style={styles.MainView}>
                    <View style={styles.SubView}>
                        <Label small font_medium mt={15} mb={15}>
                            {Strings.attachvideolink}
                        </Label>
                        <View style={styles.SubViewIcon}>
                            <Icon name={'video'} size={ThemeUtils.relativeWidth(10)} color={Color.INPUT_BORDER}/>

                        </View>

                    </View>
                    <View style={styles.SubView}>
                        <Label small font_medium mt={15} mb={15}>
                            {Strings.attach360viewlink}
                        </Label>
                        <View style={styles.SubViewIcon}>
                            <Icon name={'360'} size={ThemeUtils.relativeWidth(10)} color={Color.INPUT_BORDER}/>

                        </View>

                    </View>
                    <View>

                    </View>

                </View>


                {this.state.cheboxshow ?
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginRight: ThemeUtils.relativeWidth(4),
                        marginTop: ThemeUtils.relativeWidth(4),
                        marginBottom: ThemeUtils.relativeWidth(1.5)
                    }}>
                        <View style={{flexDirection: 'row',}}>
                            <Icon name={'tickmark'} size={ThemeUtils.fontXLarge} color={Color.PRIMARY}/>
                            <Label small ml={15}
                                   font_medium>{`${[this.state.count + Strings.photoselected]}`}</Label>
                        </View>

                        <Icon name={'delete'} size={ThemeUtils.fontXLarge} color={Color.RED}/>
                    </View> : <Label small font_medium mt={25} mb={15}>
                        {Strings.photo}
                    </Label>}


                {this.PhotoGrid()}


            </View>
        );
    }*/

    renderUploadPhotos = () => {
        return (
            <View style={CommonStyle.container}>
                {this.renderPhotoDetailsModal()}
                <Query query={PHOTOS}
                       variables={{
                           status: 1,
                           first: 30,
                           page: 1,
                           photoId: this.state.orderDetails.id
                       }}>
                    {({data, loading, error, refetch}) => {
                        this.refetch = refetch;
                        if (loading && !data) {
                            return <ActivityIndicator color={Color.PRIMARY}
                                                      style={CommonStyle.loader}/>
                        }
                        return (
                            <View>

                                <View style={styles.topContainer}>

                                    <View>
                                        <Label small font_regular mt={15} mb={15}>
                                            {Strings.uploadintroduction}
                                        </Label>
                                    </View>


                                    <Label small font_medium mt={15} mb={15}>
                                        {Strings.uploadnewphoto}
                                    </Label>
                                    <Mutation mutation={UPLOAD_PHOTO}
                                              refetchQueries={[{
                                                  query: PHOTOS,
                                                  variables: {
                                                      status: 1,
                                                      first: 30,
                                                      page: 1,
                                                      photoId: this.state.orderDetails.id
                                                  }
                                              }]}>
                                        {(uploadPhoto, mutation) => (
                                            <FilePicker multiple
                                                        style={{alignSelf: 'flex-start'}}
                                                        type={FileType.IMAGE}
                                                        onFilePicked={(data, files) => {
                                                            uploadPhoto({
                                                                variables: {
                                                                    photo: files,
                                                                    photoOrderId: this.state.orderDetails.id
                                                                }
                                                            })
                                                        }}>
                                                <View style={[styles.SubViewIcon]}>
                                                    {mutation.loading ? (
                                                        <ActivityIndicator color={Color.PRIMARY}/>
                                                    ) : (
                                                        <Icon name={'add'}
                                                              size={ThemeUtils.relativeWidth(10)}
                                                              color={Color.INPUT_BORDER}/>
                                                    )}

                                                </View>
                                            </FilePicker>
                                        )}

                                    </Mutation>


                                    <View style={styles.MainView}>

                                        <View style={styles.SubView}>

                                            <Label small font_medium mt={15} mb={15}
                                                   style={CommonStyle.flex}>
                                                {Strings.attachvideolink}
                                            </Label>
                                            <TouchableOpacity onPress={this.toggleModelVideo}>

                                                <View
                                                    style={[styles.SubViewIcon, this.state.videoLink ? {backgroundColor: Color.PRIMARY} : null]}>
                                                    <Icon name={'video'}
                                                          size={ThemeUtils.relativeWidth(10)}
                                                          color={this.state.videoLink ? Color.COLOR_ACCENT : Color.INPUT_BORDER}/>

                                                </View>
                                            </TouchableOpacity>
                                        </View>


                                        <View style={styles.SubView}>
                                            <Label small font_medium mt={15} mb={15}
                                            style={styles.subViewLbl}>
                                                {Strings.attach360viewlink}
                                            </Label>
                                            <TouchableOpacity onPress={this.toggleModelView}>

                                                <View
                                                    style={[styles.SubViewIcon, this.state.viewLink ? {backgroundColor: Color.PRIMARY} : null]}>
                                                    <Icon name={'360'}
                                                          size={ThemeUtils.relativeWidth(10)}
                                                          color={this.state.viewLink ? Color.COLOR_ACCENT : Color.INPUT_BORDER}/>
                                                </View>
                                            </TouchableOpacity>
                                        </View>


                                    </View>
                                    {this.state.selectionEnable ? (
                                        <View style={styles.propertySelectionCont}>

                                            <Icon name={'tickmark'}
                                                  size={ThemeUtils.fontNormal}
                                                  color={Color.PRIMARY}/>
                                            <Label small
                                                   font_medium
                                                   ml={15}
                                                   style={CommonStyle.flex}>
                                                {`${this.state.selectedImages.length} ${Strings.photoselected}`}
                                            </Label>


                                            <Mutation mutation={DELETE_PHOTOS}
                                                      refetchQueries={[{
                                                          query: PHOTOS,
                                                          variables: {
                                                              status: 1,
                                                              first: 30,
                                                              page: 1,
                                                              photoId: this.state.orderDetails.id
                                                          }
                                                      }]}>
                                                {(deletePhoto, {loading}) => (
                                                    <>
                                                        <Dialog visible={this.state.deleteDialogVisible}
                                                                transparent
                                                                onNegativePress={this.toggleDeleteDialog}
                                                                onPositivePress={() => {
                                                                    deletePhoto({
                                                                        variables: {
                                                                            ids: this.state.selectedImages
                                                                        }
                                                                    })
                                                                    this.setState({
                                                                        selectedImages: [],
                                                                        selectionEnable: false

                                                                    }, () => this.toggleDeleteDialog())

                                                                }}
                                                                title={`${Strings.deletePhoto}`}
                                                                buttonTitleFirst={Strings.cancel}
                                                                buttonTitleSecond={Strings.okay}
                                                                color={Color.RED}
                                                                desc={Strings.areYouSureYouWantToDelete}/>
                                                        {loading ? (
                                                            <ActivityIndicator color={Color.PRIMARY}/>
                                                        ) : this.state.selectedImageForDelete.length ? (
                                                            <TouchableOpacity activeOpacity={0.6}
                                                                              onPress={this.toggleDeleteDialog}>
                                                                <Icon name={'delete'}
                                                                      size={ThemeUtils.fontNormal}
                                                                      color={Color.RED}/>
                                                            </TouchableOpacity>) : null}
                                                    </>
                                                )}

                                            </Mutation>

                                            <TouchableOpacity activeOpacity={0.6}
                                                              style={{marginStart: 20}}
                                                              onPress={this.toggleSelectionMode}>
                                                <Icon name={'close'}
                                                      size={ThemeUtils.fontNormal}
                                                      color={Color.PRIMARY}/>
                                            </TouchableOpacity>

                                        </View>
                                    ) : (<Label small
                                                font_medium
                                                mt={15}
                                                mb={5}>
                                        {data?.order_photo_list?.data ? Strings.photos : ""}
                                    </Label>)}


                                </View>

                                {this.PhotoGrid(data?.order_photo_list?.data ?? [])}


                                {/*<View style={{marginTop: 20}}>*/}
                                {/*<Hr/>*/}
                                {/*</View>*/}
                                {/* {data?.moovin_photo_photos?.length ?
                                (<View>
                                    <Label small
                                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                           font_medium mt={20}>
                                        {Strings.Photos}
                                    </Label>

                                    {this.PhotoGrid()}
                                </View>) : null}*/}


                            </View>
                        )
                    }}
                </Query>
            </View>

        );
    };

    toggleSelectionMode = (index, userId) => {
        this.setState(prevState => ({
            selectionEnable: !prevState.selectionEnable,
        }), () => {
            if (this.state.selectionEnable) {
                userId == this.props.user?.id ? this.addOrRemoveImage(index) : null
            } else {
                this.setState({
                    selectedImage: []
                })
            }
        })
    };

    addOrRemoveImage = (index) => {

        let selectedImages = this.state.selectedImages.slice();
        if (selectedImages.includes(index)) {
            selectedImages.splice(selectedImages.indexOf(index), 1)
        } else {
            selectedImages.push(index)
        }
        this.setState({
            selectedImages: selectedImages,
            selectionEnable: selectedImages.length !== 0
        })
    };

    PhotoGrid = (images) => {
        return (
            <View style={styles.ImageGridView}>
                {images.map((obj, index) => {
                    return (
                        <TouchableOpacity activeOpacity={0.6}
                                          onLongPress={() => !this.state.selectionEnable ? this.toggleSelectionMode(obj.id, obj.user_id) : null}
                                          onPress={() => this.state.selectionEnable ? obj.user_id == this.props.user?.id ? this.addOrRemoveImage(obj.id) : null : this.toggleImageDetailsModal(obj)}>


                            <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${obj.photo_url}`}}
                                   style={styles.GridImage}/>
                            {this.state.selectionEnable && obj.user_id == this.props.user?.id ?
                                (<View
                                    style={[styles.GridImage, styles.icCheck, this.state.selectedImages.includes(obj.id) ? {backgroundColor: Color.PRIMARY_TRANSPARENT} : null]}>
                                    <Icon name={'tickmark'}
                                          size={ThemeUtils.fontXLarge}
                                          color={this.state.selectedImages.includes(obj.id) ? Color.COLOR_ACCENT : Color.PRIMARY_LIGHTER}/>
                                </View>) : null}

                        </TouchableOpacity>

                    )
                })
                }
            </View>
        )
    };


    Details = (data) => {

        return (
            <View>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.oppdragno}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.property?.oppdrags_nummer}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.sellername}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {`${data?.property?.seller?.first_name} ${data?.property?.seller?.last_name}`}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Email}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.property?.seller?.email}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Phone}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.property?.seller?.mobile}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.dates}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {moment(data?.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.time}</Label>
                {this.state.orderDetails?.order_status === Constants.ORDER_STATUS.ORDERED ?
                    (
                        <Label small
                               mb={20}
                               font_regular
                               align={'left'}>
                            {`${moment(data?.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)} - ${moment(data.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}`}
                        </Label>) : (
                        <Label small
                               mb={20}
                               font_regular
                               align={'left'}>
                            {moment(data?.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                        </Label>
                    )}
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.propertyaddress}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {`${data?.property?.street_adress ?? ""} ${data?.property?.city ?? ""} ${data?.property?.zipcode ?? ""}`}
                </Label>
            </View>
        );
    };


    render() {
        return (
            <Provider locale={enUS}>
                <Header style={CommonStyle.blankHeader}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>
                {/* <ScrollView contentContainerStyle={{ flexGrow: 1 }}> */}

                <View style={styles.container}>
                    {/* <View style={styles.topContainer}> */}
                    <View style={styles.titleCont}>
                        <Label
                            font_medium
                            mb={15}
                            mt={10}
                            xlarge
                            align={'left'}>
                            {Strings.orderdetails}
                        </Label>


                    </View>


                    <IndicatorViewPager
                        indicator={this._renderTabIndicator()}
                        style={{flex: 1, backgroundColor: Color.WHITE}}>

                        <View>
                            <Animated.ScrollView>
                                {this.OrderingInformation()}
                            </Animated.ScrollView>
                        </View>


                        <View>
                            <Animated.ScrollView>

                                {this.renderUploadPhotos()}
                            </Animated.ScrollView>
                        </View>

                    </IndicatorViewPager>
                    {/* </View> */}
                </View>
                {/* </ScrollView> */}
            </Provider>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
