import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = 20;

export default StyleSheet.create({

    stepCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    bankLblCont: {
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 15
    },
    lblCont:{
        flexDirection:'row',
        marginBottom: 10,
        alignItems:'center'
    }

});


