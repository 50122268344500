import { getMenuData, getPageTitle } from '@ant-design/pro-layout';
import { connect } from 'react-redux';
import React from 'react';
// import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";
import { formatMessage } from "components/src/utils";
// import '../assets/icons/css/fontello.css';
import '../../sass/app.scss';
import '../../sass/defaultstyles.scss';
import '../../sass/utils.scss';
// eslint-disable-next-line import/no-named-as-default
import AdminFooter from '../AdminFooter/Index';
import AdminSidebar from './AdminSidebar/Index';

const Index = props => {
  let router = props.history;
  const {
    route = {
      routes: [],
    },
  } = props;
  const { routes = [] } = route;
  const {
    children,
    location = {
      pathname: '',
    },
  } = props;
  const { breadcrumb } = getMenuData(routes);
  const title = getPageTitle({
    pathname: location.pathname,
    formatMessage,
    breadcrumb,
    ...props,
  });

  return (
    <>
      {/* <Helmet> */}
        <title>{title}</title>
        <meta name="description" content={title} />
      {/* </Helmet> */}
      <>
        {props.location.pathname === '/admin/' || props.location.pathname === '/admin' ? (
          router.push('/admin/login')
        ) : (
          <>
            <AdminSidebar>{children}</AdminSidebar>
            <AdminFooter />
          </>
        )}
      </>
    </>
  );
};

export default connect(({ settings }) => ({ ...settings }))(withRouter(Index));


