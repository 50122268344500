import React, {useState} from 'react';
//Components
import {ActivityIndicator, Animated, FlatList, View} from 'react-native';
import {
    Dashboard,
    Header,
    Hr,
    IndicatorViewPager,
    Label,
    Modal,
    PagerTabIndicator,
    Ripple
} from "components/src/components";
//Third party
//Utils
import {Color, CommonStyle, Constants, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import {useQuery} from "@apollo/react-hooks";
import {INSPECTION_ORDER_LIST} from "../../../api/inspectionQuery";
import Search from "../InpectionApplicationSearch";
import energy_labeling from "../../../image/energy_labeling.png";
import photo from "../../../image/photo.png";
import digitalMarketing from "../../../image/ds_assignment.png";
import marketingMaterial from "../../../image/marketing_material.png";

const PRO_LIST = [
    {
        id: 1,
        desc: 'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350',
        OppdragNo: 'Oppdrag No. : 1005619'
    },
    {
        id: 2,
        desc: 'Maridalsveien 10, 0178 Oslo, Norge',
        OppdragNo: 'Oppdrag No. : 1000545'
    },
    {
        id: 3,
        desc: 'Storgata 36E, 0182 Oslo, Norge',
        OppdragNo: 'Oppdrag No. : 1000325'
    },

];

const DASHBOARD=[{
    name:`${Strings.photo} / ${Strings.styling}`,
    icon: 'photo',
    bg: photo,
},{
    name:Strings.digitalMarketing,
    icon: 'digital-sales-assignment',
    bg: digitalMarketing,
},{
    name:Strings.printMarketing,
    icon: 'print',
    bg: marketingMaterial,
},{
    name:Strings.wBefaring,
    icon: 'search',
    bg: energy_labeling,
}];

const TABS = [{
    text: Strings.propertyList,
}, {
    text: Strings.personalTemplate
}];

export default function PropertyList(props) {
    const {navigation, history} = props;
    const scrollY = new Animated.Value(0)
    const [isSearchVisible, setSearchVisible] = useState(false)

    const {loading, data, error, refetch, fetchMore} = useQuery(INSPECTION_ORDER_LIST, {
        variables: {
            input: {
                first: 30,
                page: 1,
                order_status: `${Constants.ORDER_STATUS_SERVER.TIMEBOOKED}`
            }
        }
    })

    const navigate = (item) => {
        if (IS_WEB) {
            history.push(Routes.InspectionPropertyDetails, {
                property: item
            })
        } else {
            navigation.navigate(Routes.InspectionPropertyDetails, {
                property: item
            })
        }
    };

    const renderPropertyList = ({item, index}) => {
        return (
            <>
                <Hr/>
                <Ripple style={styles.ProCont}
                        onPress={() => navigate(item)}>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <View style={styles.content}>
                            <Label small
                                   mt={10}
                                   font_medium
                                   numberOfLines={3}>
                                {item?.property?.address}
                            </Label>
                            <Label small
                                   mt={10}
                                   font_regular
                                   mb={5}
                                   color={Color.SECONDARY_TEXT_COLOR}
                                   numberOfLines={2}>
                                {item?.property?.oppdrags_nummer}
                            </Label>
                        </View>
                        <View style={{alignSelf: 'center'}}>
                            <Icon name={'drop-normal'}
                                  color={Color.SECONDARY_TEXT_COLOR}/>
                        </View>
                    </View>

                </Ripple>
            </>
        )
    };

    const renderSearchModal = () => {
        return (
            <Modal visible={isSearchVisible}>
                <View>
                    <Search onClose={() => setSearchVisible(false)}
                            renderItem={renderPropertyList}/>
                </View>
            </Modal>
        )
    };
    const renderTabIndicator = () => {
        return (
            <PagerTabIndicator tabs={TABS}/>
        )
    };

    return (
        <View style={styles.container}>
            {renderSearchModal()}
            <Header animatedTitle={Strings.inspectionApplications}
                    navigation={IS_WEB ? history : navigation}
                    headerRightFirst={'search'}
                    onPressRightFirst={() => setSearchVisible(true)}
                    animatedValue={scrollY}/>

            <View style={styles.cont}>

                <Icon name={'inspection-application'}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY_TEXT_COLOR}/>

            </View>
            <IndicatorViewPager indicator={renderTabIndicator()}
                                style={CommonStyle.flex}>

                <View style={CommonStyle.container}>


                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <View style={{marginTop: 20}}>
                            <FlatList data={data?.inspection_orders?.data ?? []}
                                      onRefresh={refetch}
                                      refreshing={loading}
                                      renderItem={renderPropertyList}
                                /*ListEmptyComponent={
                                    <View style={CommonStyle.content_center}>
                                        <Label mt={40}>
                                            {Strings.noOrder}
                                        </Label>
                                    </View>
                                }*/
                                      ListFooterComponent={
                                          loading &&
                                          data?.inspection_orders?.paginatorInfo?.hasMorePages ?
                                              (<ActivityIndicator color={Color.PRIMARY}
                                                                  style={CommonStyle.loader}/>) : null
                                      }
                                      onEndReached={(info) => {
                                          if (!loading &&
                                              data?.inspection_orders?.paginatorInfo?.hasMorePages) {
                                              fetchMore({
                                                  variables: {
                                                      page: parseInt(data?.inspection_orders?.paginatorInfo?.currentPage, 10) + 1,
                                                  },
                                                  updateQuery: (prev, {fetchMoreResult}) => {
                                                      if (!fetchMoreResult) {
                                                          return prev;
                                                      } else {
                                                          let finalResult = Object.assign({}, fetchMoreResult);
                                                          finalResult.inspection_orders.data = [...prev.inspection_orders.data, ...fetchMoreResult.inspection_orders.data];
                                                          return finalResult;
                                                      }
                                                  },
                                              });
                                          }

                                      }}
                                      onEndReachedThreshold={0.2}/>
                        </View>
                    )}

                </View>
                <View>
                    <Dashboard dashboardData={DASHBOARD}/>
                </View>
            </IndicatorViewPager>

        </View>

    )
}
