import { StyleSheet } from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    titleContainer: {

        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    topContainer: {
        flex:1,
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center',

        justifyContent:'space-between'
    },
    checkbox:{alignItems:'flex-start',flex:1,flexDirection:'column',marginTop:15,alignContent:'center',paddingTop:12,},


    energyradio:{
        marginBottom:15,
        fontSize:12,
        alignContent:'center',

    },
    buttonContainer: {
        marginHorizontal:containerHorizontalMargin,
        marginVertical:20,
        flexDirection: 'row',
    },
});

export { styles };
