import {StyleSheet} from 'react-native'
import {Color} from "../../../utils";

export default StyleSheet.create({
    cont:{
        flex:1,
        flexDirection:'row',
        backgroundColor:Color.DARK_GRAY,
        borderRadius:6,
        paddingStart:12,
        paddingEnd:4,
        paddingVertical:4,
        alignItems:'center'
    },
    icCont:{
        padding:8
    }
})
