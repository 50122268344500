// @flow
import React from 'react';
import {TouchableOpacity, View} from 'react-native';

import Button from './Button';

import styles from './style';
import {Color, ThemeUtils} from "../../../../utils";
import Label from "../../Label";

const Tooltip = ({
                     isFirstStep,
                     isLastStep,
                     handleNext,
                     handlePrev,
                     handleStop,
                     currentStep,
                     labels,
                     totalSteps,
                     currentStepNumber
                 }) => (
    <View>
        <View style={styles.tooltipContainer}>
            <Label testID="stepTitle"
                   align={'center'}
                   style={styles.tooltipText}>
                {currentStep.title}
            </Label>
            <Label testID="stepDescription"
                   align={'center'}
                   small
                   style={styles.tooltipText}>
                {currentStep.text}
            </Label>
        </View>
        <View style={[styles.bottomBar]}>
            {
                !isLastStep ?
                    <TouchableOpacity onPress={handleStop}>
                        <Button>{labels.skip || 'Skip'}</Button>
                    </TouchableOpacity>
                    : null
            }
            <View style={{
                flex: 1,
                justifyContent: 'flex-end',
                flexDirection: 'row'
            }}>
                {
                    !isFirstStep ?
                        <TouchableOpacity onPress={handlePrev}>
                            <Button>
                                {labels.previous || 'Previous'}
                            </Button>
                        </TouchableOpacity>
                        : null
                }
                {
                    !isLastStep ?
                        <TouchableOpacity onPress={handleNext}
                                          style={{
                                              backgroundColor: Color.PRIMARY,
                                              borderRadius: 4,
                                              marginStart: ThemeUtils.relativeRealWidth(2)
                                          }}>
                            <Button style={{color: Color.WHITE}}>
                                {`${labels.next || 'Next'} ( ${currentStepNumber} / ${totalSteps} )`}
                            </Button>
                        </TouchableOpacity> :
                        <TouchableOpacity onPress={handleStop}
                                          style={{
                                              backgroundColor: Color.PRIMARY,
                                              borderRadius: 4,
                                              marginStart: ThemeUtils.relativeRealWidth(2)
                                          }}>
                            <Button style={{color: Color.WHITE}}>
                                {`${labels.finish || 'Finish'} ( ${currentStepNumber} / ${totalSteps} )`}
                            </Button>
                        </TouchableOpacity>
                }

            </View>
        </View>
    </View>
);

export default Tooltip;
