import React, {useEffect, useRef, useState} from 'react';
import {Animated, Keyboard, View} from 'react-native'
//Components
import {CustomButton, CustomTextArea, Header, Label, Ripple, Toast} from "components/src/components";
//Third party
import moment from "moment";
//Network
import {useMutation} from "@apollo/react-hooks";
import {SELLER_APPROVE_MATERIAL, SELLER_MARKETING_MATERIAL_ADD_COMMENT} from "components/src/api/sellerMutation";
//Utils
import {
    Color,
    Constants,
    DateUtils,
    Icon,
    IS_WEB,
    openInNewTab,
    Routes, showMessage,
    Strings,
    ThemeUtils
} from "components/src/utils";
import styles from './styles';
import {connect} from "react-redux";


let toast;
function MarketingMaterialDetails(props) {
    const {history, navigation} = props;

    const [material, setMaterial] = useState(IS_WEB ? history.location.state.document ?? {} : navigation.getParam('document', {}))

    const [comments, setComments] = useState();

    const scrollY = new Animated.Value(0)

    const [addComment, addCommentMutation] = useMutation(SELLER_MARKETING_MATERIAL_ADD_COMMENT);

    const [approveMaterial, approveMutation] = useMutation(SELLER_APPROVE_MATERIAL);

    useEffect(() => {
        console.log(material)
        material?.materialComment.length ? setComments(material?.materialComment?.slice().pop()?.comment) : ""
    }, [])

    const sendComment = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        addComment({
            variables: {
                id: material.id,
                propertyId: props?.property?.id,
                comment: comments
            },
        }).then(()=>{
            showMessage("Kommentar ble lagt til",Constants.MessageType.SUCCESS)
        }).catch(() => {
        });
    };

    const approve = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        approveMaterial({
            variables: {
                id: material.id,
                propertyId: props?.property?.id,
            }
        }).then((data) => {
            setMaterial(data.data.approve_marketing_material);
        }).catch(() => {
        });
    };

    const openDoc = () => {

    };

    return (
        <View style={styles.cont}>
            <Toast ref={r=>toast=r}/>
            <Header animatedTitle={"Markedsføringsmateriell"}
                    navigation={IS_WEB ? history : navigation}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.replace(Routes.SalesPreparation) : navigation.popToTop()}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 keyboardShouldPersistTaps={'always'}
                                 contentContainerStyle={styles.scrollCont}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.contentCont}>
                    <Icon name={'marketing-materials'}
                          style={styles.icMaterial}
                          size={ThemeUtils.fontXXLarge}/>

                    <View style={styles.materialCont}>
                        <Icon name={'marketing-material-print'}
                              color={Color.PRIMARY}
                              size={28}/>
                        <View style={styles.lblCont}>
                            <Label font_medium
                                   small
                                   mb={5}>
                                {material.document.title}
                            </Label>
                            <Label xsmall>
                                {material.is_approved ? `${Strings.approvedOn} ${moment.utc(material.approved_date).local().format(DateUtils.hh_mm_dd_mm_yyy)} ` : Strings.notApprovedYet}
                            </Label>
                        </View>
                        <Ripple rippleContainerBorderRadius={14}
                                onPress={() => {
                                    openInNewTab(`${Constants.APIConfig.STORAGE_URL}${material?.document?.doc_url}`)
                                }}
                                style={styles.icOpenExtCont}>
                            <Icon name={'open-externally'}
                                  color={Color.PRIMARY_LIGHT}
                                  size={14}/>
                        </Ripple>
                    </View>
                    {!material.is_approved ? (
                        <View>
                            <CustomTextArea mt={20}
                                            onChange={setComments}
                                            value={comments}
                                            placeholder={Strings.writeYourComments}
                                            labelText={"Tilbakemelding / Eventuell korrektur"}/>
                            <CustomButton title={Strings.send}
                                          mt={20}
                                          disabled={!comments}
                                          loading={addCommentMutation.loading}
                                          onPress={sendComment}
                                          mr={ThemeUtils.relativeRealWidth(60)}/>
                        </View>) : null}
                    <View style={styles.btnApproveCont}>
                        <CustomButton title={material.is_approved ? Strings.back : Strings.approve}
                                      loading={approveMutation.loading}
                                      onPress={() => material.is_approved ? IS_WEB ? history.goBack() : navigation.goBack() : approve()}/>
                    </View>
                </View>
            </Animated.ScrollView>
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStateToProps)(MarketingMaterialDetails);
