import { EditOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Typography, Dropdown, Menu, notification } from 'antd';
import React, { useState,useEffect, useRef } from 'react';
import { Link,useHistory } from 'react-router-dom';

import { Query, Mutation } from 'react-apollo';

import { ADMIN_JOBS_LIST } from '../../../../query/AdminQuery';
import { ADMIN_DELETE_JOB } from '../../../../mutation/AdminMutation';
import { encryptId } from '../../../utils/utils';
import {formatMessage} from "components/src/utils";

const { Title } = Typography;
const { Search } = Input;
let dataRefetch;
export const Jobs = () => {
  let router=useHistory();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [orderData, setOrderData] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [prevPagination, setprevPagination] = useState({});
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 30,
    total: 1,
  });

  const timerRef = useRef(null);

  const usePreviousValue = () => {
    const ref = useRef();
    useEffect(() => {
      ref.current = searchKeyword;
    });
    return ref.current;
  };
  const prevValue = usePreviousValue();

  const searchFunc = e => {
    if (prevValue === '') {
      console.log('prevValue;', prevValue);
      setprevPagination(paginationData);
    }
    if (e !== '') {
      setPaginationData({
        current: 1,
        pageSize: 30,
        total: 1,
      });
    } else {
      setPaginationData(prevPagination);
    }
  };

  const handleTableChange = pagination => {
    console.log('pagination:', pagination);
    if (pagination) {
      setPaginationData(pagination);
      setprevPagination(paginationData);
    }
  };

  useEffect(() => {
    if (searchKeyword === '') {
      setprevPagination(paginationData);
    }
  }, []);

  const menu = (item, deletUser) => (
      <Menu>
        <Menu.Item
            onClick={() => {
              // const deleteID =  orderData.filter(item =>item.id);
              deletUser({
                variables: {
                  id: parseInt(
                      item.map(i => i.id),
                      10,
                  ),
                  status: false,
                },
              }).then(({ data }) => {
                console.log('Data submitted===>>>', data);
                console.log('Data data.delete_user===>>>', data.delete_jobs);
                if (dataRefetch) {
                  dataRefetch();
                  if (data && data.delete_jobs && data.delete_jobs.meta) {
                    notification.success({
                      message: data.delete_jobs.meta.message,
                      description: '',
                      duration: 3,
                    });
                  }
                }
              });
            }}
        >
          {formatMessage({ id: 'component.form.delete' })}
        </Menu.Item>
      </Menu>
  );
  const columns = [
    {
      title: `${formatMessage({ id: 'component.table.title' })}`,
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title - b.title,
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.table.companyname' })}`,
      dataIndex: 'company_name',
      key: 'company_name',
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.table.localization' })}`,
      dataIndex: 'localization',
      key: 'localization',
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.table.action' })}`,
      key: 'action',
      render: name => {
        const itemToSend = orderData && orderData.filter(item => name.key === item.id);
        console.log('Item to send==>', itemToSend[0]);
        return (
            <span style={{ paddingLeft: 16 }}>
            <Link
                to={{
                  pathname: `/admin/ledigstilling/rediger/${encryptId(itemToSend.map(i => i.id))}`,
                  state: { orderItem: itemToSend[0] },
                }}
            >
              <EditOutlined style={{ marginRight: 8 }} />
            </Link>
            <Mutation mutation={ADMIN_DELETE_JOB}>
              {deletUser => (
                  <Dropdown
                      // overlay={menu}
                      overlay={menu(itemToSend, deletUser)}
                      placement="bottomCenter"
                      trigger={['click']}
                  >
                    <MoreOutlined />
                  </Dropdown>
              )}
            </Mutation>
          </span>
        );
      },
    },
  ];

  return (
      <div className="contentshadow">
        <div className="text-primary main">
          <Row className="photo-order-wrapper">
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
              <Title level={3} className="text-primary">
                {formatMessage({
                  id: 'component.jobs.title',
                })}
              </Title>
              <p>
                Her finner du en oversikt over alle ledige stillinger i foretaket. Du kan også legge
                til nye.
              </p>
              <Link to="/admin/ledigstilling/leggtil">
                <Button
                    type="primary"
                    className="btn-secondary btn-secondary admin-forms-btn with-lg-spaces"
                    size="large"
                >
                  {formatMessage({ id: 'component.jobs.addjobs' })}
                </Button>
              </Link>
              <Row>
                <Col xs={24}>
                  <Query
                      query={ADMIN_JOBS_LIST}
                      variables={{
                        status: 1,
                        first: 30,
                        page: 1,
                        key: searchKeyword,
                        sorting: {
                          field: 'id',
                          order: 'DESC',
                        },
                      }}
                      fetchPolicy="cache-and-network"
                      onCompleted={responseData => {
                        setOrderData(responseData.joblist.data);
                        if (
                            responseData &&
                            responseData.joblist &&
                            responseData.joblist.paginatorInfo
                        ) {
                          setPaginationData({
                            current: responseData.joblist.paginatorInfo.currentPage,
                            pageSize: responseData.joblist.paginatorInfo.perPage,
                            total: responseData.joblist.paginatorInfo.total,
                          });
                        }
                        if (responseData && responseData.joblist && responseData.joblist.data) {
                          setJobList(responseData.joblist.data);
                        }
                      }}
                  >
                    {({ data, loading, refetch, error }) => {
                      console.log(error);
                      dataRefetch = refetch;
                      const JobsArray = [];
                      if (data && data.joblist.data) {
                        // eslint-disable-next-line array-callback-return
                        data.joblist.data.map(item => {
                          const InspectionUserList = {
                            key: item.id,
                            title: item.title,
                            company_name: item.company_name,
                            localization: item.location,
                          };
                          JobsArray.push(InspectionUserList);
                        });
                      }
                      if (!error) {
                        return (
                            <>
                              <Row>
                                <Col lg={12} xs={24}>
                                  <div className="table-searchbar">
                                    <Search
                                        placeholder={formatMessage({
                                          id: 'component.tablesearch.placeholder',
                                        })}
                                        onSearch={value => setSearchKeyword(value)}
                                        onChange={e => {
                                          const searchText = e.target.value;
                                          searchFunc(searchText);
                                          if (timerRef.current) {
                                            clearTimeout(timerRef.current);
                                          }
                                          timerRef.current = setTimeout(() => {
                                            console.log('On Change===>>>', searchText);
                                            setSearchKeyword(searchText);
                                          }, 2000);
                                        }}
                                        style={{ padding: '8px 15px', marginBottom: '20px' }}
                                        className="table-searchbar"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div className="unbordered-table-wrapper admin-tables">
                                <Table
                                    columns={columns}
                                    onChange={handleTableChange}
                                    dataSource={JobsArray}
                                    pagination={{
                                      current: paginationData.current,
                                      pageSize: paginationData.pageSize,
                                      total: paginationData.total,
                                      showSizeChanger: true,
                                      showTotal: () =>
                                          `Total ${paginationData.total} ${formatMessage({
                                            id: 'component.table.items',
                                          })}`,
                                      pageSizeOptions: ['30', '35', '40', '45', '50'],
                                    }}
                                />
                              </div>
                            </>
                        );
                      }
                      if (!loading && error) {
                        return (
                            <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen.</div>
                        );
                      }
                      return <></>;
                    }}
                  </Query>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
  );
};

export default Jobs;
