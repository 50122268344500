import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN

const styles = StyleSheet.create({
    container: {
        flex: 1,

        backgroundColor: Color.WHITE
    },
    photocheck: {
        position: 'absolute',

        zIndex: 1,
        justifyContent: 'center',
        marginTop: ThemeUtils.relativeWidth(3.5),
        marginLeft: ThemeUtils.relativeWidth(1)
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        // alignContent: 'center'
    },
    titleCont: {
        marginHorizontal: containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    GridImage: {
        height: ThemeUtils.relativeWidth(41),
        marginEnd: (ThemeUtils.COMMON_HORIZONTAL_MARGIN + 7) / 2,
        width: ThemeUtils.relativeWidth(41),
        borderRadius: 8,
        marginTop: (ThemeUtils.COMMON_HORIZONTAL_MARGIN + 7) / 2,
        backgroundColor: Color.LIGHT_GRAY
    },
    subViewLbl:{
        width: ThemeUtils.relativeWidth(41),
    },
    SubViewIcon: {
        backgroundColor: Color.PRIMARY_LIGHT,
        height: ThemeUtils.relativeWidth(41),
        width: ThemeUtils.relativeWidth(41),
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    SubView: {
        flex: 1,
    },

    DetailsView: {marginTop: 20},
    MainView: {
        flexDirection: "row",
        marginTop: 15,
        justifyContent: 'space-between',
        flex: 1,
    },
    ImageGridView: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginBottom: 25,
        marginStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginEnd: ThemeUtils.COMMON_HORIZONTAL_MARGIN / 2
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labeliconView: {

        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    labelTopView: {
        alignItems: 'flex-start',
        flex: 1,
        flexDirection: 'row',
        marginTop: 15,
        alignContent: 'center',
        justifyContent: 'space-between'
    },

    energyradio: {
        marginBottom: 15,
        fontSize: 12,
        alignContent: 'center',

    },
    fieldView: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },

    icCheck: {
        position: 'absolute',
        zIndex: 10,
        top: 0,
        start: 0,
        end: 0,
        bottom: 0,
        paddingVertical: 10,
        paddingHorizontal: 10,
    },
    propertySelectionCont: {
        flexDirection: 'row',
        marginTop: 20,
    }

});
export {styles};
