import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
        marginTop: 20
    },
    displayCont: {
        flexDirection: 'row',
        flex: 1
    },
    lblCont: {
        flex: 0.4,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    serviceCont: {
        borderWidth: 1,
        borderRadius: 1,
        borderColor: Color.SECONDARY,
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginVertical: 10
    },
    imgCont: {
        flex: 1,
        flexDirection:'row',
        justifyContent:'center',
        paddingStart: 15,
        paddingBottom: 15
    },
    scrollCont: {
        paddingBottom: 15
    },
    imgBottom: {
        height: 100,
        width: 120,
        marginStart: 20
    },
    icCont: {
        zIndex:100,
        alignItems:'center',
        justifyContent:'center',
        position:'absolute',
        end:0,
        bottom:0,
        start:0,
        top:0,
        backgroundColor: Color.TRANSPARENT_BLACK
    },

})
