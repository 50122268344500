import React from 'react'
//Components
import {Animated, View} from 'react-native'
import {CustomButton, Header, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
// import { PickerView } from '@ant-design/react-native';
//Utils
import {styles} from './styles.js';
import {Color, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
// import {Button, InputItem} from "@ant-design/react-native";
// import { DatePickerView } from 'antd-mobile';


class TakeOver extends React.Component {

    constructor(props) {
        super(props);
        this.scrollY = new Animated.Value(0)
    }

    render() {
        return (
            <Provider locale={enUS}>

                <Header animatedTitle={Strings.takeover}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={{
                                         flexGrow: 1,
                                         paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2
                                     }}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.labeliconContainer}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'takeover'}
                                    color={Color.PRIMARY_DARK}/>
                                {/*  <Label xlarge
                                       ml={9}
                                       font_medium
                                       align={'left'}>
                                    {Strings.takeover}
                                </Label>*/}
                            </View>
                            <View style={styles.bottomView}>
                                <View>
                                    <Label
                                        mt={16}
                                        small
                                        font_regular
                                        align={'left'}>
                                        {Strings.takeoverintroduction}
                                    </Label>
                                </View>
                                <View>
                                    <CustomButton
                                        mt={18}
                                        mb={18}
                                        title={Strings.startacquisitionbutton}/>
                                </View>

                            </View>

                        </View>
                    </View>
                </Animated.ScrollView>
            </Provider>

        )
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TakeOver);
