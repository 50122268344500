import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, Keyboard, View} from 'react-native'
import {CustomButton, CustomTextArea, Header, Label} from "components/src/components";
//Third Party
import moment from "moment";
//Network
import {Mutation} from "@apollo/react-components";
import {useQuery} from "@apollo/react-hooks";
import {SELLER_FINN_DETAILS} from 'components/src/api/sellerQuery'
import {SELLER_ADD_FINN_COMMENT, SELLER_APPROVE_FINN_AD} from "components/src/api/sellerMutation";
//Utils
import {Color, DateUtils, Icon, IS_WEB, MessageUtils, openInNewTab, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import {connect} from "react-redux";

function SeeFinnAd(props) {

    const {history, navigation} = props;

    const [finnDetails, setFinDetails] = useState(null);
    const [comment, setComments] = useState('');
    const [finnUrl, setFinnUrl] = useState('');

    const scrollY = new Animated.Value(0)

    const {data, loading, error, refetch} = useQuery(SELLER_FINN_DETAILS, {
        variables: {
            propertyId: props?.property?.id
        },
        fetchPolicy: 'cache-and-network'
    });


    useEffect(() => {
        if (data?.property_finn_ad_details) {
            setFinDetails(data?.property_finn_ad_details)
            let url = data?.property_finn_ad_details?.url ?? "";
            if (url && !(url.startsWith("http://") || url.startsWith("https://"))) {
                url = `https://${url}`;
            }
            data?.property_finn_ad_details?.finn_comments.length && setComments(data?.property_finn_ad_details?.finn_comments.slice().pop()?.comment)
            setFinnUrl(url);
        }
    }, [data])

    const sendComment = (addComment) => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        addComment({
            variables: {
                id: finnDetails?.id,
                propertyId: props?.property?.id,
                comment: comment
            },
        });
    };

    const approveFinnAd = (approveFinn) => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        approveFinn({
            variables: {
                id: finnDetails?.id,
                propertyId: props?.property?.id
            },
        })
    }

    return (
        <View style={styles.cont}>
            <Header animatedTitle={Strings.seeFinnAdd}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 keyboardShouldPersistTaps={'always'}
                                 contentContainerStyle={styles.flexGrow}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.contentCont}>
                    <Icon name={'see-finn-ad'}
                          style={styles.icFinn}
                          size={ThemeUtils.fontXXXLarge}/>

                    <Label small
                           mb={20}
                           style={styles.lblInst}>
                        {Strings.finnInst}
                    </Label>

                    {loading && !data ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) :
                        !finnDetails ? (
                            <>
                                <Label align={'center'}
                                       small
                                       font_medium>
                                    {MessageUtils.Message.finnAdLinkNotAvailable}
                                </Label>

                            </>

                        ) : (
                            <>
                                {finnUrl ? (
                                    <CustomButton title={Strings.seeFinnNoAd}
                                                  onPress={() => {
                                                      openInNewTab(finnUrl)
                                                  }}
                                    />) : null}

                                {finnDetails?.is_approved ?
                                    (<View style={styles.lblCont}>
                                        <Label small
                                               align={'center'}>
                                            {`${Strings.approvedOn} ${moment(finnDetails.approved_date, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.hh_mm_dd_mm_yyy)}`}

                                        </Label>
                                    </View>)
                                    :
                                    (<Mutation mutation={SELLER_ADD_FINN_COMMENT}
                                               onError={() => {
                                               }}>
                                        {(addComment, {loading, data}) => (
                                            <>
                                                <CustomTextArea placeholder={Strings.writeYourComments}
                                                                mt={20}
                                                                value={comment}
                                                                onChange={setComments}
                                                                labelText={Strings.comments}/>
                                                <CustomButton title={Strings.send}
                                                              mt={20}
                                                              loading={loading}
                                                              onPress={() => sendComment(addComment)}
                                                              mr={ThemeUtils.relativeRealWidth(60)}/>
                                            </>
                                        )}
                                    </Mutation>)}

                                <Mutation mutation={SELLER_APPROVE_FINN_AD}
                                          onCompleted={() => refetch()}
                                          onError={() => {

                                          }}>
                                    {(approveFinn, {loading, data}) => (
                                        <View style={styles.btnApproveCont}>
                                            <CustomButton
                                                title={finnDetails?.is_approved ? Strings.okay : Strings.approve}
                                                onPress={() => finnDetails?.is_approved ? IS_WEB ? history.goBack() : navigation.goBack() : approveFinnAd(approveFinn)}/>
                                        </View>
                                    )}
                                </Mutation>

                            </>
                        )
                    }

                </View>
            </Animated.ScrollView>
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStateToProps)(SeeFinnAd);

