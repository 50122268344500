import React, {useEffect, useState} from 'react';
//Components
import {Image, SafeAreaView, ScrollView, Text, View} from 'react-native'
import {CustomButton, Header, Label, Modal, RadioGroup} from "components/src/components";
//Utils
import {Color, CommonStyle, openInNewTab, ThemeUtils} from "components/src/utils";
import styles from "./styles";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {BUYER_HOME_INSURANCE_INFO} from "../../../../../api/buyerQuery";
import {BUYER_UPDATE_HOME_INSURANCE_INFO} from "../../../../../api/buyerMutation";
import signUpInsurance from "../../../../../image/signup_Insurance.png";


const DAMAGE_REPORT_STEPS_1 = [{
    id: 1,
    step: "Dersom du opplever at boligen du har kjøpt har en skade eller et avvik som du ønsker" +
        "å få vurdert under Boligselgerforsikringen er det viktig at du sender en skademelding" +
        "til forsikringsselskapet så fort som mulig. Det gjelder nemlig en reklamasjonsfrist i" +
        "avhendingsloven, og denne strekker seg til to måneder, maksimalt tre måneder i" +
        "enkelte tilfeller, etter at du har oppdaget/burde ha oppdaget en skade/et avvik." +
        "Sendes ikke skademelding innen fristen risikerer du å miste muligheten til å få" +
        "dekning under Boligselgerforsikringen.\n\n" +
        "Merk at boligkjøperforsikringen ikke dekker/erstatter en skade/et avvik, det er en" +
        "rettshjelpsforsikring hvor spesialister som vil bistå deg i å vurdere om og hva du har" +
        "krav på etter lovens bestemmelser.",

}, {
    id: 2,
    step: "Lag en beskrivelse av avviket/skaden. Gjør denne så konkret som mulig, eksempelvis" +
        "hvor i boligen er skaden, hvordan viser skaden seg, etc."
}];
const SUB_STEPS = [
    "Beskrivelsen",
    "Salgsoppgaven",
    "Takst/tilstandsrapport",
    "Egenerklæringsskjema",
    "Kjøpekontrakt",
    "Overtagelsesprotokoll",
];
const DAMAGE_REPORT_STEPS_2 = [{
    id: 4,
    step: "Så snart forsikringsselskapet mottar det du har sendt inn vil de foreta en" +
        "gjennomgang og vurdering av din sak. Det kan imidlertid hende at de også er" +
        "avhengig av en fagkyndig uttalelse for å kunne gjennomføre sin vurdering. I så fall vil" +
        "de be deg om å innhente dette.",

}, {
    id: 5,
    step: "Endelig beslutning i din sak fattes da evt. etter mottagelse av fagkyndig" +
        "dokumentasjon."
}, {
    id: 6,
    step: "Forsikringsselskapet tilstreber å fatte beslutninger i alle saker så hurtig som mulig," +
        "men saksbehandlingstiden vil variere mellom tre til fem uker fra all dokumentasjon" +
        "er mottatt. Om saksbehandlingstiden i perioder med stor pågang skulle bli mer enn" +
        "det vil forsikringsselskapet informere om dette og oppgi ny saksbehandlingstid."
}, {
    id: 7,
    step: "Vi anbefaler alltid å avvente oppstart av utbedringsarbeider før du har sendt" +
        "skademelding til forsikringsselskapet og snakket med en saksbehandler. Selger har" +
        "alltid en rett til å få utbedre skaden for egen regning, og du som kjøper må godta at" +
        "selger gjør dette innenfor visse rammer. Dersom du utbedrer uten at selger har fått" +
        "muligheten til å utbedre, kan du risikere å miste retten til å få utbedringskostnadene" +
        "dine dekket."
}];

export default function HomeBuyerInsurance(props) {

    const [isInsuranceTaken, setInsurance] = useState(0);
    const [isStepModalVisible, setStepModalVisible] = useState(false);

    const {loading, error, refetch, data} = useQuery(BUYER_HOME_INSURANCE_INFO, {
        variables: {
            id: props.property?.id,
            status: 1
        }
    });

    useEffect(() => {
        if (data?.home_buyer_insurance_info) {
            setInsurance(data?.home_buyer_insurance_info?.want_insurance)
        }
    }, [data]);

    const [updateInsuranceInfo, updateOwnerInfoMutation] = useMutation(BUYER_UPDATE_HOME_INSURANCE_INFO, {
        onCompleted: () => {
            refetch && refetch()
        }
    });

    const saveInsuranceInfo = () => {
        updateInsuranceInfo({
            variables: {
                input: {
                    prop_buyer_info_id: props.property?.id,
                    insurance_info: {
                        want_insurance: isInsuranceTaken
                    }
                }
            }
        }).catch(() => {
        })
    };

    useEffect(() => {
        saveInsuranceInfo();
    }, [isInsuranceTaken]);

    const renderStep = (item, index) => {
        return (
            <View key={index.toString()}
                  style={styles.lblCont}>
                <Label small
                       mr={10}>
                    {`${item.id}.`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {item.step}
                </Label>
            </View>
        )
    };

    const renderSubStep = (item, index, self) => {
        return (
            <View key={index.toString()}
                  style={styles.lblSubStep}>
                <Label small
                       mb={index !== self.length - 1 ? 5 : 10}
                       ml={10}>
                    {`•  ${item}`}
                </Label>
            </View>
        )
    };

    const renderDamageReportStepModal = () => {
        return (
            <Modal visible={isStepModalVisible}
                   hardwareAccelerated
                   onRequestClose={() => setStepModalVisible(false)}>

                <SafeAreaView style={CommonStyle.safeArea}>
                <Header onPressLeft={() => setStepModalVisible(false)}/>
                    <ScrollView>
                        <View style={styles.lblSubStep}>
                            <Label align={'center'}
                                   font_medium
                                   mt={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                   mb={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                {"Skademelding og saksgang"}
                            </Label>
                            {DAMAGE_REPORT_STEPS_1.map(renderStep)}
                            <View style={styles.lblCont}>
                                <Label small
                                       mr={10}>
                                    {`${3}.`}
                                </Label>
                                <Label small
                                       style={CommonStyle.flex}>
                                    {"Send følgende dokumenter pr. e-post til "}
                                    <Text style={styles.lblLink}
                                          onPress={() => openInNewTab("mailto:post@claims-handling.no")}>
                                        {"post@claims-handling.no;"}
                                    </Text>
                                </Label>
                            </View>
                            {SUB_STEPS.map(renderSubStep)}
                            {DAMAGE_REPORT_STEPS_2.map(renderStep)}
                            <Label small
                                   style={CommonStyle.flex}
                                   mt={5}>
                                {"E-post: "}
                                <Text style={styles.lblLink}
                                      onPress={() => openInNewTab("mailto:post@claims-handling.no")}>
                                    {"post@claims-handling.no"}
                                </Text>
                            </Label>
                            <Label small
                                   style={CommonStyle.flex}
                                   mt={15}
                                   mb={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                {"Telefon: "}
                                <Text style={styles.lblLink}
                                      onPress={() => openInNewTab("tel:+47 953 60 660")}>
                                    {"+47 953 60 660"}
                                </Text>
                            </Label>

                        </View>
                    </ScrollView>
                </SafeAreaView>
            </Modal>
        )
    };


    const renderInsuranceRequiredContent = () => {
        return (
            <>
                <Label small
                       mb={15}>
                    {"Under finner Dere forsikringsvilkår og forsikringsbevis. Dersom Dere opplever at boligen" +
                    "Dere har kjøpt har en skade eller et avvik som Dere ønsker å få vurdert gjennom" +
                    "Boligkjøperforsikringen, trykk på «Meld skade» og følg instruksjonen der."}
                </Label>
                <CustomButton mb={20}
                              title={"Forsikringsbevis"}/>
                <CustomButton mb={20}
                              title={"Forsikringsvilkår"}/>
                <CustomButton mb={20}
                              onPress={() => setStepModalVisible(true)}
                              title={"Meld skade"}/>
            </>
        )
    };

    const renderInsuranceNotRequiredContent = () => {
        return (
            <>
                <Label small
                       mb={15}>
                    {"Hver fjerde eller femte bolighandel ender i boligtvist og vi anbefaler derfor på det sterkeste" +
                    "at alle våre kunder, både kjøper og selger, tegner forsikring i forbindelse med handelen.\n\n" +
                    "Boligkjøperforsikringen er en rettshjelpsforsikring som gir deg juridisk bistand hvis du" +
                    "oppdager vesentlige skjulte feil og mangler, hvis det er tilbakeholdt opplysninger eller der" +
                    "det er gitt uriktige opplysninger som har påvirket boligkjøpet ditt. Boligkjøperforsikringen gir" +
                    "deg dessuten juridisk rådgivning, advokathjelp og håndtering gjennom rettsapparatet, hvis" +
                    "du trenger dette."}
                </Label>
                <Label font_medium
                       mb={15}>
                    {"Siste anledning til å tegne boligkjøperforsikring er ved signering av kjøpekontrakten."}
                </Label>
                <Label small
                       mb={20}>
                    {"Om du likevel ikke ønsker å tegne forsikring er det viktig for oss at du er kjent med risikoen" +
                    "det innebærer å ikke tegne slik forsikring. Les mer under «Trenger jeg" +
                    "boligkjøperforsikring?» eller kontakt megler for nærmere informasjon om du er usikker."}
                </Label>
                <CustomButton mb={20}
                              title={"Trenger jeg boligkjøperforsikring?"}/>
            </>
        )
    };

    return (
        <ScrollView>
            <View style={styles.stepCont}>

                {renderDamageReportStepModal()}
                <Image source={signUpInsurance}
                       style={styles.imgSignUp}/>
                <Label large
                       font_medium
                       mt={15}
                       mb={15}
                       color={data?.home_buyer_insurance_info?.want_insurance === 0 ? Color.TEXT_COLOR_GREEN : Color.TEXT_COLOR_RED}
                       align={'center'}>
                    {data?.home_buyer_insurance_info?.want_insurance === 0 ? "Dere har tegnet forsikring" : "Dere er ikke forsikret"}
                </Label>
                {data?.home_buyer_insurance_info?.want_insurance === 0 ?
                    renderInsuranceRequiredContent() :
                    renderInsuranceNotRequiredContent()}

                {data?.home_buyer_insurance_info?.want_insurance !== 0 ? (
                    <RadioGroup changeOnProp options={[{
                        name: "Jeg vil tegne forsikring",
                        value: 0
                    }, {
                        name: "Jeg ønsker å være uforsikret og er kjent med risikoen",
                        value: 2
                    }, {
                        name: "Jeg kan ikke tegne forsikring ihht vilkår",
                        value: 3
                    }]}
                                selectedOptions={[isInsuranceTaken]}
                                disabled={props.property?.buyerContractDoc}
                                style={{marginBottom: 20}}
                                onSelectOption={(val) => {
                                    setInsurance(val)
                                }}/>) : null}
            </View>
        </ScrollView>
    )
}
