import React, {useState} from 'react'
//Components
import {Image,SafeAreaView, ScrollView, TouchableOpacity, View} from 'react-native'
import {
    CustomButton,
    Dialog,
    Header,
    Hr,
    InputField,
    Label,
    Ripple,
    UploadImageDialog,
    ZoomView
} from "components/src/components";
//Third party
import moment from "moment";
//Network
import {Mutation} from "@apollo/react-components";
import {SELLER_ADD_PHOTO_COMMENT, SELLER_DELETE_PHOTO_COMMENT} from "components/src/api/sellerMutation";
//Utils
import styles from './styles';
import {Color, CommonStyle, Constants, DateUtils, Icon, Strings, ThemeUtils} from "components/src/utils";
import {useMutation} from "@apollo/react-hooks";
import {DELETE_PHOTOS, REPLACE_PHOTO} from "../../../../../../api/photographerMutation";


export default function PhotoDetails(props) {
    const {image, refetch, user, onClose} = props;
    const [addComment, setAddComment] = useState(false)
    const [largeImage, setLargeImage] = useState(false)
    const [comment, setComment] = useState('')
    const [replaceImageVisible, setReplaceImageVisible] = useState(false)

    const [deleteVisible, setDeleteVisible] = useState('')

    const [deletePhoto, deletePhotoMutation] = useMutation(DELETE_PHOTOS)
    const [replacePhoto, replacePhotoMutation] = useMutation(REPLACE_PHOTO)
    if (largeImage) {
        return (
            <SafeAreaView style={CommonStyle.safeArea}>

                <View style={CommonStyle.flex}>
                    <TouchableOpacity onPress={() => setLargeImage(false)}
                                      style={styles.icClose}>
                        <Icon name={'close'}
                              color={Color.WHITE}
                              size={16}/>
                    </TouchableOpacity>
                    <ZoomView maxZoom={1.5}
                              minZoom={0.5}
                              zoomStep={0.5}
                              initialZoom={1}
                              bindToBorders={true} style={{
                        backgroundColor: Color.PRIMARY
                    }}>
                        <Image style={[styles.img, CommonStyle.flex]}
                               resizeMode={"contain"}
                               source={{uri: `${Constants.APIConfig.STORAGE_URL}${image?.photo_url}`}}/>
                    </ZoomView>
                </View>
            </SafeAreaView>
        )
    }

    const replaceImageModal = () => {
        return (
            <UploadImageDialog visible={replaceImageVisible}
                               loading={replacePhotoMutation.loading}
                               oldPhotoRequired={false}
                               onPositivePress={(newImage, oldImage) => {
                                   let variables = {
                                       id: image.id,
                                       image: newImage,
                                       orderId: image?.order_photo_id
                                   }
                                   replacePhoto({
                                       variables: variables,
                                   }).then(() => {
                                       setReplaceImageVisible(false)
                                       onClose();
                                       refetch()
                                   }).catch(() => {

                                   })
                               }}
                               onNegativePress={() => setReplaceImageVisible(false)}
                               title={Strings.choosePhoto}/>
        )
    };


    return (
        <SafeAreaView style={CommonStyle.safeArea}>

            <View style={styles.container}>

                {replaceImageModal()}
                <Header style={CommonStyle.blankHeader}
                        headerRightFirst={user?.id == image?.user_id ? 'replace' : null}
                        headerRightSecond={user?.id == image?.user_id ? 'delete' : null}
                        onPressRightSecond={() => setDeleteVisible(true)}
                        onPressRightFirst={() => setReplaceImageVisible(true)}
                        onPressLeft={onClose}/>

                <Dialog visible={deleteVisible}
                        transparent
                        loading={deletePhotoMutation.loading}
                        onNegativePress={() => setDeleteVisible(false)}
                        onPositivePress={() => {
                            deletePhoto({
                                variables: {
                                    ids: [image.id]
                                }
                            }).then(() => {
                                onClose()
                                refetch()
                                setDeleteVisible(true)
                            }).catch(() => {

                            })
                        }}
                        title={`${Strings.deletePhoto}`}
                        buttonTitleFirst={Strings.cancel}
                        buttonTitleSecond={Strings.okay}
                        color={Color.RED}
                        desc={Strings.areYouSureYouWantToDelete}/>

                <ScrollView>
                    <View>
                        <Ripple onPress={() => setLargeImage(true)}>
                            <Image style={styles.img}
                                   source={{uri: `${Constants.APIConfig.STORAGE_URL}${image?.photo_url}`}}/>
                        </Ripple>
                        <View style={styles.lblCont}>
                            <Label small
                                   font_medium
                                   style={CommonStyle.flex}>
                                {Strings.comments}
                            </Label>
                            <TouchableOpacity onPress={() => setAddComment(true)}>
                                <Icon name={'edit'}
                                      color={Color.LIGHT_BLUE}
                                      size={ThemeUtils.fontNormal}/>
                            </TouchableOpacity>
                        </View>

                        {addComment ? (
                                <View>
                                    <InputField textArea
                                                ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                placeholder={Strings.writeYourComments}
                                                value={comment}
                                                onChange={setComment}/>
                                    <View style={styles.btnCont}>
                                        <Mutation mutation={SELLER_ADD_PHOTO_COMMENT}
                                                  onError={() => {

                                                  }}
                                                  onCompleted={() => {
                                                      refetch && refetch();
                                                      onClose();
                                                  }}>
                                            {(addComment, {loading}) => {
                                                return (
                                                    <CustomButton title={Strings.send}
                                                                  onPress={() => {
                                                                      addComment({
                                                                          variables: {
                                                                              input: {
                                                                                  property_id: image?.property_id,
                                                                                  photo_id: image?.id,
                                                                                  comment: comment,
                                                                              }
                                                                          }
                                                                      })
                                                                  }}
                                                                  loading={loading}
                                                                  style={{flex: 1}}
                                                                  mr={10}/>)
                                            }}
                                        </Mutation>

                                        <CustomButton title={Strings.cancel}
                                                      onPress={() => {
                                                          setAddComment(false)
                                                      }}
                                                      style={{flex: 1}}
                                                      textColor={Color.PRIMARY}
                                                      borderWidth={1}
                                                      borderColor={Color.PRIMARY_LIGHT}
                                                      bgColor={Color.WHITE}
                                                      ml={10}/>
                                    </View>
                                </View>
                            ) :
                            (<View>
                                {image?.comments?.map((item) => (
                                    <View>
                                        <View style={styles.lblCont}>
                                            <Label small
                                                   mr={10}
                                                   style={CommonStyle.flex}>
                                                {item.comment}
                                            </Label>

                                            <Mutation mutation={SELLER_DELETE_PHOTO_COMMENT}
                                                      onError={() => {
                                                      }}
                                                      onCompleted={() => {
                                                          refetch && refetch();
                                                          onClose();
                                                      }}>
                                                {(deleteComment, {loading}) => {
                                                    return (
                                                        <TouchableOpacity onPress={() => {
                                                            deleteComment({
                                                                variables: {
                                                                    input: {
                                                                        property_id: image?.property_id,
                                                                        photo_id: image?.id,
                                                                        comment_id: item.id
                                                                    }
                                                                }
                                                            })
                                                        }}>
                                                            <Icon name={'delete'}
                                                                  color={Color.PRIMARY_LIGHT}
                                                                  size={ThemeUtils.fontNormal}/>
                                                        </TouchableOpacity>
                                                    )
                                                }}
                                            </Mutation>

                                        </View>
                                        {item.created_at ?
                                            (<Label small
                                                    ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                    mb={10}
                                                    color={Color.PRIMARY_LIGHT}>
                                                {moment.utc(item.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.hh_mm_dd_mm_yyy)}
                                            </Label>) : null}
                                        <Hr/>
                                    </View>
                                ))}
                            </View>)
                        }
                    </View>
                </ScrollView>

            </View>
        </SafeAreaView>
    )
}
