import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, FlatList, View} from 'react-native';
import {Header, Hr, IndicatorViewPager, Label, PagerTabIndicator} from "components/src/components";
//Utils
import {Color, CommonStyle, DateUtils, formatPrice, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import styles from "./styles";
import moment from "moment";
import {useQuery} from "@apollo/react-hooks";
import {SELLER_BID_QUERY} from "components/src/api/sellerQuery";
import {connect} from "react-redux";
import PureChart from "../../../../../components/ui/Charts";

const TABS = [{
    text: Strings.bidJournal
}, {
    text: "Aktive bud"
}, {
    text: "Historiske bud"
}];



function Bids(props) {
    const {history, property, navigation} = props;
    const scrollY = new Animated.Value(0)
    const [activeBid, setActiveBid] = useState([]);
    const [completedBid, setCompletedBid] = useState([]);
    const [finalData, setFinalData] = useState([]);

    const propertyId = IS_WEB ? props.history.location?.state?.id : props.navigation.getParam('id');


    const {loading, data, error} = useQuery(SELLER_BID_QUERY, {
        variables: {
            propertyId: property?.id
        }
    })

    useEffect(() => {
        if (data?.property_bids) {
            setCompletedBid(data?.property_bids?.bids?.filter(item => moment(item.expires).diff(moment(), 's') < 0 && !item.rejectedDate).sort((a, b) => moment(b.time).diff(moment(a.time))))
            setActiveBid(data?.property_bids?.bids?.filter(item => moment(item.expires).diff(moment(), 's') > 0 || item.rejectedDate).sort((a, b) => moment(b.time).diff(moment(a.time))))
            const bidders = data?.property_bids?.bids?.filter((item, index, self) => self.findIndex(selfItem => selfItem.partyid === item.partyid) === index).map(item => item.partyid)
            let bidderTotals = data?.property_bids?.bids?.map(item => {
                return {
                    /*bidder: item.partyid,
                    value: item.amount,*/
                    x: `${Strings.bidder} ${item.partyid}`,
                    y: item.amount,
                    color: data?.property_bids?.color[bidders.indexOf(item.partyid)]

                }
            })
            setFinalData(bidderTotals)

        }
    }, [data]);

    const renderBid = (item, index, isExpired) => {
        return (
            <View key={index.toString()}>
                <View style={styles.bidCont}>
                    <View style={styles.bidLblCont}>
                        <Label small
                               font_medium
                               style={CommonStyle.flex}>
                            {Strings.status}
                        </Label>
                        {item.accepted ?
                            (<Label small
                                    font_medium
                                    color={Color.TEXT_COLOR_GREEN}>
                                {Strings.accepted}
                            </Label>) : isExpired ?
                                (<Label small
                                        font_medium
                                        color={Color.TEXT_COLOR_RED}>
                                    {Strings.expired}
                                </Label>) : null}

                        {item.rejectedDate ?
                            (<Label small
                                    font_medium
                                    color={Color.TEXT_COLOR_RED}>
                                {Strings.rejected}
                            </Label>) : !isExpired ?
                                (<Label small
                                        font_medium
                                        color={Color.PRIMARY}>
                                    {Strings.active}
                                </Label>) : null}
                    </View>

                    <View style={styles.bidLblCont}>
                        <Label small
                               style={CommonStyle.flex}>
                            {Strings.bid}
                        </Label>
                        <Label small>
                            {item.bidId}
                        </Label>
                    </View>
                    <View style={styles.bidLblCont}>
                        <Label small
                               style={CommonStyle.flex}>
                            {Strings.amount}
                        </Label>
                        <Label small>
                            {`Kr ${formatPrice(item.amount)},-`}
                        </Label>
                    </View>
                    <View style={styles.bidLblCont}>
                        <Label small
                               style={CommonStyle.flex}>
                            {Strings.dateTime}
                        </Label>
                        <Label small>
                            {moment(item.time).format(DateUtils.hh_mm_dd_mm_yyy)}
                        </Label>
                    </View>
                    <View style={styles.bidLblCont}>
                        <Label small
                               style={CommonStyle.flex}>
                            {Strings.expiredOn}
                        </Label>
                        <Label small>
                            {moment(item.expires).format(DateUtils.hh_mm_dd_mm_yyy)}
                        </Label>
                    </View>

                    <View style={styles.bidLblCont}>
                        <Label small
                               style={CommonStyle.flex}>
                            {Strings.rejectedDate}
                        </Label>
                        <Label small>
                            {item?.rejectedDate ? moment(item.rejectedDate).format(DateUtils.hh_mm_dd_mm_yyy) : null}
                        </Label>
                    </View>
                    <View style={styles.bidLblCont}>
                        <Label small
                               style={CommonStyle.flex}>
                            {"Type bud"}
                        </Label>
                        <Label small>
                            {item.type === 1 ? "Bud" : "Motbud"}
                        </Label>
                    </View>
                    <View style={styles.bidLblCont}>
                        <Label small
                               style={CommonStyle.flex}>
                            {Strings.bidder}
                        </Label>
                        <Label small>
                            {item.type === 2 ? Strings.seller : `${Strings.bidder} ${item.partyid}`}
                        </Label>
                    </View>
                    <View style={styles.bidLblCont}>
                        <Label small
                               style={CommonStyle.flex}>
                            {"Akseptert"}
                        </Label>
                        <Label small>
                            {item.accepted === 1 ? Strings.yes : Strings.no}
                        </Label>
                    </View>
                    <View style={styles.bidLblCont}>
                        <Label small
                               style={CommonStyle.flex}>
                            {"Forbehold"}
                        </Label>
                        <Label small>
                            {item.reservations ? Strings.yes : Strings.no}
                        </Label>
                    </View>


                </View>
                <Hr/>
            </View>
        )
    }

    const renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS} scrollEnabled/>;
    };


    return (
        <View style={CommonStyle.container}>

            <Header navigation={IS_WEB ? history : navigation}
                    animatedTitle={Strings.bids}
                    animatedValue={scrollY}
            />

            <Animated.View style={{marginTop: 15, flex: 1}}>
                <Icon name={'bid-round-handled-by-broker'}
                      style={{marginStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN}}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY}/>
                <Label small
                       mt={20}
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                    {'Her finner du informasjon om budrunden slik selger ser det i sitt dashboard.'}
                </Label>
                <IndicatorViewPager indicator={renderTabIndicator()}
                                    style={CommonStyle.flex}>
                    <View style={{marginTop: 20}}>
                        <Animated.ScrollView scrollEventThrottle={1}>
                            <View>
                                <View style={{flexDirection: 'row'}}>
                                    {/*<YAxis
                                        data={finalData}
                                        yAccessor={({index}) => index}
                                        scale={scale.scaleLinear}
                                        contentInset={{top: 10, bottom: 10}}
                                        spacing={0.2}
                                        formatLabel={(value, index) => index}
                                    />*/}


                                </View>
                                {/* <BarChart
                                    style={{height: ThemeUtils.relativeRealHeight(40)}}
                                    data={finalData.reverse()}
                                    yAccessor={({item}) => item.value}>
                                    <Grid/>
                                </BarChart>
                                <XAxis
                                    style={{marginTop: 10}}
                                    data={finalData.map(item=>item.data[0]?.bidder)}
                                    scale={scale.scaleLinear}
                                    formatLabel={(value, index) => {
                                        return index
                                    }}
                                    labelStyle={{color: Color.PRIMARY}}
                                />*/}

                                {loading && !data ? (

                                    <ActivityIndicator color={Color.PRIMARY}
                                                       style={CommonStyle.loader}/>

                                ) : finalData.length ? (<Animated.ScrollView horizontal
                                                                             contentContainerStyle={{
                                                                                 paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
                                                                             }}
                                                                             showsHorizontalScrollIndicator={false}>

                                    <PureChart type={'bar'}
                                               data={[{
                                                   seriesName: 'test2',
                                                   data: finalData.slice()
                                               }]}

                                               barHeight={ThemeUtils.relativeRealHeight(40)}
                                               numberOfYAxisGuideLine={finalData.length ? finalData.length : 10}
                                               height={ThemeUtils.relativeRealHeight(50)}
                                               highlightColor={Color.PRIMARY_LIGHT}
                                               labelColor={Color.PRIMARY}
                                               showEvenNumberXaxisLabel={false}
                                    />
                                </Animated.ScrollView>) : (
                                    <Label small
                                           align={'center'}>
                                        {Strings.noBids}
                                    </Label>
                                )}

                            </View>


                            {/*{console.log(finalData)}
                            <XAxis
                                style={{marginTop: 10}}
                                data={finalData}
                                scale={scale.scaleBand}
                                formatLabel={(value, index) => value.toString()}
                                labelStyle={{color: 'black'}}
                            />*/}
                        </Animated.ScrollView>
                    </View>
                    <View style={styles.cont}>
                        <FlatList data={activeBid}
                                  ListEmptyComponent={
                                      <Label mt={20}
                                             small
                                             align={'center'}>
                                          {Strings.noBids}
                                      </Label>
                                  }
                                  extraData={activeBid}
                                  renderItem={({item, index}) => renderBid(item, index, false)}/>
                    </View>
                    <View style={styles.cont}>
                        <FlatList data={completedBid}
                                  ListEmptyComponent={
                                      <Label mt={20}
                                             align={'center'}
                                             small>
                                          {Strings.noBids}
                                      </Label>
                                  }
                                  extraData={completedBid}
                                  renderItem={({item, index}) => renderBid(item, index, true)}/>
                    </View>
                </IndicatorViewPager>
            </Animated.View>


        </View>
    )

}

const mapStateToProps = (state) => {

    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(Bids);
