import gql from 'graphql-tag';

export const LOG_OUT=gql`
mutation logout {
  logout {
    meta {
      status
      message
      code
    }
  }
}`;

export const FORGOT_PASSWORD=gql`
mutation ForgotPassword($email:String!,$userTypeId:Int!){
  forgot_password_email(email:$email,user_type_id:$userTypeId){
    meta{
      status
      message
      code
    }
  }
}`;

export const CHANGE_PASSWORD = gql`
  mutation change_password($input: ChangePasswordInput!) {
    change_password(input: $input) {
      user {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        zipcode
        status
      }
      token {
        access_token
        token_type
        expires_in
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      firebase_token
      user {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        zipcode
        status
        user_type_id
      }
      token {
        access_token
        token_type
        expires_in
      }
    }
  }
`;

export const CHANGE_PROFILE_PIC=gql`
mutation changeProfilePic($file:Upload!){
  change_profile_pic(profile_pic:$file)
}`;


export const SWITCH_ACCOUNT=gql`
mutation switchAccount {
  switch_account {
    user {
      id
      first_name
      last_name
      email
      address
      mobile
      profile_image
      city
      zipcode
      status
      user_type_id
    }
    token {
      access_token
      token_type
      expires_in
    }
  }
}`;

export const REFRESH_TOKEN=gql`
mutation refresh_token{
  refresh_token{
    token{
      access_token
      token_type
      expires_in
    }
  }
}`
