import {StyleSheet} from 'react-native';
import {Color} from '../../../../../utils';

const containerHorizontalMargin = 20;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    titleCont: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    checkbox: {alignItems: 'flex-start', flex: 1, flexDirection: 'column', alignContent: 'center',},

    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },
    uploadView: {
        alignSelf: 'flex-start',
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: Color.INPUT_BORDER,
        justifyContent: 'center',
        alignContent: 'center',
        borderRadius: 6
    },

    UploadedMainView: {
        flexDirection: 'row',
        width: '100%',
        padding: 10,
        justifyContent: 'space-between',
        backgroundColor: Color.UPLOAD_BG_COLOR,
        borderRadius: 6
    },
    fieldContainer: {
        alignContent: 'center',
    },
    labeliconView:
        {
            marginTop: 20,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },

    energyradio: {
        marginVertical: 10,
    },
    IconView:
        {
            marginLeft: 12,
            flexDirection: 'row',
            alignItems: 'center',


        },
    IconViews:
        {
            marginRight: 12,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around'
        },
    DeleteIcon: {marginLeft: 7},
    DownloadIcon: {marginRight: 7},
    filePicker: {
        marginBottom: 15,
        alignSelf: 'flex-start'
    }
});
export {styles};
