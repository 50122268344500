import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {
    Accordion,
    Header,
    Hr,
    IndicatorViewPager,
    Label,
    PagerTabIndicator,
    Ripple,
    Tag
} from "components/src/components";
//Network
import {SELLER_PROPERTY_DISPLAY_VITEC} from "components/src/api/sellerQuery";
import {useQuery} from "@apollo/react-hooks";
//Utils
import styles from './styles';
import {Color, CommonStyle, DateUtils, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import moment from "moment";
import {connect} from "react-redux";

const TABS = [{
    text: Strings.upComingView
}, {
    text: Strings.completedView
}];


function Display(props) {

    const {history, navigation} = props;
    const [upcomingDisplay, setUpComingDisplay] = useState([])
    const [completedDisplay, setCompletedDisplay] = useState([])
    const scrollY = new Animated.Value(0)

    const {loading, error, data} = useQuery(SELLER_PROPERTY_DISPLAY_VITEC, {
        variables: {
            propertyId: props.property?.id
        }
    });

    useEffect(() => {
        if (data?.vitec_property_display) {
            setCompletedDisplay(data.vitec_property_display.filter(item => {
                return moment(item.startDate, DateUtils.yyyy_mm_dd_hh_mm_ss).diff(moment(), 's') < 0
            }));
            setUpComingDisplay(data.vitec_property_display.filter(item => {
                return moment(item.startDate, DateUtils.yyyy_mm_dd_hh_mm_ss).diff(moment(), 's') > 0
            }))
        }
    }, [data]);

    const getStatusColor = (item) => {
        return Color.PRIMARY_TEXT_COLOR
        if (item.is_completed) {
            return Color.TEXT_COLOR_GREEN;
        }
        return item.is_broker_on_view === 'Broker on View' ? Color.TEXT_COLOR_BLUE : Color.TEXT_COLOR_ORANGE
    };


    const _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };

    const renderDisplayHeader = (item, status) => {
        return (
            <View style={styles.displayTimeCont}>
                <View style={CommonStyle.flex}>

                    {/*<Tag
                        name={item.is_broker_on_view === 'Broker on View' ? Strings.brokerOnView : Strings.sellerOnView}
                        small
                        ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                        mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                        mt={3}
                        color={getStatusColor(item)}/>*/}
                    <Label small
                           font_medium
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                        {`${moment(item.startDate, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.dd_mm_yyyy_hh_mm)}`}
                    </Label>
                </View>
            </View>
        )
    };

    const renderDisplayContent = () => {
        return (
            <Label small
                   mt={3}
                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                   mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                {''}
            </Label>
        )
    };

    const renderDisplay = (item, index) => {
        return (
            <>
                <Accordion isExpanded
                           iconColor={Color.DARK_GRAY}
                           headerContainerStyle={styles.accordionHeader}
                           contentContainerStyle={styles.accordionContent}
                           tintColor={Color.PRIMARY}
                           renderContent={() => renderDisplayContent()}
                           renderHeader={() => renderDisplayHeader(item)}
                />
                <Hr/>
            </>
        )
    };

    const renderUpcomingDisplay = (item, index) => {
        return (
            <Ripple >
                <View style={styles.upComingCont}>
                    <View style={CommonStyle.flex}>
                        {renderDisplayHeader(item)}
                        <Label small
                               mt={5}
                               ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               mb={10}
                               numberOfLines={2}>
                            {item.showingnote}
                        </Label>
                    </View>
                    {/*<Icon name={'drop-normal'}
                          size={12}
                          style={styles.icDrop}
                          color={Color.PRIMARY_LIGHT}/>*/}
                </View>
                <Hr/>
            </Ripple>
        )
    };

    const renderCompletedDisplay = (item, index) => {
        return (
            <View>
                <View style={styles.upComingCont}>
                    <View style={CommonStyle.flex}>
                        {renderDisplayHeader(item)}
                        <Label small
                               mt={5}
                               ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               mb={10}
                               numberOfLines={2}>
                            {item.showingnote}
                        </Label>
                    </View>
                    {/* <Icon name={'drop-normal'}
                          size={12}
                          style={styles.icDrop}
                          color={Color.PRIMARY_LIGHT}/>*/}
                </View>
                <Hr/>
            </View>
        )
    };

    return (
        <View style={styles.cont}>
            <Header title={Strings.display}
                    tintColor={Color.SECONDARY}
                    style={CommonStyle.blankHeader}
                    navigation={IS_WEB ? history : navigation}/>

            <View>
                <View style={styles.contentCont}>
                    <View style={styles.titleCont}>
                        <Icon name={'display-package'}
                              size={ThemeUtils.fontXXLarge}/>
                        <Label xlarge
                               font_medium
                               mt={5}
                               ml={10}>
                            {Strings.display}
                        </Label>
                    </View>
                    <Label small
                           mb={10}
                           style={styles.lblInst}>
                        {Strings.displayInst}
                    </Label>
                </View>

            </View>
            <IndicatorViewPager indicator={_renderTabIndicator()}
                                style={{flex: 1, backgroundColor: 'white'}}>

                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         contentContainerStyle={styles.flexGrow}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                             {useNativeDriver: true})}>
                        <View>
                            {upcomingDisplay.length ? upcomingDisplay.map(renderUpcomingDisplay) :
                                loading ? (<ActivityIndicator color={Color.PRIMARY}
                                                              style={CommonStyle.loader}/>) : (
                                    <Label small
                                           font_medium
                                           style={CommonStyle.loader}
                                           align={'center'}>
                                        {"Ingen data"}
                                    </Label>)
                            }

                        </View>
                    </Animated.ScrollView>

                </View>

                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         contentContainerStyle={styles.flexGrow}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                             {useNativeDriver: true})}>
                        <View>
                            {completedDisplay.length ? completedDisplay.map(renderCompletedDisplay) :
                                loading ? (<ActivityIndicator color={Color.PRIMARY}
                                                              style={CommonStyle.loader}/>) : (
                                    <Label small
                                           font_medium
                                           style={CommonStyle.loader}
                                           align={'center'}>
                                        {"Ingen data"}
                                    </Label>)}
                        </View>
                    </Animated.ScrollView>

                </View>

            </IndicatorViewPager>

            {/*<CustomButton title={`+ ${Strings.addView}`}
                          style={{borderRadius: 0, height: 40,}}
                          textColor={Color.PRIMARY_TEXT_COLOR}
                          onPress={() => IS_WEB ? history.push(Routes.AddView) : navigation.navigate(Routes.AddView)}/>*/}

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStateToProps)(Display);
