import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from '../../../../utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labeliconContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    instructionalContainer: {
        marginVertical:15,
        marginHorizontal:containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    deviderline: {
        height: 0.1,
        flexDirection: 'row',
        borderColor: '#9C8B9D',
        width: "100%",
        borderWidth: 0.5,
    },
    iconView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    imageProfile: {
        width: 150,
        height: 150,
        borderRadius: 12,
        marginBottom: 10
    },
    fieldView: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    buttonContainer: {
        marginTop: '80%',
        flexDirection: 'row',
        justifyContent: 'space-between',

    },
    imgThumb:{
        height:80,
        width:80,
        borderRadius:10,
    }

});
export {styles};
