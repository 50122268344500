import gql from 'graphql-tag';


export const INSPECTION_UPDATE_PROFILE = gql`
  mutation updatebookinspection_profile($input: UpdateBookInspectionProfileInput) {
    updatebookinspection_profile(input: $input) {
      user {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        zipcode
        status
      }
    }
  }
`;

export const INSPECTION_UPDATE_ORDER = gql`
  mutation updateInspectionOrder($input: OrderBookInspectionUpdateInput) {
  update_inspection_order(input: $input) {
    orderbookinspection {
      id
      property_id
      inspection_id
      order_date
      order_status
      earliest_start_time
      latest_start_time
      comment
      property {
        oppdrags_nummer
        address
        seller {
          first_name
          last_name
          email
          mobile
        }
      }
    }
  }
}`;

export const TAKST_ADD_APPRAISER = gql`
  mutation order_rate_detail($input: OrderRateDetailInput!) {
    order_rate_detail(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const INSPECTION_START_INSPECTION=gql`
mutation startPropertyInspection($propertyId:Int!,$orderInpspectionId:Int!){
  start_property_inspection(property_id:$propertyId, order_inspection_id:$orderInpspectionId){
    id
    property_id
    key_sales_point
    recommended_value
    location
    general_impression
    other_comment
    Property{
      id
      oppdrags_nummer
      address
      postnr
      poststed
    }
    PropertyInspectionRoomType{
      id
      name
      room_title_id
      inspection_report_id
      answer_json
    }
    PropertyInspectionPhotos{
      id
      inspection_report_id
      photo_url
      vitec_unique_uuid
      vitec_image_id
    }
  }
}`;

export const ADD_EXTRA_ROOMS=gql`
mutation addExtraRooms($input: AddExtraRoomInput!) {
  add_extra_room(input: $input) {
    id
    inspection_report_id
    room_title_id
    name
    answer_json
  }
}
`
export const ADD_ROOM_ANSWER=gql`
mutation saveAnswer($input:SaveRoomTypeDetail!){
  save_room_type_answer(input:$input){
    id
    inspection_report_id
    name
    answer_json
  }
}`

export const INSPECTION_SEND_REPORT=gql`
mutation sendReport($id:Int!,$propertyId:Int!,$orderInspectionId:Int!){
  send_report(id:$id,property_id:$propertyId,order_inspection_id:$orderInspectionId){
    status
    message
    code
  }
}`

export const INSPECTION_SAVE_REPORT=gql`
mutation savePropertyInspection($input: SaveInpectionReport!){
  save_inspection_report(input:$input){
    id
    property_id
    key_sales_point
    recommended_value
    location
    general_impression
    other_comment
    other_comment
    PropertyInspectionRoomType{
      id
      inspection_report_id
      room_title_id
      name
      answer_json
    }
    Property{
      id
    }
    PropertyInspectionPhotos{
      id
      inspection_report_id
      photo_url
      vitec_unique_uuid
      vitec_image_id
    }
  }
}`
