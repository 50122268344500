import React from 'react';
import { Animated, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import Button from "@ant-design/react-native/lib/button";
import { Label, InputField } from '../../../components';

import AntDesignIcon from 'react-native-vector-icons/dist/AntDesign';

import DatePicker from "@ant-design/react-native/lib/date-picker";


import List from "@ant-design/react-native/lib/list";
import Provider from "@ant-design/react-native/lib/provider";

import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import InputItem from "@ant-design/react-native/lib/input-item";

// import ButtonStyle from '@ant-design/react-native/lib/button/style/index';

import {
    Color,
    CommonStyle,
    IS_ANDROID,
    ThemeUtils, Icon
} from '../../../utils';

const CustomDatePicker = (props) => {

    const getStyleArray = () => {
        let stylesArray = [];

        stylesArray.push({
            marginTop: props.mt,
            marginBottom: props.mb,
            marginLeft: props.ml,
            marginRight: props.mr,
            backgroundColor: props.backgroundColor
        });

        return stylesArray;
    }

    return (
        <View style={getStyleArray()}>
            {props.labelText &&
                <Label small font_medium align={'left'} mb={props.lblMb}>{props.labelText}</Label>}
            <View>
                <List >
                    <DatePicker min={new Date(moment().add(1, 'd'))}
                        value={props.value}
                        mode={props.datePickerMode}
                        title={props.datePickerTitle}
                        defaultDate={props.defaultDate}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        // maxDate={new Date(2026, 11, 3)}
                        // onChange={this.onChange}
                        use12Hours={props.use12Hours}
                        format="YYYY-MM-DD"
                        onOk={(event) => {
                            if (props.onOk) {
                                props.onOk(event)
                            }
                        }}
                        onDismiss={() => {
                            if (props.onDismiss) {
                                props.onDismiss()
                            }
                        }}>
                        <List.Item ></List.Item>
                    </DatePicker>
                </List>
                <View pointerEvents='none' style={{
                    backgroundColor: props.backgroundColor,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    alignContent: 'center',
                    justifyContent: 'center'
                }}>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center', alignItems: 'center' }}>
                        <View style={{ flex: 1 }}>
                            <View style={{ flex: 1, justifyContent: 'center', margin: 2 }}>
                                <InputItem
                                    style={{ fontSize: ThemeUtils.fontNormal, color: Color.PRIMARY_DARK, }}
                                    disabled={false}
                                    editable={false}
                                    type="phone"
                                    placeholder={props.placeholder}
                                    value={props.valueText} />
                            </View>
                        </View>
                        {/* <Label
                            style={{flex: 1}}
                            ml={12}
                            mb={0}
                            align={'left'}>{props.valueText}</Label> */}
                        <View style={{ marginHorizontal: 10 }}>
                            <Icon
                                size={ThemeUtils.fontNormal}
                                name={props.datePickerMode === 'date' ? 'calendar' : 'time'}
                                color={Color.PRIMARY_DARK} />
                        </View>
                    </View>
                </View>
                <View pointerEvents='none' style={{
                    backgroundColor: Color.TRANSPARENT,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    alignContent: 'center',
                    justifyContent: 'center',
                    borderColor: props.borderColor,
                    borderWidth: props.borderWidth,
                    borderRadius: props.borderRadius
                }} />
            </View>
            <View>

            </View>
        </View>
    );
};

CustomDatePicker.defaultProps = {
    backgroundColor: Color.SECONDARY_BG_COLOR,
    labelText: undefined,
    valueText: '',
    placeholder: '',

    borderColor: Color.INPUT_BORDER,
    borderWidth: 0.5,
    borderRadius: 4,
    lblMb: 10,

    datePickerMode: 'date',
    datePickerTitle: '',
    use12Hours: false,

    defaultDate: undefined,
    minDate: undefined,
    maxDate: undefined,

    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
};

CustomDatePicker.propTypes = {
    backgroundColor: PropTypes.string,
    labelText: PropTypes.string,
    valueText: PropTypes.string,
    placeholder: PropTypes.string,

    borderColor: PropTypes.string,
    borderWidth: PropTypes.number,
    borderRadius: PropTypes.number,

    lblMb: PropTypes.number,

    datePickerMode: PropTypes.string,
    datePickerTitle: PropTypes.string,
    use12Hours: PropTypes.bool,

    defaultDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),

    mt: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
    mr: PropTypes.number,
};

export default CustomDatePicker;
