import React from 'react'
//Components
import {ActivityIndicator, Animated, ScrollView, View} from 'react-native'

import {
    Attachment,
    CustomButton,
    DatePicker,
    FilePicker,
    FileType,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label,
    PagerTabIndicator
} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import moment from "moment";
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {TAKST_ORDER_INFORMATION} from "components/src/api/takstQuery";
import {TAKST_ADD_APPRAISER, TAKST_UPDATE_ORDER_LIST} from "components/src/api/takstMutation";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    IS_WEB,
    MessageUtils,
    openInNewTab,
    Routes,
    showMessage,
    Strings,
    validation
} from "components/src/utils";
import {styles} from './styles.js';
import {decryptId} from "web/src/utils";

const TABS = [{
    text: Strings.orderinginformation
}, {
    text: Strings.taxinformation
}];

class OrderDetails extends React.Component {
    constructor(props) {
        super(props)
        this.order = IS_WEB ? props.history.location.state?.order ?? {
            id: parseInt(decryptId(props.match.params.id), 10),
            property_id: parseInt(decryptId(props.match.params.propertyId), 10)
        } : props.navigation.getParam('order', {});

        this.state = {
            orderDate: this.order?.order_date ? new Date(moment(this.order.order_date, DateUtils.yyyy_mm_dd)) : undefined,
            startTime: this.order?.earliest_start_time ? new Date(moment(this.order.earliest_start_time, DateUtils.hh_mm_ss)) : undefined,
            endTime: null,

            taxDate: null,
            file: [],

            uploadPhotoShow: false,
            inspectionDate: undefined,
            inspectionDateDisplay: '',
            earlierStartTime: undefined,
            earlierStartTimeDisplay: '',
            latestStartTime: undefined,
            latestStartTimeDisplay: '',
            comments: undefined,
            totalAmount: 0,
            displayAmount: undefined,
            valuerate: '',
            errorvaluerate: null,
            loanrate: '',
            errorloanrate: null,
            bta: '',
            errorbta: null,
            bra: '',
            errorbra: null,
            prom: '',
            errorprom: null,
            constyears: '',
            errorconstyear: null,
            detailsone: [
                {
                    index: 1,
                    Oppdrag: '1005619',
                    sellername: 'Emilie Pettersen',
                    email: 'emilieP12@webmegling.no',
                    phone: '955-536-65',
                    date: '22-02-2020',
                    time: '8:00 - 13:00',
                    propertyaddress: 'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350'
                },
            ],
        }

        this.scrollY = new Animated.Value(0)
    }

    handleChangeText = (text, fieldId) => {
        switch (fieldId) {
            case Constants.FieldId.VALUE_RATE:
                this.setState({
                    valuerate: text,
                    errorvaluerate: null
                });
                break;
            case Constants.FieldId.LOAN_RATE:
                this.setState({
                    loanrate: text,
                    errorloanrate: null
                });
                break;
            case Constants.FieldId.BTA:
                this.setState({
                    bta: text,
                    errorbta: null
                });
                break;
            case Constants.FieldId.BRA:
                this.setState({
                    bra: text,
                    errorbra: null
                });
                break;
            case Constants.FieldId.PROM:
                this.setState({
                    prom: text,
                    errorprom: null
                });
                break;
            case Constants.FieldId.CONST_YEAR:
                if (text.length < 5) {
                    this.setState({
                        constyear: text,
                        errorconstyear: null
                    });
                }
                break;
        }
    };
    radionbuttonview = (addAppraiserInfo, orderRateData) => {
        const {taxDate, file, valuerate, loanrate, bta, bra, prom, constyear} = this.state;
        this.setState({
            errorvaluerate: validation('valuerate', valuerate),
            errorloanrate: validation('loanrate', loanrate),
            errorbta: validation('bta', bta),
            errorbra: validation('bra', bra),
            errorprom: validation('prom', prom),
            errorconstyear: validation('Constyears', constyear),
        }, () => {

            if (!this.state.errorvaluerate && !this.state.errorloanrate && !this.state.errorbta && !this.errorbra && !this.errorprom && !this.errorconstyear) {
                if (taxDate) {
                    if (file.length) {
                        addAppraiserInfo({
                            variables: {
                                input: {
                                    property_id: parseInt(this.order.property_id),
                                    order_rate_id: parseInt(this.order?.id),
                                    tax_date: moment(taxDate).format(DateUtils.yyyy_mm_dd),
                                    construction_year: parseInt(constyear, 10),
                                    value_rate: parseInt(valuerate, 10),
                                    bta: parseInt(bta, 10),
                                    loan_rate: parseInt(loanrate, 10),
                                    bra: parseInt(bra, 10),
                                    p_rom: parseInt(prom, 10),
                                    tariff_doc_url: file[0],
                                },
                            },
                        });
                    } else {
                        showMessage(MessageUtils.Errors.file, Constants.MessageType.FAILED)
                    }
                } else {
                    showMessage(MessageUtils.Errors.taxDate, Constants.MessageType.FAILED)
                }
            }
        })
    };
    _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };
    OrderingInformation = (data) => {
        return (
            <View>

                {this.order?.order_status === Constants.ORDER_STATUS.ORDERED ||
                this.order?.order_status === Constants.ORDER_STATUS.TIMEBOOKED ? (
                    <Mutation mutation={TAKST_UPDATE_ORDER_LIST}
                              onCompleted={(data) => {
                                  this.order = data?.update_order_list?.orderrate
                                  if (IS_WEB) {
                                      this.props.history.replace(Routes.TakstOrderAccepted, {
                                          order: data?.update_order_list?.orderrate
                                      })
                                  } else {
                                      this.props.navigation.navigate(Routes.TakstOrderAccepted, {
                                          order: data?.update_order_list?.orderrate
                                      })
                                  }
                              }}
                              onError={() => {
                              }}>
                        {(updateOrder, {loading, data, error}) => (
                            <View style={styles.topContainer}>
                                <DatePicker min={new Date(moment().add(1, 'd'))} title={Strings.date}
                                            selectedDate={this.state.orderDate}
                                            onSelectDate={(date) => this.setState({orderDate: date})}
                                            placeholder={Strings.selectDate}/>
                                <DatePicker title={Strings.startTime}
                                            selectedDate={this.state.startTime}
                                            placeholder={Strings.selectTime}
                                            onSelectDate={(date) => this.setState({startTime: date})}
                                            mode={'time'}/>

                                <CustomButton loading={loading}
                                              mt={30}
                                              mb={30}
                                              title={this.order?.order_status === Constants.ORDER_STATUS.ORDERED ? Strings.confirm : Strings.reSchedule}
                                              onPress={() => {
                                                  if (this.state.orderDate) {
                                                      if (this.state.startTime) {
                                                          let variables = {
                                                              order_date: moment(this.state.orderDate).format(DateUtils.yyyy_mm_dd),
                                                              earliest_start_time: moment(this.state.startTime).format(DateUtils.hh_mm_ss),
                                                          };
                                                          if (this.order?.order_status === Constants.ORDER_STATUS.ORDERED) {
                                                              variables.order_status = Constants.ORDER_STATUS_SERVER.TIMEBOOKED;
                                                          }
                                                          updateOrder({
                                                              variables: {
                                                                  input: {
                                                                      id: this.order?.id,
                                                                      fields: variables,
                                                                  },
                                                              },
                                                          })
                                                      } else {
                                                          showMessage(MessageUtils.Errors.startTime,Constants.MessageType.FAILED)
                                                      }
                                                  } else {
                                                      showMessage(MessageUtils.Errors.orderDate,Constants.MessageType.FAILED)
                                                  }

                                              }}/>
                            </View>
                        )}
                    </Mutation>
                ) : null}

                <Hr/>
                <View style={styles.topContainer}>
                    <View>{this.Details(data)}</View>
                    <View>
                        {data?.comment ? (
                            <>
                                <Label
                                    mt={5}
                                    font_medium
                                    small
                                    align={'left'}>{Strings.comment}</Label>
                                <Label
                                    mt={12}
                                    font_regular
                                    small
                                    align={'left'}>
                                    {data.comment}
                                </Label>
                            </>
                        ) : null}
                    </View>
                    <CustomButton
                        mt={30}
                        mb={25}
                        disabled={!this.order?.property?.order_rate_detail?.document?.doc_url}
                        title={Strings.downloadtariffdocument}
                        onPress={() => {
                            if (this.order?.property?.order_rate_detail?.document?.doc_url) {
                                openInNewTab(`${Constants.APIConfig.STORAGE_URL}${this.order?.property?.order_rate_detail?.document?.doc_url}`)
                            }
                        }}/>
                </View>
            </View>
        );
    }

    taxInformation = (loading, data) => {
        console.log(data?.order_rate_details)
        if (loading && !data) {
            return <ActivityIndicator color={Color.PRIMARY}
                                      style={CommonStyle.loader}/>
        }
        return (
            <View>
                <View style={styles.topContainer}>
                    <Label small font_regular mt={18}>
                        {Strings.taxinformationdata}
                    </Label>
                    <DatePicker max={new Date()}
                                title={Strings.taxdate}
                                selectedDate={data?.property?.order_rate_detail?.tax_date ? moment(data?.property?.order_rate_detail?.tax_date, DateUtils.yyyy_mm_dd) : null}
                                onSelectDate={(date) => this.setState({taxDate: date})}
                                placeholder={Strings.entertaxdate}/>

                    <InputField mt={15}
                                type={"number"}
                                labelText={Strings.valuerate}
                                placeholder={Strings.entervaluerate}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.VALUE_RATE)}
                                onFocus={() => this.setState({errorvaluerate: null})}
                                error={this.state.errorvaluerate}
                                value={this.state.valuerate}
                    />

                    <InputField mt={15}
                                type={"number"}
                                labelText={Strings.loanrate}
                                placeholder={Strings.enterloanrate}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.LOAN_RATE)}
                                onFocus={() => this.setState({errorloanrate: null})}
                                error={this.state.errorloanrate}
                                value={this.state.loanrate}
                    />

                    <InputField mt={15}
                                type={"number"}
                                labelText={Strings.btatitle}
                                placeholder={Strings.enterbta}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.BTA)}
                                onFocus={() => this.setState({errorbta: null})}
                                error={this.state.errorbta}
                                value={this.state.bta}
                    />

                    <InputField mt={15}
                                type={"number"}
                                labelText={Strings.bratitle}
                                placeholder={Strings.enterbra}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.BRA)}
                                onFocus={() => this.setState({errorbra: null})}
                                error={this.state.errorbra}
                                value={this.state.bra}
                    />

                    <InputField mt={15}
                                type={"number"}
                                labelText={Strings.prom}
                                placeholder={Strings.enterprom}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.PROM)}
                                onFocus={() => this.setState({errorprom: null})}
                                error={this.state.errorprom}
                                value={this.state.prom}
                    />

                    <InputField mt={15}
                                type={"number"}
                                labelText={Strings.constructionyear}
                                placeholder={Strings.enterconstructionyear}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.CONST_YEAR)}
                                onFocus={() => this.setState({errorconstyear: null})}
                                error={this.state.errorconstyear}
                                value={this.state.constyear}
                    />
                    <View style={styles.titleCont}>
                        <Label small
                               font_medium
                               mb={15}
                               align={'left'}>
                            {Strings.uploadtariffdocument}
                        </Label>
                    </View>
                    <FilePicker type={FileType.PDF}
                                onFilePicked={(data, file) => this.setState({file: file})}/>
                    {this.state.file.length ?
                        this.state.file.map(item => (
                            <Attachment name={item.name}
                                        style={styles.attachment}
                                        isDownloadable={false}
                                        onPressDelete={() => this.setState({file: []})}
                                        isDeletable/>
                        ))
                        : null}
                </View>

                {data?.property?.order_rate_detail?.comments?.length ?
                    (
                        <>
                            <Hr marginTop={15}/>
                            <View style={styles.flatlisttopContainer}>
                                <View style={styles.commentview}>
                                    <Label small
                                           mt={8}
                                           mb={8}
                                           font_medium
                                           align={'left'}>
                                        {Strings.comments}
                                    </Label>
                                </View>
                                {data?.property?.order_rate_detail?.comments?.map(item => (
                                    <>
                                        <View style={styles.MainSeocodflatView}>
                                            <View style={styles.labelView}>
                                                <Label
                                                    mt={5}
                                                    mb={5}
                                                    small
                                                    font_regular
                                                    align={'left'}>
                                                    {item.comment}
                                                </Label>
                                            </View>
                                        </View>
                                        {item.created_at ?
                                            (<Label
                                                mt={5}
                                                mb={15}
                                                small
                                                font_regular
                                                color={Color.PRIMARY_LIGHT}
                                                align={'left'}>
                                                {item.dateandtime}
                                            </Label>) : null}
                                    </>
                                ))}
                            </View>
                        </>
                    ) : null}
                {/*<FlatList
                                data={}
                                renderItem={({item}) =>
                                    <View>


                                    </View>
                                    < /View>
                                }/>*/}

                <Mutation mutation={TAKST_ADD_APPRAISER}
                          onCompleted={() => {
                              IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack()
                          }}
                          onError={() => {

                          }}>
                    {(addAppraiserInfo, {loading}) => (
                        <View style={styles.topContainer}>
                            <CustomButton loading={loading}
                                          mt={18}
                                          mb={25}
                                          title={Strings.Save}
                                          onPress={() => {
                                              this.radionbuttonview(addAppraiserInfo, data)
                                          }}
                            />
                        </View>
                    )}
                </Mutation>

            </View>

        )
    }
    Details = (data) => {

        return (
            <View>
                <Label small
                       font_medium
                       mt={15}
                       mb={8}
                       align={'left'}>{Strings.oppdragno}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.property?.oppdrags_nummer}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.sellername}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {`${data?.property?.seller?.first_name} ${data?.property?.seller?.last_name}`}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Email}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {data?.property?.seller?.email}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Phone}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {data?.property?.seller?.mobile}
                </Label>


                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.dates}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {moment(data.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.time}</Label>
                {this.order?.order_status === Constants.ORDER_STATUS.ORDERED ?
                    (<Label small
                            mb={20}
                            font_regular
                            align={'left'}>
                        {`${moment(data.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)} - ${moment(data.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}`}
                    </Label>) : (
                        <Label small
                               mb={20}
                               font_regular
                               align={'left'}>
                            {moment(data.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                        </Label>
                    )}
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.propertyaddress}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {`${data?.property?.street_adress ?? ""} ${data?.property?.city ?? ""} ${data?.property?.zipcode ?? ""}`}
                </Label>
            </View>
        );

    };


    render() {
        return (
            <Provider locale={enUS}>
                <Header style={CommonStyle.blankHeader}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    <View style={styles.container}>
                        {/* <View style={styles.topContainer}> */}
                        <View style={styles.titleConts}>
                            <Label
                                font_medium
                                mb={13}
                                mt={13}
                                xlarge
                                align={'left'}>
                                {Strings.orderdetails}
                            </Label>
                        </View>

                        <Query query={TAKST_ORDER_INFORMATION}
                               onCompleted={(data) => {

                                   let finalData = data?.order_list?.orderrate?.property?.order_rate_detail;
                                   console.log(finalData, "DATA")
                                   this.setState({
                                       taxDate: finalData?.tax_date,
                                       constyear: finalData?.construction_year,
                                       valuerate: parseInt(finalData?.value_rate, 10),
                                       bta: finalData?.bta,
                                       loanrate: parseInt(finalData?.loan_rate, 10),
                                       bra: finalData?.bra,
                                       prom: finalData?.p_rom,
                                       orderDate: data?.order_list?.orderrate?.order_date ? new Date(moment(this.order.order_date, DateUtils.yyyy_mm_dd)) : undefined,
                                       startTime: data?.order_list?.orderrate?.earliest_start_time ? new Date(moment(this.order.earliest_start_time, DateUtils.hh_mm_ss)) : undefined,
                                   })
                               }}
                               onError={(e) => {
                                   console.log(e)
                               }}
                               variables={{
                                   id: this.order.id
                               }}>
                            {({loading, data, error}) => {
                                if (loading && !data) {
                                    return (<ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>)
                                }
                                if (data) {
                                    this.order = data?.order_list?.orderrate
                                }
                                return (
                                    <IndicatorViewPager
                                        indicator={this._renderTabIndicator()}
                                        style={{flex: 1, backgroundColor: 'white'}}>
                                        <View>
                                            <Animated.ScrollView scrollEventThrottle={1}
                                                                 contentContainerStyle={styles.flexGrow}
                                                                 onScroll={Animated.event(
                                                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                                                     {useNativeDriver: true})}>

                                                <View>
                                                    {this.OrderingInformation(this.order)}
                                                </View>


                                            </Animated.ScrollView>
                                        </View>
                                        <View>
                                            <Animated.ScrollView scrollEventThrottle={1}
                                                                 contentContainerStyle={styles.flexGrow}
                                                                 onScroll={Animated.event(
                                                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                                                     {useNativeDriver: true})}>
                                                <View>
                                                    {this.taxInformation(loading, this.order)}
                                                </View>
                                            </Animated.ScrollView>
                                        </View>
                                    </IndicatorViewPager>
                                )
                            }}
                        </Query>
                        {/* </View> */}
                    </View>
                </ScrollView>
            </Provider>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
