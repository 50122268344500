import React from 'react'
//Components
import {ScrollView, View} from 'react-native'
import {Header, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
// import { PickerView } from '@ant-design/react-native';
//Utils
import {styles} from './styles.js';
import {Color, CommonStyle, Constants, DateUtils, IS_WEB, Routes, Strings} from "components/src/utils";
import moment from "moment";
// import {Button, InputItem} from "@ant-design/react-native";
// import { DatePickerView } from 'antd-mobile';


class Thankyouorder extends React.Component {

    constructor(props) {
        super(props);
        this.rateDetails = IS_WEB ? props.history.location.state?.rateDetails ?? props.property?.orderrate ?? {} : props.navigation.getParam('order', props.property?.orderrate ?? {});
        // this.rateDetails = IS_WEB ? props.history.location.state?.rateDetails ?? props.property?.orderrate ?? null : props.navigation.getParam('order', props.property?.orderrate ?? null);
    }

    rateViewDetails = () => {
        let route = Routes.ViewDetailsRate
        if (!this.rateDetails?.tax_date) {
            route = Routes.SubmitedDetailsRate
        }
        if (IS_WEB) {
            this.props.history.push(route, {
                rateDetails: this.rateDetails
            })
        } else {
            this.props.navigation.navigate(route, {
                rateDetails: this.rateDetails
            });
        }
    };

    render() {
        const {order_date, order_status, comment, earliest_start_time, latest_start_time} = this.rateDetails;

        return (
            <View style={CommonStyle.container}>
                <Header style={CommonStyle.blankHeader}
                        headerRightFirst={'dashboard'}
                        onPressRightFirst={() => IS_WEB ? this.props.history.replace(Routes.SalesPreparation) : this.props.navigation.popToTop()}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>
                <ScrollView contentContainerStyle={{flexGrow: 1}}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View>
                                <Label xlarge
                                       font_medium
                                       align={'left'}>
                                    {Strings.thanksForTheOrder}
                                </Label>
                                <Label
                                    mt={16}
                                    small
                                    font_regular
                                    align={'left'}>
                                    {Strings.thanksInstructionrate}
                                </Label>
                            </View>
                            {/*<CustomButton
                                    mt={18}
                                    mb={18}
                                    title={Strings.viewDetails}
                                    onPress={() => {
                                        this.rateViewDetails()
                                    }}/>*/}


                            <View style={styles.subviewdata}>
                                <Label small
                                       font_medium mb={10}>
                                    {Strings.dates}
                                </Label>
                                <Label small
                                       font_regular
                                       mb={10}
                                       align={'left'}>
                                    {moment(order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                                </Label>
                            </View>
                            <View style={styles.subviewdata}>
                                <Label small
                                       font_medium mb={10}>
                                    {Strings.startTime}
                                </Label>
                                <Label
                                    small
                                    font_regular
                                    mb={10}
                                    align={'left'}>
                                    {moment(earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                </Label>
                            </View>
                            {order_status === Constants.ORDER_STATUS.ORDERED ?
                                (<View style={styles.subviewdata}>
                                    <Label small
                                           font_medium
                                           mb={10}>
                                        {Strings.endTime}
                                    </Label>
                                    <Label small
                                           font_regular
                                           mb={10}
                                           align={'left'}>
                                        {moment(latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                    </Label>
                                </View>) : null}

                            {comment ? (
                                <View style={styles.subviewdata}>
                                    <Label small
                                           font_medium
                                           mb={10}
                                           align={'left'}>
                                        {Strings.comments}
                                    </Label>
                                    <Label
                                        mb={10}
                                        small
                                        font_regular
                                        align={'left'}>
                                        {comment}
                                    </Label>
                                </View>) : null}


                        </View>
                    </View>

                </ScrollView>
            </View>

        )
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Thankyouorder);
