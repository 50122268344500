import React from 'react'
//Components
import {ScrollView, View} from 'react-native'
import {CustomButton, Header, Label} from "../../../components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Utils
import {styles} from './styles.js';
import {CommonStyle, IS_WEB, Routes, Strings} from "components/src/utils";

function OrderAccepted(props) {
    const {history, navigation} = props;
    const order = IS_WEB ? props.history.location.state?.order ?? {} : props.navigation.getParam('order', {});
console.log(order)
    const stylingViewDetails = () => {
        if (IS_WEB) {
            history.replace(Routes.TakstOrderInfo, {
                order: order
            })
        } else {
            navigation.navigate(Routes.TakstOrderInfo, {
                order: order
            });
        }
    }

    return (
        <Provider locale={enUS}>
            <Header navigation={IS_WEB ? history : navigation}
                    style={CommonStyle.blankHeader}/>
            <ScrollView contentContainerStyle={{flexGrow: 1}}>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={CommonStyle.flex}>
                            <Label xlarge

                                   font_medium
                                   align={'left'}>
                                {Strings.orderaccepted}
                            </Label>
                            <Label
                                small
                                mt={16}
                                font_regular
                                align={'left'}>
                                {Strings.orderaccepteddata}
                            </Label>
                        </View>
                        <View>
                            <CustomButton
                                mt={10}
                                title={Strings.viewDetails}
                                onPress={() => {
                                    stylingViewDetails()
                                }}/>
                        </View>
                    </View>
                </View>

            </ScrollView>
        </Provider>
    )
}


const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderAccepted);
