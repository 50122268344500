import { EditOutlined, MoreOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Typography,
  Menu,
  Dropdown,
  notification,
} from "antd";
import React, { useState,useEffect, useRef } from "react";
import { Link,useHistory } from "react-router-dom";
import { encryptId } from "../../../utils/utils";
import { formatMessage } from "components/src/utils";
import { Query, Mutation } from "react-apollo";
import { ADMIN_OFFICES_LIST } from "../../../../query/AdminQuery";
import { ADMIN_UPDATE_OFFICE_STATUS } from "../../../../mutation/AdminMutation";
import "../../../sass/defaultstyles.scss";
// import data from '../../../../mock/officesdata';
// import { IntrospectionFragmentMatcher } from 'apollo-boost';

const { Title } = Typography;
const { Search } = Input;
let dataRefetch;
const menu = (item, updateStatus) => {
  return (
    <Menu
      onClick={() => {
        // const deleteID =  orderData.filter(item =>item.id);
        updateStatus({
          variables: {
            id: item.map((i) => i.id).toString(),
            status: false,
          },
        }).then(({data})=> {
          if (dataRefetch) {
            dataRefetch();
          }
          console.log("Data submitted===>>>", data);
          if (data && data.change_office_status) {
            notification.success({
              message: formatMessage({
                id: "component.table.inactiveoffice",
              }),
              description: "",
              duration: 3,
            });
          }
        })
      }}
    >
      <Menu.Item>
        {formatMessage({ id: "component.table.inactiveoffice" })}
      </Menu.Item>
    </Menu>
  );
};

const onActivateOffice = (item, updateStatus) => {
  updateStatus({
    variables: {
      id: item.map((i) => i.id).toString(),
      status: true,
    },
  }).then(({ data, loading }) => {
    if (dataRefetch) {
      dataRefetch();
    }
    console.log("Data submitted===>>>", data, loading);
    if (data && data.change_office_status) {
      notification.success({
        message: "Aktiver",
        description: "",
        duration: 3,
      });
    }
  });
};

export const Offices = () => {
  let router=useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderData, setOrderData] = useState([]);
  const [officeList, setOfficeList] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState('');

  const [prevPagination, setprevPagination] = useState({});
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 30,
    total: 1,
  });

  const timerRef = useRef(null);

  const usePreviousValue = () => {
    const ref = useRef();
    useEffect(() => {
      ref.current = searchKeyword;
    });
    return ref.current;
  };
  const prevValue = usePreviousValue();

  const searchFunc = e => {
    if (prevValue === '') {
      console.log('prevValue;', prevValue);
      setprevPagination(paginationData);
    }
    if (e !== '') {
      setPaginationData({
        current: 1,
        pageSize: 30,
        total: 1,
      });
    } else {
      setPaginationData(prevPagination);
    }
  };

  const handleTableChange = pagination => {
    console.log('pagination:', pagination);
    if (pagination) {
      setPaginationData(pagination);
      setprevPagination(paginationData);
    }
  };

  useEffect(() => {
    if (searchKeyword === '') {
      setprevPagination(paginationData);
    }
  }, []);

  const columns = [
    {
      key: 'status',
    },
    {
      title: `${formatMessage({ id: 'component.table.officename' })}`,
      dataIndex: 'officename',
      key: 'officename',
      sorter: (a, b) => a.officename - b.officename,
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.table.companyname' })}`,
      dataIndex: 'companyname',
      key: 'companyname',
      sorter: (a, b) => a.companyname - b.companyname,
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.table.orgnumber' })}`,
      dataIndex: 'orgnumber',
      key: 'orgnumber',
      sorter: (a, b) => a.orgnumber.length - b.orgnumber.length,
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.form.address' })}`,
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.length - b.address.length,
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.table.action' })}`,
      key: 'action',
      render: (status, name) => {
        const itemToSend = orderData.filter(item => name.key === item.id);
        console.log('status:', status);

        return (
            <>
              {status.status ? (
                  <span style={{ paddingLeft: 16 }}>
                <Link
                    to={{
                      pathname: `/admin/kontorer/redigerekontorer/${encryptId(status.key)}`,
                    }}
                >
                  <EditOutlined style={{ marginRight: 8 }} />
                </Link>
                <Mutation mutation={ADMIN_UPDATE_OFFICE_STATUS}>
                  {(updateStatus, { loading, data }) => {
                    if (data) {
                      if (dataRefetch) {
                        dataRefetch();
                      }
                      console.log('Data submitted===>>>', data, loading);
                      if (data && data.delete_user && data.delete_user.meta) {
                        notification.success({
                          message: data.delete_user.meta.message,
                          description: '',
                          duration: 3,
                        });
                      }
                    }
                    return (
                        <Dropdown
                            overlay={menu(itemToSend, updateStatus)}
                            placement="bottomCenter"
                            trigger={['click']}
                        >
                          <MoreOutlined />
                        </Dropdown>
                    );
                  }}
                </Mutation>
              </span>
              ) : (
                  <Mutation mutation={ADMIN_UPDATE_OFFICE_STATUS}>
                    {(updateStatus, { loading }) => {
                      return (
                          <a
                              loading={loading}
                              className="mb-0 text-greenish"
                              onClick={() => onActivateOffice(itemToSend, updateStatus)}
                          >
                            Aktiver
                          </a>
                      );
                    }}
                  </Mutation>
              )}
            </>
        );
      },
    },
  ];

  return (
      <div className="contentshadow">
        <div className="text-primary main">
          <Row className="photo-order-wrapper">
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
              <Title level={3} className="text-primary">
                {formatMessage({
                  id: 'component.offices.title',
                })}
              </Title>

              <Link to="/admin/kontorer/leggetilkontorer">
                <Button
                    type="primary"
                    className="btn-secondary admin-forms-btn with-lg-spaces"
                    size="large"
                >
                  {formatMessage({ id: 'component.offices.addoffices' })}
                </Button>
              </Link>
              <Row>
                <Col xs={24}>
                  <Query
                      query={ADMIN_OFFICES_LIST}
                      variables={{
                        input: {
                          first: paginationData.pageSize,
                          page: paginationData.current,
                          key: searchKeyword,
                          sorting: {
                            field: 'id',
                            order: 'DESC',
                          },
                        },
                      }}
                      fetchPolicy="cache-and-network"
                      onCompleted={responseData => {
                        setOrderData(responseData.offices.data);
                        if (
                            responseData &&
                            responseData.offices &&
                            responseData.offices.paginatorInfo
                        ) {
                          setPaginationData({
                            current: responseData.offices.paginatorInfo?.currentPage,
                            pageSize: responseData.offices.paginatorInfo?.perPage,
                            total: responseData.offices.paginatorInfo?.total,
                          });
                        }
                        if (responseData && responseData.offices && responseData.offices.data) {
                          setOfficeList(responseData.offices.data);
                        }
                      }}
                  >
                    {({ data, loading, refetch, error }) => {
                      console.log(error);
                      dataRefetch = refetch;
                      const officesArray = [];
                      if (data && data.offices.data) {
                        // eslint-disable-next-line array-callback-return
                        data.offices.data.map(item => {
                          const officesList = {
                            key: item.id,
                            officename: `${item.name}`,
                            companyname: item?.company_name,
                            orgnumber: item?.org_number,
                            address: item?.address,
                            status: item?.status,
                          };
                          officesArray.push(officesList);
                        });
                      }
                      if (!error) {
                        return (
                            <>
                              <Row>
                                <Col lg={12} xs={24}>
                                  <div className="table-searchbar">
                                    <Search
                                        placeholder={formatMessage({
                                          id: 'component.offices.tablesearch',
                                        })}
                                        onSearch={value => setSearchKeyword(value)}
                                        onChange={e => {
                                          const searchText = e.target.value;
                                          searchFunc(searchText);
                                          if (timerRef.current) {
                                            clearTimeout(timerRef.current);
                                          }
                                          timerRef.current = setTimeout(() => {
                                            console.log('On Change===>>>', searchText);
                                            setSearchKeyword(searchText);
                                          }, 1000);
                                        }}
                                        style={{ padding: '8px 15px', marginBottom: '20px' }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div style={{ width: '100%', height: '100%', overflowX: 'auto' }}>
                                <div className="unbordered-table-wrapper admin-tables">
                                  <Table
                                      columns={columns}
                                      onChange={handleTableChange}
                                      dataSource={officesArray}
                                      pagination={{
                                        current: paginationData.current,
                                        pageSize: paginationData.pageSize,
                                        total: paginationData.total,
                                        showSizeChanger: true,
                                        showTotal: () =>
                                            `Total ${paginationData.total} ${formatMessage({
                                              id: 'component.table.items',
                                            })}`,
                                        pageSizeOptions: ['30', '35', '40', '45', '50'],
                                      }}
                                      loading={loading}
                                  />
                                </div>
                              </div>
                            </>
                        );
                      }
                      if (!loading && error) {
                        return (
                            <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen.</div>
                        );
                      }
                      return <></>;
                    }}
                  </Query>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
  );
};

export default Offices;
