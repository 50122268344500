export default {
  'component.orderinformation.title.tab1': 'Bestillingsinformasjon',
  'component.orderinformation.title.tab2': 'Avgift informasjon',
  'component.orderinformation.form.button1': 'Bekrefte',
  'component.orderinformation.form.button2': 'Avbryt',
  'component.orderinformation.oppdrag': 'Oppdragsnr. :',
  'component.orderinformation.sellername': 'Selger navn :',
  'component.orderinformation.email': 'e-post :',
  'component.orderinformation.phone': 'telefon :',
  'component.orderinformation.date': 'Dato :',
  'component.orderinformation.time': 'Tid :',
  'component.orderinformation.propertyaddress': 'Eiendommens adresse :',
  'component.orderinformation.comment': 'Kommentar :',
  'component.orderinformation.uploaddoc': 'Lastet opp Tarrif-dokument :',
  'component.orderinformation.tax.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  'component.orderinformation.tax.line1.heading1': 'Skattedato',
  'component.orderinformation.tax.line1.heading1.placeholder': 'Tast inn Skattedato',
  'component.orderinformation.tax.line3.heading1': 'Byggeår',
  'component.orderinformation.tax.line3.heading1.placeholder': 'Tast inn Byggeår',
  'component.orderinformation.tax.line1.heading2': 'Verdifrekvens',
  'component.orderinformation.tax.line1.heading2.placeholder': 'Tast inn Verdifrekvens',
  'component.orderinformation.tax.line2.heading1': 'BTA',
  'component.orderinformation.tax.line2.heading1.placeholder': 'Tast inn BTA',
  'component.orderinformation.tax.line1.heading3': 'Lånesats',
  'component.orderinformation.tax.line1.heading3.placeholder': 'Tast inn Lånesats',
  'component.orderinformation.tax.line2.heading2': 'BRA',
  'component.orderinformation.tax.line2.heading2.placeholder': 'Tast inn BRA',
  'component.orderinformation.tax.line2.heading3': 'PROM',
  'component.orderinformation.tax.line2.heading3.placeholder': 'Tast inn PROM',
  'component.orderinformation.tax.line4.heading1': 'Last opp tariffdokument',
  'component.orderinformation.tax.line5.button': 'Last opp',
  'component..orderinformation.tax.line6.heading1': 'kommentarer',
  'component.orderinformation.tax.line6.button1': 'Rekkefølge',
  'component.orderinformation.tax.line6.button2': 'Avbryt',
};
