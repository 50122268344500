import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.PRIMARY_BG_COLOR,

    },
    headerTitle: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 40
    },
    txtRegular: {
        fontFamily: 'Maax'
    },
    topContainer: {
        marginTop: 40,
        marginHorizontal: containerHorizontalMargin,
        justifyContent: 'center',
    },
    btnCont: {
        alignSelf: 'stretch',
        marginVertical: 30
    },
    logo:{
        alignSelf:'center',
        height:ThemeUtils.responsiveScale(92),
        width:ThemeUtils.responsiveScale(180),
        marginBottom:ThemeUtils.relativeRealHeight(2)
    }
});

export {styles};
