import gql from 'graphql-tag';


export const PHOTOGRAPHER_ORDER_LIST = gql`
 query photographerOrders($input: OrderListInput!) {
  photo_orders(input: $input) {
    paginatorInfo {
      currentPage
      hasMorePages
      total
    }
    data {
      id
      property_id
      photo_id  
      order_date
      earliest_start_time
      latest_start_time
      order_status
      property{
          id
          street_adress
          city
          zipcode
      }
    }
  }
}`;

export const PHOTOGRAPHER_ORDER_DETAILS=gql`
query photoOrder($id: ID!) {
  photo_order(id: $id) {
    order {
      id
      property_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      photo_order_360_view {
        id
        order_photo_id
        property_id
        view_360_url
      }
      photo_order_video_view {
        id
        order_photo_id
        property_id
        video_url
      }
      order_status
      property {
        id
        street_adress
        city
        zipcode
        oppdrags_nummer
        seller {
           id
          first_name
          last_name
          email
          mobile
        }
      }
      photo {
        id
        first_name
        last_name
        email
      }
    }
  }
}`

export const PHOTOS = gql`
query photos($status: Int!, $photoId: Int!, $first: Int!, $page: Int!) {
  order_photo_list(status: $status, order_photo_id: $photoId, first: $first, page: $page) {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
    data {
      id
      user_id
      order_photo_id
      property_id
      photo_url
      comments {
        id
        order_photo_photos_id
        seller_id
        comment
      }
    }
  }
}`;

export const PHOTO_CALENDAR_EVENT = gql`
query photoCalendarEvent {
  photo_calendar_event {
    id
    property_id
    created_user_id
    title
    description
    start_datetime
    end_datetime
    event_type
    user_type
    property{
        street_adress
        city
        zipcode
    }
  }
}`;
