import React from 'react'
//Components
import {ActivityIndicator, FlatList, ScrollView, TouchableOpacity, View} from 'react-native'

import {Hr, Label, Modal, PopOver, Ripple, Tag,} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {INSPECTION_ORDER_LIST} from "components/src/api/inspectionQuery";
import {INSPECTION_UPDATE_ORDER} from "components/src/api/inspectionMutation";

//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    getStatusColor,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';
import Search from '../Search'
import Filter from '../Filter'
import moment from 'moment'

class OrderList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isVisible: false,
            isSearchVisible: false,
            isFilterVisible: false,
            filters: [],
            fromView: null,
        }
    }

    navigate = (order) => {
        if (IS_WEB) {
            this.props.history.push(Routes.InspectionOrderInfo, {
                order: order
            })
        } else {
            this.props.navigation.navigate(Routes.InspectionOrderInfo, {
                order: order
            })
        }
    };

    renderOrderItem = ({item, index}) => {
        return (
            <View key={index.toString()}
                  style={CommonStyle.flex}>
                {this.renderOrder({item, index})}
            </View>

        );
    }

    renderOrder = ({item, index}) => {

        let touchable;
        return (
            <TouchableOpacity activeOpacity={0.5}
                              key={index.toString()}
                              onPress={() => this.navigate(item)}>
                <Hr/>
                <View style={{marginTop: 15, marginBottom: 15}}>
                    <View style={styles.topContainer}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>

                            <Tag name={item.order_status}
                                 color={getStatusColor(item.order_status)}/>


                            {item.order_status !== Constants.ORDER_STATUS.PROJECTCOMPLETE ?
                                (<TouchableOpacity ref={ref => touchable = ref}
                                                   style={styles.button}
                                                   onPress={() => this.showPopover(touchable)}>
                                    <View>
                                        <Icon
                                            size={ThemeUtils.fontSmall}
                                            name={'more'}
                                            color={Color.PRIMARY_DARK}/>
                                    </View>
                                </TouchableOpacity>) : null}
                        </View>
                        <View>
                            <Label small font_medium mt={10} align={'left'}>
                                {item?.property?.address}
                            </Label>
                            <Label small font_regular mt={10} align={'left'}>
                                {`${moment(item.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}-${moment(item.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                            </Label>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }


    showPopover = (ref) => {
        this.setState({
            isVisible: true,
            fromView: ref
        });
    }

    closePopover = (ref) => {
        this.setState({
            isVisible: false,
            fromView: ref
        });
    }

    renderSearchModal = () => {
        return (
            <Modal visible={this.state.isSearchVisible}>
                <View>
                    <Search onClose={() => this.setState({isSearchVisible: false})}
                            renderItem={this.renderOrder}/>
                </View>
            </Modal>
        )
    };

    renderFilterModal = () => {
        return (
            <Modal visible={this.state.isFilterVisible}>
                <View style={CommonStyle.container}>
                    <Filter onClose={() => this.setState({isFilterVisible: false})}
                            filters={this.state.filters}
                            onApplyFilter={(val) => {
                                this.setState({
                                    isFilterVisible: false,
                                    filters: val
                                })
                            }}/>
                </View>
            </Modal>
        )
    };

    render() {
        return (
            <Provider locale={enUS}>
                {this.renderSearchModal()}
                {this.renderFilterModal()}

                <ScrollView contentContainerStyle={{flexGrow: 1}}>

                    <View style={styles.container}>

                        <Query query={INSPECTION_ORDER_LIST}
                               variables={{
                                   input: {
                                       first: 30,
                                       page: 1,
                                       order_status: this.state.filters.toString()
                                   }
                               }}>
                            {({data, refetch, loading, error, fetchMore}) => {
                                if (loading && !data) {
                                    return (<ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>)
                                }
                                return (
                                    <View>
                                        <View style={styles.topContainer}>
                                            <View style={styles.titleContainer}>
                                                <Label xlarge
                                                       font_medium
                                                       style={CommonStyle.flex}
                                                       align={'left'}>
                                                    {Strings.orderlist}
                                                </Label>


                                                <Ripple style={styles.icon}
                                                        onPress={() => this.setState({isSearchVisible: true})}
                                                        rippleContainerBorderRadius={40}>
                                                    <Icon size={ThemeUtils.fontXLarge}
                                                          name={'search'}
                                                          color={Color.PRIMARY_DARK}/>
                                                </Ripple>

                                                <Ripple style={styles.icon}
                                                        onPress={() => this.setState({isFilterVisible: true})}
                                                        rippleContainerBorderRadius={40}>
                                                    <Icon size={ThemeUtils.fontXLarge}
                                                          name={'filter'}
                                                          color={Color.PRIMARY_DARK}/>
                                                </Ripple>

                                            </View>

                                        </View>
                                        <FlatList data={data?.inspection_orders?.data ?? []}
                                                  onRefresh={refetch}
                                                  refreshing={loading}
                                                  renderItem={this.renderOrderItem}
                                                  ListEmptyComponent={
                                                      <View style={CommonStyle.content_center}>
                                                          <Label mt={40}>
                                                              {Strings.noOrder}
                                                          </Label>
                                                      </View>
                                                  }
                                                  ListFooterComponent={
                                                      loading &&
                                                      data?.inspection_orders?.paginatorInfo?.hasMorePages ?
                                                          (<ActivityIndicator color={Color.PRIMARY}
                                                                              style={CommonStyle.loader}/>) : null
                                                  }
                                                  onEndReached={(info) => {
                                                      if (!loading &&
                                                          data?.inspection_orders?.paginatorInfo?.hasMorePages) {
                                                          fetchMore({
                                                              variables: {
                                                                  page: parseInt(data?.inspection_orders?.paginatorInfo?.currentPage, 10) + 1,
                                                              },
                                                              updateQuery: (prev, {fetchMoreResult}) => {
                                                                  if (!fetchMoreResult) {
                                                                      return prev;
                                                                  } else {
                                                                      let finalResult = Object.assign({}, fetchMoreResult);
                                                                      finalResult.inspection_orders.data = [...prev.inspection_orders.data, ...fetchMoreResult.inspection_orders.data];
                                                                      return finalResult;
                                                                  }
                                                              },
                                                          });
                                                      }

                                                  }}
                                                  onEndReachedThreshold={0.2}/>
                                    </View>
                                )
                            }}
                        </Query>
                        <PopOver isVisible={this.state.isVisible}
                                 fromView={this.state.fromView}
                            //   placement={PLACEMENT_OPTIONS.AUTO}
                                 onRequestClose={() => this.closePopover()}>

                            <Mutation mutation={INSPECTION_UPDATE_ORDER}
                                      onCompleted={() => {
                                          this.setState({
                                              isSearchVisible: false,
                                          }, () => {
                                              this.refetch && this.refetch()
                                          })
                                      }}>
                                {(updateOrder, {data}) => (
                                    <View style={{paddingHorizontal: 10}}>
                                        <TouchableOpacity activeOpacity={0.5}
                                                          onPress={() => {
                                                              updateOrder({
                                                                  variables: {
                                                                      input: {
                                                                          id: this.state.selectedStatus?.id,
                                                                          fields: {
                                                                              order_status: Constants.ORDER_STATUS_SERVER.PROJECTCOMPLETE,
                                                                          },
                                                                      },
                                                                  },
                                                              });
                                                              this.closePopover()
                                                          }}>
                                            <Label small mb={10} mt={10}>{Strings.projectCompleted}</Label>
                                        </TouchableOpacity>
                                    </View>
                                )}
                            </Mutation>

                        </PopOver>


                    </View>
                </ScrollView>
            </Provider>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
