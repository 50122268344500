/* eslint-disable no-unused-expressions */
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  Row,
  Typography,
  Upload,
} from "antd";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import React from "react";
import { Mutation } from "react-apollo";
import { Link,withRouter } from "react-router-dom";
import { formatMessage } from "components/src/utils";
import { ADMIN_CREATE_NEWS } from "../../../../../../mutation/AdminMutation";
import { STORAGE_URL } from "../../../../../utils/constant";
import { PhotographerSearch } from "components/src/screens";

const { Title } = Typography;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

// function beforeUpload (file) {
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//   if (!isJpgOrPng) {
//     message.error('Du kan kun laste opp JPG/PNG filer.');
//   }
//   const isLt2M = file.size / 1024 / 1024 < 5;
//   if (!isLt2M) {
//     message.error('Image must smaller than 5MB!');
//     this.setState({ erroFlag: true });
//   }
//   return isJpgOrPng && isLt2M;
// };

class AddNews extends React.Component {
  state = {
    editorState: BraftEditor.createEditorState(),
    loading: false,
    newsVideo: [],
    erroFlag: false,
    imageObject: '',
  };

  componentDidMount() {
    this.isLivinig = true;
    setTimeout(this.setEditorContentAsync, 3000);
  }

  componentWillUnmount() {
    this.isLivinig = false;
  }

  handleChange = editorState => {
    console.log('Editor Value====>', editorState.toText());
    this.setState({
      editorState,
    });
  };

  handleUploadChange = info => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
          imageObject: info.file.originFileObj,
        }),
    );
  };

  setEditorContentAsync = () => {
    this.isLivinig &&
    this.setState({
      editorState: BraftEditor.createEditorState(),
    });
  };

  render() {
    const uploadButton = (
        <div>
          {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div className="ant-upload-text">{formatMessage({ id: 'component.form.upload' })}</div>
        </div>
    );
    const { editorState, imageUrl, newsVideo, imageObject } = this.state;

    const onFinish = (values, createNews) => {
      console.log('Success:', values);

      const inputFields = {
        title: values.title,
        description: editorState.toHTML(),
      };

      if (imageObject) {
        inputFields.cover_image = imageObject;
      }
      if (newsVideo.length > 0) {
        inputFields.video = newsVideo[0].originFileObj;
      }
      createNews({
        variables: {
          input: inputFields,

        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.add_news) {
          notification.success({
            message: `Nyheter lagt til`,
            description: '',
            duration: 3,
          });
          this.props.history.push('/admin/nyheter');
        }
      });
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };


    return (
        <>
          <Row className="contentshadow">
            <Col xs={24} lg={24}>
              <Title level={3} className="text-primary">
                {formatMessage({ id: 'component.news.addbutton' })}
              </Title>
              <Mutation mutation={ADMIN_CREATE_NEWS}>
                {(createNews, { loading }) => (
                    <Form
                        name="ad_news"
                        initialValues={{ remember: true }}
                        onFinish={values => onFinish(values, createNews)}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        className="user-forms addEditform"
                    >
                      <Form.Item
                          name="cover_image"
                          label={formatMessage({
                            id: 'component.news.coverphoto',
                          })}
                          rules={[
                            {
                              required: false,
                              message: 'Vennligst skriv inn profilbilde',
                            },
                          ]}
                      >
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            onChange={this.handleUploadChange}
                            accept={['.jpg', '.jpeg', '.png', '.svg']}
                        >
                          {imageUrl ? (
                              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                          ) : (
                              uploadButton
                          )}
                        </Upload>
                      </Form.Item>
                      <Form.Item
                          label={formatMessage({ id: 'component.news.newstitle' })}
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: `Vennligst skriv inn ${formatMessage({
                                id: 'component.news.newstitle',
                              }).toLowerCase()}`,
                            },
                          ]}
                      >
                        <Input
                            placeholder={formatMessage({ id: 'component.news.newstitle' })}
                            size="large"
                        />
                      </Form.Item>
                      <Form.Item
                          label={formatMessage({ id: 'component.news.newsdesc' })}
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: `Vennligst skriv inn ${formatMessage({
                                id: 'component.news.newsdesc',
                              }).toLowerCase()}`,
                            },
                          ]}
                      >
                        <BraftEditor
                            className="editor-wrapper"
                            name="newsdesc"
                            language="en"
                            value={editorState}
                            onChange={this.handleChange}
                            contentStyle={{ height: 210 }}
                            placeholder={formatMessage({ id: 'component.news.newsdescplaceholder' })}
                        />
                      </Form.Item>
                      <Form.Item
                          name="newsvideo"
                          label={formatMessage({
                            id: 'component.news.uploadvideo',
                          })}
                          valuePropName="fileList"
                          rules={[
                            {
                              required: false,
                              message: `Vennligst  ${formatMessage({
                                id: 'component.news.uploadvideo',
                              })}!`,
                            },
                          ]}
                      >
                        <Upload
                            showUploadList={false}
                            accept=".mp4"
                            fileList={false}
                            onChange={({ fileList }) => {
                              console.log('video-->', fileList[0]);
                              const file = [];
                              file.push(fileList[0]);
                              this.setState({
                                newsVideo: file,
                              });
                            }}
                        >
                          <Button className="marginbottom cancelbutton" size="large">
                            <UploadOutlined />
                            {formatMessage({
                              id: 'component.form.upload',
                            })}
                          </Button>
                        </Upload>
                      </Form.Item>
                      {newsVideo.length !== 0 && (
                          <Row gutter={32}>
                            <Col xs={24} lg={16}>
                              {newsVideo.map(item => (
                                  <div className="default_list mt-3 mb-3">
                                    <div>
                                      <i className="icon-attachment" />
                                      <span className="ml-2">
                                {item.originFileObj ? (
                                    <span>{item.name}</span>
                                ) : (
                                    <a
                                        href={`${STORAGE_URL}${item.media_url}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                      {item.name}
                                    </a>
                                )}
                              </span>
                                    </div>
                                    <div
                                        className="upload-media-delete"
                                    >

                                      <i
                                          className="icon-delete"
                                          onClick={() => this.setState({ newsVideo: [] })}
                                      />
                                    </div>
                                  </div>
                              ))}
                            </Col>
                          </Row>
                      )}
                      <Button
                          type="primary"
                          htmlType="submit"
                          className="primary buttons admin-forms-btn mt-3"
                          size="large"
                          loading={loading}
                      >
                        {formatMessage({
                          id: 'component.news.publish',
                        })}
                      </Button>
                      <Link to="/admin/nyheter">
                        <Button size="large" className="buttons admin-forms-btn back-button mt-3">
                          {formatMessage({
                            id: 'component.form.cancel',
                          })}
                        </Button>
                      </Link>
                    </Form>
                )}
              </Mutation>
            </Col>
          </Row>
        </>
    );
  }
}
export default withRouter(AddNews);
