import { Button, Col, Form, Input, Row, Typography } from 'antd';
import React from 'react';
// import { Mutation, Query } from 'react-apollo';
import { formatMessage } from "components/src/utils";

// import imaged from '../../../../../assets/Photo_Styling_Images/1.png';
// import { from } from 'apollo-link';

const { Title } = Typography;

const onFinish = values => {
  console.log('Success:', values);
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};
// const uploadButton = (
//   <div>
//     <PlusOutlined className="uploadplus" />
//     <div className="ant-upload-text label-texts">
//       {formatMessage({
//         id: 'component.form.upload',
//       })}
//     </div>
//   </div>
// );

const AddVitec = (props) => {
  let router=props.history
  // const options = [
  //   { value: '0178' },
  //   { value: '0188' },
  //   { value: '1350' },
  //   { value: '0183' },
  //   { value: '4730' },
  // ];

  // const [imageUrl, setimageUrl] = useState('');

  // function getBase64(img, callback) {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // }
  // const handleChange = info => {
  //   // if (info.file.status === 'done') {
  //   getBase64(info.file.originFileObj, image => setimageUrl(image));
  //   // }
  // };

  return (
      <div className="contentshadow">
        <div className="text-primary main">
          <Row>
            <Col xs={24}>
              <Title level={3} className="text-primary">
                {formatMessage({
                  id: 'component.vitecmanagement.addvitec',
                })}
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form
                  layout="vertical"
                  className="user-forms"
                  name="addVitecDataForm"
                  initialValues={{
                    remember: true,
                  }}
                  colon={false}
                  onFinish={values => onFinish(values)}
                  onFinishFailed={onFinishFailed}
              >
                <Row gutter={16}>
                  <Col xs={24} lg={8}>
                    <Form.Item
                        name="name"
                        label={formatMessage({
                          id: 'component.form.name',
                        })}
                        rules={[
                          {
                            required: true,
                            message: `Vennligst skriv inn ${formatMessage({
                              id: 'component.form.name',
                            }).toLowerCase()}`,
                          },
                        ]}
                    >
                      <Input
                          style={{ width: '100%' }}
                          placeholder={`Tast inn ${formatMessage({
                            id: 'component.form.name',
                          }).toLowerCase()}`}
                          size="large"
                          className="pickerwidth"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} lg={8}>
                    <Form.Item
                        name="installationid"
                        label={formatMessage({
                          id: 'component.table.installationid',
                        })}
                        rules={[
                          {
                            required: true,
                            message: `Vennligst skriv inn ${formatMessage({
                              id: 'component.table.installationid',
                            }).toLowerCase()}`,
                          },
                        ]}
                    >
                      <Input
                          placeholder={`Tast inn ${formatMessage({
                            id: 'component.table.installationid',
                          }).toLowerCase()}`}
                          size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} lg={8}>
                    <Form.Item
                        name="username"
                        label={formatMessage({
                          id: 'component.table.username',
                        })}
                        rules={[
                          {
                            required: true,
                            message: `Vennligst skriv inn ${formatMessage({
                              id: 'component.table.username',
                            }).toLowerCase()}`,
                          },
                        ]}
                    >
                      <Input
                          placeholder={`Tast inn ${formatMessage({
                            id: 'component.table.username',
                          }).toLowerCase()}`}
                          size="large"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={8}>
                    <Form.Item
                        name="password"
                        label={formatMessage({
                          id: 'component.form.password',
                        })}
                        rules={[
                          {
                            required: true,
                            message: 'Vennligst oppgi epost',
                          },
                        ]}
                    >
                      <Input
                          style={{ width: '100%' }}
                          placeholder={`Tast inn ${formatMessage({
                            id: 'component.form.password',
                          }).toLowerCase()}`}
                          size="large"
                          className="pickerwidth"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row gutter={16}>
                <Col xs={24} lg={16}>
                  <Form.Item
                    name="assignedzipcodes"
                    label={formatMessage({
                      id: 'component.form.zipcode',
                    })}
                    rules={[
                      {
                        required: true,
                        message: `Vennligst skriv inn ${formatMessage({
                          id: 'component.form.address',
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      placeholder={`Angi ${formatMessage({
                        id: 'component.form.address',
                      })}`}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row> */}

                <Row className="mt-3">
                  <Col xs={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-secondary buttons admin-forms-btn"
                        size="large"
                        // loading={loading}
                    >
                      {formatMessage({
                        id: 'component.form.save',
                      })}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-secondary buttons admin-forms-btn"
                        size="large"
                        style={{ marginLeft: 8 }}
                        // loading={loading}
                    >
                      Test
                    </Button>
                    <Button
                        className="text-primary admin-forms-btn"
                        style={{ marginLeft: 8 }}
                        size="large"
                        onClick={router.goBack}
                    >
                      {formatMessage({
                        id: 'component.form.cancel',
                      })}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
  );
};
export default AddVitec;
