import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        zIndex: 1,
        paddingHorizontal:8,

    },
    animatedTitleCont: {
        zIndex: 6,
        position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        elevation: 6
    },
    headerCont: {
        flexDirection: 'row',
        height: ThemeUtils.APPBAR_HEIGHT,
        backgroundColor: Color.TRANSPARENT,
        elevation: 6
    },
    headerBg: {
        height: ThemeUtils.APPBAR_HEIGHT,
        backgroundColor: Color.PRIMARY,
        position: 'absolute',
        start: 0,
        end: 0,
        shadowColor: Color.BLACK,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    icHeader: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        padding: 10,
    },
    icHeaderRightCont: {
        flex: 1,
        zIndex:10,
        top:0,
        bottom:0,
        end:0,
        position:'absolute',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row',
    },
    headerTitleCont: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'absolute',
        alignSelf: 'center',
        elevation: 6,
    }
})
