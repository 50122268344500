import React from 'react'
//Components
import {Animated, Keyboard, View} from 'react-native'

import {
    Attachment,
    CheckBox,
    CustomButton,
    CustomTextArea,
    DatePicker,
    FilePicker,
    FileType,
    Header,
    InputField,
    Label
} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from '@apollo/react-components'
import {SELLER_DETAILED_RATE, SELLER_RATE} from 'components/src/api/sellerMutation'
import {SELLER_PROPERTY_LIST, SELLER_RATE_DETAILS} from 'components/src/api/sellerQuery'
//Utils
import {
    add1Day,
    add4Hours,
    checkDiff,
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    Icon,
    IS_WEB,
    MessageUtils,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {styles} from './styles.js';
import moment from "moment";


// import { Checkbox, Radio,WhiteSpace } from '@ant-design/react-native/lib/';
// import List from "@ant-design/react-native/lib/list";

// import {Button, InputItem} from "@ant-design/react-native";
// import { Radio, WhiteSpace } from '@ant-design/react-native';

// import { DatePickerView } from 'antd-mobile';

const options = [{
    id: 1,
    name: Strings.iarrangetheappraiseronmyown,
    value: 1
}];

class Rate extends React.Component {
    constructor(props) {
        super(props);
        if (props.property?.order_rate_detail) {
            this.orderRateDetails = props.property?.order_rate_detail ?? null;
        }
        this.state = {
            orderDate: null,
            taxDate: null,
            startTime: null,
            endTime: null,
            comments: undefined,
            tariffDoc: [],
            rateDetails: this.orderRateDetails,
            // part1Value: 1,
            pageShow: [],
            valuerate: this.orderRateDetails?.value_rate ?? '',
            errorvaluerate: null,
            loanrate: this.orderRateDetails?.loan_rate ?? '',
            errorloanrate: null,
            bta: this.orderRateDetails?.bta ?? '',
            errorbta: null,
            bra: this.orderRateDetails?.bra ?? '',
            errorbra: null,
            prom: this.orderRateDetails?.p_rom ?? '',
            errorprom: null,
            constyears: this.orderRateDetails?.construction_year ?? '',
            errorconstyear: null,
        }
        if (props.property?.orderrate || props.property?.order_rate_detail) {
            // IS_WEB ? props.history.goBack() : props.navigation.goBack()
        }
        this.scrollY = new Animated.Value(0)
    }

    handleChangeText = (text, fieldId) => {
        switch (fieldId) {
            case Constants.FieldId.VALUE_RATE:
                this.setState({
                    valuerate: text,
                    errorvaluerate: null
                });
                break;
            case Constants.FieldId.LOAN_RATE:
                this.setState({
                    loanrate: text,
                    errorloanrate: null
                });
                break;
            case Constants.FieldId.BTA:
                this.setState({
                    bta: text,
                    errorbta: null
                });
                break;
            case Constants.FieldId.BRA:
                this.setState({
                    bra: text,
                    errorbra: null
                });
                break;

            case Constants.FieldId.PROM:
                this.setState({
                    prom: text,
                    errorprom: null
                });
                break;

            case Constants.FieldId.CONST_YEAR:
                this.setState({
                    constyear: text,
                    errorconstyear: null
                });
                break;

        }
    };


    validate = (placeOrder) => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        const {orderDate, rateDetails, taxDate, startTime, comments, endTime, tariffDoc, valuerate, loanrate, bta, bra, prom, constyear, pageShow} = this.state;

        if (this.state.pageShow.includes(1)) {
            this.setState({
                errorvaluerate: validation('valuerate', valuerate.trim()),
                errorloanrate: validation('loanrate', loanrate),
                errorbta: validation('bta', bta),
                errorbra: validation('bra', bra),
                errorprom: validation('prom', prom),
                errorconstyear: validation('Constyears', constyear),
            }, () => {
                if (!this.state.errorvaluerate && !this.state.errorloanrate && !this.state.errorbta && !this.errorbra && !this.errorprom && !this.errorconstyear) {
                    if (tariffDoc.length) {
                        placeOrder({
                            variables: {
                                input: {
                                    property_id: this.props.property?.id,
                                    order_rate_id: null,
                                    construction_year: parseInt(constyear, 10),
                                    value_rate: parseFloat(valuerate),
                                    bta: parseInt(bta, 10),
                                    loan_rate: parseFloat(loanrate),
                                    bra: parseInt(bra, 10),
                                    p_rom: parseInt(prom, 10),
                                    tariff_doc_url: tariffDoc[0],
                                    tax_date: moment(taxDate).format(DateUtils.yyyy_mm_dd),
                                },
                            },
                        });
                    } else {
                        showMessage(MessageUtils.Errors.file,Constants.MessageType.FAILED)
                    }
                }
            })
        } else {
            if (orderDate) {
                if (startTime) {
                    if (endTime) {
                        if (moment(endTime).diff(moment(startTime), 'h') >= Constants.TIME_GAP) {
                            placeOrder({
                                variables: {
                                    input: {
                                        property_id: this.props.property?.id,
                                        order_date: moment(orderDate).format(DateUtils.yyyy_mm_dd),
                                        earliest_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                                        latest_start_time: moment(endTime).format(DateUtils.hh_mm_ss),
                                        comment: comments,
                                    },
                                },
                            });
                        } else {
                            showMessage(MessageUtils.Errors.errTimeGap,Constants.MessageType.FAILED)
                        }
                    } else {
                        showMessage(MessageUtils.Errors.endTime,Constants.MessageType.FAILED)
                    }
                } else {
                    showMessage(MessageUtils.Errors.startTime,Constants.MessageType.FAILED)
                }
            } else {
                showMessage(MessageUtils.Errors.orderDate,Constants.MessageType.FAILED)
            }
        }
    };

    navigateToThankYou = (data) => {
        const {orderDate, rateDetails, taxDate, startTime, comments, endTime, tariffDoc, valuerate, loanrate, bta, bra, prom, constyear, pageShow} = this.state;
        console.log("HREdfdf", this.state.pageShow)

        if (this.state.pageShow.includes(1)) {
            IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack();
        } else {
            if (IS_WEB) {
                console.log("HRE")
                this.props.history.replace(Routes.ThankYouRate, {
                    rateDetails: {
                        order_status: Constants.ORDER_STATUS.ORDERED,
                        order_date: moment(orderDate).format(DateUtils.yyyy_mm_dd),
                        earliest_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                        latest_start_time: moment(endTime).format(DateUtils.hh_mm_ss),
                        comment: comments,
                    }
                })
            } else {
                this.props.navigation.replace(Routes.ThankYouRate, {
                    rateDetails: {
                        order_status: Constants.ORDER_STATUS.ORDERED,
                        order_date: moment(orderDate).format(DateUtils.yyyy_mm_dd),
                        earliest_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                        latest_start_time: moment(endTime).format(DateUtils.hh_mm_ss),
                        comment: comments,
                    }
                });
            }
        }
    }

    _renderCheckBoxUnclickView = () => {
        return (
            <View>
                <DatePicker title={Strings.date}

                            min={new Date(moment().add(1, 'd'))} key={'taxDate'}
                            onSelectDate={(date) => this.setState({orderDate: date})}
                            placeholder={Strings.selectDate}
                />

                <Label
                    mt={18}
                    small
                    font_regular
                    align={'left'}>
                    {Strings.rateInstruction2}
                </Label>

                <DatePicker title={Strings.startTime}
                            onSelectDate={(date) => this.setState({
                                startTime: date,
                                endTime: checkDiff(date) ? add1Day() : add4Hours(date)
                            })}
                            placeholder={Strings.selectTime}
                            minTime={new Date(moment().add(1, 'd').set({hours: 0, minutes: 0, seconds: 0}))}
                            maxTime={add1Day()}
                            mode={'time'}/>
                <DatePicker title={Strings.endTime}
                            selectedDate={this.state?.startTime && !this.state.endTime ? checkDiff(this.state?.startTime) ? add1Day() : add4Hours(this.state.startTime) : this.state.endTime ? new Date(moment(this.state.endTime)) : null}
                            minTime={checkDiff(this.state?.startTime) ? add1Day() : add4Hours(this.state.startTime)}
                            maxTime={add1Day()}
                            placeholder={Strings.selectTime}
                            onSelectDate={(date) => {
                                console.log(date)
                                this.setState({endTime: date})
                            }}
                            mode={'time'}/>
                <CustomTextArea
                    mt={18}
                    heightInRow={5}
                    value={this.state.comments}
                    placeholder={Strings.writeYourComments}
                    labelText={Strings.comments}
                    onChange={(comments) => {
                        this.setState({comments: comments})
                    }}/>

                <Mutation mutation={SELLER_RATE}
                          onCompleted={(data) => this.navigateToThankYou(data)}
                          refetchQueries={[{
                              query: SELLER_PROPERTY_LIST,
                              variables: {
                                  status: 1
                              }
                          }]}
                          onError={() => {
                          }}>
                    {(placeOrder, {loading, data}) => (
                        <CustomButton
                            mt={18}
                            mb={18}
                            title={Strings.order}
                            loading={loading}
                            onPress={() => {
                                this.validate(placeOrder)
                                //this.navigateToThankYou();

                            }}/>
                    )}
                </Mutation>


            </View>
        );
    }

    _renderCkeckBoxClickView = () => {
        return (
            <View style={CommonStyle.flex}>
                <DatePicker max={new Date()}
                            title={Strings.taxdate}
                            selectedDate={null}
                            onSelectDate={(date) => this.setState({taxDate: date})}
                            placeholder={Strings.entertaxdate}/>

                <View style={styles.fieldView}>
                    <Label small
                           font_medium
                           mb={0}
                           mt={30}
                           align={'left'}>{Strings.valuerate}</Label>

                </View>
                <InputField editable={!this.orderRateDetails} mt={15} type={'number'}
                            placeholder={Strings.entervaluerate}
                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.VALUE_RATE)}
                            onFocus={() => this.setState({errorvaluerate: null})}
                            error={this.state.errorvaluerate}
                            value={this.state.valuerate}
                />
                <View style={styles.fieldView}>
                    <Label small
                           font_medium
                           mb={0}
                           align={'left'}>{Strings.loanrate}</Label>

                </View>
                <InputField editable={!this.orderRateDetails} mt={15} type={'number'}
                            placeholder={Strings.enterloanrate}
                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.LOAN_RATE)}
                            onFocus={() => this.setState({errorloanrate: null})}
                            error={this.state.errorloanrate}
                            value={this.state.loanrate}
                />
                <View style={styles.fieldView}>
                    <Label small
                           font_medium
                           mb={0}
                           align={'left'}>{Strings.btatitle}</Label>

                </View>
                <InputField editable={!this.orderRateDetails} mt={15} type={'number'} placeholder={Strings.enterbta}
                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.BTA)}
                            onFocus={() => this.setState({errorbta: null})}
                            error={this.state.errorbta}
                            value={this.state.bta}
                />
                <View style={styles.fieldView}>
                    <Label small
                           font_medium
                           mb={0}
                           align={'left'}>{Strings.bratitle}</Label>

                </View>
                <InputField editable={!this.orderRateDetails} mt={15} type={'number'} placeholder={Strings.enterbra}
                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.BRA)}
                            onFocus={() => this.setState({errorbra: null})}
                            error={this.state.errorbra}
                            value={this.state.bra}
                />
                <View style={styles.fieldView}>
                    <Label small
                           font_medium
                           mb={0}
                           align={'left'}>{Strings.prom}</Label>

                </View>
                <InputField editable={!this.orderRateDetails} mt={15} type={'number'} placeholder={Strings.enterprom}
                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.PROM)}
                            onFocus={() => this.setState({errorprom: null})}
                            error={this.state.errorprom}
                            value={this.state.prom}
                />
                <View style={styles.fieldView}>
                    <Label small
                           font_medium
                           mb={0}
                           align={'left'}>{Strings.constructionyear}</Label>

                </View>
                <InputField editable={!this.orderRateDetails} mt={15}
                            type="number"
                            placeholder={Strings.enterconstructionyear}
                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.CONST_YEAR)}
                            onFocus={() => this.setState({errorconstyear: null})}
                            error={this.state.errorconstyear}
                            value={this.state.constyear}
                />
                <View style={styles.titleCont}>
                    <Label small
                           font_medium
                           mb={15}
                           align={'left'}>
                        {Strings.uploadtariffdocument}
                    </Label>

                </View>
                <FilePicker onFilePicked={(data, file) => {
                    this.setState({tariffDoc: file})
                }}
                            type={FileType.PDF}/>

                {this.state.tariffDoc.map(item => (
                    <Attachment isDeletable
                                style={{marginTop: 15}}
                                name={item.name}
                                onPressDelete={() => this.setState({tariffDoc: []})}/>
                ))}
                <Mutation mutation={SELLER_DETAILED_RATE}
                          refetchQueries={[{
                              query: SELLER_PROPERTY_LIST,
                              variables: {
                                  status: 1
                              }
                          }]}
                          onCompleted={() => IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack()}
                          onError={() => {
                          }}>
                    {(placeOrder, {loading, data}) => (
                        <CustomButton mt={18}
                                      mb={18}
                                      loading={loading}
                                      title={Strings.Save}
                                      onPress={() => {
                                          this.validate(placeOrder)
                                      }}
                        />)}
                </Mutation>


            </View>
        );

    }

    render() {
        return (
            <View style={CommonStyle.container}>
                <Provider locale={enUS}>
                    <Header animatedTitle={Strings.rate}
                            navigation={IS_WEB ? this.props.history : this.props.navigation}
                            animatedValue={this.scrollY}/>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         keyboardShouldPersistTaps={'always'}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                             {useNativeDriver: true})}>

                        <Query query={SELLER_RATE_DETAILS}
                               fetchPolicy={'cache-and-network'}
                               variables={{
                                   propertyId: this.props?.property?.id,
                               }}
                               onCompleted={(data) => {
                                   this.setState({
                                       rateDetails: data.property_rate_detail
                                   })
                               }}>
                            {({data, loding, error}) => {
                                return (
                                    <View style={styles.container}>
                                        <View style={styles.topContainer}>
                                            <View style={styles.labeliconView}>
                                                <Icon
                                                    size={ThemeUtils.fontXXLarge}
                                                    name={'rate'}
                                                    color={Color.PRIMARY_DARK}/>
                                            </View>
                                            <Label
                                                mt={16}
                                                small
                                                align={'left'}>
                                                {Strings.rateInstruction1}
                                            </Label>
                                            <View style={styles.checkbox}>
                                                <CheckBox options={options}
                                                          defaultChecked={!this.props.property?.orderrate && this.props.property?.order_rate_detail ? [1] : []}
                                                          size={ThemeUtils.fontXLarge}
                                                          labelProps={{
                                                              font_medium: true,
                                                              small: true
                                                          }}
                                                          style={styles.energyradio}
                                                          onCheckChanged={(val) => this.setState({pageShow: val})}
                                                />

                                            </View>
                                            {this.state.pageShow.includes(1) ? this._renderCkeckBoxClickView() : this._renderCheckBoxUnclickView()}

                                        </View>

                                    </View>
                                )
                            }}

                        </Query>
                    </Animated.ScrollView>
                </Provider>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Rate);
