import {StyleSheet} from 'react-native';
import { Color } from '../../../../../utils';

const containerHorizontalMargin = 20;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    titleCont:{
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center',
        justifyContent: 'space-around',
    },

    fieldContainer: {
        alignContent: 'center',
    },

    costingFlatList: {
        backgroundColor: Color.PRIMARY_BG_COLOR,
        paddingVertical: 15,
        marginVertical: 9
    },

    totalAmountMainContainer: {
        paddingTop: 10
    },

    seperaterView: {
        backgroundColor: Color.PRIMARY_DARK,
        height: 0.5,
        marginHorizontal: 14,
    },

    totalAmountSubContainer: {
        paddingTop: 7,
        paddingStart: 7,
    }
});

export {styles};
