// import { DownloadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
  Input,
  Form,
  Rate,
  Button,
  Divider,
  Typography,
  List,
  notification,
  Modal,
} from "antd";
// import { LoadingOutlined } from '@ant-design/icons';
import React, { useState } from "react";

import { useHistory } from "react-router";
import { Mutation } from "react-apollo";
import {
  ADMIN_ADD_AGENT_FEEDBACK,
  ADMIN_EDIT_AGENT_FEEDBACK,
  ADMIN_DELETE_FEEBACK,
} from "../../../../../../../../../../mutation/AdminMutation";
// import { ADMIN_FEEDBACK_LIST } from '../../../../../../../../../../query/AdminQuery';

import "./editagentfeedback.scss";
import { formatMessage } from "components/src/utils";

// const props = {
//   showUploadList: {
//     showDownloadIcon: true,
//     downloadIcon: <DownloadOutlined />,
//     showRemoveIcon: true,
//     removeIcon: <i className="icon-delete" />,
//   },
//   defaultFileList: [
//     {
//       uid: '1',
//       name: 'tariff.png',
//       status: 'done',
//       response: 'Server Error 500', // custom error message to show
//       url: 'http://www.baidu.com/xxx.png',
//     },
//   ],
// };

const { Title } = Typography;

const { TextArea } = Input;

export const EditAgentFeedBack = props => {
  let router=useHistory();
  const forData = props.objectData;
  console.log('feedback>>', forData);
  const [addFeedbackForm] = Form.useForm();

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackList, setFeedbackList] = useState(props.objectData.feedback);
  const [currentSelectedItem, setCurrentSelectedItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const onFeedbackFinish = (values, addAgentFeedbackData) => {
    console.log('Success:', values);
    addAgentFeedbackData({
      variables: {
        input: {
          // id:forData.id,
          agent_id: forData.id,
          rating: values.rating,
          feedback: values.feedback,
          given_by: values.givenby,
        },
      },
    }).then(({ data }) => {
      console.log('Data submitted===>>>', data);
      if (data && data.add_agent_feedback) {
        notification.success({
          message: 'Tilbakemelding ble lagt til',
          description: '',
          duration: 3,
        });
        if (addFeedbackForm) {
          addFeedbackForm.resetFields();
        }
        setFeedbackList(data.add_agent_feedback);
      }
    });
  };
  const onFeedbackFinishFailed = errorinfo => console.log(errorinfo);

  const onFinish = values => console.log(values);
  const onFinishFailed = errorinfo => console.log(errorinfo);

  return (
      <div className="contentshadow" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className="text-primary main">
          <Mutation mutation={ADMIN_ADD_AGENT_FEEDBACK}>
            {(addAgentFeedbackData, { loading }) => {
              return (
                  <Form
                      form={addFeedbackForm}
                      layout="vertical"
                      className="user-forms"
                      name="uploadpic"
                      initialValues={{
                        remember: true,
                      }}
                      colon={false}
                      onFinish={values => onFeedbackFinish(values, addAgentFeedbackData)}
                      onFinishFailed={onFeedbackFinishFailed}
                  >
                    <Row>
                      <Col>
                        <Form.Item
                            name="rating"
                            className="label-texts"
                            label={formatMessage({
                              id: 'component.allagents.ratings',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Værsåsnill gi ${formatMessage({
                                  id: 'component.allagents.ratings',
                                })}!`,
                              },
                            ]}
                        >
                          <Rate allowHalf />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="feedback"
                            className="label-texts"
                            label={formatMessage({
                              id: 'component.allagents.feedback',
                            })}
                            rules={[
                              {
                                required: false,
                                message: `Vennligst  ${formatMessage({
                                  id: 'component.form.comment',
                                })}!`,
                              },
                            ]}
                        >
                      <TextArea
                          rows={3}
                          placeholder={formatMessage({
                            id: 'component.allagents.feedback.placeholder',
                          })}
                      />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={8}>
                        <Form.Item
                            name="givenby"
                            className="label-texts"
                            label={formatMessage({
                              id: 'component.allagents.feedbackgivenby',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst  ${formatMessage({
                                  id: 'component.allagents.feedbackgivenby.placeholder',
                                })}!`,
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.allagents.feedbackgivenby.placeholder',
                              })}
                              size="large"
                          />
                        </Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            className="btn-secondary buttons admin-forms-btn mt-3"
                            loading={loading}
                        >
                          {formatMessage({
                            id: 'component.form.submit',
                          })}
                        </Button>
                        <Button
                            size="large"
                            onClick={() => router.goBack()}
                            className="buttons admin-forms-btn back-button mt-3 cancel-btn"
                        >
                          {formatMessage({
                            id: 'component.form.cancel',
                          })}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
              );
            }}
          </Mutation>
          <Divider />
          <Row>
            <Col lg={24}>
              <Title level={4}>
                {formatMessage({
                  id: 'component.allagents.allfeedback',
                })}
                {`: `}
              </Title>
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <List
                  className="listViewStyle"
                  itemLayout="horizontal"
                  dataSource={feedbackList}
                  renderItem={item => (
                      <List.Item
                          actions={[
                            <i
                                className="icon-edit"
                                style={{ marginRight: 8 }}
                                onClick={() => {
                                  setCurrentSelectedItem(item);
                                  setEditModal(true);
                                }}
                            />,
                            <i
                                className="icon-delete"
                                onClick={() => {
                                  setCurrentSelectedItem(item);
                                  setDeleteModal(true);
                                }}
                            />,
                          ]}
                      >
                        <List.Item.Meta
                            title={
                              <a className="text-bold">
                                <Rate allowHalf defaultValue={item.rating} disabled />
                              </a>
                            }
                            description={
                              <>
                                <p className="mb-0">{item.feedback}</p>
                                <p className="d-block label-texts">{item.given_by}</p>
                              </>
                            }
                        />
                      </List.Item>
                  )}
              />
            </Col>
          </Row>
          <Modal
              title={`Rediger ${formatMessage({
                id: 'component.allagents.tab2',
              }).toLowerCase()}`}
              visible={editModal}
              footer={null}
              width="720px"
              destroyOnClose
              onCancel={() => setEditModal(false)}
          >
            <Mutation mutation={ADMIN_EDIT_AGENT_FEEDBACK}>
              {(editAgentFeedback, { loading, data }) => {
                return (
                    <Form
                        layout="vertical"
                        className="user-forms"
                        name="edit-feedback"
                        initialValues={
                          currentSelectedItem
                              ? {
                                rating: currentSelectedItem.rating,
                                feedback: currentSelectedItem.feedback,
                                givenby: currentSelectedItem.given_by,
                              }
                              : {
                                remember: true,
                              }
                        }
                        colon={false}
                        onFinish={values => {
                          console.log('Success==>>', values);
                          editAgentFeedback({
                            variables: {
                              agent_id: props.objectData.id,
                              id: currentSelectedItem.id,
                              rating: values.rating,
                              feedback: values.feedback,
                              given_by: values.givenby,
                            },
                          }).then(({ data, loading }) => {
                            if (data) {
                              console.log('Feedback Editeedd===>>>', data, loading);
                              if (data && data.edit_agent_feedback && data.edit_agent_feedback) {
                                notification.success({
                                  message: 'Kundetilbakemelding oppdatert',
                                  description: '',
                                  duration: 3,
                                });

                                setEditModal(false);
                                setFeedbackList(data.edit_agent_feedback);
                                console.log('Data in successs==>>', data.edit_agent_feedback);
                              }
                            }
                          });
                        }}
                        onFinishFailed={onFeedbackFinishFailed}
                    >
                      <Row>
                        <Col>
                          <Form.Item
                              name="rating"
                              className="label-texts"
                              label={formatMessage({
                                id: 'component.allagents.ratings',
                              })}
                              rules={[
                                {
                                  required: false,
                                  message: `Vennligst skriv en ${formatMessage({
                                    id: 'component.allagents.ratings',
                                  }).toLowerCase()}!`,
                                },
                              ]}
                          >
                            <Rate allowHalf />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xs={24} lg={16}>
                          <Form.Item
                              name="feedback"
                              className="label-texts"
                              label={formatMessage({
                                id: 'component.allagents.feedback',
                              })}
                              rules={[
                                {
                                  required: false,
                                  message: `Vennligst skriv en ${formatMessage({
                                    id: 'component.form.comment',
                                  }).toLowerCase()}!`,
                                },
                              ]}
                          >
                        <TextArea
                            rows={3}
                            placeholder={formatMessage({
                              id: 'component.allagents.feedback.placeholder',
                            })}
                        />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={8}>
                          <Form.Item
                              name="givenby"
                              className="label-texts"
                              label={formatMessage({
                                id: 'component.allagents.feedbackgivenby',
                              })}
                              rules={[
                                {
                                  required: true,
                                  message: `Vennligst skriv en ${formatMessage({
                                    id: 'component.allagents.feedbackgivenby.placeholder',
                                  }).toLowerCase()}!`,
                                },
                              ]}
                          >
                            <Input
                                placeholder={formatMessage({
                                  id: 'component.allagents.feedbackgivenby.placeholder',
                                })}
                                size="large"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xs={24}>
                          <Button
                              type="primary"
                              htmlType="submit"
                              className="btn-secondary buttons admin-forms-btn"
                              loading={loading}
                              size="large"
                          >
                            {formatMessage({
                              id: 'component.form.update',
                            })}
                          </Button>
                          <Button
                              className="text-primary admin-forms-btn"
                              style={{ marginLeft: 8 }}
                              size="large"
                              onClick={() => setEditModal(false)}
                          >
                            {formatMessage({
                              id: 'component.form.cancel',
                            })}
                          </Button>
                          <Button
                              className=" admin-forms-btn notice delete-btn"
                              style={{ float: 'right' }}
                              onClick={() => {
                                setEditModal(false);
                                setDeleteModal(true);
                              }}
                              size="large"
                          >
                            {formatMessage({
                              id: 'component.form.delete',
                            })}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                );
              }}
            </Mutation>
          </Modal>
          <Modal
              centered
              title={`${formatMessage({
                id: 'component.allagents.deletemodal.title',
              })} ${formatMessage({
                id: 'component.allagents.tab2',
              }).toLowerCase()}`}
              visible={deleteModal}
              onOk={() => setDeleteModal(false)}
              onCancel={() => setDeleteModal(false)}
              width="450px"
              footer={[
                <Mutation mutation={ADMIN_DELETE_FEEBACK}>
                  {(deleteFeedback, { loading }) => {
                    return (
                        <Button
                            key="submit"
                            type="primary"
                            className="admin-forms-btn  mr_15"
                            size="large"
                            loading={loading}
                            onClick={() => {
                              deleteFeedback({
                                variables: {
                                  agent_id: props.objectData.id,
                                  id: currentSelectedItem?.id,
                                },
                              }).then(({ data, loading }) => {
                                if (data && !loading) {
                                  console.log('Feedback Deleteeddd===>>>', data, loading);
                                  if (data && data.delete_agent_feedback && data.delete_agent_feedback) {
                                    notification.success({
                                      message: 'Kundetilbakemelding slettet',
                                      description: '',
                                      duration: 3,
                                    });
                                    setFeedbackList(data.delete_agent_feedback);
                                    setDeleteModal(false);
                                    if (editModal) {
                                      setEditModal(false);
                                    }
                                  }
                                }
                              });
                            }}

                        >
                          {formatMessage({
                            id: 'component.form.yes',
                          })}
                        </Button>
                    );
                  }}
                </Mutation>,
                <Button
                    className="admin-forms-btn  mr_15"
                    size="large"
                    onClick={() => setDeleteModal(false)}
                >
                  {formatMessage({
                    id: 'component.form.no',
                  })}
                </Button>,
              ]}
          >
            <p className="text-primary no-margin">
              {formatMessage({
                id: 'component.allagents.deletemodal.text',
              })}
            </p>
          </Modal>
        </div>
      </div>
  );
};

export default EditAgentFeedBack;
