export default {
  'component.agentrate.title': 'Takst',
  'component.agentrate.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  'component.agentrate.taxdate': 'Skattedato',
  'component.agentrate.valuerate': 'Verdiskurs',
  'component.agentrate.loanrate': 'Lånesats',
  'component.agentrate.bta': 'BTA',
  'component.agentrate.bra': 'BRA',
  'component.agentrate.prom': 'P-ROM',
  'component.agentrate.construction': 'Byggeår',
  'component.agentrate.downloadpdf': 'Last ned PDF',
  'component.agentrate.viewpdf': 'Se PDF',
};
