import validate from 'validate.js';
import Message from './MessageUtils';

let constraints = {
    firstName:{
        presence: {
            message: Message.Errors.nameBlank,
        },
    },
    lastName:{
        presence: {
            message: Message.Errors.nameBlank,
        },
    },
    address:{
        presence: {
            message: Message.Errors.address,
        },
    },
    name: {
        presence: {
            message: Message.Errors.nameBlank,
        },
        length: {
            maximum: 30,
            minimum: 2,
            tooLong: Message.Errors.nameLength,
            tooShort: Message.Errors.nameLength,
        },
        format: {
            pattern: /^[a-zA-Z\s]*$/,
            message: Message.Errors.nameInvalid,
        },
    },
    searchName: {
        presence: {
            message: Message.Errors.saveSearchName,
        },
        length: {
            minimum: 3,
            message: Message.Errors.saveSearchNameLength,
        },
    },
    description: {
        presence: {
            message: Message.Errors.descriptionRequired,
        },
      /*  length: {
            minimum: 10,
            maximum: 160,
            tooShort: Message.Errors.descriptionRequiredLengthMin,
            tooLong: Message.Errors.descriptionRequiredLengthMax,
        },*/
    },
    question: {
        presence: {
            message: Message.Errors.question,
        },
    },
    email: {
        presence: {
            message: Message.Errors.emailBlank,
        },
        format: {
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: Message.Errors.emailValidity,
        },
    },
    password: {
        presence: {
            message: Message.Errors.pwdBlank,
        },
        length: {
            minimum: 8,
            message: Message.Errors.pwdLengthMinimum,
        },
        format:{
            pattern: /^(?=.*[!@#$%^&*-])(?=.*[0-9])(?=.*[A-Z]).{8,}$/,
            message:Message.Errors.pwdInvalid
        }
    },
    passwordNoSpecialChar:{
        presence: {
            message: Message.Errors.pwdBlank,
        },
        length: {
            minimum: 8,
            message: Message.Errors.pwdLengthMinimum,
        },
        format:{
            pattern: /^(?=.*[0-9])(?=.*[A-Z]).{8,}$/,
            message:Message.Errors.pwdInvalid
        }
    },
    passwordBlank: {
        presence: {
            message: Message.Errors.pwdBlank,
        },
    },
    recommendation: {
        presence: {
            message: Message.Errors.recommendation,
        },
    },
    phoneNo: {
        presence: {
            message: Message.Errors.phoneBlank,
        },
        numericality: {
            notValid: Message.Errors.phoneInvalid,
        },
        length: {
            minimum: 6,
            maximum:12,
            tooLong: Message.Errors.phoneTooLong,
            tooShort: Message.Errors.phoneTooShort,
        },
    },
    age: {
        presence: {
            message: Message.Errors.ageBlank,
        },
        numericality: {
            onlyInteger: true,
            strict: true,
            lessThanOrEqualTo: 100,
            greaterThanOrEqualTo: 5,
            notGreaterThanOrEqualTo: Message.Errors.ageLess,
            notLessThanOrEqualTo: Message.Errors.ageGrater,
            notValid: Message.Errors.ageInvalid,
            notInteger: Message.Errors.ageInvalid,
        },
    },
    couponCode: {
        presence: {
            message: Message.Errors.couponCodeBlank,
        },
    },
    comment: {
        presence: {
            message: Message.Errors.commentBlank,
        },
    },
    Contacts: {
        presence: {
            message: Message.Errors.contacts,
        },
    },
    zipCode: {
        presence: {
            message: Message.Errors.zipCode,
        },
        length: {
            maximum: 5,
            tooLong: Message.Errors.zipCodeLength,
        },
    },
    flagComment: {
        presence: {
            message: Message.Errors.flagCommentBlank,
        },
    },
    city: {
        presence: {
            message: Message.Errors.defaultCity,
        },
    },
    countryCode: {
        presence: {
            message: Message.Errors.countryCodeBlank,
        },
    },
    playListName: {
        presence: {
            message: Message.Errors.playListNameBlank,
        },
    },

    DenominationKr: {
        presence: {
            message: Message.Errors.denominationkr,
        },
    },

    ResidualDebt: {
        presence: {
            message: Message.Errors.residualDebtkr,
        },
    },

    Loanno: {
        presence: {
            message: Message.Errors.loanNo,
        },
    },
    valuerate: {
        presence: {
            message: Message.Errors.valuerate,
        },
    },
     loanrate: {
        presence: {
            message: Message.Errors.loanrate,
        },
    },
    bta: {
        presence: {
            message: Message.Errors.bta,
        },
    },
    bra: {
        presence: {
            message: Message.Errors.bra,
        },
    },
    prom: {
        presence: {
            message: Message.Errors.prom,
        },
    },
    Constyears: {
        presence: {
            message: Message.Errors.constyears,
        },
    },

    AccountNoForTransfer: {
        presence: {
            message: Message.Errors.accountnofortransfer,
        },
    },
    buyer:{
        presence: {
            message: Message.Errors.buyerRequired,
        },
    },
    interest:{
        presence: {
            message: Message.Errors.interestRequired,
        },
    },
    percentage:{
        presence: {
            message: Message.Errors.percentageRequired,
        },
    },
    wallType:{
        presence: {
            message: Message.Errors.wallType,
        },
    },
    floorType:{
        presence: {
            message: Message.Errors.floorType,
        },
    },
    hamling:{
        presence: {
            message: Message.Errors.hamling,
        },
    },

    workTop:{
        presence: {
            message: Message.Errors.workTop,
        },
    },
    appliances:{
        presence: {
            message: Message.Errors.appliances,
        },
    },
    serviceName:{
        presence: {
            message: Message.Errors.serviceName,
        },
    },
    heading:{
        presence: {
            message: Message.Errors.heading,
        },
    },
    price:{
        presence: {
            message: Message.Errors.price,
        },
    },
    link: {
        presence: {
            message: Message.Errors.linkRequired,
        },
        format: {
            pattern: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
            message: Message.Errors.linkInvalid,
        },
    },
    fullName: {
        fullName: {
            nameLengthMin: 2,
            nameLengthMax: 30,
            minLengthMessage: Message.Errors.nameLengthMin,
            maxLengthMessage: Message.Errors.nameLengthMax,
            lastNameMessage: Message.Errors.lastNameRequired,
            nameMessage: Message.Errors.nameBlank,
        },
    },
    personnummer:{
        presence:{
            message:Message.Errors.personNumber
        }
    },
    eierandel:{
        presence:{
            message:Message.Errors.ownership
        }
    },
    organizationNo:{
        presence:{
            message:Message.Errors.organizationNo
        }
    },
    securityNo:{
        presence:{
            message:Message.Errors.securityNo
        }
    },
    equity:{
        presence:{
            message:Message.Errors.equity
        }
    },
    originEquity:{
        presence:{
            message:Message.Errors.originEquity
        }
    },
    purposeOfPurchase:{
        presence:{
            message:Message.Errors.purposeOfPurchase
        }
    },
    receivables:{
        presence:{
            message:Message.Errors.receivablesInvalid
        },
        numericality: {
            notValid: Message.Errors.receivablesInvalid,
        },
        format: {
            pattern: /^\d{11}$/,
            message: Message.Errors.receivablesInvalid,
        }
    },
};

validate.validators.fullName = function (value, options, key, attributes) {
    /* let separatedName = '';
     let firstName = '';
     let lastName = '';
     if (value) {
         separatedName = value.split(' ');
         firstName = separatedName.slice(0, separatedName.length - 1).join(' ');
         lastName = separatedName.slice(separatedName.length - 1).join('');
     } else {
         return options.nameMessage;
     }
     if (separatedName.length < 2) {
         return options.lastNameMessage;
     } else if (firstName.length < 1) {
         return `First ${options.minLengthMessage}`;
     } else if (firstName.length > 30) {
         return `First ${options.maxLengthMessage}`;
     } else if (lastName.length < 1) {
         return `Last ${options.minLengthMessage}`;
     } else if (lastName.length > 30) {
         return `Last ${options.maxLengthMessage}`;
     }
     return null;*/
    if (value) {
        if (value.length < options.nameLengthMin) {
            return options.minLengthMessage
        }
    } else {
        return options.nameMessage;
    }
};

export function validation(fieldName, value) {
    let formValues = {};
    formValues[fieldName] = value === '' ? null : value;

    let formFields = {};
    formFields[fieldName] = constraints[fieldName];

    let result = validate(formValues, formFields, {fullMessages: false});

    if (result) {
        return result[fieldName][0];
    }
    return null;
}


let PasswordConstraints = {
    confirmPassword: {
        equality: {
            attribute: 'password',
            message: Message.Errors.pwdMisMatch,
        },
    },
};

/**
 * @return {null}
 */
export function PasswordValidate(password, confirmPassword) {
    let result1 = validate({
        password: password,
        confirmPassword: confirmPassword,
    }, PasswordConstraints, {fullMessages: false});

    if (result1 !== null && result1 !== undefined) {
        if (result1.confirmPassword !== undefined) {
            return result1.confirmPassword[0];
        }
    }
    return null;

}
