import React from 'react'
//Components
import {ActivityIndicator, Animated, Image, SafeAreaView, TouchableOpacity, View} from 'react-native'

import {CustomButton, Dialog, FilePicker, FileType, Header, Label, Modal} from "components/src/components";
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {PHOTOGRAPHER_ORDER_DETAILS, PHOTOS} from "components/src/api/photographerQuery";
import {DELETE_PHOTOS, UPLOAD_PHOTO} from "components/src/api/photographerMutation";
//Utils
import {Color, CommonStyle, Constants, Icon, IS_WEB, openInNewTab, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';
import PhotoDetails from "../PhotoDetails";

const TABS = [{
    text: Strings.sellerInformation
}, {
    text: Strings.uploadPhoto
}];

class Photo extends React.Component {
    constructor(props) {
        super(props)

        this.propertyId = IS_WEB ? props.history.location?.state?.id : props.navigation.getParam('id');
        this.refetch = () => {
        };


        this.state = {
            uploadPhotoShow: false,
            inspectionDate: undefined,
            inspectionDateDisplay: '',

            earlierStartTime: undefined,
            earlierStartTimeDisplay: '',

            latestStartTime: undefined,
            latestStartTimeDisplay: '',

            comments: undefined,
            selectedAddServices: [],
            selectedImageForDelete: [],

            link: '',
            videoLinkVisible: false,
            viewLinkVisible: false,
            dialogVisible: false,


            totalAmount: 0,
            displayAmount: undefined,
            cheboxshow: false,
            count: 0,
            selectedImages: [],
            idForDelete: [],
            isPreviewVisible: false,
            photoDetailsVisible: false,
            selectedImage: null,
            selectionEnable: false

        }
        this.scrollY = new Animated.Value(0)
    }


    toggleSelectionMode = (index, userId) => {
        this.setState(prevState => ({
            selectionEnable: !prevState.selectionEnable,
        }), () => {
            if (this.state.selectionEnable) {
                userId == this.props.user?.id ? this.addOrRemoveImage(index) : null
            } else {
                this.setState({
                    selectedImageForDelete: []
                })
            }
        })
    };

    addOrRemoveImage = (index) => {

        let selectedImages = this.state.selectedImageForDelete.slice();
        if (selectedImages.includes(index)) {
            selectedImages.splice(selectedImages.indexOf(index), 1)
        } else {
            selectedImages.push(index)
        }
        this.setState({
            selectedImageForDelete: selectedImages,
            selectionEnable: selectedImages.length !== 0
        })
    };

    toggleImagePreviewModal = () => {
        this.setState(prevState => ({
            isPreviewVisible: !prevState.isPreviewVisible
        }))
    }

    toggleImageDetailsModal = (image) => {
        this.setState(prevState => ({
            photoDetailsVisible: !prevState.photoDetailsVisible,
            selectedImage: image,
        }))
    }

    renderUploadPhotoModal = () => {
        return (
            <Modal visible={this.state.isPreviewVisible}
                   hardwareAccelerated
                   onRequestClose={this.toggleImagePreviewModal}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.flex}>
                        <Header animatedValue={new Animated.Value(0)}
                                headerLeft={'close'}
                                onPressLeft={this.toggleImagePreviewModal}
                        />
                        <View style={CommonStyle.flex}>
                            <Animated.ScrollView>
                                <View style={styles.ImageGridView}>
                                    {this.state.selectedImages.map(item => {
                                        return (
                                            <Image source={IS_WEB ? URL.createObjectURL(item) : {uri: item.uri}}
                                                   style={styles.GridImage}/>

                                        )
                                    })}
                                </View>
                            </Animated.ScrollView>
                        </View>
                        <View style={styles.btnCont}>
                            <Mutation mutation={UPLOAD_PHOTO}
                                      onCompleted={() => {
                                          this.toggleImagePreviewModal();
                                          this.refetch && this.refetch()
                                      }}>
                                {(uploadDoc, {loading}) => {
                                    return (<CustomButton title={Strings.Save}
                                                          onPress={() => {
                                                              uploadDoc({
                                                                  variables: {
                                                                      photo: this.state.selectedImages,
                                                                      photoOrderId: this.props.property?.order_photo?.id
                                                                  }
                                                              })
                                                          }}
                                                          loading={loading}
                                                          style={{flex: 1}}
                                                          mr={10}/>)
                                }}
                            </Mutation>

                            <CustomButton title={Strings.cancel}
                                          onPress={() => {
                                              this.setState({selectedImages: []}, this.toggleImagePreviewModal)
                                          }}
                                          style={{flex: 1}}
                                          textColor={Color.PRIMARY}
                                          borderWidth={1}
                                          borderColor={Color.PRIMARY_LIGHT}
                                          bgColor={Color.WHITE}
                                          ml={10}/>
                        </View>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    renderPhotoDetailsModal = () => {
        return (
            <Modal visible={this.state.photoDetailsVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={this.toggleImageDetailsModal}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <PhotoDetails image={this.state.selectedImage}
                                  user={this.props.user}
                                  refetch={this.refetch}
                                  onClose={this.toggleImageDetailsModal}/>
                </SafeAreaView>
            </Modal>
        )
    }

    renderUploadPhotos = (orderDetails) => {
        console.log(orderDetails)
        return (
            <View style={CommonStyle.flex}>
                <Query query={PHOTOS}
                       variables={{
                           status: 1,
                           first: 30,
                           page: 1,
                           photoId: this.props.property?.order_photo?.id
                       }}>
                    {({data, loading, refetch, fetchMore, error}) => {
                        this.refetch = refetch;
                        if (loading && !data) {
                            return <ActivityIndicator color={Color.PRIMARY}
                                                      style={CommonStyle.loader}/>
                        }
                        return (
                            <View>
                                {this.renderUploadPhotoModal()}
                                {this.renderPhotoDetailsModal()}
                                <View style={styles.topContainer}>

                                    <View>
                                        <Label small font_regular mt={15} mb={15}>
                                            {Strings.uploadintroduction}
                                        </Label>
                                    </View>


                                    <Label small font_medium mt={15} mb={15}>
                                        {Strings.uploadnewphoto}
                                    </Label>

                                    <FilePicker multiple
                                                style={{alignSelf: 'flex-start'}}
                                                type={FileType.IMAGE}
                                                onFilePicked={(data, files) => {
                                                    if (files.length) {
                                                        this.setState({selectedImages: files}, () => {
                                                            this.toggleImagePreviewModal()
                                                        })
                                                    }

                                                }}>
                                        <View style={[styles.SubViewIcon, {backgroundColor: Color.GRAY}]}>
                                            <Icon name={'add'}
                                                  size={ThemeUtils.responsiveScale(60)}
                                                  color={Color.PRIMARY_LIGHT}/>

                                        </View>
                                    </FilePicker>


                                    <View style={[styles.MainView]}>

                                        <View
                                            style={[styles.SubView, orderDetails?.order?.photo_order_video_view?.video_url ? null : {opacity: 0.8}]}>
                                            <Label small font_medium mt={15} mb={15}>
                                                {Strings.watchVideo}
                                            </Label>
                                            <TouchableOpacity
                                                disabled={!orderDetails?.order?.photo_order_video_view?.video_url}
                                                onPress={() => {
                                                    if (orderDetails?.order?.photo_order_video_view?.video_url) {
                                                        openInNewTab(orderDetails.order?.photo_order_video_view?.video_url)
                                                    }
                                                }}>
                                                <View style={[styles.SubViewIcon]}>
                                                    <Icon name={'video'}
                                                          size={ThemeUtils.responsiveScale(60)}
                                                          color={Color.COLOR_ACCENT}/>

                                                </View>
                                            </TouchableOpacity>
                                        </View>


                                        <View
                                            style={[styles.SubView, orderDetails?.order?.photo_order_video_view?.video_url ? null : {opacity: 0.8}]}>
                                            <Label small font_medium mt={15} mb={15}>
                                                {Strings.fullView}
                                            </Label>
                                            <TouchableOpacity
                                                disabled={!orderDetails?.order?.photo_order_video_view?.video_url}
                                                onPress={() => {
                                                    if (orderDetails?.order?.photo_order_360_view?.view_360_url) {
                                                        openInNewTab(orderDetails?.order?.photo_order_360_view?.view_360_url)
                                                    }
                                                }}>
                                                <View style={styles.SubViewIcon}>
                                                    <Icon name={'360'}
                                                          size={ThemeUtils.responsiveScale(100)}
                                                          color={Color.COLOR_ACCENT}/>
                                                </View>
                                            </TouchableOpacity>
                                        </View>


                                        <View>

                                        </View>

                                    </View>
                                    {this.state.selectionEnable ? (
                                        <View style={styles.propertySelectionCont}>

                                            <Icon name={'tickmark'}
                                                  size={ThemeUtils.fontNormal}
                                                  color={Color.PRIMARY}/>
                                            <Label small
                                                   font_medium
                                                   ml={15}
                                                   style={CommonStyle.flex}>
                                                {`${this.state.selectedImageForDelete.length} ${Strings.photoselected}`}
                                            </Label>


                                            <Mutation mutation={DELETE_PHOTOS}
                                                      refetchQueries={[{
                                                          query: PHOTOS,
                                                          variables: {
                                                              status: 1,
                                                              first: 30,
                                                              page: 1,
                                                              photoId: this.props.property?.order_photo?.id
                                                          }
                                                      }]}>
                                                {(deletePhoto, {loading}) => (
                                                    <>
                                                        <Dialog visible={this.state.dialogVisible}
                                                                transparent
                                                                onNegativePress={this.toggleDeleteDialog}
                                                                onPositivePress={() => {
                                                                    deletePhoto({
                                                                        variables: {
                                                                            ids: this.state.selectedImageForDelete
                                                                        }
                                                                    })
                                                                    this.setState({
                                                                        selectedImages: [],
                                                                        selectionEnable: false

                                                                    }, () => this.toggleDeleteDialog())

                                                                }}
                                                                title={`${Strings.deletePhoto}`}
                                                                buttonTitleFirst={Strings.cancel}
                                                                buttonTitleSecond={Strings.okay}
                                                                color={Color.RED}
                                                                desc={Strings.areYouSureYouWantToDelete}/>
                                                        {deletePhoto.loading ? (
                                                            <ActivityIndicator color={Color.PRIMARY}/>
                                                        ) : this.state.selectedImageForDelete.length ? (
                                                            <TouchableOpacity activeOpacity={0.6}
                                                                              onPress={this.toggleDeleteDialog}>
                                                                <Icon name={'delete'}
                                                                      size={ThemeUtils.fontNormal}
                                                                      color={Color.RED}/>
                                                            </TouchableOpacity>) : null}
                                                    </>
                                                )}

                                            </Mutation>

                                            <TouchableOpacity activeOpacity={0.6}
                                                              style={{marginStart: 20}}
                                                              onPress={this.toggleSelectionMode}>
                                                <Icon name={'close'}
                                                      size={ThemeUtils.fontNormal}
                                                      color={Color.PRIMARY}/>
                                            </TouchableOpacity>
                                        </View>
                                    ) : (<Label small
                                                font_medium
                                                mt={15}
                                                mb={5}>
                                        {Strings.photos}
                                    </Label>)}


                                </View>
                                {this.renderImageGrid(data?.order_photo_list?.data ?? [], true)}
                                {data?.order_photo_list?.data?.length &&
                                data?.order_photo_list?.paginatorInfo?.hasMorePages
                                    ? (<CustomButton title={Strings.loadMore}
                                                     onPress={() => {
                                                         if (!loading &&
                                                             data?.order_photo_list?.paginatorInfo?.hasMorePages) {
                                                             fetchMore({
                                                                 variables: {
                                                                     page: parseInt(data?.order_photo_list?.paginatorInfo?.currentPage, 10) + 1,
                                                                 },
                                                                 updateQuery: (prev, {fetchMoreResult}) => {
                                                                     if (!fetchMoreResult) {
                                                                         return prev;
                                                                     } else {
                                                                         let finalResult = Object.assign({}, fetchMoreResult);
                                                                         finalResult.order_photo_list.data = [...prev.order_photo_list.data, ...fetchMoreResult.order_photo_list.data];
                                                                         return finalResult;
                                                                     }
                                                                 },
                                                             });
                                                         }
                                                     }}
                                                     loading={loading}
                                                     bgColor={Color.WHITE}
                                                     borderWidth={1}
                                                     textColor={Color.PRIMARY}
                                                     borderColor={Color.PRIMARY_LIGHT}
                                                     mb={15}
                                                     ml={ThemeUtils.relativeRealWidth(30)}
                                                     mr={ThemeUtils.relativeRealWidth(30)}/>) : null}
                            </View>
                        )
                    }}
                </Query>
            </View>

        );
    };

    toggleDeleteDialog = (id) => {

        this.setState({
            dialogVisible: !this.state.dialogVisible,
            idForDelete: [id]
        })
    }


    renderImageGrid = (data, isDeletable) => {
        return <View style={styles.ImageGridView}>
            {data.map(obj => {
                return (
                    <TouchableOpacity
                        onLongPress={() => !this.state.selectionEnable ? this.toggleSelectionMode(obj.id, obj.user_id) : null}
                        onPress={() => this.state.selectionEnable ? obj.user_id == this.props.user?.id ? this.addOrRemoveImage(obj.id) : null : this.toggleImageDetailsModal(obj)}
                        activeOpacity={0.7}>

                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${obj.photo_url}`}}
                               style={styles.GridImage}/>
                        {this.state.selectionEnable && obj.user_id == this.props.user?.id ?
                            (<View
                                style={[styles.GridImage, styles.icCheck, this.state.selectedImageForDelete.includes(obj.id) ? {backgroundColor: Color.PRIMARY_TRANSPARENT} : null]}>
                                <Icon name={'tickmark'}
                                      size={ThemeUtils.fontXLarge}
                                      color={this.state.selectedImageForDelete.includes(obj.id) ? Color.COLOR_ACCENT : Color.PRIMARY_LIGHTER}/>
                            </View>) : null}
                    </TouchableOpacity>

                )
            })}
        </View>
    }

    render() {
        return (
            <View style={CommonStyle.container}>
                <Header navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedTitle={Strings.photo}
                        animatedValue={this.scrollY}/>


                <Animated.ScrollView scrollEventThrottle={1}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <Query query={PHOTOGRAPHER_ORDER_DETAILS}
                           fetchPolicy={'cache-and-network'}
                           variables={{
                               id: this.props.property?.order_photo?.id
                           }}>
                        {({loading, data, error}) => {
                            console.log(data, error, loading, "DATA-=-==-=")
                            return (
                                <View style={styles.container}>
                                    <View style={styles.titleCont}>
                                        <Icon name={'photo'} size={ThemeUtils.fontXXLarge}/>
                                    </View>
                                    {this.renderUploadPhotos(data?.photo_order)}
                                </View>
                            )
                        }}
                    </Query>

                </Animated.ScrollView>


            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Photo);
