import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const styles = StyleSheet.create({
    cont: {
        flex: 1,
        justifyContent: 'center'
    },
    container: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: Color.PRIMARY_LIGHT,
        flex: 1,
    },
    bottomViewContainer: {
        maxHeight: ThemeUtils.relativeHeight(50),
        backgroundColor: Color.WHITE,
        width: '100%',
        position: 'absolute',
        bottom: 0,
        alignSelf: 'center',
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 15,
        paddingHorizontal: ThemeUtils.relativeWidth(6),

        shadowColor: Color.BLACK,
        backgroundColor: Color.WHITE,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    selectedItem: {
        backgroundColor: Color.DARK_GRAY,
    },
    itemCont: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: ThemeUtils.relativeRealWidth(6),
        paddingVertical: 10,
    },
    tagSold: {
        backgroundColor: Color.PRIMARY_BG_COLOR,
        borderRadius: 4,
        paddingHorizontal: ThemeUtils.relativeRealWidth(3),
        paddingVertical: ThemeUtils.relativeRealWidth(2)
    }
});
export default styles;
