import gql from 'graphql-tag';

export const SELLER_FAQ = gql`
 query faq($status: Int!) {
  faq(status: $status) {
    question
    answer
  }
}`;

export const SELLER_INSTRUCTIONAL = gql`
query instructional($status: Int!) {
  instructional(status: $status) {
    title
    video_url
    thumbnail
    description
    created_at
    updated_at
  }
}`;

export const SELLER_DISCLAIMER = gql`
query disclaimer($status: Int!) {
  disclaimer(status: $status) {
    content
  }
}`;

export const SELLER_REWARDS = gql`
query reward($isBuyerSeller: String!, $status: Int!) {
  reward(is_buyer_seller: $isBuyerSeller, status: $status) {
    content
    is_buyer_seller
  }
}`;

export const SELLER_OWNERSHIP = gql`
  {
    ownerships(status: 1) {
      id
      name
      description
      image
      icon
    }
  }
`;

export const SELLER_REMUNERATIONS = gql`
  {
    remunerations(status: 1) {
      id
      name
      description
      price
      image
      icon
      cost_type
      features {
        id
        remuneration_id
        name
        icon
      }
    }
  }
`;

export const SELLER_STYLING_TYPES = gql`
  {
    styling_types(status: 1) {
      id
      name
    }
  }
`;

export const SELLER_HEAT_TYPE = gql`
  {
    heat_types(status: 1) {
      id
      name
    }
  }
`;

export const SELLER_PHOTO_SERVICES = gql`
query photo_type_services($propertyId: Int!, $status: Int!) {
  photo_type_services(status: $status, property_id: $propertyId) {
    id
    name
    purchased_office_template {
      id
      office_id
      service_type_id
      headline
      description
      purchased_office_services {
        id
        name
        image
        description
        price
        is_free
        free_text
        external_url
        service_selected {
          id
          price
          quantity
        }
      }
      office_service_media {
        media_url
        media_new_url
      }
      agent_service_media {
        media_url
        media_new_url
      }
    }
  }
}`;


export const SELLER_GET_SERVICES = gql`
query purchased_services(
  $status: Int!
  $property_id: Int!
  $service_category: ServiceCatrgory
) {
  purchased_services(
    status: $status
    service_category: $service_category
    property_id: $property_id
  ) {
    service_types {
      id
      name
      vendor_services {
        id
        vendor_id
        service_type_id
        name
        description
        free_text
        price
        is_free
        service_selected {
          id
          property_id
          usable_service_id
          usable_service_class
          price
          quantity
        }
      }
    }
    remunerations {
      id
      property_id
      name
      description
      price
      usableServiceClass {
        id
        price
        quantity
      }
      cost_type
      hours
    }
    property_utlegg {
      id
      property_id
      name
      price
      type
      status
      is_free
      free_text
      is_default
      price_type
      usableServiceClass {
        id
        price
        property_id
      }
    }
    purchased_services {
      id
      name
      purchased_office_template {
        id
        office_id
        service_type_id
        headline
        description
        purchased_office_services {
          id
          name
          image
          description
          price
          is_free
          free_text
          external_url
          service_selected {
            id
            price
            quantity
          }
        }
        office_service_media {
          id
          media_url
          media_new_url
        }
        agent_service_media {
          media_url
          media_new_url
        }
      }
    }
  }
}`;

export const PROPERTY_PREFERENCE = gql`
query property($id: Int!) {
  property(id: $id) {
    id
    property_preference {
      id
      preferable_price
      percentage
      fix_price
      rebate_amount
    }
  }
}
`

export const SELLER_PROPERTY_LIST = gql`
query properties{
  seller_property_list {
    id
    seller_id
    estate_id
    oppdrags_nummer
    street_adress
    city
    zipcode
    property_status
    buyerContractDoc{
      id
    }
    property_buyer_info {
      id
    }
    settlementInfo{
      id
    }
    seller {
      id
      user_type_id
      first_name
      last_name
      email
      title
      address
      mobile
      profile_image
      city
      zipcode
    }
    orderstyling {
      id
      property_id
      styling_type_id
      styling_type {
        id
        name
      }
      order_date
      earliest_start_time
      latest_start_time
      comment
      status
      order_status
    }
    order_rate_detail {
      id
      property_id
      order_rate_id
      tax_date
      construction_year
      value_rate
      bta
      loan_rate
      bra
      p_rom
      comments{
        id
        comment
      }
      document{
        doc_url
      }
    }
    orderrate {
      id
      property_id
      takst_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      order_status
    }
    order_photo {
      id
      property_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      order_status
      status
    }
    order_inspection {
      id
      property_id
      order_date
      earliest_start_time
      earliest_start_time
      latest_start_time
      comment
      status
      order_status
    }
    energy_labeling {
      id
      property_id
      document_id
      letter_code
      color_code
      document {
        id
        property_id
        title
        doc_url
        doc_type
        is_buyer_seller_doc
        status
      }
      HouseHeatType {
        id
        name
      }
    }
  }
}`;

export const SELLER_FINN_DETAILS = gql`
query property_finn_ad_details($propertyId: Int!) {
  property_finn_ad_details(property_id: $propertyId) {
    id
    property_id
    url
    is_approved
    approved_date
    finn_comments {
      id
      property_finn_ad_id
      comment
      created_at
      updated_at
    }
  }
}

`;


export const SELLER_DIGITAL_ASSIGNMENT = gql`
query property_digital_sales_assignment($propertyId: Int!) {
  property_digital_sales_assignment(property_id:$propertyId) {
    id
    property_id
    url
    is_approved
    approved_date
    digital_sales_assi_comment {
      id
      digital_sales_assi_id
      comment
      created_at
      updated_at
    }
  }
}
`;


export const SELLER_MARKETING_MATERIAL = gql`
query property_marketing_materials($id: Int!) {
  property_marketing_materials(property_id: $id) {
    id
    document_id
    is_approved
    approved_date
    materialComment{
     id
     marketing_materials_id
     comment
    }
    document {
      id
      property_id
      title
      doc_url
      doc_type
      is_buyer_seller_doc
      status
      property {
        id
        seller_id
        oppdrags_nummer
        seller {
          id
          
          first_name
          last_name
          email
          address
          
          mobile
          profile_image
          city
          zipcode
          status
          access_level
        }
      }
    }
  }
}
`;

export const SELLER_GET_PROPERTY_LIST = gql`
query propertyListing($input: propertyListingInput) {
  propertyListing(input: $input) {
    propertiesData {
      total_properties
      owner_properties
      property_data
      owner_number
    }
    assigned_agent {
      teamId
    }
  }
}
`;
export const SELLER_RATE_DETAILS = gql`
query propertyRateDetails($propertyId: Int!) {
  property_rate_detail(property_id: $propertyId) {
    id
    property_id
    order_rate_id
    tax_date
    construction_year
    value_rate
    bta
    loan_rate
    bra
    p_rom
    document {
      id
      property_id
    }
    comments {
      id
      comment
    }
  }
}`;

export const SELLER_PROPERTY_DISPLAY = gql`
query proertyDisplay($propertyId: Int!) {
  property_display(property_id: $propertyId) {
    id
    property_id
    display_date
    earliest_start_time
    latest_start_time
    comment
    is_broker_on_view
    created_at
  }
}`;

export const SELLER_PROPERTY_DISPLAY_VITEC = gql`
query vitecProperty($propertyId: Int!) {
  vitec_property_display(property_id: $propertyId) {
    id
    vitec_prop_id
    startDate
    showingId
    showingnote
  }
}`;

export const SELLER_ADDITIONAL_SERVICES = gql`
query additionalServices($propertyId: Int!) {
  additional_services(property_id: $propertyId) {
    id
    name
    description
    price
    image
    icon
    is_use
    is_free
    free_text
    external_url
    service_type
  }
}`;
export const SELLER_SALES_INFO = gql`
query salesInfo($propertyId: Int!) {
  property_sales_info(property_id: $propertyId) {
    id
    property_id
    first_name
    last_name
    email
    address
    city
    zipcode
    property {
      street_adress
      city
      zipcode
      vitecProperty {
        takeOverDate
        vitecPropertyEstatePrice {
          priceSuggestion
        }
      }
    }
    coBuyerInfo {
      id
      prop_buyer_info_id
      first_name
      last_name
      email
      address
      city
      zipcode
      phone
    }
  }
}
`;

export const SELLER_FINANCE_TYPE = gql`
query financeType($status:Boolean!) {
  financing_type(status:$status){
    id
    name
    status
  }
}`


export const SELLER_SELECTED_FINANCE = gql`
query sellerFinanceDetail($propertyId: Int!) {
  seller_financing_details(property_id: $propertyId) {
    id
    user_id
    property_id
    user_type
    name
    phone_no
    email
    is_agree
    status
    financing_types {
      id
      name
      status
    }
  }
}`;


export const SELLER_SERVICES = gql`
query services($status: Int!, $serviceType: String!) {
  services(status: $status, service_type: $serviceType) {
    id
    name
    description
    price
    image
    icon
    is_use
    is_free
    free_text
    external_url
    service_type
  }
}`;

export const SELLER_TIMELINE = gql`
query propertyTimeline($propertyId: ID!, $status: Int) {
  seller_timeline(property_id: $propertyId, status: $status) {
    id
    property_id
    timeline_id
    is_completed
    completed_date
    completed_time
    end_date
    timeline {
      id
      name
      description
      icon
    }
  }
}`;

export const SELLER_INTERVIEW = gql`
query sellerInterview($propertyId: Int!, $status: Int!) {
  moovin_seller_interviews(property_id: $propertyId, status: $status) {
    id
    seller_id
    property_id
    answer_json
    status
  }
}`;

export const SELLER_MOVING_GUIDE = gql`
query seller_moving_guide_lists($status: Int!, $propertyId: Int!) {
  seller_moving_guide_lists(status: $status, property_id: $propertyId) {
    id
    name
    description
    action
    is_service
    sellerMovingGuide {
      property_id
      seller_id
      moving_guide_id
      is_completed
      completed_date
    }
    movingGuideLink {
      id
      moving_guide_id
      title
      link_url
      status
    }
  }
}`;

export const SELLER_BID_QUERY = gql`
query propertyBids($propertyId: Int!) {
  property_bids(property_id: $propertyId) {
    bids {
      bidId
      type
      time
      amount
      partyid
      expires
      reservations
      accepted
      changedDate
      rejectedDate
    }
    color
  }
}`;


export const SELLER_SETTLEMENT_INFO = gql`
query propertySettlementInfo($propertyId: Int!) {
  property_settlement_information(property_id: $propertyId) {
    id
    property_id
    address
    city
    fra_date
    loan_status
    zipcode
    fra_date
    settlement_loans {
      id
      denomination_kr
      residual_debt_kr
      reminder_date
      bank_name
      bank_contact
      bank_phone
      bank_email
      loan_no
      is_repaid
    }
    settlement_receivable {
      id
      receivables_account_no
      percentage
    }
  }
}`

export const SELLER_DOCUMENT = gql`
query sellerPropertyDocument($id: ID!) {
  seller_document(id: $id) {
     documentId
     documentUrl
     head
     extension
     docType
     lastChanged
     signStatus
  }
}`

export const ADS = gql`
query ads_popup_details($status:Int!,$place:AdsType){
  ads_popup_details(status:$status,place:$place){
    id
    place
    ads_url
    redirection_url
    status
  }
}`


export const SELLER_CALENDAR_EVENT = gql`
query sellerCalendarEvent {
  seller_calendar_event {
    id
    property_id
    created_user_id
    title
    description
    start_datetime
    end_datetime
    event_type
    user_type
    property{
        street_adress
        city
        zipcode
    }
  }
}`;


export const MARKETING_PACKAGES = gql`
query befaringPackages($propertyId:Int!) {
  befaring_packages(property_id:$propertyId) {
    id
    office_id
    name
    price
    description
    image_url
    property_package {
      id
      property_id
      marketing_package_id
      price
    }
    marketing_package_service {
      id
      marketing_package_id
      office_template_id
      office_service_id
      office_service {
        id
        name
        description
        image
        icon
        price
        is_use
        is_free
        free_text
        external_url
      }
    }
  }
}`;


export const SELLER_BROKER_ON_VIEW = gql`
query officeBrokerOnView($propertyId:Int) {
  office_broker_on_view_service (property_id:$propertyId){
    id
    office_id
    name
    description
    image
    price
    service_selected{
      id
      office_broker_view_id
      property_id
      quantity
      price
    }
  }
}`;
