/* eslint-disable import/no-named-as-default */
import {Col, Row, Spin, Tabs, Typography} from 'antd';
import React, {useState} from 'react';
import {formatMessage} from 'components/src/utils';
import {decryptId} from '../../../../../../utils/utils';
import {Query} from 'react-apollo';
import OfficeEditForm from './officeEditForm';
import EditAgentFeedBack from './editagentfeedback';
import Media from './media';
import {useHistory} from "react-router";
import {ADMIN_AGENT_DETAIL} from '../../../../../../../query/AdminQuery';


// import AgentDetailForm from './component/agentdetails/components/agentdetailform';
// eslint-disable-next-line import/no-named-as-default

const {TabPane} = Tabs;
const {Title} = Typography;

// const props = {
//   showUploadList: {
//     showDownloadIcon: true,
//     downloadIcon: <DownloadOutlined />,
//     showRemoveIcon: true,
//     removeIcon: <i className="icon-delete" />,
//   },
//   defaultFileList: [
//     {
//       uid: '1',
//       name: 'tariff.png',
//       status: 'done',
//       response: 'Server Error 500', // custom error message to show
//       url: 'http://www.baidu.com/xxx.png',
//     },
//   ],
// };

export const OrderInformation = props => {
    let router = useHistory();
    const {id} = props.match.params;
    console.log('is id visible=>', decryptId(id));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [orderData, setOrderData] = useState('');
    return (
        <>
            <Query
                query={ADMIN_AGENT_DETAIL}
                variables={{
                    id: decryptId(id),
                }}
                fetchPolicy="cache-and-network"
                onCompleted={responseData => {
                    setOrderData(responseData.agent_detail);
                }}
            >
                {({data, error}) => {
                    console.log('error:', error);
                    if (data && data.agent_detail) {
                        // eslint-disable-next-line array-callback-return
                        const agentData = data.agent_detail;
                        console.log('arrayOfObject[0]=>', agentData);
                        return (
                            <div className="tablayout">
                                <Row>
                                    <Col xs={24} lg={24}>
                                        <Tabs defaultActiveKey="1" className="tabView">
                                            <TabPane
                                                tab={
                                                  <Title level={4}>
                                                  {formatMessage({
                                                    id: 'component.officecontroller.editofficecontroller',
                                                  })}
                                                </Title>
                                                }
                                                key="1"
                                            >
                                                <div className="contentshadow" style={{marginLeft: 0, marginRight: 0}}>
                                                    <div className="text-primary main">
                                                        <OfficeEditForm fullObject={agentData}/>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane
                                                tab={
                                                    <Title level={4}>
                                                        {formatMessage({
                                                            id: 'component.officeceo.feedback',
                                                        })}
                                                    </Title>
                                                }
                                                key="2"
                                            >
                                                <EditAgentFeedBack fullObject={agentData}/>
                                                {/* asdsa */}
                                            </TabPane>
                                            <TabPane
                                                tab={
                                                    <Title level={4}>
                                                        {formatMessage({
                                                            id: 'component.officeceo.media',
                                                        })}
                                                    </Title>
                                                }
                                                key="3"
                                            >
                                                <Media fullObject={agentData}/>
                                            </TabPane>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </div>
                        );
                    }
                    return (
                        <div className="loader-wrapper">
                            <Spin/>
                        </div>
                    );
                }}
            </Query>
        </>
    );
};

export default OrderInformation;
