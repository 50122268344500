import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const style = StyleSheet.create({
    cont: {
        flex: 1,
        marginHorizontal: ThemeUtils.relativeRealWidth(6),
        marginVertical:15
    },
    stepperCont: {
        flexDirection: 'row',
    },
    dotCont: {
        alignItems: 'center',
    },
    dot: {
        height: 12,
        width: 12,
        marginVertical: 6,
        borderRadius: 6,
        marginLeft: 0.5,
        backgroundColor: Color.PRIMARY
    },
    line: {
        flex: 1,
        marginVertical: 10,
        minHeight: 50,
        backgroundColor: Color.PRIMARY_LIGHTER,
        width: 1
    },
    lblCont: {
        flex: 1,
        marginHorizontal: 15,
    },
    stepperTitleCont: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    lblCompleted: {
        alignSelf: 'flex-start',
        backgroundColor: Color.PRIMARY_BG_COLOR,
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: 4,
        borderRadius: 4,
        marginTop: 15,
    },
    icCont:{
        marginTop: 20,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    btnCont:{
    }
});

export default style;
