import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        justifyContent: 'center',
        overflow: 'hidden',
        marginVertical: 5,
        // marginHorizontal: ThemeUtils.relativeWidth(3),
        borderRadius: 4,
    },
    titleCont: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    contentCont: {
        borderRadius: 4,
        backgroundColor: Color.PRIMARY_DARK,
        marginVertical: ThemeUtils.relativeHeight(1),
        paddingHorizontal: ThemeUtils.relativeWidth(4),

    },
    elevation: {
        shadowColor: Color.BLACK,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    accordionCont: {
        borderRadius: 4,
        backgroundColor: Color.GRAY,
        // marginHorizontal: ThemeUtils.relativeWidth(3),
        paddingHorizontal: ThemeUtils.relativeWidth(3),
        paddingVertical: ThemeUtils.relativeHeight(1.5)
    }
})
