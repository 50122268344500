import gql from 'graphql-tag';

export const BUYER_UPDATE_PROFILE = gql`
mutation updateBuyerProfile($input: UpdateBuyerProfileInput) {
  update_buyer_profile(input: $input) {
    user {
      id
      user_type_id
      first_name
      last_name
      email
      title
      address
      mobile
      profile_image
      city
      zipcode
      status
      access_level
    }
  }
}`;

export const BUYER_ADD_FINANCING = gql`
mutation addFinancing($input:financingInput) {
  buyer_add_financing(input:$input) {
    meta {
      status
      message
      code
    }
  }
}`;

export const BUYER_UPDATE_BANK_INFO = gql`
mutation addBuyerBankInfo($input:updateBankInfoInput){
  update_buyer_bank_info(input:$input){
    meta{
      message
      code
    }
  }
}`

export const BUYER_UPDATE_OWNERSHIP_INFO = gql`
mutation updateBuyerOwnershipInfo($input:updateOwnershipInput){
  update_buyer_ownership(input:$input){
    meta{
      message
    }
  }
}`

export const BUYER_UPDATE_HOME_INSURANCE_INFO = gql`
mutation updateHomeInsuranceInfo($input: updateInsuranceInput) {
  update_buyer_insurance(input: $input) {
    meta {
      message
    }
  }
}`

export const BUYER_ADD_INSURANCE = gql`
mutation buyerAddInsurance($input: insuranceInput) {
  buyer_add_insurance(input: $input) {
    meta {
      message
    }
  }
}`

export const BUYER_MOVING_GUIDE_GET_DEALS = gql`
mutation getDeals($id:Int!){
  get_deals_mail(moving_guide_id: $id) {
    meta {
      status
      message
      code
    }
  }
}`;

export const BUYER_MOVING_GUIDE_COMPLETE_STEP = gql`
mutation completeMovingGuide($id: Int!, $propertyId: Int!) {
  complete_moving_guide(moving_guide_id: $id, property_id: $propertyId) {
    meta {
      status
      message
      code
    }
  }
}`;

export const UPDATE_BUYER_TIMELINE = gql`
mutation updateBuyerPropertyTimeline($propertyId: ID!, $status: Int!) {
  update_buyer_timeline(property_id: $propertyId, status: $status) {
    success
  }
}`

export const BUYER_SIGNUP = gql`
mutation buyerSignUp($input: registerBuyerInput) {
  buyer_register(input: $input) {
    meta {
      status
      message
      code
    }
  }
}`

export const BUYER_MOVING_CONTACT_ME=gql`
mutation contactMeNotification($propertyId:Int!){
  contact_me_notification(property_id:$propertyId){
    meta{
      message
    }
  }
}`

export const BUYER_ADD_CONTRACT_COMMENT=gql`
mutation addContractDocComment($id: Int!, $comment: String!, $commentBy: commentBy!) {
  add_contract_doc_comment(id: $id, comment: $comment, comment_by:$commentBy) {
    id
    buyer_contract_doc_id
    comment
    status
    created_at
  }
}`;
