import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'

import {Header, HTMLView} from "components/src/components";
//Third party
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Query} from "@apollo/react-components";
import {SELLER_REWARDS} from "components/src/api/sellerQuery";
//Utils
import {Color, CommonStyle, IS_WEB, Strings} from "components/src/utils";
import {styles} from './styles.js';


class Rewards extends React.Component {
    constructor(props) {
        super(props)
        this.scrollY = new Animated.Value(0)
    }


    render() {
        return (
            <View style={CommonStyle.flex}>
                <Header animatedTitle={Strings.rewards}
                        initialMarginLeft={15}
                        animatedValue={this.scrollY}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={styles.flexGrow}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <Query query={SELLER_REWARDS}
                               variables={{
                                   status: 1,
                                   isBuyerSeller: 'BUYER'
                               }}>
                            {({data, loading, error}) => {
                                if (loading && !data) {
                                    return (
                                        <View>
                                            <ActivityIndicator color={Color.PRIMARY}
                                                               style={styles.loader}/>
                                        </View>)
                                }
                                return (

                                    <View style={styles.cont}>
                                        <HTMLView
                                            value={data?.reward?.content}
                                            stylesheet={styles}
                                        />
                                    </View>
                                )
                            }}
                        </Query>

                    </View>
                </Animated.ScrollView>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
