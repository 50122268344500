import gql from 'graphql-tag';


export const STYLIST_UPDATE_PROFILE = gql`
mutation updateStylistProfile($input: UpdateStylistProfileInput) {
  updatestylistprofile(input:$input){
    user {
      id
      user_type_id
      first_name
      last_name
      email
      title
      address
      mobile
      profile_image
      city
      zipcode
      status
      access_level
  }
  }
}`;

export const STYLIST_UPDATE_ORDER_INFORMATION = gql`
  mutation updatestylist($input: OrderStylishUpdateInput) {
    updatestylist(input: $input) {
      orderstyle {
        id
        property_id
        order_date
        earliest_start_time
        latest_start_time
        comment
        styling_type {
          id
          name
        }
        property {
          id
          oppdrags_nummer
          street_adress
          city
          zipcode
          seller {
            id
            first_name
            last_name
            mobile
            email
          }
        }
      }
    }
  }
`;
