/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { decryptId } from "../../../../../utils/utils";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
    Spin,
  Col,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Typography,
  Upload,
} from "antd";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import React, { useRef } from "react";
import { Mutation, Query } from "react-apollo";
import { Link,withRouter } from "react-router-dom";
import { formatMessage } from "components/src/utils";
import {
  ADMIN_DELETE_PORFESSIONAL,
  ADMIN_UPDATE_FAGLING,
} from '../../../../../../mutation/AdminMutation';
import { ADMIN_FAGLIGS_LIST_DETAILS } from '../../../../../../query/AdminQuery';
import { STORAGE_URL } from '../../../../../utils/constant';

const { Title } = Typography;

let professionalItem;
const docprops = {
  showUploadList: {
    showRemoveIcon: true,
    removeIcon: <i className="icon-delete" />,
  },
  defaultFileList: [],
};
const normFile = e => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

// const fileList = [
//   {
//     uid: '-1',
//     name: 'image.png',
//     status: 'done',
//     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//   },
// ];
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Du kan kun laste opp JPG/PNG filer.');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Bilder må være mindre en 2MB');
  }
  return isJpgOrPng && isLt2M;
}

let id;
let fullObject = [];
class EditProfessionals extends React.PureComponent {
  constructor(props) {
    super(props);
    // eslint-disable-next-line prefer-destructuring
    id = props.match.params.id;

    this.state = {
      editorState: BraftEditor.createEditorState(null),
      loading: false,
      visible: false,
      orderData: '',
      imageObject: '',
      isCoverPhotoChnaged: false,
      professionalVideo: [],
      isImagePresent: false,
    };
    this.formRef = React.createRef();
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.isLivinig = true;
    setTimeout(this.setEditorContentAsync(fullObject), 3000);
  }

  componentWillUnmount() {
    this.isLivinig = false;
  }

  handleChange = editorState => {
    this.setState({
      editorState,
    });
  };

  handleUploadChange = info => {
    getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
          imageObject: info.file.originFileObj,
          isCoverPhotoChnaged: true,
        }),
    );
  };

  setEditorContentAsync = editorData => {
    // eslint-disable-next-line no-unused-expressions
    this.isLivinig &&
    this.setState({
      editorState: BraftEditor.createEditorState(editorData && editorData.description),
    });
    if (this.formRef.current !== null) {
      this.formRef.current.setFieldsValue({
        description: BraftEditor.createEditorState(editorData.description),
      });
    }
  };

  render() {
    const uploadButton = (
        <div>
          {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div className="ant-upload-text">{formatMessage({ id: 'component.form.upload' })}</div>
        </div>
    );
    const {
      editorState,
      imageUrl,
      imageObject,
      orderData,
      professionalVideo,
      isCoverPhotoChnaged,
      isImagePresent,
    } = this.state;
    console.log('link check', professionalVideo);

    const handleVideoUpload = e => {
      const file = [];
      file.push(e.file);
      this.setState({ professionalVideo: file });
    };

    const onFinish = (values, editProfessionalData) => {
      console.log('Success:', values, '\n professionalDesc==>', editorState.toText());
      console.log('TITLE:', values.title);
      // TODO: add the data from the success form response below

      const inputField = {
        id: parseInt(decryptId(id), 10),
        title: values.title,
        description: editorState.toHTML(),
      };

      if (isCoverPhotoChnaged) {
        inputField.cover_image = imageObject;
      }
      if (professionalVideo.length > 0) {
        inputField.video = professionalVideo[0].originFileObj;
      }

      editProfessionalData({
        variables: {
          input: inputField,
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.update_professional) {
          notification.success({
            message: 'Innspillingen ble oppdatert',
            description: '',
            duration: 3,
          });
          this.props.history.push('/admin/faglig');
        }
      });
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const onDelete = (deleteID, deleteFaglig) => {
      console.log('delete id ==>', deleteID);
      deleteFaglig({
        variables: {
          id: parseInt(decryptId(id), 10),
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.delete_professional && data.data.delete_professional.meta) {
          notification.success({
            message: 'Faglig slettet',
            description: '',
            duration: 3,
          });
          this.props.history.push('/admin/faglig');
        }
      });

    };

    return (
        <>
          <Query
              query={ADMIN_FAGLIGS_LIST_DETAILS}
              variables={{
                id: decryptId(id),
                status: 1,
              }}
              fetchPolicy="cache-and-network"
              onCompleted={responseData => {
                const professionalObject = [responseData.professional_detail];


                this.setState({
                  orderData: professionalObject[0],

                  professionalVideo: professionalObject[0].video_url
                      ? [
                        {
                          uid: 1,
                          name: 'Video',
                          media_url: `${professionalObject[0].video_url}`,
                        },
                      ]
                      : [],
                });
                if (professionalObject[0].cover_image_url) {
                  this.setState({
                    isImagePresent: true,
                    imageUrl: `${STORAGE_URL}${professionalObject[0].cover_image_url}`,
                  });
                }
                fullObject = responseData.professional_detail;
                setTimeout(this.setEditorContentAsync(fullObject), 3000);
              }}
          >
            {({ data, loading, error }) => {
              console.log(error);
              if (data && data.professional_detail) {
                return (
                    <Row className="contentshadow">
                      <Col xs={24} lg={24}>
                        <Title level={3} className="text-primary">
                          {formatMessage({ id: 'component.professional.editprofessional' })}
                        </Title>
                        <Mutation mutation={ADMIN_UPDATE_FAGLING}>
                          {(editProfessionalData, { loading }) => {
                            console.log('orderData>', orderData.title);
                            return (
                                <Form
                                    ref={this.formRef}
                                    name="editPorfessionalForm"
                                    initialValues={{
                                      remember: true,
                                      title: `${data.professional_detail.title}`,
                                      cover_photo: `${STORAGE_URL}${data.professional_detail.cover_image_url}`,
                                      description: data.professional_detail.description,
                                      cover_video: data.professional_detail.video_url,
                                    }}
                                    onFinish={values => onFinish(values, editProfessionalData)}
                                    onFinishFailed={onFinishFailed}
                                    layout="vertical"
                                    className="user-forms addEditform"
                                >
                                  <Row>
                                    <Col xs={24} md={8} lg={4}>
                                      <Form.Item
                                          name="cover_photo"
                                          className="mb-3"
                                          label={formatMessage({
                                            id: 'component.news.coverphoto',
                                          })}
                                      >
                                        {isImagePresent ? (
                                            <Row>
                                              <Col xs={24}>
                                                <div className="user-profile-box">
                                                  <img
                                                      src={imageUrl}
                                                      alt="John Doe"
                                                      className="profile-picture"
                                                  />
                                                </div>
                                              </Col>
                                              <Col xs={24} className="mt-3">
                                                <Upload
                                                    showUploadList={false}
                                                    onChange={this.handleUploadChange}
                                                    accept={['.jpg', '.jpeg', '.png', '.svg']}
                                                >
                                                  <Button>
                                                    <UploadOutlined />{' '}
                                                    {formatMessage({
                                                      id: 'component.form.upload',
                                                    })}
                                                  </Button>
                                                </Upload>
                                              </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                              <Col xs={24}>
                                                <Upload
                                                    name="avatar"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    // beforeUpload={beforeUpload}
                                                    onChange={this.handleUploadChange}
                                                    accept={['.jpg', '.jpeg', '.png', '.svg']}
                                                >
                                                  {imageUrl ? (
                                                      <img
                                                          src={imageUrl}
                                                          alt="avatar"
                                                          style={{ width: '100%' }}
                                                      />
                                                  ) : (
                                                      uploadButton
                                                  )}
                                                </Upload>
                                              </Col>
                                            </Row>
                                        )}
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Form.Item
                                      label={formatMessage({
                                        id: 'component.professional.professionaltitle',
                                      })}
                                      name="title"
                                      className="mt-3"
                                      rules={[
                                        {
                                          required: true,
                                          message: `Vennligst skriv inn ${formatMessage({
                                            id: 'component.professional.professionaltitle',
                                          }).toLowerCase()}!`,
                                        },
                                      ]}
                                  >
                                    <Input
                                        placeholder={formatMessage({
                                          id: 'component.professional.professionaltitle',
                                        })}
                                        size="large"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                      label={formatMessage({
                                        id: 'component.professional.professionaldesc',
                                      })}
                                      rules={[
                                        {
                                          required: true,
                                          message: `Vennligst skriv inn ${formatMessage({
                                            id: 'component.professional.professionaldesc',
                                          })}!`,
                                        },
                                      ]}
                                      name="description"
                                  >
                                    <BraftEditor
                                        className="editor-wrapper"
                                        language="en"
                                        value={editorState}
                                        onChange={this.handleChange}
                                        contentStyle={{ height: 210 }}
                                        placeholder={formatMessage({
                                          id: 'component.professional.professionaldesc',
                                        })}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                      name="cover_video"
                                      label={formatMessage({ id: 'component.news.uploadvideo' })}
                                      // valuePropName="fileList"
                                      getValueFromEvent={normFile}
                                  >
                                    <Upload
                                        showUploadList={false}
                                        name="logo"
                                        action="/upload.do"
                                        // {...docprops}
                                        onChange={e => handleVideoUpload(e)}
                                        listType="picture"
                                        accept={['.mp4']}
                                    >
                                      <Button>
                                        <UploadOutlined />{' '}
                                        {formatMessage({ id: 'component.form.upload' })}
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                  {professionalVideo.length !== 0 && (
                                      <Row gutter={32}>
                                        <Col xs={24} lg={16}>
                                          {professionalVideo.map(item => (
                                              <div className="default_list mt-3 mb-3">
                                                <div>
                                                  <i className="icon-attachment" />
                                                  <span className="ml-2">
                                          {item.originFileObj ? (
                                              <span>{item.name}</span>
                                          ) : (
                                              <a
                                                  href={`${STORAGE_URL}${item.media_url}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                              >
                                                {item.name}
                                              </a>
                                          )}
                                        </span>
                                                </div>
                                                <div className="upload-media-delete">
                                                  <i
                                                      className="icon-delete"
                                                      onClick={() => this.setState({ professionalVideo: [] })}
                                                  />
                                                </div>
                                              </div>
                                          ))}
                                        </Col>
                                      </Row>
                                  )}
                                  <Button
                                      type="primary"
                                      size="large"
                                      htmlType="submit"
                                      loading={loading}
                                      className="btn-secondary buttons admin-forms-btn mt-3"
                                  >
                                    {formatMessage({
                                      id: 'component.news.publish',
                                    })}
                                  </Button>
                                  <Link to="/admin/faglig">
                                    <Button
                                        size="large"
                                        className="buttons admin-forms-btn back-button mt-3"
                                    >
                                      {formatMessage({
                                        id: 'component.form.cancel',
                                      })}
                                    </Button>
                                  </Link>
                                  <Button
                                      size="large"
                                      className="buttons admin-forms-btn delete-button mt-3 pull-right"
                                      onClick={this.showModal}
                                      loading={loading}
                                  >
                                    {formatMessage({
                                      id: 'component.form.delete',
                                    })}
                                  </Button>
                                  <Modal
                                      title={formatMessage({ id: 'component.news.deletenews' })}
                                      visible={this.state.visible}
                                      onOk={this.handleOk}
                                      onCancel={this.handleCancel}
                                      footer={[
                                        <Mutation mutation={ADMIN_DELETE_PORFESSIONAL}>
                                          {deleteFaglig => (
                                              <Button
                                                  type="primary"
                                                  className="btn-secondary admin-forms-btn buttons"
                                                  size="large"
                                                  onClick={() => onDelete(orderData.id, deleteFaglig)}
                                              >
                                                {formatMessage({ id: 'component.form.yes' })}
                                              </Button>
                                          )}
                                        </Mutation>,
                                        <Button
                                            className="admin-forms-btn buttons"
                                            size="large"
                                            onClick={this.handleOk}
                                        >
                                          {formatMessage({ id: 'component.form.no' })}
                                        </Button>,
                                      ]}
                                  >
                                    <p className="mb-0">
                                      {formatMessage({
                                        id: 'component.professional.deleteprofessionaldesc',
                                      })}
                                    </p>
                                  </Modal>
                                </Form>
                            );
                          }}
                        </Mutation>
                      </Col>
                    </Row>
                );
              }
              if (loading && !error) {
                return (
                    <div className="loader-wrapper">
                      <Spin />
                    </div>
                );
              }
              if (!loading && error) {
                return <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>;
              }
              return <React.Fragment />;
            }}
          </Query>
        </>
    );
  }
}
export default withRouter(EditProfessionals);

// /* eslint-disable no-shadow */
// import {
//   Col,
//   Row,
//   Typography,
//   Button,
//   Form,
//   Input,
//   Upload,
//   message,
//   Modal,
//   notification,
// } from 'antd';
// import { PlusOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
// import 'braft-editor/dist/index.css';
// import React from 'react';
// import BraftEditor from 'braft-editor';
// import { formatMessage } from "components/src/utils";
// import { Link, router } from 'umi';
// import { decryptId } from '@/utils/utils';
// import { Mutation, Query } from 'react-apollo';
// import { STORAGE_URL } from '../../../../../utils/constant';
// import { ADMIN_UPDATE_FAGLING } from '../../../../../../mutation/AdminMutation';
// import { ADMIN_FAGLIGS_LIST } from '../../../../../../query/AdminQuery';
// // import profile from '../../../../../assets/sales_preparation/book_inspection.png';
// // import professionalDetail from '@/pages/admin/professional-detail';

// const { Title } = Typography;
// let id;
// const normFile = e => {
//   console.log('Upload event:', e);
//   if (Array.isArray(e)) {
//     return e;
//   }
//   return e && e.fileList;
// };

// const docprops = {
//   showUploadList: {
//     showRemoveIcon: true,
//     removeIcon: <i className="icon-delete" />,
//   },
//   defaultFileList: [],
// };

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// function beforeUpload(file) {
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//   if (!isJpgOrPng) {
//     message.error('Du kan kun laste opp JPG/PNG filer.');
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error('Bilder må være mindre en 2MB');
//   }
//   return isJpgOrPng && isLt2M;
// }

// let professionalItem;
// let professionalsObject;

// class EditProfessional extends React.Component {
//   // eslint-disable-next-line no-useless-constructor
//   constructor(props) {
//     super(props);
//     // eslint-disable-next-line prefer-destructuring
//     id = props.match.params.id;
//     professionalItem = props.location.state.orderItem;
//   }

//   // docprops.defaultFileList.push = `${STORAGE_URL}/${professionalItem.second_video_image_url}`;
//   // this.setState={
//   //   editorState:professionalItem.description
//   // }
//   // console.log('editor this state==>',this.state.editorState)

//   state = {
//     editorState: BraftEditor.createEditorState(),
//     // editorState: BraftEditor.createEditorState(professionalItem.description),
//     loading: false,
//     visible: false,
//     orderData: '',
//     // imageUrl: `${STORAGE_URL}${professionalItem.cover_image_url}`,
//     imageObject: '',
//   };

//   showModal = () => {
//     this.setState({
//       visible: true,
//     });
//   };

//   handleOk = e => {
//     console.log(e);
//     this.setState({
//       visible: false,
//     });
//   };

//   handleCancel = e => {
//     console.log(e);
//     this.setState({
//       visible: false,
//     });
//   };

//   componentDidMount() {
//     this.isLivinig = true;
//     setTimeout(this.setEditorContentAsync, 3000);
//   }

//   componentWillUnmount() {
//     this.isLivinig = false;
//   }

//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   handleChange = (editorState, info) => {
//     this.setState = {
//       editorState,
//     };
//     // if (info.file.status === 'uploading') {
//     //   this.setState({ loading: true });
//     //   return;
//     // }
//     // if (info.file.status === 'done') {
//     //   // Get this url from response in real world.
//     //   getBase64(info.file.originFileObj, imageUrl =>
//     //     this.setState({
//     //       imageUrl,
//     //       loading: false,
//     //     }),
//     //   );
//     // }
//   };

//   //   handleChange = info => {
//   //   // Uploadprops.defaultFileList=[]
//   //   // console.log("info===>",info.file.originFileObj)
//   //   if (info.file.status === 'done') {

//   //     getBase64(info.file.originFileObj, image =>
//   //       this.setState({ imageUrl: image, imageObject: info.file.originFileObj }),
//   //     );
//   //     // setimageObject(info.file.originFileObj);
//   //   }
//   // };

//   handleUploadChange = info => {
//     if (info.file.status === 'uploading') {
//       this.setState({ loading: true });
//       return;
//     }
//     if (info.file.status === 'done') {
//       // Get this url from response in real world.
//       getBase64(info.file.originFileObj, imageUrl =>
//         this.setState({
//           imageUrl,
//           loading: false,
//           imageObject: info.file.originFileObj,
//         }),
//       );
//     }
//   };

//   setEditorContentAsync = () => {
//     // eslint-disable-next-line no-unused-expressions
//     this.isLivinig &&
//       this.setState({
//         // this.setState = {
//         // editorState: BraftEditor.createEditorState(),
//         editorState: BraftEditor.createEditorState(professionalItem.description),
//       });
//   };

//   render() {
//     // eslint-disable-next-line @typescript-eslint/no-unused-vars
//     const uploadButton = (
//       <div>
//         {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
//         <div className="ant-upload-text">{formatMessage({ id: 'component.news.uploadvideo' })}</div>
//       </div>
//     );

//     // eslint-disable-next-line @typescript-eslint/no-unused-vars
//     const { editorState, imageUrl, imageObject, orderData } = this.state;
//     console.log('editor=====>', editorState);

//     const onFinish = (values, editProfessionalData) => {
//       console.log('Success:', values, '\n newsDesc==>', editorState.toText());
//       // TODO: add the data from the success form response below
//       editProfessionalData({
//         variables: {
//           id: decryptId(id),
//           cover_image: imageObject,
//           title: values.professionaltitle,
//           description: editorState.toText(),
//           video: values.cover_video.fileList[0].originFileObj,
//         },
//       }).then(data => {
//         console.log('Data submitted===>>>', data);
//         if (data && data.update_fegling && data.update_fegling.meta) {
//           notification.success({
//             message: data.update_fegling.meta.message,
//             description: '',
//             duration: 3,
//           });
//           router.push(`/admin/profesjonell`);
//         }
//       });
//     };

//     const onFinishFailed = errorInfo => {
//       console.log('Failed:', errorInfo);
//     };

//     return (
//       <>
//         <Query
//           query={ADMIN_FAGLIGS_LIST}
//           variables={{
//             first: 100,
//             page: 1,
//             status: true,
//           }}
//           onCompleted={responseData => {
//             professionalsObject = responseData.professionals.data.filter(
//               i => i.id === parseInt(decryptId(id), 10),
//             );
//             this.setState({
//               orderData: professionalsObject,
//               imageUrl: `${STORAGE_URL}${professionalsObject[0].cover_image_url}`,
//             });
//           }}
//         >
//           {({ data }) => {
//             if (data && data.professionals.data) {
//               // console.log('user-list', data.users_list);
//               // eslint-disable-next-line @typescript-eslint/no-unused-vars
//               const { orderData } = this.state;
//               return (
//                 <Row className="contentshadow">
//                   <Col xs={24} lg={24}>
//                     <Title level={3} className="text-primary">
//                       {formatMessage({ id: 'component.professional.editprofessional' })}
//                     </Title>
//                     <p className="text-primary">
//                       Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
//                       eirmod tempor invidunt ut labore et dolore magna.
//                     </p>
//                     <Mutation mutation={ADMIN_UPDATE_FAGLING}>
//                       {editProfessionalData => {
//                         return (
//                           <Form
//                             name="editprofessional"
//                             initialValues={{
//                               remember: true,
//                               cover_photo: `${STORAGE_URL}${professionalItem.cover_image_url}`,
//                               professionaltitle: professionalItem.title,
//                               professionaldesc: professionalItem.description,
//                               cover_video: professionalItem.video_url,
//                             }}
//                             onFinish={values => onFinish(values, editProfessionalData)}
//                             onFinishFailed={onFinishFailed}
//                             layout="vertical"
//                             className="user-forms addEditform"
//                           >
//                             <Row>
//                               <Col xs={24} md={8} lg={4}>
//                                 <Form.Item
//                                   name="cover_photo"
//                                   className="mb-0"
//                                   label={formatMessage({
//                                     id: 'component.news.coverphoto',
//                                   })}
//                                 >
//                                   <Row>
//                                     <Col xs={24}>
//                                       <div className="user-profile-box">
//                                         <img
//                                           src={imageUrl}
//                                           alt="John Doe"
//                                           // alt={user.first_name + user.last_name}
//                                           className="profile-picture"
//                                         />
//                                       </div>
//                                     </Col>
//                                     <Col xs={24} className="mt-3">
//                                       <Upload
//                                         showUploadList={false}
//                                         onChange={this.handleUploadChange}
//                                       >
//                                         <Button>
//                                           <UploadOutlined /> Upload
//                                         </Button>
//                                       </Upload>
//                                     </Col>
//                                   </Row>
//                                 </Form.Item>
//                               </Col>
//                             </Row>
//                             <Form.Item
//                               label={formatMessage({
//                                 id: 'component.professional.professionaltitle',
//                               })}
//                               name="professionaltitle"
//                             >
//                               <Input
//                                 placeholder={formatMessage({
//                                   id: 'component.professional.professionaltitle',
//                                 })}
//                                 size="large"
//                               />
//                             </Form.Item>
//                             <Form.Item
//                               label={formatMessage({
//                                 id: 'component.professional.professionaldesc',
//                               })}
//                               name="professionaldesc"
//                             >
//                               <div className="editor-wrapper">
//                                 <BraftEditor
//                                   language="en"
//                                   value={editorState}
//                                   onChange={this.handleChange}
//                                   contentStyle={{ height: 210 }}
//                                   placeholder={formatMessage({
//                                     id: 'component.professional.professionaldescplaceholder',
//                                   })}
//                                 />
//                               </div>
//                             </Form.Item>
//                             <Form.Item
//                               name="upload"
//                               label={formatMessage({ id: 'component.news.uploadvideo' })}
//                               valuePropName="fileList"
//                               getValueFromEvent={normFile}
//                             >
//                               <Upload
//                                 name="logo"
//                                 action="/upload.do"
//                                 {...docprops}
//                                 listType="picture"
//                               >
//                                 <Button>
//                                   <UploadOutlined />{' '}
//                                   {formatMessage({ id: 'component.form.upload' })}
//                                 </Button>
//                               </Upload>
//                             </Form.Item>
//                             <Button
//                               type="primary"
//                               size="large"
//                               htmlType="submit"
//                               className="btn-secondary buttons admin-forms-btn mt-3"
//                             >
//                               {formatMessage({
//                                 id: 'component.news.publish',
//                               })}
//                             </Button>
//                             <Link to="/admin/profesjonell">
//                               <Button
//                                 size="large"
//                                 className="buttons admin-forms-btn back-button mt-3"
//                               >
//                                 {formatMessage({
//                                   id: 'component.form.cancel',
//                                 })}
//                               </Button>
//                             </Link>
//                             <Button
//                               size="large"
//                               className="buttons admin-forms-btn delete-button mt-3 pull-right"
//                               onClick={this.showModal}
//                             >
//                               {formatMessage({
//                                 id: 'component.form.delete',
//                               })}
//                             </Button>
//                             <Modal
//                               title={formatMessage({
//                                 id: 'component.professional.deleteprofessional',
//                               })}
//                               visible={this.state.visible}
//                               onOk={this.handleOk}
//                               onCancel={this.handleCancel}
//                               footer={[
//                                 <Button
//                                   type="primary"
//                                   className="btn-secondary admin-forms-btn buttons"
//                                   size="large"
//                                   onClick={this.handleCancel}
//                                 >
//                                   {formatMessage({ id: 'component.form.yes' })}
//                                 </Button>,
//                                 <Button
//                                   className="admin-forms-btn buttons"
//                                   size="large"
//                                   onClick={this.handleOk}
//                                 >
//                                   {formatMessage({ id: 'component.form.no' })}
//                                 </Button>,
//                               ]}
//                             >
//                               <p className="mb-0">
//                                 {formatMessage({
//                                   id: 'component.professional.deleteprofessionaldesc',
//                                 })}
//                               </p>
//                             </Modal>
//                           </Form>
//                         );
//                       }}
//                     </Mutation>
//                   </Col>
//                 </Row>
//               );
//             }
//             return <LoadingOutlined />;
//           }}
//         </Query>
//       </>
//     );
//   }
// }
// export default EditProfessional;
