import React from 'react';
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {Header, Hr, Label, Ripple} from "components/src/components";
//Third Party
import moment from "moment";
//Network
import {useQuery} from "@apollo/react-hooks";
import {SELLER_MARKETING_MATERIAL} from "components/src/api/sellerQuery";
//Utils
import {
    Color,
    CommonStyle,
    DateUtils,
    Icon,
    IS_WEB,
    MessageUtils,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import styles from './styles';
import {connect} from "react-redux";


function MarketingMaterial(props) {

    const scrollY = new Animated.Value(0);
    const {history, navigation} = props;

    const {data, loading, error} = useQuery(SELLER_MARKETING_MATERIAL, {
        fetchPolicy:'network-only',
        variables: {
            id: props.property?.id
        }
    });

    const renderMaterials = (item, index) => {
        return (
            <Ripple
                onPress={() => IS_WEB ? history.push(Routes.MarketingMaterialsDetails, {document: item}) : navigation.navigate(Routes.MarketingMaterialsDetails, {document: item})}>
                <View style={styles.materialCont}>
                    <Icon name={'marketing-material-print'}
                          color={Color.PRIMARY}
                          size={28}/>
                    <View style={styles.lblCont}>
                        <Label font_medium
                               mb={5}>
                            {item.document.title}
                        </Label>
                        <Label small>
                            {item.is_approved ? `${Strings.approvedOn} ${moment.utc(item.approved_date).local().format(DateUtils.hh_mm_dd_mm_yyy)} ` : Strings.notApprovedYet}
                        </Label>
                    </View>
                    <Icon name={'drop-normal'}
                          color={Color.PRIMARY_LIGHTER}
                          size={16}/>
                </View>
                <Hr/>
            </Ripple>
        )
    };

    return (
        <View style={styles.cont}>
            <Header animatedTitle={Strings.marketingMaterials}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.cont}>
                    <Icon name={'marketing-materials'}
                          style={styles.icMaterial}
                          size={ThemeUtils.fontXXLarge}/>

                    <Hr/>
                    {loading && !data ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) :
                        !data?.property_marketing_materials?.length ? (
                            <Label align={'center'}
                                   mt={20}
                                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                   mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                   small
                                   font_medium>
                                {MessageUtils.Message.noMarketingMaterialAvailable}
                            </Label>
                        ) : data?.property_marketing_materials?.map(renderMaterials)}
                </View>
            </Animated.ScrollView>
        </View>
    )
}

const mapStatToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStatToProps)(MarketingMaterial)

