import gql from 'graphql-tag';

export const TAKST_ORDER_LIST = gql`
query rate_order_lists($input: OrderListInput!) {
    rate_order_lists(input: $input) {
      data {
        id
        property_id
        takst_id
        order_date
        earliest_start_time
        latest_start_time
        comment
        order_status
        property {
          id
          seller_id
          oppdrags_nummer
          street_adress
          city
          zipcode
          seller {
            id
            first_name
            last_name
            email
            address
            mobile
            profile_image
            city
            zipcode
            status
          }
          orderrate {
            id
            property_id
            takst_id
            order_date
            earliest_start_time
            latest_start_time
            comment
            order_status
          }
        }
        takst {
          id
          first_name
          last_name
          email
          address
          mobile
          profile_image
          city
          zipcode
          status
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }`;
export const TAKST_ORDER_INFORMATION = gql`
query order_list($id: ID!) {
  order_list(id: $id) {
    orderrate {
      id
      property_id
      takst_id
      order_date
      order_status
      earliest_start_time
      latest_start_time
      comment
      property {
        id
        order_rate_detail {
          id
          property_id
          order_rate_id
          tax_date
          construction_year
          value_rate
          bta
          loan_rate
          bra
          p_rom
          document {
            id
            property_id
          }
          comments {
            id
            comment
          }
        }
        oppdrags_nummer
        street_adress
        city
        zipcode
        seller {
          id
          first_name
          last_name
          email
          mobile
        }
      }
    }
  }
}`;

export const TAKST_CALENDAR_EVENT = gql`
query takstCalendarEvent {
  takst_calendar_event {
    id
    property_id
    created_user_id
    title
    description
    start_datetime
    end_datetime
    event_type
    user_type
    property{
        street_adress
        city
        zipcode
    }
  }
}`;
