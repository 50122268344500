import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    emptyDataCont: {
        alignItems: 'center',
        justifyContent: 'center',
        margin: 40,
        borderRadius: 4,
        flex: 1,
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        borderStyle: 'dotted'
    },
    eventCont: {
        flex: 1,
        justifyContent: 'flex-end',
        marginTop: ThemeUtils.relativeRealHeight(2)
    },
    lblCont: {
        backgroundColor: Color.SECONDARY,
        borderRadius: 4,
        marginEnd: ThemeUtils.relativeRealWidth(2),
        marginBottom: ThemeUtils.relativeRealHeight(1),
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: ThemeUtils.relativeRealHeight(1)
    },
    addNewLblCont: {
        backgroundColor: Color.GRAY,
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        borderStyle: 'dotted'
    },
    modalCont: {
        flex:1,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    chk: {
        marginVertical: 5
    },
    btnCont: {
        flexDirection: 'row',
        marginVertical: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    }
})
