import {Button, Col, Form, Input, notification, Row, Typography, Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import React, {useState} from 'react';
import {formatMessage} from 'components/src/utils';
import {Link, useHistory} from 'react-router-dom';
import {Mutation} from 'react-apollo';
import {ADMIN_CREATE_ADS_POPUPS} from '../../../../../../mutation/AdminMutation';

const { Title } = Typography;
// const { TabPane } = Tabs;

const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: '40px', opacity: 0.3 }} />
      <div className="ant-upload-text label-texts">
        {formatMessage({
          id: 'component.form.upload',
        })}
      </div>
    </div>
);
export const AdsAndPopUpsSales = () => {
  let router= useHistory();
  const [imageUrl, setimageUrl] = useState('');
  const [imageUrl2, setimageUrl2] = useState('');
  const [imageObject1, setimageObject1] = useState();
  const [imageObject2, setimageObject2] = useState();

  function getBase64(img, callback) {
    const reader1 = new FileReader();
    reader1.addEventListener('load', () => callback(reader1.result));
    reader1.readAsDataURL(img);
  }

  function getBase(img1, callback) {
    const reader2 = new FileReader();
    reader2.addEventListener('load', () => callback(reader2.result));
    reader2.readAsDataURL(img1);
  }
  const handleChangeImage1 = info => {
    getBase64(info.file.originFileObj, image => setimageUrl(image));
    setimageObject1(info.file.originFileObj);
  };

  const handleChangeImage2 = info => {
    getBase(info.file.originFileObj, image2 => setimageUrl2(image2));
    setimageObject2(info.file.originFileObj);
  };

  const onFinishSales = (values, addAdsAndPopUpsSales) => {
    console.log('Success:', values);
    console.log('addAdsAndPopUpsSales:', addAdsAndPopUpsSales);

    if (imageObject2 && values?.redirect_address2_sales) {
      addAdsAndPopUpsSales({
        variables: {
          ads_image: [imageObject1, imageObject2],
          redirect_url: [values.redirect_address1_sales, values?.redirect_address2_sales],
          place: 'SALES',
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.create_ads_popup && data.data.create_ads_popup.meta) {
          notification.success({
            message: data.data.create_ads_popup.meta.message,
            description: '',
            duration: 3,
          });
          router.push(`/admin/dashboard`);
        }
      });
    } else {
      addAdsAndPopUpsSales({
        variables: {
          ads_image: [imageObject1],
          redirect_url: [values.redirect_address1_sales],
          place: 'SALES',
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.create_ads_popup && data.data.create_ads_popup.meta) {
          notification.success({
            message: data.data.create_ads_popup.meta.message,
            description: '',
            duration: 3,
          });
          router.push(`/admin/dashboard`);
        }
      });
    }
  };


  const onFinishFailedSales = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
      <Row>
        <Col lg={24}>
          <Mutation mutation={ADMIN_CREATE_ADS_POPUPS}>
            {(addAdsAndPopUpsSales, { loading }) => {
              return (
                  <Form
                      layout="vertical"
                      className="user-forms"
                      name="adsandpopupsSales"
                      initialValues={{
                        remember: true,
                      }}
                      colon={false}
                      // eslint-disable-next-line no-undef
                      onFinish={values => onFinishSales(values, addAdsAndPopUpsSales)}
                      onFinishFailed={onFinishFailedSales}
                  >
                    <Row className="mt-3">
                      <Col lg={24} md={24} xs={24}>
                        <Title level={4} className="text-primary">
                          Bilder 1 :
                        </Title>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={24} md={24} xs={24}>
                        <Form.Item
                            name="image1_sales"
                            label={formatMessage({
                              id: 'component.adsandpopups.uploadimageorgif',
                            })}
                            rules={[
                              {
                                required: true,
                                message: 'Vennligst last opp bilde eller GIF',
                              },
                            ]}
                        >
                          <Upload
                              name="sales_avatar1"
                              accept={['.jpeg', '.jpg', '.png', '.gif']}
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              onChange={handleChangeImage1}
                          >
                            {imageUrl ? (
                                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                                uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={16} md={24} xs={24}>
                        <Form.Item
                            name="redirect_address1_sales"
                            label={formatMessage({
                              id: 'component.adsandpopups.redirectionurl',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst fyll inn ${formatMessage({
                                  id: 'component.adsandpopups.redirectionurl',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              size="large"
                              placeholder={formatMessage({
                                id: 'component.adsandpopups.redirectionurl.placeholder',
                              })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={24} md={24} xs={24}>
                        <Title level={4} className="text-primary">
                          Bilder 2:
                        </Title>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={24} md={24} xs={24}>
                        <Form.Item
                            name="image2_sales"
                            className="label-texts no-margin"
                            label={formatMessage({
                              id: 'component.adsandpopups.uploadimageorgif',
                            })}

                        >
                          <Upload
                              name="sales-avtar2"
                              accept={['.jpeg', '.jpg', '.png', '.gif']}
                              listType="picture-card"
                              className="avatar-uploader sm_center"
                              showUploadList={false}
                              onChange={handleChangeImage2}
                          >
                            {imageUrl2 ? (
                                <img src={imageUrl2} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                                uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={16} md={24} xs={24}>
                        <Form.Item
                            name="redirect_address2_sales"
                            label={formatMessage({
                              id: 'component.adsandpopups.redirectionurl',
                            })}

                        >
                          <Input
                              size="large"
                              placeholder={formatMessage({
                                id: 'component.adsandpopups.redirectionurl.placeholder',
                              })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={24} md={24} xs={24}>
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            className="admin-forms-btn  mr_15"
                            size="large"
                            loading={loading}
                        >
                          {formatMessage({
                            id: 'component.form.save',
                          })}
                        </Button>
                        <Link to="/admin/dashboard">
                          <Button className="admin-forms-btn  mr_15" size="large">
                            {formatMessage({
                              id: 'component.form.cancel',
                            })}
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
              );
            }}
          </Mutation>
        </Col>
      </Row>
  );
};
export default AdsAndPopUpsSales;
