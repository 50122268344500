import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {CustomTextArea, Label, RadioGroup} from "components/src/components";
//Utils
import {Strings,Constants} from "components/src/utils";
import styles from "../styles";
import {setSellerInterViewForm} from "components/src/redux/action";
import {connect} from "react-redux";

const QUESTIONS = [{
    id: 'q1',
    text: Strings.thirdStep.q1
}, {
    id: 'q2',
    text: Strings.thirdStep.q2
},];

const Q3 = Strings.thirdStep.q3;


function ThirdStep(props) {
    const {setForm, interViewForm} = props;

    const [maintenanceDue, setMaintenance] = useState(interViewForm.find(item => item.interview_section === 3 && item.question_no === 9)?.answer ?? 'Ja');

    const [answers, setAnswer] = useState({
        q1: interViewForm.find(item => item.interview_section === 3 && item.question_no === 9)?.comment ?? '',
        q2: interViewForm.find(item => item.interview_section === 3 && item.question_no === 10)?.answer ?? '',
    });

    useEffect(() => {

        let answer = [
            {
                "question":QUESTIONS[0].text,
                "comment_questions":QUESTIONS[1].text,
                "interview_section": 3,
                "question_no": 9,
                "type": "RADIO",
                "answer": maintenanceDue,
                "extra": null,
                "comment": answers?.q1
            }, {
                "question":Q3,
                "interview_section": 3,
                "question_no": 10,
                "type": "TEXT",
                "answer": answers?.q2 ?? '',
                "extra": null,
                "comment": null
            },];
        setForm([...interViewForm.filter(item => item.interview_section !== 3), ...answer])
    }, [answers,maintenanceDue]);

    const handleChangeText = (text, id) => {
        let updatedAnswer = {...answers};
        updatedAnswer[id] = text;
        setAnswer(updatedAnswer)
    };

    const renderQuestions = (item, index) => {
        if (index === 0 && maintenanceDue !== 'Ja') {
            return;
        }
        return (<CustomTextArea labelText={item.text}
                                mt={10}
                                placeholder={Strings.writeYourComments}
                                value={answers[item.id]}
                                onChange={(text) => handleChangeText(text, item.id)}/>)
    };

    return (
        <View>
            <Label small
                   mt={10}
                   font_medium
                   mb={10}>
                {Q3}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS}
                        style={styles.radio}
                        contentStyle={styles.radioCont}
                        selectedOptions={maintenanceDue}
                        onSelectOption={setMaintenance}/>
            {QUESTIONS.map(renderQuestions)}
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form))
    }

};
export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep)
