import React from 'react'
//Components
import {ActivityIndicator, Animated, Image, View} from 'react-native'
import {Header, Hr, Label, Ripple} from "components/src/components";
//Third party
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Query} from "@apollo/react-components";
import {SELLER_INSTRUCTIONAL} from "components/src/api/sellerQuery";
//Utils
import {Color, CommonStyle, Constants, Icon, IS_WEB, openInNewTab, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';


// import {Button, InputItem} from "@ant-design/react-native";
// import { PickerView } from '@ant-design/react-native';
// import { DatePickerView } from 'antd-mobile';


class Instructional extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            array: [
                {
                    index: 1,
                    title: "instructional Video 1",
                },
                {
                    index: 2,
                    title: "instructional Video 2",
                },
                {
                    index: 3,
                    title: "instructional Video 3",
                },
                {
                    index: 4,
                    title: "instructional Video 4",
                },
                {
                    index: 5,
                    title: "instructional Video 5",
                },

            ]

        };
        this.scrollY = new Animated.Value(0)
    }


    list = (data) => {

        return data?.map((item, index) => {
            return (
                <Ripple onPress={() => {
                    openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item.video_url}`)
                }}>
                    <Hr/>

                    <View style={styles.instructionalContainer}>

                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.thumbnail}`}}
                               style={styles.imgThumb}/>
                        <Label small
                               ml={9}
                               font_medium
                               bolder>{item.title}
                        </Label>
                        <View style={styles.iconView}>
                            <Icon
                                size={ThemeUtils.fontLarge}
                                name={'drop-normal'}
                                color={Color.ICON_BUTTON_ARROW}/>

                        </View>
                    </View>
                    {index === data.length - 1 ? <View style={styles.deviderline}/> : null}
                </Ripple>
            )
        });
    };


    render() {
        return (
            <View style={CommonStyle.container}>

                <Header animatedTitle={Strings.instructional}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.labeliconContainer}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'instructional'}
                                    color={Color.PRIMARY_DARK}/>

                            </View>

                            <Label
                                mt={15}
                                mb={20}
                                small
                                font_regular
                                align={'left'}>
                                {Strings.InstructionalContent}
                            </Label>


                        </View>
                        <View>
                            <Query query={SELLER_INSTRUCTIONAL}
                                   variables={{
                                       status: 1
                                   }}>
                                {({data, loading, error}) => {
                                    if (loading && !data) {
                                        return <ActivityIndicator color={Color.PRIMARY}/>
                                    }
                                    return (
                                        <View>
                                            {this.list(data?.instructional ?? [])}
                                        </View>
                                    )
                                }}
                            </Query>

                        </View>

                    </View>
                </Animated.ScrollView>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Instructional);




