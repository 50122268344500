import gql from 'graphql-tag';

export const SIGN_USER = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      user {
        id
        title
        first_name
        last_name
        email
        address
        mobile
        access_level
        office_id
        profile_image
        city
        zipcode
        user_detail{
          id
          user_id
          education
          about
          cv_url
          status
        }
        user_media{
          id
          user_id
          title
          redirect_url
          media_url
          status
        }
        user_document{
          id
          user_id
          doc_url
          doc_type
        }
      }
      token {
        access_token
        token_type
        expires_in
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgot_password_email($email: String!, $userTypeId: Int!) {
    forgot_password_email(email: $email, user_type_id: $userTypeId) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_CHANGE_PASSWORD = gql`
  mutation change_password($input: ChangePasswordInput!) {
    change_password(input: $input) {
      user {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        zipcode
        status
      }
      token {
        access_token
        token_type
        expires_in
      }
    }
  }
`;

export const SELLER_INIT_SIGNANT_SESSION = gql`
  mutation bankIdInitSession($input: BankIdInput) {
    bankIdInitSession(input: $input) {
      initial_session {
        AuthenticationUrl
        RequestID
        TrackingID
      }
    }
  }
`;

export const SELLER_LOGOUT = gql`
  mutation logout {
    logout {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const SELLER_ENERGY_LABELING = gql`
  mutation property_energy_labeling($input: EnergyLabelingInput!) {
    property_energy_labeling(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const SELLER_ADD_FINN_COMMENT = gql`
  mutation add_finn_ad_comment($id: Int!, $propertyId: Int!, $comment: String!) {
    add_finn_ad_comment(id: $id, property_id: $propertyId, comment: $comment) {
      id
      property_finn_ad_id
      comment
      created_at
      updated_at
      property_finn_ad {
        id
        property_id
        url
        is_approved
        approved_date
        finn_comments {
          id
          property_finn_ad_id
          comment
          created_at
          updated_at
        }
      }
    }
  }
`;

export const SELLER_APPROVE_FINN_AD = gql`
  mutation approve_Finn_ad($id: Int!, $propertyId: Int!) {
    approve_Finn_ad(id: $id, property_id: $propertyId) {
      id
      property_id
      url
      is_approved
      approved_date
      finn_comments {
        id
        property_finn_ad_id
        comment
        created_at
        updated_at
      }
    }
  }
`;

export const SELLER_APPROVE_DS = gql`
  mutation approve_digital_sales_assignment($id: Int!, $propertyId: Int!) {
    approve_digital_sales_assignment(id: $id, property_id: $propertyId) {
      id
      property_id
      url
      is_approved
      approved_date
      digital_sales_assi_comment {
        id
        digital_sales_assi_id
        comment
        created_at
        updated_at
      }
    }
  }
`;

export const SELLER_MARKETING_MATERIAL_COMMENT = gql`
  mutation add_marketing_material_comment($id: Int!, $propertyId: Int!, $comment: String!) {
    add_marketing_material_comment(id: $id, property_id: $propertyId, comment: $comment) {
      id
      marketing_materials_id
      comment
      created_at
      updated_at
    }
  }
`;

export const SELLER_MARKETING_MATERIAL_APPROVE = gql`
  mutation approve_marketing_material($id: Int!, $propertyId: Int!) {
    approve_marketing_material(id: $id, property_id: $propertyId) {
      id
      document_id
      is_approved
      approved_date
      document {
        id
        property_id
        title
        doc_url
        doc_type
        is_buyer_seller_doc
        status
      }
    }
  }
`;

export const SELLER_ADD_FINANCING = gql`
  mutation add_financing($financingType: [Int]!, $isAgree: Int!, $propertyId: Int!) {
    add_financing(Financingtype: $financingType, is_agree: $isAgree, property_id: $propertyId) {
      id
      user_id
      property_id
      user_type
      name
      phone_no
      email
      is_agree
      financing_types {
        id
        name
      }
    }
  }
`;

export const SELLER_GET_MOVING_DEAL = gql`
  mutation get_deals_mail($movingId: Int!) {
    get_deals_mail(moving_guide_id: $movingId) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_COMPLETE_MOVING = gql`
  mutation complete_moving_guide_seller($movingId: Int!, $propertyId: Int!) {
    complete_moving_guide_seller(moving_guide_id: $movingId, property_id: $propertyId) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const UPDATE_PROPERTY_TIME_LINE = gql`
mutation updatePropertyTimeline($propertyId: ID!, $status: Int!) {
  update_property_timeline(property_id: $propertyId, status: $status) {
    success
  }
}`;


export const SELLER_CONTACT_ME_MOVING_GUIDE = gql`
mutation contact_me_notification($propertyId: Int!) {
  contact_me_notification(property_id: $propertyId) {
    meta {
      status
      message
      code
    }
  }
}
`;

export const SELLER_SETTLEMENT_INFORMATION = gql`
  mutation property_settlement_info($input: SettlementInfoInput!) {
    property_settlement_info(input: $input) {
      id
      property_id
      address
      city
      zipcode
      settlement_loans {
        id
        denomination_kr
        residual_debt_kr
        reminder_date
        bank_name
        bank_phone
        bank_email
        loan_no
        is_repaid
      }
      settlement_receivable {
        id
        receivables_account_no
        percentage
      }
    }
  }
`;
export const SELLER_ADD_DISPLAY = gql`
  mutation add_property_display($input: PropertyDisplayInput!) {
    add_property_display(input: $input) {
      id
      property_id
      display_date
      earliest_start_time
      latest_start_time
      comment
      is_broker_on_view
      created_at
    }
  }
`;

export const SELLER_EDIT_DISPLAY = gql`
  mutation edit_property_display($input: EditPropertyDisplayInput!) {
    edit_property_display(input: $input) {
      id
      property_id
      display_date
      earliest_start_time
      latest_start_time
      comment
      is_broker_on_view
      created_at
    }
  }
`;
export const SELLER_STYLISH_ORDER = gql`
  mutation order_stylish($input: OrderStylishInput!) {
    order_stylish(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const SELLER_PURCHASE_SERVICE = gql`
mutation property_services($input:SavePropertyServices!){
    property_services(input:$input)
      {
        id
        property_id
        usable_service_id
        usable_service_class
        price
        quantity
      }
}`;

export const SELLER_RATE_ADD_COMMENT = gql`
  mutation rateAddComment($input: OrderRateCommentInput!) {
    order_rate_detail_comment(input: $input) {
      id
      order_rate_detail_id
      seller_id
      comment
    }
  }
`;

export const SELLER_RATE_DELETE_COMMENT = gql`
  mutation rateDeleteComment($input: DeleteRateDetailCommentInput!) {
    delete_rate_detail_comment(input: $input) {
      id
    }
  }
`;

export const SELLER_RATE = gql`
  mutation order_rate($input: OrderRateInput!) {
    order_rate(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SELLER_DETAILED_RATE = gql`
  mutation order_rate_detail($input: OrderRateDetailInput!) {
    order_rate_detail(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const SELLER_INTERVIEW_ANSWER = gql`
  mutation interview_answer($input: InterviewAnswerInput!) {
    interview_answer(input: $input) {
      id
      seller_id
      property_id
      answer_json
      answer_pdf_url
    }
  }
`;

export const SELLER_UPDATE_PROFILE = gql`
  mutation update_seller_profile($input: UpdateSellerProfileInput) {
    update_seller_profile(input: $input) {
      user {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        zipcode
        status
        access_level
      }
    }
  }
`;

export const CHANGE_PROFILE_PIC = gql`
mutation changeProfilePic($file:Upload!){
  change_profile_pic(profile_pic:$file)
}`;

export const SELLER_PHOTO_ORDER = gql`
  mutation order_photo($input: OrderPhotoInput!) {
    order_photo(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
