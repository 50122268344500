import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    notification,
    Row,
    Select,
    Typography,
    Upload,
} from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import React, {useEffect, useState} from 'react';
import {Mutation} from 'react-apollo';
import {Link, useHistory} from 'react-router-dom';

import {ADMIN_CREATE_JOBS} from '../../../../../mutation/AdminMutation';
import {formatMessage} from "components/src/utils";
import { isEmpty } from 'lodash';

const {Title} = Typography;


const AddJobs = () => {
    let router=useHistory();
    const [editorState, seteditorState] = useState(null);
    const [editorState2, seteditorState2] = useState(null);
    const [editorState3, seteditorState3] = useState(null);
    const [successModal, setSuccessModal] = useState(false);
    const [keysInitial, setkeysInitial] = useState([1]);
    const [initialId, setinitialId] = useState(0);
    const [formValue, setFormValue] = useState();
    const [imageUrl, setimageUrl] = useState('');
    const [imageObj, setimageObj] = useState();

    useEffect(() => {
        seteditorState(BraftEditor.createEditorState());
        seteditorState2(BraftEditor.createEditorState());
        seteditorState3(BraftEditor.createEditorState());
    }, []);

    const submitSummaryContent = async () => {
        const htmlContent = editorState.toHTML();
    };

    const submitSummaryContent2 = async () => {
        const htmlContent = editorState2.toHTML();
    };

    const submitSummaryContent3 = async () => {
        const htmlContent = editorState3.toHTML();
    };

    const handleEditorChange = editor => {
        seteditorState(editor);
    };

    const handleEditorChange2 = editor => {
        seteditorState2(editor);
    };

    const handleEditorChange3 = editor => {
        seteditorState3(editor);
    };

    const onClickOk = (createJobs, values) => {
        setSuccessModal(false);
        router.push(`/admin/ledigstilling`);
    };

    const onFinish = (createJobs, values) => {
        console.log('Success Onfinish:', values);
        console.log('Success Onfinish:', values);

        setFormValue(values);
        const variables = {
            job_title: values.jobtitle,
            location: values.location,
            company_name: values.companyname,
            party: values.party,
            type: values.jobtype,
            looking_for_description: editorState2.toHTML(),
            what_you_offer: editorState3.toHTML(),
            contact_info: values.contact_info,
        };
        console.log('values.jobpicture.file.originFileObj:', imageObj);
        console.log('Success:', values);

        if (!isEmpty(editorState.toHTML())) {
            variables.rightside_description = editorState.toHTML();
        }
        if (values.jobid) {
            variables.code = values.jobid;
        }
        if (imageObj) {
            console.log('inside condition');
            variables.image_url = imageObj;
        }
        console.log('variables:', variables);
        createJobs({
            variables,
        }).then(({ data }) => {
            console.log('Data submitted===>>>', data);
            if (data && data.create_job && data.create_job.meta) {
                // setSuccessModal(true);
                notification.success({
                    message: 'Jobber lagt til',
                    description: '',
                    duration: 3,
                })  ;
                router.push(`/admin/ledigstilling`);
            }
        });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = info => {
        getBase64(info.file.originFileObj, image => {
            setimageUrl(image);
            setimageObj(info.file.originFileObj);
        });
    };

    const uploadButton = (
        <div>
            <PlusOutlined className="uploadplus" />
            <div className="ant-upload-text label-texts">
                {formatMessage({
                    id: 'component.form.upload',
                })}
            </div>
        </div>
    );
    const optionsData = [
        { value: 'FULLTIME', label: 'Fulltid' },
        { value: 'PARTTIME', label: 'Deltid' },
    ];

    const addContactForm = () => {
        console.log('initi id:', initialId);
        console.log('keys initial:', keysInitial);
        let temp_id = initialId;
        const temp_keysInitial = keysInitial;
        setinitialId((temp_id += 1));
        temp_keysInitial.push((temp_id += 1));
        setkeysInitial(temp_keysInitial);
    };

    const removeContactForm = k => {
        if (keysInitial.length === 0) {
            return;
        }
        const temp_initial = keysInitial.filter(key => key !== k);
        setkeysInitial(temp_initial);
    };

    const addContactFormItems = keysInitial.map((k, index) => (
        <>
            {initialId > 0 && index !== 0 ? (
                <Row gutter={24}>
                    <Col xs={24}>
                        <div className="flex-wrapper space-between">
                            <h5 className="text-small text-primary text-bold mt-20 mb-20 text-left">
                                Addition Contact {index}
                            </h5>
                            <div
                                className="cursor-pointer"
                                onClick={() => {
                                    removeContactForm(k);
                                }}
                            >
                                <i className="icon-order-cancel text-primary" />
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : null}
            <Row gutter={16}>
                <Col xs={24} lg={8}>
                    <Form.Item
                        name={['contact_info', `${k}`, 'name']}
                        label={formatMessage({
                            id: 'component.jobs.contactname',
                        })}
                        rules={[
                            {
                                required: true,
                                message: `Angi ${formatMessage({
                                    id: 'component.jobs.contactname',
                                }).toLowerCase()}`,
                            },
                        ]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder={formatMessage({
                                id: 'component.jobs.contactname',
                            })}
                            size="large"
                            className="pickerwidth"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item
                        name={['contact_info', `${k}`, 'email']}
                        label={formatMessage({
                            id: 'component.jobs.contactemail',
                        })}
                        rules={[
                            {
                                required: true,
                                message: `Angi ${formatMessage({
                                    id: 'component.jobs.contactemail',
                                }).toLowerCase()}`,
                            },
                        ]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder={formatMessage({
                                id: 'component.jobs.contactemail',
                            })}
                            size="large"
                            className="pickerwidth"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item
                        name={['contact_info', `${k}`, 'phone']}
                        label={formatMessage({
                            id: 'component.jobs.contactphone',
                        })}
                        rules={[
                            {
                                required: true,
                                message: `Angi ${formatMessage({
                                    id: 'component.jobs.contactphone',
                                }).toLowerCase()}`,
                            },
                        ]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder={formatMessage({
                                id: 'component.jobs.contactphone',
                            })}
                            size="large"
                            className="pickerwidth"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    ));

    return (
        <>
            <div className="contentshadow">
                <div className="text-primary main">
                    <Row>
                        <Col xs={24}>
                            <Title level={3} className="text-primary">
                                {formatMessage({
                                    id: 'component.jobs.addjobs',
                                })}
                            </Title>
                            <p />
                            {/* {formatMessage({
                  id: 'component.jobs.addjobs.description',
                })}
              </p> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Mutation mutation={ADMIN_CREATE_JOBS}>
                                {(createJobs, { loading }) => (
                                    <Form
                                        layout="vertical"
                                        className="user-forms addEditform"
                                        name="jobs"
                                        initialValues={{
                                            remember: true,
                                        }}
                                        colon={false}
                                        onFinish={values => onFinish(createJobs, values)}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Row gutter={16}>
                                            <Col xs={24}>
                                                <Form.Item name="jobpicture" label="Stilling ledig bilde">
                                                    <Upload
                                                        name="jobpicture"
                                                        listType="picture-card"
                                                        className="avatar-uploader"
                                                        showUploadList={false}
                                                        onChange={handleChange}
                                                    >
                                                        {imageUrl ? (
                                                            <img src={imageUrl} alt="jobpicture" style={{ width: '100%' }} />
                                                        ) : (
                                                            uploadButton
                                                        )}
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="jobtitle"
                                                    label={formatMessage({
                                                        id: 'component.jobs.jobtitle',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Vennligst skriv inn jobb tittel',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        placeholder={formatMessage({
                                                            id: 'component.jobs.jobtitle.placeholder',
                                                        })}
                                                        size="large"
                                                        className="pickerwidth"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="jobtype"
                                                    label={formatMessage({
                                                        id: 'component.jobs.jobtype',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Vennligst skriv inn jobb type',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        suffixIcon={<CaretDownOutlined />}
                                                        style={{ width: '100%' }}
                                                        placeholder={formatMessage({
                                                            id: 'component.jobs.jobtype.placeholder',
                                                        })}
                                                        size="large"
                                                        options={optionsData}
                                                        className="pickerwidth"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="companyname"
                                                    label={formatMessage({
                                                        id: 'component.table.companyname',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: `${formatMessage({
                                                                id: 'component.jobs.company.placeholder',
                                                            })}`,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        placeholder={formatMessage({
                                                            id: 'component.jobs.company.placeholder',
                                                        })}
                                                        size="large"
                                                        className="pickerwidth"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="party"
                                                    label={formatMessage({
                                                        id: 'component.jobs.party',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: `${formatMessage({
                                                                id: 'component.jobs.party.placeholder',
                                                            })}`,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        placeholder={formatMessage({
                                                            id: 'component.jobs.party.placeholder',
                                                        })}
                                                        size="large"
                                                        className="pickerwidth"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="jobid"
                                                    label={formatMessage({
                                                        id: 'component.jobs.jobid',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: `${formatMessage({
                                                                id: 'component.jobs.jobtype.placeholder',
                                                            })}`,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        placeholder={formatMessage({
                                                            id: 'component.jobs.reqid.placeholder',
                                                        })}
                                                        size="large"
                                                        className="pickerwidth"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="location"
                                                    label={formatMessage({
                                                        id: 'component.jobs.location',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Vennligst skriv inn plassering',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        placeholder={formatMessage({
                                                            id: 'component.jobs.location.placeholder',
                                                        })}
                                                        size="large"
                                                        className="pickerwidth"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={24}>
                                                <Form.Item
                                                    name="rightsidedescription"
                                                    label={formatMessage({
                                                        id: 'component.jobs.rightsidedescription',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Vennligst skriv inn jobb beskrivelse',
                                                        },
                                                    ]}
                                                >
                                                    <BraftEditor
                                                        className="editor-wrapper"
                                                        value={editorState}
                                                        onChange={handleEditorChange}
                                                        onSave={submitSummaryContent}
                                                        language="en"
                                                        contentStyle={{ height: 210 }}
                                                        placeholder={`Angi ${formatMessage({
                                                            id: 'component.jobs.rightsidedescription',
                                                        }).toLowerCase()}`}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={24}>
                                                <Form.Item
                                                    name="whatareyoulookingfor"
                                                    label={formatMessage({
                                                        id: 'component.jobs.whatareyoulookingfor',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Vennligst skriv inn jobb beskrivelse',
                                                        },
                                                    ]}
                                                >
                                                    <BraftEditor
                                                        className="editor-wrapper"
                                                        value={editorState2}
                                                        onChange={handleEditorChange2}
                                                        onSave={submitSummaryContent2}
                                                        language="en"
                                                        contentStyle={{ height: 210 }}
                                                        placeholder={`Angi ${formatMessage({
                                                            id: 'component.jobs.jobdescription',
                                                        }).toLowerCase()}`}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={24}>
                                                <Form.Item
                                                    name="what_you_offer"
                                                    label={formatMessage({
                                                        id: 'component.jobs.whatyouoffer',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Vennligst skriv inn jobb beskrivelse',
                                                        },
                                                    ]}
                                                >
                                                    <BraftEditor
                                                        className="editor-wrapper"
                                                        value={editorState3}
                                                        onChange={handleEditorChange3}
                                                        onSave={submitSummaryContent3}
                                                        language="en"
                                                        contentStyle={{ height: 210 }}
                                                        placeholder={`Angi ${formatMessage({
                                                            id: 'component.jobs.jobdescription',
                                                        }).toLowerCase()}`}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {addContactFormItems}
                                        <Form.Item>
                                            <Row className="mb-3">
                                                <Col lg={24}>
                                                    <Button
                                                        type="primary"
                                                        className="primary buttons admin-forms-btn"
                                                        onClick={() => addContactForm()}
                                                        size="large"
                                                    >
                                                        {formatMessage({
                                                            id: 'component.addmore',
                                                        })}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Row>
                                            <Col lg={24}>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="btn-secondary buttons admin-forms-btn"
                                                    loading={loading}
                                                    size="large"
                                                >
                                                    {formatMessage({
                                                        id: 'component.form.add',
                                                    })}
                                                </Button>
                                                <Link to="/admin/ledigstilling">
                                                    <Button
                                                        className="text-primary admin-forms-btn"
                                                        style={{ marginLeft: 8 }}
                                                        size="large"
                                                        // onClick={router.goBack}
                                                    >
                                                        {formatMessage({
                                                            id: 'component.form.cancel',
                                                        })}
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Modal
                                            centered
                                            title={`Success full `}
                                            visible={successModal}
                                            onOk={() => {
                                                setSuccessModal(false);
                                                router.push(`/admin/ledigstilling`);
                                            }}
                                            onCancel={setSuccessModal(false)}
                                            width="450px"
                                            footer={[
                                                <Mutation mutation={ADMIN_CREATE_JOBS}>
                                                    {(createJobs, { loading }) => (
                                                        <Button
                                                            key="submit"
                                                            type="primary"
                                                            className="btn-secondary admin-forms-btn  mr_15"
                                                            size="large"
                                                            onClick={() => onClickOk(createJobs, formValue)}
                                                        >
                                                            {formatMessage({
                                                                id: 'component.form.okay',
                                                            })}
                                                        </Button>
                                                    )}
                                                </Mutation>,
                                            ]}
                                        >
                                            <p>
                                                {formatMessage({
                                                    id: 'component.jobs.modal.text',
                                                })}
                                            </p>
                                        </Modal>
                                    </Form>
                                )}
                            </Mutation>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default AddJobs;
