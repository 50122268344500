import React from 'react';
import {Animated, View} from 'react-native';
import PropTypes from 'prop-types';

import { CommonStyle, IS_ANDROID, IS_WEB, ThemeUtils} from '../../../utils';
import {LocalColor as Color} from 'components/src/utils/Color'
const Label = (props) => {

    const onClick = () => {
        if (props.onPress) {
            props.onPress();
        }
    };

    let stylesArray = [];
    if (props.xxlarge) {
        stylesArray.push({fontSize: ThemeUtils.fontXXLarge});
    } else if (props.xlarge) {
        stylesArray.push({fontSize: ThemeUtils.fontXLarge});
    } else if (props.large) {
        stylesArray.push({fontSize: ThemeUtils.fontLarge});
    } else if (props.normal) {
        stylesArray.push({fontSize: ThemeUtils.fontNormal});
    } else if (props.small) {
        stylesArray.push({fontSize: ThemeUtils.fontSmall});
    } else if (props.xsmall) {
        stylesArray.push({fontSize: ThemeUtils.fontXSmall});
    } else if (props.xxsmall) {
        stylesArray.push({fontSize: ThemeUtils.fontXXSmall});
    } else {
        stylesArray.push({fontSize: ThemeUtils.fontNormal});
    }

    if (props.bolder) {
        stylesArray.push({fontFamily: 'Maax-Bold'});
    } else if (props.bold) {
        stylesArray.push({fontFamily: 'Maax-Medium'});
    }
    // font_regular: false,
    // font_medium: false,
    // font_italic: false,
    // font_boldItalic: false,
    // font_black: false,
    // font_bold: false,
    // font_mediumItalic: false,

    if (props.font_regular) {
        stylesArray.push({fontFamily: 'Maax'});
    } else if (props.font_medium) {
        stylesArray.push({fontFamily: 'Maax-Medium'});
    } else if (props.font_italic) {
        stylesArray.push({fontFamily: 'Maax-Italic'});
    } else if (props.font_bold) {
        stylesArray.push({fontFamily: 'Maax-Bold'});
    } else if(props.font_title){
        stylesArray.push({fontFamily: 'Quattrocento'});
    } else if(props.font_title_bold){
        stylesArray.push({fontFamily: 'Quattrocento-Bold'});
    } else{
        stylesArray.push({fontFamily: 'Maax'});
    }

    stylesArray.push({
        color: props.color,
        marginTop: props.mt,
        marginBottom: props.mb,
        marginLeft: props.icon ? ThemeUtils.relativeRealWidth(2) : props.ml,
        marginRight: props.mr,
        textAlign: props.align,
        backgroundColor: props.bgColor
    });
    if (props.fontSize != null) {
        stylesArray.push({fontSize: props.fontSize});
    }


    return (
        <Animated.View style={props.style}>
            <Animated.Text pointeEvents={'none'}
                           style={[stylesArray, props.textStyle]}
                           numberOfLines={props.singleLine ? 1 : props.numberOfLines ? props.numberOfLines : null}
                           onPress={props.onPress ? onClick : null}>
                {props.children}
            </Animated.Text>
        </Animated.View>
    );
};

Label.defaultProps = {
    xxsmall: false,
    xsmall: false,
    small: false,
    normal: false,
    large: false,
    xlarge: false,
    xxlarge: false,
    bold: false,
    bolder: false,
    lighter: false,
    light: false,
    color: Color.PRIMARY_DARK,
    bgColor: Color.TRANSPARENT,

    font_regular: false,
    font_medium: false,
    font_italic: false,
    font_boldItalic: false,
    font_black: false,
    font_bold: false,
    font_mediumItalic: false,

    badge: false,
    badgeCount: 0,
    align: 'left',
    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
    singleLine: false,
    icon: null,
    iconSize: ThemeUtils.fontNormal,
    iconColor: Color.PRIMARY,
    fontSize: null,
};

Label.propTypes = {
    xxsmall: PropTypes.bool,
    xsmall: PropTypes.bool,
    small: PropTypes.bool,
    normal: PropTypes.bool,
    large: PropTypes.bool,
    badge: PropTypes.bool,
    xlarge: PropTypes.bool,
    xxlarge: PropTypes.bool,
    bold: PropTypes.bool,
    bolder: PropTypes.bool,
    light: PropTypes.bool,
    lighter: PropTypes.bool,
    color: PropTypes.string,
    bgColor: PropTypes.string,
    font_medium: PropTypes.bool,
    font_regular: PropTypes.bool,
    font_bold: PropTypes.bool,
    font_title: PropTypes.bool,
    font_title_bold: PropTypes.bool,
    font_italic: PropTypes.bool,
    mt: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
    mr: PropTypes.number,
    align: PropTypes.string,
    singleLine: PropTypes.bool,
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    iconColor: PropTypes.string,
    numberOfLines: PropTypes.number,
    badgeCount: PropTypes.number,
    badgeStyle: PropTypes.object,


};
export default Label;
