import React from 'react'
import {ActivityIndicator, Animated, View} from 'react-native'
//Network
import {Mutation, Query} from "@apollo/react-components";
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
// import { Checkbox, Radio,WhiteSpace } from '@ant-design/react-native/lib/';
// import List from "@ant-design/react-native/lib/list";
import Provider from "@ant-design/react-native/lib/provider";
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
import {CheckBox, CustomButton, Header, Label} from "components/src/components";

import enUS from '@ant-design/react-native/lib/locale-provider/en_US';

import {styles} from './styles.js';
import {BUYER_ADD_FINANCING} from "components/src/api/buyerMutation";
import {BUYER_FINANCE_TYPE, BUYER_FINANCING_DETAILS, SELLER_FINANCE_TYPE} from "components/src/api/buyerQuery";
// import {Button, InputItem} from "@ant-design/react-native";
// import { Radio, WhiteSpace } from '@ant-design/react-native';
// import { DatePickerView } from 'antd-mobile';

const option = [{
    index: 1,
    name: Strings.AgreeFinancing,
    value: 1
}]

class Financing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            isAgree: [],
            financeType: []
        }
        this.scrollY = new Animated.Value(0)


    }

    addFinance = (addFinance) => {
        addFinance({
            variables: {
                input: {
                    financingtype: this.state.financeType,
                    is_agree: this.state.isAgree.includes(1) ? 1 : 0,
                    property_id: this.props.property?.property_id
                }

            }
        })
    };

    render() {
        return (
            <View style={CommonStyle.container}>

                <Header animatedTitle={Strings.FinancingTitle}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>

                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={{
                                         flexGrow: 1,
                                         paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2
                                     }}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.labeliconContainer}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'rate'}
                                    color={Color.PRIMARY_DARK}/>

                            </View>
                            <View>
                                <Label small
                                       mt={20}
                                       font_medium
                                       align={'left'}>
                                    {Strings.getbettermortgageterms}
                                </Label>
                                <Label
                                    mt={16}
                                    font_regular
                                    mb={18}
                                    small
                                    align={'left'}>{Strings.FinancingInformation}</Label>
                            </View>
                            <Query query={BUYER_FINANCING_DETAILS}
                                   fetchPolicy={'network-only'}
                                   onCompleted={(data) => {
                                       this.setState({
                                           isAgree: data?.buyer_financing_detail?.is_agree===1?[1]:[0],
                                       })
                                   }}
                                   variables={{
                                       propertyId: this.props.property?.property_id
                                   }}>
                                {(buyerFinanceQuery) => {
                                    let financeType=buyerFinanceQuery?.data?.buyer_financing_detail?.financingType?.map(item => item.financing_type_id)
                                    if (buyerFinanceQuery?.loading && !buyerFinanceQuery?.data) {
                                        return <ActivityIndicator color={Color.PRIMARY}/>
                                    }
                                    return (
                                        <View style={styles.bottomdownContainer}>

                                            <View style={styles.checkbox}>

                                                <Query query={BUYER_FINANCE_TYPE}
                                                       variables={{
                                                           status: true
                                                       }}>
                                                    {({loading, data, error}) => {
                                                        if (loading && !data && buyerFinanceQuery?.loading) {
                                                            return <ActivityIndicator color={Color.PRIMARY}/>
                                                        }
                                                        return (<CheckBox options={data?.financing_type_list ?? []}
                                                                          disabled={buyerFinanceQuery?.data?.buyer_financing_detail}
                                                                          style={styles.Financingradio}
                                                                          size={20}
                                                                          changeOnProp
                                                                          defaultChecked={financeType}
                                                                          onCheckChanged={(val) => this.setState({financeType: val})}/>)
                                                    }}
                                                </Query>


                                            </View>


                                            <CheckBox options={option}
                                                      disabled={buyerFinanceQuery?.data?.buyer_financing_detail}
                                                      size={ThemeUtils.fontXLarge}
                                                      labelProps={{
                                                          bolder: true,
                                                          small: true
                                                      }}
                                                      changeOnProp
                                                      defaultChecked={buyerFinanceQuery?.data?.buyer_financing_detail?.is_agree===1?[1]:[0]}
                                                      style={styles.energyradio}
                                                      bold
                                                      onCheckChanged={(val) => {
                                                          this.setState({isAgree: val})
                                                      }}
                                            />
                                            <Mutation mutation={BUYER_ADD_FINANCING}
                                                      onCompleted={() => IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack()}
                                                      onError={() => {

                                                      }}>
                                                {(addFinance, {loading}) => (
                                                    <CustomButton
                                                        disabled={(!this.state.financeType.length || !this.state.isAgree.includes(1)) || buyerFinanceQuery?.data?.buyer_financing_detail}
                                                        mt={18}
                                                        mb={18}
                                                        loading={loading}
                                                        font_medium
                                                        onPress={() => this.addFinance(addFinance)}
                                                        title={Strings.send}/>
                                                )}

                                            </Mutation>
                                        </View>
                                    )
                                }}
                            </Query>

                        </View>

                    </View>
                </Animated.ScrollView>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Financing);
