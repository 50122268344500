import {StyleSheet} from 'react-native';
import { Color ,ThemeUtils} from '../../../../../../utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },

    fieldContainer: {
        alignContent: 'center',
    },
    viewicon:{
        marginTop: 24,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    mainviewdata:{flexDirection: 'column'},
    subviewdata:{
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    commentview:{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },


});
export {styles};
