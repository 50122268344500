import {STORAGE_URL, USER_TYPE} from "../../../utils/constant";
import {CaretDownOutlined, MailOutlined} from "@ant-design/icons";
import {Avatar, Badge, Col, Drawer, Layout, Menu, Popover, Row} from "antd";
import Cookie from "js-cookie";
import React, {PureComponent} from "react";
import {Mutation} from "react-apollo";
import {Link, withRouter} from "react-router-dom";
import {formatMessage} from "components/src/utils";
import {SELLER_LOGOUT} from "../../../../mutation/SellerMutation";
// import webmeglingMobileLogo from '../../../assets/webmegling.svg';
// import webmeglingLogo from '../../../assets/webmegling_green_wm.svg';
import userPlaceholder from "../../../assets/defaultuser.png";
import "../../index.scss";
import {getWmeglingInnerLogo} from "../../../utils/utils";

const {Header, Sider, Content} = Layout;
const {SubMenu} = Menu;

class AdminSidebar extends PureComponent {
    constructor(props) {
        const {user} = JSON.parse(Cookie.get(`${USER_TYPE.ADMIN}_user`));
        console.log("inn-->", user);
        console.log(
            "cookie user data:",
            JSON.parse(Cookie.get(`${USER_TYPE.ADMIN}_user`))
        );
        // const { user } =
        //   Cookie.get(`${USER_TYPE.ADMIN}_user`) !== undefined &&
        //   Cookie.get(`${USER_TYPE.ADMIN}_user`) !== null
        //     ? JSON.parse(Cookie.get(`${USER_TYPE.ADMIN}_user`))
        //     : undefined;
        //  JSON.parse(Cookie.get(`${USER_TYPE.ADMIN}_user`));
        super(props);
        this.state = {
            collapsed: false,
            hasNotification: true,
            currentUser: user,
            current: "/",
            visible: false,
            collapsedMobile: true,
        };
    }

    componentDidMount() {
        const {
            children: {props},
        } = this.props;
        const {user} =
            Cookie.get(`${USER_TYPE.ADMIN}_user`) !== undefined &&
            Cookie.get(`${USER_TYPE.ADMIN}_user`) !== null
                ? JSON.parse(Cookie.get(`${USER_TYPE.ADMIN}_user`))
                : {};
        if (!user) {
            this.props.history.push("/admin/login");
        }
        this.setState({current: props.location.pathname});
        document.body.classList.add("adminheader");
        this.onStorageChange();
    }

    componentWillUnmount() {
        document.body.classList.remove("adminheader");

        // returned function will be called on component unmount
    }

    showDrawer = () => {
        this.setState({
            visible: true,
            collapsedMobile: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            collapsedMobile: false,
        });
    };

    handleNavigation = (e) => {
        this.setState({
            current: e.key,
        });
    };

    onStorageChange = () => {
        window.addEventListener("updateAdminProfile", () => {
            this.setState({
                currentUser: JSON.parse(Cookie.get(`${USER_TYPE.ADMIN}_user`)).user,
            });
        });
    };

    render() {
        const {
            collapsed,
            hasNotification,
            current,
            currentUser,
            collapsedMobile,
        } = this.state;
        const {children} = this.props;

        const topmenu = (
            <Menu className="popupmenu popmenuitem text-primary">
                <Menu.Item key="0" className="profilename">
                    {`${currentUser.first_name} ${currentUser.last_name}`}
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item key="/admin/redigerprofil">
                    <Link to="/admin/redigerprofil">
                        <i className="icon-edit-profile topnacicon"/>
                        <span>
              {formatMessage({
                  id: "component.menu.editprofile",
              })}
            </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/admin/bytt-passord">
                    <Link to="/admin/bytt-passord">
                        <i className="icon-takeover topnacicon"/>
                        <span>
              {formatMessage({
                  id: "component.menu.changepassword",
              })}
            </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="3">
                    <Mutation mutation={SELLER_LOGOUT}>
                        {(doLogout, {loading, data}) => {
                            if (data && data.logout && data.logout.meta && !loading) {
                                Cookie.remove(`${USER_TYPE.ADMIN}_token`);
                                Cookie.remove(`${USER_TYPE.ADMIN}_user`);
                                this.props.history.push("/admin/login");
                            }
                            return (
                                <div
                                    className="logout"
                                    onClick={() => {
                                        const adminToken = Cookie.get(`${USER_TYPE.ADMIN}_token`);
                                        const token = Cookie.get("token");
                                        if (token !== adminToken) {
                                            Cookie.set("token", adminToken);
                                        }
                                        doLogout();
                                    }}
                                >
                                    <i className="icon-logout topnacicon"/>
                                    <span>
                    {formatMessage({
                        id: "component.menu.logout",
                    })}
                  </span>
                                </div>
                            );
                        }}
                    </Mutation>
                </Menu.Item>
            </Menu>
        );

        const mobileImage = (
            <img
                src={getWmeglingInnerLogo()}
                alt="logo"
                className="d-block"
                style={{maxWidth: "22%", margin: "0 auto"}}
            />
        );

        const text = (
            <span className="text-primary text-large text-bold">Varsler</span>
        );
        const content = (
            <span>
        <div className="bg-gray">
          <i className="icon-dot"/>
          <div className="card-content">
            <div className="selected-list">
              <p className="text-small mb-0">
                Lorem ipsum dolor sit amet, consectetuer adipisc -ng elit.
                Aenean commodo ligula eget dolor.
              </p>
              <span className="small-text">Just Now</span>
            </div>
          </div>
        </div>
        <hr/>
        <div className="bg-gray">
          <i className="icon-dot"/>
          <div className="card-content">
            <div className="selected-list">
              <p className="text-small mb-0">
                Lorem ipsum dolor sit amet, consectetuer adipisc -ng elit.
                Aenean commodo ligula eget dolor.
              </p>
              <span className="small-text">2 min. ago</span>
            </div>
          </div>
        </div>
        <hr/>
        <div className="card-content">
          <p className="text-small mb-0">
            Lorem ipsum dolor sit amet, consectetuer adipisc -ng elit. Aenean
            commodo ligula eget dolor.
          </p>
          <span className="small-text">3 hrs ago</span>
        </div>
        <hr/>
        <div className="">
          <span className="clearall text-center">Slett alle</span>
          <span className="seemore text-center">Se flere</span>
        </div>
      </span>
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (
            <>
                <Header className="w-100 ant-layout-header admin-header bg-primary d-none d-lg-block">
                    <Row className="w-100">
                        <Col xs={0} md={24} lg={24}>
                            <div className="admin-layout-wrapper">
                                <div className="logo-wrapper">
                                    <div className="logo-container">
                                        <Link to="/admin/dashboard">
                                            <img
                                                src={getWmeglingInnerLogo()}
                                                className="logoicon"
                                                style={{color: "#b9e2bf"}}
                                                alt="WebMegling"
                                            />
                                        </Link>
                                        <span className="alignright">
                      <i
                          className="icon-menu text-white"
                          onClick={() => {
                              this.setState({collapsed: !collapsed});
                          }}
                      />
                    </span>
                                    </div>
                                </div>
                                <div className="admin-components-global-header-index-right">
                                    <Link to="#" style={{marginRight: "10px"}}>
                                        {/* <Popover
                      placement="bottom"
                      className="notification-popover"
                      title={text}
                      content={content}
                      trigger="click"
                    > */}
                                        <Badge dot={hasNotification}>
                                            <i className="icon-notifications horizontalbaricon notification"/>
                                        </Badge>
                                        {/* </Popover> */}
                                    </Link>
                                    <Link to="#">
                                        <MailOutlined className="horizontalbaricon"/>
                                    </Link>
                                    <Popover
                                        placement="bottomRight"
                                        className="popoverclass"
                                        title=""
                                        content={topmenu}
                                        trigger="click"
                                    >
                                        <Avatar
                                            style={{marginLeft: "20px"}}
                                            shape="square"
                                            size={48}
                                            // src={`${STORAGE_URL}${user.profile_image}`}
                                            src={
                                                currentUser.profile_image
                                                    ? `${STORAGE_URL}${currentUser.profile_image}`
                                                    : userPlaceholder
                                            }
                                            // src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                            className="avtar_margin_b"
                                        />
                                    </Popover>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Header className="ant-layout-header admin-header bg-primary w-100 d-lg-none">
                    <Row className="w-100">
                        <Col xs={24} lg={0}>
                            <div className="admin-layout-wrapper w-100">
                                <Link onClick={this.showDrawer}>
                  <span className="alignright">
                    <i
                        className="icon-menu"
                        onClick={() => {
                            this.setState({collapsed: !collapsed});
                        }}
                    />
                  </span>
                                </Link>
                                <div className="logo-wrapper w-100">
                                    <div className="logo-container w-100">
                                        <Link to="/admin/dashboard">
                                            <img
                                                src={getWmeglingInnerLogo()}
                                                className="logoicon"
                                                style={{color: "#b9e2bf"}}
                                                alt="WebMegling"
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div
                                    className="admin-components-global-header-index-right"
                                    style={{marginRight: "-10px"}}
                                >
                                    <Link
                                        to="#"
                                        style={{paddingRight: "10px", marginTop: "10px"}}
                                    >
                                        <Popover
                                            placement="bottom"
                                            className="notification-popover"
                                            title={text}
                                            content={content}
                                            trigger="click"
                                        >
                                            <Badge dot={hasNotification}>
                                                <i className="icon-notifications horizontalbaricon notification"/>
                                            </Badge>
                                        </Popover>
                                    </Link>
                                    <Popover
                                        placement="bottomRight"
                                        className="popoverclass"
                                        title=""
                                        content={topmenu}
                                        trigger="click"
                                    >
                                        <Avatar
                                            style={{marginLeft: "10px"}}
                                            shape="square"
                                            size={48}
                                            src={
                                                currentUser.profile_image
                                                    ? `${STORAGE_URL}${currentUser.profile_image}`
                                                    : userPlaceholder
                                            }
                                            className="avtar_margin_b"
                                        />
                                    </Popover>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Layout className="d-lg-none">
                    <Content>{children}</Content>
                </Layout>

                <div className="site-drawer-render-in-current-wrapper d-lg-none mobile-sidebar test">
                    {/* <div style={{ marginTop: 16 }}>
          <Button type="primary" onClick={this.showDrawer}>
            Open
          </Button>
        </div> */}
                    <Drawer
                        title={mobileImage}
                        placement="left"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                        getContainer={false}
                        width="260"
                    >
                        <p>
                            <Layout className="layout-drawer text-left">
                                <Sider
                                    width="260px"
                                    className="admin-sider"
                                    collapsedWidth={0}
                                    collapsible
                                    collapsedMobile={collapsedMobile}
                                >
                                    <Menu
                                        mode="inline"
                                        className="admin-sidemenu"
                                        selectedKeys={[current]}
                                        onClick={this.handleNavigation}
                                    >
                                        <Menu.Item key="/admin/dashboard" className="submenuitems">
                                            <Link to="/admin/dashboard">
                                                <i className="icon-dashboard iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.dashboard",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="/admin/nyheter" className="submenuitems">
                                            <Link to="/admin/nyheter">
                                                <i className="icon-news iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.news",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="/admin/kontorer" className="submenuitems">
                                            <Link to="/admin/kontorer">
                                                <i className="icon-ds-property iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.offices",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="/admin/faglig/" className="submenuitems">
                                            <Link to="/admin/faglig/">
                                                <i className="icon-experienced-real-estate-agent iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.professional",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="allemeglere" className="submenuitems">
                                            <Link to="/admin/allemeglere">
                                                <i className="icon-agents iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.agents",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>

                                        {/* <SubMenu
                className="menuitemfonts custom-dropdown-icons admin-submenus"
                // suffixcon={<i className="icon-drop-down text-primary" />}
                suffixIcon={<CaretDownOutlined className="text-primary" />}
                key="/admin/agenter"
                title={
                  <div>
                    <i className="icon-agents iconmargingright" />
                    {formatMessage({
                      id: 'component.sidemenu.agents',
                    })}
                  </div>
                }
              >
                <Menu.Item key="/admin/alleagenter" className="submenuitem">
                  <Link to="/admin/alleagenter">
                    {formatMessage({
                      id: 'component.sidemenu.submenu.allagents',
                    })}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/admin/team" className="submenuitem">
                  <Link to="/admin/team">
                    {formatMessage({
                      id: 'component.sidemenu.submenu.team',
                    })}
                  </Link>
                </Menu.Item>
              </SubMenu> */}
                                        <SubMenu
                                            className="menuitemfonts custom-dropdown-icons admin-submenus"
                                            style={{marginLeft: "0px"}}
                                            // suffixcon={<i className="icon-drop-down text-primary" />}
                                            suffixIcon={
                                                <CaretDownOutlined className="text-primary"/>
                                            }
                                            key="/admin/superusers"
                                            title={
                                                <div>
                                                    <i className="icon-superusers iconmargingright"/>
                                                    {formatMessage({
                                                        id: "component.sidemenu.superusers",
                                                    })}
                                                </div>
                                            }
                                        >
                                            <Menu.Item
                                                key="/admin/dagligleder"
                                                className="submenuitem"
                                            >
                                                <Link to="/admin/dagligleder">
                                                    {formatMessage({
                                                        id: "component.sidemenu.submenu.officeceo",
                                                    })}
                                                </Link>
                                            </Menu.Item>

                                            <Menu.Item
                                                key="/admin/fagansvarlig"
                                                className="submenuitem"
                                            >
                                                <Link to="/admin/fagansvarlig">
                                                    {formatMessage({
                                                        id: "component.sidemenu.submenu.officecontroller",
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                        </SubMenu>
                                        <SubMenu
                                            className="menuitemfonts custom-dropdown-icons admin-submenus"
                                            // suffixcon={<i className="icon-drop-down text-primary" />}
                                            style={{marginLeft: "0px"}}
                                            suffixIcon={
                                                <CaretDownOutlined className="text-primary"/>
                                            }
                                            key="/admin/vendors"
                                            title={
                                                <div>
                                                    <i className="icon-vendors iconmargingright"/>
                                                    {formatMessage({
                                                        id: "component.sidemenu.vendors",
                                                    })}
                                                </div>
                                            }
                                        >
                                            <Menu.Item
                                                key="/admin/fotografer"
                                                className="submenuitem"
                                            >
                                                <Link to="/admin/fotografer">
                                                    {formatMessage({
                                                        id: "component.sidemenu.submenu.photographers",
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="/admin/takst" className="submenuitem">
                                                <Link to="/admin/takst">
                                                    {formatMessage({
                                                        id: "component.sidemenu.submenu.takst",
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="/admin/stylist" className="submenuitem">
                                                <Link to="/admin/stylist">
                                                    {formatMessage({
                                                        id: "component.sidemenu.submenu.stylist",
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                            {/* <Menu.Item key="/admin/undersøkelse" className="submenuitem">
                  <Link to="/admin/undersøkelse">
                    {formatMessage({
                      id: 'component.sidemenu.submenu.inspectionagent',
                    })}
                  </Link>
                </Menu.Item> */}
                                        </SubMenu>
                                        <Menu.Item
                                            className=" text-primary menuitemfonts"
                                            key="/admin/lånesøknad"
                                        >
                                            <Link to="/admin/lånesøknad">
                                                <i className="icon-marketing-material-print iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.loanapplication",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item
                                            className=" text-primary menuitemfonts"
                                            key="/admin/forsikring"
                                        >
                                            <Link to="/admin/forsikring">
                                                <i className="icon-offers-on-insurance iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.insurance",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item
                                            className=" text-primary menuitemfonts"
                                            key="/admin/ledigstilling"
                                        >
                                            <Link to="/admin/ledigstilling">
                                                <i className="icon-jobs iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.jobs",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>
                                        {/*<Menu.Item
                                            className="text-primary menuitemfonts"
                                            key="/admin/filbehandling"
                                        >
                                            <Link to="/admin/filbehandling">
                                                <i className="icon-content-management iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.contentmanagement",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>*/}

                                        <SubMenu style={{marginLeft: "0px"}}
                                                 className="menuitemfonts custom-dropdown-icons admin-submenus"
                                            // suffixcon={<i className="icon-drop-down text-primary" />}
                                                 suffixIcon={<CaretDownOutlined className="text-primary"/>}
                                                 key="/admin/homesettings"
                                                 title={
                                                     <div>
                                                         <i className="icon-superusers iconmargingright"/>
                                                         {formatMessage({
                                                             id: 'component.sidemenu.websiteedits',
                                                         })}
                                                     </div>
                                                 }
                                        >
                                            <Menu.Item key="/admin/homesettings" className="submenuitem">
                                                <Link to="/admin/homesettings">
                                                    {formatMessage({
                                                        id: 'component.sidemenu.homesettings',
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="/admin/metalisting" className="submenuitem">
                                                <Link to="/admin/metalisting">
                                                    {formatMessage({
                                                        id: 'component.sidemenu.metalisting',
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                        </SubMenu>

                                        <SubMenu
                                            className="menuitemfonts custom-dropdown-icons admin-submenus"
                                            style={{marginLeft: "0px"}}
                                            // suffixcon={<i className="icon-drop-down text-primary" />}
                                            suffixIcon={
                                                <CaretDownOutlined className="text-primary"/>
                                            }
                                            key="/admin/flytteveiledning"
                                            title={
                                                <div>
                                                    <i className="icon-moving-guide iconmargingright"/>
                                                    {formatMessage({
                                                        id: "component.sidemenu.movingguide",
                                                    })}
                                                </div>
                                            }
                                        >
                                            <Menu.Item
                                                key="/admin/fotografer"
                                                className="submenuitem"
                                            >
                                                <Link to="/admin/administrereleverandører">
                                                    {formatMessage({
                                                        id: "component.sidemenu.submenu.managevendors",
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="/admin/takst" className="submenuitem">
                                                <Link to="/admin/administrer-koblinger">
                                                    {formatMessage({
                                                        id: "component.sidemenu.submenu.managelinks",
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                        </SubMenu>
                                        <Menu.Item
                                            className=" text-primary menuitemfonts"
                                            key="/admin/annonserogpopups"
                                        >
                                            <Link to="/admin/annonserogpopups">
                                                <i className="icon-ads-popup iconmargingright"/>
                                                <span>
                          {formatMessage({
                              id: "component.sidemenu.adspopups",
                          })}
                        </span>
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                </Sider>
                            </Layout>
                        </p>
                    </Drawer>
                </div>
                <Layout className="d-none d-lg-block">
                    <Sider
                        width="280px"
                        className="admin-sider d-none d-lg-block"
                        collapsedWidth={0}
                        collapsible
                        collapsed={collapsed}
                    >
                        <Menu
                            mode="inline"
                            className="admin-sidemenu"
                            selectedKeys={[current]}
                            onClick={this.handleNavigation}
                        >
                            <Menu.Item key="/admin/dashboard" className="submenuitems">
                                <Link to="/admin/dashboard">
                                    <i className="icon-dashboard iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.dashboard",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/admin/kontorer" className="submenuitems">
                                <Link to="/admin/kontorer">
                                    <i className="icon-ds-property iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.offices",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/admin/nyheter" className="submenuitems">
                                <Link to="/admin/nyheter">
                                    <i className="icon-news iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.news",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/admin/faglig" className="submenuitems">
                                <Link to="/admin/faglig">
                                    <i className="icon-experienced-real-estate-agent iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.professional",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="/admin/allemeglere" className="submenuitems">
                                <Link to="/admin/allemeglere">
                                    <i className="icon-agents iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.agents",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>

                            {/* <SubMenu
                className="menuitemfonts custom-dropdown-icons admin-submenus"
                // suffixcon={<i className="icon-drop-down text-primary" />}
                suffixIcon={<CaretDownOutlined className="text-primary" />}
                key="/admin/agenter"
                title={
                  <div>
                    <i className="icon-agents iconmargingright" />
                    {formatMessage({
                      id: 'component.sidemenu.agents',
                    })}
                  </div>
                }
              >
                <Menu.Item key="/admin/alleagenter" className="submenuitem">
                  <Link to="/admin/alleagenter">
                    {formatMessage({
                      id: 'component.sidemenu.submenu.allagents',
                    })}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/admin/team" className="submenuitem">
                  <Link to="/admin/team">
                    {formatMessage({
                      id: 'component.sidemenu.submenu.team',
                    })}
                  </Link>
                </Menu.Item>
              </SubMenu> */}
                            {/* <Menu.Item key="/admin/vitec" className="submenuitems">
                <Link to="/admin/vitec">
                  <i className="icon-agents iconmargingright" />
                  <span>
                    {formatMessage({
                      id: 'component.sidemenu.vitecmangement',
                    })}
                  </span>
                </Link>
              </Menu.Item> */}
                            <SubMenu
                                className="menuitemfonts custom-dropdown-icons admin-submenus"
                                // suffixcon={<i className="icon-drop-down text-primary" />}
                                suffixIcon={<CaretDownOutlined className="text-primary"/>}
                                key="/admin/superusers"
                                title={
                                    <div>
                                        <i className="icon-superusers iconmargingright"/>
                                        {formatMessage({
                                            id: "component.sidemenu.superusers",
                                        })}
                                    </div>
                                }
                            >
                                <Menu.Item key="/admin/dagligleder" className="submenuitem">
                                    <Link to="/admin/dagligleder">
                                        {formatMessage({
                                            id: "component.sidemenu.submenu.officeceo",
                                        })}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/admin/fagansvarlig" className="submenuitem">
                                    <Link to="/admin/fagansvarlig">
                                        {formatMessage({
                                            id: "component.sidemenu.submenu.officecontroller",
                                        })}
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu
                                className="menuitemfonts custom-dropdown-icons admin-submenus"
                                // suffixcon={<i className="icon-drop-down text-primary" />}
                                suffixIcon={<CaretDownOutlined className="text-primary"/>}
                                key="/admin/vendors"
                                title={
                                    <div>
                                        <i className="icon-vendors iconmargingright"/>
                                        {formatMessage({
                                            id: "component.sidemenu.vendors",
                                        })}
                                    </div>
                                }
                            >
                                <Menu.Item key="/admin/fotografer" className="submenuitem">
                                    <Link to="/admin/fotografer">
                                        {formatMessage({
                                            id: "component.sidemenu.submenu.photographers",
                                        })}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/admin/takst" className="submenuitem">
                                    <Link to="/admin/takst">
                                        {formatMessage({
                                            id: "component.sidemenu.submenu.takst",
                                        })}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/admin/stylist" className="submenuitem">
                                    <Link to="/admin/stylist">
                                        {formatMessage({
                                            id: "component.sidemenu.submenu.stylist",
                                        })}
                                    </Link>
                                </Menu.Item>
                                {/* <Menu.Item key="/admin/undersøkelse" className="submenuitem">
                  <Link to="/admin/undersøkelse">
                    {formatMessage({
                      id: 'component.sidemenu.submenu.inspectionagent',
                    })}
                  </Link>
                </Menu.Item> */}
                            </SubMenu>
                            <Menu.Item
                                className=" text-primary menuitemfonts"
                                key="/admin/lånesøknad"
                            >
                                <Link to="/admin/lånesøknad">
                                    <i className="icon-marketing-material-print iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.loanapplication",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item
                                className=" text-primary menuitemfonts"
                                key="/admin/forsikring"
                            >
                                <Link to="/admin/forsikring">
                                    <i className="icon-offers-on-insurance iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.insurance",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item
                                className=" text-primary menuitemfonts"
                                key="/admin/ledigstilling"
                            >
                                <Link to="/admin/ledigstilling">
                                    <i className="icon-jobs iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.jobs",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>
                            <SubMenu
                                className="menuitemfonts custom-dropdown-icons admin-submenus"
                                // suffixcon={<i className="icon-drop-down text-primary" />}
                                suffixIcon={<CaretDownOutlined className="text-primary"/>}
                                key="/admin/homesettings"
                                title={
                                    <div>
                                        <i className="icon-superusers iconmargingright"/>
                                        {formatMessage({
                                            id: "component.sidemenu.websiteedits",
                                        })}
                                    </div>
                                }
                            >
                                <Menu.Item key="/admin/homesettings" className="submenuitem">
                                    <Link to="/admin/homesettings">
                                        {formatMessage({
                                            id: "component.sidemenu.homesettings",
                                        })}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/admin/metalisting" className="submenuitem">
                                    <Link to="/admin/metalisting">
                                        {formatMessage({
                                            id: "component.sidemenu.metalisting",
                                        })}
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item
                                className="text-primary menuitemfonts"
                                key="/admin/filbehandling"
                            >
                                <Link to="/admin/filbehandling">
                                    <i className="icon-content-management iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.contentmanagement",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>
                            <SubMenu
                                className="menuitemfonts custom-dropdown-icons admin-submenus"
                                // suffixcon={<i className="icon-drop-down text-primary" />}
                                suffixIcon={<CaretDownOutlined className="text-primary"/>}
                                key="/admin/flytteveiledning"
                                title={
                                    <div>
                                        <i className="/ iconmargingright"/>
                                        {formatMessage({
                                            id: "component.sidemenu.movingguide",
                                        })}
                                    </div>
                                }
                            >
                                <Menu.Item key="/admin/fotografer" className="submenuitem">
                                    <Link to="/admin/administrereleverandører">
                                        {formatMessage({
                                            id: "component.sidemenu.submenu.managevendors",
                                        })}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/admin/takst" className="submenuitem">
                                    <Link to="/admin/administrer-koblinger">
                                        {formatMessage({
                                            id: "component.sidemenu.submenu.managelinks",
                                        })}
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item
                                className=" text-primary menuitemfonts"
                                key="/admin/annonserogpopups"
                            >
                                <Link to="/admin/annonserogpopups">
                                    <i className="icon-ads-popup iconmargingright"/>
                                    <span>
                    {formatMessage({
                        id: "component.sidemenu.adspopups",
                    })}
                  </span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Content>{children}</Content>
                </Layout>
            </>
        );
    }
}

export default withRouter(AdminSidebar);
