import gql from 'graphql-tag';


export const TAKST_UPDATE_PROFILE = gql`
mutation updateTakstProfile($input:UpdateTakstProfileInput){
  updatetakstprofile(input:$input){
    user {
      id
      user_type_id
      first_name
      last_name
      email
      title
      address
      mobile
      profile_image
      city
      zipcode
      status

      access_level

    }
  }
}`;

export const TAKST_UPDATE_ORDER_LIST = gql`
 mutation update_order_list($input: OrderTaskUpdateInput) {
  update_order_list(input: $input) {
    orderrate {
      id
      property_id
      takst_id
      order_date
      order_status
      earliest_start_time
      latest_start_time
      comment
      property {
        orderrate {
          id
        }
        order_rate_detail {
          id
          property_id
          order_rate_id
          tax_date
          construction_year
          value_rate
          bta
          loan_rate
          bra
          p_rom
          document {
            id
            property_id
          }
          comments {
            id
            comment
          }
        }
        oppdrags_nummer
        street_adress
        city
        zipcode
        seller {
          id
          first_name
          last_name
          email
          mobile
        }
      }
    }
  }
}`;

export const TAKST_ADD_APPRAISER = gql`
  mutation order_rate_detail($input: OrderRateDetailInput!) {
    order_rate_detail(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
