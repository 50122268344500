import React, {useEffect, useRef, useState} from 'react';
//Components
import {ActivityIndicator, TouchableOpacity, View} from 'react-native'
import {CustomButton, DropDown, Hr, InputField, Label, PopOver, RadioGroup, Toast} from "components/src/components";
//Utils
import {Color, CommonStyle, Constants, Icon, showMessage, Strings, ThemeUtils, validation} from "components/src/utils";
import styles from "./styles";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {BUYER_BANK_CONTACT_INFO, ORIGIN_EQUITY, PURPOSE_OF_PURCHASE} from "../../../../../api/buyerQuery";
import {connect} from "react-redux";
import {BUYER_UPDATE_BANK_INFO} from "../../../../../api/buyerMutation";
import {Query} from "@apollo/react-components";

const BANK_INPUTS = [{
    id: Constants.FieldId.BANK_NAME,
    title: Strings.nameOfBank,
    placeholder: Strings.nameOfBank,
    type: 'text',
    validationType: 'name'
}, {
    id: Constants.FieldId.BANK_CONTACT,
    title: Strings.contactBank,
    placeholder: Strings.contactBank,
    type: 'text',
    validationType: 'name'
}, {
    id: Constants.FieldId.EMAIL,
    title: Strings.Email,
    placeholder: Strings.Email,
    type: 'email',
    validationType: 'email'
}, {
    id: Constants.FieldId.PHONE,
    title: Strings.Phone,
    placeholder: Strings.Phone,
    type: 'number',
    validationType: 'phoneNo'
}/*, {
    id: Constants.FieldId.ACCOUNT_NO,
    title: Strings.accountNo,
    placeholder: Strings.accountNo,
    type: 'text',
    validationType: 'accountnofortransfer'
}*/];


let toastRef;
function ContactInfoBank(props) {
    const {property} = props;
    let ref = useRef();
    const [isVisible, setPopOverVisible] = useState(false);
    const [desc, setDesc] = useState("");
    const [popOverRef, setFromView] = useState();

    const [banks, setBanks] = useState([]);
    const [values, setValues] = useState([{}]);
    const [errors, setErrors] = useState([{}]);

    const [equity, setEquity] = useState('')
    const [errEquity, setErrEquity] = useState(null)

    const [otherOrigin, setOtherOriginEquity] = useState("")
    const [errOtherOrigin, setErrOtherOriginEquity] = useState(null)

    const [otherPurpose, setOtherPurpose] = useState("")
    const [errOtherPurpose, setErrOtherPurpose] = useState(null)

    const [originEquity, setOriginEquity] = useState('')
    const [isNeedLoadWithMortage, setLoanWithMortage] = useState("Ja")

    const [purchasePurpose, setPurposeOfPurchase] = useState('')
    const [politicPerson, setIsPoliticPersion] = useState('Nei')
    const [possibleReceivable, setPossibleReceivable] = useState('')
    const [errPossibleReceivable, setErrPossibleReceivable] = useState('')

    const {loading, error, data} = useQuery(BUYER_BANK_CONTACT_INFO, {
        variables: {
            id: property?.id,
            status: 1
        }
    });
    console.log(error,"ERR")
    const [updateBankInfo, updateBankInfoMutation] = useMutation(BUYER_UPDATE_BANK_INFO)

    useEffect(() => {
        console.log(data,"DATA")
        if (data?.buyer_bank_contact_info) {
            setLoanWithMortage(data?.buyer_bank_contact_info?.pant_boligen ? "Ja" : "Nei");
            setEquity(data?.buyer_bank_contact_info?.agencapital);
            setOriginEquity(data?.buyer_bank_contact_info?.opparinelse_id);
            if (data?.buyer_bank_contact_info?.opparinelse_id === 4) {
                setOtherOriginEquity(data?.buyer_bank_contact_info?.opparinelse_text)
            }
            setIsPoliticPersion(data?.buyer_bank_contact_info?.pep_status ? "Ja" : "Nei");
            setPurposeOfPurchase(data?.buyer_bank_contact_info?.purpose_of_purchase_id);
            if (data?.buyer_bank_contact_info?.purpose_of_purchase_id === 4) {
                setOtherPurpose(data?.buyer_bank_contact_info?.purchase_text)
            }
            setPossibleReceivable(data?.buyer_bank_contact_info?.account_number)
            if (data?.buyer_bank_contact_info?.pant_boligen) {
                let updatedBanks = data?.buyer_bank_contact_info?.buyerBankInfo?.length ? new Array(data?.buyer_bank_contact_info?.buyerBankInfo?.length - 1).fill(BANK_INPUTS) : [];
                let updatedValues = data?.buyer_bank_contact_info?.buyerBankInfo.map(item => ({
                    [Constants.FieldId.BANK_NAME]: item?.bank_name,
                    [Constants.FieldId.BANK_CONTACT]: item?.bank_contact,
                    [Constants.FieldId.EMAIL]: item?.bank_email,
                    [Constants.FieldId.PHONE]: item?.bank_mobile,
                }))
                let updatedErrors = data?.buyer_bank_contact_info?.buyerBankInfo.map(item => ({
                    [Constants.FieldId.BANK_NAME]: null,
                    [Constants.FieldId.BANK_CONTACT]: null,
                    [Constants.FieldId.EMAIL]: null,
                    [Constants.FieldId.PHONE]: null,
                }))
                setValues(updatedValues)
                // setBanks(updatedBanks)
                setErrors(updatedErrors)
                setTimeout(() => {
                    setBanks(updatedBanks)
                })
            }


        }
    }, [data])


    const addAdditionalBank = () => {
        const updatedBank = banks.slice();
        const updatedValue = values.slice();
        const updatedError = errors.slice();
        updatedValue.push({});
        updatedError.push({});
        setValues(updatedValue)
        setErrors(updatedError)

        updatedBank.push(BANK_INPUTS);
        setBanks(updatedBank)
    };

    const handleChangeText = (text, fieldId, parentIndex) => {
        let updatedValues = values.slice();
        updatedValues[parentIndex] = {
            ...updatedValues[parentIndex],
            [fieldId]: text.split(" ").join("")
        };
        setValues(updatedValues)
    };

    const onFocus = (id, parentIndex) => {
        let updatedErrors = errors.slice();
        updatedErrors[parentIndex][id] = null;
        setErrors(updatedErrors)
    };

    const saveBankInfo = () => {
        /*const updatedErrors = errors.slice();
        let isError = false;
        values.map((bankInfo, parentIndex) => {
            for (let i = 0; i < BANK_INPUTS.length; i++) {
                let item = BANK_INPUTS[i];
                let value = bankInfo[item.id] ? bankInfo[item.id].trim() : '';
                if (item.id === Constants.FieldId.PHONE_EMAIL) {
                    updatedErrors[parentIndex][item.id] = validation(isNumeric(value) ? 'phoneNo' : 'email', value);
                } else {
                    updatedErrors[parentIndex][item.id] = validation(item.validationType, value);
                }
                if (updatedErrors[parentIndex][item.id]) {
                    isError = true;
                }
            }
        });*/
        const updatedErrors = errors.slice();
        let isError = false
        if (isNeedLoadWithMortage === "Ja") {
            values.map((bankInfo, parentIndex) => {
                for (let i = 0; i < BANK_INPUTS.length; i++) {
                    let item = BANK_INPUTS[i];
                    let value = bankInfo[item.id] ? bankInfo[item.id].trim() : '';
                    updatedErrors[parentIndex][item.id] = validation(item.validationType, value);
                    if (updatedErrors[parentIndex][item.id]) {
                        isError = true;
                    }
                }
            });
        }

        let updatedErrQuity = validation('equity', equity);
        let updatedOriginErrEquity;
        if (originEquity === 4) {
            updatedOriginErrEquity = validation('originEquity', otherOrigin);
        } else {
            updatedOriginErrEquity = validation('originEquity', originEquity);

        }
        let updateErrPurposeOfPurchase;
        if (purchasePurpose === 4) {
            updateErrPurposeOfPurchase = validation('purposeOfPurchase', otherPurpose)
        } else {
            updateErrPurposeOfPurchase = validation('purposeOfPurchase', purchasePurpose);
        }
        let updatedErrPossibleReceivable = validation('receivables', possibleReceivable)

        if (updatedErrQuity || updatedOriginErrEquity || updateErrPurposeOfPurchase || updatedErrPossibleReceivable) {
            isError = true
        }
        if (isError) {
            setErrEquity(updatedErrQuity)
            setErrOtherOriginEquity(updatedOriginErrEquity)
            setErrOtherPurpose(updateErrPurposeOfPurchase)
            setErrPossibleReceivable(updatedErrPossibleReceivable)
            if (purchasePurpose === 4) {
                showMessage(updateErrPurposeOfPurchase,Constants.MessageType.FAILED)
                if (originEquity === 4) {
                    showMessage(updatedOriginErrEquity,Constants.MessageType.FAILED)
                }
            }
            setErrors(updatedErrors)
        } else {

            let bankInfoRequest = {
                agencapital: equity,
                opparinelse_id: originEquity,
                pant_boligen: isNeedLoadWithMortage === "Ja" ? 1 : 0,
                pep_status: politicPerson === "Ja" ? 1 : 0,
                purpose_of_purchase_id: purchasePurpose,
                account_number: possibleReceivable,
            }
            if (originEquity === 4) {
                bankInfoRequest.opparinelse_text = otherOrigin
            }
            if (purchasePurpose === 4) {
                bankInfoRequest.purchase_text = otherPurpose
            }
            if (isNeedLoadWithMortage === "Ja") {
                bankInfoRequest.bank_details = [{
                    bank_name: values[0][Constants.FieldId.BANK_NAME],
                    bank_contact: values[0][Constants.FieldId.BANK_CONTACT],
                    bank_email: values[0][Constants.FieldId.EMAIL],
                    bank_mobile: values[0][Constants.FieldId.PHONE]
                }, ...banks.map(item => ({
                    bank_name: item[Constants.FieldId.BANK_NAME],
                    bank_contact: item[Constants.FieldId.BANK_CONTACT],
                    bank_email: values[0][Constants.FieldId.EMAIL],
                    bank_mobile: values[0][Constants.FieldId.PHONE]
                }))]
            }

            updateBankInfo({
                variables: {
                    input: {
                        prop_buyer_info_id: props.property?.id,
                        bank_info: bankInfoRequest
                    }
                }
            }).then((data)=>{
                showMessage(data?.data?.update_buyer_bank_info?.meta?.message,Constants.MessageType.SUCCESS)
            }).catch(() => {

            })
        }
    }
    const removeBank = (index) => {
        const updatedBank = banks.slice();
        const updatedValue = values.slice();
        const updatedError = errors.slice();
        updatedValue.splice(index + 1, 1);
        updatedError.splice(index + 1, 1);
        setValues(updatedValue)
        setErrors(updatedError)
        updatedBank.splice(index, 1);
        setBanks(updatedBank)
    };


    const renderAdditionalBank = (item, parentIndex) => {
        return (
            <View key={parentIndex.toString()}>
                <Hr/>
                <View style={styles.bankLblCont}>
                    <Label small
                           style={CommonStyle.flex}
                           font_medium>
                        {`${Strings.additionalBank} ${parentIndex + 2} :`}
                    </Label>
                    <TouchableOpacity activeOpacity={0.5}
                                      onPress={() => removeBank(parentIndex)}>
                        <Icon name={'order-cancel'}
                              size={ThemeUtils.fontNormal}
                              color={Color.RED}/>
                    </TouchableOpacity>
                </View>
                {item.map((item, index) => renderBankForm(item, index, parentIndex + 1))}
            </View>
        )
    }

    const renderBankForm = (item, index, parentIndex) => {
        return (
            <InputField key={item.id.toString()}
                        textArea={item.type === 'textArea'}
                        type={item.type}
                        labelProps={{
                            small: true,
                            font_medium: true,
                        }}
                        style={{marginTop: 0}}
                        disabled={item.disabled}
                        onChange={(text) => handleChangeText(text, item.id, parentIndex)}
                        onFocus={() => onFocus(item.id, parentIndex)}
                        error={errors[parentIndex][item.id]}
                        value={values[parentIndex][item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };

    return (
        <View style={styles.stepCont}>
            <Toast ref={r => toastRef = r}/>
            <PopOver isVisible={isVisible}
                     fromView={popOverRef}

                     onRequestClose={() => setPopOverVisible(false)}>
                <View style={{paddingHorizontal: 10}}>

                    <Label small mb={10} mt={10}>
                        {desc}
                    </Label>
                </View>
            </PopOver>
            <Label small
                   mt={15}>
                {"Her finner du første utkast til kjøpekontrakt. Kontraktene er standard kontrakter til bruk av kjøp og salg av bolig. Les gjennom for å sjekke at all informasjon er korrekt. Har du kommentarer skriver du de nedenfor."}
            </Label>
            {loading ? (<ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>) : (
                <>

                    <View>
                        <Label font_medium
                               mt={ThemeUtils.relativeRealHeight(1)}
                               mb={ThemeUtils.relativeRealHeight(2)}>
                            {"Finansiering"}
                        </Label>
                        <InputField labelText={"Bank for egenkapital"}
                                    onChange={setEquity}
                                    value={equity}
                                    error={errEquity}
                                    placeholder={"Bank for egenkapital"}/>
                        <Query query={ORIGIN_EQUITY}
                               variables={{
                                   status: 1
                               }}>
                            {({loading, data, error}) => {

                                if (loading) {
                                    return (
                                        <ActivityIndicator color={Color.PRIMARY}/>
                                    )
                                }
                                return (
                                    <DropDown changeOnProp
                                              title={"Opprinnelse egenkapital"}
                                              onSelectOptions={setOriginEquity}
                                              selectedOption={originEquity}
                                              options={data?.opprinnelse_egenkapital_option}/>
                                )
                            }}
                        </Query>
                        {originEquity === 4 ? (
                            <InputField mt={ThemeUtils.relativeRealHeight(2)}
                                        onChange={setOtherOriginEquity}
                                        value={otherOrigin}
                                        error={errOtherOrigin}
                                        placeholder={"Opprinnelse egenkapital"}/>) : null}
                        <Label font_medium
                               mt={ThemeUtils.relativeRealHeight(2)}
                               mb={ThemeUtils.relativeRealHeight(2)}>
                            {"Lån / Pant i boligen"}
                        </Label>
                        <Label small
                               font_medium>
                            {"Skal du ha lån med pant i boligen?"}
                        </Label>

                        <RadioGroup changeOnProp
                                    options={Constants.RADIO_OPTIONS}
                                    onSelectOption={setLoanWithMortage}
                                    contentStyle={{
                                        flexDirection: 'row',
                                        marginTop: ThemeUtils.relativeRealHeight(1),
                                        marginBottom: ThemeUtils.relativeRealHeight(2)
                                    }}
                                    selectedOptions={isNeedLoadWithMortage}
                        />
                    </View>

                    {isNeedLoadWithMortage === "Ja" ? (<View>
                        <Label small
                               font_medium
                               mt={10}
                               mb={15}>
                            {Strings.bankDetails}
                        </Label>
                        {BANK_INPUTS.map((item, index) => renderBankForm(item, index, 0))}
                        {banks.length !== 0 ? (
                            <>
                                {banks.map(renderAdditionalBank)}
                            </>
                        ) : null}
                        <CustomButton mb={10}
                                      mt={10}
                                      title={"Jeg/vi har flere lån"}
                                      onPress={addAdditionalBank}/>
                        <Hr/>
                    </View>) : null}


                    <View style={styles.lblCont}>
                        <Label font_medium
                               mt={ThemeUtils.relativeRealHeight(1)}
                               mb={ThemeUtils.relativeRealHeight(1)}
                               mr={10}>
                            {"Politisk eksponert person (PEP)"}
                        </Label>
                        <TouchableOpacity ref={r => ref = r}
                                          onPress={() => {
                                              setDesc("En politisk eksponert person (PEP) er en fysisk person som er i, eller har hatt, et høytstående offentlig verv eller stilling.")
                                              setFromView(ref)
                                              setPopOverVisible(true)
                                          }}>
                            <Icon name={'faq'}
                                  size={ThemeUtils.fontSmall}
                                  color={Color.PRIMARY}/>
                        </TouchableOpacity>
                    </View>
                    <Label small
                           font_medium>
                        {"Er du/dere en politisk(e) eksponert person(er)?"}
                    </Label>
                    <RadioGroup changeOnProp
                                options={Constants.RADIO_OPTIONS}
                                contentStyle={{
                                    flexDirection: 'row',
                                    marginVertical: ThemeUtils.relativeRealHeight(1)
                                }}
                                selectedOptions={politicPerson}
                                onSelectOption={setIsPoliticPersion}
                    />
                    <Label font_medium
                           mt={ThemeUtils.relativeRealHeight(1)}
                           mb={ThemeUtils.relativeRealHeight(1)}>
                        {"Formål med kjøp"}
                    </Label>

                    <Query query={PURPOSE_OF_PURCHASE}
                           variables={{
                               status: 1
                           }}>
                        {({loading, data, error}) => {

                            if (loading) {
                                return (
                                    <ActivityIndicator color={Color.PRIMARY}/>
                                )
                            }
                            return (
                                <DropDown style={{
                                    marginBottom: ThemeUtils.relativeRealHeight(2)
                                }}
                                          selectedOption={purchasePurpose}
                                          onSelectOptions={setPurposeOfPurchase}
                                          options={data?.purpose_purchase_option ?? []}/>
                            )
                        }}
                    </Query>

                    {purchasePurpose === 4 ? (
                        <InputField onChange={setOtherPurpose}
                                    value={otherPurpose}
                                    error={errOtherPurpose}
                                    placeholder={"Formål med kjøp"}/>) : null}
                    <Label font_medium
                           mb={ThemeUtils.relativeRealHeight(1)}>
                        {"Eventuelt tilgodehavende"}
                    </Label>
                    <InputField labelText={"Overføres til kontonummer"}
                                type={"number"}
                                onChange={setPossibleReceivable}
                                error={errPossibleReceivable}
                                value={possibleReceivable}
                                placeholder={"Overføres til kontonummer"}/>
                    <CustomButton mb={10}
                                  mt={10}
                                  loading={updateBankInfoMutation.loading}
                                  title={Strings.Save}
                                  onPress={saveBankInfo}/>
                </>
            )}

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(ContactInfoBank);
