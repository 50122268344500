/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import {LoadingOutlined} from '@ant-design/icons';
import {Col, Row} from 'antd';
import React from 'react';
import {Query} from 'react-apollo';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {DS_DETAIL_PAGE} from '../../../query/AgentQuery';
import Resale from './digitalSales/index';
import ProjectMaster from './digitalSalesNewDev';
import ProjectUnit from './digitalSalesUnit';
import UserHeader from '../../layouts/UserLayout'
import './digitalsales.scss';

const DigitalSales = props => {
    let router=props.history;
    const isContinue = !!(props.match.params && props.match.params.id && props.match.params.id);
    if (!isContinue) {
        router.replace('/');
    }

    const oppdragsnummer = isContinue ? props.match.params.id : '';

    return (
        <UserHeader>
            <Row>
                <Col xs={24}>
                    <div className="ds-wrapper">
                        <Query
                            query={DS_DETAIL_PAGE}
                            variables={{
                                oppdragsnummer,
                                is_live_request: false,
                            }}
                        >
                            {({data, loading, error}) => {
                                console.log(data,loading,error)
                                if (data && !loading) {
                                    if (data?.ds_property_detail) {
                                        console.log(
                                            'data?.ds_property_detail?.projectRelation',
                                            data?.ds_property_detail?.projectRelation,
                                        );
                                        if (data?.ds_property_detail?.projectRelation === 3) {
                                            return <Resale {...props} loading={loading} error={error} data={data}/>;
                                        }
                                        if (data?.ds_property_detail?.projectRelation === 1) {
                                            return <ProjectMaster {...props} loading={loading} error={error}
                                                                  data={data}/>;
                                        }
                                        if (data?.ds_property_detail?.projectRelation === 2) {
                                            return <ProjectUnit {...props} loading={loading} error={error}
                                                                data={data}/>;
                                        }
                                    }
                                    window.location = 'https://weiendomsmegling.no/';
                                    // router.replace('https://weiendomsmegling.no/')
                                }
                                return (
                                    <div className="loader-wrapper">
                                        <LoadingOutlined/>
                                    </div>
                                );
                            }}
                        </Query>
                    </div>
                </Col>
            </Row>
        </UserHeader>
    );
};

export default DigitalSales;
