import React, {useEffect, useState} from 'react'
//Component
import {Dashboard, Dialog} from "components/src/components";
//Utils
import {IS_WEB, Routes, Strings} from 'components/src/utils'
import salesInfo from 'components/src/image/SalesInformation.png'
import settlementInfo from 'components/src/image/SettlementInformation.png'
import yourFeedback from 'components/src/image/YourFeedback.png'
import financing from 'components/src/image/Rate.jpg'
import movingGuide from 'components/src/image/MovingGuide.jpg'
import takeover from 'components/src/image/Takeover.png'
import purchasingContract from "../../../../image/SettlementInformation.png";
import {connect} from "react-redux";

const DASHBOARD = [{
    name: Strings.salesinformation,
    icon: 'sales-information',
    bg: salesInfo,
    order: 1,
    text: "Her finner du alle detaljer rundt handelen som er inngått, f.eks pris og overtagelsesdato, samt navn og informasjon på kjøper."
}, {
    name: Strings.Settlementinformation,
    icon: 'settlement-information',
    bg: settlementInfo,
    order: 2,
    text: "Når boligen din er solgt trenger vi oppgjørsinformasjon for å kunne forberede oppgjøret og utbetaling av salgssummen. Vi ber deg fylle det inn så fort boligen er solgt."
},/* {
    name: Strings.purchasingContract,
    icon: 'settlement-information',
    bg: purchasingContract,
    // disabled:true,
},*/ {
    name: Strings.yourFeedback,
    icon: 'edit',
    bg: yourFeedback,
    order: 3,
    text: "Hvordan har du opplevd salgsprosessen? Vi vil veldig gjerne høre din tilbakemelding. Både ris og ros er med på å gjøre oss bedre."
}, {
    name: Strings.FinancingTitle,
    icon: 'rate',
    bg: financing,
    order: 4,
    text: "Du skal snart flytte inn i ny bolig, om du ikke allerede har gjort det. Dersom du har eller trenger boliglån - få et uforpliktende tilbud. Kanskje kan det være mange tusen å spare."
}, {
    name: Strings.movingGuide,
    icon: 'moving-guide',
    bg: movingGuide,
    order: 5,
    text: "Vi har laget en liten guide og en sjekkliste for å hjelpe deg med flytteprosessen."
}, {
    name: Strings.takeover,
    icon: 'takeover',
    bg: takeover,
    disabled: true,
    order: 6,
    text: "Endelig er det tid for overtagelse og salgsprosessen nærmer seg slutten. Her vil du bli videresendt til en digital overtagelsesprotokoll du skal benytte på overtagelsen."
}];

function ContractSettlements(props) {
    const {navigation, history} = props;
    const [soldSectionMessage, setSoldSectionMessage] = useState("")
    const [soldSectionVisible, setSectionVisible] = useState(false)

    useEffect(() => {
        if (!soldSectionVisible) {
            setSoldSectionMessage("")
        }
    }, [soldSectionVisible]);

    const handleItemPress = (name) => {
        let route = null
        switch (name) {
            case Strings.salesinformation:
                if (props.property?.property_buyer_info) {
                    route = Routes.SalesInformation
                } else {
                    setSoldSectionMessage("Salgsinformasjon blir tilgjengelig straks boligen din er solgt.")
                    setSectionVisible(true)
                }

                break;
            case Strings.Settlementinformation:
                if (props.property?.property_buyer_info) {
                    route = Routes.SettlementInfo;
                } else {
                    setSoldSectionMessage("Property not sold.")
                    setSectionVisible(true)
                }
                break;
            case Strings.purchasingContract:
                if (props.property?.property_buyer_info && props.property?.buyerContractDoc) {
                    route = Routes.SellerPurchasingContract
                } else {
                    setSoldSectionMessage("Utkast kjøpekontrakt blir tilgjengelig straks kjøper og selger har fylt ut nødvendige opplysninger etter at boligen er solgt.")
                    setSectionVisible(true)
                }

                break;
            case Strings.yourFeedback:
                route = Routes.YourFeedBack
                break;
            case Strings.FinancingTitle:
                route = Routes.Financing
                break;
            case Strings.movingGuide:
                route = Routes.SellerMovingGuide
                break;
            case Strings.takeover:
                route = Routes.TakeOver
                break;

        }
        if (route) {
            if (IS_WEB) {
                history.push(route)
            } else {
                navigation.navigate(route)
            }
        }
    }
    return (
        <>
            <Dialog visible={soldSectionVisible}
                    onPositivePress={() => {
                        setSectionVisible(false)
                    }}
                    buttonTitleSecond={Strings.okay}
                    desc={soldSectionMessage}/>
            <Dashboard dashboardData={DASHBOARD}
                       place={'CONTRACT'}
                       onPressItem={handleItemPress}/>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(ContractSettlements);
