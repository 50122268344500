import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({

    toggleBtnCont: {
        flexDirection: 'row',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: Color.PRIMARY_BG_COLOR,
        flex: 1,
        margin: 10
    },
    btnToggle: {
        flex: 1,
        paddingVertical: 10,
    },
    bgView: {
        backgroundColor: Color.PRIMARY_BG_COLOR,
        position: 'absolute',
        top: 0,
        width: '50%',

        borderRadius: 4,
        bottom: 0
    },
    cont: {
        flex: 1,

    },
    drawerHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 20
    },
    drawerItemCont: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        paddingHorizontal: ThemeUtils.relativeWidth(8)
    },
    accordionHeaderCont: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    accordionContainer: {
        backgroundColor: Color.WHITE,
        paddingVertical: ThemeUtils.relativeRealHeight(1),
        paddingHorizontal: 0,
    },
    accordionItem: {
        paddingVertical: 10
    },
    selectedAccordionItem: {
        backgroundColor: Color.GRAY
    },
    accordionHeader: {
        borderRadius: 0,
        backgroundColor: Color.PRIMARY,
        paddingHorizontal: 0,
        paddingEnd: ThemeUtils.relativeWidth(8),
        paddingVertical: 0,
    },
    txtRegular: {
        fontFamily: 'Maax'
    },
    introModalBtnCont: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    modal: {
        flex: 1,
        backgroundColor: Color.PRIMARY_TRANSPARENT,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        justifyContent: 'center',
    },
    modalCont: {
        backgroundColor: Color.WHITE,
        paddingTop: 15,
        paddingHorizontal: 15,
        borderRadius: 3,
        overflow: 'hidden',
    },
    btnCont: {
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: ThemeUtils.relativeRealHeight(1),
        marginVertical: 10,
    },
    btn: {
        backgroundColor: Color.PRIMARY,
        borderRadius: 4,
        marginStart: ThemeUtils.relativeRealWidth(2),
    },
    popOverCont: {
        padding: 8,
    },
    popOverLblCont: {
        padding: 8
    }
})
