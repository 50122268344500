/* eslint-disable no-shadow */
import {CaretDownFilled, LoadingOutlined, UploadOutlined,} from '@ant-design/icons';
import {Button, Checkbox, Col, Form, Input, Modal, notification, Row, Select, Upload} from 'antd';
import BraftEditor from 'braft-editor';
import React, {useEffect, useRef, useState} from 'react';

import {useHistory} from "react-router";
import {Mutation,useMutation, Query} from 'react-apollo';
import {
    ADMIN_ACTIVE_INACTIVE_AGENT,
    ADMIN_DELETE_AGENT_CV,
    ADMIN_DELETE_AGENT_DOC,
    ADMIN_UPDATE_SUPER_USER,
} from '../../../../../../../../mutation/AdminMutation';
import {ADMIN_OFFICES_LIST_ONLY} from '../../../../../../../../query/AdminQuery';
import {STORAGE_URL} from '../../../../../../../utils/constant';
import {formatMessage} from "components/src/utils";

const {TextArea} = Input;

// const props = {
//   showUploadList: {
//     showDownloadIcon: false,
//     downloadIcon: <DownloadOutlined />,
//     showRemoveIcon: true,
//     removeIcon: <i className="icon-delete" />,
//   },
// };

// const uploadButton = (
//   <div>
//     <PlusOutlined />
//     <div className="ant-upload-text">Upload</div>
//   </div>H
// );
let formData;
const OfficeEditForm = props => {
    let router=useHistory();
    const { fullObject } = props;
    formData = fullObject;
    console.log('inside edit office details=>', props.fullObject);
    const [imageUrl, setimageUrl] = useState(`${STORAGE_URL}${fullObject.profile_image}`);
    const [imageObject, setimageObject] = useState(`${STORAGE_URL}${fullObject.profile_image}`);
    const [isimageUrlChanged, setisimageUrlChanged] = useState(false);

    const cvLink = [];
    const docLink = [];
    if (fullObject.user_detail && fullObject.user_detail.cv_url) {
        cvLink.push({
            uid: 1,
            name: 'CV',
            media_url: fullObject.user_detail.cv_url,
        });
    }
    if (fullObject.user_document && fullObject.user_document.length > 0) {
        fullObject.user_document.forEach(item => {
            docLink.push({
                uid: item.id,
                name: `Doc-${item.id}`,
                media_url: item.doc_url,
            });
        });
    }

    const [displayCv, setDisplayCv] = useState(cvLink);
    const [displayOtherDoc, setDisplayOtherDoc] = useState(docLink);
    const [editorState, seteditorState] = useState(fullObject?.user_detail?.about);
    const [deleteModal, setdeleteModal] = useState(false);
    const [isInAdminOffice, setisInAdminOffice] = useState(fullObject.is_in_admin_office);

    // const fileRef = useRef(null);
    const formRef = useRef(null);

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    useEffect(() => {
        if (formRef.current !== null) {
            formRef.current.setFieldsValue({
                summary: BraftEditor.createEditorState(fullObject && fullObject?.user_detail?.about),
            });
        }

    }, []);

    const submitSummaryContent = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const htmlContent = editorState.toHTML();
    };

    const handleEditorChange = editor => {
        seteditorState(editor);
    };

    const onCheckboxChange = e => {

        setisInAdminOffice(!isInAdminOffice);
    };

    const onDelete = (deleteID, deletUser) => {
        console.log('delete id ==>', deleteID);
        deletUser({
            variables: {
                id: deleteID,
            },
        }).then(data => {
            console.log('Data submitted===>>>', data);
            if (data && data.active_inactive_agent) {
                notification.success({
                    message: `${data.active_inactive_agent?.first_name} slettet`,
                    description: '',
                    duration: 3,
                });
            }
            router.push('/admin/fagansvarlig');
        });
    };

    const onFinish = (values, updateSuperUser) => {
        console.log('Success:', values);

        const otherDocFileList = [];
        if (displayOtherDoc && displayOtherDoc.length > 0) {
            displayOtherDoc.forEach(item => {
                if (item.originFileObj) {
                    otherDocFileList.push(item.originFileObj);
                }
            });
        }

        const variables = {
            id: fullObject.id,
            profile_image: isimageUrlChanged ? imageObject : null,
            first_name: values.firstname,
            last_name: values.lastname,
            office_id: values.office,
            email: values.email,
            title: values.title,
            is_in_admin_office: isInAdminOffice,
        };

        if (values.phone) {
            variables.mobile = values.phone;
        }

        if (values.education) {
            variables.education = values.education;
        }

        if (values.summary) {
            variables.about = editorState.toHTML();
        }

        if (values.uploadcv) {
            variables.cv = displayCv && displayCv.length > 0 && displayCv[0].originFileObj;
        }

        if (values.uploadotherdoc) {
            variables.other_doc = otherDocFileList;
        }
        // eslint-disable-next-line no-unused-expressions
        updateSuperUser({
            variables,

        }).then(({ data }) => {
            console.log('Data submitted===>>>', data);
            if (data && data.update_super_user) {
                notification.success({
                    message: `Innspillingen ble oppdatert`,
                    description: '',
                    duration: 3,
                });
                router.push(`/admin/fagansvarlig`);
            }
        });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const handleUploadChange = info => {
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, image => setimageUrl(image));
            setimageObject(info.file.originFileObj);
            setisimageUrlChanged(true);

        }
    };

    const handleCvUpload = e => {
        const file = [];
        file.push(e.file);
        console.log('CV File:', file);
        setDisplayCv(file);
    };
    const removeCvUpload = () => {
        setDisplayCv([]);
    };

    const handleOtherDoc = e => {
        console.log('e-->', e);
        console.log('displayOtherDoc on chnagee', displayOtherDoc);
        const file = [];
        file.push(e.file);
        console.log('UPLOAD OTHER DOC:', file);
        setDisplayOtherDoc(file);
    };


    return (
        <>
            <Row>
                <Col xs={24}>
                    <Mutation mutation={ADMIN_UPDATE_SUPER_USER}>
                        {(updateSuperUser, { loading }) => {
                            return (
                                <Form
                                    ref={formRef}
                                    layout="vertical"
                                    className="user-forms"
                                    name="eitOfficeControllerForm"
                                    initialValues={{
                                        remember: true,
                                        id: fullObject.id,
                                        profilepicture: fullObject?.profile_image,
                                        firstname: fullObject?.first_name && fullObject?.first_name,
                                        lastname: fullObject?.last_name && fullObject?.last_name,
                                        email: fullObject?.email && fullObject?.email,
                                        phone: fullObject?.mobile && fullObject?.mobile,
                                        title: fullObject?.title && fullObject?.title,
                                        office: fullObject?.office?.id && fullObject?.office?.id,
                                        education: fullObject?.user_detail?.education
                                            ? fullObject?.user_detail?.education
                                            : '',
                                        summary: fullObject?.user_detail?.about,
                                        uploadcv: fullObject?.user_detail?.cv_url,
                                        uploadotherdoc: fullObject?.uploadotherdoc,
                                    }}
                                    colon={false}
                                    onFinish={values => onFinish(values, updateSuperUser)}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Row style={{ marginTop: 5 }}>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="profilepicture"
                                                label={formatMessage({
                                                    id: 'component.form.profilepicture',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst skriv inn ${formatMessage({
                                                            id: 'component.form.profilepicture',
                                                        })}`,
                                                    },
                                                ]}
                                            >
                                                <div className="user-profile-box">
                                                    <img
                                                        src={imageUrl}
                                                        alt={fullObject?.first_name + fullObject?.last_name}
                                                        className="profile-picture"
                                                    />
                                                </div>

                                                <Upload accept={['.jpeg', '.jpg', '.png', '.gif']} showUploadList={false} onChange={handleUploadChange}>
                                                    <Button>
                                                        <UploadOutlined />{' '}
                                                        {formatMessage({
                                                            id: 'component.form.upload',
                                                        })}
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={8}>
                                            <Form.Item
                                                name="firstname"
                                                label={formatMessage({
                                                    id: 'component.form.firstname',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst skriv inn ${formatMessage({
                                                            id: 'component.form.firstname',
                                                        }).toLowerCase()}`,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: '100%' }}
                                                    placeholder={formatMessage({
                                                        id: 'component.form.firstname',
                                                    })}
                                                    size="large"
                                                    className="pickerwidth"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item
                                                name="lastname"
                                                label={formatMessage({
                                                    id: 'component.form.lastname',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst skriv inn ${formatMessage({
                                                            id: 'component.form.lastname',
                                                        }).toLowerCase()}`,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={formatMessage({
                                                        id: 'component.form.lastname',
                                                    })}
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={8}>
                                            <Form.Item
                                                name="email"
                                                label={formatMessage({
                                                    id: 'component.form.email',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst skriv inn ${formatMessage({
                                                            id: 'component.form.email',
                                                        }).toLowerCase()}`,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: '100%' }}
                                                    placeholder={formatMessage({
                                                        id: 'component.form.email',
                                                    })}
                                                    size="large"
                                                    className="pickerwidth"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item
                                                name="phone"
                                                label={formatMessage({
                                                    id: 'component.form.phone',
                                                })}
                                            >
                                                <Input
                                                    placeholder={formatMessage({
                                                        id: 'component.form.phone',
                                                    })}
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={16}>
                                            <Form.Item
                                                name="title"
                                                label={formatMessage({
                                                    id: 'component.form.title',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst skriv inn ${formatMessage({
                                                            id: 'component.form.title',
                                                        }).toLowerCase()}`,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: '100%' }}
                                                    placeholder={`Angi ${formatMessage({
                                                        id: 'component.form.title',
                                                    }).toLowerCase()}`}
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16]}>
                                        <Col xs={24} md={24} lg={16}>
                                            <Query
                                                query={ADMIN_OFFICES_LIST_ONLY}
                                                variables={{
                                                    status: true,
                                                }}
                                            >
                                                {({ data }) => {
                                                    if (data && data.offices_list) {
                                                        return (
                                                            <Form.Item
                                                                name="office"
                                                                label={`${formatMessage({
                                                                    id: 'component.form.office',
                                                                })}`}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: `Vennligst velg ${formatMessage({
                                                                            id: 'component.form.office',
                                                                        }).toLowerCase()}`,
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    placeholder={`Vennligst velg ${formatMessage({
                                                                        id: 'component.form.office',
                                                                    }).toLowerCase()}`}
                                                                    size="large"
                                                                    suffixIcon={<CaretDownFilled className="caret-style" />}
                                                                    className="pickerwidth"
                                                                >
                                                                    {data.offices_list.map(i => (
                                                                        <Select.Option value={i.id}>
                                                                            <span>{i.name}</span>
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        );
                                                    }
                                                    return (
                                                        <div className="loader-wrapper">
                                                            <LoadingOutlined />
                                                        </div>
                                                    );
                                                }}
                                            </Query>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={16}>
                                            <Form.Item
                                                name="education"
                                                label={formatMessage({
                                                    id: 'component.form.education',
                                                })}
                                            >
                        <TextArea
                            rows="3"
                            placeholder={`Angi ${formatMessage({
                                id: 'component.form.education',
                            }).toLowerCase()}`}
                        />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={16}>
                                            <Form.Item
                                                name="summary"
                                                label={formatMessage({
                                                    id: 'component.form.summary',
                                                })}
                                            >
                                                <BraftEditor
                                                    className="editor-wrapper"
                                                    value={editorState}
                                                    onChange={handleEditorChange}
                                                    onSave={submitSummaryContent}
                                                    language="en"
                                                    contentStyle={{ height: 210 }}
                                                    placeholder={`Angi ${formatMessage({
                                                        id: 'component.form.summary',
                                                    }).toLowerCase()}`}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={32}>
                                        <Col xs={24} lg={16} md={16}>
                                            <div className="label-texts mb-3">
                                                {formatMessage({ id: 'component.form.uploadcv' })}
                                            </div>
                                            <Form.Item
                                                className="no-margin"
                                                name="uploadcv"
                                                valuePropName="file"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: (
                                                            <span className="text-lowercase">{`Vennligst velg${formatMessage(
                                                                {
                                                                    id: 'component.form.upload.video',
                                                                },
                                                            )}`}</span>
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Upload
                                                    onChange={e => handleCvUpload(e)}
                                                    accept=".pdf,.doc,.docx"
                                                    showUploadList={false}
                                                >
                                                    <Button>
                                                        <UploadOutlined /> {formatMessage({ id: 'component.form.uploadcv' })}
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {console.log('displayCv.length:', displayCv.length)}
                                    {displayCv.length !== 0 && (
                                        <Row gutter={32}>
                                            <Col xs={24} lg={16}>
                                                {displayCv?.map(item => (
                                                    <div className="default_list mt-3 mb-3">
                                                        <div>
                                                            <i className="icon-attachment" />
                                                            <span className="ml-2">
                                {item?.originFileObj ? (
                                    <span>{item?.name}</span>
                                ) : (
                                    <a
                                        href={`${STORAGE_URL}${item?.media_url}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {item?.name}
                                    </a>
                                )}
                              </span>
                                                        </div>
                                                        <Mutation mutation={ADMIN_DELETE_AGENT_CV}>
                                                            {deletUser => {
                                                                return (
                                                                    <div className="upload-media-delete" onClick={removeCvUpload}>
                                                                        <i
                                                                            className="icon-delete"
                                                                            onClick={() => {
                                                                                deletUser({
                                                                                    variables: {
                                                                                        agent_id: fullObject.id.toString(),
                                                                                    },
                                                                                }).then(data => {
                                                                                    console.log('Data submitted===>>>', data);
                                                                                    console.log(
                                                                                        'Data data.delete_user===>>>',
                                                                                        data.data.delete_vendor,
                                                                                    );
                                                                                    if (data && data.data.delete_cv) {
                                                                                        notification.success({
                                                                                            message: 'Cv slettet',
                                                                                            description: '',
                                                                                            duration: 3,
                                                                                        });
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            }}
                                                        </Mutation>
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    )}
                                    <Row gutter={8} className="mt-3">
                                        <Col xs={24} lg={16}>
                                            <Form.Item
                                                name="uploadotherdoc"
                                                label={formatMessage({
                                                    id: 'component.form.uploadotherdoc',
                                                })}
                                            >
                                                <Upload
                                                    onChange={e => handleOtherDoc(e)}
                                                    accept=".pdf,.doc,.docx"
                                                    showUploadList={false}
                                                    multiple
                                                >
                                                    <Button className="borderradius">
                                                        <UploadOutlined />
                                                        {formatMessage({
                                                            id: 'component.form.uploadotherdoc',
                                                        })}
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {displayOtherDoc?.length !== 0 && (
                                        <Row gutter={32}>
                                            <Col xs={24} lg={16}>
                                                {displayOtherDoc.map((item, index) => (
                                                    <div className="default_list mb-3">
                                                        {console.log('ITEm>,', index)}
                                                        {console.log('STORAGE DOC--->', `${STORAGE_URL}${item.media_url}`)}
                                                        <div>
                                                            <i className="icon-attachment" />
                                                            <span className="ml-2">
                                {item.originFileObj ? (
                                    <span>
                                    {console.log('ITEM ORIGINFILEOBJ', item.originFileObj)}
                                        {item.name}
                                  </span>
                                ) : (
                                    <a
                                        href={`${STORAGE_URL}${item.media_url}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {item.name}
                                    </a>
                                )}
                              </span>
                                                        </div>
                                                        <Mutation mutation={ADMIN_DELETE_AGENT_DOC}>
                                                            {deletUser => {
                                                                return (
                                                                    <div className="upload-media-delete">
                                                                        <i
                                                                            className="icon-delete"
                                                                            onClick={() => {
                                                                                if (docLink.includes(item.uid)) {
                                                                                    deletUser({
                                                                                        variables: {
                                                                                            agent_id: fullObject.id.toString(),
                                                                                            user_document_id: parseInt(item.uid,10),
                                                                                        },
                                                                                    }).then(data => {
                                                                                        console.log('Data submitted===>>>', data);
                                                                                        console.log(
                                                                                            'Data data.delete_user===>>>',
                                                                                            data.data.delete_document,
                                                                                        );
                                                                                        if (data && data.data.delete_document) {
                                                                                            notification.success({
                                                                                                message: 'Dokument slettet',
                                                                                                description: '',
                                                                                                duration: 3,
                                                                                            });
                                                                                            location.reload();
                                                                                        }
                                                                                        // }
                                                                                    });
                                                                                } else {
                                                                                    setDisplayOtherDoc(
                                                                                        displayOtherDoc.filter(i => i.uid !== item.uid),
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            }}
                                                        </Mutation>
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    )}
                                    <Row className="mt-3">
                                        <Col lg={24}>
                                            <Form.Item name="isAdmin">
                                                <Checkbox checked={isInAdminOffice} onChange={onCheckboxChange}>
                                                    {formatMessage({
                                                        id: 'component.form.isadmin',
                                                    })}
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={24}>
                                            <div className="flex-container">
                                                <div>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="btn-secondary buttons admin-forms-btn"
                                                        size="large"
                                                        loading={loading}
                                                    >
                                                        {formatMessage({
                                                            id: 'component.form.add',
                                                        })}
                                                    </Button>
                                                    <Button
                                                        className="text-primary admin-forms-btn"
                                                        style={{ marginLeft: 8 }}
                                                        size="large"
                                                        onClick={()=>router.goBack()}
                                                    >
                                                        {formatMessage({
                                                            id: 'component.form.cancel',
                                                        })}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        className="admin-forms-btn delete-btn"
                                                        style={{ marginLeft: 8, float: 'right' }}
                                                        onClick={() => setdeleteModal(true)}
                                                        size="large"
                                                    >
                                                        {formatMessage({
                                                            id: 'component.form.delete',
                                                        })}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Modal
                                        centered
                                        title={`${formatMessage({
                                            id: 'component.form.delete',
                                        })} Superbrukere`}
                                        visible={deleteModal}
                                        onOk={() => setdeleteModal(false)}
                                        onCancel={() => setdeleteModal(false)}
                                        width="450px"
                                        footer={[
                                            <Mutation mutation={ADMIN_ACTIVE_INACTIVE_AGENT}>
                                                {(deletUser, { loading }) => {
                                                    return (
                                                        <Button
                                                            key="submit"
                                                            type="primary"
                                                            className="admin-forms-btn  mr_15"
                                                            size="large"
                                                            onClick={() => onDelete(formData.id, deletUser)}
                                                            loading={loading}
                                                        >
                                                            {formatMessage({
                                                                id: 'component.form.yes',
                                                            })}
                                                        </Button>
                                                    );
                                                }}
                                            </Mutation>,
                                            <Button
                                                className="admin-forms-btn  mr_15"
                                                size="large"
                                                onClick={() => setdeleteModal(false)}
                                            >
                                                {formatMessage({
                                                    id: 'component.form.no',
                                                })}
                                            </Button>,
                                        ]}
                                    >
                                        <p className="text-primary no-margin">
                                            {formatMessage({
                                                id: 'component.photographer.deletemodal.text',
                                            })}
                                        </p>
                                    </Modal>
                                </Form>
                            );
                        }}
                    </Mutation>
                </Col>
            </Row>
        </>
    );
};
export default OfficeEditForm;
