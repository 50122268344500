import React from 'react';
import {View} from 'react-native'
import styles from './styles';
import PropTypes from 'prop-types';
import {Color, CommonStyle, Icon, ThemeUtils} from "components/src/utils";
import {Label, Ripple} from "components/src/components";

export default function Attachment(props) {
    const {name,isDownloadable, isDeletable, onPressDelete, style, onPressDownload} = props;
    return (
        <View style={[styles.cont, style]}>
            <Icon name={'attachment'}
                  size={ThemeUtils.fontNormal}
                  color={Color.PRIMARY_LIGHT}/>
            <Label small
                   singleLine
                   ml={10}
                   style={CommonStyle.flex}
                   color={Color.PRIMARY_LIGHT}>
                {name}
            </Label>
            {isDownloadable ? (
                <Ripple style={styles.icCont}
                        onPress={onPressDownload}
                        rippleContainerBorderRadius={12}>
                    <Icon name={'download'}
                          size={ThemeUtils.fontNormal}
                          color={Color.PRIMARY_LIGHT}/>
                </Ripple>) : null}
            {isDeletable ? (
                <Ripple style={styles.icCont}
                        onPress={onPressDelete}
                        rippleContainerBorderRadius={12}>
                    <Icon name={'delete'}
                          size={ThemeUtils.fontNormal}
                          color={Color.PRIMARY_LIGHT}/>
                </Ripple>) : null}
        </View>
    )
}
Attachment.defaultProps = {
    isDownloadable: true
}
Attachment.propTypes = {
    name: PropTypes.string,
    isDeletable: PropTypes.bool,
    isDownloadable: PropTypes.bool,
    onPressDelete: PropTypes.func,
    onPressDownload: PropTypes.func
}
