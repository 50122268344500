import { Col, Row, Typography, Form, Upload, Input, Button, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { formatMessage } from 'components/src/utils';
import { Link,useHistory } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { ADMIN_CREATE_ADS_POPUPS } from '../../../../../../mutation/AdminMutation';

const { Title } = Typography;
// const { TabPane } = Tabs;

const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: '40px', opacity: 0.3 }} />
      <div className="ant-upload-text label-texts">
        {formatMessage({
          id: 'component.form.upload',
        })}
      </div>
    </div>
);

const onFinishFailedSales = errorInfo => {
  console.log('Failed:', errorInfo);
};

export const AdsAndPopUpsMarketing = () => {
  let router=useHistory();
  const [imageUrl, setimageUrl] = useState('');
  const [imageUrl2, setimageUrl2] = useState('');
  const [imageObject1, setimageObject1] = useState();
  const [imageObject2, setimageObject2] = useState();

  function getBase64(img, callback) {
    const reader1 = new FileReader();
    reader1.addEventListener('load', () => callback(reader1.result));
    reader1.readAsDataURL(img);
  }

  function getBase(img1, callback) {
    const reader2 = new FileReader();
    reader2.addEventListener('load', () => callback(reader2.result));
    reader2.readAsDataURL(img1);
  }
  const handleChangeImage1 = info => {
    getBase64(info.file.originFileObj, image => setimageUrl(image));
    setimageObject1(info.file.originFileObj);
  };

  const handleChangeImage2 = info => {
    getBase(info.file.originFileObj, image2 => setimageUrl2(image2));
    setimageObject2(info.file.originFileObj);
  };

  const onFinishSales = (values, addAdsAndPopUpsMarketing) => {
    console.log('Success:', values);
    if (imageObject2 && values?.redirect_address2_marketing) {
      addAdsAndPopUpsMarketing({
        variables: {
          ads_image: [imageObject1, imageObject2],
          redirect_url: [values.redirect_address1_marketing, values.redirect_address2_marketing],
          place: 'MARKETING',
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.create_ads_popup && data.data.create_ads_popup.meta) {
          notification.success({
            message: data.data.create_ads_popup.meta.message,
            description: '',
            duration: 3,
          });
          router.push(`/admin/dashboard`);
        }
      });
    } else {
      addAdsAndPopUpsMarketing({
        variables: {
          ads_image: [imageObject1],
          redirect_url: [values.redirect_address1_marketing],
          place: 'MARKETING',
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.create_ads_popup && data.data.create_ads_popup.meta) {
          notification.success({
            message: data.data.create_ads_popup.meta.message,
            description: '',
            duration: 3,
          });
          router.push(`/admin/dashboard`);
        }
      });
    }
  };

  return (
      <Mutation mutation={ADMIN_CREATE_ADS_POPUPS}>
        {(addAdsAndPopUpsMarketing, { loading }) => {
          return (
              <Form
                  layout="vertical"
                  className="user-forms"
                  name="adsandpopupsMarketing"
                  initialValues={{
                    remember: true,
                  }}
                  colon={false}
                  // eslint-disable-next-line no-undef
                  onFinish={values => onFinishSales(values, addAdsAndPopUpsMarketing)}
                  onFinishFailed={onFinishFailedSales}
              >
                <Row className="mt-3">
                  <Col lg={24} md={24} xs={24}>
                    <Title level={4} className="text-primary">
                      Bilder 1 :
                    </Title>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item
                        name="image1_marketing"
                        label={formatMessage({
                          id: 'component.adsandpopups.uploadimageorgif',
                        })}
                        rules={[
                          {
                            required: true,
                            message: 'Vennligst last opp bilde eller GIF',
                          },
                        ]}
                    >
                      <Upload
                          name="marketing_avatar1"
                          accept={['.jpeg', '.jpg', '.png', '.gif']}
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          onChange={handleChangeImage1}
                      >
                        {imageUrl ? (
                            <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                        ) : (
                            uploadButton
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={16} md={24} xs={24}>
                    <Form.Item
                        name="redirect_address1_marketing"
                        label={formatMessage({
                          id: 'component.adsandpopups.redirectionurl',
                        })}
                        rules={[
                          {
                            required: true,
                            message: `Vennligst fyll inn ${formatMessage({
                              id: 'component.adsandpopups.redirectionurl',
                            }).toLowerCase()}`,
                          },
                        ]}
                    >
                      <Input
                          size="large"
                          placeholder={formatMessage({
                            id: 'component.adsandpopups.redirectionurl.placeholder',
                          })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={24}>
                    <Title level={4} className="text-primary">
                      Bilder 2:
                    </Title>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item
                        name="image2_marketing"
                        className="label-texts no-margin"
                        label={formatMessage({
                          id: 'component.adsandpopups.uploadimageorgif',
                        })}
                        rules={[
                          {
                            required: false,
                            message: 'Vennligst last opp bilde eller GIF',
                          },
                        ]}
                    >
                      <Upload
                          name="sales-avtar2"
                          accept={['.jpeg', '.jpg', '.png', '.gif']}
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          onChange={handleChangeImage2}
                      >
                        {imageUrl2 ? (
                            <img src={imageUrl2} alt="avatar" style={{ width: '100%' }} />
                        ) : (
                            uploadButton
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={16} md={24} xs={24}>
                    <Form.Item
                        name="redirect_address2_marketing"
                        label={formatMessage({
                          id: 'component.adsandpopups.redirectionurl',
                        })}
                        rules={[
                          {
                            required: false,
                            message: `Vennligst fyll inn ${formatMessage({
                              id: 'component.form.redirectionurl',
                            }).toLowerCase()}`,
                          },
                        ]}
                    >
                      <Input
                          size="large"
                          placeholder={formatMessage({
                            id: 'component.adsandpopups.redirectionurl.placeholder',
                          })}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col lg={24} md={24} xs={24}>
                    <Button
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        className="admin-forms-btn  mr_15"
                        size="large"
                        loading={loading}
                    >
                      {formatMessage({
                        id: 'component.form.save',
                      })}
                    </Button>
                    <Link to="/admin/dashboard">
                      <Button className="admin-forms-btn  mr_15" size="large">
                        {formatMessage({
                          id: 'component.form.cancel',
                        })}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Form>
          );
        }}
      </Mutation>
  );
};
export default AdsAndPopUpsMarketing;
