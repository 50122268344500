// import { DownloadOutlined } from '@ant-design/icons';
import { Col, Row, Tabs, Typography } from 'antd';
import React from 'react';

import AgentDetailForm from './component/agentdetails/components/agentdetailform';
import {formatMessage} from "components/src/utils";
// eslint-disable-next-line import/no-named-as-default
// import AddAgentFeedBack from './component/agentdetails/components/addagentfeedback';

const { TabPane } = Tabs;
const { Title } = Typography;

// const props = {
//   showUploadList: {
//     showDownloadIcon: true,
//     downloadIcon: <DownloadOutlined />,
//     showRemoveIcon: true,
//     removeIcon: <i className="icon-delete" />,
//   },
//   defaultFileList: [
//     {
//       uid: '1',
//       name: 'tariff.png',
//       status: 'done',
//       response: 'Server Error 500', // custom error message to show
//       url: 'http://www.baidu.com/xxx.png',
//     },
//   ],
// };

export const OrderInformation = () => (
  <div className="tablayout">
    <Row>
      <Col xs={24} lg={24}>
        <Tabs defaultActiveKey="agentdetails" className="tabView">
          <TabPane
            tab={
              <Title level={4}>
                {formatMessage({
                  id: 'component.allagents.addAgent',
                })}
              </Title>
            }
            key="agentdetails"
          >
            <div className="contentshadow" style={{ marginLeft: 0, marginRight: 0 }}>
              <div className="text-primary main">
                <Row>
                  <Col xs={24}>
                    <p>
                      {/* {formatMessage({
                        id: 'component.allagents.tab1.title',
                      })} */}
                    </p>
                  </Col>
                </Row>
                <AgentDetailForm />
              </div>
            </div>
          </TabPane>
          {/* <TabPane
            tab={
              <Title level={4}>
                {formatMessage({
                  id: 'component.allagents.tab2',
                })}
              </Title>
            }
            key="addagentfeedback"
          >
            <AddAgentFeedBack />
          </TabPane> */}
        </Tabs>
      </Col>
    </Row>
  </div>
);

export default OrderInformation;
