import React from 'react';

import {View, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';

import {styles} from './styles';
import {Icon,Color, ThemeUtils} from 'components/src/utils';


class StarRating extends React.Component {


    constructor(props) {
        super(props);
        this.rating = props.rating - 1;
        this.state = {
            fractionRate: this.rating % 1,
            ratingSize: this.rating - this.rating % 1,
        };
    }

    onPress = (index) => {
        !this.props.disabled && this.props.onStarSelected ?
            this.setState({
                ratingSize: index,
            }, () => {
                this.props.onStarSelected(this.state.ratingSize + 1);
            }) : null;
    };

    renderStar = (item, index) => {

        let iconName = 'star-outline';
        let colorName = Color.PRIMARY;
        if (index <= this.state.ratingSize) {
            iconName = 'rating';
        } else if (index === this.state.ratingSize + 1) {
            if (this.state.fractionRate === 0.5) {
                iconName = 'star-half-fill';
            } else if (this.state.fractionRate > 0.5) {
                iconName = 'rating';
            } else {
                colorName = this.props.disabledColor;
            }
        } else {
            colorName = this.props.disabledColor;
        }
        return (
            <TouchableOpacity activeOpacity={this.props.disabled ? 1 : 0.7}
                              style={[{marginEnd:5},this.props.iconContainerStyle]}
                              key={index.toString()}
                              onPress={() => !this.props.disabled && this.onPress(index)}>
                <Icon name={iconName}
                      color={colorName}
                      size={this.props.iconSize}/>
            </TouchableOpacity>
        );
    };

    render() {
        return (
            <View style={styles.containerMain}>
                {new Array(5).fill(false).map(this.renderStar)}
            </View>
        );
    }
}


StarRating.defaultProps = {
    rating: 0,
    iconSize: ThemeUtils.fontLarge,
    disabledColor: Color.PRIMARY_LIGHT,
};

StarRating.propTypes = {
    rating: PropTypes.number,
    iconSize: PropTypes.number,
    onStarSelected: PropTypes.func,
    disabled: PropTypes.bool,
    iconContainerStyle: PropTypes.object,
    disabledColor: PropTypes.string,

};
export default StarRating;
