import {StyleSheet} from 'react-native'
import {ThemeUtils} from "components/src/utils";
export default StyleSheet.create({
    container:{
        flex:1
    },
    icCont:{
        marginTop:20,
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    lblListCont:{
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    lblCont:{
      marginVertical:10
    }
})
