import React from 'react'
//Components
import {Animated, View} from 'react-native'

import {Header, Label,} from "components/src/components";
//Third party
import moment from "moment";
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Utils
import {Constants, DateUtils, IS_WEB, Strings} from "components/src/utils";
import {styles} from './styles.js';


function ViewDetails(props) {
    const {history, navigation} = props;
    const order = IS_WEB ? props.history.location.state?.order ?? {} : props.navigation.getParam('order', {});
    const scrollY = new Animated.Value(0)

    const renderOrderDetails = () => {
        return (
            <View>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.oppdragno}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{order?.property?.oppdrags_nummer}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.sellername}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {`${order?.property?.seller?.first_name} ${order?.property?.seller?.last_name}`}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Email}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{order?.property?.seller?.email}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Phone}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{order?.property?.seller?.mobile}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.dates}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {moment(order.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.time}</Label>
                {order?.order_status === Constants.ORDER_STATUS.ORDERED ?
                    (
                        <Label small
                               mb={20}
                               font_regular
                               align={'left'}>
                            {`${moment(order.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)} - ${moment(order.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}`}
                        </Label>) : (
                        <Label small
                               mb={20}
                               font_regular
                               align={'left'}>
                            {moment(order.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                        </Label>
                    )}
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.propertyaddress}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {`${order?.property?.street_adress ?? ""} ${order?.property?.city ?? ""} ${order?.property?.zipcode ?? ""}`}
                </Label>
            </View>
        )
    }

    return (
        <View style={styles.container}>
            <Header animatedTitle={Strings.orderinginformation}
                    animatedValue={scrollY}
                    initialMarginLeft={10}
                    navigation={IS_WEB ? history : navigation}/>
            <Animated.ScrollView contentContainerStyle={{flexGrow: 1}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.contentCont}>
                    {renderOrderDetails()}
                </View>
            </Animated.ScrollView>
        </View>
    );
}


const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewDetails);
