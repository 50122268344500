import {Platform, StyleSheet} from 'react-native'
import {Color, IS_WEB, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    chatCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: ThemeUtils.relativeRealHeight(2),
    },
    chatImage: {
        height: 40,
        width: 40,
        borderRadius: 20,
        backgroundColor: Color.GRAY
    },
    lblCont: {
        flex: 1,
        marginHorizontal: 15,
        justifyContent: 'space-between'
    },
    inptCont: {
        // position: Platform.OS === "web" ? 'fixed' : 'absolute',
        width: "100%",
        bottom: 0,
        backgroundColor: Color.WHITE,
        flexDirection: 'row',
    },
    input: {
        flex: 1,
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        borderWidth: 0,
        borderTopWidth:1,
        borderColor: Color.DARK_GRAY,

    },
    flatListContentContainer: {
        // marginBottom: ThemeUtils.relativeRealHeight(15)
    },
    iconCont: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Color.PRIMARY,
        aspectRatio:1,
        height:ThemeUtils.relativeRealWidth(14),
        width:ThemeUtils.relativeRealWidth(14),
        alignSelf: 'flex-end',
    },
    headerTitle: {
        alignItems: 'flex-start',
        marginStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        justifyContent: 'center',
    }
})
