import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    lblCont: {
        marginVertical: 7
    },
    propertyDetailsCont: {
        marginTop: 20,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    inspectionDetailsCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    utlegCont: {
        flexDirection: 'row',
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: 10,
        alignItems: 'center'
    },
    serviceLblCont: {
        flex: 1,
        marginHorizontal: 15
    },
    btnCont: {
        flexDirection: 'row',
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginVertical: 15
    },
    sendOfferCont: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    }

});
export {styles};
