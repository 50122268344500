import { decryptId } from "../../../../../utils/utils";
import {
  LoadingOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
    Spin,
  Modal,
  notification,
  Row,
  Typography,
  Upload,
  message,
} from "antd";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import React from "react";
import { Mutation, Query } from "react-apollo";
import { Link,withRouter } from "react-router-dom";
import { formatMessage } from "components/src/utils";
import {
  ADMIN_DELETE_NEWS,
  ADMIN_UPDATE_NEWS,
} from "../../../../../../mutation/AdminMutation";
import { ADMIN_NEWS_LIST_DETAILS } from "../../../../../../query/AdminQuery";
import { APIConfig } from "../../../../../../../components/src/utils/Constants";

const { Title } = Typography;
const STORAGE_URL = APIConfig.STORAGE_URL;
let newsItem;
const docprops = {
  showUploadList: {
    showRemoveIcon: true,
    removeIcon: <i className="icon-delete" />,
  },
  defaultFileList: [],
};
// const normFile = e => {
//   console.log('Upload event:', e);
//   if (Array.isArray(e)) {
//     return e;
//   }
//   return e && e.fileList;
// };
// const fileList = [
//   {
//     uid: '-1',
//     name: 'image.png',
//     status: 'done',
//     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//   },
// ];
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

let id;
let fullObject;
let orderItem;
class EditNews extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line prefer-destructuring
    id = props.match.params.id;
    newsItem = orderItem;
    console.log('news list props==>', newsItem);
    this.state = {
      editorState: BraftEditor.createEditorState(),
      loading: false,
      visible: false,
      orderData: '',
      imageObject: '',
      isCoverPhotoChnaged: false,
      newsVideo: [],
      isImagePresent: false,
    };
    this.formRef = React.createRef();

  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.isLivinig = true;
    setTimeout(this.setEditorContentAsync(fullObject), 3000);
  }

  componentWillUnmount() {
    this.isLivinig = false;
  }

  handleChange = editorState => {
    this.setState({
      editorState,
    });
  };

  handleUploadChange = info => {
    getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
          imageObject: info.file.originFileObj,
          isCoverPhotoChnaged: true,
        }),
    );
  };

  setEditorContentAsync = editorData => {
    // eslint-disable-next-line no-unused-expressions
    this.isLivinig &&
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      editorState: BraftEditor.createEditorState(editorData?.description),
    });
    if (this.formRef.current !== null) {
      console.log('sync-.', editorData);
      this.formRef.current.setFieldsValue({
        description: BraftEditor.createEditorState(editorData?.description),
      });
    }
  };

  render() {
    const uploadButton = (
        <div>
          {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div className="ant-upload-text">{formatMessage({ id: 'component.form.upload' })}</div>
        </div>
    );
    const {
      editorState,
      imageUrl,
      imageObject,
      orderData,
      newsVideo,
      isCoverPhotoChnaged,
      isImagePresent,
    } = this.state;

    const onFinish = (values, editnewsData) => {
      console.log('Success:', values, '\n newsDesc==>', editorState.toText());
      console.log('TITLE:', values.title);
      // TODO: add the data from the success form response below
      const inputFields = {
        id: parseInt(decryptId(id), 10),
        title: values.title,
        description: editorState.toText(),
      };
      if (isCoverPhotoChnaged) {
        inputFields.cover_image = isCoverPhotoChnaged ? imageObject : null;
      }
      if (newsVideo.length > 0) {
        inputFields.video = newsVideo[0].originFileObj;
      }

      editnewsData({
        variables: {
          input: inputFields,
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.update_news) {
          notification.success({
            message: 'Innspillingen ble oppdatert',
            description: '',
            duration: 3,
          });
          this.props.history.push('/admin/nyheter');
        }
      });
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const onDelete = (deleteID, deleteNews) => {
      console.log('delete id ==>', deleteID);
      deleteNews({
        variables: {
          id: parseInt(decryptId(id), 10),
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.delete_news && data.data.delete_news.meta) {
          notification.success({
            message: 'Nyheter slettet',
            description: '',
            duration: 3,
          });
          this.props.history.push('/admin/nyheter');
        }
      });

    };

    return (
        <>
          <Query
              query={ADMIN_NEWS_LIST_DETAILS}
              variables={{
                id: decryptId(id),
                status: 1,
              }}
              fetchPolicy="cache-and-network"
              onCompleted={responseData => {

                const newsObject = responseData.news_detail;
                orderItem = responseData.news_detail;
                newsItem = newsObject;
                console.log('responseData:', responseData.news_detail);
                this.setState({
                  orderData: newsObject,
                  editorState: BraftEditor.createEditorState(newsObject.description),
                  newsVideo: newsObject.video_url
                      ? [
                        {
                          uid: 1,
                          name: 'Video',
                          media_url: newsObject?.video_url,
                        },
                      ]
                      : [],
                });
                if (newsObject.cover_image_url) {
                  this.setState({
                    isImagePresent: true,
                    imageUrl: `${STORAGE_URL}${newsObject.cover_image_url}`,
                  });
                }
                fullObject = responseData.news_detail;
                setTimeout(this.setEditorContentAsync(fullObject), 3000);
              }}
          >
            {({ data, loading, error }) => {
              console.log(error);
              if (data && data.news_detail) {
                return (
                    <Row className="contentshadow">
                      <Col xs={24} lg={24}>
                        <Title level={3} className="text-primary">
                          {formatMessage({ id: 'component.news.editnews' })}
                        </Title>

                        {console.log(docprops.defaultFileList.push)}
                        <Mutation mutation={ADMIN_UPDATE_NEWS}>
                          {(editnewsData, { loading }) => {
                            console.log('orderData>', orderData);
                            return (
                                <Form
                                    ref={this.formRef}
                                    name="editPorfessionalForm"
                                    initialValues={{
                                      remember: true,
                                      cover_photo: `${STORAGE_URL}${data.news_detail?.cover_image_url}`,
                                      title: data.news_detail.title,
                                      description: data.news_detail?.description,
                                      cover_video: data.news_detail?.video_url,
                                    }}
                                    onFinish={values => onFinish(values, editnewsData)}
                                    onFinishFailed={onFinishFailed}
                                    layout="vertical"
                                    className="user-forms addEditform"
                                >
                                  <Row>
                                    <Col xs={24} md={8} lg={4}>
                                      <Form.Item
                                          name="cover_photo"
                                          className="mb-3"
                                          label={formatMessage({
                                            id: 'component.news.coverphoto',
                                          })}
                                      >
                                        {isImagePresent ? (
                                            <Row>
                                              <Col xs={24}>
                                                <div className="user-profile-box">
                                                  <img
                                                      src={imageUrl}
                                                      alt="John Doe"
                                                      className="profile-picture"
                                                  />
                                                </div>
                                              </Col>
                                              <Col xs={24} className="mt-3">
                                                <Upload
                                                    showUploadList={false}
                                                    onChange={this.handleUploadChange}
                                                    accept={['.jpg', '.jpeg', '.png', '.svg']}
                                                >
                                                  <Button>
                                                    <UploadOutlined />{' '}
                                                    {formatMessage({
                                                      id: 'component.form.upload',
                                                    })}
                                                  </Button>
                                                </Upload>
                                              </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                              <Col xs={24}>
                                                <Upload
                                                    name="avatar"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    onChange={this.handleUploadChange}
                                                    accept={['.jpg', '.jpeg', '.png', '.svg']}
                                                >
                                                  {imageUrl ? (
                                                      <img
                                                          src={imageUrl}
                                                          alt="avatar"
                                                          style={{ width: '100%' }}
                                                      />
                                                  ) : (
                                                      uploadButton
                                                  )}
                                                </Upload>
                                              </Col>
                                            </Row>
                                        )}
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Form.Item
                                      label={formatMessage({
                                        id: 'component.news.newstitle',
                                      })}
                                      name="title"
                                      rules={[
                                        {
                                          required: true,
                                          message: `Vennligst skriv inn ${formatMessage({
                                            id: 'component.news.newstitle',
                                          }).toLowerCase()}`,
                                        },
                                      ]}
                                  >
                                    <Input
                                        placeholder={formatMessage({ id: 'component.news.newstitle' })}
                                        size="large"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                      label={formatMessage({
                                        id: 'component.news.newsdesc',
                                      })}
                                      name="description"
                                  >
                                    <BraftEditor
                                        className="editor-wrapper"
                                        language="en"
                                        value={editorState}
                                        onChange={this.handleChange}
                                        contentStyle={{ height: 210 }}
                                        placeholder={formatMessage({
                                          id: 'component.news.newsdescplaceholder',
                                        })}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                      name="newsvideo"
                                      label={formatMessage({
                                        id: 'component.news.uploadvideo',
                                      })}
                                      valuePropName="fileList"
                                      rules={[
                                        {
                                          required: false,
                                          message: `Vennligst  ${formatMessage({
                                            id: 'component.news.uploadvideo',
                                          })}!`,
                                        },
                                      ]}
                                  >
                                    <Upload
                                        showUploadList={false}
                                        accept=".mp4"
                                        fileList={false}
                                        onChange={({ fileList }) => {
                                          console.log('video-->', fileList[0]);
                                          const file = [];
                                          file.push(fileList[0]);
                                          this.setState({
                                            newsVideo: file,
                                          });
                                        }}
                                    >
                                      <Button className="marginbottom cancelbutton" size="large">
                                        <UploadOutlined />
                                        {formatMessage({
                                          id: 'component.form.upload',
                                        })}
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                  {newsVideo.length !== 0 && (
                                      <Row gutter={32}>
                                        <Col xs={24} lg={16}>
                                          {newsVideo.map(item => (
                                              <div className="default_list mt-3 mb-3">
                                                <div>
                                                  <i className="icon-attachment" />
                                                  <span className="ml-2">
                                          {item.originFileObj ? (
                                              <span>{item.name}</span>
                                          ) : (
                                              <a
                                                  href={`${STORAGE_URL}${item.media_url}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                              >
                                                {item.name}
                                              </a>
                                          )}
                                        </span>
                                                </div>
                                                <div
                                                    className="upload-media-delete"
                                                >
                                                  <i
                                                      className="icon-delete"
                                                      onClick={() => this.setState({ newsVideo: [] })}
                                                  />
                                                </div>
                                              </div>
                                          ))}
                                        </Col>
                                      </Row>
                                  )}
                                  <Button
                                      type="primary"
                                      size="large"
                                      htmlType="submit"
                                      className="btn-secondary buttons admin-forms-btn mt-3"
                                      loading={loading}
                                  >
                                    {formatMessage({
                                      id: 'component.news.publish',
                                    })}
                                  </Button>
                                  <Link to="/admin/nyheter">
                                    <Button
                                        size="large"
                                        className="buttons admin-forms-btn back-button mt-3"
                                    >
                                      {formatMessage({
                                        id: 'component.form.cancel',
                                      })}
                                    </Button>
                                  </Link>
                                  <Button
                                      size="large"
                                      className="buttons admin-forms-btn delete-button mt-3 pull-right"
                                      onClick={this.showModal}
                                  >
                                    {formatMessage({
                                      id: 'component.form.delete',
                                    })}
                                  </Button>
                                  <Modal
                                      title={formatMessage({ id: 'component.news.deletenews' })}
                                      visible={this.state.visible}
                                      onOk={this.handleOk}
                                      onCancel={this.handleCancel}
                                      footer={[
                                        <Mutation mutation={ADMIN_DELETE_NEWS}>
                                          {deleteNews => (
                                              <Button
                                                  type="primary"
                                                  className="btn-secondary admin-forms-btn buttons"
                                                  size="large"
                                                  onClick={() => onDelete(orderData.id, deleteNews)}
                                              >
                                                {formatMessage({ id: 'component.form.yes' })}
                                              </Button>
                                          )}
                                        </Mutation>,
                                        <Button
                                            className="admin-forms-btn buttons"
                                            size="large"
                                            onClick={this.handleOk}
                                        >
                                          {formatMessage({ id: 'component.form.no' })}
                                        </Button>,
                                      ]}
                                  >
                                    <p className="mb-0">
                                      {formatMessage({ id: 'component.news.deletenewsdesc' })}
                                    </p>
                                  </Modal>
                                </Form>
                            );
                          }}
                        </Mutation>
                      </Col>
                    </Row>
                );
              }
              if (loading && !error) {
                return (
                    <div className="loader-wrapper">
                      <Spin />
                    </div>
                );
              }
              if (!loading && error) {
                return (
                    <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>
                );
              }
              return <React.Fragment />;
            }}
          </Query>
        </>
    );
  }
}
export default withRouter(EditNews);
// /* eslint-disable no-shadow */
// /* eslint-disable @typescript-eslint/no-unused-vars */
// import {
//   Col,
//   Row,
//   Typography,
//   Button,
//   Form,
//   Input,
//   Upload,
//   message,
//   Modal,
//   notification,
// } from 'antd';
// import { PlusOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
// import { decryptId } from '@/utils/utils';
// import 'braft-editor/dist/index.css';
// import React from 'react';
// import BraftEditor from 'braft-editor';
// import { Link, router } from 'umi';
// import { formatMessage } from "components/src/utils";
// import { Mutation, Query } from 'react-apollo';
// import { STORAGE_URL } from '../../../../../utils/constant';
// import { ADMIN_NEWS_LIST } from '../../../../../../query/AdminQuery';
// import { ADMIN_UPDATE_NEWS, ADMIN_DELETE_NEWS } from '../../../../../../mutation/AdminMutation';
// import profile from '../../../../../assets/sales_preparation/book_inspection.png';

// const { Title } = Typography;

// let newsItem;
// const docprops = {
//   showUploadList: {
//     showRemoveIcon: true,
//     removeIcon: <i className="icon-delete" />,
//   },
//   defaultFileList: [],
// };
// const normFile = e => {
//   console.log('Upload event:', e);
//   if (Array.isArray(e)) {
//     return e;
//   }
//   return e && e.fileList;
// };
// // const fileList = [
// //   {
// //     uid: '-1',
// //     name: 'image.png',
// //     status: 'done',
// //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
// //   },
// // ];
// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

// function beforeUpload(file) {
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//   if (!isJpgOrPng) {
//     message.error('Du kan kun laste opp JPG/PNG filer.');
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error('Bilder må være mindre en 2MB');
//   }
//   return isJpgOrPng && isLt2M;
// }

// let id;
// let fullObject;
// class EditNews extends React.Component {
//   constructor(props) {
//     super(props);
//     // eslint-disable-next-line prefer-destructuring
//     id = props.match.params.id;
//     newsItem = props.location.state.orderItem;
//     console.log('news list props==>', newsItem);
//     // docprops.defaultFileList.push = `${STORAGE_URL}${newsItem.second_video_image_url}`;
//     // fileList.push(`${STORAGE_URL}${newsItem.main_image_url}`)
//     // console.log('fileList upper side:>>', fileList);
//   }

//   state = {
//     editorState: BraftEditor.createEditorState(),
//     // editorState: BraftEditor.createEditorState(newsItem.description),
//     loading: false,
//     visible: false,
//     orderData: '',
//     // imageUrl: `${STORAGE_URL}${newsItem.cover_image_url}`,
//     imageObject: '',
//     displayNews: [],
//   };

//   showModal = () => {
//     this.setState({
//       visible: true,
//     });
//   };

//   handleOk = e => {
//     console.log(e);
//     this.setState({
//       visible: false,
//     });
//   };

//   handleCancel = e => {
//     console.log(e);
//     this.setState({
//       visible: false,
//     });
//   };

//   componentDidMount() {
//     this.isLivinig = true;
//     setTimeout(this.setEditorContentAsync, 3000);
//   }

//   componentWillUnmount() {
//     this.isLivinig = false;
//   }

//   handleChange = (editorState, info) => {
//     this.setState({
//       editorState,
//     });
//     // if (info.file.status === 'uploading') {
//     //   this.setState({ loading: true });
//     //   return;
//     // }
//     // if (info.file.status === 'done') {
//     //   // Get this url from response in real world.
//     //   getBase64(info.file.originFileObj, imageUrl =>
//     //     this.setState({
//     //       imageUrl,
//     //       loading: false,
//     //     }),
//     //   );
//     // }
//   };

//   // handleUploadChange = info => {
//   //   if (info.file.status === 'uploading') {
//   //     this.setState({ loading: true });
//   //     return;
//   //   }
//   //   if (info.file.status === 'done') {
//   //     // Get this url from response in real world.
//   //     getBase64(info.file.originFileObj, imageUrl =>
//   //       this.setState({
//   //         imageUrl,
//   //         loading: false,
//   //       }),
//   //     );
//   //   }
//   // };
//   handleUploadChange = info => {
//     // if (info.file.status === 'uploading') {
//     //   this.setState({ loading: true });
//     //   return;
//     // }
//     // if (info.file.status === 'done') {
//     // Get this url from response in real world.
//     getBase64(info.file.originFileObj, imageUrl =>
//       this.setState({
//         imageUrl,
//         loading: false,
//         imageObject: info.file.originFileObj,
//       }),
//     );
//     // }
//   };

//   setEditorContentAsync = () => {
//     // eslint-disable-next-line no-unused-expressions
//     this.isLivinig &&
//       this.setState({
//         // eslint-disable-next-line react/no-access-state-in-setstate
//         // editorState: BraftEditor.createEditorState(this.state.orderData.description),
//         editorState: BraftEditor.createEditorState(newsItem.description),
//       });
//   };

//   render() {
//     const uploadButton = (
//       <div>
//         {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
//         <div className="ant-upload-text">{formatMessage({ id: 'component.news.uploadvideo' })}</div>
//       </div>
//     );
//     const { editorState, imageUrl, imageObject, orderData, displayNews , deleteId } = this.state;
//     // console.log('prop check', newsItem);

//     const handleCvUpload = e => {
//       const file = [];
//       file.push(e.file);
//       console.log('CV File:', file);
//       this.setState = { displayNews: file };
//     };
//     const removeCvUpload = () => {
//       this.setState = { displayNews: [] };
//     };

//     // const onFinish = values => {
//     const onFinish = (values, editNewsData) => {
//       console.log('Success:', values, '\n newsDesc==>', editorState.toText());
//       console.log('TITLE:', values.title);
//       // TODO: add the data from the success form response below
//       editNewsData({
//         variables: {
//           input: {
//             id: parseInt(decryptId(id), 10),
//             cover_image: imageObject,
//             title: values.title,
//             description: editorState.toText(),
//             video: displayNews[0].originFileObj,
//           },
//         },
//       }).then(data => {
//         console.log('Data submitted===>>>', data);
//         if (data && data.data.update_news) {
//           notification.success({
//             message: 'Innspillingen ble oppdatert',
//             description: '',
//             duration: 3,
//           });
//           router.push(`/admin/nyheter`);
//         }
//       });
//     };

//     const onFinishFailed = errorInfo => {
//       console.log('Failed:', errorInfo);
//     };
//     // console.log('fileList===>>>', fileList);

//   const onDelete = (deleteID, deletNews) => {
//     // const deleteID =  orderData.filter(item =>item.id);
//     console.log('delete id ==>', deleteID);
//     deletNews({
//       variables: {
//         id: parseInt(decryptId(id), 10),
//       },
//     }).then(data => {
//       console.log('Data submitted===>>>', data);
//       if (data && data.data.delete_news && data.data.delete_news.meta) {
//         notification.success({
//           message: data.data.delete_news.meta.message,
//           description: '',
//           duration: 3,
//         });
//       }
//     });
//     router.push('/admin/nyheter');
//   };

//     return (
//       <>
//         <Query
//           query={ADMIN_NEWS_LIST}
//           variables={{
//             first: 100,
//             staus: true,
//           }}
//           onCompleted={responseData => {
//             const newObject = responseData.news.data.filter(
//               i => i.id === parseInt(decryptId(id), 10),
//             );
//             console.log('responseData:', responseData);
//             this.setState({
//               orderData: newObject,
//               editorState: BraftEditor.createEditorState(newObject[0].description),
//               imageUrl: `${STORAGE_URL}${newObject[0].cover_image_url}`,
//             });
//             fullObject = newObject;
//           }}
//         >
//           {({ data }) => {
//             if (data && data.news.data) {
//               console.log('orderdata::>>', orderData);
//               return (
//                 <Row className="contentshadow">
//                   <Col xs={24} lg={24}>
//                     <Title level={3} className="text-primary">
//                       {formatMessage({ id: 'component.news.editnews' })}
//                     </Title>
//                     {console.log(docprops.defaultFileList.push)}
//                     {/* {console.log("fullObject:",fullObject[0].id)} */}
//                     <Mutation mutation={ADMIN_UPDATE_NEWS}>
//                       {(editNewsData, { loading, data }) => {
//                         console.log('orderData>', orderData);
//                         return (
//                           <Form
//                             name="editnews"
//                             initialValues={{
//                               remember: true,
//                               cover_photo: `${STORAGE_URL}${newsItem.cover_image_url}`,
//                               title: newsItem.title,
//                               news_description: orderData.description,
//                               cover_video: orderData.video_url,
//                             }}
//                             onFinish={values => onFinish(values, editNewsData)}
//                             // onFinish={values => onFinish(values)}
//                             onFinishFailed={onFinishFailed}
//                             layout="vertical"
//                             className="user-forms addEditform"
//                           >
//                             {console.log('o+>', orderData.title)}
//                             {/* {defaultFileList.puh()} */}
//                             {/* {console.log("def",(docprops.defaultFileList.push(newsItem.cover_video)))} */}
//                             <Row>
//                               <Col xs={24} md={8} lg={4}>
//                                 <Form.Item
//                                   name="cover_photo"
//                                   className="mb-0"
//                                   label={formatMessage({
//                                     id: 'component.news.coverphoto',
//                                   })}
//                                 >
//                                   <Row>
//                                     <Col xs={24}>
//                                       <div className="user-profile-box">
//                                         <img
//                                           src={imageUrl}
//                                           alt="John Doe"
//                                           // alt={user.first_name + user.last_name}
//                                           className="profile-picture"
//                                         />
//                                       </div>
//                                     </Col>
//                                     <Col xs={24} className="mt-3">
//                                       <Upload
//                                         showUploadList={false}
//                                         onChange={this.handleUploadChange}
//                                       >
//                                         <Button>
//                                           <UploadOutlined /> Upload
//                                         </Button>
//                                       </Upload>
//                                     </Col>
//                                   </Row>
//                                 </Form.Item>
//                               </Col>
//                             </Row>
//                             {/* <Form.Item
//                               name="cover_photo"
//                               className="mb-0"
//                               label={formatMessage({
//                                 id: 'component.news.coverphoto',
//                               })}
//                             >
//                               <Upload
//                                 action={profile}
//                                 listType="picture-card"
//                                 // fileList={fileList}
//                               />
//                             </Form.Item> */}
//                             <Form.Item
//                               label={formatMessage({ id: 'component.news.newstitle' })}
//                               name="title"
//                             >
//                               <Input
//                                 placeholder={formatMessage({ id: 'component.news.newstitle' })}
//                                 size="large"
//                               />
//                             </Form.Item>
//                             <Form.Item
//                               label={formatMessage({ id: 'component.news.newsdesc' })}
//                               name="newsdesc"
//                             >
//                               <div className="editor-wrapper">
//                                 <BraftEditor
//                                   language="en"
//                                   value={editorState}
//                                   onChange={this.handleChange}
//                                   contentStyle={{ height: 210 }}
//                                   placeholder={formatMessage({
//                                     id: 'component.news.newsdescplaceholder',
//                                   })}
//                                 />
//                               </div>
//                             </Form.Item>
//                             <Form.Item
//                               name="cover_video"
//                               label={formatMessage({ id: 'component.news.uploadvideo' })}
//                               // valuePropName="fileList"
//                               getValueFromEvent={normFile}
//                             >
//                               <Upload
//                                 name="logo"
//                                 action="/upload.do"
//                                 {...docprops}
//                                 listType="picture"
//                               >
//                                 <Button>
//                                   <UploadOutlined />{' '}
//                                   {formatMessage({ id: 'component.form.upload' })}
//                                 </Button>
//                               </Upload>
//                             </Form.Item>
//                             <Form.Item
//                               name="cover_video"
//                               label={formatMessage({ id: 'component.news.uploadvideo' })}
//                               // valuePropName="fileList"
//                               getValueFromEvent={normFile}
//                             >
//                               <Upload
//                                 name="logo"
//                                 action="/upload.do"
//                                 {...docprops}
//                                 listType="picture"
//                               >
//                                 <Button>
//                                   <UploadOutlined />{' '}
//                                   {formatMessage({ id: 'component.form.upload' })}
//                                 </Button>
//                               </Upload>
//                             </Form.Item>
//                             <Button
//                               type="primary"
//                               size="large"
//                               htmlType="submit"
//                               className="btn-secondary buttons admin-forms-btn mt-3"
//                             >
//                               {formatMessage({
//                                 id: 'component.news.publish',
//                               })}
//                             </Button>
//                             <Link to="/admin/nyheter">
//                               <Button
//                                 size="large"
//                                 className="buttons admin-forms-btn back-button mt-3"
//                               >
//                                 {formatMessage({
//                                   id: 'component.form.cancel',
//                                 })}
//                               </Button>
//                             </Link>
//                             <Button
//                               size="large"
//                               className="buttons admin-forms-btn delete-button mt-3 pull-right"
//                               onClick={this.showModal()}
//                             >
//                               {formatMessage({
//                                 id: 'component.form.delete',
//                               })}
//                             </Button>
//                             <Modal
//                               title={formatMessage({ id: 'component.news.deletenews' })}
//                               visible={this.state.visible}
//                               onOk={this.handleOk}
//                               onCancel={this.handleCancel}
//                               footer={[
//                                 // <Mutation mutation={ADMIN_DELETE_NEWS}>
//                                 //   {deletNews => {
//                                 //     if (data) {
//                                 //       console.log('Data submitted===>>>', data);
//                                 //       if (data && data.delete_news && data.delete_news.meta) {
//                                 //         notification.success({
//                                 //           message: data.delete_news.meta.message,
//                                 //           description: '',
//                                 //           duration: 3,
//                                 //         });
//                                 //       }
//                                 //     }
//                                 //     return (
//                                       <Button
//                                         type="primary"
//                                         className="btn-secondary admin-forms-btn buttons"
//                                         size="large"
//                                         // onClick={onDelete(deleteId)}
//                                       >
//                                         {formatMessage({ id: 'component.form.yes' })}
//                                       </Button>
//                                 //     );
//                                 //   }}
//                                 // </Mutation>
//                                 ,
//                                 <Button
//                                   className="admin-forms-btn buttons"
//                                   size="large"
//                                   onClick={this.handleOk}
//                                 >
//                                   {formatMessage({ id: 'component.form.no' })}
//                                 </Button>,
//                               ]}
//                             >
//                               <p className="mb-0">
//                                 {formatMessage({ id: 'component.news.deletenewsdesc' })}
//                               </p>
//                             </Modal>
//                           </Form>
//                         );
//                       }}
//                     </Mutation>
//                   </Col>
//                 </Row>
//               );
//             }
//             return (
//               <div className="loader-wrapper">
//                 <LoadingOutlined />
//               </div>
//             );
//           }}
//         </Query>
//       </>
//     );
//   }
// }
// export default EditNews;
