import React, {Component} from 'react';

import {Animated, TouchableOpacity, View} from 'react-native';
//Third Party
import PropTypes from 'prop-types';
//Custom Components
import {FilePicker, FileType, Hr, Label, Modal, Ripple} from 'components/src/components';
//Utils
import {Color, Icon, IS_WEB, Strings, ThemeUtils} from 'components/src/utils';
import styles from './styles';

const BUTTONS = [{
    name: Strings.takePhoto,
    icon: 'photo',
    color: Color.PRIMARY
}, {
    name: Strings.uploadFromGallery,
    icon: 'gallery',
    color: Color.PRIMARY
}, {
    name: Strings.removePhoto,
    icon: 'delete',
    color: Color.RED
}];

class ImagePicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 1,
            visible: false
        };
        this.animatedHeight = new Animated.Value(ThemeUtils.relativeRealHeight(50));
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.startAnimation();
        }
    }

    //utility
    startAnimation = () => {
        Animated.timing(this.animatedHeight, {
            toValue: 0,
            duration: 250,
        }).start();
    };

    handleHardwareBack = () => {
        this.closePopUp();
        return true;
    };

    //User interaction
    closePopUp = (isSaved) => {
        Animated.timing(this.animatedHeight, {
            toValue: ThemeUtils.relativeRealHeight(50),
            duration: 200,

        }).start(() => {
            this.props.onClose && this.props.onClose()
        });
    };

    handleClick = (item) => {
        switch (item) {
            case Strings.removePhoto:
                break;
            case Strings.takePhoto:
                break;
            case Strings.uploadFromGallery:
                break;
        }
    }
    renderButtons = (item, data) => {
        return (
            <>

            </>
        )
    }

    render() {
        const {style, isRemovable} = this.props;
        return (
            <View style={style}>
                <Modal transparent
                       hardwareAccelerated
                       visible={this.props.visible}
                       onRequestClose={this.handleHardwareBack}>
                    <View style={styles.cont}>
                        <TouchableOpacity activeOpacity={1}
                                          onPress={this.handleHardwareBack}
                                          style={styles.container}/>
                        <Animated.View style={[styles.bottomViewContainer,
                            {transform: [{translateY: this.animatedHeight}]}]}>
                            <View style={styles.titleContainer}>
                                <Label small
                                       font_medium>
                                    {Strings.choosePhoto}
                                </Label>
                            </View>
                            {!IS_WEB ?
                                (<Ripple style={styles.icCont}>
                                    <Icon name={'photo'}
                                          color={Color.PRIMARY}
                                          size={ThemeUtils.fontNormal}/>
                                    <Label small
                                           font_medium

                                           ml={15}>
                                        {Strings.takePhoto}
                                    </Label>
                                </Ripple>):null}
                            <Hr/>
                            <FilePicker type={FileType.IMAGE}
                                        onFilePicked={(data, file) => {
                                            if (file.length) {
                                                this.props.onImageSelected(file)
                                            }
                                        }}>
                                <View style={styles.icCont}>
                                    <Icon name={'gallery'}
                                          color={Color.PRIMARY}
                                          size={ThemeUtils.fontNormal}/>
                                    <Label small
                                           font_medium

                                           ml={15}>
                                        {Strings.uploadFromGallery}
                                    </Label>
                                </View>

                            </FilePicker>
                            {isRemovable && false ? <Hr/> : null}
                            {isRemovable && false?
                                (<Ripple style={styles.icCont}>
                                    <Icon name={'delete'}
                                          color={Color.RED}
                                          size={ThemeUtils.fontNormal}/>
                                    <Label small
                                           font_medium
                                           color={Color.RED}
                                           ml={15}>
                                        {Strings.removePhoto}
                                    </Label>
                                </Ripple>) : null}
                            {isRemovable ? <Hr/> : null}
                        </Animated.View>
                    </View>

                </Modal>
            </View>
        );
    }
}

ImagePicker.defaultProps = {
    title: 'Title',
    placeholder: 'Select Options...',
    sortOptions: [],
    options: 0,
    onClose: null,
};

ImagePicker.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    selectedOption: PropTypes.number,
    options: PropTypes.array,
    onClose: PropTypes.func,
    onAddNewProperty: PropTypes.func,
};

export default ImagePicker;
