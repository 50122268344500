import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {CustomTextArea, Label, RadioGroup} from "components/src/components";
//Utils
import {Constants, Strings} from "components/src/utils";
import styles from "../styles";
import {setSellerInterViewForm} from "components/src/redux/action";
import {connect} from "react-redux";


const Q1 = Strings.fifthStep.q1;
const Q2 = Strings.fifthStep.q2;
const Q3 = Strings.fifthStep.q3;
const Q4 = Strings.fifthStep.q4;
const Q5 = Strings.fifthStep.q5;

 function FifthStep(props) {

    const {setForm, interViewForm} = props;

    const [agreementAvailable, setAgreementAvailable] = useState(interViewForm.find(item => item.interview_section === 5 && item.question_no === 15)?.answer??"Ja");
    const [garageAvailable, setGarageAvailable] = useState(interViewForm.find(item => item.interview_section === 5 && item.question_no === 16)?.answer??"Ja");
    const [isDocumented, setDocumented] = useState(interViewForm.find(item => item.interview_section === 5 && item.question_no === 16)?.extra?? "Ja");
    const [associateWithCommonAreas, setAssociateWithCommonArea] = useState(interViewForm.find(item => item.interview_section === 5 && item.question_no === 17)?.answer??"Ja");

    const [agreementComment, setAgreementComment] = useState(interViewForm.find(item => item.interview_section === 5 && item.question_no === 15)?.comment ?? '')
    const [garageComment, setGarageComment] = useState(interViewForm.find(item => item.interview_section === 5 && item.question_no === 16)?.comment ?? '')
    const [commonAreaComment, setCommonAreaComment] = useState(interViewForm.find(item => item.interview_section === 5 && item.question_no === 17)?.comment ?? '');

    useEffect(() => {
        let answer = [
            {    "question":Q1,
                "interview_section": 5,
                "question_no": 15,
                "type": "RADIO",
                "answer": agreementAvailable,
                "extra": null,
                "comment": agreementComment
            }, {
                "question":Q2,
                "comment_questions":Q3,
                "interview_section": 5,
                "question_no": 16,
                "type": "RADIO",
                "answer": garageAvailable,
                "extra": isDocumented,
                "comment": garageComment
            }, {
                "question":Q4,
                "comment_questions":Q5,
                "interview_section": 5,
                "question_no": 17,
                "type": "RADIO",
                "answer": associateWithCommonAreas,
                "extra": null,
                "comment": commonAreaComment
            }]
        setForm([...interViewForm.filter(item => item.interview_section !== 5), ...answer])

    }, [
        agreementAvailable,
        garageAvailable,
        isDocumented,
        associateWithCommonAreas,
        agreementComment,
        garageComment,
        commonAreaComment])

    return (
        <View>
            <Label small
                   mt={10}
                   font_medium
                   mb={10}>
                {Q1}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS}
                        style={styles.radio}
                        contentStyle={styles.radioCont}
                        selectedOptions={agreementAvailable}
                        onSelectOption={setAgreementAvailable}/>

            {agreementAvailable === 'Ja' ? (
                <CustomTextArea labelText={Strings.comment}
                                value={agreementComment}
                                onChange={setAgreementComment}
                                mt={10}/>) : null}

            <Label small
                   mt={15}
                   font_medium
                   mb={10}>
                {Q2}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS}
                        style={styles.radio}
                        contentStyle={styles.radioCont}
                        selectedOptions={garageAvailable}
                        onSelectOption={setGarageAvailable}/>

            {garageAvailable === 'Ja' ? (
                <>
                    <Label small
                           mt={15}
                           font_medium
                           mb={10}>
                        {Q3}
                    </Label>
                    <RadioGroup options={Constants.RADIO_OPTIONS}
                                style={styles.radio}
                                contentStyle={styles.radioCont}
                                selectedOptions={isDocumented}
                                onSelectOption={setDocumented}/>
                    <CustomTextArea labelText={Strings.comment}
                                    value={garageComment}
                                    onChange={setGarageComment}
                                    mt={10}/>
                </>
            ) : null}

            <Label small
                   mt={15}
                   font_medium
                   mb={10}>
                {Q4}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS}
                        style={styles.radio}
                        contentStyle={styles.radioCont}
                        selectedOptions={associateWithCommonAreas}
                        onSelectOption={setAssociateWithCommonArea}/>

            {associateWithCommonAreas === 'Ja' ? (
                <CustomTextArea labelText={Q5}
                                value={commonAreaComment}
                                onChange={setCommonAreaComment}
                                mt={10}/>) : null}


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form))
    }

};
export default connect(mapStateToProps, mapDispatchToProps)(FifthStep)
