/* eslint-disable no-shadow */
import {Button, Col, Form, Input, notification, Row, Select, Typography, Upload} from 'antd';
import {LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import React, {useState} from 'react';


import {decryptId} from '../../../../../utils/utils';
import {Mutation, Query} from 'react-apollo';
import {STORAGE_URL} from '../../../../../utils/constant';
import {ADMIN_UPDATE_METAPAGE} from '../../../../../../mutation/AdminMutation';
import {ADMIN_META_PAGE_DETAIL, ADMIN_OFFICES_LIST_ONLY,} from '../../../../../../query/AdminQuery';
import {formatMessage} from "components/src/utils";
import {useHistory} from "react-router";

const {Title} = Typography;

const EditMetaList = props => {
    let history = useHistory()

    console.log('id=>>', props.match.params.id);
    const {id} = props.match.params;
    console.log('prop...>', props.location.state.pageType);
    const {pageType} = props.location.state;

    const options = [
        {value: 'Nøkkelord 1'},
        {value: 'Nøkkelord 2'},
        {value: 'Nøkkelord 3'},
        {value: 'Nøkkelord 4'},
        {value: 'Nøkkelord 5'},
        {value: 'Nøkkelord 6'},
        {value: 'Nøkkelord 7'},
    ];
    const [imageUrl, setimageUrl] = useState();
    const [imageObject, setimageObject] = useState();
    const [isimageUrlChanged, setisimageUrlChanged] = useState(false);

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleUploadChange = info => {
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, image => setimageUrl(image));
            setimageObject(info.file.originFileObj);
            setisimageUrlChanged(true);
        }
    };

    const onFinish = (values, editPhotoUser) => {
        editPhotoUser({
            variables: {
                id: decryptId(id),
                image_url: isimageUrlChanged ? imageObject : null,
                name: values.pagename,
                title: values.pagetitle,
                description: values.description,
                keywords: values.keywords.toString(),
            },
        }).then(data => {
            console.log('Data submitted===>>>', data);
            if (data && data.data.update_meta_page) {
                notification.success({
                    message: `Meta page updated`,
                    description: '',
                    duration: 3,
                });
                history.push('/admin/metalisting');
            }
        });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    //  console.log('prop loc:==>',props.location)
    // const { orderItem } = props.location.state;
    //   console.log('Props Location===>>>', orderItem);

    // const handleChange = info => {
    //   // Uploadprops.defaultFileList=[]
    //   // console.log("info===>",info.file.originFileObj)
    //   if (info.file.status === 'done') {
    //     //
    //     getBase64(info.file.originFileObj, image => function getBase64(img, callback) {
    //   const reader = new FileReader();
    //   reader.addEventListener('load', () => callback(reader.result));
    //   reader.readAsDataURL(img);
    // }

    // };

    return (
        <div className="contentshadow">
            <div className="text-primary main">
                <Row>
                    <Col xs={24}>
                        <Title level={3} className="text-primary">
                            {formatMessage({
                                id: 'component.metalisting.editmetalisting',
                            })}
                        </Title>
                    </Col>
                </Row>
                <Query
                    query={ADMIN_META_PAGE_DETAIL}
                    variables={{
                        page_type: pageType[0],
                    }}
                    onCompleted={data => {
                        console.log('data--', data.meta_page_detail.image_url);
                        setimageUrl(`${STORAGE_URL}${data.meta_page_detail.image_url}`);
                    }}
                    fetchPolicy="cache-and-network"
                >
                    {({data}) => {
                        if (data && data.meta_page_detail) {
                            console.log('insodwe;', data.meta_page_detail);

                            return (
                                <>
                                    {/* <Row style={{ marginTop: 5 }}>
                    <Col xs={24}>
                      <h3>Upload image</h3>
                      <Mutation
                        mutation={CHANGE_PROFILE_PIC}
                        onCompleted={data => {
                          const tempUser = user;
                          tempUser.image_url = data.change_profile_pic;
                          console.log('Updated Link==>', tempUser.image_url);

                          window.dispatchEvent(new Event('updatePhotoVendorProfile'));
                          setUser(tempUser);
                        }}
                      >
                        {(changeProfilePic, { loading }) => (
                          <Row>
                            <Col xs={24}>
                              <div className="user-profile-box">
                                <img
                                  src={
                                    user?.image_url
                                      ? `${STORAGE_URL}${user?.image_url}`
                                      : userPlaceholder
                                  }
                                  alt={user?.first_name + user?.last_name}
                                  className="profile-picture"
                                />
                              </div>
                            </Col>
                            <Col
                              xs={24}
                              className="mt-3"
                              onClick={() => {
                                if (fileRef) {
                                  fileRef.current.click();
                                }
                              }}
                            >
                              <input
                                type="file"
                                multiple
                                accept=".jpg,.png,.jpeg"
                                disabled={loading}
                                onChange={e => {
                                  console.log('Files===>>>', e.target.files);
                                  changeProfilePic({
                                    variables: {
                                      file: e.target.files[0],
                                    },
                                  }).then(({ data, loading }) => {
                                    if (data && !loading) {
                                      const tempUser = user;
                                      tempUser.image_url = data.change_profile_pic;
                                      window.dispatchEvent(new Event('updatePhotoVendorProfile'));
                                      setUser(tempUser);
                                      console.log(user);
                                    }
                                  });
                                }}
                                ref={fileRef}
                                className="d-none"
                              />
                              <Button>
                                <UploadOutlined /> {loading ? 'Laster opp...' : 'Upload'}
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Mutation>
                    </Col>
                  </Row> */}
                                    <Row>
                                        <Col xs={24}>
                                            <Mutation mutation={ADMIN_UPDATE_METAPAGE}>
                                                {(editPhotoUser, {loading}) => {
                                                    return (
                                                        <Form
                                                            layout="vertical"
                                                            className="user-forms"
                                                            name="editmetapage"
                                                            // onLoad={setimageUrl(`${STORAGE_URL}${formData.profile_image}`)}
                                                            initialValues={{
                                                                remember: true,
                                                                pagename: data.meta_page_detail.name,
                                                                pagetitle: data.meta_page_detail.title,
                                                                description: data.meta_page_detail.description,
                                                                keywords: data.meta_page_detail.keywords,
                                                            }}
                                                            colon={false}
                                                            onFinish={values => onFinish(values, editPhotoUser)}
                                                            onFinishFailed={onFinishFailed}
                                                        >
                                                            {console.log('formData-->', data.meta_page_detail)}
                                                            {/* <Row>
                                <Col>
                                  <Form.Item name="id">
                                    <Input hidden />
                                  </Form.Item>
                                </Col>
                              </Row> */}
                                                            <Row className="mt-3">
                                                                <Col xs={24}>
                                                                    <Form.Item
                                                                        name="profilepicture"
                                                                        label={`${formatMessage({
                                                                            id: 'component.form.upload',
                                                                        })} ${formatMessage({
                                                                            id: 'component.form.image',
                                                                        }).toLowerCase()}`}
                                                                        // rules={[
                                                                        //   {
                                                                        //     required: true,
                                                                        //     message: `Vennligst skriv inn ${formatMessage({
                                                                        //       id: 'component.form.profilepicture',
                                                                        //     })}`,
                                                                        //   },
                                                                        // ]}
                                                                    >
                                                                        <div className="user-profile-box">
                                                                            <img
                                                                                src={imageUrl}
                                                                                // alt="John Doe"
                                                                                alt={data.meta_page_detail.name}
                                                                                className="profile-picture"
                                                                            />
                                                                        </div>

                                                                        <Upload showUploadList={false}
                                                                                onChange={handleUploadChange}>
                                                                            <Button className="mt-3">
                                                                                <UploadOutlined/>{' '}
                                                                                {formatMessage({
                                                                                    id: 'component.form.upload',
                                                                                })}
                                                                            </Button>
                                                                        </Upload>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={16}>
                                                                <Col xs={24} lg={8}>
                                                                    <Form.Item
                                                                        name="pagename"
                                                                        label={formatMessage({
                                                                            id: 'component.form.pagename',
                                                                        })}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: `Vennligst skriv inn ${formatMessage({
                                                                                    id: 'component.form.pagename',
                                                                                }).toLowerCase()}`,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            style={{width: '100%'}}
                                                                            placeholder={formatMessage({
                                                                                id: 'component.form.pagename',
                                                                            })}
                                                                            size="large"
                                                                            className="pickerwidth"
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} lg={8}>
                                                                    <Form.Item
                                                                        name="pagetitle"
                                                                        label={formatMessage({
                                                                            id: 'component.form.pagetitle',
                                                                        })}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: `Vennligst skriv inn ${formatMessage({
                                                                                    id: 'component.form.pagetitle',
                                                                                }).toLowerCase()}`,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            placeholder={formatMessage({
                                                                                id: 'component.form.pagetitle',
                                                                            })}
                                                                            size="large"
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={16}>
                                                                <Col xs={24} lg={16}>
                                                                    <Form.Item
                                                                        name="description"
                                                                        label={formatMessage({
                                                                            id: 'component.form.description',
                                                                        })}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: `Vennligst skriv inn ${formatMessage({
                                                                                    id: 'component.form.description',
                                                                                }).toLowerCase()}`,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input.TextArea
                                                                            rows={3}
                                                                            style={{width: '100%'}}
                                                                            placeholder={formatMessage({
                                                                                id: 'component.form.description',
                                                                            })}
                                                                            size="large"
                                                                            className="pickerwidth"
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={[16]}>
                                                                <Col xs={24} md={24} lg={16}>
                                                                    <Query
                                                                        query={ADMIN_OFFICES_LIST_ONLY}
                                                                        variables={{
                                                                            status: true,
                                                                        }}
                                                                    >
                                                                        {({data}) => {
                                                                            // console.log('user-list', data.users_list);
                                                                            if (data && data.offices_list) {
                                                                                return (
                                                                                    <Form.Item
                                                                                        name="keywords"
                                                                                        label={`${formatMessage({
                                                                                            id: 'component.metalisting.keywords',
                                                                                        })}`}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: `Vennligst velg ${formatMessage({
                                                                                                    id: 'component.metalisting.keywords',
                                                                                                }).toLowerCase()}`,
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Select
                                                                                            mode="multiple"
                                                                                            //  defaultValue={['0178', '0188']}
                                                                                            options={options}
                                                                                            size="large"
                                                                                            style={{width: '100%'}}
                                                                                        />
                                                                                        {/* <Select.Option value="8">{8}</Select.Option>
                                            </Select> */}
                                                                                    </Form.Item>
                                                                                );
                                                                            }
                                                                            return (
                                                                                <div className="loader-wrapper-inline">
                                                                                    <LoadingOutlined/>
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </Query>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-3">
                                                                <Col xs={24}>
                                                                    <Button
                                                                        type="primary"
                                                                        htmlType="submit"
                                                                        className="btn-secondary buttons admin-forms-btn"
                                                                        size="large"
                                                                        loading={loading}
                                                                    >
                                                                        {formatMessage({
                                                                            id: 'component.form.save',
                                                                        })}
                                                                    </Button>
                                                                    <Button
                                                                        className="text-primary admin-forms-btn"
                                                                        style={{marginLeft: 8}}
                                                                        size="large"
                                                                        onClick={history.goBack}
                                                                    >
                                                                        {formatMessage({
                                                                            id: 'component.form.cancel',
                                                                        })}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    );
                                                }}
                                            </Mutation>
                                        </Col>
                                    </Row>
                                </>
                            );
                        }
                        return (
                            <div className="loader-wrapper">
                                <LoadingOutlined/>
                            </div>
                        );
                    }}
                </Query>
            </div>
        </div>
    );
};

export default EditMetaList;
