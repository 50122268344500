import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = 20;

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.WHITE
    },
    headerTitle: {
        zIndex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: ThemeUtils.APPBAR_HEIGHT,
        backgroundColor: Color.PRIMARY,
    },
    txtRegular: {
        fontFamily: 'Maax'
    },
    bottomNavCont: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: ThemeUtils.relativeWidth(4),
        paddingHorizontal: ThemeUtils.relativeWidth(4),
        backgroundColor: Color.TRANSPARENT
    },
    stepCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    bankLblCont: {
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 15
    },
    lblCont: {
        flexDirection: 'row',
        marginBottom: 12,
        alignItems: 'center'
    },
    imgSignUp: {
        marginTop:ThemeUtils.relativeRealHeight(2),
        alignSelf:'center',
        height: ThemeUtils.responsiveScale(120),
        width: ThemeUtils.responsiveScale(120)
    }

});


