import {ApolloProvider} from "@apollo/react-components";
import client from "components/src/api";
import {persistor, store} from 'components/src/redux/store';
import {getUserName, getUserType, IS_WEB, Routes, UserType} from "components/src/utils";
import React, {useEffect} from 'react';
import {View} from 'react-native';
import {Provider} from 'react-redux';
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {PersistGate} from 'redux-persist/integration/react';
import './main.scss';
import AppRoot from './router';
import Cookie from 'js-cookie'
import {ConfigProvider} from "antd";
import nbNo from 'antd/es/locale/nb_NO';
import Toast from '../../components/src/components/ui/Toast'
import {setUser} from "components/src/redux/action";
import TagManager from 'react-gtm-module'

import 'antd/dist/antd.css';
import './sass/utils.scss'
import './sass/variable.scss'
import './sass/app.scss'
import './layout.scss'
import './sass/form.scss'
import './sass/defaultstyles.scss'


const webOnlyPaths = [
    "/",
    "/om-webmegling",
    "/oppgjor",
    "/eiendom",
    "/personver",
    "/brukervilkar",
    "/informasjonskapsler",
    "/karriere",
    "/karriere-detaljer",
    "/oppdrag",
    "/oppdragsavtale",
    "/oppdragsavtale2",
    "/gratulerer",
    "/registrerte-egenskaper",
    "/annonse",
    "/finansiering",
    "/kontakt",
    "/eiendom"
]


function usePageViews(props) {


    let nonAuthPath = [
        `/${Routes.Seller}/${Routes.Login}`,
        `/${Routes.Examination}/${Routes.Login}`,
        `/${Routes.Megler}/${Routes.Login}`,
        `/${Routes.Photographer}/${Routes.Login}`,
        `/${Routes.Stylist}/${Routes.Login}`,
        `/${Routes.Takst}/${Routes.Login}`,
        `/${Routes.Buyer}/${Routes.Login}`,
        `/${Routes.Admin}/${Routes.Login}`,
        `/${Routes.Seller}/${Routes.ForgotPassword}`,
        `/${Routes.Examination}/${Routes.ForgotPassword}`,
        `/${Routes.Megler}/${Routes.ForgotPassword}`,
        `/${Routes.Photographer}/${Routes.ForgotPassword}`,
        `/${Routes.Stylist}/${Routes.ForgotPassword}`,
        `/${Routes.Takst}/${Routes.ForgotPassword}`,
        `/${Routes.Buyer}/${Routes.ForgotPassword}`,

    ];

    let nonAuthPathsWithoutToken = [
        `/${Routes.Buyer}/${Routes.SignUp}/`,
        `/kj%C3%B8per/${Routes.SignUp}/`,
        `/eiendom`
    ]

    let location = useLocation();

    let history = useHistory();


    React.useEffect(() => {

        let user = store.getState().user;
        let userType = location.pathname.split('/')[1]

        if (!user && IS_WEB) {
            try {
                if (Cookie.get(`${getUserName(userType)}_user`)) {
                    user = JSON.parse(Cookie.get(`${getUserName(userType)}_user`))?.user;
                    store.dispatch(setUser(user))
                }
            } catch (e) {

            }
        }

        if (window.location.href.startsWith("https://weiendomsmegling.no/") &&
            (location.pathname.startsWith("/selger") ||
                location.pathname.startsWith("/kjøper"))) {
            const tagManagerArgs = {
                gtmId: 'G-6LS4H2C7E4'
            }
            TagManager.initialize(tagManagerArgs)
        }
        const isWebOnlyPath = nonAuthPathsWithoutToken.find(item => item.startsWith(location.pathname))
        if (store.getState().token || Cookie.get(`${getUserName(userType)}_token`)) {
            if (nonAuthPath.find(item => item.startsWith(location.pathname)) && !isWebOnlyPath) {
                if (getUserType(userType) === user?.user_type_id) {
                    Cookie.set('token', Cookie.get(`${getUserName(userType)}_token`))
                    switch (getUserType(userType)) {
                        case UserType.Seller:
                            history.replace(`/${Routes.Seller}/${Routes.SalesPreparation}`)
                            break;
                        case UserType.Takst:
                        case UserType.Photographer:
                        case UserType.Stylist:
                        case UserType.Examination:
                        case UserType.Buyer:
                            history.replace(Routes.Dashboard)
                            break;
                    }
                }
            }
        } else if (!nonAuthPath.find(item => item.startsWith(location.pathname)) && !webOnlyPaths) {
            switch (location.pathname.split('/')[1]) {
                case Routes.Seller:
                case Routes.Takst:
                case Routes.Photographer:
                case Routes.Stylist:
                case Routes.Examination:
                case Routes.Buyer:
                case Routes.Admin:
                    history.replace(`/${location.pathname.split('/')[1]}/${Routes.Login}`, {
                        route: location.pathname.split('/')[2] ? location.pathname : null
                    })
                    break;
            }
        } else if (!location.pathname.split('/')[2] && nonAuthPath.find(item => item.startsWith(location.pathname))) {
            history.replace(`/${location.pathname.split('/')[1]}/${Routes.Login}`, {
                route: location.pathname.split('/')[2] ? location.pathname : null
            })
        }
    }, [history, location.pathname]);
}

function App(props) {
    usePageViews();

    // let location = useLocation();

    React.useEffect(() => {
        const isAdmin = location.pathname.startsWith('/admin/')
        const isWebOnly = webOnlyPaths.find(item => location.pathname.startsWith(item)) || location.pathname === "/";
        if (isWebOnly) {
            document.documentElement.classList.add('body-scroll');
        }
        if (!isAdmin) {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        } else {
            document.documentElement.style.setProperty('--vh', `20`);
            document.documentElement.classList.add('body-scroll');
            document.documentElement.classList.remove('style');
            document.getElementById('root').classList.add('full-height');
            // document.getElementById('root').style.removeProperty('--vh');
            return () => {
                document.documentElement.classList.remove('body-scroll');
            }
        }
    }, [location.pathname]);
    let history = useHistory();


    useEffect(() => {
        let state = store.getState();

        const unlisten = props.history.listen((location, action) => {
            let state = store.getState();
            if (state.property?.property_buyer_info && !state.property?.settlementInfo) {
                if (location.pathname !== `/${Routes.Seller}/${Routes.SettlementInfo}`)
                    history.push(Routes.SettlementInfo)
            }
        });
        if (state.property?.property_buyer_info && !state.property?.settlementInfo) {
            if (location.pathname !== `/${Routes.Seller}/${Routes.SettlementInfo}`)
                history.push(Routes.SettlementInfo)
        }
        return unlisten
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={null}
                         persistor={persistor}>
                <ApolloProvider client={client}>
                    <View style={{flex: 1, backgroundColor: "#fff"}}>
                        <ConfigProvider locale={nbNo}>
                            {AppRoot}
                            {!location.pathname.startsWith("/eiendom") ?
                                <Toast ref={r => Toast.setRef(r)}/> : null}
                        </ConfigProvider>
                    </View>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    )

}

export default withRouter(App);
