import React from 'react'
//Components
import {Animated, TouchableOpacity, View} from 'react-native'
import {CustomButton, Header, Hr, InputField, Label} from "components/src/components";
//Third party
//redux
import {connect} from "react-redux";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    Icon,
    IS_WEB,
    openInNewTab,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';
import moment from "moment";
import {Mutation} from "@apollo/react-components";
import {SELLER_RATE_ADD_COMMENT, SELLER_RATE_DELETE_COMMENT} from "components/src/api/sellerMutation";
import {SELLER_PROPERTY_LIST} from "components/src/api/sellerQuery";
// import {Button, InputItem} from "@ant-design/react-native";
// import { Radio, WhiteSpace } from '@ant-design/react-native';

// import { DatePickerView } from 'antd-mobile';


class ViewDetailsRate extends React.Component {
    constructor(props) {
        super(props)
        this.scrollY = new Animated.Value(0)
        this.state = {
            rateDetails: IS_WEB ? props.history.location.state?.rateDetails ?? props.property?.order_rate_detail ?? {} : props.navigation.getParam('rateDetails', props.property?.order_rate_detail ?? {}),
            addComment: false,
            comment: ''

        }
    }

    renderCommentSection = (item) => {
        return (
            <>
                <Hr/>
                <View style={styles.lblCont}>
                    <Label small
                           font_medium
                           style={CommonStyle.flex}>
                        {Strings.comments}
                    </Label>
                    <TouchableOpacity
                        onPress={() => this.setState(prevState => ({addComment: !prevState.addComment}))}>
                        <Icon name={'edit'}
                              color={Color.LIGHT_BLUE}
                              size={ThemeUtils.fontNormal}/>
                    </TouchableOpacity>
                </View>

                {this.state.addComment ? (
                        <View>
                            <InputField textArea
                                        ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                        mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                        placeholder={Strings.writeYourComments}
                                        value={this.state.comment}
                                        onChange={text => this.setState({comment: text})}/>
                            <View style={styles.btnCont}>
                                <Mutation mutation={SELLER_RATE_ADD_COMMENT}
                                          onError={() => {

                                          }}
                                          refetchQueries={[{
                                              query: SELLER_PROPERTY_LIST,
                                              variables: {
                                                  status: 1
                                              }
                                          }]}
                                          onCompleted={(data) => {
                                              let rateDetails = {...item};
                                              rateDetails.comments.push(data?.order_rate_detail_comment ?? {})
                                              this.setState({
                                                  rateDetails: rateDetails,
                                                  addComment: false
                                              })
                                          }}>
                                    {(addComment, {loading}) => {
                                        return (
                                            <CustomButton title={Strings.send}
                                                          onPress={() => {
                                                              if (this.state.comment) {
                                                                  addComment({
                                                                      variables: {
                                                                          input: {
                                                                              property_id: item?.property_id,
                                                                              order_rate_id: item?.id,
                                                                              comment: this.state.comment,
                                                                          }
                                                                      }
                                                                  })
                                                              }
                                                          }}
                                                          loading={loading}
                                                          style={{flex: 1}}
                                                          mr={10}/>)
                                    }}
                                </Mutation>

                                <CustomButton title={Strings.cancel}
                                              onPress={() => {
                                                  this.setState({addComment: false})
                                              }}
                                              style={{flex: 1}}
                                              textColor={Color.PRIMARY}
                                              borderWidth={1}
                                              borderColor={Color.PRIMARY_LIGHT}
                                              bgColor={Color.WHITE}
                                              ml={10}/>
                            </View>
                        </View>
                    ) :
                    (<View>
                        {item?.comments?.map((comment, index) => (
                            <View>
                                <View style={styles.lblCont}>
                                    <Label small
                                           mr={10}
                                           style={CommonStyle.flex}>
                                        {comment.comment}
                                    </Label>

                                    <Mutation mutation={SELLER_RATE_DELETE_COMMENT}
                                              onError={() => {
                                              }}
                                              refetchQueries={[{
                                                  query: SELLER_PROPERTY_LIST,
                                                  variables: {
                                                      status: 1
                                                  }
                                              }]}
                                              onCompleted={(data) => {
                                                  let rateDetails = {...item};
                                                  rateDetails.comments.splice(index, 1)
                                                  this.setState({
                                                      rateDetails: rateDetails,
                                                      addComment: false
                                                  })
                                              }}>
                                        {(deleteComment, {loading}) => {
                                            return (
                                                <TouchableOpacity onPress={() => {
                                                    deleteComment({
                                                        variables: {
                                                            input: {
                                                                property_id: item?.property_id,
                                                                order_rate_detail_id: comment?.id,
                                                                comment_id: comment.id
                                                            }
                                                        }
                                                    })
                                                }}>
                                                    <Icon name={'delete'}
                                                          color={Color.PRIMARY_LIGHT}
                                                          size={ThemeUtils.fontNormal}/>
                                                </TouchableOpacity>
                                            )
                                        }}
                                    </Mutation>

                                </View>
                                {comment.created_at ?
                                    (<Label small
                                            ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                            mb={10}
                                            color={Color.PRIMARY_LIGHT}>
                                        {moment.utc(comment.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.hh_mm_dd_mm_yyy)}
                                    </Label>) : null}
                                {item.comments?.length - 1 !== index ? (<Hr/>) : null}
                            </View>
                        ))}
                    </View>)}
            </>
        )
    }

    render() {
        const item = this.state.rateDetails
        return (
            <View style={CommonStyle.container}>
                <Header animatedTitle={Strings.rate}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.viewicon}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'rate'}
                                    color={Color.PRIMARY_DARK}/>
                            </View>
                            <View>
                                <Label
                                    mt={16}
                                    mb={18}
                                    small
                                    font_regular
                                    align={'left'}>
                                    {'Vi anbefaler alle å selge bolig med takst eller tilstandsrapport. Dersom du ordner takstmann selv – kryss av for dette i skjemaet under og du vil få opp et nytt skjema for inntasting av nøkkelinformasjon og løsning for å laste opp takstdokumentet. Dersom du ønsker at vi formidler kontakt med takstmann, velger du tidspunkt og bestiller her. Bestillingen er uforpliktende og du bil bli kontaktet av en takstmann for nærmere avklaringer om de forskjellige takstproduktene og bekreftelse av tidspunkt.'}
                                </Label>

                                <View style={styles.mainviewdata}>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.taxDate}
                                        </Label>
                                        <Label
                                            small
                                            font_regular
                                            align={'left'}>
                                            {`${moment(item.tax_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.valueRate}
                                        </Label>
                                        <Label small
                                               font_regular
                                               align={'left'}>
                                            {`${parseFloat(item.value_rate).toString()}`}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.loanRate}
                                        </Label>
                                        <Label
                                            small
                                            font_regular
                                            align={'left'}>
                                            {`${parseFloat(item.loan_rate).toString()}`}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.bta}
                                        </Label>
                                        <Label small
                                               font_regular
                                               align={'left'}>
                                            {`${item.bta}`}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.bra}
                                        </Label>
                                        <Label small
                                               font_regular
                                               align={'left'}>
                                            {`${item.bra}`}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.prom}
                                        </Label>
                                        <Label small
                                               font_regular
                                               align={'left'}>
                                            {`${item.p_rom}`}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.constructionYear}
                                        </Label>
                                        <Label small
                                               font_regular
                                               align={'left'}>
                                            {`${item.construction_year}`}
                                        </Label>
                                    </View>

                                </View>
                            </View>
                        </View>
                        {this.props.property?.orderrate ? this.renderCommentSection(item) : null}

                        <CustomButton onPress={() => {
                            openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item.document?.doc_url}`)
                        }}
                                      mt={15}
                                      ml={20}
                                      mr={20}
                                      mb={20}
                                      bolder
                                      large
                                      title={Strings.viewPDF}/>

                    </View>

                </Animated.ScrollView>
            </View>
        );
    }

}

const mapStateToProps = (state) => {

    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(ViewDetailsRate);
