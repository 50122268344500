import React, {useEffect} from 'react'
import {Alert, TouchableOpacity, View} from 'react-native';
import {Label} from "components/src/components";
import PropTypes from 'prop-types';
import {loadFile, useFilePicker} from "react-sage";
import {ReactNativeFile} from 'apollo-upload-client';
import {Color, Icon, IS_ANDROID, IS_WEB, MessageUtils, Strings} from "components/src/utils";

export default function FilePicker(props) {
    let id = 'react-native-document-picker'
    let permissionId = 'react-native-permissions'
    let DocumentPicker;
    let Permission = {};
    if (!IS_WEB) {
        Permission = require(`${permissionId}`);
        DocumentPicker = require(`${id}`).default
    }
    const {rippleProps, style, type, onFilePicked, children, maxFileSize, multiple} = props;

    const {files, onClick, errors, HiddenFileInput} = useFilePicker({
        maxFileSize: maxFileSize,
        imageQuality: 0.92,
        resizeImage: true
    });


    useEffect(() => {
        if (IS_WEB) {
            const getDataUrls = async () => {
                const data = await Promise.all(files.map(loadFile))
                if (files?.length) {
                    onFilePicked && onFilePicked(data, files)
                }

            }
            getDataUrls()
        }
    }, [files]);

    const getFileType = () => {
        switch (type) {
            case FileType.IMAGE:
                return IS_WEB ? '.jpg,.jpeg,.png' : DocumentPicker.types.images
            case FileType.PDF:
                return IS_WEB ? '.pdf' : DocumentPicker.types.pdf;
        }
    };

    const pickOnMobile = async () => {
        try {
            let status
            let isGranted
            if (IS_ANDROID) {
                status = await Permission.check(Permission.PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE)
            } else {
                status = Permission.RESULTS.GRANTED //await Permission.check(Permission.PERMISSIONS.IOS.READ_EXTERNAL_STORAGE)
            }
            switch (status) {
                case Permission.RESULTS.UNAVAILABLE:
                    break;
                case Permission.RESULTS.DENIED:
                    //For Rationall add as second argument
                    /*{
                        title: Strings.permissionRequired,
                        message: Strings.allowForPermission,
                        buttonPositive: Strings.okay,
                        buttonNegative: Strings.no,
                        buttonNeutral: Strings.cancleButton
                    }*/
                    isGranted = await Permission.request(Permission.PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE)
                    break;
                case Permission.RESULTS.GRANTED:
                    isGranted = status
                    break;
                case Permission.RESULTS.BLOCKED:
                    Alert.alert(
                        MessageUtils.PermissionMessage.storagePermissionTitle,
                        MessageUtils.PermissionMessage.storagePermissionMessage, [{
                            text: Strings.cancel,
                            style: "cancel"
                        }, {
                            text: Strings.okay,
                            onPress: () => Permission.openSettings().catch(() => console.warn('cannot open settings'))
                        }],
                        {cancelable: false}
                    );
                    break;
            }

            if (isGranted === Permission.RESULTS.GRANTED) {
                if (multiple) {
                    const res = await DocumentPicker.pickMultiple({
                        type: getFileType(),
                    });
                    onFilePicked && onFilePicked(null, res.map(item => new ReactNativeFile(item)))
                } else {
                    const res = await DocumentPicker.pick({
                        type: getFileType(),
                    });
                    onFilePicked && onFilePicked(null, [new ReactNativeFile(res)])
                }
            }
        } catch (err) {
            if (DocumentPicker.isCancel(err)) {
                // User cancelled the picker, exit any dialogs or menus and move on
            } else {
                throw err;
            }
        }
    };

    const pickWithCameraOptions = () => {
        let id = 'react-native-image-picker'
        let ImagePicker = require(`${id}`).default
        const options = {
            title: Strings.selectProfilePic,
            storageOptions: {
                skipBackup: true,
                path: 'images',
            },
        };
        ImagePicker.showImagePicker(options, async (response) => {

            if (response.didCancel) {
                console.log('User cancelled image picker');
            } else if (response.error) {
                let status
                if (IS_ANDROID) {
                    status = await Permission.request(Permission.PERMISSIONS.ANDROID.CAMERA)
                } else {
                    status = await Permission.request(Permission.PERMISSIONS.IOS.CAMERA)
                }
                if (status === Permission.RESULTS.BLOCKED) {
                    Alert.alert(
                        MessageUtils.PermissionMessage.cameraPermissionTitle,
                        MessageUtils.PermissionMessage.cameraPermissionMessage, [{
                            text: Strings.cancel,
                            style: "cancel"
                        }, {
                            text: Strings.okay,
                            onPress: () => Permission.openSettings().catch(() => console.warn('cannot open settings'))
                        }],
                        {cancelable: false}
                    );
                }
            } else {
                const source = {uri: response.uri};
                onFilePicked && onFilePicked(null, [new ReactNativeFile({
                    type: response.type,
                    uri: response.uri,
                    name: response.fileName
                })])
            }
        });
    }

    return (
        <View style={style}>
            {IS_WEB ? (
                <div className={'div'}>
                    <HiddenFileInput accept={getFileType()}

                                     multiple={multiple}/>
                </div>) : null}
            <TouchableOpacity activeOpacity={0.5}
                              onPress={IS_WEB ? onClick : props.isCameraOptionRequired ? pickWithCameraOptions : pickOnMobile}
                              style={children ? null : {alignSelf: 'flex-start'}}
                              rippleContainerBorderRadius={children ? 0 : 4}
                              {...rippleProps}>
                {children ? children : (
                    <View style={{
                        paddingHorizontal: 16,
                        paddingVertical: 8,
                        borderWidth: 1,
                        borderColor: Color.PRIMARY,
                        borderRadius: 4,
                        alignSelf: 'flex-start'
                    }}>
                        <Label small>
                            <Icon name={'upload'}/>
                            {`  ${Strings.upload}`}
                        </Label>
                    </View>
                )}
            </TouchableOpacity>
        </View>
    )
}
FilePicker.defaultProps = {
    type: 'image',
    maxFileSize: 1024,
    multiple: false
};
FilePicker.propTypes = {
    rippleProps: PropTypes.object,
    style: PropTypes.object,
    type: PropTypes.string,
    onFilePicked: PropTypes.func,
    maxFileSize: PropTypes.number,
    multiple: PropTypes.bool,
    isCameraOptionRequired: PropTypes.bool,

}

export class FileType {
    static IMAGE = 'image';
    static PDF = 'pdf'
}

