/* eslint-disable no-shadow */
import { decryptId } from '../../../../../utils/utils';
import { CaretDownFilled, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, notification, Row, Select, Typography } from 'antd';
import React, { useEffect,useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { formatMessage } from 'components/src/utils';
import {
  ADMIN_DELETE_MOVING_VENDOR_USER,
  ADMIN_UPDATE_MOOVING_VENDORS,
} from '../../../../../../mutation/AdminMutation';
import {
  ADMIN_MOOVIN_GUIDE_VENDORS_LIST,
  ADMIN_ZIPCODE_LIST,
  ADMIN_MOOVIN_GUIDE_MANAGE_LINKS,
  ADMIN_COMPANY_TYPE_LIST,
} from '../../../../../../query/AdminQuery';
import { USER_TYPE_SERVER } from '../../../../../utils/constant';
import{useHistory} from "react-router";
import './editmanagevendors.scss';
// import { from } from 'apollo-link';
const { Title } = Typography;

const companytypeoptionlist = [
  { label: 'Mortgage', value: 1 },
  { label: 'Insurance', value: 2 },
  { label: 'Home security', value: 3 },
  { label: 'TV / Cable', value: 4 },
  { label: 'Cleaning', value: 5 },
  { label: 'Moving', value: 6 },
  { label: 'Sunscreening', value: 7 },
  { label: 'Phone', value: 8 },
  { label: 'Varmepumper', value: 9 },
  { label: 'Short term loans', value: 10 },
];
// const zipcodesdata = [
//   { value: '0182' },
//   { value: '0235' },
//   { value: '0178' },
//   { value: '0188' },
//   { value: '1350' },
//   { value: '0183' },
//   { value: '4730' },
//   { value: '4457' },
//   { value: '5073' },
//   { value: '1024' },
//   { value: '2058' },
//   { value: '0048' },
//   { value: '1044' },
//   { value: '0154' },
//   { value: '0882' },
//   { value: '4288' },
//   { value: '4115' },
//   { value: '0015' },
// ];

const serviceoptionlist = [
  { label: 'Moovin Agency', value: 1 },
  { label: 'Book the Internet', value: 2 },
  { label: 'Order Tv Package', value: 3 },
  { label: 'Order A Home Alarm', value: 4 },
];
let orderItem;
const Editmanagevendors = props => {
  let router=useHistory();
  const { id } = props.match.params;
  console.log('id;;>>', id);
  const options = [];


  const [deleteModal, setdeleteModal] = useState(false);

  const onFinish = (values, updateMangeVendors) => {
    console.log('Success:', values);
    console.log('updateMangeVendors:', updateMangeVendors);

    updateMangeVendors({
      variables: {
        id: decryptId(id),
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        phone: values.phone,
        company_name: values.companyname,
        company_type_id: values.companytype,
        assigned_zipcode: values.assignedzipcodes,
        service_type_id: values.service,
      },
    }).then(({ data }) => {
      // console.log('Data submitted===>>>', data, );
      console.log(
          'data--->',
          data,
          '\ndata.updat_moving_vendor-->>',
          data.update_moving_vendor,
          '\ndata.update_moving_vendor.meta',
          data.update_moving_vendor.meta,
      );
      router.push('/admin/administrereleverandører');
      if (data && data.updat_moving_vendor && data.update_moving_vendor.meta) {
        notification.success({
          message: data.update_moving_vendor.meta.message,
          status: 1,
          description: '',
          duration: 3,
        });
      }
    });
    console.log('-----donee-----');
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onDelete = (deleteID, deletUser) => {
    console.log('delete id ==>', deleteID);
    deletUser({
      variables: {
        id: deleteID,
      },
    });
    router.push('/admin/administrereleverandører');
  };

  return (
      <div className="contentshadow">
        <div className="text-primary main">
          <Row>
            <Col xs={24}>
              <Title level={3} className="text-primary">
                {formatMessage({
                  id: 'component.managevendors.editmanagevendors',
                })}
              </Title>
            </Col>
          </Row>
          <Query
              query={ADMIN_MOOVIN_GUIDE_VENDORS_LIST}
              variables={{
                status: 1,
                first: 300,
                page: 1,
              }}
              fetchPolicy="cache-and-network"
          >
            {({ data, error }) => {
              console.log(error);
              if (data && data.moving_guide_vendors_list.data) {
                const dataObj = data.moving_guide_vendors_list.data.filter(
                    i => i.id === parseInt(decryptId(id), 10),
                );
                console.log('dataObj:>>', dataObj);
                // eslint-disable-next-line prefer-destructuring
                orderItem = dataObj[0];
                console.log('orderItem:>>', orderItem);
                return (
                    <Row>
                      <Col xs={24}>
                        <Mutation mutation={ADMIN_UPDATE_MOOVING_VENDORS}>
                          {updateMangeVendors => (
                              <Form
                                  layout="vertical"
                                  className="user-forms"
                                  name="managevendors"
                                  initialValues={{
                                    remember: true,
                                    firstname: orderItem?.first_name,
                                    lastname: orderItem?.last_name,
                                    email: orderItem?.email,
                                    phone: orderItem?.phone,
                                    companyname: orderItem?.company_name,
                                    companytype: orderItem?.company_type_id,
                                    service: orderItem?.service_type_id,
                                    assignedzipcodes: orderItem?.vendorZipcodes.map(i => i?.zipcode),
                                  }}
                                  colon={false}
                                  onFinish={values => onFinish(values, updateMangeVendors)}
                                  onFinishFailed={onFinishFailed}
                              >
                                <Row gutter={16}>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="firstname"
                                        label={formatMessage({
                                          id: 'component.form.firstname',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst fyll inn ${formatMessage({
                                              id: 'component.form.firstname',
                                            }).toLowerCase()}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.firstname',
                                          }).toLowerCase()}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="lastname"
                                        label={formatMessage({
                                          id: 'component.form.lastname',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst fyll inn ${formatMessage({
                                              id: 'component.form.lastname',
                                            }).toLowerCase()}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.lastname',
                                          }).toLowerCase()}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="email"
                                        label={formatMessage({
                                          id: 'component.form.email',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst fyll inn ${formatMessage({
                                              id: 'component.form.email',
                                            }).toLowerCase()}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.email',
                                          }).toLowerCase()}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="phone"
                                        label={formatMessage({
                                          id: 'component.form.phone',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst fyll inn ${formatMessage({
                                              id: 'component.form.phone',
                                            }).toLowerCase()}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.phone',
                                          }).toLowerCase()}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="companyname"
                                        label={formatMessage({
                                          id: 'component.managevendors.companyname',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Vennligst angi selskapsnavn',
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={`${formatMessage({
                                            id: 'component.managevendors.companyname',
                                          })}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Query
                                        query={ADMIN_COMPANY_TYPE_LIST}
                                        variables={{
                                          status: 1,
                                        }}
                                    >
                                      {({ data, loading }) => {
                                        if (data && !loading) {
                                          if (data && data.company_type_list) {
                                            return (
                                                <>
                                                  <Form.Item
                                                      name="companytype"
                                                      label={formatMessage({
                                                        id: 'component.managevendors.companytype',
                                                      })}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: `Vennligst velg ${formatMessage({
                                                            id: 'component.managevendors.companytype',
                                                          }).toLowerCase()}`,
                                                        },
                                                      ]}
                                                  >
                                                    <Select
                                                        // defaultValue="Mortgage"
                                                        style={{ width: '100%' }}
                                                        placeholder={`${formatMessage({
                                                          id: 'component.managevendors.companytype',
                                                        })}`}
                                                        size="large"
                                                        suffixIcon={
                                                          <CaretDownFilled className="caret-style" />
                                                        }
                                                        className="pickerwidth"
                                                        // options={companytypeoptionlist}
                                                    >
                                                      {data?.company_type_list?.map(i => (
                                                          // console.log(i.postnummer)
                                                          <Select.Option value={i.id}>{i.name}</Select.Option>
                                                      ))}
                                                    </Select>
                                                  </Form.Item>
                                                </>
                                            );
                                          }
                                          return <div>{formatMessage({ id: 'component.nodata' })}</div>;
                                        }
                                        return (
                                            <div className="loader-wrapper-inline">
                                              <LoadingOutlined />
                                            </div>
                                        );
                                      }}
                                    </Query>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={16}>
                                    <Query
                                        query={ADMIN_MOOVIN_GUIDE_MANAGE_LINKS}
                                        variables={{
                                          status: 1,
                                        }}
                                    >
                                      {({ data, loading }) => {
                                        if (data && !loading) {
                                          if (data && data.manage_link_moving_guide_list) {
                                            return (
                                                <>
                                                  <Form.Item
                                                      name="service"
                                                      label={formatMessage({
                                                        id: 'component.managevendors.service',
                                                      })}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                              'Vennligst oppgi hvilke tjenester som leveres',
                                                        },
                                                      ]}
                                                  >
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        placeholder={`Velg ${formatMessage({
                                                          id: 'component.managevendors.service',
                                                        })}`}
                                                        size="large"
                                                        suffixIcon={
                                                          <CaretDownFilled className="caret-style" />
                                                        }
                                                        className="pickerwidth"
                                                    >
                                                      {data?.manage_link_moving_guide_list?.filter(j=>j.is_service===1).map(i => (
                                                          <Select.Option value={i.id}>{i.name}</Select.Option>
                                                      ))}
                                                    </Select>
                                                  </Form.Item>
                                                </>
                                            );
                                          }
                                          return <div>{formatMessage({ id: 'component.nodata' })}</div>;
                                        }
                                        return (
                                            <div className="loader-wrapper-inline">
                                              <LoadingOutlined />
                                            </div>
                                        );
                                      }}
                                    </Query>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={16}>
                                    <Query
                                        query={ADMIN_ZIPCODE_LIST}
                                        variables={{
                                          status: 1,
                                          user_type_id: USER_TYPE_SERVER.ADMIN,
                                          user_id: 1,
                                        }}
                                    >
                                      {({ data, loading }) => {
                                        if (data && !loading) {
                                          if (data && data.zipcode_list) {
                                            data.zipcode_list.map(i => options.push(i.postnummer));
                                            return (
                                                <>
                                                  <Form.Item
                                                      name="assignedzipcodes"
                                                      label={formatMessage({
                                                        id: 'component.form.assignedzipcode',
                                                      })}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: `Vennligst fyll inn ${formatMessage({
                                                            id: 'component.form.zipcode',
                                                          }).toLowerCase()}`,
                                                        },
                                                      ]}
                                                  >
                                                    <Select
                                                        mode="multiple"
                                                        size="large"
                                                        style={{ width: '100%' }}
                                                        placeholder={`Velg ${formatMessage({
                                                          id: 'component.form.zipcode',
                                                        })}`}
                                                    >
                                                      {data.zipcode_list.map(i => (
                                                          <Select.Option value={i.postnummer}>
                                                            {i.postnummer}
                                                          </Select.Option>
                                                      ))}
                                                    </Select>
                                                  </Form.Item>
                                                </>
                                            );
                                          }
                                          return <div>{formatMessage({ id: 'component.nodata' })}</div>;
                                        }
                                        return (
                                            <div className="loader-wrapper-inline">
                                              <LoadingOutlined />
                                            </div>
                                        );
                                      }}
                                    </Query>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col xs={24}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btn-secondary buttons admin-forms-btn"
                                        size="large"
                                    >
                                      {formatMessage({
                                        id: 'component.form.add',
                                      })}
                                    </Button>
                                    <Button
                                        className="text-primary admin-forms-btn"
                                        style={{ marginLeft: 8 }}
                                        size="large"
                                        onClick={router.goBack}
                                    >
                                      {formatMessage({
                                        id: 'component.form.cancel',
                                      })}
                                    </Button>
                                    <Button
                                        className="admin-forms-btn"
                                        style={{ cssFloat: 'right', color: 'red' }}
                                        onClick={() => setdeleteModal(true)}
                                        size="large"
                                    >
                                      {formatMessage({
                                        id: 'component.form.delete',
                                      })}
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                          )}
                        </Mutation>
                      </Col>
                    </Row>
                );
              }
              return (
                  <div className="loader-wrapper">
                    <LoadingOutlined />
                  </div>
              );
            }}
          </Query>
          <Modal
              centered
              title={formatMessage({
                id: 'component.managevendors.deletemodal.title',
              })}
              visible={deleteModal}
              onOk={() => setdeleteModal(false)}
              onCancel={() => setdeleteModal(false)}
              width="450px"
              footer={[
                <Mutation mutation={ADMIN_DELETE_MOVING_VENDOR_USER}>
                  {(deletUser, { data, loading }) => {
                    if (data) {
                      console.log('Data submitted===>>>', data, loading);
                      if (data && data.delete_user && data.delete_user.meta) {
                        notification.success({
                          message: data.delete_user.meta.message,
                          description: '',
                          duration: 3,
                        });
                      }
                    }
                    return (
                        <Button
                            key="submit"
                            type="primary"
                            className="admin-forms-btn  mr_15"
                            size="large"
                            onClick={() => onDelete(orderItem.id, deletUser)}
                        >
                          {formatMessage({
                            id: 'component.form.yes',
                          })}
                        </Button>
                    );
                  }}
                </Mutation>,
                <Button
                    className="admin-forms-btn  mr_15"
                    size="large"
                    onClick={() => setdeleteModal(false)}
                >
                  {formatMessage({
                    id: 'component.form.no',
                  })}
                </Button>,
              ]}
          >
            <p>
              {formatMessage({
                id: 'component.managevendors.deletemodal.text',
              })}
            </p>
          </Modal>
        </div>
      </div>
  );
};
export default Editmanagevendors;
