import {
    SET_BUYER_FORM, SET_COLOR,
    SET_CURRENT_PROPERTY,
    SET_TOKEN, SET_TOUR_SHOWN,
    SET_USER
} from './action-types';

import {REHYDRATE} from 'redux-persist/src/constants';

let initialState = {
    user: null,
    token: null,
    seller_interview:[],
    property:null,
};

const setToken = (token) => ({type: SET_TOKEN, token});
const setUser = (user) => ({type: SET_USER, user});

const setSellerInterViewForm = (form) => ({type: SET_BUYER_FORM, form});

const setCurrentProperty = (property) => ({type: SET_CURRENT_PROPERTY, property});

const setTourShown = (tourShown) => ({type: SET_TOUR_SHOWN, tourShown});

const logout = () => ({type: REHYDRATE, payload: initialState});

const setColors = (colors) => ({type: SET_COLOR,colors});

export {
    setTourShown,
    setToken,
    setUser,
    logout,
    setCurrentProperty,
    setSellerInterViewForm,
    setColors
};
