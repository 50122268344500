import { StyleSheet } from 'react-native';
import { Color, ThemeUtils } from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,

        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        // alignContent: 'center'
    },
    titleConts: {
        // marginTop: 20,
        marginHorizontal:containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },


    fieldView:
    {
        // marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },

    labeliconView: {

        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    labelTopView: { alignItems: 'flex-start', flex: 1, flexDirection: 'row', marginTop: 15, alignContent: 'center', justifyContent: 'space-between' },

    energyradio: {
        marginBottom: 15,
        fontSize: 12,
        alignContent: 'center',

    },

    uploadView:
    {
        flexDirection: 'row',
        width: 125,
        height: 48,
        alignItems: 'center',
        borderWidth: 2,
        borderColor: Color.INPUT_BORDER,
        justifyContent: 'center',
        alignContent: 'center',
        marginBottom: 25,
        borderRadius: 6
    },
    flatlisttopContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },
    commentview: {
        marginTop: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    MainSeocodflatView: { flexDirection: 'row' },
    labelView: { flexDirection: 'column', flex: 1 },
    attachment:{
        marginTop:15
    }
});
export { styles };
