import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'

import {Header, HTMLView,} from "components/src/components";
//Third party
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Query} from "@apollo/react-components";
import {SELLER_DISCLAIMER} from "components/src/api/sellerQuery";
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';

class Disclaimer extends React.Component {
    constructor(props) {
        super(props)
        this.scrollY = new Animated.Value(0)

    }


    render() {
        return (
            <View style={CommonStyle.container}>
                <Header animatedTitle={Strings.disclaimer}

                        animatedValue={this.scrollY}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={styles.flexGrow}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>


                    <View style={styles.container}>
                        <Icon name={'disclaimer'}
                              color={Color.PRIMARY_TEXT_COLOR}
                              size={ThemeUtils.fontXXLarge}/>
                        <Query query={SELLER_DISCLAIMER}
                               variables={{
                                   status: 1,
                                   isBuyerSeller: 'SELLER'
                               }}>
                            {({data, loading, error}) => {
                                if (loading && !data) {
                                    return <ActivityIndicator color={Color.PRIMARY}
                                                              style={styles.loader}/>
                                }
                                return (
                                    <HTMLView style={styles.htmlCont} value={data?.disclaimer?.content}/>
                                )


                            }}
                        </Query>

                    </View>
                </Animated.ScrollView>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Disclaimer);
