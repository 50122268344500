import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {CustomTextArea} from "components/src/components";
//Utils
import {Strings} from "components/src/utils";
import {connect} from "react-redux";
import {setSellerInterViewForm} from "components/src/redux/action";

const QUESTIONS = [{
    id: 'q1',
    text: Strings.firstStep.q1
}, {
    id: 'q2',
    text: Strings.firstStep.q2
},];

function FirstStep(props) {
    const {onChangeAnswer, setForm, interViewForm} = props;

    const [finalAnswer,setFinalAnswer]=useState({
        q1: interViewForm.find(item => item.interview_section === 1 && item.question_no === 1)?.answer ?? '',
        q2: interViewForm.find(item => item.interview_section === 1 && item.question_no === 2)?.answer ?? '',
    })
    const [answers, setAnswer] = useState({
        q1: interViewForm.find(item => item.interview_section === 1 && item.question_no === 1)?.answer ?? '',
        q2: interViewForm.find(item => item.interview_section === 1 && item.question_no === 2)?.answer ?? '',
    });

    useEffect(() => {
        setAnswer({
            q1: interViewForm.find(item => item.interview_section === 1 && item.question_no === 1)?.answer ?? '',
            q2: interViewForm.find(item => item.interview_section === 1 && item.question_no === 2)?.answer ?? '',
        })
    }, [interViewForm]);

    useEffect(() => {

        let answer = [{
            "question": QUESTIONS[0].text,
            "interview_section": 1,
            "question_no": 1,
            "type": "TEXT",
            "answer": answers.q1,
            "extra": null,
            "comment": null
        }, {
            "question": QUESTIONS[1].text,
            "interview_section": 1,
            "question_no": 2,
            "type": "TEXT",
            "answer": answers.q2,
            "extra": null,
            "comment": null
        },];
        setForm([...interViewForm.filter(item => item.interview_section !== 1), ...answer])
    }, [finalAnswer]);

    const handleChangeText = (text, id) => {
        let updatedAnswer = {...answers};
        updatedAnswer[id] = text;
        setAnswer(updatedAnswer)
        setFinalAnswer(updatedAnswer)
    };

    const renderQuestions = (item, index) => {
        return (<CustomTextArea labelText={item.text}
                                mb={10}
                                placeholder={Strings.writeYourComment}
                                value={answers[item.id]}
                                onChange={(text) => handleChangeText(text, item.id)}/>)
    };

    return (
        <View>
            {QUESTIONS.map(renderQuestions)}
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form))
    }

};
export default connect(mapStateToProps, mapDispatchToProps)(FirstStep)
