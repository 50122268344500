import { Button, Col, Input, Row, Table, Typography } from 'antd';
import React, { useState,useEffect, useRef } from 'react';
import { formatMessage } from 'components/src/utils';
import { LoadingOutlined } from '@ant-design/icons';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_LOAN_APPLICATION_LIST } from '../../../../query/AdminQuery';
import { ADMIN_DOWNLOAD_LOAN_APPLICATION } from '../../../../mutation/AdminMutation';
import { STORAGE_URL } from '../../../utils/constant';
// import data from '../../../../mock/officeceodata';
import {useHistory} from "react-router";

const { Title } = Typography;
const { Search } = Input;

export const Loanapplication = () => {
  let router=useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderData, setOrderData] = useState([]);
  const [loanList, setLoanList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [csvUrl, setCsvUrl] = useState('');
  const [prevPagination, setprevPagination] = useState({});
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 30,
    total: 1,
  });

  const timerRef = useRef(null);

  const usePreviousValue = () => {
    const ref = useRef();
    useEffect(() => {
      ref.current = searchKeyword;
    });
    return ref.current;
  };
  const prevValue = usePreviousValue();

  const searchFunc = e => {
    if (prevValue === '') {
      console.log('prevValue;', prevValue);
      setprevPagination(paginationData);
    }
    if (e !== '') {
      setPaginationData({
        current: 1,
        pageSize: 30,
        total: 1,
      });
    } else {
      setPaginationData(prevPagination);
    }
  };

  const handleTableChange = pagination => {
    console.log('pagination:', pagination);
    if (pagination) {
      setPaginationData(pagination);
      setprevPagination(paginationData);
    }
  };

  useEffect(() => {
    if (searchKeyword === '') {
      setprevPagination(paginationData);
    }
  }, []);

  const columns = [
    {
      title: `${formatMessage({ id: 'component.loanapplication.applicantTitle' })}`,
      dataIndex: 'applicant',
      key: 'applicant',
      sorter: (a, b) => a.applicant - b.applicant,
    },
    {
      title: `${formatMessage({ id: 'component.table.email' })}`,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => {
        const aSplit = a.email.split('@');
        const bSplit = b.email.split('@');
        return aSplit[0].length - bSplit[0].length;
      },
    },
    {
      title: `${formatMessage({ id: 'component.table.phone' })}`,
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: `${formatMessage({ id: 'component.table.mortgageoption' })}`,
      dataIndex: 'mortgageoption',
      key: 'mortgageoption',
      sorter: (a, b) => a.mortgageoption.length - b.mortgageoption.length,
    },
    {
      title: `${formatMessage({ id: 'component.loanapplication.coapplicantTitle' })}`,
      dataIndex: 'coapplicant',
      key: 'coapplicant',
      sorter: (a, b) => a.coapplicant.length - b.coapplicant.length,
    },
    {
      title: `${formatMessage({ id: 'component.finance.loanamount' })}`,
      dataIndex: 'loanamount',
      key: 'loanamount',
      sorter: (a, b) => a.loanamount.length - b.loanamount.length,
    },
    {
      title: `${formatMessage({ id: 'component.form.comments' })}`,
      dataIndex: 'comments',
      key: 'comments',
      sorter: (a, b) => a.comments.length - b.comments.length,
    },
  ];

  const download = (value, downloadFile) => {
    downloadFile({
      variables: {
        status: 1,
        file_type: value,
      },
    }).then(data => {
      console.log('Data submitted===>>>', data);

      if (data && data.data.download_loan_application) {
        console.log('URL:::>>>', data.data.download_loan_application.url);
        // const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = `${STORAGE_URL}${data.data.download_loan_application.url}`;
        a.download = 'Loan_Application_List';
        a.click();
      }
    });
  };

  const printDoc = printFile => {
    printFile({
      variables: {
        status: 1,
        file_type: 'XLSX',
      },
    }).then(data => {
      console.log('Data submitted===>>>', data);

      if (data && data.data.download_loan_application) {
        console.log('URL:::>>>', data.data.download_loan_application.url);
        const divToPrint = document.getElementById('loanApplicationTable');
        const newWin = window.open('');
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
      }
    });
  };

  return (
      <div>
        <div className="contentshadow">
          <div className="text-primary main">
            <Row className="photo-order-wrapper">
              <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                <Title level={3} className="text-primary">
                  {formatMessage({
                    id: 'component.loanapplication.title',
                  })}
                </Title>
                <Mutation mutation={ADMIN_DOWNLOAD_LOAN_APPLICATION}>
                  {downloadFile => (
                      <>
                        <Button
                            type="primary"
                            className="btn-secondary admin-forms-btn with-lg-spaces"
                            size="large"
                            style={{ marginRight: 16 }}
                            onClick={() => download('CSV', downloadFile)}
                        >
                          {formatMessage({ id: 'component.loanapplication.exportascsv' })}
                        </Button>

                        <Button
                            type="primary"
                            className="btn-secondary admin-forms-btn with-lg-spaces"
                            style={{ marginRight: 16 }}
                            size="large"
                            onClick={() => download('XLSX', downloadFile)}
                        >
                          {formatMessage({ id: 'component.loanapplication.exportasexcel' })}
                        </Button>

                        <Button
                            type="primary"
                            className="btn-secondary admin-forms-btn with-lg-spaces"
                            size="large"
                            style={{ marginRight: 16 }}
                            value="PDF"
                            onClick={() => download('PDF', downloadFile)}
                        >
                          {formatMessage({ id: 'component.loanapplication.exportaspdf' })}
                        </Button>
                      </>
                  )}
                </Mutation>
                <Mutation mutation={ADMIN_DOWNLOAD_LOAN_APPLICATION}>
                  {printFile => (
                      <>
                        <Button
                            type="primary"
                            className="btn-secondary admin-forms-btn with-lg-spaces"
                            size="large"
                            style={{ marginRight: 16 }}
                            onClick={() => printDoc(printFile)}
                        >
                          {formatMessage({ id: 'component.loanapplication.printall' })}
                        </Button>
                      </>
                  )}
                </Mutation>
                <Row>
                  <Col xs={24}>
                    <Query
                        query={ADMIN_LOAN_APPLICATION_LIST}
                        variables={{
                          status: 1,
                          page: paginationData.current,
                          first: paginationData.pageSize,
                          key: searchKeyword,
                          sorting: {
                            field: 'id',
                            order: 'DESC',
                          },
                        }}
                        fetchPolicy="no-cache"
                        onCompleted={responseData => {
                          setOrderData(responseData.loan_application_list.data);
                          if (
                              responseData &&
                              responseData.loan_application_list &&
                              responseData.loan_application_list.paginatorInfo
                          ) {
                            setPaginationData({
                              current: responseData.loan_application_list.paginatorInfo.currentPage,
                              pageSize: responseData.loan_application_list.paginatorInfo.perPage,
                              total: responseData.loan_application_list.paginatorInfo.total,
                            });
                          }
                          if (
                              responseData &&
                              responseData.loan_application_list &&
                              responseData.loan_application_list.data
                          ) {
                            setLoanList(responseData.loan_application_list.data);
                          }
                        }}
                    >
                      {({ data, loading, error }) => {
                        console.log(error);
                        const LoanApplicationDataList = [];
                        if (data && data.loan_application_list.data) {
                          console.log('insodwe;', data.loan_application_list.data);
                          // eslint-disable-next-line array-callback-return
                          data.loan_application_list.data.map(item => {
                            const LoanApplicationList = {
                              applicant: item.applicant_name ? item.applicant_name : '-',
                              email: item.email ? item.email : '-',
                              phone: item.phone ? item.phone : '-',
                              mortgageoption: item.financing_types ? item.financing_types : '-',
                              coapplicant: item.co_applicant_name ? item.co_applicant_name : '-',
                              loanamount: item.loan_amount ? item.loan_amount : '-',
                              comments: item.comments ? item.comments : '-',
                            };
                            LoanApplicationDataList.push(LoanApplicationList);
                          });
                        }
                        if (!error) {
                          return (
                              <>
                                <Row>
                                  <Col lg={12} xs={24}>
                                    <div className="table-searchbar">
                                      <Search
                                          placeholder={formatMessage({
                                            id: 'component.tablesearch.placeholder',
                                          })}
                                          onSearch={value => setSearchKeyword(value)}
                                          onChange={e => {
                                            const searchText = e.target.value;
                                            searchFunc(searchText);
                                            if (timerRef.current) {
                                              clearTimeout(timerRef.current);
                                            }
                                            timerRef.current = setTimeout(() => {
                                              console.log('On Change===>>>', searchText);
                                              setSearchKeyword(searchText);
                                            }, 2000);
                                          }}
                                          style={{ padding: '8px 15px', marginBottom: '20px' }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <div style={{ width: '100%', height: '100%', overflowX: 'auto' }}>
                                  <div className="unbordered-table-wrapper admin-tables">
                                    <Table
                                        id="loanApplicationTable"
                                        columns={columns}
                                        onChange={handleTableChange}
                                        dataSource={LoanApplicationDataList}
                                        pagination={{
                                          current: paginationData.current,
                                          pageSize: paginationData.pageSize,
                                          total: paginationData.total,
                                          showSizeChanger: true,
                                          showTotal: () =>
                                              `Total ${paginationData.total} ${formatMessage({
                                                id: 'component.table.items',
                                              })}`,
                                          pageSizeOptions: ['30', '35', '40', '45', '50'],
                                        }}
                                    />
                                  </div>
                                </div>
                              </>
                          );
                        }
                        if (!loading && error) {
                          return (
                              <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen.</div>
                          );
                        }
                        return <></>;
                      }}
                    </Query>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <Row>
          <Col lg={24}>
            <Button
                type="primary"
                className="btn-secondary admin-forms-btn with-lg-spaces back-button"
                size="large"
                style={{ marginRight: 16 }}
                onClick={router.goBack}
            >
              {formatMessage({ id: 'component.form.back' })}
            </Button>
          </Col>
        </Row>
      </div>
  );
};

export default Loanapplication;
