import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,
    },
    contentCont:{
        flexGrow:1,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    scrollCont: {
        flexGrow: 1,
    },
    icMaterial: {
        marginVertical: 15,
    },
    materialCont: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginVertical: 8,
        borderRadius: 4,
        backgroundColor: Color.DARK_GRAY
    },
    lblCont: {
        flex: 1,
        marginHorizontal: 10,
        justifyContent: 'space-between'
    },
    icOpenExtCont:{
        padding:8,
    },
    btnApproveCont: {
        flexGrow:1,
        justifyContent:'flex-end',
        marginVertical:20

    }
})
