import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {AddServiceView, Header, Label} from "components/src/components";
//Third party
//redux
import {connect} from "react-redux";
//Utils
import {styles} from './styles.js';
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    formatPrice,
    getStatusLabel,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import moment from "moment";
import {Query} from "@apollo/react-components";
import {PURCHASED_SERVICES} from "../../../../../../api/query";

function ThankYouPhoto(props) {
    const {history, navigation} = props;
    const order = IS_WEB ? props.history.location.state?.order ?? props.property?.order_photo ?? {} : props.navigation.getParam('order', props.property?.order_photo ?? {});
    const scrollY = new Animated.Value(0)
    const stylingViewDetails = () => {
        if (IS_WEB) {
            history.replace(Routes.ViewDetailsPhotoService, {
                order: order
            })
        } else {
            navigation.navigate(Routes.ViewDetailsPhotoService, {
                order: order
            });
        }
    }


    const renderAdditionalServiceItem = (item, index, parentIndex) => {
        return (
            <AddServiceView title={item.name}
                            price={parseInt(item.price) ? `Kr ${formatPrice(item.price)},-` : item.free_text}
                            image={item.image}
                            name={'disclaimer'}
                            extraNote={item.external_url}
                            mb={9}
                            desc={item.description}
            />)
    };


    const renderAdditionalService = (item, index) => {

        let data = []
        if (item?.vendor_services?.length) {
            data = item?.vendor_services?.filter(item => item.service_selected)
        } else {
            data = item.purchased_office_template.purchased_office_services.filter(item => item.service_selected)
        }
        if (!data?.length) {
            return (<View/>)
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       mb={10}>
                    {item.name}
                </Label>
                {data.map((item, childIndex) => renderAdditionalServiceItem(item, childIndex, index))}
            </View>
        )
    }

    const {order_date, order_status, styling_type, comment, earliest_start_time, latest_start_time} = order;

    return (
        <View style={CommonStyle.container}>
            <Header navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.replace(Routes.SalesPreparation) : navigation.goBack()}
                    initialMarginLeft={IS_WEB?10:ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    animatedTitle={Strings.thanksForTheOrder}/>
            <Animated.ScrollView contentContainerStyle={{flexGrow: 1,paddingBottom:ThemeUtils.APPBAR_HEIGHT}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={CommonStyle.flex}>
                            <Label
                                small
                                mt={16}
                                font_regular
                                align={'left'}>
                                {Strings.thanksInstructionPhoto}
                            </Label>
                        </View>
                        <View>
                            <View style={styles.mainviewdata}>
                                <View style={styles.subviewdata}>
                                    <Label small
                                           font_medium
                                           mt={15}>
                                        {Strings.status}
                                    </Label>
                                    <Label small
                                           font_regular
                                           mt={5}
                                           align={'left'}>
                                        {getStatusLabel(order_status)}
                                    </Label>
                                </View>
                                <View style={styles.subviewdata}>
                                    <Label small
                                           font_medium
                                           mt={15}>
                                        {Strings.dates}
                                    </Label>
                                    <Label small
                                           font_regular

                                           align={'left'}
                                           mt={5}>
                                        {moment(order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                                    </Label>
                                </View>
                                <View style={styles.subviewdata}>
                                    <Label small
                                           font_medium
                                           mt={15}>
                                        {Strings.startTime}
                                    </Label>
                                    <Label
                                        small
                                        font_regular
                                        mt={5}
                                        align={'left'}>
                                        {moment(earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                    </Label>
                                </View>
                                {order_status === Constants.ORDER_STATUS.ORDERED ?
                                    (<View style={styles.subviewdata}>
                                        <Label small
                                               mt={15}
                                               font_medium>
                                            {Strings.endTime}
                                        </Label>
                                        <Label small
                                               font_regular
                                               mt={5}
                                               align={'left'}>
                                            {moment(latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                        </Label>
                                    </View>) : null}
                            </View>
                        </View>


                        {comment ? (
                            <Label small
                                   mt={15}
                                   font_medium
                                   align={'left'}>
                                {Strings.comments}
                            </Label>) : null}
                        <Label
                            mt={5}
                            small
                            font_regular
                            align={'left'}>
                            {comment}
                        </Label>
                        <Label small
                               mt={15}
                               mb={10}
                               font_medium
                               align={'left'}>
                            {Strings.purchasedService}
                        </Label>

                        <Query query={PURCHASED_SERVICES}
                               fetchPolicy={'network-only'}
                               variables={{
                                   status: 1,
                                   property_id: props.property?.id,
                                   service_category: "PS"
                               }}>
                            {({data, loading, error}) => {
                                let additioanlSServices = [];
                                if (loading && !data) {
                                    return <ActivityIndicator color={Color.PRIMARY}/>
                                }
                                data?.purchased_services?.purchased_services?.map((service, index) => {
                                    let vendorServices = [];
                                    if (index === 0) {
                                        vendorServices = data?.purchased_services?.service_types[0];
                                    }
                                    if (index === 1) {
                                        return;
                                    }
                                    if (index === 3) {
                                        vendorServices = data?.purchased_services?.service_types[2];
                                    }
                                    if (index === 4) {
                                        vendorServices = data?.purchased_services?.service_types[3];
                                    }
                                    if (vendorServices?.vendor_services?.length) {
                                        if (vendorServices?.vendor_services?.filter(item => item.service_selected).length) {
                                            additioanlSServices.push(vendorServices)
                                        }
                                    } else if (service.purchased_office_template?.purchased_office_services?.filter(item => item.service_selected).length) {
                                        additioanlSServices.push(service)
                                    }
                                })
                                return (
                                    <View>
                                        {additioanlSServices?.map(renderAdditionalService)}
                                    </View>
                                )
                            }}
                        </Query>

                    </View>
                </View>

            </Animated.ScrollView>
        </View>
    )
}


const mapStateToProps = (state) => {
    return {
        property: state.property,
    };
};
export default connect(mapStateToProps)(ThankYouPhoto);
