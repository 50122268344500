import React, {Component} from 'react';
//Components
import {SafeAreaView,ActivityIndicator, Animated, FlatList, TouchableOpacity, View} from 'react-native';
import {Label, Modal, Ripple} from 'components/src/components';
//Third Party
import PropTypes from 'prop-types';
//Network
//redux
import {setCurrentProperty} from "components/src/redux/action";
import {connect} from "react-redux";
//Utils
import {Color, CommonStyle, IS_WEB, Strings, ThemeUtils} from 'components/src/utils';
import styles from './styles';


class PropertyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
        this.animatedHeight = new Animated.Value(0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.startAnimation();
        }
    }

    //utility
    startAnimation = () => {
        Animated.timing(this.animatedHeight, {
            toValue: ThemeUtils.relativeRealHeight(50),
            duration: 200,
        }).start();
    };

    handleHardwareBack = () => {
        this.closePopUp();
        return true;
    };

    //User interaction
    closePopUp = (isSaved) => {
        Animated.timing(this.animatedHeight, {
            toValue: 0,
            duration: 200,

        }).start(() => {
            this.props.onClose && this.props.onClose()
        });
    };

    getPropertyStatus = (status) => {
        switch (status) {
            case "PENDING":
                return "Til salgs"
            case "SOLD":
                return "Solgt"

        }
    }
    onSortOptionSelected = (item, index) => {
        this.props.setCurrentProperty(item)
        if (IS_WEB) {
            localStorage.setItem('selectedProperty', JSON.stringify(item))
        }
        this.setState({})
    };

    renderItem = ({item, index}) => {
        return (
            <Ripple style={[styles.itemCont, this.props.property?.id === item.id ? styles.selectedItem : null]}
                    onPress={() => this.onSortOptionSelected(item, index)}
                    key={index.toString()}>
                <View style={CommonStyle.flex}>
                    <Label small
                           font_medium={this.props.property?.id === item.id}
                           style={CommonStyle.flex}>
                        {item.street_adress ? `${item?.street_adress ?? ""} ${item?.city ?? ""} ${item?.zipcode ?? ""}` :
                            `${item?.property?.street_adress ?? ""} ${item?.property?.city ?? ""} ${item?.property?.zipcode ?? ""}`}
                    </Label>
                    <Label small
                           font_medium={this.props.property?.id === item.id}
                           style={CommonStyle.flex}>
                        {`Oppdrag nummer. : ${item.oppdrags_nummer ? item.oppdrags_nummer ?? "-" : item.property?.oppdrags_nummer ?? "-"}`}
                    </Label>
                </View>

                <View style={styles.tagSold}>
                    <Label xsmall
                           font_medium
                           color={Color.TERNARY_TEXT_COLOR}
                           style={CommonStyle.flex}>
                        {this.getPropertyStatus(item?.property_status ? item?.property_status : item.property?.property_status)}
                    </Label>
                </View>
                {/*{this.props.property?.id === item.id ?
                    (<Icon name={'tick-simple'}
                           size={18}
                           color={Color.PRIMARY}/>) : null
                }*/}
                {/*{item.isSold ?
                    (<View style={styles.tagSold}>
                        <Label small
                               style={CommonStyle.flex}>
                            {Strings.sold}
                        </Label>
                    </View>) : null}*/}
            </Ripple>
        );
    };


    render() {
        const {style} = this.props;
        return (
            <View style={style}>
                <Modal transparent
                       hardwareAccelerated
                       visible={this.props.visible}
                       onRequestClose={this.handleHardwareBack}>
                    <SafeAreaView style={CommonStyle.safeArea}>
                        <View style={styles.cont}>
                            <TouchableOpacity activeOpacity={1}
                                              onPress={this.handleHardwareBack}
                                              style={styles.container}/>
                            <Animated.View style={[styles.bottomViewContainer,
                                {height: this.animatedHeight}]}>
                                <View style={styles.titleContainer}>
                                    <Label small
                                           font_medium>
                                        {Strings.yourProperties}
                                    </Label>
                                    <TouchableOpacity activeOpacity={0.5}
                                                      onPress={() => this.closePopUp(true)}>
                                        <Label small
                                               font_regular
                                               color={Color.LIGHT_BLUE}>
                                            {Strings.done}
                                        </Label>
                                    </TouchableOpacity>
                                </View>
                                {this.props.loading && !this.props.data ?
                                    (
                                        <View style={CommonStyle.container}>
                                            <ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>
                                        </View>
                                    ) : (
                                        <FlatList
                                            extraData={this.state}
                                            data={this.props.data ?? []}
                                            renderItem={this.renderItem}
                                            keyExtractor={item => `${item.id}`}
                                        />
                                    )}


                                {/*<CustomButton style={{borderRadius: 0}} //dont remove this inline style
                                          textColor={Color.TERNARY_TEXT_COLOR}
                                          onPress={this.props.onAddNewProperty}
                                          title={`+ ${Strings.addNewProperty}`}/>*/}
                            </Animated.View>
                        </View>

                    </SafeAreaView>
                </Modal>
            </View>
        );
    }
}

PropertyList.defaultProps = {
    title: 'Title',
    placeholder: 'Select Options...',
    sortOptions: [],
    options: 0,
    onClose: null,
};

PropertyList.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    onClose: PropTypes.func,
    onAddNewProperty: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        property: state.property,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentProperty: (property) => dispatch(setCurrentProperty(property)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyList);

