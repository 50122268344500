import {Linking, Platform} from 'react-native';
import {LocalColor} from '../utils/Color';
import Messages from '../utils/MessageUtils';
import MessageUtils from '../utils/MessageUtils';
import Constants from '../utils/Constants';
import CommonStyle from '../utils/CommonStyles';
import ThemeUtils from '../utils/ThemeUtils';
import LocalizedStrings from '../utils/Strings';
import DateUtils from '../utils/DateUtils';
import {PasswordValidate, validation} from '../utils/ValidationUtils';
import Routes from '../../../mobile/src/router/routes';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import fontelloConfig from '../../config';
import Toast from '../components/ui/Toast'
import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import {store} from "../redux/store";
import moment from "moment";
import NorwayStrings from "../../../web/src/locale/nb-NO";
import CryptoJS from 'components/src/utils/CryptoJS';

let Color = LocalColor
/*store.subscribe((state) => {
    Color = state.color
})*/


let Strings = LocalizedStrings.no

const Icon = createIconSetFromFontello(fontelloConfig);

const getCurrentColors = () => {
    let Colors = store.getState().colors;
    store.subscribe((state) => {
        if (state) {
            Colors = state.colors
        }

    })
    return Colors
}

const {
    Version,
    OS,
} = Platform;

export const IS_ANDROID = OS === 'android';
export const IS_IOS = OS === 'ios';
export const IS_WEB = OS === 'web';
export const IS_LT_LOLLIPOP = Version < 21;

const isNumeric = (val) => {
    return /^\d+$/.test(val)
}

export const encryptId = id => btoa(btoa(id));

export const decryptId = id => {
    if (IS_WEB) {
        return atob(atob(id));
    }
    let base64Id = 'base-64'
    let base64 = require(`${base64Id}`);
    return base64.decode(base64.decode(id))
}


const decodeFromBase64 = base64string => {
    const parsedWordArray = CryptoJS.enc.Base64.parse(base64string);
    return parsedWordArray.toString(CryptoJS.enc.Utf8);
};

const getDecryptedId = encryptedValue => {

    try {
        let plainText;

        if (encryptedValue !== undefined && encryptedValue !== null && encryptedValue !== '') {
            const encryptedKeyJSON = JSON.parse(decodeFromBase64(encryptedValue));

            const {value} = encryptedKeyJSON;
            const {iv} = encryptedKeyJSON;

            plainText = CryptoJS.AES.decrypt(
                value,
                CryptoJS.enc.Base64.parse('7LUIOizHvGf6SIKW17y2q5PGHLJTor1AplPtDZnVGbo='),
                {
                    iv: CryptoJS.enc.Base64.parse(iv),
                },
            ).toString(CryptoJS.enc.Utf8);
        }

        return plainText;
    } catch (e) {
        console.log('Error parsing ID', e);
        return 'error';
    }
};

const getStatusColor = (status) => {
    switch (status) {
        case Constants.ORDER_STATUS.ORDERED:
            return Color.TEXT_COLOR_RED;

        case Constants.ORDER_STATUS.TIMEBOOKED:
            return Color.TEXT_COLOR_ORANGE;

        case Constants.ORDER_STATUS.INFOADDED:
        case Constants.ORDER_STATUS.PHOTOTAKEN:
        case Constants.ORDER_STATUS.PHOTOUPLOAD:
            return Color.TEXT_COLOR_BLUE;

        case Constants.ORDER_STATUS.PROJECTCOMPLETE:
            return Color.TEXT_COLOR_GREEN;

        default:
            return Color.PRIMARY
    }
};

const getStatusLabel = (status) => {
    switch (status) {
        case Constants.ORDER_STATUS.ORDERED:
            return Strings.ordered;
        case Constants.ORDER_STATUS.TIMEBOOKED:
            return Strings.timeBooked;
        case Constants.ORDER_STATUS.PHOTOTAKEN:
            return Strings.photoTaken;
        case Constants.ORDER_STATUS.PHOTOUPLOAD:
            return Strings.photoUploaded;
        case Constants.ORDER_STATUS.PROJECTCOMPLETE:
            return Strings.projectCompleted;
        case Constants.ORDER_STATUS.INFOADDED:
            return Strings.infoAdded;

    }
};

function roundDate(date, isRounded) {
    if (!isRounded) {
        return date
    }
    return moment(Math.ceil((date) / (moment.duration(15, "minutes"))) * (moment.duration(15, "minutes")));
}

const add1Day = () => {
    return new Date(moment().add(1, 'd').set({
        hours: 23,
        minutes: 45,
        seconds: 0
    }))
}

const add4Hours = (date, customizeHour = Constants.TIME_GAP) => {
    return new Date(moment(date).add(customizeHour, 'h'))
}

const checkDiff = (date, hour = Constants.TIME_GAP) => {

    return moment().add(1, 'd').set({
        hours: 23,
        minutes: 45,
        seconds: 0
    }).diff(moment(date), 'h') < hour
}


const showMessage = (message, messageType = 'info', options = {}) => {
    if (Toast.getRef()) {
        Toast.show({
            type: messageType,
            text1: message,
            visibilityTime: 1000,
        });
    }
};

const UserType = {
    Admin: 1, //Admin
    Seller: 2,
    Agent: 3,
    Photographer: 4,
    Takst: 5,
    Stylist: 6,
    Examination: 7, //Book inspector
    Buyer: 8,
}
const getUserType = (user) => {
    switch (user) {
        case Routes.Admin:
            return UserType.Admin;
        case Routes.Seller:
            return UserType.Seller;
        case Routes.Buyer:
            return UserType.Buyer;
        case Routes.Photographer:
            return UserType.Photographer;
        case Routes.Takst:
            return UserType.Takst;
        /*case Routes.Megler:
            return UserType.Megler;*/
        case Routes.Examination:
            return UserType.Examination;
        case Routes.Stylist:
            return UserType.Stylist;
        default:
            return UserType.Seller
    }
};


const USER_TYPE = {
    ADMIN: 'Admin',
    SELLER: 'Seller',
    AGENT: 'Agent',
    PHOTO: 'Photographer',
    TAKST: 'Takst',
    STYLIST: 'Stylish',
    BOOK_INSPECTION: 'BookInspection',
    BUYER: 'Buyer',
    SUPERUSER: 'Superuser',
};


const getUserString = (userType) => {
    switch (userType) {
        case UserType.Admin:
            return 'Admin';
        case UserType.Seller:
            return 'Selger';
        case UserType.Photographer:
            return 'Fotograf';
        case UserType.Takst:
            return 'Takst';
        case UserType.Stylist:
            return 'Stylist';
        case UserType.Examination:
            return 'befaring';
        case UserType.Buyer:
            return 'Kjøper';
        case UserType.Agent:
            return 'Eiendomsmegler';

    }
}

const getUserName = (user) => {
    switch (user) {
        case UserType.Seller:
        case Routes.Seller:
            return USER_TYPE.SELLER;
        case UserType.Admin:
        case Routes.Admin:
            return USER_TYPE.ADMIN;
        case UserType.Buyer:
        case Routes.Buyer:
            return USER_TYPE.BUYER;
        case UserType.Photographer:
        case Routes.Photographer:
            return USER_TYPE.PHOTO;
        case UserType.Takst:
        case Routes.Takst:
            return USER_TYPE.TAKST;
        /*case Routes.Megler:
            return USER_TYPE.Megler;*/
        case UserType.Examination:
        case Routes.Examination:
            return USER_TYPE.BOOK_INSPECTION;
        case UserType.Stylist:
        case Routes.Stylist:
            return USER_TYPE.STYLIST;
        default:
            return USER_TYPE.SELLER
    }
};

const formatPrice = (price) => {
    if (price) {
        return price.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return ""
}
const getBidColor = (status) => {
    switch (status) {
        case 0:
            return Color.TEXT_COLOR_GREEN
        case 1:
            return Color.TEXT_COLOR_RED
    }
};

const getBidLabel = (status) => {
    switch (status) {
        case 0:
            return Strings.accepted
        case 1:
            return Strings.expired
    }
};

const openInNewTab = (url) => {
    if (window && IS_WEB) {
        window.open(url, '_blank')
    } else {
        console.log(url, "URL")
        Linking.openURL(url)
    }
}

const formatMessage = (item) => {
    return NorwayStrings[item.id]
}

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'no',
        debug: true,
        resources: {
            en: {
                translation: LocalizedStrings.en,
            },
            no: {
                translation: LocalizedStrings.no,
            },
        },
    });
// console.log(i18next.t('Contact'))

/*setTimeout(()=>{
    i18next.changeLanguage('en')
    console.log(i18next.t('Contact'))
},10000)*/

const getDateTimeForNotification = timestamp => {
    if (timestamp) {
        if (moment().diff(moment(moment(timestamp).format(DateUtils.dd_mm_yyyy), DateUtils.dd_mm_yyyy), "d") === 0) {
            return moment(timestamp).fromNow()
        } else {
            return moment(timestamp).format(DateUtils.dd_mm_yyyy_hh_mm)
        }
    } else {
        return moment().format(DateUtils.dd_mm_yyyy_hh_mm)
    }
};


const BOLIG_TYPE_0_UNDER_230 = [
    {
        name: 'Boligkjøperforsikring',
        value:
            'Denne forsikringen er en rettshjelpsforsikring som gir deg juridisk bistand hvis du oppdager vesentlige skjulte' +
            'feil og mangler, hvis det er tilbakeholdt opplysninger eller der det er gitt uriktige opplysninger som har påvirket' +
            'boligkjøpet ditt. Boligkjøperforsikringen gir deg dessuten juridisk rådgivning, advokathjelp og håndtering' +
            'gjennom rettsapparatet, hvis du trenger dette.',
    },
    {
        name: 'Innboforsikring med superdekning',
        value: 'If sin beste innboforsikring med en forsikringssum på inntil 2 000 000 kroner.',
    },
    {
        name: 'Hus-/hytteforsikring',
        value:
            'If sin standard hus- og hytteforsikring. Forsikringen kan oppgraderes til superdekning hvis ønskelig.',
    },
    {
        name: 'Flytteforsikring',
        value: 'Dekker skade på innbo som oppstår i forbindelse med selve flyttingen.',
    },
    {
        name: 'Dobbel rente forsikring',
        value:
            'Dekker rente- og forsikringspremie med inntil 10 000 kroner per måned på den gamle boligen din. Forsikringen' +
            'gjelder hvis du ikke får solgt den gamle boligen tre måneder etter du har kjøpt ny bolig, med utbetaling i inntil' +
            'ni måneder. Dekningen gjelder i forhold til bud opp til 90 % av prisantydningen. Forsikringen gjelder ikke hytte.',
    },
    {
        name: 'Skadedyrforsikring',
        value:
            'If hjelper deg hvis du får skadedyr eller insekter som veggdyr, kakerlakker, stokkmaur m.m. i boligen din, og tar' +
            'hånd om hele denne prosessen for deg.',
    },
    {
        name: 'Andre dekninger',
        value:
            'If dekker tyveri av mobilen og sykkelen din uten egenandel, uhellsskader på tingene dine og hjelper deg ved ID-' +
            'tyveri inntil kr 1.000.000,-.',
    },
    {
        name: 'Våtromsbevis',
        value:
            'Selges eiendommen med ett eller flere våtromsbevis, vil du ved kjøp av boligkjøperpakken få dekket reparasjon' +
            'av badet hvis det er utett. Våtromsbeviset er gyldig i fire år.',
    },
    {
        name: 'Pris på boligkjøperpakken: kr 12.650,-',
        value:
            'Boligkjøperforsikringen gjelder fra kontraktsmøtet og i ett år etter overtakelse. Prisen du betaler for' +
            'boligkjøperpakken gjelder det første året, og innbetales sammen med kjøpesum og omkostningene ved' +
            'boligkjøpet til meglers klientkonto. Dette innebærer at du ikke får en særskilt faktura for forsikringene dine det' +
            'første året. If tar kontakt med deg før det første året er over og sammen med deg sørger If for at boligen din er' +
            'riktig forsikret videre. Selve boligkjøperforsikringen kan beholdes i opptil fem år, og vil årlig koste kr 300,- etter' +
            'det første året.',
    },
    {
        name: 'Pris på kun boligkjøperforsikring: kr 11.100,-',
        value:
            'Boligkjøperforsikringen gjelder fra kontraktsmøtet og i 5 år etter overtakelse. Kostnaden innbetales sammen' +
            'med kjøpesum og omkostningene ved boligkjøpet til meglers klientkonto.',
    },
];

const BOLIG_TYPE_0_ABOVE_230 = [{
    name: "Boligkjøperforsikring",
    value: "Denne forsikringen er en rettshjelpsforsikring som gir deg juridisk bistand hvis du oppdager vesentlige skjulte" +
        "feil og mangler, hvis det er tilbakeholdt opplysninger eller der det er gitt uriktige opplysninger som har påvirket" +
        "boligkjøpet ditt. Boligkjøperforsikringen gir deg dessuten juridisk rådgivning, advokathjelp og håndtering" +
        "gjennom rettsapparatet, hvis du trenger dette."
}, {
    name: "Innboforsikring med superdekning ",
    value: "If sin beste innboforsikring med en forsikringssum på inntil 2 000 000 kroner."
}, {
    name: "Hus-/hytteforsikring ",
    value: "If sin standard hus- og hytteforsikring. Forsikringen kan oppgraderes til superdekning hvis ønskelig."
}, {
    name: "Flytteforsikring",
    value: "Dekker skade på innbo som oppstår i forbindelse med selve flyttingen."
}, {
    name: "Dobbel rente forsikring",
    value: "Dekker rente- og forsikringspremie med inntil 10 000 kroner per måned på den gamle boligen din. Forsikringen" +
        "gjelder hvis du ikke får solgt den gamle boligen tre måneder etter du har kjøpt ny bolig, med utbetaling i inntil" +
        "ni måneder. Dekningen gjelder i forhold til bud opp til 90 % av prisantydningen. Forsikringen gjelder ikke hytte."
}, {
    name: "Skadedyrforsikring",
    value: "If hjelper deg hvis du får skadedyr eller insekter som veggdyr, kakerlakker, stokkmaur m.m. i boligen din, og tar" +
        "hånd om hele denne prosessen for deg."
}, {
    name: "Andre dekninger",
    value: "If dekker tyveri av mobilen og sykkelen din uten egenandel, uhellsskader på tingene dine og hjelper deg ved ID-" +
        "tyveri inntil kr 1.000.000,-."
}, {
    name: "Våtromsbevis",
    value: "Selges eiendommen med ett eller flere våtromsbevis, vil du ved kjøp av boligkjøperpakken få dekket reparasjon" +
        "av badet hvis det er utett. Våtromsbeviset er gyldig i fire år."
}, {
    name: "Pris på boligkjøperpakken: kr 14.650,-",
    value: "Boligkjøperforsikringen gjelder fra kontraktsmøtet og i ett år etter overtakelse. Prisen du betaler for" +
        "boligkjøperpakken gjelder det første året, og innbetales sammen med kjøpesum og omkostningene ved" +
        "boligkjøpet til meglers klientkonto. Dette innebærer at du ikke får en særskilt faktura for forsikringene dine det" +
        "første året. If tar kontakt med deg før det første året er over og sammen med deg sørger If for at boligen din er" +
        "riktig forsikret videre. Selve boligkjøperforsikringen kan beholdes i opptil fem år, og vil årlig koste kr 300,- etter" +
        "det første året."
}, {
    name: "Pris på kun boligkjøperforsikring: kr 11.100,-",
    value: "Boligkjøperforsikringen gjelder fra kontraktsmøtet og i 5 år etter overtakelse. Kostnaden innbetales sammen" +
        "med kjøpesum og omkostningene ved boligkjøpet til meglers klientkonto."
}]

const BOLIG_TYPE_1_2 = [{
    name: "Boligkjøperforsikring",
    value: "Denne forsikringen er en rettshjelpsforsikring som gir deg juridisk bistand hvis du oppdager vesentlige skjulte" +
        "feil og mangler, hvis det er tilbakeholdt opplysninger eller der det er gitt uriktige opplysninger som har påvirket" +
        "boligkjøpet ditt. Boligkjøperforsikringen gir deg dessuten juridisk rådgivning, advokathjelp og håndtering" +
        "gjennom rettsapparatet, hvis du trenger dette."
}, {
    name: "Innboforsikring med superdekning",
    value: "If sin beste innboforsikring med en forsikringssum på inntil 2 000 000 kroner."
}, {
    name: "Flytteforsikring",
    value: "Dekker skade på innbo som oppstår i forbindelse med selve flyttingen."
}, {
    name: "Dobbel rente forsikring",
    value: "Dekker rente- og forsikringspremie med inntil 10 000 kroner per måned på den gamle boligen din. Forsikringen" +
        "gjelder hvis du ikke får solgt den gamle boligen tre måneder etter du har kjøpt ny bolig, med utbetaling i inntil" +
        "ni måneder. Dekningen gjelder i forhold til bud opp til 90 % av prisantydningen. Forsikringen gjelder ikke hytte."
}, {
    name: "Skadedyrforsikring",
    value: "If hjelper deg hvis du får skadedyr eller insekter som veggdyr, kakerlakker, stokkmaur m.m. i boligen din, og tar" +
        "hånd om hele denne prosessen for deg."
}, {
    name: "Andre dekninger",
    value: "If dekker tyveri av mobilen og sykkelen din uten egenandel, uhellsskader på tingene dine og hjelper deg ved ID-" +
        "tyveri inntil kr 1.000.000,-."
}, {
    name: "Våtromsbevis",
    value: "Selges eiendommen med ett eller flere våtromsbevis, vil du ved kjøp av boligkjøperpakken få dekket reparasjon" +
        "av badet hvis det er utett. Våtromsbeviset er gyldig i fire år."
}, {
    name: "Pris på boligkjøperpakken: kr 6.850,-",
    value: "Boligkjøperforsikringen gjelder fra kontraktsmøtet og i ett år etter overtakelse. Prisen du betaler for" +
        "boligkjøperpakken gjelder det første året, og innbetales sammen med kjøpesum og omkostningene ved" +
        "boligkjøpet til meglers klientkonto. Dette innebærer at du ikke får en særskilt faktura for forsikringene dine det" +
        "første året. If tar kontakt med deg før det første året er over og sammen med deg sørger If for at boligen din er" +
        "riktig forsikret videre. Selve boligkjøperforsikringen kan beholdes i opptil fem år, og vil årlig koste kr 300,- etter" +
        "det første året."
}, {
    name: "Pris på kun boligkjøperforsikring: kr 5.900,-",
    value: "Boligkjøperforsikringen gjelder fra kontraktsmøtet og i 5 år etter overtakelse. Kostnaden innbetales sammen" +
        "med kjøpesum og omkostningene ved boligkjøpet til meglers klientkonto."
}]

const BOLIG_TYPE_14_15 = [{
    name: "Boligkjøperforsikring",
    value: "Boligkjøperforsikringen gjelder fra kontraktsmøtet og i 5 år etter overtakelse. Kostnaden innbetales sammen" +
        "med kjøpesum og omkostningene ved boligkjøpet til meglers klientkonto. Forsikringen er en" +
        "rettshjelpsforsikring som gir deg juridisk bistand hvis du oppdager vesentlige skjulte feil og mangler, hvis det er" +
        "tilbakeholdt opplysninger eller der det er gitt uriktige opplysninger som har påvirket kjøpet ditt." +
        "Boligkjøperforsikringen gir deg dessuten juridisk rådgivning, advokathjelp og håndtering gjennom" +
        "rettsapparatet, hvis du trenger dette."
}]

const BOLIG_TYPE_4 = [{
    name: "Boligkjøperforsikring",
    value: "Denne forsikringen er en rettshjelpsforsikring som gir deg juridisk bistand hvis du oppdager vesentlige skjulte" +
        "feil og mangler, hvis det er tilbakeholdt opplysninger eller der det er gitt uriktige opplysninger som har påvirket" +
        "boligkjøpet ditt. Boligkjøperforsikringen gir deg dessuten juridisk rådgivning, advokathjelp og håndtering" +
        "gjennom rettsapparatet, hvis du trenger dette."
}, {
    name: "Innboforsikring med superdekning",
    value: "If sin beste innboforsikring med en forsikringssum på inntil 2 000 000 kroner."
}, {
    name: "Flytteforsikring",
    value: "Dekker skade på innbo som oppstår i forbindelse med selve flyttingen."
}, {
    name: "Dobbel rente forsikring",
    value: "Dekker rente- og forsikringspremie med inntil 10 000 kroner per måned på den gamle boligen din. Forsikringen" +
        "gjelder hvis du ikke får solgt den gamle boligen tre måneder etter du har kjøpt ny bolig, med utbetaling i inntil" +
        "ni måneder. Dekningen gjelder i forhold til bud opp til 90 % av prisantydningen. Forsikringen gjelder ikke hytte."
}, {
    name: "Skadedyrforsikring",
    value: "If hjelper deg hvis du får skadedyr eller insekter som veggdyr, kakerlakker, stokkmaur m.m. i boligen din, og tar" +
        "hånd om hele denne prosessen for deg."
}, {
    name: "Andre dekninger",
    value: "If dekker tyveri av mobilen og sykkelen din uten egenandel, uhellsskader på tingene dine og hjelper deg ved ID-" +
        "tyveri inntil kr 1.000.000,-."
}, {
    name: "Våtromsbevis",
    value: "Selges eiendommen med ett eller flere våtromsbevis, vil du ved kjøp av boligkjøperpakken få dekket reparasjon" +
        "av badet hvis det er utett. Våtromsbeviset er gyldig i fire år."
}, {
    name: "Pris på boligkjøperpakken: kr 7.650,-",
    value: "Boligkjøperforsikringen gjelder fra kontraktsmøtet og i ett år etter overtakelse. Prisen du betaler for" +
        "boligkjøperpakken gjelder det første året, og innbetales sammen med kjøpesum og omkostningene ved" +
        "boligkjøpet til meglers klientkonto. Dette innebærer at du ikke får en særskilt faktura for forsikringene dine det" +
        "første året. If tar kontakt med deg før det første året er over og sammen med deg sørger If for at boligen din er" +
        "riktig forsikret videre. Selve boligkjøperforsikringen kan beholdes i opptil fem år, og vil årlig koste kr 300,- etter" +
        "det første året."
}, {
    name: "Pris på kun boligkjøperforsikring: kr 7.300,-",
    value: "Boligkjøperforsikringen gjelder fra kontraktsmøtet og i 5 år etter overtakelse. Kostnaden innbetales sammen" +
        "med kjøpesum og omkostningene ved boligkjøpet til meglers klientkonto."
}];

const BOLIG_TYPE_4_230 = [{
    name: "Boligkjøperforsikring",
    value: "Denne forsikringen er en rettshjelpsforsikring som gir deg juridisk bistand hvis du oppdager vesentlige skjulte" +
        "feil og mangler, hvis det er tilbakeholdt opplysninger eller der det er gitt uriktige opplysninger som har påvirket" +
        "boligkjøpet ditt. Boligkjøperforsikringen gir deg dessuten juridisk rådgivning, advokathjelp og håndtering" +
        "gjennom rettsapparatet, hvis du trenger dette."
}, {
    name: "Innboforsikring med superdekning",
    value: "If sin beste innboforsikring med en forsikringssum på inntil 2 000 000 kroner."
}, {
    name: "Hus-/hytteforsikring",
    value: "If sin standard hus- og hytteforsikring. Forsikringen kan oppgraderes til superdekning hvis ønskelig."
}, {
    name: "Flytteforsikring",
    value: "Dekker skade på innbo som oppstår i forbindelse med selve flyttingen."
}, {
    name: "Dobbel rente forsikring",
    value: "Dekker rente- og forsikringspremie med inntil 10 000 kroner per måned på den gamle boligen din. Forsikringen" +
        "gjelder hvis du ikke får solgt den gamle boligen tre måneder etter du har kjøpt ny bolig, med utbetaling i inntil" +
        "ni måneder. Dekningen gjelder i forhold til bud opp til 90 % av prisantydningen. Forsikringen gjelder ikke hytte."
}, {
    name: "Skadedyrforsikring",
    value: "If hjelper deg hvis du får skadedyr eller insekter som veggdyr, kakerlakker, stokkmaur m.m. i boligen din, og tar" +
        "hånd om hele denne prosessen for deg."
}, {
    name: "Andre dekninger",
    value: "If dekker tyveri av mobilen og sykkelen din uten egenandel, uhellsskader på tingene dine og hjelper deg ved ID-" +
        "tyveri inntil kr 1.000.000,-."
}, {
    name: "Våtromsbevis",
    value: "Selges eiendommen med ett eller flere våtromsbevis, vil du ved kjøp av boligkjøperpakken få dekket reparasjon" +
        "av badet hvis det er utett. Våtromsbeviset er gyldig i fire år."
}, {
    name: "Pris på boligkjøperpakken: kr 12.650,-",
    value: "Boligkjøperforsikringen gjelder fra kontraktsmøtet og i ett år etter overtakelse. Prisen du betaler for" +
        "boligkjøperpakken gjelder det første året, og innbetales sammen med kjøpesum og omkostningene ved" +
        "boligkjøpet til meglers klientkonto. Dette innebærer at du ikke får en særskilt faktura for forsikringene dine det" +
        "første året. If tar kontakt med deg før det første året er over og sammen med deg sørger If for at boligen din er" +
        "riktig forsikret videre. Selve boligkjøperforsikringen kan beholdes i opptil fem år, og vil årlig koste kr 300,- etter" +
        "det første året."
}, {
    name: "Pris på kun boligkjøperforsikring: kr 11.100,-",
    value: "Boligkjøperforsikringen gjelder fra kontraktsmøtet og i 5 år etter overtakelse. Kostnaden innbetales sammen" +
        "med kjøpesum og omkostningene ved boligkjøpet til meglers klientkonto."
}]

export const getBoligTitle = (type, area) => {
    switch (type) {
        case 0:
            if (area < 230) {
                return 'Boligkjøperpakken\n' + 'Din nye bolig ferdig forsikret!';
            }
            return 'Boligkjøperpakken\n' + 'Din nye bolig ferdig forsikret!';
        case 1:
        case 2:
            return 'Boligkjøperpakken\n' + 'Din nye bolig ferdig forsikret!';
        case 4:
            if (area < 230) {
                return 'Boligkjøperpakken\n' + 'Din nye bolig ferdig forsikret!';
            }
            return 'Boligkjøperpakken\n' + 'Din nye bolig ferdig forsikret!';
        case 14:
        case 15:
            return 'Boligkjøperforsikring\n' + 'Din trygghet!';
    }
};

export const getBoligDesc = (type, area) => {
    switch (type) {
        case 0:
            if (area < 230) {
                return (
                    'Som kjøper har du reklamasjonsrett i 5 år fra overtakelse. Hver fjerde eller femte bolighandel ender i boligtvist.' +
                    'Når du tegner Boligkjøperforsikring hos Buysure (IF skadeforsikring) sikrer du deg mot den økonomiske og' +
                    ' ressurskrevende belastningen det er å fremme krav overfor selger dersom du oppdager feil eller mangler ved' +
                    'boligen i løpet av alle disse årene.\n\nMed vår Boligkjøperpakke får du'
                );
            }
            return (
                'Som kjøper har du reklamasjonsrett i 5 år fra overtakelse. Hver fjerde eller femte bolighandel ender i boligtvist.' +
                'Når du tegner Boligkjøperforsikring hos Buysure (IF skadeforsikring) sikrer du deg mot den økonomiske og' +
                ' ressurskrevende belastningen det er å fremme krav overfor selger dersom du oppdager feil eller mangler ved' +
                'boligen i løpet av alle disse årene.\n\nMed vår Boligkjøperpakke får du'
            );
        case 1:
        case 2:
            return (
                'Som kjøper har du reklamasjonsrett i 5 år fra overtakelse. Hver fjerde eller femte bolighandel ender i boligtvist.' +
                'Når du tegner Boligkjøperforsikring hos Buysure (IF skadeforsikring) sikrer du deg mot den økonomiske og' +
                ' ressurskrevende belastningen det er å fremme krav overfor selger dersom du oppdager feil eller mangler ved' +
                'boligen i løpet av alle disse årene.\n\n' +
                'Med vår Boligkjøperpakke får du'
            );
        case 4:
            if (area < 230) {
                return (
                    'Som kjøper har du reklamasjonsrett i 5 år fra overtakelse. Hver fjerde eller femte bolighandel ender i boligtvist.' +
                    'Når du tegner Boligkjøperforsikring hos Buysure (IF skadeforsikring) sikrer du deg mot den økonomiske og' +
                    ' ressurskrevende belastningen det er å fremme krav overfor selger dersom du oppdager feil eller mangler ved' +
                    'boligen i løpet av alle disse årene.' +
                    'Med vår Boligkjøperpakke får du'
                );
            }
            return (
                'Som kjøper har du reklamasjonsrett i 5 år fra overtakelse. Hver fjerde eller femte bolighandel ender i boligtvist.' +
                'Når du tegner Boligkjøperforsikring hos Buysure (IF skadeforsikring) sikrer du deg mot den økonomiske og' +
                ' ressurskrevende belastningen det er å fremme krav overfor selger dersom du oppdager feil eller mangler ved' +
                'boligen i løpet av alle disse årene.' +
                'Med vår Boligkjøperpakke får du'
            );
        case 14:
        case 15:
            return (
                'Som kjøper har du reklamasjonsrett i 5 år fra overtakelse. Hver fjerde eller femte bolighandel ender i boligtvist.' +
                'Når du tegner Boligkjøperforsikring hos Buysure (IF skadeforsikring) sikrer du deg mot den økonomiske og' +
                ' ressurskrevende belastningen det er å fremme krav overfor selger dersom du oppdager feil eller mangler ved' +
                'boligen i løpet av alle disse årene.'
            );
    }
};

export const getBoligOptions = (type, area) => {
    switch (type) {
        case 0:
            if (area < 230)
                return BOLIG_TYPE_0_UNDER_230
            return BOLIG_TYPE_0_ABOVE_230
        case 1:
        case 2:
            return BOLIG_TYPE_1_2

        case 4:
            if (area < 230)
                return BOLIG_TYPE_4
            return BOLIG_TYPE_4_230
        case 14:
        case 15:
            return BOLIG_TYPE_14_15
        default:
            return [];
    }
};


const NOTIFICATION_TYPE = {
    SELLER_ENERGY_LABELING: 1,
    SELLER_RATE_ORDER: 2,
    SELLER_INSPECTION_ORDER: 3,
    SELLER_STYLING_ORDER: 4,
    SELLER_PHOTO_ORDER: 5,
    SELLER_INTERVIEW: 6,
    SELLER_PHOTO_COMMENT: 7,
    SELLER_RATE_DETAILS_COMMENT: 8,
    PHOTO_ORDER_CONFIRM: 9,
    TAKST_ORDER_CONFIRM: 10,
    INSPECTION_ORDER_CONFIRM: 11,
    PHOTO_UPLOAD: 12,
    RATE_DETAILS_ADDED: 13,
    PHOTO_ORDER_COMPLETED: 14,
    RATE_ORDER_COMPLETED: 15,
    INSPECTION_ORDER_COMPLETED: 16,
    SELLER_ADDED_VIEW: 17,
    AGENT_ADDED_FINN_NO: 18,
    AGENT_ADDED_DS: 19,
    AGENT_ADDED_MARKETING_MATERIAL: 20,
    SELLER_APPROVED_FINN_NO: 21,
    SELLER_APPROVED_DS: 22,
    SELLER_APPROVED_MARKETING_MATERIAL: 23,
    SELLER_ADDED_COMMENT_FINN_NO: 24,
    SELLER_ADDED_COMMENT_DS: 25,
    SELLER_ADDED_COMMENT_MARKETING_MATERIAL: 26,
    AGENT_UPLOADED_DOC_SELLER: 27,
    AGENT_UPLOADED_DOC_BUYER: 28,
    SELLER_ADD_ADDITIONAL_SERVICES: 29,
    ADMIN_ADDED_FEGLING: 30,
    ADMIN_ADDED_NEWS: 31,
    CUSTOM_NOTIFICATION: 32,
    SEND_BY_AGENT: 33,
    SELLER_BOOK_INSPECTION_NOTIFY: 34,
    SELLER_ENERGY_LABELING_NOTIFY: 35,
    SELLER_ORDER_TAKST_NOTIFY: 36,
    SELLER_ORDER_PHOTO_NOTIFY: 37,
    SELLER_SUBMIT_INTERVIEW_NOTIFY: 38,
    SELLER_APPROVE_DS_NOTIFY: 39,
    SELLER_APPROVE_FINN_NOTIFY: 40,
    SELLER_APPROVE_MARKETING_MATERIAL_NOTIFY: 41,
    SELLER_SETTLEMENT_INFO_NOTIFY: 42,
    MOVING_GUIDE_CONTACT_ME_NOTIFY: 43,
    SELLER_SUBMIT_SETTLEMENT_INFO: 44,
    BUYER_REGISTER: 45,
    AGENT_PHOTO_DELIVER: 46,
    AGENT_TAKST_DELIVER: 47,
    INSPECTION_DELIVER: 48,
};

export {
    NOTIFICATION_TYPE,
    USER_TYPE,
    getDateTimeForNotification,
    formatPrice,
    Color,
    Messages,
    Strings,
    Constants,
    CommonStyle,
    ThemeUtils,
    validation,
    PasswordValidate,
    MessageUtils,
    Icon,
    isNumeric,
    getBidLabel,
    getBidColor,
    Routes,
    getStatusLabel,
    getStatusColor,
    getUserType,
    UserType,
    DateUtils,
    getUserName,
    showMessage,
    openInNewTab,
    getCurrentColors,
    add1Day,
    checkDiff,
    add4Hours,
    roundDate,
    formatMessage,
    getUserString,
    getDecryptedId,
};
