import React from 'react'
//Components
import {ActivityIndicator, Animated, FlatList, View} from 'react-native'
import {Header, IndicatorViewPager, Label, PagerTabIndicator} from "components/src/components";
//Utils
import styles from './styles';
import {Color, CommonStyle, DateUtils, formatPrice, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import {useQuery} from "@apollo/react-hooks";
import {APPOINTMENT_DETAILS} from 'components/src/api/buyerQuery'
import {connect} from "react-redux";
import moment from "moment";

const TABS = [{
    text: Strings.sellersDetails
}, {
    text: Strings.appointmentDetails
}];

const SELLER_DETAILS = [{
    id: 'first_name',
    parentId: 'seller',
    title: Strings.firstName,
    value: 'Frank'
}, {
    id: 'last_name',
    parentId: 'seller',
    title: Strings.lastName,
    value: 'Doe'
}, {
    id: 'address',
    parentId: 'seller',
    title: Strings.address,
    value: 'Brakestien 5'
}, {
    id: 'city',
    parentId: 'seller',
    title: Strings.city,
    value: 'Adger'
}, {
    id: 'zipcode',
    parentId: 'seller',
    title: Strings.zipcode,
    value: '4760'
}, {
    id: 'mobile',
    parentId: 'seller',
    title: Strings.phone,
    value: '123-456-898'
}, {
    id: 'email',
    parentId: 'seller',
    title: Strings.email,
    value: 'frank@gmail.com'
},]

const APPOINTMENT_DETAIL = [{
    id: ['street_adress', 'zipcode', 'city'],
    title: Strings.address,
}, {
    id: 'municipality',
    title: Strings.municipality,
}, {
    id: 'gnr',
    title: Strings.gnr,
}, {
    id: 'bnr',
    title: Strings.bnr,
}, {
    id: 'fnr',
    title: Strings.fnr,
}, {
    id: 'partOrgNumber',
    title: Strings.orgNr,
}, {
    id: 'snr',
    title: Strings.snrShareNo,
    value: '3'
},]

function AppointmentInfo(props) {
    const {history, property, navigation} = props;
    const scrollY = new Animated.Value(0)

    const {loading, data, error} = useQuery(APPOINTMENT_DETAILS, {
        variables: {
            id: property?.property?.id
        }
    })
    const renderLabelWithTitle = ({item, index}) => {
        return (
            <View style={styles.lblCont}>
                <Label small
                       mt={5}
                       font_medium>
                    {item.title}
                </Label>
                {Array.isArray(item.id) ? (
                    <Label small
                           mt={10}>
                        {item.id.map(subId => `${data?.appointment_info[subId]} `)}
                    </Label>
                ) : (
                    <Label small
                           mt={10}>
                        {item.parentId ? data?.appointment_info[item.parentId][item.id] : data?.appointment_info[item.id]}
                    </Label>
                )}

            </View>
        )
    }
    const renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };

    return (
        <View style={CommonStyle.container}>
            <Header navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}
                    animatedTitle={Strings.appointmentInfo}/>

            <View style={CommonStyle.container}>
                <View style={styles.icCont}>
                    <Icon name={'settlement-information'}
                          size={ThemeUtils.fontXXLarge}
                          color={Color.PRIMARY}/>
                    <Label small
                           mt={15}
                           mb={10}>
                        {Strings.appointmentInfoInst}
                    </Label>
                </View>
                <IndicatorViewPager indicator={renderTabIndicator()}
                                    style={{flex: 1, backgroundColor: 'white'}}>

                    <View style={styles.lblListCont}>

                        {loading && !data ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : data?.appointment_info ? (
                            <FlatList data={SELLER_DETAILS}

                                      showsVerticalScrollIndicator={false}
                                      renderItem={renderLabelWithTitle}/>
                        ) : (
                            <Label small
                                   font_medium
                                   align={'center'}
                                   style={CommonStyle.loader}>
                                {"Ingen data"}
                            </Label>
                        )}

                    </View>

                    <View style={styles.lblListCont}>
                        {loading && !data ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : data?.appointment_info ? (
                            <View>
                                <View style={styles.lblCont}>
                                    <Label small
                                           mt={5}
                                           font_medium>
                                        {Strings.PurchasePrice}
                                    </Label>
                                    <Label small
                                           mt={10}>
                                        {data?.appointment_info?.vitecProperty?.vitecPropertyEstatePrice?.priceSuggestion ? `Kr ${formatPrice(data?.appointment_info?.vitecProperty?.vitecPropertyEstatePrice?.priceSuggestion)},-` : "-"}
                                    </Label>
                                </View>
                                <View style={styles.lblCont}>
                                    <Label small
                                           mt={5}
                                           font_medium>
                                        {Strings.TakeoverDate}
                                    </Label>
                                    <Label small
                                           mt={10}>
                                        {data?.appointment_info?.vitecProperty?.takeOverDate ? moment(data?.appointment_info?.vitecProperty?.takeOverDate, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy) : "-"}
                                    </Label>
                                </View>

                                <FlatList data={APPOINTMENT_DETAIL}
                                          showsVerticalScrollIndicator={false}
                                          renderItem={renderLabelWithTitle}/>
                            </View>
                        ) : (
                            <Label small
                                   font_medium
                                   align={'center'}
                                   style={CommonStyle.loader}>
                                {"Ingen data"}
                            </Label>
                        )}

                    </View>

                </IndicatorViewPager>
            </View>


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(AppointmentInfo);
