export default {
    'component.movingguide.title': 'Flytteveiledning',
    'component.movingguide.description':
      "Moving can be an energy and time consuming process. We Webmegling want to help make it as simple and effective as possible. That's why we've created a guide with things to keep in mind about the relocation process, as well as how you can easily obtain offers for services you may need.",
    'component.movingguide.webmeglingmooving': 'Webmegling Moovin(g)',
    'component.movingguide.webmeglingmooving.description':
      'Our digital platform, which we have called moovin, can also be used for mooving. In addition to assisting with the checklist for relocation, we can assist with full service relocation services at a fixed price. We take care of everything from washing up to ..........',
    'component.movingguide.contactmeformoreinformation': 'Contact Me for More Information',
    'component.movingguide.sortdonatethrow': 'Sort, Donate and Throw.',
    'component.movingguide.sortdonatethrow.description':
      "Well before you start packing, it's a good idea and go all you have to own. Something you may not want to carry with you? Before you throw, give it away or sell it on various digital platforms.",
    'component.movingguide.seemovie': 'Se film',
  };
  