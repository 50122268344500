/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { decryptId, encryptId } from "../../../utils/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { Button,Spin, Col, Row, Typography } from "antd";
import React, { useEffect,useState } from "react";
import { Query } from "react-apollo";
import { Link,useHistory } from "react-router-dom";
import moment from "moment";
import { formatMessage } from "components/src/utils";
import { ADMIN_NEWS_DEATILS } from "../../../../query/AdminQuery";
import { STORAGE_URL } from "../../../utils/constant";
// import newsimage from '../../../assets/News _ Professional/News_Big.png';

const { Title } = Typography;
let newsItem;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const newsDetail = props => {
let router=useHistory();
    const { id } = props.match.params;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [orderData, setOrderData] = useState('');
    return (
        <>
            <Row className="contentshadow">
                <Query
                    query={ADMIN_NEWS_DEATILS}
                    variables={{
                        id: parseInt(decryptId(id), 10),
                        status: 1,
                    }}
                    fetchPolicy="cache-and-network"

                >
                    {({ data, error,loading }) => {
                        console.log(error);
                        if (data) {
                            return (
                                <Col xs={24} lg={24}>
                                    {data.news_detail.cover_image_url && (
                                        <div className="cover-img-box">
                                            <img
                                                src={`${STORAGE_URL}${data.news_detail.cover_image_url}`}
                                                alt="news detail"
                                                className="img-fluid"
                                                style={{ margin: '0px auto 30px', display: 'block' }}
                                            />
                                        </div>
                                    )}
                                    <Title level={3} className="text-primary mt-20">
                                        {formatMessage({ id: 'component.news.newslatest' })}
                                    </Title>
                                    <div className="text-primary">
                                        <div dangerouslySetInnerHTML={{ __html: data.news_detail.description }} />
                                    </div>
                                    {data.news_detail.video_url && (
                                        <div className="videoSizing">
                                            <video width="600" height="400" controls className="videostyle mt-20">
                                                <track kind="captions" />
                                                <source
                                                    src={`${STORAGE_URL}${data.news_detail.video_url}`}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        </div>
                                    )}
                                    <h5 style={{ opacity: 0.5 }} className="mb-20 mt-20">
                                        Nyhet er publisert :{' '}
                                        {moment(data.news_detail.created_at).format('DD-MM-YYYY HH:mm')}
                                    </h5>
                                    <Row>
                                        <Col xs={24}>
                                            <Link
                                                to={{
                                                    pathname: `/admin/nyheter/redigernyheter/${encryptId(
                                                        data.news_detail.id,
                                                    )}`,
                                                    state: { orderItem: data.news_detail },
                                                }}
                                            >
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="btn-secondary buttons admin-forms-btn mt-20"
                                                    size="large"
                                                >
                                                    {formatMessage({
                                                        id: 'component.news.editbutton',
                                                    })}
                                                </Button>
                                            </Link>
                                            <Button
                                                className="text-primary admin-forms-btn"
                                                style={{ marginLeft: 8 }}
                                                onClick={router.goBack}
                                                size="large"
                                            >
                                                {formatMessage({
                                                    id: 'component.form.cancel',
                                                })}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            );
                        }
                        if (loading && !error) {
                            return (
                                <div className="loader-wrapper">
                                    <Spin />
                                </div>
                            );
                        }
                        if (!loading && error) {
                            return (
                                <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>
                            );
                        }
                        return <React.Fragment />;
                    }}
                </Query>
            </Row>
        </>
    );
};

export default newsDetail;
