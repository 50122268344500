/* eslint-disable no-unused-expressions */
import {
  Button,
  Col,
  Form,
  Input,
    InputNumber,
  Row,
  Upload,
  Divider,
  notification,
  Checkbox,
} from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { formatMessage } from "components/src/utils";
import { PlusOutlined } from "@ant-design/icons";
import { Mutation } from "react-apollo";
import { ADMIN_ADD_OFFICE } from "../../../../../../../mutation/AdminMutation";
// import { USER_TYPE } from '../../../../../../utils/constant';

import "../../addofficeceo.scss";
// import { from } from 'apollo-link';

// const { Title } = Typography;
const uploadButton = (
  <div>
    <PlusOutlined className="uploadplus" />
    <div className="ant-upload-text label-texts">
      {formatMessage({
        id: "component.form.upload",
      })}
    </div>
  </div>
);

const AddOfficesDetails = () => {
  let router=useHistory();
  const [imageUrl, setimageUrl] = useState('');
  const [officeLogo, setOfficeLogo] = useState('');
  const [banner1, setBanner1] = useState('');
  const [banner2, setBanner2] = useState('');
  const [imageObject, setimageObject] = useState('');
  const [officeLogoObject, setOfficeLogoObject] = useState('');
  const [banner1Object, setBanner1Object] = useState('');
  const [banner2Object, setBanner2Object] = useState('');
  const [isAdminOffice, setisAdminOffice] = useState(false);
  const [departmentIdValue, setdepartmentIdValue] = useState();
  const [departmentList, setdepartmentList] = useState([]);


  const [addOfficeForm]= Form.useForm();
  let departmentIdList = [];
  const addDepartmentIdList = id => {
    if (id) {
      departmentIdList = departmentList;
      departmentIdList.push(id);
      console.log('departmentIdList-', departmentIdList);
      setdepartmentList(departmentIdList);
      setdepartmentIdValue();
      addOfficeForm.resetFields(['departmentId']);
    }
  };

  const onRemove = value => {
    console.log('value::', departmentList.indexOf(value));
    const list = departmentList.filter(
        i => departmentList.indexOf(value) !== departmentList.indexOf(i),
    );
    console.log('list->', list);
    setdepartmentList(list);
  };

  const onFinish = (values, addOffice) => {
    console.log('Success:', values);
    console.log('isAdminOffice:', isAdminOffice);
    console.log('departmentList:', departmentList.toString());
    // eslint-disable-next-line no-lone-blocks
    if (values.departmentId) {
      addDepartmentIdList(values.departmentId);
    }

    const inputValue = {
      name: values.officename,
      city: values.city,
      image_url: imageObject,
      logo_url: officeLogoObject,
      company_name: values.companyname,
      org_number: values.orgnumber,
      slug: values.officeurl,
      widget_code: values.tawkwidgetcode,
      banner_1: banner1Object,
      banner_2: banner2Object,
      small_headline: values.smallheadline,
      big_headline: values.bigheadline,
      ingress: values.ingress,
      headline_text_section: values.headlinetextsection,
      text_section: values.textsection,
      address: values.address,
      zipcode: values.zipcode,
      telephone_no: values.phone,
      email: values.sendemail,
      call_us: values.callus,
      send_email: values.sendemail,
      is_admin_office: isAdminOffice,
      department_id: departmentList.toString(),
    };

    if (values.vitecusername && values.password && values.installationid) {
      inputValue.username = values.vitecusername;
      inputValue.password = values.password;
      inputValue.installer_id = values.installationid;
    }
    if (values.vitecusername && values.password && !values.installationid) {
      inputValue.username = values.vitecusername;
      inputValue.password = values.password;
    }
    if (values.vitecusername && !values.password && values.installationid) {
      inputValue.username = values.vitecusername;
      inputValue.installer_id = values.installationid;
    }
    if (values.vitecusername && !values.password && values.installationid) {
      inputValue.username = values.vitecusername;
      inputValue.installer_id = values.installationid;
    }
    if (!values.vitecusername && values.password && values.installationid) {
      inputValue.password = values.password;
      inputValue.installer_id = values.installationid;
    }
    if (values.vitecusername && !values.password && !values.installationid) {
      inputValue.username = values.vitecusername;
    }
    if (!values.vitecusername && values.password && !values.installationid) {
      inputValue.password = values.password;
    }
    if (!values.vitecusername && !values.password && values.installationid) {
      inputValue.installer_id = values.installationid;
    }
    addOffice({
      variables: {
        input: inputValue,
      },
    }).then(data => {
      console.log('Data submitted===>>>', data);
      if (data && data.data.add_office) {
        notification.success({
          message: 'Posten ble lagt til',
          description: '',
          duration: 3,
        });
        router.push('/admin/kontorer');
      }
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handleChange = info => {
    getBase64(info.file.originFileObj, image => setimageUrl(image));
    setimageObject(info.file.originFileObj);
  };
  const handleChangeOfficelogo = info => {
    getBase64(info.file.originFileObj, image => setOfficeLogo(image));
    setOfficeLogoObject(info.file.originFileObj);
  };
  const handleChangeBanner1 = info => {
    getBase64(info.file.originFileObj, image => setBanner1(image));
    setBanner1Object(info.file.originFileObj);
  };
  const handleChangeBanner2 = info => {
    // if (info.file.status === 'done') {
    getBase64(info.file.originFileObj, image => setBanner2(image));
    setBanner2Object(info.file.originFileObj);

    // }
  };
  const onCheckboxChange = e => {
    console.log(isAdminOffice);
    console.log('checked:', e.target.checked);
    setisAdminOffice(!isAdminOffice);
  };

  return (
      <div className="text-primary main">
        <Row>
          <Col xs={24}>
            <Mutation mutation={ADMIN_ADD_OFFICE}>
              {(addOffice, { loading }) => (
                  <Form
                      layout="vertical"
                      className="user-forms"
                      form={addOfficeForm}
                      name="officeceo"
                      initialValues={{
                        remember: true,
                      }}
                      colon={false}
                      onFinish={values => onFinish(values, addOffice)}
                      onFinishFailed={onFinishFailed}
                  >
                    <Row>
                      <Col xs={24} md={8} lg={4}>
                        <Form.Item
                            name="officeimage"
                            label={formatMessage({
                              id: 'component.form.officeimage',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.officeimage',
                                })}`,
                              },
                            ]}
                        >
                          <Upload
                              accept={['.jpg', '.png', 'gif']}
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              onChange={handleChange}
                          >
                            {imageUrl ? (
                                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                                uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} lg={4}>
                        <Form.Item
                            name="officelogo"
                            label={formatMessage({
                              id: 'component.form.officelogo',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.officelogo',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Upload
                              accept={['.jpg', '.png', 'gif']}
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              onChange={handleChangeOfficelogo}
                          >
                            {officeLogo ? (
                                <img src={officeLogo} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                                uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="officename"
                            label={formatMessage({
                              id: 'component.table.officename',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.table.officename',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={formatMessage({
                                id: 'component.table.officename',
                              })}
                              size="large"
                              className="pickerwidth"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="companyname"
                            label={formatMessage({
                              id: 'component.table.companyname',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.table.companyname',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.table.companyname',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="orgnumber"
                            label={formatMessage({
                              id: 'component.table.orgnumber',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.table.orgnumber',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.table.orgnumber',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="address"
                            label={formatMessage({
                              id: 'component.form.address',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.address',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={formatMessage({
                                id: 'component.form.address',
                              })}
                              size="large"
                              className="pickerwidth"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="city"
                            label={formatMessage({
                              id: 'component.form.city',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.city',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.form.city',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="zipcode"
                            label={formatMessage({
                              id: 'component.form.zipcode',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.zipcode',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.form.zipcode',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="phone"
                            label={formatMessage({
                              id: 'component.form.phone',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.phone',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={formatMessage({
                                id: 'component.form.phone',
                              })}
                              size="large"
                              className="pickerwidth"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="email"
                            label={formatMessage({
                              id: 'component.form.email',
                            })}
                            rules={[
                              {
                                required: true,
                                message: 'Vennligst oppgi epost',
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.form.email',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="callus"
                            label={formatMessage({
                              id: 'component.form.callus',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.callus',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.form.callus',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="sendemail"
                            label={formatMessage({
                              id: 'component.form.sendemail',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.sendemail',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.email',
                              }).toLowerCase()}`}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="officeurl"
                            label={formatMessage({
                              id: 'component.form.officeurl',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.officeurl',
                                }).toLowerCase()}`,
                              },

                              {
                                whitespace: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.officeurl',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              prefix="https://weiendomsmegling.no/kontorer/"
                              style={{ width: '100%', marginRight: '0px' }}
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.officeurl',
                              }).toLowerCase()}`}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="tawkwidgetcode"
                            label={formatMessage({
                              id: 'component.contentmanagement.towidgetcode',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.contentmanagement.towidgetcode',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input.TextArea
                              rows={3}
                              style={{ width: '100%' }}
                              placeholder="Skriv her"
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col xs={24} md={8} lg={4}>
                        <Form.Item
                            name="bannerimage1"
                            label={formatMessage({
                              id: 'component.form.bannerimage1',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.bannerimage1',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Upload
                              accept={['.jpg', '.png', 'gif']}
                              name="bannerimage1"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              onChange={handleChangeBanner1}
                          >
                            {banner1 ? (
                                <img src={banner1} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                                uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} lg={4}>
                        <Form.Item
                            name="bannerimage2"
                            label={formatMessage({
                              id: 'component.form.bannerimage2',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.bannerimage2',
                                })}`,
                              },
                            ]}
                        >
                          <Upload
                              accept={['.jpg', '.png', 'gif']}
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              onChange={handleChangeBanner2}
                          >
                            {banner2 ? (
                                <img src={banner2} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                                uploadButton
                            )}
                          </Upload>
                          {/* <Upload action={profile} listType="picture-card" fileList={fileList} /> */}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="smallheadline"
                            label={formatMessage({
                              id: 'component.form.smallheadline',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.smallheadline',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.writehere',
                              }).toLowerCase()}`}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="bigheadline"
                            label={formatMessage({
                              id: 'component.form.bigheadline',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.bigheadline',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.writehere',
                              }).toLowerCase()}`}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="headlinetextsection"
                            label={formatMessage({
                              id: 'component.form.headlinetextsection',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst angi  ${formatMessage({
                                  id: 'component.form.headlinetextsection',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input.TextArea
                              rows={3}
                              style={{ width: '100%' }}
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.writehere',
                              }).toLowerCase()}`}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="textsection"
                            label={formatMessage({
                              id: 'component.form.textsection',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst angi  ${formatMessage({
                                  id: 'component.form.textsection',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input.TextArea
                              rows={3}
                              style={{ width: '100%' }}
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.writehere',
                              }).toLowerCase()}`}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="ingress"
                            label={formatMessage({
                              id: 'component.form.ingress',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst angi  ${formatMessage({
                                  id: 'component.form.ingress',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input.TextArea
                              rows={3}
                              style={{ width: '100%' }}
                              placeholder={`${formatMessage({
                                id: 'component.form.writehere',
                              })}`}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={24}>
                        <Form.Item name="isAdmin">
                          <Checkbox onChange={onCheckboxChange}>
                            {formatMessage({
                              id: 'component.form.isadmin',
                            })}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="vitecusername"
                            label={formatMessage({
                              id: 'component.form.vitecusername',
                            })}
                            rules={[
                              {
                                required: false,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.vitecusername',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.vitecusername',
                              }).toLowerCase()}`}
                              size="large"
                              className="pickerwidth"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="password"
                            label={formatMessage({
                              id: 'component.form.password',
                            })}
                            rules={[
                              {
                                required: false,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.password',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.form.password',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="installationid"
                            label={formatMessage({
                              id: 'component.form.installationid',
                            })}
                            rules={[
                              {
                                required: false,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.installationid',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.form.installationid',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Row gutter={[16]}>
                          <Col lg={20} xs={24}>
                            <Row>
                              <Col lg={24}>
                                {departmentList.map(i => (
                                    <div className="total-box" key={Math.random()}>
                                      <div>
                                        <Row className="mb-2">
                                          <Col lg={20}>
                                            <>{i}</>
                                          </Col>
                                          <Col lg={4}>
                                            <>
                                              <i
                                                  className="iconList-delete icon-order-cancel"
                                                  onClick={() => onRemove(i)}
                                              />
                                            </>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                ))}
                              </Col>
                            </Row>
                            <Form.Item
                                name="departmentId"
                                label="Department id"
                                rules={[
                                  {
                                    required: false,
                                    message: `Vennligst skriv inn department id`,
                                  },
                                  {
                                    whitespace: true,
                                    message: `Vennligst skriv inn department id`,
                                  },
                                ]}
                            >
                              <Row>
                                <Col lg={16} xs={24}>
                                  <Input
                                      placeholder="Angi department id"
                                      size="large"
                                      value={departmentIdValue}
                                      onChange={e => setdepartmentIdValue(e.target.value.trim())}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <Button
                                      className="btn-secondary buttons admin-forms-btn"
                                      style={{ marginTop: 8 }}
                                      size="large"
                                      onClick={() => addDepartmentIdList(departmentIdValue)}
                                  >
                                    {formatMessage({
                                      id: 'component.addmore',
                                    })}
                                  </Button>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-secondary buttons admin-forms-btn"
                            size="large"
                            loading={loading}
                        >
                          {formatMessage({
                            id: 'component.form.save',
                          })}
                        </Button>
                        <Button
                            className="text-primary admin-forms-btn"
                            style={{ marginLeft: 8 }}
                            size="large"
                            onClick={()=>router.goBack()}
                        >
                          {formatMessage({
                            id: 'component.form.cancel',
                          })}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
              )}
            </Mutation>
          </Col>
        </Row>
        {/* </div> */}
      </div>
  );
};
export default AddOfficesDetails;
