export default {
  'component.tablesearch.placeholder':
    'Søk etter oppdrag nr., selgernavn, e-post, telefon og eiendomsadresse',
  'component.tablesearch.stylist.placeholder':
    'Søk etter navn, e-post, telefon, eiendomsadresse og postnummer',
  'component.orderlist': 'Bestillingsoversikt',
  'component.note': 'Merk:',
  'component.currency': 'Kr',
  'component.month': 'Måned',
  'component.quarter': 'Kvartal',
  'component.year': 'År',
  'component.delete': 'Slett',
  'component.signature.placeholder':
    'Søk etter oppdragsnr., addresse eller navn på selger',
  'component.keysales': 'Viktige salgspoeng',
  'component.recommendedvalue': 'Anbefalte verdiøkende oppgraderinger',
  'component.home': 'Hjem',
  'component.nodata':'Ingen data',
  'component.nophotoupload': 'Ingen bilder lastet opp',
  'component.orderaccepted': 'Bestill godkjent!',
  'component.addmore': 'Legg til mer',
  'component.order.status.ordered': 'Bestilt',
  'component.order.status.timebooked': 'Bekreftet',
  'component.order.status.infoadded': 'Info lagt til',
  'component.order.status.phototaken': 'Foto gjennomført',
  'component.order.status.photouploaded': 'Bilder levert',
  'component.order.status.projectcompleted': 'Oppdrag utført',
};
