import React from 'react'
//Component
import {Dashboard} from "components/src/components";
//Utils
import {IS_WEB, Routes, Strings} from 'components/src/utils'

import appointmentInfo from 'components/src/image/SalesInformation.png'
import purchasingContract from 'components/src/image/SettlementInformation.png'
import documents from 'components/src/image/Documents.png'
import financing from 'components/src/image/Rate.jpg'
import insurance from 'components/src/image/Insurance.png'
import movingGuide from 'components/src/image/MovingGuide.jpg'
import energy from 'components/src/image/energy_labeling.png'
import upgrades from 'components/src/image/Upgrades in the Property.png'
import contractInfo from 'components/src/image/ContractInformation.png'


const DASHBOARD = [{
    name: Strings.appointmentInfo,
    icon: 'sales-information',
    bg: appointmentInfo,
    route:Routes.AppointmentInfo
}, {
    name: Strings.contractInformation,
    icon: 'contract-information',
    bg: contractInfo,
    route:Routes.ContractInfo
}, {
    name: Strings.purchasingContract,
    icon: 'settlement-information',
    bg: purchasingContract,
    // disabled:true,
    route:Routes.BuyerPurchasingContract
}, {
    name: Strings.documents,
    icon: 'documents',
    bg: documents,
    route:Routes.BuyerDocument

}, {
    name: Strings.FinancingTitle,
    icon: 'rate',
    bg: financing,
    route:Routes.BuyerFinancing
}, {
    name: Strings.insurance,
    icon: 'offers-on-insurance',
    bg: insurance,
    route:Routes.BuyerInsurance
}, {
    name: Strings.movingGuide,
    icon: 'moving-guide',
    bg: movingGuide,
    route:Routes.BuyerMovingGuide
}/*, {
    name: Strings.energy,
    icon: 'energy-labeling',
    bg: energy,
}, {
    name: Strings.propertyUpgrades,
    icon: 'upgrades-in-property',
    bg: upgrades,
}*/];

function BuyerHome(props) {
    const {navigation, history} = props;
    const handleItemPress = (name,route) => {
        if (route) {
            if (IS_WEB) {
                history.push(route)
            } else {
                navigation.navigate(route)
            }
        }
    };
    return (
        <Dashboard dashboardData={DASHBOARD}
                   onPressItem={handleItemPress}/>
    )
}


export default BuyerHome;
