import {Button, Col, Form, Input, Layout, Menu, Modal, notification, Row} from 'antd';
import React, {PureComponent} from 'react';
import {Mutation} from 'react-apollo';
import {Link} from 'react-router-dom';
import {DS_FINANCING} from '../../../../../mutation/AgentMutation';
import './userheader.scss';
import {formatMessage} from "components/src/utils";
import {getWmeglingBlackLogo, getWmeglingInnerLogo} from "../../../../utils/utils";

const {Header} = Layout;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const {SubMenu} = Menu;


class UserHeader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            current: '',
            isTop: true,
            hovered: false,
            isToggleOn: false,
            isFinancing: false,
        };
        this.logoRef = React.createRef();
        this.whiteLogoRef = React.createRef();
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 100;
            if (isTop !== this.state.isTop) {
                // this.setState({ isTop })
                this.addScrollClass();
            } else {
                this.removeScrollClass();
            }
        });
    }

    showFinanceModal = () => {
        this.setState({
            isFinancing: true,
        });
    };

    handleFinanceOk = () => {
        setTimeout(() => {
            this.setState({isFinancing: false});
        }, 3000);
    };

    handleFinanceCancel = () => {
        this.setState({isFinancing: false});
    };

    onFinanceFinish = (values, sendFinancingEmail) => {
        sendFinancingEmail({
            variables: {
                telefon: values.telefon,
                epost: values.epost
            }
        }).then(({data}) => {
            if (data) {
                if (data && data.send_financing_email && data.send_financing_email.meta) {
                    this.setState({isFinancing: false});
                    notification.success({
                        message: data.send_financing_email.meta.message,
                        description: '',
                        duration: 3,
                    });
                }
            }
        });
    };

    onFinanceFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    addScrollClass = () => {
        // this.setState({ isTop: false })
        document.body.classList.add('down');
    };

    removeScrollClass = () => {
        // this.setState({ isTop: true })
        document.body.classList.remove('down');
    };

    toggleHover = () => {
        const {hovered} = this.state;
        this.setState({hovered: !hovered});
    };

    handleMenuClick() {
        const {isToggleOn} = this.state;
        this.setState({
            isToggleOn: !isToggleOn,
        });
    }

    render() {
        const {hovered, isTop, current, isToggleOn, isFinancing} = this.state;
        return (
            <>
                <Header className={hovered ? 'app-header navHoverBG' : 'app-header'}>
                    <div className={isTop ? '' : 'bg-primary'}>
                        <div
                            className={hovered ? 'site-header__backdrop hasbgHover' : 'site-header__backdrop'}
                        />
                        <Row gutter={32} type="flex" align="middle">
                            <Col xs={0} lg={2} xl={4}>
                                <a href="https://weiendomsmegling.no/" className="brand-name">
                                    <img
                                        ref={this.logoRef}
                                        src={getWmeglingInnerLogo()}
                                        className="fluid logo"
                                        alt="W Eiendomsmegling"
                                        onClick={() => this.setState({current: '/'})}
                                    />
                                </a>
                            </Col>
                            <Col xs={0} lg={22} xl={20} xxl={20}>
                                <Menu
                                    selectedKeys={[current]}
                                    onClick={e => {
                                        this.setState({current: e.key});
                                    }}
                                    mode="horizontal"
                                    className="right-menu-bar"
                                >
                                    <SubMenu
                                        title={formatMessage({
                                            id: 'component.userHeader.selling-housing',
                                        })}
                                        className="user-header-submenu"
                                    >
                                        <Menu.ItemGroup title="selling-house" className="user-header-special-submenus">
                                            <Menu.Item key="selge">
                                                <a href="https://weiendomsmegling.no/selge" className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.sell',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="verdivurdering">
                                                <Link to="#" className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.sell-valuation',
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="markedsføring">
                                                <a
                                                    href="https://weiendomsmegling.no/markedsføring"
                                                    className="text-uppercase"
                                                >
                                                    {formatMessage({
                                                        id: 'component.userHeader.marketing',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="teknologi">
                                                <a href="https://weiendomsmegling.no/teknologi"
                                                   className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.technology',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="finansiering">
                                                <Link to="#" className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.finance',
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="eiendomsoppgjør">
                                                <a href="https://weiendomsmegling.no/oppgjor"
                                                   className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.property-settlement',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="usolgt-bolig">
                                                <a
                                                    href="https://weiendomsmegling.no/usolgtbolig"
                                                    className="text-uppercase"
                                                >
                                                    {formatMessage({
                                                        id: 'component.userHeader.unsold-housing',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </SubMenu>
                                    <Menu.Item key="/kontorer">
                                        <a href="https://weiendomsmegling.no/kontorer" className="text-uppercase">
                                            {formatMessage({
                                                id: 'component.userHeader.offices',
                                            })}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="/properties">
                                        <a href="https://weiendomsmegling.no/eiendommer" className="text-uppercase">
                                            {formatMessage({
                                                id: 'component.userHeader.properties',
                                            })}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="/finansiering">
                                        <Link onClick={this.showFinanceModal} className="text-uppercase">
                                            {formatMessage({
                                                id: 'component.userHeader.finance',
                                            })}
                                        </Link>
                                    </Menu.Item>
                                    <SubMenu
                                        title={formatMessage({
                                            id: 'component.userHeader.for-megler',
                                        })}
                                        className="user-header-submenu"
                                    >
                                        <Menu.ItemGroup title="for-megler" className="user-header-special-submenus">
                                            <Menu.Item key="formeglere">
                                                <a href="https://weiendomsmegling.no/formeglere"
                                                   className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.concept',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="w-flex">
                                                <a
                                                    to="http://flex.weiendomsmegling.no/"
                                                    target="_blank"
                                                    className="text-uppercase"
                                                >
                                                    {formatMessage({
                                                        id: 'component.userHeader.flex',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="karriere">
                                                <a href="https://weiendomsmegling.no/karriere"
                                                   className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.career',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </SubMenu>
                                    <Menu.Item key="/kontakt">
                                        <a href="https://weiendomsmegling.no/kontakt" className="text-uppercase">
                                            {formatMessage({
                                                id: 'component.userHeader.contact',
                                            })}
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            </Col>
                        </Row>
                    </div>
                </Header>
                <Header className={hovered ? 'app-header responsive' : 'app-header responsive'}>
                    <Row gutter={32} type="flex" align="middle">
                        <Col xs={12} lg={0}>
                            <a href="https://weiendomsmegling.no/" className="brand-name">
                                <img
                                    ref={this.logoRef}
                                    src={getWmeglingInnerLogo()}
                                    className="fluid logo"
                                    alt="W Eiendomsmegling"
                                    onClick={() => this.setState({current: '/'})}
                                    style={{marginLeft: '20px'}}
                                />
                            </a>
                        </Col>
                        <Col xs={12} lg={0}>
                            <div className="navigation-header position-relative" style={{padding: '0px 20px'}}>
                                <Link onClick={() => this.handleMenuClick()}>
                                    <div className="navbtn"/>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <div className={`navbar-ds-collapse ${isToggleOn ? 'd-block' : 'd-none'}`}>
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    defaultOpenKeys={['sub1']}
                                    mode="inline"
                                    inlineCollapsed={this.state.collapsed}
                                >
                                    <SubMenu
                                        title={formatMessage({
                                            id: 'component.userHeader.selling-housing',
                                        })}
                                        className="user-header-submenu"
                                    >
                                        <Menu.ItemGroup title="selling-house" className="user-header-special-submenus">
                                            <Menu.Item key="selge">
                                                <a href="https://weiendomsmegling.no/selge" className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.sell',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="verdivurdering">
                                                <Link to="#" className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.sell-valuation',
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="markedsføring">
                                                <a
                                                    href="https://weiendomsmegling.no/markedsføring"
                                                    className="text-uppercase"
                                                >
                                                    {formatMessage({
                                                        id: 'component.userHeader.marketing',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="teknologi">
                                                <a href="https://weiendomsmegling.no/teknologi"
                                                   className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.technology',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="finansiering">
                                                <Link to="#" className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.finance',
                                                    })}
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="eiendomsoppgjør">
                                                <a href="https://weiendomsmegling.no/oppgjor"
                                                   className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.property-settlement',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="usolgt-bolig">
                                                <a
                                                    href="https://weiendomsmegling.no/usolgtbolig"
                                                    className="text-uppercase"
                                                >
                                                    {formatMessage({
                                                        id: 'component.userHeader.unsold-housing',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </SubMenu>
                                    <Menu.Item key="/kontorer">
                                        <a href="https://weiendomsmegling.no/kontorer" className="text-uppercase">
                                            {formatMessage({
                                                id: 'component.userHeader.offices',
                                            })}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="/properties">
                                        <a href="https://weiendomsmegling.no/eiendommer" className="text-uppercase">
                                            {formatMessage({
                                                id: 'component.userHeader.properties',
                                            })}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="/finansiering">
                                        <Link onClick={this.showFinanceModal} className="text-uppercase">
                                            {formatMessage({
                                                id: 'component.userHeader.finance',
                                            })}
                                        </Link>
                                    </Menu.Item>
                                    <SubMenu
                                        title={formatMessage({
                                            id: 'component.userHeader.for-megler',
                                        })}
                                        className="user-header-submenu"
                                    >
                                        <Menu.ItemGroup title="for-megler" className="user-header-special-submenus">
                                            <Menu.Item key="formeglere">
                                                <a href="https://weiendomsmegling.no/formeglere"
                                                   className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.concept',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="w-flex">
                                                <a
                                                    to="http://flex.weiendomsmegling.no/"
                                                    target="_blank"
                                                    className="text-uppercase"
                                                >
                                                    {formatMessage({
                                                        id: 'component.userHeader.flex',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                            <Menu.Item key="karriere">
                                                <a href="https://weiendomsmegling.no/karriere"
                                                   className="text-uppercase">
                                                    {formatMessage({
                                                        id: 'component.userHeader.career',
                                                    })}
                                                </a>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </SubMenu>
                                    <Menu.Item key="/kontakt">
                                        <a href="https://weiendomsmegling.no/kontakt" className="text-uppercase">
                                            {formatMessage({
                                                id: 'component.userHeader.contact',
                                            })}
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            </div>
                        </Col>
                    </Row>
                </Header>
                {/* financing modal  */}

                <Modal
                    visible={isFinancing}
                    className="finance-modal"
                    title={
                        <>
                            <div className="text-center">
                                <img src={getWmeglingBlackLogo()} alt="logo"/>
                                <span>VERDIVURDERING</span>
                                <p>LEGG IGJEN DIN KONTAKTINFORMASJON SÅ TAR VI KONTAKT FOR Å AVTALE EN
                                    VERDIVURDERING.</p>
                            </div>
                        </>
                    }
                    onOk={this.handleFinanceOk}
                    onCancel={this.handleFinanceCancel}
                    footer={null}
                >
                    <Mutation
                        mutation={DS_FINANCING}
                    >
                        {(sendFinancingEmail, {loading}) => (
                            <Form
                                name="finance-form"
                                initialValues={{remember: true}}
                                onFinish={values => this.onFinanceFinish(values, sendFinancingEmail)}
                                onFinishFailed={this.onFinanceFinishFailed}
                            >
                                <Form.Item
                                    name="telefon"
                                >
                                    <Input placeholder={formatMessage({id: 'component.form.telephone'})} size="large"/>
                                </Form.Item>
                                <Form.Item
                                    name="epost"
                                >
                                    <Input placeholder={formatMessage({id: 'component.form.email'})} size="large"/>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                        key="submit"
                                        type="primary"
                                        className="btn-secondary buttons btn-send admin-forms-btn"
                                        size="large"
                                        loading={loading}
                                    >
                                        {formatMessage({id: 'component.form.send'})}
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </Mutation>
                </Modal>
            </>
        );
    }
}

export default UserHeader;
