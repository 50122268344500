import React from 'react'
import withDrawer from "components/src/HOC/Drawer";
import {Routes, Strings} from "components/src/utils";
import OrderList from './OrderedList';
import Profile from './MyProfile';
import ChatList from "../Chat/ChatList";

const TABS = [{
    id: 1,
    name: Strings.home,
    icon: 'home',
    route: Routes.Dashboard,
    screen: OrderList
}/*, {
    id: 2,
    name: Strings.chat,
    icon: 'chat',
    route: Routes.Chat,
    screen:ChatList
}*/, {
    id: 4,
    name: Strings.profile,
    icon: 'profile',
    route: Routes.EditProfile,
    screen: Profile
}];


const DRAWER_ITEMS = [{
    name: Strings.dashBoard,
    icon: 'dashboard',
    route: Routes.Dashboard,
}/*, {
    name: Strings.calendar,
    icon: 'calendar',
    route:Routes.Calendar
}*/, {
    name: Strings.logout,
    icon: 'logout',
    route: Routes.SignIn,
}];


const getParams = (props) => {
    return {
        parentRoute:Routes.Stylist,
        tabs: TABS,
        drawerItems: DRAWER_ITEMS
    }
};
export default withDrawer(getParams)
