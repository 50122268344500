import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {Header, Hr, Label} from "components/src/components";
//Third party
//Network
import {Query} from "@apollo/react-components";
import {SELLER_SALES_INFO} from "components/src/api/sellerQuery";
//redux
import {connect} from "react-redux";
//Utils
import {
    Color,
    CommonStyle,
    DateUtils,
    formatPrice,
    Icon,
    IS_WEB,
    MessageUtils,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';
import moment from "moment";


class SalesInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            salesInfo: {},
            detailsone: [
                {
                    index: 1,
                    firstName: 'Frank',
                    lastname: 'Johannessen',
                    address: 'Brakestien 5',
                    city: 'Agder',
                    zipcode: '4760',
                    phone: '455-556-91',
                    email: 'frajoh73@email.com'
                },
            ],
            appointment: [
                {
                    index: 1,
                    purchaseprice: 'Kr 34.56.789, -',
                    takeoverdate: '03-04-2020'
                }
            ]
        }
        if (!props.property?.property_buyer_info) {
            IS_WEB ? props.history.goBack() : props.navigation.goBack();
        }
        this.scrollY = new Animated.Value(0)
    }

    renderDetails = (data, index) => {

        return (
            <View style={{marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN}}>
                {index!==undefined ? (
                    <Label
                        mt={15}
                        small
                        font_medium
                        align={'left'}>
                        {`Kjøpers ${index + 2} detaljer`}
                    </Label>) : null}
                <Label small
                       font_medium
                       mt={20}
                       mb={8}
                       align={'left'}>{Strings.firstName}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.first_name ?? "-"}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.lastName}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.last_name ?? "-"}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Address}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.address ?? "-"}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.City}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.city ?? "-"}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.zipcode}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.zipcode ?? "-"}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Phone}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.phone ?? "-"}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Email}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.email ?? "-"}</Label>
            </View>
        );
    };

    Appoinmentinfo = (data) => {

        return (
            <View>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.PurchasePrice}</Label>
                <Label small
                       font_regular
                       mb={20}
                       align={'left'}>
                    {data?.property_sales_info?.property?.vitecProperty?.vitecPropertyEstatePrice?.priceSuggestion ? `Kr ${formatPrice(data?.property_sales_info?.property?.vitecProperty?.vitecPropertyEstatePrice?.priceSuggestion ?? 0)},-` : "-"}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>
                    {Strings.TakeoverDate}
                </Label>
                <Label small
                       font_regular
                       mb={20}
                       align={'left'}>
                    {data?.property_sales_info?.property?.vitecProperty?.takeOverDate ? moment(data?.property_sales_info?.property?.vitecProperty?.takeOverDate, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy) : "-"}
                </Label>
            </View>
        );
    };

    render() {
        return (
            <View style={CommonStyle.container}>


                <Header animatedTitle={Strings.salesinformation}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>
                    <Query query={SELLER_SALES_INFO}
                           onCompleted={(data) => {
                               if (data?.property_sales_info) {
                                   this.setState({
                                       salesInfo: data?.property_sales_info
                                   })
                               }
                           }}
                           variables={{
                               propertyId: this.props?.property?.id,
                           }}>
                        {({loading, data, error}) => {
                            return (
                                <>
                                    <View style={styles.container}>
                                        <View style={styles.topContainer}>
                                            <View style={styles.titleContainer}>
                                                <Icon
                                                    size={ThemeUtils.fontXXLarge}
                                                    name={'sales-information'}
                                                    color={Color.PRIMARY_DARK}/>

                                            </View>
                                            <Label mt={15}
                                                   font_regular
                                                   small
                                                   align={'left'}>{Strings.salesintroduction}</Label>

                                        </View>
                                        {loading && !data ? (
                                                <ActivityIndicator color={Color.PRIMARY}
                                                                   style={CommonStyle.loader}/>
                                            ) :
                                            data?.property_sales_info ? (
                                                <>

                                                    <View>
                                                        <Label
                                                            mt={25}
                                                            ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                            small
                                                            font_medium
                                                            align={'left'}>
                                                            {`Kjøpers 1 detaljer`}
                                                        </Label>
                                                        {this.renderDetails(data?.property_sales_info)}
                                                    </View>
                                                    <Hr/>
                                                    <View>
                                                        {data?.property_sales_info?.coBuyerInfo?.map(this.renderDetails)}
                                                    </View>
                                                    <Hr/>
                                                    <View style={styles.topContainer}>
                                                        <View style={styles.titleContainers}>

                                                            <Label small
                                                                   mt={10}
                                                                   mb={10}
                                                                   font_medium
                                                                   align={'left'}>
                                                                {Strings.appointmentDetails}
                                                            </Label>
                                                        </View>
                                                        <View>{this.Appoinmentinfo(data)}</View>
                                                    </View>
                                                    {data?.property_sales_info?.comment ? <Hr/> : null}
                                                    {data?.property_sales_info?.comment ? (
                                                        <View style={styles.topContainer}>
                                                            <Label small
                                                                   font_medium
                                                                   mt={15}
                                                                   mb={10}>
                                                                {`${Strings.reservation} / ${Strings.comment}`}
                                                            </Label>
                                                            <Label small
                                                                   mb={15}>
                                                                {data?.property_sales_info?.comment}
                                                            </Label>

                                                        </View>) : null}
                                                </>
                                            ) : (
                                                <Label font_medium
                                                       align={'center'}
                                                       mt={20}>
                                                    {MessageUtils.Message.noSalesInfo}
                                                </Label>
                                            )}

                                    </View>
                                </>
                            )
                        }}
                    </Query>
                </Animated.ScrollView>


            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        property: state.property
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesInformation);
