import React from 'react'
import {Image} from 'react-native'
import styles from "./styles";
import Logo from 'components/src/image/W_Logo_Only.png'

export default function LogoForHeader(props) {
    let {size} = props;
    return (
        <Image source={Logo}
               resizeMode={'cover'}
               style={[styles.logo, size ? {
                   height: size * 0.70,
                   width: size,
               } : null]}/>
    )
}
