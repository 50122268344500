import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {Header, Hr, Label, Ripple} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    Icon,
    IS_WEB,
    openInNewTab,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';
import {useQuery} from "@apollo/react-hooks";
import {SELLER_DOCUMENT} from "components/src/api/sellerQuery";
import moment from 'moment-timezone';

function Documents(props) {
    const {property, history, navigation} = props;
    const scrollY = new Animated.Value(0)

    const {loading, data, error} = useQuery(SELLER_DOCUMENT, {
        variables: {
            id: property?.id,
        }
    })

    const renderList = () => {
        if (loading && !data) {
            return (<ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>)
        }
        if (!data?.seller_document?.length) {
            return (
                <Label mt={10}
                       small
                       align={'center'}
                       font_medium>
                    {"Det er ikke lastet opp noen dokumenter enda."}
                </Label>
            )
        }
        return data?.seller_document?.map((item, index) => {
            return (
                <Ripple onPress={() => openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item?.documentUrl}`)}>
                    <Hr/>
                    <View style={styles.topContainer}>
                        <View style={styles.documentcontainer}>

                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'marketing-material-print'}
                                color={Color.PRIMARY_DARK}/>

                            <View>
                                <Label small
                                       ml={9}
                                       font_medium
                                       align={'left'}>{item.head}</Label>
                                <Label small
                                       ml={9}
                                       mt={5}
                                       align={'left'}>

                                    {moment(item.lastChanged).format(DateUtils.dd_mm_yyyy_hh_mm)}
                                </Label>
                            </View>
                            <View style={styles.iconView}>
                                <Icon
                                    size={ThemeUtils.fontLarge}
                                    name={'drop-normal'}
                                    color={Color.ICON_BUTTON_ARROW}/>

                            </View>
                        </View>

                    </View>
                    <Hr/>
                </Ripple>
            );
        });
    };

    return (
        <View style={CommonStyle.container}>
            <Header animatedTitle={Strings.documents}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.labeliconContainer}>
                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'documents'}
                                color={Color.PRIMARY_DARK}/>

                        </View>

                        <Label
                            mt={10}
                            mb={20}
                            font_regular
                            small
                            align={'left'}>
                            {Strings.infoBuyerDocument}
                        </Label>


                    </View>
                    <View>{renderList()}</View>

                </View>
            </Animated.ScrollView>
        </View>
    );


}


const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Documents);




