import {CaretDownFilled, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, notification, Row, Select, Typography, Upload} from 'antd';
import React, {useState} from 'react';
import {Mutation, Query} from 'react-apollo';


import {ADMIN_CREATE_VENORS_USER} from '../../../../../../mutation/AdminMutation';
import {ADMIN_OFFICES_LIST_ONLY, ADMIN_ZIPCODE_LIST} from '../../../../../../query/AdminQuery';
import {USER_TYPE, USER_TYPE_SERVER} from '../../../../../utils/constant';
import './addphotographer.scss';
import {formatMessage} from "components/src/utils";
import {useHistory} from "react-router";

const {Title} = Typography;


const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
};
const uploadButton = (
    <div>
        <PlusOutlined className="uploadplus"/>
        <div className="ant-upload-text label-texts">
            {formatMessage({
                id: 'component.form.upload',
            })}
        </div>
    </div>
);

const AddPhotographer = () => {
    let router = useHistory();
    const options = [];
    // const optionsPoststed = [];
    const [optionsPoststed, setOptionsPoststed] = useState([]);
    const [imageUrl, setimageUrl] = useState('');

    const [officeList, setOfficeList] = useState([]);


    const onFinish = (values, addPhotoUser) => {
        console.log('Success:', values);
        addPhotoUser({
            variables: {
                user_profile: values?.profilepicture?.file?.originFileObj,
                first_name: values.firstname,
                last_name: values.lastname,
                email: values.email,
                mobile: values.phone,
                city: values.city,
                address: values.address,
                company_name: values.companyName,
                zipcode: parseInt(values.zipcode, 10),
                office_ids: values.office,
                user_type: USER_TYPE.PHOTO,
            },
        }).then(data => {
            console.log('Data submitted===>>>', data);
            if (data && data.data.add_vendor && data.data.add_vendor.meta) {
                notification.success(
                    {
                        message: data.data.add_vendor.meta.message,
                        description: '',
                        duration: 3,
                    },
                    router.push(`/admin/fotografer`),
                );
            }
        });
    };

    function onChange(value) {
        console.log(`selected ${value}`);
    }

    function onBlur() {
        console.log('blur');
    }

    function onFocus() {
        console.log('focus');
    }

    function onSearch(val) {
        console.log('search:', val);
    }

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = info => {
        getBase64(info.file.originFileObj, image => setimageUrl(image));
    };

    return (
        <div className="contentshadow">
            <div className="text-primary main">
                <Row>
                    <Col xs={24}>
                        <Title level={3} className="text-primary">
                            {formatMessage({
                                id: 'component.photographer.addphotographer',
                            })}
                        </Title>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Mutation mutation={ADMIN_CREATE_VENORS_USER}>
                            {(addPhotoUser, {loading}) => (
                                <Form
                                    layout="vertical"
                                    className="user-forms"
                                    name="editprofile"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    colon={false}
                                    onFinish={values => onFinish(values, addPhotoUser)}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="profilepicture"
                                                label={formatMessage({
                                                    id: 'component.form.profilepicture',
                                                })}
                                                /*rules={[
                                                  {
                                                    required: true,
                                                    message: 'Vennligst skriv inn profilbilde',
                                                  },
                                                ]}*/
                                            >
                                                <Upload
                                                    name="avatar"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    onChange={handleChange}
                                                >
                                                    {imageUrl ? (
                                                        <img src={imageUrl} alt="avatar" style={{width: '100%'}}/>
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={16}>
                                            <Form.Item
                                                name="companyName"
                                                label="Selskapsnavn"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst skriv inn selskapsnavn`,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{width: '100%'}}
                                                    placeholder={`Tast inn selskapsnavn`}
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={8}>
                                            <Form.Item
                                                name="firstname"
                                                label={formatMessage({
                                                    id: 'component.form.firstname',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst skriv inn ${formatMessage({
                                                            id: 'component.form.firstname',
                                                        }).toLowerCase()}`,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{width: '100%'}}
                                                    placeholder={`Tast inn ${formatMessage({
                                                        id: 'component.form.firstname',
                                                    }).toLowerCase()}`}
                                                    size="large"
                                                    className="pickerwidth"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item
                                                name="lastname"
                                                label={formatMessage({
                                                    id: 'component.form.lastname',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vennligst skriv inn etternavn',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={`Tast inn ${formatMessage({
                                                        id: 'component.form.lastname',
                                                    }).toLowerCase()}`}
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={8}>
                                            <Form.Item
                                                name="email"
                                                label={formatMessage({
                                                    id: 'component.form.email',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vennligst oppgi epost',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{width: '100%'}}
                                                    placeholder={`Tast inn ${formatMessage({
                                                        id: 'component.form.email',
                                                    }).toLowerCase()}`}
                                                    size="large"
                                                    className="pickerwidth"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item
                                                name="phone"
                                                label={formatMessage({
                                                    id: 'component.form.phone',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst skriv inn ${formatMessage({
                                                            id: 'component.form.phone',
                                                        }).toLowerCase()}`,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={`Tast inn ${formatMessage({
                                                        id: 'component.form.phone',
                                                    }).toLowerCase()}`}
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} lg={16}>
                                            <Form.Item
                                                name="address"
                                                label={formatMessage({
                                                    id: 'component.form.address',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst skriv inn ${formatMessage({
                                                            id: 'component.form.address',
                                                        }).toLowerCase()}`,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{width: '100%'}}
                                                    placeholder={`Tast inn ${formatMessage({
                                                        id: 'component.form.address',
                                                    }).toLowerCase()}`}
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={8}>
                                        <Col xs={24} lg={16}>
                                            <Query
                                                query={ADMIN_ZIPCODE_LIST}
                                                variables={{
                                                    status: 1,
                                                    user_type_id: USER_TYPE_SERVER.PHOTO,
                                                    user_id: 1,
                                                }}
                                                onCompleted={responseData => {
                                                    setOptionsPoststed([
                                                        ...new Map(
                                                            responseData.zipcode_list.map(o => [o.poststed, o]),
                                                        ).values(),
                                                    ]);
                                                }}
                                            >
                                                {({data}) => {
                                                    if (data && data.zipcode_list) {
                                                        // data.zipcode_list.map(i => optionsPoststed.push(i.poststed));
                                                        console.log('poststed:', optionsPoststed);
                                                        return (
                                                            <>
                                                                <Form.Item
                                                                    name="city"
                                                                    label={formatMessage({
                                                                        id: 'component.table.city',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: `Vennligst fyll inn ${formatMessage({
                                                                                id: 'component.table.city',
                                                                            }).toLowerCase()}`,
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        // mode="multiple"
                                                                        placeholder={`Velg ${formatMessage({
                                                                            id: 'component.table.city',
                                                                        }).toLowerCase()}`}
                                                                        size="large"
                                                                        style={{width: '100%'}}
                                                                        suffixIcon={<CaretDownFilled/>}
                                                                        filterOption={(input, option) =>
                                                                            option?.children
                                                                                ?.toLowerCase()
                                                                                ?.indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {optionsPoststed.map(i => (
                                                                            <Select.Option
                                                                                value={i.poststed}>{i.poststed}</Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </>
                                                        );
                                                    }
                                                    return (
                                                        <div className="loader-wrapper-inline">
                                                            <LoadingOutlined/>
                                                        </div>
                                                    );
                                                }}
                                            </Query>
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col xs={24} lg={24}>
                                            <Form.Item
                                                name="zipcode"
                                                label={formatMessage({
                                                    id: 'component.form.zipcode',
                                                })}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Vennligst oppgi ${formatMessage({
                                                            id: 'component.form.zipcode',
                                                        }).toLowerCase()}`,
                                                    },
                                                    {
                                                        pattern: /^(0|-*[1-9]+[0-9]*)$/,
                                                        message: `Vennligst oppgi ${formatMessage({
                                                            id: 'component.form.zipcode',
                                                        }).toLowerCase()}`,
                                                    },
                                                ]}
                                            >
                                                <InputNumber style={{width: '100%'}}
                                                             placeholder={`Tast inn ${formatMessage({
                                                                 id: 'component.form.zipcode',
                                                             }).toLowerCase()}`}
                                                             size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16]}>
                                        <Col xs={24} md={24} lg={16}>
                                            <Query
                                                query={ADMIN_OFFICES_LIST_ONLY}
                                                variables={{
                                                    status: true,
                                                }}
                                            >
                                                {({data}) => {
                                                    // console.log('user-list', data.users_list);
                                                    if (data && data.offices_list) {
                                                        return (
                                                            <Form.Item
                                                                name="office"
                                                                label={`${formatMessage({
                                                                    id: 'component.form.office',
                                                                }).toLowerCase()}`}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: `Velg ${formatMessage({
                                                                            id: 'component.form.office',
                                                                        }).toLowerCase()}`,
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    mode="multiple"
                                                                    style={{width: '100%'}}
                                                                    placeholder={`Velg ${formatMessage({
                                                                        id: 'component.form.office',
                                                                    }).toLowerCase()}`}
                                                                    size="large"
                                                                    suffixIcon={<CaretDownFilled
                                                                        className="caret-style"/>}
                                                                    className="pickerwidth"
                                                                >
                                                                    {data.offices_list.map(i => (
                                                                        <Select.Option value={i.id}>
                                                                            <span>{i.name}</span>
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        );
                                                    }
                                                    return (
                                                        <div className="loader-wrapper-inline">
                                                            <LoadingOutlined/>
                                                        </div>
                                                    );
                                                }}
                                            </Query>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={24}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="btn-secondary buttons admin-forms-btn"
                                                size="large"
                                                loading={loading}
                                            >
                                                {formatMessage({
                                                    id: 'component.form.save',
                                                })}
                                            </Button>
                                            <Button
                                                className="text-primary admin-forms-btn"
                                                style={{marginLeft: 8}}
                                                size="large"
                                                onClick={router.goBack}
                                            >
                                                {formatMessage({
                                                    id: 'component.form.cancel',
                                                })}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Mutation>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default AddPhotographer;
