/* eslint-disable no-shadow */
import { CaretDownFilled, LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, notification, Row, Select, Upload } from 'antd';
import BraftEditor from 'braft-editor';
import React, { useEffect, useState } from 'react';
import { useMutation,Mutation, Query } from 'react-apollo';
import {useHistory} from "react-router";


import {
  ADMIN_ADD_AGENT_DETAILS,
  ADMIN_DELETE_AGENT_CV,
  ADMIN_DELETE_AGENT_DOC,
} from '../../../../../../../../../../mutation/AdminMutation';
import { ADMIN_OFFICES_LIST_ONLY } from '../../../../../../../../../../query/AdminQuery';
import { STORAGE_URL } from '../../../../../../../../../utils/constant';
import {formatMessage} from "components/src/utils";

const { TextArea } = Input;

const props = {
  showUploadList: {
    showDownloadIcon: false,
    // downloadIcon: <DownloadOutlined />,
    showRemoveIcon: true,
    removeIcon: <i className="icon-delete" />,
  },
};

const docprops = {
  showUploadList: {
    showDownloadIcon: false,
    // downloadIcon: <DownloadOutlined />,
    showRemoveIcon: true,
    removeIcon: <i className="icon-delete" />,
  },
};

const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">
        {formatMessage({
          id: 'component.form.upload',
        })}
      </div>
    </div>
);

const AgentDetailAddForm = () => {
  let router=useHistory();
  const [imageUrl, setimageUrl] = useState('');
  const [imageObject, setimageObject] = useState();
  const [displayCv, setDisplayCv] = useState([]);
  const [displayOtherDoc, setDisplayOtherDoc] = useState([]);
  const [deleteCV, deleteCVResponse] = useMutation(ADMIN_DELETE_AGENT_CV);
  const [deleteDocument, deleteDocumentResponse] = useMutation(ADMIN_DELETE_AGENT_DOC);
  const [isAdminOffice, setisAdminOffice] = useState(false);

  const [editorState, seteditorState] = useState(null);

  const onFinish = (values, addAgentDetailsData) => {
    console.log('Success:', values);

    const otherDocFileList = [];
    if (displayOtherDoc && displayOtherDoc.length > 0) {
      displayOtherDoc.forEach(item => {
        if (item.originFileObj) {
          otherDocFileList.push(item.originFileObj);
        }
      });
    }


    const variables = {
      profile_image: imageObject,
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      title: values.title,
      office_id: values.office,
      is_in_admin_office: isAdminOffice,
    };

    if (values.phone) {
      variables.mobile = values.phone;
    }

    if (values.education) {
      variables.education = values.education;
    }

    if (values.summary) {
      variables.about = editorState.toHTML();
    }

    if (values.uploadcv) {
      variables.cv = displayCv && displayCv.length > 0 && displayCv[0].originFileObj;
    }

    if (values.uploadotherdoc) {
      variables.other_document = otherDocFileList;
    }

    addAgentDetailsData({
      variables,

    }).then(data => {
      console.log('Data submitted===>>>', data);
      if (data && data.data.add_agent) {
        notification.success({
          message: `Posten ble lagt til`,
          description: '',
          duration: 3,
        });
        router.push(`/admin/allemeglere`);
      }
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handleChange = info => {
    getBase64(info.file.originFileObj, image => setimageUrl(image));
    setimageObject(info.file.originFileObj);
  };

  const onCheckboxChange = e => {
    console.log(isAdminOffice);
    console.log('checked:', e.target.checked);
    setisAdminOffice(!isAdminOffice);
  };

  const handleCvUpload = e => {
    const file = [];
    file.push(e.file);
    console.log('CV File:', file);
    setDisplayCv(file);
  };
  const removeCvUpload = () => {
    setDisplayCv([]);
  };

  const handleOtherDoc = e => {
    console.log(e);
    console.log(e);
    const tempList = [...e.fileList, ...displayOtherDoc];
    const tempFiles = new Set(tempList);
    setDisplayOtherDoc(Array.from(tempFiles));
  };

  const removeOtherDoc = uid => {
    console.log(uid);
    setDisplayOtherDoc([]);
  };

  useEffect(() => {
    seteditorState(BraftEditor.createEditorState());
  }, []);

  const submitSummaryContent = async () => {
    const htmlContent = editorState.toHTML();
  };

  const handleEditorChange = editor => {
    seteditorState(editor);
  };

  return (
      <>
        <Row>
          <Col xs={24}>
            <Mutation mutation={ADMIN_ADD_AGENT_DETAILS}>
              {(addAgentDetailsData, { loading, data }) => (
                  <Form
                      layout="vertical"
                      className="user-forms"
                      name="editprofile"
                      initialValues={{
                        remember: true,
                      }}
                      colon={false}
                      onFinish={values => onFinish(values, addAgentDetailsData)}
                      onFinishFailed={onFinishFailed}
                  >
                    <Row style={{ marginTop: 5 }}>
                      <Col xs={24}>
                        <Form.Item
                            name="profilepicture"
                            label={formatMessage({
                              id: 'component.form.profilepicture',
                            })}
                            rules={[
                              {
                                required: true,
                                message: `Vennligst skriv inn ${formatMessage({
                                  id: 'component.form.profilepicture',
                                }).toLowerCase()}`,
                              },
                            ]}
                        >
                          <Upload
                              name="avatar"
                              accept={['.jpg', '.png', '.jpeg']}
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              onChange={handleChange}
                          >
                            {imageUrl ? (
                                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                                uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="firstname"
                            label={formatMessage({
                              id: 'component.form.firstname',
                            })}
                            rules={[
                              {
                                required: true,
                                message: 'Vennligst skriv inn fornavn',
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={formatMessage({
                                id: 'component.form.firstname',
                              })}
                              size="large"
                              className="pickerwidth"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="lastname"
                            label={formatMessage({
                              id: 'component.form.lastname',
                            })}
                            rules={[
                              {
                                required: true,
                                message: 'Vennligst skriv inn etternavn',
                              },
                            ]}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.form.lastname',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="email"
                            label={formatMessage({
                              id: 'component.form.email',
                            })}
                            rules={[
                              {
                                required: true,
                                message: 'Vennligst oppgi epost',
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={formatMessage({
                                id: 'component.form.email',
                              })}
                              size="large"
                              className="pickerwidth"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={8}>
                        <Form.Item
                            name="phone"
                            label={formatMessage({
                              id: 'component.form.phone',
                            })}
                        >
                          <Input
                              placeholder={formatMessage({
                                id: 'component.form.phone',
                              })}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="title"
                            label={formatMessage({
                              id: 'component.form.title',
                            })}
                            rules={[
                              {
                                required: true,
                                message: 'Vennligst oppgi tittel',
                              },
                            ]}
                        >
                          <Input
                              style={{ width: '100%' }}
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.title',
                              }).toLowerCase()}`}
                              size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16]}>
                      <Col xs={24} md={24} lg={16}>
                        <Query
                            query={ADMIN_OFFICES_LIST_ONLY}
                            variables={{
                              status: true,
                            }}
                        >
                          {({ data }) => {
                            if (data && data.offices_list) {
                              return (
                                  <Form.Item
                                      name="office"
                                      label={`${formatMessage({
                                        id: 'component.form.office',
                                      })}`}
                                      rules={[
                                        {
                                          required: true,
                                          message: `Velg ${formatMessage({
                                            id: 'component.form.office',
                                          }).toLowerCase()}`,
                                        },
                                      ]}
                                  >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder={`Velg ${formatMessage({
                                          id: 'component.form.office',
                                        }).toLowerCase()}`}
                                        size="large"
                                        suffixIcon={<CaretDownFilled className="caret-style" />}
                                        className="pickerwidth"
                                    >
                                      {data.offices_list.map(i => (
                                          <Select.Option value={i.id}>
                                            <span>{i.name}</span>
                                          </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                              );
                            }
                            return (
                                <div className="loader-wrapper-inline">
                                  <LoadingOutlined />
                                </div>
                            );
                          }}
                        </Query>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="education"
                            label={formatMessage({
                              id: 'component.form.education',
                            })}
                        >
                      <TextArea
                          rows="3"
                          placeholder={`Angi ${formatMessage({
                            id: 'component.form.education',
                          }).toLowerCase()}`}
                      />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="summary"
                            label={formatMessage({
                              id: 'component.form.summary',
                            })}

                        >

                          <BraftEditor
                              className="editor-wrapper"
                              value={editorState}
                              onChange={handleEditorChange}
                              onSave={submitSummaryContent}
                              language="en"
                              contentStyle={{ height: 210 }}
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.summary',
                              }).toLowerCase()}`}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={32}>
                      <Col xs={24} lg={16} md={16}>
                        <div className="label-texts mb-3">
                          {formatMessage({ id: 'component.form.uploadcv' })}
                        </div>
                        <Form.Item
                            className="no-margin"
                            name="uploadcv"
                            rules={[
                              {
                                required: false,
                                message: (
                                    <span className="text-lowercase">{`Vennligst ${formatMessage({
                                      id: 'component.form.uploadcv',
                                    })}`}</span>
                                ),
                              },
                            ]}
                            valuePropName="file"
                        >
                          <Upload
                              onChange={e => handleCvUpload(e)}
                              accept=".pdf,.doc,.docx"
                              showUploadList={false}
                          >
                            <Button>
                              <UploadOutlined /> {formatMessage({ id: 'component.form.uploadcv' })}
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                    {console.log('displayCv.length:', displayCv.length)}
                    {displayCv.length !== 0 && (
                        <Row gutter={32}>
                          <Col xs={24} lg={16}>
                            {displayCv.map(item => (
                                <div className="default_list mt-3 mb-3">
                                  <div>
                                    <i className="icon-attachment" />
                                    <span className="ml-2">
                              {item.originFileObj ? (
                                  <span>{item.name}</span>
                              ) : (
                                  <a
                                      href={`${STORAGE_URL}${item.media_url}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                  >
                                    {item.name}
                                  </a>
                              )}
                            </span>
                                  </div>
                                  <div className="upload-media-delete" onClick={() => removeCvUpload(item)}>
                                    {deleteCVResponse.loading ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <i className="icon-delete" />
                                    )}
                                  </div>
                                </div>
                            ))}
                          </Col>
                        </Row>
                    )}
                    <Row gutter={8} className="mt-3">
                      <Col xs={24} lg={16}>
                        <Form.Item
                            name="uploadotherdoc"
                            label={formatMessage({
                              id: 'component.form.uploadotherdoc',
                            })}
                        >
                          <Upload
                              onChange={e => handleOtherDoc(e)}
                              accept=".pdf,.doc,.docx"
                              showUploadList={false}
                              multiple
                          >
                            <Button className="borderradius">
                              <UploadOutlined />
                              {formatMessage({
                                id: 'component.form.uploadotherdoc',
                              })}
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                    {displayOtherDoc.length !== 0 && (
                        <Row gutter={32}>
                          <Col xs={24} lg={16}>
                            {displayOtherDoc.map((item, index) => (
                                <div className="default_list mt-3 mb-3">
                                  <div>
                                    <i className="icon-attachment" />
                                    <span className="ml-2">
                              {item.originFileObj ? (
                                  <span>{item.name}</span>
                              ) : (
                                  <a
                                      href={`${STORAGE_URL}${item.media_url}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                  >
                                    {item.name}
                                  </a>
                              )}
                            </span>
                                  </div>
                                  <div
                                      className="upload-media-delete"
                                      onClick={() => removeOtherDoc(item.uid)}
                                  >
                                    <i className="icon-delete" />
                                  </div>
                                </div>
                            ))}
                          </Col>
                        </Row>
                    )}
                    <Row className="mt-3">
                      <Col lg={24}>
                        <Form.Item name="isAdmin">
                          <Checkbox onChange={onCheckboxChange}>
                            {formatMessage({
                              id: 'component.form.isadmin',
                            })}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={24}>
                        <div className="flex-container">
                          <div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btn-secondary buttons admin-forms-btn"
                                size="large"
                                loading={loading}
                            >
                              {formatMessage({
                                id: 'component.form.add',
                              })}
                            </Button>
                            <Button
                                className="text-primary admin-forms-btn"
                                style={{ marginLeft: 8 }}
                                size="large"
                                onClick={router.goBack}
                            >
                              {formatMessage({
                                id: 'component.form.cancel',
                              })}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
              )}
            </Mutation>
          </Col>
        </Row>
      </>
  );
};
export default AgentDetailAddForm;
