import React, {useEffect} from 'react'
//Components
import {Animated, View} from 'react-native'
import {Header} from "components/src/components";
//Third party
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
// import { PickerView } from '@ant-design/react-native';
//Utils
import styles from './styles.js';
import {Color, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import WebView from "../../../../../components/ui/WebView";
// import {Button, InputItem} from "@ant-design/react-native";
// import { DatePickerView } from 'antd-mobile';

function YourFeedBack(props) {
    const {history, navigation} = props;
    const scrollY = new Animated.Value(0)
   /* useEffect(() => {
        // Update the document title using the browser API
        if (IS_WEB) {
            const el = document.createElement('script');
            el.src =
                'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd5GTJ2jjYQYsTJMsMh3vJNMvRqJHjaurcY_2BqUREG72BO.js';
            document.head.appendChild(el);
        }
    }, []);*/

    return (
        <View style={styles.container}>
            <Header animatedTitle={Strings.yourFeedback}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={{flexGrow: 1,}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.contentContainer}>
                    <Icon name={'edit'}
                          style={styles.icon}
                          color={Color.PRIMARY}
                          size={ThemeUtils.fontXLarge}/>

                    <WebView source={{uri:"https://docs.google.com/forms/d/e/1FAIpQLSfyll1DgzODggM86qdU-z6ltr03iNoyKevwaTJ4K6gAaoxdxw/viewform?embedded=true"}}/>
                      {/*  <div style={{
                            marginStart:100
                        }}
                              id="smcx-sdk">

                        </div>
*/}
                        {/*<WebView
                            source={{uri:"https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd5GTJ2jjYQYsTJMsMh3vJNMvRqJHjaurcY_2BqUREG72BO.js"}}/>
*/}

                </View>
            </Animated.ScrollView>
        </View>
    )
}


const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(YourFeedBack);
