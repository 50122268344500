import {STORAGE_URL} from '../../../../../../utils/constant';
import {formatCurrency} from '../../../../../../utils/utils';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Button, Checkbox, Col, Divider, Form, List, Modal, notification, Row, Spin, Typography,} from 'antd';
import React, {useState} from 'react';
import {Mutation, Query} from 'react-apollo';
import {Link, useHistory} from 'react-router-dom';

import {ADMIN_PURCHASEB2BSERVICES, ADMIN_REMOVE_B2BSERVICES,} from '../../../../../../../mutation/AdminMutation';
import {ADMIN_OFFICES_B2B_SERIVICES} from '../../../../../../../query/AdminQuery';
import {formatMessage} from "components/src/utils";

const {Title} = Typography;

let serviceRefetch;

const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
};

const list = [];
const EditServiecesToBuy = props => {
    const {id} = props;
    let router = useHistory();
    const [purchaseService, setpurchaseService] = useState(false);
    const [purchaseItem, setPurchaseItem] = useState(false);
    const [selectedServices, setSelectedServices] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [unUsedserviceList, setUnUsedServiceList] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [purchasedTotalAmount, setPurchasedTotalAmount] = useState(28500);
    const [onCheckedValue, setOnCheckedValue] = useState([]);
    const [checkedlist, setCheckedlist] = useState('');
    const [purchasedServices, setPurchasedServices] = useState([]);
    const [additionalServices, setadditionalServices] = useState([]);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [responsiveModal, setResponsiveModal] = useState(false);
    const checkboxRef = React.createRef();
    const onFinish = values => {
        console.log('Success:', values, '\nadditionalservices', values.additionalservices);
        setpurchaseService(true);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onRemoveBeforePurchase = (item, value) => {
        console.log('item', item);
        let checklist = checkedlist.slice();
        //console.log('Filtered:', checklist.filter(i => parseInt(i,10) !== parseInt(item,10)));
        const serviceList = selectedServices.filter(i => parseInt(i.id, 10) !== parseInt(item, 10));
        setSelectedServices(serviceList);
        setCheckedlist(checklist.filter(i => parseInt(i, 10) !== parseInt(item, 10)));
        setOnCheckedValue(checklist.filter(i => parseInt(i, 10) !== parseInt(item, 10)));
    };

    const onAdditionalServiceChange = checkedValues => {
        setOnCheckedValue(checkedValues);
        console.log('Checked value', checkedValues);
        const selectedService = serviceList.filter(item => checkedValues.includes(item.id));
        console.log('Selected Services===>>>', selectedService);
        let total = 0;
        selectedService.forEach(item => {
            if (!item.is_free) {
                total += parseInt(item.price, 10);
            }
        });
        // total += 35000;
        setTotalAmount(total);
        setSelectedServices(selectedService);
        setCheckedlist(checkedValues);
    };

    const handleConfirmModalOk = addService => {
        setpurchaseService(false);
        console.log(checkedlist);
        addService({
            variables: {
                service_id: checkedlist, // TODO:Change service id here
                office_id: parseInt(id, 10),
            },
        }).then(({data}) => {
            console.log('Data submitted===>>>', data);
            if (data && data.purchase_b2b_services && data.purchase_b2b_services.meta) {
                notification.success({
                    message: data.purchase_b2b_services.meta.message,
                    description: '',
                    duration: 3,
                });
                setSelectedServices([])
                setCheckedlist([]);
                if (serviceRefetch) {
                    serviceRefetch();
                }
            }
        });
    };
    const onRemove = value => {
        setDeleteId(value);
        setDeleteModalVisible(true);
    };
    const handleDeleteModalOk = removeService => {
        setDeleteModalVisible(false);
        removeService({
            variables: {
                service_subscribe_id: deleteId, // TODO:Change service id here
                office_id: parseInt(id, 10),
            },
        }).then(({refetch, data}) => {
            console.log('Data submitted===>>>', data);
            if (data && data.remove_b2b_service && data.remove_b2b_service.meta) {
                notification.success({
                    message: data.remove_b2b_service.meta.message,
                    description: '',
                    duration: 3,
                });
                if (serviceRefetch) {
                    serviceRefetch();
                }
            }
        });
    };

    return (
        <>
            <Row>
                <Col xs={24}>
                    <Title level={3} className="text-primary">
                        {formatMessage({
                            id: 'component.b2bservice.purchase.title',
                        })}
                    </Title>
                </Col>
            </Row>
            <div>
                <Row>
                    <Col lg={24}>
                        <Query
                            query={ADMIN_OFFICES_B2B_SERIVICES}
                            variables={{
                                status: 1,
                                office_id: id,
                            }}
                            fetchPolicy="cache-and-network"
                            onCompleted={data => {
                                console.log(
                                    'data list',
                                    data.office_b2b_services.filter(item => item.office_service !== null),
                                );
                                setServiceList(data.office_b2b_services);
                                const usedServices = data.office_b2b_services.filter(
                                    item => item.office_service !== null,
                                );
                                console.log('service->', usedServices);
                                const unUsedServices = data.office_b2b_services.filter(
                                    item => item.office_service === null,
                                );
                                setUnUsedServiceList(unUsedServices);
                                data.office_b2b_services.map(i => console.log('used services:', i));
                                setPurchasedServices(usedServices);
                            }}
                        >
                            {({data, loading, refetch}) => {
                                serviceRefetch = refetch;
                                if (data && data.office_b2b_services && !loading) {
                                    let finalTotal = 0;

                                    purchasedServices.forEach(item => {
                                        if (!item.is_free) {
                                            finalTotal += parseInt(item.price, 10);
                                        }
                                    });
                                    setPurchasedTotalAmount(finalTotal);
                                    return (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={purchasedServices}
                                            renderItem={item => (
                                                <List.Item className="list-item-without-checkbox mb-3 w-100">
                                                    <List.Item.Meta
                                                        avatar={<img src={`${STORAGE_URL}${item.image}`} alt="avatar"/>}
                                                        title={
                                                            <div className="d-block">
                                                                <div className="d-block">
                                                                    <p className="text-medium mb-0 title">{item.name}</p>
                                                                    {/* <p className="text-medium mb-0 title">Social Media Viewing</p> */}
                                                                </div>
                                                                <h4 className="text-bold mr-3">
                                                                    {item.free_text
                                                                        ? item.is_free
                                                                            ? `${item.free_text}`
                                                                            : `Kr ${formatCurrency(Math.round(item.price))},- ${
                                                                                item.free_text
                                                                                }`
                                                                        : `Kr ${formatCurrency(Math.round(item.price))},-`}
                                                                </h4>
                                                            </div>
                                                        }
                                                        // description={item.description}
                                                    />
                                                        <InfoCircleOutlined
                                                            onClick={() => {
                                                                setResponsiveModal(true)
                                                                setPurchaseItem(item)
                                                            }}/>
                                                </List.Item>
                                            )}
                                        />
                                    );
                                }
                                return (
                                    <div className="loader-wrapper">
                                        <Spin/>
                                    </div>
                                );
                            }}
                        </Query>
                    </Col>
                </Row>
            </div>
            <Row className="offer-total-section admin-total-section">
                <Col xs={24}>
                    {purchasedServices.map(i => (
                        <div className="total-box" key={Math.random()}>
                            <div>
                                {i.is_default === 0 ? (
                                    <>
                                        <Mutation mutation={ADMIN_REMOVE_B2BSERVICES}>
                                            {(removeService, {loading, data}) => {
                                                if (data) {
                                                    if (data && data.remove_b2b_service && data.remove_b2b_service.meta) {
                                                        notification.success({
                                                            message: data.remove_b2b_service.meta.message,
                                                            description: '',
                                                            duration: 3,
                                                        });
                                                        if (serviceRefetch) {
                                                            serviceRefetch();
                                                        }
                                                    }
                                                }
                                                return (
                                                    <i
                                                        className="iconList-delete icon-order-cancel"
                                                        onClick={value => onRemove(i.id)}
                                                        value={i.id}
                                                    />
                                                );
                                            }}
                                        </Mutation>
                                    </>
                                ) : (
                                    ''
                                )}
                                <span>{i.name}</span>
                            </div>
                            <div>{!i.is_free ? `Kr ${formatCurrency(Math.round(i.price))},-` : i.free_text}</div>
                        </div>
                    ))}
                </Col>
                <Divider style={{background: '#fff', opacity: '0.5'}}/>
                <Col xs={24}>
                    <div className="total-box" key={Math.random()}>
                        <div>
                            <p className="no-margin text-secondary"> Totalpris</p>
                        </div>
                        <div>
                            <p className="no-margin text-secondary">Kr. {formatCurrency(Math.round(purchasedTotalAmount))},-</p>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className="text-primary main mt-3">
                <div style={{marginBottom: '0px'}}>
                    <Row>
                        <Col xs={24}>
                            <Title level={3} className="text-primary">
                                {formatMessage({
                                    id: 'component.form.additionalservices',
                                })}
                            </Title>
                        </Col>
                    </Row>

                    <Form
                        className="user-forms"
                        name="b2bservice"
                        initialValues={{
                            remember: true,
                        }}
                        colon={false}
                        onFinish={values => onFinish(values)}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                    >
                        <Row>
                            <Col xs={24} className="checkbox-checklist">
                                <Form.Item
                                    name="additionalservices"
                                    /*rules={[
                                      {
                                        required: true,
                                        message: `Vennligst velg  ${formatMessage({
                                          id: 'component.form.additionalservices',
                                        })}!`,
                                      },
                                    ]}*/
                                >
                                    <Checkbox.Group
                                        style={{width: '100%'}}
                                        className="d-none d-lg-block"
                                        value={onCheckedValue.length > 0 && onCheckedValue}
                                        onChange={onAdditionalServiceChange}
                                    >
                                        <Row className="w-100">
                                            <Col xs={24}>
                                                <List
                                                    itemLayout="horizontal"
                                                    // dataSource={data.purchased_services}
                                                    dataSource={unUsedserviceList}
                                                    renderItem={item => (
                                                        <List.Item className="listview-with-checkbox">
                                                            <Checkbox
                                                                value={item.id}
                                                                checked={
                                                                    item.usableServiceClass && item.usableServiceClass.length > 0
                                                                }
                                                                disabled={
                                                                    item.usableServiceClass && item.usableServiceClass.length > 0
                                                                }
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={<img src={`${STORAGE_URL}${item.image}`}
                                                                                 alt="avatar"/>}
                                                                    title={
                                                                        <div className="flex-wrapper space-between">
                                                                            <div className="flex-wrapper">
                                                                                <h4 className="tabs-list-heading">
                                                                                    {item.name}
                                                                                    {/* {item.title} */}
                                                                                </h4>
                                                                            </div>
                                                                            <h4 className="text-bold">
                                                                                {item.is_free
                                                                                    ? item.free_text
                                                                                    : `Kr ${formatCurrency(Math.round(item.price))},-`}
                                                                            </h4>
                                                                        </div>
                                                                    }
                                                                    description={item.description}
                                                                />
                                                            </Checkbox>
                                                        </List.Item>
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>

                                    <Checkbox.Group
                                        style={{width: '100%'}}
                                        className="d-lg-none d-block"
                                        value={onCheckedValue.length > 0 && onCheckedValue}
                                        onChange={onAdditionalServiceChange}
                                    >
                                        <Row>
                                            <Col xs={24}>
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={unUsedserviceList}
                                                    renderItem={item => (
                                                        <List.Item className="listview-with-checkbox"
                                                        >
                                                            <Checkbox
                                                                value={item.id}
                                                                checked={
                                                                    item.usableServiceClass && item.usableServiceClass.length > 0
                                                                }
                                                                disabled={
                                                                    item.usableServiceClass && item.usableServiceClass.length > 0
                                                                }
                                                            >
                                                                <List.Item.Meta
                                                                    title={
                                                                        
                                                                            <a href="#" className="text-primary">
                                                                                {item.name}
                                                                            </a>
                                                                        
                                                                    }
                                                                    // avatar={<img src={`${STORAGE_URL}${item?.image}`}
                                                                    //              alt="avatar" />}
                                                                    description={
                                                                        <>
                                                                        {item.is_free
                                                                            ? item.free_text
                                                                            : `Kr ${formatCurrency(Math.round(item.price))},-`}
                                                                <span className="price-text responsive"> &nbsp;
                                                                    <InfoCircleOutlined
                                                                        onClick={() => {
                                                                            setResponsiveModal(true)
                                                                            setPurchaseItem(item)
                                                                        }}/>
                                                                </span>
                                                                    </>}
                                                                />
                                                            </Checkbox>
                                                        </List.Item>
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* Additional service modal */}
                        <Modal
                            visible={responsiveModal}
                            title={purchaseItem?.name}
                            onOk={() => setResponsiveModal(false)}
                            onCancel={() => setResponsiveModal(false)}
                            footer={[
                                <Button type="primary" onClick={() => setResponsiveModal(false)}>
                                    Okay
                                </Button>,
                            ]}
                        >
                            {purchaseItem?.free_text ? (
                                <p className="text-large">{purchaseItem?.free_text}</p>) : (
                                <p className="text-large">Kr {formatCurrency(Math.round(purchaseItem?.price))},-</p>)}
                            <p>
                                {purchaseItem?.description}
                            </p>
                        </Modal>

                        {selectedServices.length > 0 && (
                            <Row className="admin-total-section offer-total-section">
                                <Col xs={24}>
                                    {selectedServices &&
                                    selectedServices.map(item => (
                                        <div className="total-box" key={item.id}>
                                            <div>
                                                <i
                                                    className="iconList-delete icon-order-cancel"
                                                    onClick={value => onRemoveBeforePurchase([item.id])}
                                                    value={item.id}
                                                />
                                                <span>{item.name}</span>
                                            </div>
                                            <div>{item.is_free ? item.free_text : `Kr ${formatCurrency(Math.round(item.price))},-`}</div>
                                        </div>
                                    ))}
                                </Col>
                                <Divider style={{background: '#fff', opacity: '0.5'}}/>
                                <Col xs={24}>
                                    <div className="total-box" key={Math.random()}>
                                        <div>
                                            <p className="text-secondary no-margin"> Totalpris</p>
                                        </div>
                                        <div>
                                            <p className="text-secondary no-margin">{`Kr. ${formatCurrency(totalAmount)},-`}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row className="mt-3">
                            <Col xs={24} style={{paddingTop: 16}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="btn-secondary buttons admin-forms-btn"
                                    size="large"
                                    disabled={selectedServices?.length <= 0}
                                    // onClick={() => setpurchaseService(true)}
                                >
                                    {formatMessage({
                                        id: 'component.b2bservice.purchase',
                                    })}
                                </Button>
                                <Button
                                    className="text-primary admin-forms-btn"
                                    style={{marginLeft: 8}}
                                    size="large"
                                >
                                    <Link to="/admin/kontorer">
                                        {formatMessage({
                                            id: 'component.form.cancel',
                                        })}
                                    </Link>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <Mutation mutation={ADMIN_PURCHASEB2BSERVICES}>
                {(servicesToBePurchased, {loading}) => (
                    <div className="modalclass">
                        <Modal
                            title="Purchase Service"
                            visible={purchaseService}
                            onOk={() => handleConfirmModalOk(false)}
                            onCancel={() => setpurchaseService(false)}
                            footer={[
                                <Button
                                    key="submit"
                                    type="primary"
                                    className="btn-secondary admin-forms-btn"
                                    onClick={() => handleConfirmModalOk(servicesToBePurchased)}
                                    loading={loading}
                                >
                                    Ja
                                </Button>,
                                <Button
                                    className="cancelbutton admin-forms-btn"
                                    key="back"
                                    onClick={() => setpurchaseService(false)}
                                >
                                    Nei
                                </Button>,
                            ]}
                        >
                            <p>Er du sikker på at du vil bestille denne tjenesten?</p>
                        </Modal>
                    </div>
                )}
            </Mutation>
            <Mutation mutation={ADMIN_REMOVE_B2BSERVICES}>
                {(servicesToBePurchased, {loading}) => (
                    <div className="modalclass">
                        <Modal
                            title="Purchase Service"
                            visible={deleteModalVisible}
                            onOk={() => handleDeleteModalOk(false)}
                            onCancel={() => setDeleteModalVisible(false)}
                            footer={[
                                <Button
                                    key="submit"
                                    type="primary"
                                    className="btn-secondary admin-forms-btn"
                                    onClick={() => handleDeleteModalOk(servicesToBePurchased)}
                                    loading={loading}
                                >
                                    Ja
                                </Button>,
                                <Button
                                    className="cancelbutton admin-forms-btn"
                                    key="back"
                                    onClick={() => setDeleteModalVisible(false)}
                                >
                                    Nei
                                </Button>,
                            ]}
                        >
                            <p>Er du sikker på at du vil slette denne tjenesten?</p>
                        </Modal>
                    </div>
                )}
            </Mutation>
        </>
    );
};
export default EditServiecesToBuy;
