import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },
    checkbox: {
        alignItems: 'flex-start',
        flex: 1,
        marginTop: 8,
        alignContent: 'center',
        justifyContent: 'space-around'
    },
    fieldContainer: {
        alignContent: 'center',
    },
    labelContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    energyradio: {
        marginVertical: 10,

    },
});
export default styles;
