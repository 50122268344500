import gql from 'graphql-tag';

export const AGENT_NEWS_LIST = gql`
  query news($status: Boolean, $orderBy: [OrderByClause!], $first: Int!, $page: Int) {
    news(status: $status, orderBy: $orderBy, first: $first, page: $page) {
      data {
        id
        cover_image_url
        title
        description
        created_at
        video_url
        created_at
        status
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        lastItem
        lastPage
        perPage
      }
    }
  }
`;

export const AGENT_NEWS_DEATILS = gql`
  query news_detail($status: Int!, $id: Int!) {
    news_detail(id: $id, status: $status) {
      cover_image_url
      title
      description
      created_at
      video_url
    }
  }
`;

export const AGENT_FAGLIGS_LIST = gql`
  query professionals($first: Int!, $page: Int, $status: Boolean, $orderBy: [OrderByClause!]) {
    professionals(first: $first, page: $page, status: $status, orderBy: $orderBy) {
      data {
        id
        cover_image_url
        title
        description
        video_url
        created_at
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        lastItem
        lastPage
        perPage
      }
    }
  }
`;

export const AGENT_FAGLIGS_LIST_DETAILS = gql`
  query professional_detail($id: Int!, $status: Int!) {
    professional_detail(id: $id, status: $status) {
      cover_image_url
      title
      description
      video_url
      created_at
    }
  }
`;

export const PHOTO_SALES_INFO = gql`
  query moovin_photo_seller_info($property_id: Int!) {
    moovin_photo_seller_info(property_id: $property_id) {
      id
      property_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      order_status
      status
      property {
        street_adress
        oppdrags_nummer
        seller {
          id
          first_name
          last_name
          email
          mobile
        }
      }
    }
  }
`;

// Finn Details.

export const FINN_DETAILS = gql`
  query property_finn_ad_details($status: Int!, $property_id: Int!) {
    finn_ad_detail(property_id: $property_id, status: $status) {
      id
      property_id
      url
      is_approved
      approved_date
      finn_comments {
        id
        property_finn_ad_id
        comment
        created_at
        updated_at
      }
    }
  }
`;

// digital sales assignment details

export const DIGITAL_ASSIGNMENT_DETAILS = gql`
  query digital_sales_assignment($status: Int!, $property_id: Int!) {
    ds_assignment_detail(status: $status, property_id: $property_id) {
      id
      property_id
      url
      is_approved
      approved_date
      digital_sales_assi_comment {
        id
        digital_sales_assi_id
        comment
        created_at
        updated_at
      }
    }
  }
`;

// marketing material detail

export const PROPERTY_MARKETING_MATERIAL = gql`
  query property_marketing_materials($property_id: Int!, $status: Int!) {
    fetch_market_materials(property_id: $property_id, status: $status) {
      id
      document_id
      is_approved
      approved_date
      materialComment {
        id
        comment
        created_at
      }
      document {
        id
        property_id
        title
        doc_url
        doc_type
        is_buyer_seller_doc
        status
        property {
          id
          seller_id
          oppdrags_nummer
          street_adress
          seller {
            id
            first_name
            last_name
            email
            address
            mobile
            profile_image
            city
            zipcode
            status
            access_level
          }
        }
      }
    }
  }
`;

// Document List

export const DOC_LIST = gql`
  query property_documents($property_id: Int!, $status: Int!) {
    property_documents(property_id: $property_id, status: $status) {
      id
      property_id
      title
      doc_url
      doc_type
      is_buyer_seller_doc
      status
      property {
        id
      }
    }
  }
`;

// selger bestilliger

export const AGENT_MOOVIN_PROPERTY_RATE = gql`
  query moovin_property_rate($property_id: Int!) {
    moovin_property_rate(property_id: $property_id) {
      id
      property_id
      order_rate_id
      tax_date
      construction_year
      value_rate
      value_rate
      loan_rate
      bta
      bra
      p_rom
      order_rate {
        property_id
        order_date
        earliest_start_time
        latest_start_time
        comment
      }
    }
  }
`;

export const AGENT_MOOVIN_PROPERTY_SELLER_DETAILS = gql`
  query moovin_property_detail($id: ID!) {
    moovin_property_detail(id: $id) {
      id
      street_adress
      oppdrags_nummer
      zipcode
      city
      seller {
        id
        first_name
        last_name
        email
        mobile
      }
    }
  }
`;

export const AGENT_SELLER_ORDER = gql`
  query purchased_services($status: Int!, $propertyId: Int!) {
    purchased_services(status: $status, property_id: $propertyId) {
      service_types {
        id
        name
        image
        sub_category
        office_template {
          id
          office_id
          service_type_id
          headline
          description
        }
        vendor_services {
          id
          vendor_id
          service_type_id
          name
          description
          image
          icon
          price
          is_use
          is_free
          free_text
          external_url
          service_selected {
            id
            property_id
            usable_service_id
            usable_service_class
            price
            quantity
          }
        }
      }
      purchased_services {
        id
        name
        image
        purchased_office_template {
          id
          office_id
          service_type_id
          headline
          description
          description
          purchased_office_services {
            id
            office_template_id
            name
            description
            image
            icon
            price
            is_use
            is_free
            free_text
            external_url
            service_selected {
              id
              property_id
              usable_service_id
              usable_service_class
              price
              quantity
            }
          }
        }
      }
    }
  }
`;

export const AGENT_MOOVIN_STYLIST_DETAILS = gql`
  query moovin_stylist_details($property_id: Int!) {
    moovin_stylist_details(property_id: $property_id) {
      id
      property_id
      styling_type_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      status
      order_status
      property {
        id
        street_adress
        seller_id
        oppdrags_nummer
        zipcode
        city
        seller {
          title
          first_name
          last_name
          email
          mobile
        }
      }
      styling_type {
        name
      }
    }
  }
`;

export const PROPERTY_DISPLAY = gql`
  query property_display($property_id: Int!) {
    property_display(property_id: $property_id) {
      id
      display_date
      property_id
      earliest_start_time
      latest_start_time
      comment
      is_broker_on_view
      created_at
    }
  }
`;

export const STACK_HOLDERS_FROM_DS = gql`
  query stackholders_from_ds(
    $propertyId: Int!
    $status: Int!
    $first: Int!
    $page: Int!
    $key: String
    $sorting: OrderByClause
  ) {
    stackholders_from_ds(
      property_id: $propertyId
      status: $status
      first: $first
      page: $page
      key: $key
      sorting: $sorting
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastPage
        total
        perPage
      }
      data {
        id
        first_name
        last_name
        email
        phone
        send_info_about_property
        bid_notification
        get_update_similar_homes
        receive_our_newsletter
      }
    }
  }
`;

export const STACK_HOLDERS_SIGNED_IN_VIEW = gql`
  query signed_in_viewing_ds(
    $propertyId: Int!
    $status: Int!
    $first: Int!
    $page: Int!
    $key: String
    $sorting: OrderByClause
  ) {
    signed_in_viewing_ds(
      property_id: $propertyId
      status: $status
      first: $first
      page: $page
      key: $key
      sorting: $sorting
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastPage
        total
        perPage
      }
      data {
        id
        first_name
        last_name
        email
        phone
        contact_me_private_viewing_time
        plan_set_community_shows
        is_notify_me
      }
    }
  }
`;

export const AGENT_MOOVIN_PROPERTY_LIST = gql`
  query moovin_properties(
    $status: Int!
    $first: Int!
    $page: Int!
    $key: String
    $sorting: OrderByClause
  ) {
    moovin_properties(status: $status, first: $first, page: $page, key: $key, sorting: $sorting) {
      data {
        id
        oppdrags_nummer
        street_adress
        property_agent {
          id
          # agentTeam {
          #   title
          #   teamMembers {
          #     id
          #     is_primary_agent
          #     agent_id
          #     status
          #     agentUser {
          #       first_name
          #       last_name
          #     }
          #   }
          # }
          name
        }
      }
      paginatorInfo {
        count
        currentPage
        total
        perPage
      }
    }
  }
`;

export const ENERGY_LABELING = gql`
  query energyLabeling($propertyId: Int!, $status: Int!) {
    moovin_energy_labeling(property_id: $propertyId, status: $status) {
      id
      property_id
      document_id
      letter_code
      color_code
      document {
        id
        property_id
        title
        doc_url
        doc_type
        is_buyer_seller_doc
        status
      }
      HouseHeatType {
        id
        name
      }
    }
  }
`;

export const SETTLEMENT_INFO = gql`
  query propertySettlementInfo($propertyId: Int!) {
    property_settlement_information(property_id: $propertyId) {
      id
      property_id
      address
      city
      zipcode
      loan_status
      settlement_loans {
        id
        denomination_kr
        residual_debt_kr
        reminder_date
        bank_name
        bank_contact
        bank_phone
        bank_email
        loan_no
        is_repaid
      }
      settlement_receivable {
        id
        receivables_account_no
        percentage
      }
    }
  }
`;

export const SELLER_INTERVIEW = gql`
  query sellerInterview($propertyId: Int!, $status: Int!) {
    moovin_seller_interviews(property_id: $propertyId, status: $status) {
      id
      seller_id
      property_id
      answer_json
      status
    }
  }
`;

// Insurance List

export const BOLIGSELGER_INSURANCE_LIST = gql`
  query boligselgerInsuranceList($propertyId: Int!) {
    boligselger_Insurance_list(property_id: $propertyId) {
      id
      name
      selected_in_property
    }
  }
`;

// Vitec Buyer Info

export const VITEC_BUYER_INFO = gql`
  query propertyInfoFromBuyer($propertyId: Int!) {
    vitec_buyer_info(property_id: $propertyId) {
      contactId
      departmentId
      contactType
      companyName
      organisationNumber
      firstName
      lastName
      mobilePhone
      privatePhone
      workPhone
      email
      address
      postalAddress
      postalCode
      city
      changedDate
      customerReview
    }
  }
`;

export const PROPERTY_INFO_FROM_BUYER = gql`
  query propertyInfoFromBuyer($property_id: Int!) {
    property_info_from_buyer(property_id: $property_id) {
      id
      contactId
      departmentId
      contactType
      companyName
      organisationNumber
      first_name
      last_name
      email
      phone
      address
      city
      zipcode
      ownership_interest
      personnummer
      eierandel
      contact_person
      ssn_number
      coBuyerInfo {
        id
        prop_buyer_info_id
        contactId
        departmentId
        contactType
        companyName
        organisationNumber
        first_name
        last_name
        email
        phone
        address
        city
        zipcode
        workPhone
        ownership_interest
        personnummer
        contact_person
        ssn_number
        eierandel
      }
      buyerBankDetail {
        id
        prop_buyer_info_id
        agencapital
        opparinelse_id
        opparinelse_text
        pant_boligen
        pep_status
        purpose_of_purchase_id
        purchase_text
        account_number
        buyerBankInfo {
          buyer_bank_id
          bank_name
          bank_contact
          bank_email
          bank_mobile
        }
      }
      buyerHomeInsurance {
        id
        prop_buyer_info_id
        want_insurance
        status
      }
      buyerHomeContract {
        id
        prop_buyer_info_id
        comment
        status
      }
    }
  }
`;

// Timeline

export const AGENT_SELLER_TIMELINE = gql`
  query seller_timeline($propertyId: ID!, $status: Int) {
    seller_timeline(property_id: $propertyId, status: $status) {
      id
      property_id
      timeline_id
      completed_date
      completed_time
      end_date
      is_completed
      timeline {
        id
        name
        description
        icon
      }
    }
  }
`;

export const AGENT_BUYER_TIMELINE = gql`
  query buyer_property_timeline($propertyId: ID!, $status: Int!) {
    buyer_property_timeline(property_id: $propertyId, status: $status) {
      id
      is_completed
      completed_date
      updated_at
      timeline {
        id
        name
        description
        icon
      }
    }
  }
`;

// bids

export const PROPERTY_BID = gql`
  query property_bids($property_id: Int!) {
    property_bids(property_id: $property_id) {
      bids {
        bidId
        type
        time
        amount
        partyid
        expires
        reservations
        accepted
        changedDate
        rejectedDate
      }
      color
    }
  }
`;

// property list

export const PROPERTY_LISTING = gql`
  query ds_property_list($input: DsInput!) {
    ds_property_list(input: $input) {
      data {
        id
        property_id
        estateId
        oppdragsnummer
        streetAdress
        zipCode
        city
        status
        lastUpdatedDate
        ownershipType
        vitecPropertyPlot {
          size
        }
        vitecPropertyEstatePrice {
          priceSuggestion
        }
        vitecPropertyAds {
          vitec_prop_id
          ads {
            adStatus_text
            ads_id
            publishStart
            publishEnd
            lastChanged
          }
        }
        vitecPropertyImages {
          id
          vitec_prop_id
          imageId
          imageUrl
          fileType
          imageSequence
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const DS_DETAIL_PAGE = gql`
  query ds_property_detail($oppdragsnummer: String!, $is_live_request: Boolean!) {
    ds_property_detail(oppdragsnummer: $oppdragsnummer, is_live_request: $is_live_request) {
      id
      estateId
      property_id
      heading
      randomCode
      systemId
      defaultImageId
      defaultImageUrl
      departmentId
      settleDepartmentId
      status
      assignmentNum
      assignmentType
      ownership
      pris {
        min
        max
      }
      totalpris {
        min
        max
      }
      tomteareal {
        min
        max
      }
      p_rom {
        min
        max
      }
      bra {
        min
        max
      }
      soverom {
        min
        max
      }
      estateTypeExternal
      estateBaseType
      noOfRooms
      noOfBedRooms
      floor
      constructionYear
      energyLetter
      energyColorCode
      energyColorName
      rentalTimeSpan
      availableDate
      apartmentNumber
      streetAdress
      zipCode
      city
      # soldDate
      # commissionAcceptedDate
      # takeOverDate
      # contractMeetingDate
      finnCode
      # finnPublishDate
      # finnExpireDate
      leasingContractDate
      municipality
      municipalityId
      projectRelation
      estateTypeId
      estateType
      longitude
      latitude
      takeoverComment
      appraiserContactId
      areaId
      changedDate
      bidLink
      lastUpdatedDate
      ownershipType
      department {
        departmentId
        name
        organisationNumber
        legalName
        departmentNumber
        phone
        fax
        email
        streetAddress
        postalAddress
        postalCode
        city
      }
      vitecPropertyLinks {
        id
        vitec_prop_id
        linkType
        url
        text
      }
      view360 {
        id
        order_photo_id
        property_id
        view_360_url
      }
      video {
        id
        order_photo_id
        property_id
        video_url
      }
      office {
        widget_code
      }
      vitecPropertyLinks {
        id
        vitec_prop_id
        linkType
        url
        text
      }
      vitecPropertySeller {
        firstName
        lastName
      }
      vitecPropertyDocument {
        id
        vitec_prop_id
        head
        documentId
        documentUrl
        extension
        lastChanged
        docType
      }
      vitecPropertyEmp {
        id
        vitec_prop_id
        employeeId
        brokerRole
        title
        name
        email
        mobilePhone
        photo
      }
      vitecPropertyAds {
        id
        estateBaseType
        estateStatus
        estateStatus_text
        statistics
        ads {
          id
          vitec_prop_ads_id
          ads_id
          channel
          channel_text
          adStatus
          adStatus_text
          publishStart
          publishEnd
          lastChanged
        }
      }
      vitecPropertyEstatePrice {
        id
        vitec_prop_id
        priceSuggestion
        totalPrice
        soldPrice
        estimatedValue
        collectiveDebt
        collectiveAssets
        loanFare
        communityTax
        communityTaxYear
        salesCostDescription
        rentPrMonth
        rentIncludes
        purchaseCostsAmount
        totalPrice
        waterRate
        waterRateDescription
        waterRateYear
        yearlySocietyTax
        yearlyLeaseFee
        leasingPartyTransportFee
        originalExpensesPrice
        originalAgreementPrice
        additionalAgreementOptions
        originalExpensesPrice
        originalAgreementPrice
      }
      vitecPropertyEstateSize {
        id
        vitec_prop_id
        primaryRoomArea
        primaryRoomAreaDescription
        grossArea
        usableArea
      }
      vitecPropertyImages {
        id
        vitec_prop_id
        imageId
        imageUrl
        fileType
        imageSequence
        imageDescription
        imageCategoryName
      }
      vitecPropertyMatrikkel {
        vitec_prop_id
        knr
        gnr
        bnr
        fnr
        snr
        ownPart
      }
      vitecPropertyPartOwnership {
        id
        vitec_prop_id
        loanTermsCollectiveDebt
        jointDebtDescription
        jointDebtInterest
        accountingBudgeting
        partName
        partOrgNumber
        businessManagerContactId
        preemptive
        guaranteeFund
        boardApproval
        statutes
        businessManagerContactId
        partNumber
        estateHousingCooperativeStockNumber
        shareJointCapital
        forretningsforer
      }
      vitecPropertyPlot {
        id
        vitec_prop_id
        owned
        size
        description
      }
      vitecPropertyShowing {
        id
        vitec_prop_id
        startDate
        endDate
        showingId
        showingnote
      }
      vitecPropertyTextField {
        id
        vitec_prop_id
        innhold
        standard
        descriptionItemRenovated
        constructionMethod
        adgangForUtleie
        oppvarming
        energyMarking
        diverse
        radonmaling
        beliggenhet
        adkomst
        parkering
        bebyggelse
        offentligKommunikasjon
        barnehageSkoleFritid
        communityFee
        legalpant
        hvitevarer
        velforening
        vitecPropertyTextfieldDetail {
          vitec_prop_textfield_id
          propertyTaxComments
          insuranceCompany
          insurancePolicy
          servitutterErklaeringer
          ferdigattest
          reguleringsplan
          veiVannAvlop
          kommentarKonsesjon
          kommentarOdelsrett
          kommentarBoDriveplikt
          paymentConditions
          biddingInfo
          sentraleLoverAsIs
          eierskifteforsikring
          homeOwnersInsurance
          moneyLaunderingRules
          personalDataAct
          financing
          meglersVederlag
          aboutTheUnit
          # insurancePolicy
        }
      }
      vitecPropertyValuationTax {
        id
        vitec_prop_id
        primaryValue
        primaryYear
        secondaryValue
        secondaryYear
        comment
        propertyTaxAmount
        propertyTaxYear
        valuationType
        formuesverdi_sekundar
        propertyTaxAmountYear
      }
      vitecPropertyProject {
        id
        vitec_prop_id
        estateId
        sellerId
        projectName
        assignmentNum
        vitec_status
        departmentId
        employeeId
        p12Garanti
        p12Due
        p47Due
        p12Belop
        p47Belop
        createdDate
        changedDate
        vitecPropertyProjectTextfield {
          id
          vitec_prop_proj_id
          miscellaneous
          commonArea
          garageParking
          booth
          completion
          kitchen
          bath
          recreationalRooms
          wardrobe
          construction
          facade
          roofing
          balconyTerracePatio
          stairs
          elevators
          ventilation
          sanitary
          fireSafety
          doors
          surfaces
          vitecPropertyProjectTextfieldDetail {
            id
            vitec_proj_tf_id
            boothSolutions
            garageDoor
            mailboxes
            doorPhone
            cableTvBroadbandTelephone
            electricalSystem
            organizationType
            stipulatedMonthlyJointCosts
            stipulatedShareJointDebt
            pricing
            financing
            saleOfContractPosition
            cancellations
            options
            spaceCalculation
            paymentConditions
            saleConditions
            buyingContract
            importantInformation
            distinctReservations
            aboutTheProject
          }
        }
      }
    }
  }
`;

export const INSPECTION_APPLICATIONS_PROPERTY_LIST = gql`
  query office_properties($input: OfficePropInput!) {
    office_properties(input: $input) {
      data {
        id
        zipcode
        city
        street_adress
        property_seller {
          id
          companyName
          firstName
          lastName
          mobilePhone
          privatePhone
          workPhone
          email
          postalAddress
          postalCode
          city
          is_primary_seller
        }
        agent {
          id
          first_name
          last_name
        }
        property_agent {
          id
          property_id
          title
          name
          mobilePhone
          email
          brokerRole
        }
        property_inspection {
          id
          start_time
          start_date
        }
        propertyActivity {
          id
          start_date
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const PROPERTY_UTLEGG_LIST = gql`
  query property_utlegg_list($property_id: Int!, $status: Int!) {
    property_utlegg_list(property_id: $property_id, status: $status) {
      id
      property_id
      name
      price
      is_default
      is_free
      free_text
      property_type
      type
      status
      usableServiceClass {
        id
        property_id
        usable_service_id
      }
    }
  }
`;

export const SERVICES = gql`
  query services($status: Boolean!, $service_category: ServiceCatrgory!) {
    services(status: $status, service_category: $service_category) {
      id
      name
      image
      office_template {
        id
        office_id
        service_type_id
        headline
        description
        office_services {
          id
          office_template_id
          name
          description
          image
          price
          is_use
          is_free
        }
        office_service_media {
          id
          office_template_id
          media_url
          media_new_url
          media_type
        }
        agent_service_media {
          id
          office_template_id
          media_url
          media_new_url
          media_type
        }
      }
    }
  }
`;

export const PROPERTY_DETAIL = gql`
  query property($id: Int!) {
    property(id: $id) {
      id
      seller_id
      agent_id
      office_id
      estate_id
      department_id
      construction_year
      plot_size
      apartment_no
      street_adress
      zipcode
      city
      ownership
      knr
      gnr
      bnr
      client_claim
      client_married_partner
      client_partner_both_sign
      contract_apply_housing
      contract_assignment
      name_of_brokerage
      political_person
      political_person
      municipality
      partOrgNumber
      partName
      partNumber
      sales_warranty_status
      property_agent {
        id
        property_id
        title
        name
        mobilePhone
        email
        brokerRole
      }
      property_preference {
        id
        percentage
        fix_price
        preferable_price
        rebate_amount
      }
      estateHousingCooperativeStockNumber
      property_inspection {
        id
        start_time
        start_date
        template_type
      }
      fnr
      snr
      property_seller {
        id
        companyName
        lastName
        firstName
        mobilePhone
        email
        is_primary_seller
      }
      propertyActivity {
        id
        start_date
      }
      ownpart
      estate_type_id
      estate_type
      agent {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        office {
          id
          image_url
          city
          logo_url
          product_gallery {
            id
            office_id
            headline
            ProductGalleryImages {
              id
            }
          }
          name
        }
        zipcode
        status
        title
        user_media {
          id
          title
          redirect_url
          media_url
        }
        user_detail {
          user_id
          id
          education
          about
          cv_url
          status
        }
        office {
          name
        }
        user_document {
          id
          user_id
          doc_url
          doc_type
        }
        feedback {
          id
          user_id
          rating
          feedback
          given_by
        }
      }
      property_vendor {
        id
        vendor_id
        user_type_id
        property_id
      }
    }
  }
`;

export const AGENT_PROPERTY_LIST = gql`
  query signature_property($input: SignaturePropertyInput!) {
    signature_property(input: $input) {
      data {
        id
        seller_id
        agent_id
        office_id
        oppdrags_nummer
        estate_id
        department_id
        construction_year
        plot_size
        apartment_no
        street_adress
        zipcode
        city
        knr
        gnr
        bnr
        fnr
        snr
        ownpart
        estate_type_id
        estate_type
        municipality
        municipality_id
        longitude
        latitude
        created_date
        seller_completed
        seller_sign_date
        vitec_property_status
        primary_room_area
        gross_area
        usable_area
        client_claim
        client_married_partner
        client_partner_both_sign
        contract_apply_housing
        signed_both_seller
        signed_by_agent
        total_owner
        contract_assignment
        name_of_brokerage
        political_person
        agreement_pdf_path
        seller {
          id
          first_name
          last_name
          email
          address
          mobile
          profile_image
          city
          zipcode
          status
          access_level
          user_type_id
          office_id
          title
        }
        property_seller {
          id
          companyName
          firstName
          lastName
          mobilePhone
          privatePhone
          workPhone
          email
          address
          postalAddress
          postalCode
          city
          is_primary_seller
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const AGENT_SIGN_CONTRACT = gql`
  query agent_sign_contract($input: agentSignContractInput) {
    agent_sign_contract(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const PERSONAL_TEMPLATE_DETAILS = gql`
  query personalTemplateDetails($status: Int!, $agentId: Int!, $id: Int!) {
    personal_template_details(status: $status, agent_id: $agentId, office_template_id: $id) {
      id
      agent_id
      office_template_id
      media_url
      media_new_url
      media_type
    }
  }
`;

export const AGENT_BEFARING_DETAILS = gql`
  query agentBefaringContent($status: Int!, $agentId: Int!) {
    agent_befaring_content(status: $status, agent_id: $agentId) {
      id
      agent_id
      description
    }
  }
`;

export const AGENT_BEFARING_MEDIA = gql`
  query agentProductGallery($status: Int!, $agentId: Int!) {
    befaring_product_gallery(status: $status, agent_id: $agentId) {
      id
      agent_id
      headline
      AgentProductGalleryImages {
        id
        url
      }
    }
  }
`;
export const AGENT_AFTER_BEFARING_MEDIA = gql`
  query agentAfterBefaringProductGallery($status: Int!, $agentId: Int!) {
    befaring_after_product_gallery(status: $status, agent_id: $agentId) {
      id
      agent_id
      headline
      AgentAfterGalleryMedia {
        id
        agent_product_gallery_id
        url
      }
    }
  }
`;

export const SELECTED_SERVICE = gql`
  query selected_property_services($status: Int!, $category: ServiceCatrgory, $propertyId: Int!) {
    selected_property_services(
      property_id: $propertyId
      status: $status
      service_category: $category
    ) {
      remunerations {
        id
        name
        description
        image
        icon
        price
        cost_type
        hours
        usableServiceClass {
          id
          property_id
          quantity
          price
        }
      }
      service_types {
        id
        name
        vendor_services {
          id
          vendor_id
          service_type_id
          name
          description
          price
          is_free
          free_text
          image
          service_selected {
            id
            property_id
            usable_service_id
            usable_service_class
            price
            quantity
          }
        }
      }
      services {
        id
        name
        purchased_office_template {
          id
          office_id
          service_type_id
          headline
          description
          purchased_office_services {
            id
            office_template_id
            name
            description
            image
            price
            is_use
            is_free
            free_text
            service_selected {
              id
              property_id
              price
            }
          }
          agent_service_media {
            id
            office_template_id
            media_url
            media_new_url
            media_type
          }
          office_service_media {
            id
            office_template_id
            media_url
            media_new_url
            media_type
          }
        }
      }
      property_utlegg {
        id
        property_id
        name
        price
        type
        status
        is_free
        free_text
        is_default
        price_type
        usableServiceClass {
          id
          property_id
          price
        }
      }
      marketing_package {
        id
        office_id
        name
        price
        description
        image_url
        property_package {
          id
          property_id
          marketing_package_id
          price
        }
        marketing_package_service {
          id
          marketing_package_id
          office_template_id
          office_service_id
          office_template {
            id
            office_id
            service_type_id
            headline
            description
            office_service_media {
              id
              office_template_id
              media_url
              media_new_url
              media_type
            }
          }
          office_service {
            id
            office_template_id
            name
            description
            image
            price
            is_use
            is_free
            free_text
            external_url
          }
        }
      }
    }
  }
`;

export const FIRST_AGREEMENT = gql`
  query propertyAgreementDetails($propertyId: Int!, $ownerNo: Int!) {
    property_agreement_details(property_id: $propertyId, owner_number: $ownerNo) {
      property_seller {
        id
        companyName
        firstName
        lastName
        mobilePhone
        privatePhone
        workPhone
        email
        address
        postalAddress
        organisationNumber
        postalCode
        city
        is_primary_seller
      }

      property_remuneration {
        id
        property_id
        usable_service_id
        usable_service_class
        price
        quantity
        usableService {
          id
          cost_type
          hours
          name
        }
      }
      property {
        id
        seller_id
        agent_id
        office_id
        estate_id
        department_id
        construction_year
        plot_size
        apartment_no
        street_adress
        zipcode
        city
        knr
        gnr
        bnr
        client_claim
        client_married_partner
        client_partner_both_sign
        contract_apply_housing
        contract_assignment
        name_of_brokerage
        political_person
        name_of_brokerage
        political_person
        fnr
        snr
        sales_warranty_status
        property_agent {
          id
          property_id
          title
          name
          mobilePhone
          email
        }
        ownpart
        estate_type_id
        estate_type
        property_preference {
          id
          percentage
          fix_price
          preferable_price
          rebate_amount
        }
        agent {
          id
          first_name
          last_name
          address
          email
          mobile
          city
          user_detail {
            user_id
            id
            education
            about
            cv_url
            status
          }
        }
      }
      total_owners

      owner_number
      property_service {
        id
        property_id
        usable_service_id
        usable_service_class
        price
        quantity
        usableService {
          name
          is_free
          free_text
        }
      }
      office_opprag_agreement {
        id
        office_id
        oppdrag_staker
        Oppgjor_sforetak
        financial_settlement
        settlement_employees
        hourly_rate
        time_spent
        hourly_remuneration
        other_remuneration
        sales_warranty_yes
        sales_warranty_no
        status
      }
    }
  }
`;

export const PROPERTY_DETAILS = gql`
  query befaring_property_detail($id: Int!) {
    befaring_property_detail(id: $id) {
      id
      department_id
      construction_year
      plot_size
      apartment_no
      street_adress
      zipcode
      city
      knr
      gnr
      bnr
      fnr
      snr
      ownpart
      ownership
      total_owner
    }
  }
`;

export const SEND_OFFER_DETAIL = gql`
  query send_offer_detail($id: Int!) {
    send_offer_detail(id: $id) {
      id
      seller_id
      agent_id
      office_id
      estate_id
      department_id
      construction_year
      plot_size
      apartment_no
      street_adress
      zipcode
      city
      property_inspection {
        id
        property_id
        template_id
        start_date
        start_time
        template_type
      }
      property_preference {
        id
        property_id
        preferable_price
        percentage
        fix_price
        rebate_amount
      }
      office {
        id
        name
        question_to_ask {
          id
          headline
          image
          description
        }
        after_product_gallery {
          id
          office_id
          headline
          AfterProductGalleryImages {
            id
            product_gallery_id
            url
          }
        }
        about_w {
          id
          office_id
          headline
          image
          description
        }
        preparation {
          id
          office_id
          headline
          image
          description
        }
        befaringsbekreftelse {
          description
          office_id
        }
        product_gallery {
          id
          office_id
          headline
          ProductGalleryImages {
            id
            product_gallery_id
            url
          }
        }
      }
      sendOffer {
        id
        headline
        agent_id
        description
        is_confirmation_email
        is_offer_email
      }
      property_seller {
        id
        companyName
        lastName
        firstName
        mobilePhone
        email
        is_primary_seller
        socialSecurity
      }
      knr
      gnr
      bnr
      fnr
      snr
      ownpart
      estate_type_id
      estate_type

      agent {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        agentBefaringsbekreftelse {
          id
          agent_id
          description
        }
        agentProductGallery {
          id
          agent_id
          id
          AgentProductGalleryImages {
            id
            agent_product_gallery_id
            url
          }
        }
        agentAfterProductGallery {
          id
          agent_id
          headline
          AgentAfterGalleryMedia {
            id
            agent_product_gallery_id
            url
          }
        }
        office {
          id
          image_url
          city
          logo_url
          product_gallery {
            id
            office_id
            headline
            ProductGalleryImages {
              id
            }
          }
          name
        }
        zipcode
        status
        title
        user_media {
          id
          title
          redirect_url
          media_url
        }
        user_detail {
          user_id
          id
          education
          about
          cv_url
          status
        }
        office {
          name
        }
        user_document {
          id
          user_id
          doc_url
          doc_type
        }
        feedback {
          id
          user_id
          rating
          feedback
          given_by
        }
      }
    }
  }
`;

export const SEND_OFFER_ABOUT_W = gql`
  query send_offer_detail($id: Int!) {
    send_offer_detail(id: $id) {
      id
      seller_id
      agent_id
      office_id
      estate_id
      department_id
      construction_year
      plot_size
      apartment_no
      street_adress
      zipcode
      city
      property_inspection {
        id
        property_id
        template_id
        start_date
        start_time
        template_type
      }
      property_preference {
        id
        property_id
        preferable_price
        percentage
        fix_price
        rebate_amount
      }
      office {
        id
        name
        about_w {
          id
          office_id
          headline
          image
          description
        }
        product_gallery {
          id
          office_id
          headline
          ProductGalleryImages {
            id
            product_gallery_id
            url
          }
        }
      }
      sendOffer {
        id
        headline
        agent_id
        description
      }
      property_seller {
        id
        companyName
        lastName
        firstName
        mobilePhone
        email
        is_primary_seller
      }
      knr
      gnr
      bnr
      fnr
      snr
      ownpart
      estate_type_id
      estate_type

      agent {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        agentBefaringsbekreftelse {
          id
          agent_id
          description
        }
        agentProductGallery {
          id
          agent_id
          id
          AgentProductGalleryImages {
            id
            agent_product_gallery_id
            url
          }
        }
        office {
          id
          image_url
          city
          logo_url
          product_gallery {
            id
            office_id
            headline
            ProductGalleryImages {
              id
            }
          }
          name
        }
        zipcode
        status
        title
        user_media {
          id
          title
          redirect_url
          media_url
        }
        user_detail {
          user_id
          id
          education
          about
          cv_url
          status
        }
        office {
          name
        }
        user_document {
          id
          user_id
          doc_url
          doc_type
        }
        feedback {
          id
          user_id
          rating
          feedback
          given_by
        }
      }
    }
  }
`;

export const SEND_OFFER_QUESTION_ASK = gql`
  query send_offer_detail($id: Int!) {
    send_offer_detail(id: $id) {
      id
      seller_id
      agent_id
      office_id
      estate_id
      department_id
      construction_year
      plot_size
      apartment_no
      street_adress
      zipcode
      city
      property_inspection {
        id
        property_id
        template_id
        start_date
        start_time
        template_type
      }
      property_preference {
        id
        property_id
        preferable_price
        percentage
        fix_price
      }
      office {
        id
        name
        question_to_ask {
          id
          headline
          image
          description
        }
        befaringsbekreftelse {
          description
          office_id
        }
        product_gallery {
          id
          office_id
          headline
          ProductGalleryImages {
            id
            product_gallery_id
            url
          }
        }
      }
      sendOffer {
        id
        headline
        agent_id
        description
      }
      property_seller {
        id
        companyName
        lastName
        firstName
        mobilePhone
        email
        is_primary_seller
      }
      knr
      gnr
      bnr
      fnr
      snr
      ownpart
      estate_type_id
      estate_type

      agent {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        agentBefaringsbekreftelse {
          id
          agent_id
          description
        }
        agentProductGallery {
          id
          agent_id
          id
          AgentProductGalleryImages {
            id
            agent_product_gallery_id
            url
          }
        }
        office {
          id
          image_url
          city
          logo_url
          product_gallery {
            id
            office_id
            headline
            ProductGalleryImages {
              id
            }
          }
          name
        }
        zipcode
        status
        title
        user_media {
          id
          title
          redirect_url
          media_url
        }
        user_detail {
          user_id
          id
          education
          about
          cv_url
          status
        }
        office {
          name
        }
        user_document {
          id
          user_id
          doc_url
          doc_type
        }
        feedback {
          id
          user_id
          rating
          feedback
          given_by
        }
      }
    }
  }
`;

export const SEND_OFFER_PREPARATION = gql`
  query send_offer_detail($id: Int!) {
    send_offer_detail(id: $id) {
      id
      seller_id
      agent_id
      office_id
      estate_id
      department_id
      construction_year
      plot_size
      apartment_no
      street_adress
      zipcode
      city
      property_inspection {
        id
        property_id
        template_id
        start_date
        start_time
        template_type
      }
      property_preference {
        id
        property_id
        preferable_price
        percentage
        fix_price
      }
      office {
        id
        name
        preparation {
          id
          office_id
          headline
          image
          description
        }
        befaringsbekreftelse {
          description
          office_id
        }
        product_gallery {
          id
          office_id
          headline
          ProductGalleryImages {
            id
            product_gallery_id
            url
          }
        }
      }
      sendOffer {
        id
        headline
        agent_id
        description
      }
      property_seller {
        id
        companyName
        lastName
        firstName
        mobilePhone
        email
        is_primary_seller
      }
      knr
      gnr
      bnr
      fnr
      snr
      ownpart
      estate_type_id
      estate_type

      agent {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        agentBefaringsbekreftelse {
          id
          agent_id
          description
        }
        agentProductGallery {
          id
          agent_id
          id
          AgentProductGalleryImages {
            id
            agent_product_gallery_id
            url
          }
        }
        office {
          id
          image_url
          city
          logo_url
          product_gallery {
            id
            office_id
            headline
            ProductGalleryImages {
              id
            }
          }
          name
        }
        zipcode
        status
        title
        user_media {
          id
          title
          redirect_url
          media_url
        }
        user_detail {
          user_id
          id
          education
          about
          cv_url
          status
        }
        office {
          name
        }
        user_document {
          id
          user_id
          doc_url
          doc_type
        }
        feedback {
          id
          user_id
          rating
          feedback
          given_by
        }
      }
    }
  }
`;

export const OFFER_LISTING = gql`
  query offer_listing($id: Int!) {
    offer_listing(id: $id) {
      remunerations {
        id
        name
        description
        image
        icon
        price
        cost_type
        remuneration_feature {
          id
          remuneration_id
          name
          icon
          status
        }
        usableServiceClass {
          id
          property_id
          usable_service_id
          usable_service_class
          price
          quantity
        }
      }
      services {
        id
        name
        purchased_office_template {
          id
          office_id
          service_type_id
          headline
          description
          purchased_office_services {
            id
            office_template_id
            name
            description
            image
            price
            is_use
            is_free
            free_text
            service_selected
            service_selected_price
          }
        }
      }
      property_utlegg {
        id
        property_id
        name
        price
        type
        status
        usableServiceClass {
          id
          property_id
          usable_service_id
          usable_service_class
          price
          quantity
        }
      }
    }
  }
`;

export const TIMELINE_LIST = gql`
  query inspection_timeline_list($id: Int!, $status: Int!) {
    inspection_timeline_list(property_id: $id, status: $status) {
      id
      property_id
      timeline_id
      completed_date
      service_type
      timeline {
        id
        name
        icon
        link
        description
      }
    }
  }
`;

export const PHOTOS = gql`
  query photos($propertyId: Int!) {
    moovin_photo_photos(property_id: $propertyId) {
      id
      order_photo_id
      property_id
      photo_url
      created_at
      updated_at
      comments {
        id
        order_photo_photos_id
        comment
        created_at
      }
    }
  }
`;
export const AGENT_DETAILS = gql`
  query agentDetails($id: Int!) {
    agent_detail(id: $id) {
      id
      title
      first_name
      last_name
      email
      address
      mobile
      office_id
      profile_image
      city
      zipcode
      is_in_admin_office
      office {
        id
        name
      }
      user_detail {
        id
        user_id
        education
        about
        cv_url
      }
      user_document {
        id
        user_id
        doc_url
        doc_type
      }
      feedback {
        id
        rating
        feedback
        given_by
      }
      user_media {
        id
        user_id
        title
        redirect_url
        media_url
        status
      }
      agentBefaringsbekreftelse {
        description
      }
    }
  }
`;

export const FINAL_SUBMIT_AGREEMENT = gql`
  query finalSubmit($email_verify: Int!, $property_id: String!) {
    finalGetStartedSubmit(email_verify: $email_verify, propertyId: $property_id) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const FIRST_SELLER_SUBMIT = gql`
  query firstSellerSubmit($input: sellerSignupInput) {
    first_seller_submit(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const AGENT_DASHBOARD = gql`
  query dashboard_stat {
    dashboard_stat {
      sale {
        agent {
          month
          quarter
          year
        }
        company {
          month
          quarter
          year
        }
      }
      inspection {
        agent {
          month
          quarter
          year
        }
        company {
          month
          quarter
          year
        }
      }
    }
  }
`;

export const AGENT_CALENDAR_EVENT = gql`
  query agentCalendarEvent {
    agent_calendar_event {
      id
      property_id
      created_user_id
      title
      description
      start_datetime
      end_datetime
      event_type
      user_type
    }
  }
`;

export const AGENT_CALENDAR_PROPERTIES = gql`
  query agentProperties {
    agent_proeprties {
      id
      oppdrags_nummer
      street_adress
      city
      zipcode
    }
  }
`;

export const BEFARING_PACKAGES = gql`
  query befaringPackages($propertyId: Int!) {
    befaring_packages(property_id: $propertyId) {
      id
      office_id
      name
      price
      description
      image_url
      property_package {
        id
        property_id
        marketing_package_id
        price
      }
      marketing_package_service {
        id
        marketing_package_id
        office_template_id
        office_service_id
        office_service {
          id
          name
          description
          image
          icon
          price
          is_use
          is_free
          free_text
          external_url
        }
      }
    }
  }
`;

export const DS_HISTORY = gql`
  query dsHistory($input: DsHistoryInput!) {
    ds_history(input: $input) {
      data {
        id
        vitec_prop_id
        oppdragsnummer
        pdf_url
        created_at
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastPage
        lastItem
        perPage
        total
      }
    }
  }
`;

export const BEFARING_NOTES = gql`
  query befaringNotes($inspectionId: Int!) {
    save_befaring_note(property_inspection_id: $inspectionId) {
      id
      property_inspection_id
      image
    }
  }
`;

export const GET_PROPERTY_DETAIL = gql`
  query property($id: Int!) {
    property(id: $id) {
      id
      orderstyling {
        id
        order_date
        earliest_start_time
        latest_start_time
        styling_type {
          id
          name
        }
      }
      order_photo {
        id
        order_date
        earliest_start_time
        latest_start_time
      }
      orderrate {
        id
        order_date
        earliest_start_time
        latest_start_time
      }
    }
  }
`;

export const SELLER_PHOTO_SERVICE = gql`
  query purchased_services($status: Int!, $propertyId: Int!, $serviceCategory: ServiceCatrgory) {
    purchased_services(
      status: $status
      property_id: $propertyId
      service_category: $serviceCategory
    ) {
      service_types {
        id
        name
        image
        sub_category
        office_template {
          id
          office_id
          service_type_id
          headline
          description
        }
        vendor_services {
          id
          vendor_id
          service_type_id
          name
          description
          image
          icon
          price
          is_use
          is_free
          free_text
          external_url
          service_selected {
            id
            property_id
            usable_service_id
            usable_service_class
            price
            quantity
          }
        }
      }
      purchased_services {
        id
        name
        image
        purchased_office_template {
          id
          office_id
          service_type_id
          headline
          description
          description
          purchased_office_services {
            id
            office_template_id
            name
            description
            image
            icon
            price
            is_use
            is_free
            free_text
            external_url
            service_selected {
              id
              property_id
              usable_service_id
              usable_service_class
              price
              quantity
            }
          }
        }
      }
    }
  }
`;

export const DS_UNITS = gql`
  query ds_units($input: UnitInput!) {
    ds_units(input: $input) {
      data {
        id
        estateId
        oppdragsnummer
        noOfBedRooms
        floor
        apartmentNumber
        bidLink
        vitecPropertyEstateSize {
          primaryRoomArea
          usableArea
        }
        vitecPropertyEstatePrice {
          priceSuggestion
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;
