/* eslint-disable @typescript-eslint/no-unused-vars */
import { UploadOutlined } from '@ant-design/icons';
import { Button,Spin, Col, Form, Input, notification, Row, Typography, Upload } from 'antd';
import React, { useState } from 'react';
import { Mutation, Query } from 'react-apollo';
// import { CaretDownFilled } from '@ant-design/icons';
// import { Mutation } from 'react-apollo';
// import { from } from 'apollo-link';
import { formatMessage } from 'components/src/utils';
import {
  ADMIN_UPDATE_MOVING_GUIDE_LINKS,
  ADMIN_UPLOAD_SEE_MOVIE,
} from '../../../../../mutation/AdminMutation';
import {
  ADMIN_MOOVIN_GUIDE_MANAGE_LINKS,
  ADMIN_MOOVIN_GUIDE_MANAGE_LINK_SEE_MOVIE,
} from '../../../../../query/AdminQuery';
import {STORAGE_URL} from '../../../../utils/constant';
const { Title } = Typography;
import {useHistory} from "react-router";
// const serviceoptionlist = [
//   { label: 'Moovin Agency', value: 1 },
//   { label: 'Book the Internet', value: 2 },
//   { label: 'Order Tv Package', value: 3 },
//   { label: 'Order A Home Alarm', value: 4 },
// ];
const Links = [];
let history;
const ManageLinks = () => {
  let router=useHistory();
  const [orderData, setOrderData] = useState([]);

  const [successFlag, setSuccessFlag] = useState();
  const [successSeeVideo, setSuccessSeeVideo] = useState();
  const [seeVideo, setseeVideo] = useState([]);

  // const onFinish = (values, updateManageLinks) => {
  const onFinish = (values, updateManageLinks) => {
    console.log('takeAimFullObject:', values.moovingGuideDetails[0][0].link_url);
    const temppArr = [];
    orderData.map(
        (i, index) =>
            i.movingGuideLink.length > 0 &&
            i.movingGuideLink.map((j, inner_index) => {
              temppArr.push({
                id: j?.id,
                moving_type: j.moving_type,
                moving_guide_id: j?.id,
                link_url: values.moovingGuideDetails[index][inner_index].link_url,
              });
            }),
    );

    console.log('temppArr:', temppArr);
    console.log('Success:', values);
    // console.log('Update method:>', updateManageLinks);

    console.log('arr->', [values.moovingGuideDetails]);
    updateManageLinks({
      variables: {
        input: {
          links: temppArr,
        },
      },
    }).then(({ data }) => {
      if (data) {
        console.log('Data submitted===>>>', data);
        setSuccessFlag(true);
        if (data && data.add_manage_links && data.add_manage_links.meta) {
          notification.success({
            message: data.add_manage_links.meta.message,
            description: '',
            duration: 3,
          });
          router.push(`/admin/dashboard`);
        }
      }
    });
    console.log('BELOW MUTAION');
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
      <div className="contentshadow">
        <div className="text-primary main">
          <Row>
            <Col xs={24}>
              <Title level={3} className="text-primary">
                {formatMessage({
                  id: 'component.managelinks.title',
                })}
              </Title>
              <p>Her håndterer du linkene til de forskjellige knappen i Flytteguiden</p>
              <Title level={4} className="text-primary">
                {formatMessage({
                  id: 'component.managelinks.webmeglingmooving',
                })}
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Query
                  query={ADMIN_MOOVIN_GUIDE_MANAGE_LINK_SEE_MOVIE}
                  variables={{
                    status: 1,
                  }}
                  fetchPolicy="cache-and-network"
                  onCompleted={responseData => {
                    if (responseData && responseData.see_movie_detail) {
                      console.log('responseData===============?>>', responseData.link_url);
                      setseeVideo([responseData.see_movie_detail]);
                    }
                    return '';
                  }}
              />

              <Query
                  query={ADMIN_MOOVIN_GUIDE_MANAGE_LINKS}
                  variables={{
                    status: 1,
                  }}
                  fetchPolicy="cache-and-network"
                  onCompleted={responseData => {
                    if (responseData) {
                      setOrderData(
                          responseData.manage_link_moving_guide_list.filter(i => i.action === 'LINK'),
                      );
                      const sortthrow = responseData.manage_link_moving_guide_list.filter(
                          i => i.action === 'LINK',
                      );

                      console.log('OrderData:>>', orderData);

                      console.log(
                          responseData.manage_link_moving_guide_list.filter(i => i.action === 'LINK'),
                      );
                    }
                    return '';
                  }}
              >
                {({ data, error, loading }) => {
                  console.log(error);
                  if (data && !loading && !error) {
                    if (data && data.manage_link_moving_guide_list) {
                      const sortthrow = data.manage_link_moving_guide_list.filter(
                          i => i.action === 'LINK',
                      );

                      console.log('insodwe:', sortthrow);

                      return (
                          <Mutation mutation={ADMIN_UPDATE_MOVING_GUIDE_LINKS}>
                            {updateManageLinks => (
                                <>
                                  {console.log('length:', seeVideo.length <= 0)}

                                  <Form
                                      layout="vertical"
                                      className="user-forms"
                                      name="managelinks"
                                      colon={false}
                                      // eslint-disable-next-line no-undef
                                      onFinish={values => onFinish(values, updateManageLinks)}
                                      onFinishFailed={onFinishFailed}
                                  >
                                    <Row gutter={24}>
                                      <Col xs={24} lg={16}>
                                        <Mutation mutation={ADMIN_UPLOAD_SEE_MOVIE}>
                                          {uploadSeeMovie => (
                                              <Form.Item
                                                  name="seemovie"
                                                  label={formatMessage({
                                                    id: 'component.managelinks.seemovie',
                                                  })}
                                                  rules={[
                                                    {
                                                      required: false,
                                                      message: `Vennligst fyll inn ${formatMessage({
                                                        id: 'component.managelinks.seemovie',
                                                      })}`,
                                                    },
                                                  ]}
                                              >
                                                <Upload
                                                    showUploadList={false}
                                                    accept=".mp4"
                                                    fileList={false}
                                                    onChange={({ fileList }) => {
                                                      console.log('video-->', fileList[0]);
                                                      const file = [];
                                                      file.push(fileList[0]);

                                                      setseeVideo(file);
                                                      uploadSeeMovie({
                                                        variables: {
                                                          see_movie_video: file[0].originFileObj,
                                                        },
                                                      }).then(({ data }) => {
                                                        if (data) {
                                                          console.log('Data submitted===>>>', data);
                                                          if (
                                                              data &&
                                                              data.upload_see_movie &&
                                                              data.upload_see_movie.meta
                                                          ) {
                                                            setSuccessSeeVideo(true);
                                                            notification.success({
                                                              message: data.upload_see_movie.meta.message,
                                                              description: '',
                                                              duration: 3,
                                                            });
                                                            console.log('successFlag=>', successFlag);
                                                            // if (successFlag) {
                                                            // router.push(`/admin/dashboard`);
                                                            // }
                                                          }
                                                        }
                                                      });
                                                    }}
                                                >
                                                  <Button>
                                                    {formatMessage({ id: 'component.form.upload' })}{' '}
                                                    <UploadOutlined />
                                                  </Button>
                                                </Upload>
                                              </Form.Item>
                                          )}
                                        </Mutation>
                                        {seeVideo.length !== 0 && seeVideo.length >= 0 && (
                                            <Row gutter={32}>
                                              <Col xs={24} lg={16}>
                                                {seeVideo.length >= 0 &&
                                                seeVideo.map(item => (
                                                    <div className="default_list mt-3 mb-3">
                                                      <div>
                                                        <i className="icon-attachment" />
                                                        <span className="ml-2">
                                                  {item?.originFileObj ? (
                                                      <span>{item.name}</span>
                                                  ) : (
                                                      <a
                                                          href={`${STORAGE_URL}${item?.link_url}`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                      >
                                                        {item?.title}
                                                      </a>
                                                  )}
                                                </span>
                                                      </div>
                                                      <div className="upload-media-delete">
                                                        <i
                                                            className="icon-delete"
                                                            onClick={() => setseeVideo([])}
                                                        />
                                                      </div>
                                                    </div>
                                                ))}
                                              </Col>
                                            </Row>
                                        )}
                                      </Col>

                                      <Col lg={24}>
                                        {sortthrow &&
                                        sortthrow.map((item, index) => (
                                            <Row gutter={32}>
                                              <Col xs={24}>
                                                <Row>
                                                  <Col lg={24}>
                                                    <Title level={4} className="text-primary">
                                                      {item.name &&
                                                      item.movingGuideLink.length > 0 &&
                                                      item.name}
                                                    </Title>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col xs={24} lg={16} md={16}>
                                                {item.movingGuideLink.length > 0 &&
                                                item.movingGuideLink.map((subItemx, innerIndex) => (
                                                    <>
                                                      <Form.Item
                                                          initialValue={subItemx.link_url}
                                                          name={[
                                                            'moovingGuideDetails',
                                                            `${index}`,
                                                            `${innerIndex}`,
                                                            'link_url',
                                                          ]}
                                                          label={subItemx.title}
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message: `Vennligst fyll inn ${formatMessage({
                                                                id: 'component.managelinks.finnosquare',
                                                              })}`,
                                                            },
                                                          ]}
                                                      >
                                                        <Input
                                                            placeholder={`${formatMessage({
                                                              id: 'component.managelinks.addlink',
                                                            })}`}
                                                            size="large"
                                                        />
                                                      </Form.Item>
                                                    </>
                                                ))}
                                              </Col>
                                            </Row>
                                        ))}
                                      </Col>
                                    </Row>

                                    <Row className="mt-3">
                                      <Col xs={24}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={!orderData}
                                            className="btn-secondary buttons admin-forms-btn"
                                            size="large"
                                            // onClick={() => setaddVendorSuccessful(true)}
                                        >
                                          {formatMessage({
                                            id: 'component.form.add',
                                          })}
                                        </Button>
                                        <Button
                                            className="text-primary admin-forms-btn"
                                            style={{ marginLeft: 8 }}
                                            size="large"
                                            onClick={() => router.goBack()}
                                        >
                                          {formatMessage({
                                            id: 'component.form.cancel',
                                          })}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </>
                            )}
                          </Mutation>
                      );
                    }
                    return (
                        <div className="loader-wrapper">
                          <LoadingOutlined />
                        </div>
                    );
                  }
                  if (loading && !error) {
                    return (
                        <div className="loader-wrapper">
                          <Spin />
                        </div>
                    );
                  }
                  if (!loading && error) {
                    return <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>;
                  }
                  return <React.Fragment />;
                }}
              </Query>
            </Col>
          </Row>
        </div>
      </div>
  );
};
export default ManageLinks;
