import {StyleSheet} from 'react-native';
import {LocalColor} from "./Color";

const Style = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff"
    },
    safeArea: {
        flex: 1,
    },
    flex: {
        flex: 1,
    },
    content_center: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    scrollCont: {
        paddingBottom: 80
    },
    default_elevation: {
        backgroundColor: LocalColor.WHITE,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,

        elevation: 4,
    },
    blankHeader: {
        backgroundColor: LocalColor.WHITE,
        shadowColor: LocalColor.BLACK,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0,
        shadowRadius: 0,
        elevation: 0,
    },
    loader: {
        marginTop: 20
    },
    dot: {
        end: 12,
        top: 12,
        position: 'absolute',
        backgroundColor: LocalColor.RED,
        borderWidth: 1,
        borderColor: LocalColor.PRIMARY,
        borderRadius: 4,
        height: 8,
        width: 8
    }
});

export default Style;
