import gql from 'graphql-tag';

export const INSPECTION_ORDER_LIST = gql`
query inpectionOrderList($input: OrderListInput!) {
    inspection_orders(input: $input) {
      data {
        id
        property_id
        inspection_id
        order_date
        earliest_start_time
        latest_start_time
        comment
        order_status
        property {
          id
          seller_id
          oppdrags_nummer
          address
          Knr
          Gnr
          Bnr
          Snr
          seller {
            id
            first_name
            last_name
            email
            address
            mobile
            profile_image
            city
            zipcode
            status
          }
          orderrate {
            id
            property_id
            takst_id
            order_date
            earliest_start_time
            latest_start_time
            comment
            order_status
          }
        }
        inspection {
          id
          first_name
          last_name
          email
          address
          mobile
          profile_image
          city
          zipcode
          status
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }`;

export const INSPECTION_ORDER_INFORMATION = gql`
query inspectionOrderInfo($id: ID!) {
  inspection_order(id: $id) {
    orderbookinspection {
      id
      property_id
      inspection_id
      order_date
      order_status
      earliest_start_time
      latest_start_time
      comment
      property {
        oppdrags_nummer
        address
        seller {
          first_name
          last_name
          email
          mobile
        }
      }
    }
  }
}`;
