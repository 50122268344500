import {Col, Layout, Menu, Row} from 'antd';
import React from 'react';
import {Link} from 'react-router-dom';
// import youtube from '../../../../assets/social/youtube.svg';
import './userfooter.scss';
import {getWmeglingPremiumLogo} from "../../../../utils/utils";
import moment from "moment";

const {Footer} = Layout;

export const UserFooter = () => (
    <Footer className="app-footer ds_footer">
        <Row>
            <Col xs={24} lg={24} className="gap">
                <div className="admin-footer-wrapper">
                    <div className="logo-box">
                        <a href="https://weiendomsmegling.no/">
                            <img src={getWmeglingPremiumLogo()} className="footer-logo" alt="W Eiendomsmegling"/>
                        </a>
                    </div>
                    <div className="menu_wrapper">
                        <div className="right-box">
                            <Row>
                                <Col xs={0} lg={24}>
                                    <Menu
                                        // selectedKeys={[current]}
                                        // onClick={e => {
                                        //   this.setState({ current: e.key });
                                        // }}
                                        mode="horizontal"
                                        className="center-menu-bar"
                                    >
                                        <Menu.Item key="/properties">
                                            <Link to="#">W EIENDOMSMEGLING</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/finance">
                                            <a href="https://weiendomsmegling.no/karriere.html">karriere</a>
                                        </Menu.Item>
                                        <Menu.Item key="/contact">
                                            <a href="https://weiendomsmegling.no/personvern.html#PERSONVER">Personvern</a>
                                        </Menu.Item>
                                        <Menu.Item key="/sign-in">
                                            <a href="https://weiendomsmegling.no/personvern.html#BRUKERVILKAR">
                                                Brukervilkår
                                            </a>
                                        </Menu.Item>
                                    </Menu>
                                </Col>
                                <Col xs={24} lg={0}>
                                    <ul className="mobile-footer">
                                        <li>
                                            <a href="#">W EIENDOMSMEGLING</a>
                                        </li>
                                        <li>
                                            <a href="https://weiendomsmegling.no/karriere.html">KARRIERE</a>
                                        </li>
                                        <li>
                                            <a href="https://weiendomsmegling.no/personvern.html#PERSONVER">Personvern</a>
                                        </li>
                                        <li>
                                            <a href="https://weiendomsmegling.no/personvern.html#BRUKERVILKAR">
                                                Brukervilkår
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={24} className="gap small-btm">
                <div>&copy; W Eiendomsmegling Technology AS {moment().year()}</div>
            </Col>
        </Row>
    </Footer>
);

export default UserFooter;
