import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, TouchableOpacity, View} from 'react-native'
import {CustomButton, Hr, InputField, Label, RadioGroup} from "components/src/components";
//Utils
import {Color, CommonStyle, Constants, Icon, Strings, ThemeUtils, validation} from "components/src/utils";
import styles from "./styles";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {BUYER_OWNERSHIP_INFO} from "../../../../../api/buyerQuery";
import {BUYER_UPDATE_OWNERSHIP_INFO} from "../../../../../api/buyerMutation";
import {connect} from "react-redux";


const BUYER_INPUTS = [{
    id: 'firstName',
    title: Strings.firstName,
    placeholder: Strings.firstName,
    type: 'text',
    validationType: 'name'
}, {
    id: 'lastName',
    title: Strings.lastName,
    placeholder: Strings.lastName,
    type: 'text',
    validationType: 'name'
}, {
    id: "personnummer",
    title: "Personnummer",
    placeholder: "Personnummer",
    type: 'text',
    validationType: 'personnummer'
}, {
    id: 'phone',
    title: Strings.phone,
    placeholder: Strings.phone,
    type: 'number',
    validationType: 'phoneNo'
}, {
    id: 'email',
    title: Strings.email,
    placeholder: Strings.email,
    type: 'email',
    validationType: 'email'
}, {
    id: 'interest',
    title: "Eierandel",
    placeholder: "Eierandel",
    type: 'text',
    disabled:true
    // validationType: 'eierandel'
}];

const COMPANY_INPUTS = [{
    id: "companyName",
    title: "Selskapsnavn",
    placeholder: "Selskapsnavn",
    type: 'text',
    validationType: 'name'
}, {
    id: "organisationNumber",
    title: "Organisasjonsnummer",
    placeholder: "Organisasjonsnummer",
    type: 'number',
    validationType: 'organizationNo'
}, {
    id: "contactPerson",
    title: "Navn på kontaktperson",
    placeholder: "Navn på kontaktperson",
    type: 'text',
    validationType: 'name'
}, {
    id: "contactPersonSSNNo",
    title: "Fødsel og personnummer kontaktperson",
    placeholder: "Fødsel og personnummer kontaktperson",
    type: 'number',
    validationType: 'securityNo'
}, {
    id: 'phone',
    title: "Mobil",
    placeholder: "Mobil",
    type: 'number',
    validationType: 'phoneNo'
}, {
    id: 'email',
    title: "Epost",
    placeholder: "Epost",
    type: 'email',
    validationType: 'email'
}, {
    id: 'interest',
    title: "Eierandel",
    placeholder: "Eierandel",
    type: 'text',
    disabled:true,
    // validationType: 'eierandel'
}];

function OwnerShip(props) {

    const [buyer1, setBuyer1] = useState('');
    const [buyer2, setBuyer2] = useState('');
    const [interest1, setInterest1] = useState('');
    const [interest2, setInterest2] = useState('');

    const [errInterest1, setErrInterest1] = useState('');
    const [errBuyer1, setErrBuyer1] = useState(null);

    const [buyer, setBuyer] = useState([{
        isBuyer: true,
        inputs: BUYER_INPUTS
    }]);

    const [buyerValues, setBuyerValues] = useState([{
        isBuyer: true,
        value: {}
    }]);
    const [buyerErrors, setBuyerErrors] = useState([{}]);


    const {loading, error, data} = useQuery(BUYER_OWNERSHIP_INFO, {
        fetchPolicy:'network-only',
        variables: {
            id: props.property?.property_id,
            status: 1
        }
    });

    useEffect(() => {
        if (data?.buyer_ownership_info) {
            let buyerValues = [];
            buyerValues.push({
                isBuyer: data?.buyer_ownership_info.contactType === 0,
                value: data?.buyer_ownership_info?.contactType === 0 ? {
                    firstName: data?.buyer_ownership_info?.first_name,
                    lastName: data?.buyer_ownership_info?.last_name,
                    personnummer: data?.buyer_ownership_info?.personnummer,
                    phone: data?.buyer_ownership_info?.phone,
                    email: data?.buyer_ownership_info?.email,
                    interest: data?.buyer_ownership_info?.eierandel,
                    contactId: data?.buyer_ownership_info?.contactId,
                    id: data?.buyer_ownership_info?.id,
                } : {
                    companyName: data?.buyer_ownership_info?.companyName,
                    organisationNumber: data?.buyer_ownership_info?.organisationNumber,
                    phone: data?.buyer_ownership_info?.phone,
                    email: data?.buyer_ownership_info?.email,
                    contactId: data?.buyer_ownership_info?.contactId,
                    id: data?.buyer_ownership_info?.id,
                    contactPersonSSNNo: data?.buyer_ownership_info?.ssn_number,
                    contactPerson: data?.buyer_ownership_info?.contact_person,
                    interest: data?.buyer_ownership_info?.eierandel,
                }
            });
            buyerValues = [
                ...buyerValues,
                ...data?.buyer_ownership_info?.coBuyerInfo?.map(item => {
                    return {
                        isBuyer: item.contactType === 0,
                        value: item.contactType === 0 ? {
                            firstName: item.first_name,
                            lastName: item.last_name,
                            personnummer: "",
                            phone: item.phone,
                            email: item.email,
                            interest: item?.eierandel,
                            ...item,
                        } : {
                            companyName: item.companyName,
                            organisationNumber: item.organisationNumber,
                            phone: item.phone,
                            email: item.email,
                            contactPersonSSNNo: item?.ssn_number,
                            contactPerson:item?.contact_person,
                            interest: item?.eierandel,
                            ...item,
                        }
                    }
                })
            ]
            let updatedBuyer = buyerValues.map(item => ({
                isBuyer: item.isBuyer,
                inputs: item.isBuyer ? BUYER_INPUTS : COMPANY_INPUTS
            }));
            setBuyerErrors(new Array(buyerValues.length).fill({}))
            setBuyerValues(buyerValues)
            console.log(buyerValues)
            setBuyer(updatedBuyer)
        }
    }, [data])

    const [updateOwnerInfo, updateOwnerInfoMutation] = useMutation(BUYER_UPDATE_OWNERSHIP_INFO)

    const saveOwnerShipInfo = () => {

        const updatedBuyerErrors = buyerErrors.slice();
        let isError = false;
        buyerValues.map((bankInfo, parentIndex) => {
            console.log(bankInfo)
            for (let i = 0; i < (bankInfo?.isBuyer ? BUYER_INPUTS.length : COMPANY_INPUTS.length); i++) {

                let item = bankInfo?.isBuyer ? BUYER_INPUTS[i] : COMPANY_INPUTS[i];
                let value = bankInfo.value[item.id] ? bankInfo.value[item.id].trim() : '';
                updatedBuyerErrors[parentIndex][item.id] = validation(item.validationType, value);
                console.log(updatedBuyerErrors[parentIndex][item.id], "ERR")
                if (updatedBuyerErrors[parentIndex][item.id]) {
                    isError = true;
                }
            }
        });
        console.log(updatedBuyerErrors, isError, "ERRORS")
        if (isError) {
            setBuyerErrors(updatedBuyerErrors)
        } else {
            let coBuyerRequest = buyerValues.slice(1).map(item => {
                let request = {
                    co_contactType: item.isBuyer ? 0 : 1,
                    co_buyer_email: item?.value?.email,
                    co_buyer_mobile: item?.value?.phone,
                    ownership_interest: `1/${buyerValues.length}`,
                    co_eierandel: `1/${buyerValues.length}`,
                    //co_eierandel: item.value?.interest ?? null
                }
                if (item.value?.id) {
                    request.property_co_buyer_info_id = item.value?.id;
                }
                if (item.isBuyer) {
                    /*
                    person number
                    interest
                    */
                    request = {
                        ...request,
                        co_buyer_first_name: item.value?.firstName,
                        co_buyer_last_name: item.value?.lastName,
                        co_personnummer: item.value?.personnummer,
                    }
                } else {
                    /*
                    contact person
                    contact person SSn
                    interest
                    */
                    request = {
                        ...request,
                        co_companyName: item.value?.companyName,
                        co_buyer_first_name: item.value?.companyName,
                        co_buyer_last_name: null,
                        co_organisationNumber: item.value?.organisationNumber,
                        co_contact_person: item?.value?.contactPerson,
                        co_ssn_number: item.value?.contactPersonSSNNo
                    }
                }

                request.co_contactId = item.value?.contactId ?? null
                request.co_departmentId = item.value?.departmentId ?? null
                request.co_workPhone = item.value?.workPhone ?? null
                request.co_buyer_address = item.value?.address ?? null
                request.co_buyer_zipcode = item.value?.postalCode ?? null
                request.co_buyer_city = item.value?.city ?? null

                return request
            })

            let buyerRequest = {
                property_buyer_info_id: buyerValues[0]?.value?.id,
                first_name: buyerValues[0]?.isBuyer ? buyerValues[0]?.value?.firstName : buyerValues[0]?.value?.companyName,
                last_name: buyerValues[0]?.isBuyer ? buyerValues[0]?.value?.lastName : null,
                ownership_interest: `1/${buyerValues.length}`,
                // eierandel: buyerValues[0]?.value?.interest,
                eierandel: `1/${buyerValues.length}`,
            }
            if (buyerValues[0]?.isBuyer) {
                buyerRequest = {
                    ...buyerRequest,
                    personnummer: buyerValues[0]?.value?.personnummer,
                }
            } else {
                buyerRequest = {
                    ...buyerRequest,
                    contact_person: buyerValues[0]?.value?.contactPerson,
                    ssn_number: buyerValues[0].value?.contactPersonSSNNo
                }
            }
            updateOwnerInfo({
                variables: {
                    input: {
                        prop_buyer_info_id: props.property?.id,
                        buyer_ownership: {
                            primary_buyer: buyerRequest,
                            co_buyer: coBuyerRequest
                        },
                    }
                }
            }).then(() => {

            }).catch(() => {

            })
        }
    }

    const renderAdditionalBuyer = (parentItem, parentIndex) => {
        return (
            <View key={parentIndex.toString()}>
                <Hr/>
                <View style={styles.bankLblCont}>
                    <Label small
                           style={CommonStyle.flex}
                           font_medium>
                        {`${Strings.buyer} ${parentIndex + 1} :`}
                    </Label>
                    {parentIndex !== 0 && !buyerValues[parentIndex]?.value?.id? (
                        <TouchableOpacity activeOpacity={0.5}
                                          onPress={() => removeBuyer(parentIndex)}>
                            <Icon name={'order-cancel'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.RED}/>
                        </TouchableOpacity>) : null}

                </View>
                <Label small
                       font_medium
                       mb={10}>
                    {"Er eier et aksjeselskap?"}
                </Label>
                <RadioGroup changeOnProp
                            selectedOptions={parentItem.isBuyer ? "Nei" : "Ja"}
                            disabled={parentIndex===0}
                            onSelectOption={(val) => {
                                replaceBuyerWithCompany(val === 'Nei' ? BUYER_INPUTS : COMPANY_INPUTS, parentIndex, parentItem.isBuyer,buyerValues[parentIndex]?.value)
                            }}
                            contentStyle={{flexDirection: 'row', marginBottom: 20}}
                            options={Constants.RADIO_OPTIONS}/>
                {parentItem.inputs.map((item, index) => renderBuyerForm(item, index, parentIndex, parentItem.isBuyer))}
            </View>
        )
    }

    const renderBuyerForm = (item, index, parentIndex, isBuyer) => {
        return (
            <InputField key={item.id.toString()}
                        textArea={item.type === 'textArea'}
                        type={item.type}
                        disabled={item.disabled || (parentIndex === 0 && (item.id === "email" || item.id === "phone" || item.id === "personnummer" || item.id === "contactPerson" || item.id === "contactPersonSSNNo" || item.id === "organisationNumber"))}
                        labelProps={{
                            small: true,
                            font_medium: true,
                        }}
                        style={{marginTop: 0}}

                        onChange={(text) => handleBuyerChangeText(text, item.id, parentIndex, isBuyer)}
                        onFocus={() => onBuyerFocus(item.id, parentIndex)}
                        error={buyerErrors[parentIndex][item.id]}
                        value={item.id === "interest" ? `1/${buyer.length}` : buyerValues[parentIndex]?.value[item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };

    const handleBuyerChangeText = (text, fieldId, parentIndex, isBuyer) => {
        let updatedValues = buyerValues.slice();
        updatedValues[parentIndex] = {
            isBuyer: isBuyer,
            value: {
                ...updatedValues[parentIndex].value,
                [fieldId]: text.split(" ").join("")
            }
        };
        setBuyerValues(updatedValues)
    };

    const onBuyerFocus = (id, parentIndex) => {
        let updatedErrors = buyerErrors.slice();
        updatedErrors[parentIndex][id] = null;
        setBuyerErrors(updatedErrors)
    };

    const addAdditionlBuyer = () => {
        const updatedBuyer = buyer.slice();
        const updatedValue = buyerValues.slice();
        const updatedError = buyerErrors.slice();
        updatedValue.push({
            isBuyer: true,
            value: {}
        });
        console.log(updatedValue, "VAL")
        updatedError.push({});
        setBuyerValues(updatedValue)
        setBuyerErrors(updatedError)
        updatedBuyer.push({
            isBuyer: true,
            inputs: BUYER_INPUTS
        });
        console.log(updatedBuyer)
        setBuyer(updatedBuyer)
    };
    const replaceBuyerWithCompany = (inputs, index, isBuyer,item) => {
        const updatedBuyer = buyer.slice();
        const updatedValue = buyerValues.slice();
        const updatedError = buyerErrors.slice();
        console.log("HERE")
        updatedValue[index] = {
            isBuyer: !isBuyer,
            value: {
                id:item.id
            }
        };
        updatedError[index] = {}
        setBuyerValues(updatedValue)
        setBuyerErrors(updatedError)

        updatedBuyer[index] = {
            isBuyer: !isBuyer,
            inputs: inputs
        };
        setBuyer(updatedBuyer)
    };
    const removeBuyer = (index) => {
        const updatedBank = buyer.slice();
        const updatedValue = buyerValues.slice();
        const updatedError = buyerErrors.slice();
        updatedValue.splice(index, 1);
        updatedError.splice(index, 1);
        setBuyerValues(updatedValue)
        setBuyerErrors(updatedError)
        updatedBank.splice(index, 1);
        setBuyer(updatedBank)
    };
    return (

        <View style={styles.stepCont}>
            <Label small
                   mt={15}
                   mb={15}>
                {"Dersom du skal eie boligen sammen med noen, trenger vi å vite disse detaljene. Vennligst oppgi fullt navn på begge eiere og hvordan eierandelen skal fordeles. F.eks ½ på hver dersom dere skal eie en halvpart hver. Eier du den alene skriver du 1/1"}
            </Label>
            {loading ? (<ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>) : (
                <>
                    {/*<InputField labelText={Strings.nameBuyer1}
                                placeholder={Strings.nameBuyer1}
                                onChange={setBuyer1}
                                error={errBuyer1}
                                value={buyer1}
                                type={'text'}
                                onFocus={() => setErrBuyer1(null)}
                                labelProps={{
                                    small: true,
                                    font_medium: true
                                }}/>
                    <InputField labelText={getLabelText('hernia')}
                                placeholder={Strings.ownershipInterestInHernia}
                                onChange={setInterest1}
                                error={errInterest1}
                                value={interest1}
                                type={'number'}
                                onFocus={() => setErrInterest1(null)}
                                labelProps={{
                                    small: true,
                                    font_medium: true
                                }}/>
                    <InputField labelText={Strings.nameBuyer2}
                                placeholder={Strings.nameBuyer2}
                                onChange={setBuyer2}
                                value={buyer2}
                                type={'text'}
                                labelProps={{
                                    small: true,
                                    font_medium: true
                                }}/>
                    <InputField labelText={getLabelText('hernia')}
                                placeholder={Strings.ownershipInterestInHernia}
                                onChange={setInterest2}
                                value={interest2}
                                type={'number'}
                                labelProps={{
                                    small: true,
                                    font_medium: true
                                }}/>*/}
                    {/*{BUYER_INPUTS.map((item, index) => renderBuyerForm(item, index, 0, true))}*/}
                    {buyer.length !== 0 ? (
                        <>
                            {buyer.map(renderAdditionalBuyer)}
                        </>
                    ) : null}
                    <CustomButton mb={10}
                                  mt={10}
                                  title={"Legg til medeier"}
                                  onPress={addAdditionlBuyer}/>
                    <CustomButton mb={10}
                                  loading={updateOwnerInfoMutation.loading}
                                  mt={10}
                                  title={Strings.Save}
                                  onPress={saveOwnerShipInfo}/>
                </>)}

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(OwnerShip);
