import React from 'react'
import {View} from 'react-native'
import {Header} from "components/src/components";
import {IS_WEB, ThemeUtils} from "components/src/utils";
import styles from './styles';
import BrokerMobile from './Mobile';


function Broker(props) {
    const {history, navigation} = props;


    return (
        <View style={styles.container}>
            <Header navigation={IS_WEB ? history : navigation}/>
            <View>
                {ThemeUtils.isTablet() ? null : <BrokerMobile {...props}/>}
            </View>
        </View>

    )
}

export default Broker
