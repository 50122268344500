import React, {useEffect, useState} from "react";
//Components
import {View} from "react-native";
import {CheckBox, CustomTextArea, DatePicker, Label, RadioGroup,} from "components/src/components";
//Utils
import {Constants, DateUtils, Strings} from "components/src/utils";
import styles from "../styles";
import moment from "moment";
import {setSellerInterViewForm} from "components/src/redux/action";
import {connect} from "react-redux";

const Q1 = Strings.secondStep.q1;
const Q2 = Strings.secondStep.q2;
const Q3 = Strings.secondStep.q3;
const Q4 = Strings.secondStep.q4;
const Q5 = Strings.secondStep.q5;
const Q6 = Strings.secondStep.q6;

const FACILITIES = [
    {name: Strings.secondStep.airConditioning, value: "Air Conditioning"},
    {name: Strings.secondStep.alarm, value: "Alarm"},
    {name: Strings.secondStep.resorts, value: "Resorts"},
    {name: Strings.secondStep.balconyTerrace, value: "Balcony/ Terrace"},
    {name: Strings.secondStep.kidFriendly, value: "Kid Friendly"},
    {name: Strings.secondStep.broadband, value: "Broadband"},
    {name: Strings.secondStep.batplass, value: "Batplass"},
    {name: Strings.secondStep.communalWashing, value: "Communal washing"},
    {name: Strings.secondStep.fishing, value: "Fishing"},
    {
        name: `${Strings.secondStep.garage}/${Strings.secondStep.parkingPlace}`,
        value: "Garage/ Parking place",
    },
    {name: Strings.secondStep.guestParking, value: "Guest parking"},
    {name: Strings.secondStep.golfCourse, value: "Golf course"},
    {name: Strings.secondStep.garden, value: "Garden"},
    {name: Strings.secondStep.elevator, value: "Elevator"},
    {name: Strings.secondStep.petsAllowed, value: "Pets allowed"},
    {name: Strings.secondStep.noResidents, value: "No residents"},
    {name: Strings.secondStep.cableTV, value: "Cable TV"},
    {
        name: Strings.secondStep.offWaterAndWasteWater,
        value: "Off. water and wastewater",
    },
    {name: Strings.secondStep.parquet, value: "Parquet"},
    {
        name: `${Strings.secondStep.fireplace}/ ${Strings.secondStep.hearth}`,
        value: "Fireplace/ hearth",
    },
    {
        name: Strings.secondStep.receptionQuietCentral,
        value: "Reception quiet central",
    },
    {name: Strings.secondStep.shoreline, value: "Shoreline"},
    {name: Strings.secondStep.roof, value: "Roof"},
    {name: Strings.secondStep.hiking, value: "Hiking"},
    {name: Strings.secondStep.view, value: "View"},
    {name: Strings.secondStep.expandability, value: "Expandability"},
    {
        name: `${Strings.secondStep.janitorial} / ${
            Strings.secondStep.securityService
            }`,
        value: "Janitorial /security service",
    },
];

function SecondStep(props) {
    const {interViewForm, setForm} = props;

    const [facilities, selectedFacilities] = useState(
        interViewForm.find((item) => item.question_no === 3)?.answer ?? []
    );

    const [activitiesNeeded, setActivityRequired] = useState(
        interViewForm.find((item) => item.question_no === 5)?.answer ?? "Nie"
    );
    const [activityComment, setActivityComment] = useState(
        interViewForm.find((item) => item.question_no === 5)?.comment ?? ""
    );
    const [familyWithKids, setFamilyWithKids] = useState(
        interViewForm.find((item) => item.question_no === 7)?.answer ?? "Ja"
    );
    const [familyWithKidComment, setFamilyWithKidComment] = useState(
        interViewForm.find((item) => item.question_no === 7)?.comment ?? ""
    );

    const [location, setLocationType] = useState(
        interViewForm.find((item) => item.question_no === 4)?.answer ?? ""
    );
    const [closestNeighbours, setClosestNeighbours] = useState(
        interViewForm.find((item) => item.question_no === 6)?.answer ?? ""
    );

    const [midSummerStart, setMidSummerStart] = useState(
        interViewForm.find((item) => item.question_no === 8)?.answer[0]
            ?.Midtsommers[0] !== "Invalid date" ?
            interViewForm.find((item) => item.question_no === 8)?.answer[0]
                ?.Midtsommers[0] ?? "" : null
    );
    const [midSummerEnd, setMidSummerEnd] = useState(
        interViewForm.find((item) => item.question_no === 8)?.answer[0]
            ?.Midtsommers[1] !== "Invalid date" ?
            interViewForm.find((item) => item.question_no === 8)?.answer[0]
                ?.Midtsommers[1] ?? "" : null
    );
    const [midWinterStart, setMidWinterStart] = useState(
        interViewForm.find((item) => item.question_no === 8)?.answer[0]
            ?.Vinterstid[0] !== "Invalid date" ?
            interViewForm.find((item) => item.question_no === 8)?.answer[0]
                ?.Vinterstid[0] ?? "" : null
    );

    const [midWinterEnd, setMidWinterEnd] = useState(
        interViewForm.find((item) => item.question_no === 8)?.answer[0]
            ?.Vinterstid[1] !== "Invalid date" ?
            interViewForm.find((item) => item.question_no === 8)?.answer[0]
                ?.Vinterstid[1] ?? "" : null
    );

    const [sessionComment, setSessionComment] = useState(
        interViewForm.find((item) => item.question_no === 8)?.comment ?? ""
    );

    useEffect(() => {
        let updatedForm = interViewForm.filter(
            (item) => item.interview_section !== 2
        );
        let answer = [
            {
                question: Q1,
                interview_section: 2,
                question_no: 3,
                type: "CHECKBOX",
                answer: facilities,
                extra: null,
                comment: null,
            },
            {
                question: Q2,
                interview_section: 2,
                question_no: 4,
                type: "TEXT",
                answer: location,
                extra: null,
                comment: null,
            },
            {
                question: Q3,
                interview_section: 2,
                question_no: 5,
                type: "RADIO",
                answer: activitiesNeeded,
                extra: null,
                comment: activityComment,
            },
            {
                question: Q4,
                interview_section: 2,
                question_no: 6,
                type: "TEXT",
                answer: closestNeighbours,
                extra: null,
                comment: null,
            },
            {
                question: Q5,
                interview_section: 2,
                question_no: 7,
                type: "RADIO",
                answer: familyWithKids,
                extra: null,
                comment: familyWithKidComment,
            },
            {
                question: Q6,
                interview_section: 2,
                question_no: 8,
                type: "DROPDOWN",
                answer: [
                    {
                        Midtsommers: [
                            !midSummerStart || typeof midSummerStart === "string"
                                ? midSummerStart
                                : (midSummerStart ? moment(midSummerStart).format(DateUtils.hh_mm_ss) : ""),
                            !midSummerEnd || typeof midSummerEnd === "string"
                                ? midSummerEnd
                                : (midSummerEnd ? moment(midSummerEnd).format(DateUtils.hh_mm_ss) : ""),
                        ],
                        Vinterstid: [
                            !midWinterStart || typeof midWinterStart === "string"
                                ? midWinterStart
                                : (midWinterStart ? moment(midWinterStart).format(DateUtils.hh_mm_ss) : ""),
                            !midWinterEnd || typeof midWinterEnd === "string"
                                ? midWinterEnd
                                : (midWinterEnd ? moment(midWinterEnd).format(DateUtils.hh_mm_ss) : ""),
                        ],
                    },
                ],
                extra: null,
                comment: sessionComment,
            },
        ];
        setForm([...updatedForm, ...answer]);
    }, [
        facilities,
        midWinterEnd,
        midWinterStart,
        midSummerEnd,
        midSummerStart,
        familyWithKids,
        activitiesNeeded,
        familyWithKidComment,
        closestNeighbours,
        activityComment,
        location,
        sessionComment,
    ]);

    return (
        <View>
            <Label small font_medium mb={10}>
                {Q1}
            </Label>
            <CheckBox
                onCheckChanged={selectedFacilities}
                defaultChecked={facilities}
                style={{marginVertical: 5}}
                options={FACILITIES}
            />

            <CustomTextArea
                mt={10}
                value={location}
                onChange={setLocationType}
                labelText={Q2}
            />

            <Label small mt={10} font_medium mb={10}>
                {Q3}
            </Label>
            <RadioGroup
                options={Constants.RADIO_OPTIONS}
                selectedOptions={activitiesNeeded}
                style={{alignItems: "center"}}
                contentStyle={{flexDirection: "row"}}
                onSelectOption={setActivityRequired}
            />

            {activitiesNeeded === "Ja" ? (
                <CustomTextArea
                    value={activityComment}
                    onChange={setActivityComment}
                    mt={10}
                />
            ) : null}

            <CustomTextArea
                mt={10}
                value={closestNeighbours}
                onChange={setClosestNeighbours}
                labelText={Q4}
            />

            <Label small mt={10} font_medium mb={10}>
                {Q5}
            </Label>
            <RadioGroup
                options={Constants.RADIO_OPTIONS_EXTRA}
                style={styles.radio}
                selectedOptions={familyWithKids}
                contentStyle={styles.radioCont}
                onSelectOption={setFamilyWithKids}
            />
            <CustomTextArea
                value={familyWithKidComment}
                onChange={setFamilyWithKidComment}
                mt={10}
            />

            <Label small mt={10} font_medium>
                {Q6}
            </Label>

            <DatePicker
                title={Strings.secondStep.midSummer}
                selectedDate={midSummerStart}
                onSelectDate={setMidSummerStart}
                mode={"time"}
            />

            <DatePicker
                style={{marginTop: 20}}
                selectedDate={midSummerEnd}
                onSelectDate={setMidSummerEnd}
                mode={"time"}
            />

            <DatePicker
                title={Strings.secondStep.midWinter}
                selectedDate={midWinterStart}
                onSelectDate={setMidWinterStart}
                mode={"time"}
            />

            <DatePicker
                style={{marginTop: 20}}
                selectedDate={midWinterEnd}
                onSelectDate={setMidWinterEnd}
                mode={"time"}
            />

            <CustomTextArea
                mt={20}
                value={sessionComment}
                onChange={setSessionComment}
            />
        </View>
    );
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SecondStep);
