import React from 'react'
//Components
import {ActivityIndicator, Animated, FlatList, TextInput, TouchableOpacity, View} from 'react-native'
import {Header, Hr, Label, Ripple, Tag} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import debounce from 'lodash.debounce';
import moment from "moment";
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Query} from "@apollo/react-components";
import {STYLIST_ORDER_LIST} from "components/src/api/stylistQuery";
//Utils
import {
    Color,
    CommonStyle,
    DateUtils,
    getStatusColor,
    getStatusLabel,
    Icon,
    IS_WEB,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';


class Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: '',
            searchValue: '',
            Ordered: [{
                index: 1,
                title: 'Ordered',
                status: 1,
                address: 'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350',
                timeanddate: '8:00 - 13:00, 22-02-2020'
            },
            ],
            TimeBooked: [
                {
                    index: 1,
                    status: 1,
                    title: 'Time Booked',
                    address: 'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350',
                    timeanddate: '13:00 - 17:00, 02-03-2020'
                }
            ],
            PhotoTaken: [
                {
                    index: 1,
                    title: 'Photo Taken',
                    address: 'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350',
                    timeanddate: '8:00 - 13:00, 08-03-2020'
                }
            ],
        }
        this.scrollY = new Animated.Value(0)
        this.changeText = debounce(() => {
            this.setState((state) => ({searchValue: state.searchText}))
        }, 500)
    }


    renderOrderItem = ({item, index}) => {
        return (
            <View key={index.toString()}
                  style={CommonStyle.flex}>
                <Hr/>
                <View style={{marginTop: 15, marginBottom: 15}}>
                    <View style={styles.topContainer}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>

                            <Tag name={getStatusLabel(item.order_status)}
                                 color={getStatusColor(item.order_status)}/>


                            <TouchableOpacity
                                style={styles.button}
                                onPress={() => this.showPopover()}>
                                <View>
                                    <Icon
                                        size={ThemeUtils.fontSmall}
                                        name={'more'}
                                        color={Color.PRIMARY_DARK}/>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View>
                            <Label small font_medium mt={10} align={'left'}>
                                {item.property.address}
                            </Label>
                            <Label small font_regular mt={10} align={'left'}>
                                {`${moment(item.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}-${moment(item.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                            </Label>
                        </View>
                    </View>

                </View>
            </View>

        );
    }


    render() {
        const {searchText, searchValue} = this.state
        return (
            <Provider locale={enUS}>
                <Header style={CommonStyle.blankHeader}
                        onPressLeft={this.props.onClose}/>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.titleContainer}>
                            <Label xlarge
                                   font_medium
                                   mt={15}

                                   mb={15}
                                   align={'left'}>
                                {Strings.search}
                            </Label>

                        </View>
                    </View>


                    <View style={styles.SearchMainView}>


                        <Icon name={'search'}
                              color={Color.PRIMARY}
                              size={ThemeUtils.fontSmall}/>


                        <TextInput style={[styles.Textinput, IS_WEB ? {outline: 'none'} : null]}
                                   onChangeText={(text) => {
                                       this.setState({searchText: text}, () => {
                                           this.changeText()
                                       })
                                   }}
                                   placeholderTextColor={Color.PRIMARY}
                                   value={searchText}
                                   placeholder={Strings.search}/>
                        {searchText ?
                            (<Ripple style={styles.closeIconView}
                                     onPress={() => this.setState({
                                         searchText: '',
                                         searchValue: ''
                                     })}
                                     rippleContainerBorderRadius={20}>
                                <Icon name={'close'}
                                      size={ThemeUtils.fontXSmall}
                                      color={Color.PRIMARY} align={'right'}/>
                            </Ripple>) : null}

                    </View>


                    <Query query={STYLIST_ORDER_LIST}
                           variables={{
                               input: {
                                   first: 50,
                                   page: 1,
                                   key: searchValue
                               }
                           }}>
                        {({data, loading, error, fetchMore}) => {
                            if (loading) {
                                return (<ActivityIndicator color={Color.PRIMARY}
                                                           style={CommonStyle.loader}/>)
                            }
                            return (
                                <View>
                                    <FlatList data={data?.stylish_orders?.data ?? []}
                                              renderItem={this.props.renderItem}/>

                                </View>

                            )
                        }}
                    </Query>


                </View>

            </Provider>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);
