import React from 'react'
//Components
import {ScrollView, View} from 'react-native'
import {Header, Label} from "components/src/components";
//Third party
//redux
import {connect} from "react-redux";
// import { PickerView } from '@ant-design/react-native';
//Utils
import {styles} from './styles.js';
import {CommonStyle, Constants, DateUtils, IS_WEB, Routes, Strings} from "components/src/utils";
import moment from "moment";
// import {Button, InputItem} from "@ant-design/react-native";
// import { DatePickerView } from 'antd-mobile';


class ThankyouStyling extends React.Component {

    constructor(props) {
        super(props);
        this.stylingData = IS_WEB ? props.history.location.state?.stylingData ?? props.property?.orderstyling ?? {} : props.navigation.getParam('stylingData', props.property?.orderstyling ?? {});
    }

    stylingViewDetails = () => {
        if (IS_WEB) {
            this.props.history.replace(Routes.ViewDetailsStyling, {
                stylingData: this.stylingData
            })
        } else {
            this.props.navigation.replace(Routes.ViewDetailsStyling, {
                stylingData: this.stylingData
            });
        }
    }

    render() {

        console.log(this.stylingData,"DATA")
        const {order_status, order_date, styling_type, comment, earliest_start_time, latest_start_time} = this.stylingData;

        return (
            <View style={CommonStyle.container}>
                <Header style={CommonStyle.blankHeader}
                        headerRightFirst={'dashboard'}
                        onPressRightFirst={() => IS_WEB ? this.props.history.replace(Routes.SalesPreparation) : this.props.navigation.popToTop()}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>

                <ScrollView contentContainerStyle={{flexGrow: 1}}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View>
                                <Label xlarge

                                       font_medium
                                       align={'left'}>
                                    {Strings.thanksForTheOrder}
                                </Label>
                                <Label
                                    small
                                    mt={16}
                                    font_regular
                                    align={'left'}>
                                    {Strings.thanksInstructionstyling}
                                </Label>

                                <View style={styles.mainviewdata}>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium
                                               mt={20}>
                                            {Strings.stylingtype}
                                        </Label>
                                        <Label small
                                               font_regular
                                               mt={5}
                                               align={'left'}>
                                            {styling_type?.name}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium
                                               mt={20}>
                                            {Strings.dates}
                                        </Label>
                                        <Label small
                                               font_regular
                                               mt={5}
                                               align={'left'}>
                                            {moment(order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium
                                               mt={15}>
                                            {Strings.startTime}
                                        </Label>
                                        <Label
                                            small
                                            font_regular
                                            mt={5}
                                            align={'left'}>
                                            {moment(earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                        </Label>
                                    </View>
                                    {order_status === Constants.ORDER_STATUS.ORDERED ?
                                        (<View style={styles.subviewdata}>
                                            <Label small mt={15}
                                                   font_medium>
                                                {Strings.endTime}
                                            </Label>
                                            <Label small
                                                   font_regular
                                                   mt={5}
                                                   align={'left'}>
                                                {moment(latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                            </Label>
                                        </View>) : null}
                                    {/*<View style={styles.subviewdata}>
                                        <Label small mb={10}
                                               font_medium>
                                            {Strings.Zipcode}
                                        </Label>
                                        <Label small
                                               font_regular
                                               align={'left'}>
                                            {`${item.Zipcode}`}
                                        </Label>
                                    </View>*/}
                                </View>

                                {comment ? (
                                    <Label small
                                           mt={15}
                                           font_medium
                                           align={'left'}>
                                        {Strings.comments}
                                    </Label>) : null}

                                <Label
                                    mt={5}
                                    small
                                    font_regular
                                    align={'left'}>
                                    {comment}
                                </Label>
                            </View>
                            {/*<View>
                                <CustomButton
                                    mt={18}
                                    mb={18}
                                    title={Strings.viewDetails}
                                    onPress={() => {
                                        this.stylingViewDetails()
                                    }}/>
                            </View>*/}
                        </View>
                    </View>

                </ScrollView>
            </View>

        )
    }
}

const mapStateToProps = (state) => {

    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(ThankyouStyling);
