import { getMenuData, getPageTitle } from '@ant-design/pro-layout';
import { connect } from 'react-redux';
import React from 'react';


import '../../../public/fontello.css';
import '../../sass/app.scss';
import { UserFooter } from './components/UserFooter/index';
import UserHeader from './components/UserHeader/index';

const formatMessage=()=>{

}
const Index = props => {
  const {
    route = {
      routes: [],
    },
  } = props;
  const { routes = [] } = route;
  const {
    children,
    location = {
      pathname: '',
    },
  } = props;
  const { breadcrumb } = getMenuData(routes);
  const title = getPageTitle({
    pathname: location.pathname,
    formatMessage,
    breadcrumb,
    ...props,
  });
  return (
    <>
      {/*<Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>*/}
      <div className="container-box graybg">
        <UserHeader />
        <div className="hack-for-spin">{children}</div>
        <UserFooter />
      </div>
    </>
  );
};

export default connect(({ settings }) => ({ ...settings }))(Index);
