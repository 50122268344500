import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    contentCont:{
        marginVertical:55,

        marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN
    }
});
export {styles};
