import React from 'react';
//Components
import {ScrollView, View} from 'react-native'
import {Header, Label, LogoForHeader, Ripple} from "components/src/components";
import Drawer from "@ant-design/react-native/lib/drawer";
import TabBar from "@ant-design/react-native/lib/tab-bar";
//Redux
import {store} from "components/src/redux/store";
import {logout} from "components/src/redux/action";
import styles from './styles';
import {
    Color,
    CommonStyle,
    encryptId,
    getUserName,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {LOG_OUT} from "components/src/api/mutation";
import {Mutation} from "@apollo/react-components";
import {withFirebase} from "../Firebase";
import Cookie from "js-cookie";
//Utils
//Screen

const withDrawer = (getParams, WrapperComponents) => {
    class DrawerNavigation extends React.Component {

        constructor(props) {
            super(props);
            this.user = store.getState().user;
            let selectedTab = null;
            let tabIndex = 0;
            this.extraParams = {...getParams(props)}
            const {tabs, parentRoute} = this.extraParams

            if (IS_WEB) {
                selectedTab = tabs.find(item => {
                    return `/${parentRoute}/${item.route.toLowerCase()}` === props.location.pathname.toLowerCase() ||
                        props.location.pathname.toLowerCase().startsWith(`/${parentRoute}/${item.route.toLowerCase()}`)
                })
            }

            this.state = {
                selectedTab: selectedTab ? selectedTab : tabs[0],

            }
        }

        componentDidMount() {
            this.props.database().ref('v1').once('value', (e) => {
                console.log(e.val(), "VALUES")
            })
            this.database = this.props.database().ref('v1/generalNotifications').child(`${this.user.id}`)
            this.chatDbRef = this.props.database().ref('v1/notificationList').child(`${this.user.id}`)

            this.database.on('value', (e) => {
                let notifcation = e.val();
                if (notifcation) {
                    this.setState({
                        isNewNotification: Object.values(notifcation).filter(item => !item.isRead)?.length !== 0
                    })
                }
            })
            this.chatDbRef.on('value', (e) => {
                let notifcation = e.val();
                if (notifcation) {
                    this.setState({
                        isNewMessage: Object.values(notifcation).filter(item => !item.isRead)?.length !== 0
                    })
                }
            })
        }

        componentWillUnmount() {
            this.database && this.database.off()
            this.chatDbRef && this.chatDbRef.off()
        }

        openNotification = () => {
            IS_WEB ? this.props.history.push(`/${location.pathname.split('/')[1]}/${Routes.Notifications}`) : this.props.navigation.navigate(Routes.Notifications);
        }

        onChangeTab = (tab) => {

            if (IS_WEB) {


                if (tab.route === Routes.Chat) {
                    // this.props.history.replace(`/${this.state.currentUserType === SELLER ? Routes.Seller : Routes.Buyer}/${updatedTab.route}/${encryptId(this.props.property?.id)}`)
                    this.props.history.replace(`/${this.props.location.pathname.split('/')[1]}/${tab.route}/${encryptId(this.props.property?.id)}`)
                } else {
                    this.props.history.replace(tab.route)
                }
            }
            this.setState({selectedTab: tab})
        };

        handleOnPressDrawerItem = (route, name, logOut) => {
            const {navigation, history} = this.props;
            if (name !== Strings.logout) {
                if (IS_WEB) {
                    history.push(route)
                } else if (route) {
                    navigation.navigate(route)
                }
            } else {
                if (IS_WEB) {
                    localStorage.clear();
                    Cookie.remove(`${getUserName(this.user?.user_type_id)}_token`);
                    Cookie.remove(`${getUserName(this.user?.user_type_id)}_user`);
                }
                logOut().then(() => {
                    if (IS_WEB) {
                        history.replace(Routes.Login)
                        store.dispatch(logout());
                    } else if (route) {
                        navigation.navigate(Routes.Login)
                    }
                }).catch(() => {
                });

            }
            this.drawer && this.drawer.drawer && this.drawer.drawer.closeDrawer();
        };

        //render Drawer item
        renderDrawerItems = () => {
            const {drawerItems} = this.extraParams

            return drawerItems.map((item, index) => {
                return (
                    <Mutation mutation={LOG_OUT}>
                        {(logout, {loading, data}) => (
                            <Ripple
                                style={[styles.drawerItemCont, index === 0 ? {backgroundColor: Color.PRIMARY} : null]}
                                onPress={() => this.handleOnPressDrawerItem(item.route, item.name, logout)}
                                key={item.name}>
                                <Icon name={item.icon}
                                      size={ThemeUtils.fontXLarge}
                                      color={index === 0 ? Color.COLOR_ACCENT : Color.PRIMARY}/>
                                <Label font_medium
                                       large
                                       ml={10}
                                       color={index === 0 ? Color.COLOR_ACCENT : Color.PRIMARY}>
                                    {item.name}
                                </Label>
                            </Ripple>
                        )}
                    </Mutation>
                )
            })
        };

        //render Drawer it self
        renderSideBar = () => {
            return (
                <ScrollView>
                    <View style={styles.cont}>
                        <View style={styles.drawerHeader}>
                            <LogoForHeader size={ThemeUtils.responsiveScale(80)}/>
                            {/*<Label xlarge
                                   font_medium
                                   ml={5}>
                                {Strings.web}
                                <Text style={styles.txtRegular}>
                                    {Strings.megling}
                                </Text>
                            </Label>*/}
                        </View>
                        {this.renderDrawerItems()}
                    </View>
                </ScrollView>
            )
        };

        //render bottom tabs icon
        renderTabs = (item) => {
            return (
                <TabBar.Item key={item.id}
                             title={item.name}
                             icon={<Icon name={item.icon}
                                         size={16}
                                         style={{marginBottom: 3}}
                                         color={Color.DARK_GRAY}/>}
                             selectedIcon={<Icon name={item.icon}
                                                 size={18}
                                                 style={{marginBottom: 3}}
                                                 color={Color.TERNARY_TEXT_COLOR}/>}
                             selected={this.state.selectedTab.id === item.id}
                             onPress={() => this.onChangeTab(item)}>
                    {this.renderDrawerScreens()}
                </TabBar.Item>
            )
        };

        //render header icon
        renderHeaderTitle = () => {
            return (
                <LogoForHeader size={ThemeUtils.responsiveScale(60)}/>
            )
        };

        //render drawer screens
        renderDrawerScreens = () => {
            let Screen = this.state.selectedTab.screen
            if (this.state.selectedTab.screen) {
                return <Screen {...this.props}/>
            } else {
                return (
                    <View style={[CommonStyle.flex, CommonStyle.content_center]}>
                        <Label>
                            {`Under Development ${this.state.selectedTab.name}`}
                        </Label>
                    </View>
                )
            }

        };


        render() {
            const {tabs, onPressNotification} = this.extraParams
            return (

                <Drawer ref={ref => this.drawer = ref}
                        drawerBackgroundColor={Color.WHITE}
                        sidebar={this.renderSideBar()}>
                    <Header tintColor={Color.WHITE}
                            navigation={IS_WEB ? this.props.history : this.props.navigation}
                            onPressLeft={() => this.drawer.drawer.openDrawer()}
                            renderTitle={this.renderHeaderTitle}
                            headerLeft={'menu'}
                            badge={this.state.isNewNotification || this.state.isNewMessage}
                            onPressRightSecond={this.openNotification}
                        // headerRightSecond={'notifications'}
                    />
                    <TabBar unselectedTintColor={Color.DARK_GRAY}
                            styles={{
                                tabs: {
                                    height: 56,
                                    borderStyle: 'solid',
                                    flexDirection: 'row'
                                }
                            }}
                            tintColor={Color.SECONDARY}
                            barTintColor={Color.PRIMARY}>
                        {tabs.map(this.renderTabs)}
                    </TabBar>
                </Drawer>

            )
        }
    }

    return withFirebase(DrawerNavigation);
}


export default withDrawer
