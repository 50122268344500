import React, {useEffect} from 'react'
//Component
import {Dashboard} from "components/src/components";
//Utils
import {Constants, IS_WEB, Routes, Strings} from 'components/src/utils'
import energy_labeling from 'components/src/image/energy_labeling.png'
import photo from 'components/src/image/photo.png'
import rate from '../../../../image/Rate.jpg'
import styling from '../../../../image/Styling.jpg'
import seller_s_interview from '../../../../image/Sellers_Iinterview.jpg'
import {connect} from "react-redux";
import {withFirebase} from "../../../../HOC/Firebase";

/*
* <CopilotStep
                                    text="This is a hello world example!"
                                    order={1}
                                    name="hello">
                                    */
const DASHBOARD = [{
    name: Strings.energyLabeling,
    icon: 'energy-labeling',
    bg: energy_labeling,
    text: "Alle boliger må energimerkes. Du som selger må gjøre dette her.",
    order: 1
}, {
    name: Strings.photo,
    icon: 'photo',
    bg: photo,

    text: "Book ønsket tidspunkt for boligfoto, og en fotograf vil ta kontakt med deg for å avtale nærmere. Har du også egne bilder kan de lastes opp her.",
    order: 2
}, {
    name: Strings.rate,
    icon: 'rate',
    bg: rate,
title:"Boligsalgsrapport",
    text: "Boligsalgsrapport er nødvendig ifm. salget med tanke på opplysningsplikten ovenfor selger, men også ifm. boligselgerforsikring. Her kan du booke ønsket tidspunkt, og en takstmann vil ta kontakt med deg for å avtale nærmere.",
    order: 3
}, {
    name: Strings.styling,
    icon: 'styling',
    bg: styling,
    title: "Styling/Interiør-rådgivning",
    text: "Dersom du ønsker interiør-rådgivning eller styling, kan du booke en uforpliktende samtale med interiør-konsulent her.",
    order: 4
}, {
    name: Strings.sellersInterview,
    icon: 'seller-interview',
    bg: seller_s_interview,
    text: "Som eiendomsmegler er det viktig at vi har så mye informasjon som mulig om boligen din. Vi ber deg derfor om å gå gjennom en mengde spørsmål i et skjema vi kaller for «Selgers intervju».",
    order: 5
}];

function Home(props) {
    const {navigation, history, property} = props;

    useEffect(() => {
        /*props.iid().getToken().then((e)=>{
            console.log(e)
        })*/
    }, [])
    const handleItemPress = (name) => {
        let route = null;
        switch (name) {
            case Strings.booksInspection:
                if (!property?.order_inspection) {
                    route = Routes.BookInspection;
                    break;
                }
                if (property?.order_inspection?.order_status === Constants.ORDER_STATUS.ORDERED) {
                    route = Routes.ThankYouInspection;
                    break;
                }
                route = Routes.ViewDetailsInspection;
                break;
            case Strings.photo:
                if (!property?.order_photo) {
                    route = Routes.Photos;
                    break;
                }
                if (property?.order_photo?.order_status === Constants.ORDER_STATUS.ORDERED ||
                    property?.order_photo?.order_status === Constants.ORDER_STATUS.TIMEBOOKED) {
                    route = Routes.ThankYouPhotos;
                    break;
                }
                route = Routes.UploadedPhotos;
                break;
            case Strings.rate:
                if (!property?.orderrate) {
                    if (property?.order_rate_detail) {
                        route = Routes.ViewDetailsRate
                        break;
                    }
                    route = Routes.Rate;
                    break;
                }
                if (property?.orderrate?.order_status === Constants.ORDER_STATUS.ORDERED ||
                    property?.orderrate?.order_status === Constants.ORDER_STATUS.TIMEBOOKED) {
                    route = Routes.ThankYouRate;
                    break;
                }
                route = Routes.ViewDetailsRate;
                break;
            case Strings.styling:
                if (!property?.orderstyling) {
                    route = Routes.Styling;
                    break;
                }
                if (property?.orderstyling?.order_status === Constants.ORDER_STATUS.ORDERED) {
                    route = Routes.ThankYouStyling;
                    break;
                }
                route = Routes.ViewDetailsStyling;
                break;
            case Strings.sellersInterview:
                route = Routes.SellerInterview;
                break;
            case Strings.energyLabeling:
                route = Routes.EnergyLabeling;
                break;
        }
        if (route) {
            if (IS_WEB) {
                history.push(route)
            } else {
                navigation.navigate(route)
            }
        }
    };
    return (
        <>
            <Dashboard dashboardData={DASHBOARD}
                       place={'SALES'}
                       onPressItem={handleItemPress}/>

        </>
    )
}


const mapStateToProps = (state) => {
    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(withFirebase(Home));
