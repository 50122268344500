import React, {useState} from 'react'
//Component
import {Image, ScrollView, TouchableOpacity, View} from "react-native";
import {Label, LinearGradient} from "components/src/components";
//Third Party
import PropTypes from 'prop-types'
//Utils
import FlatGrid from './FlatGrid'
import {Color, CommonStyle, Constants, Icon, openInNewTab, ThemeUtils} from 'components/src/utils'
import styles from './styles'
import {useQuery} from "@apollo/react-hooks";
import {ADS} from "../../../api/sellerQuery";
import {CopilotStep} from "../../ui/CoPilot";


const CustomComponent = ({children, copilot}) => (

    <View {...copilot}
          style={CommonStyle.flex}>
        {children}
    </View>

);


function Dashboard(props) {
    const {dashboardData, place, style, onPressItem} = props;
    const [heights, setHeight] = useState([])
    const {data} = useQuery(ADS, {
        skip: !place,
        fetchPolicy: 'network-only',
        variables: {
            status: 1,
            place: place
        }
    });

    const renderDashboardItem = (item, index) => {
        return (
            <TouchableOpacity style={[styles.dashboardItem, item.disabled ? {opacity: 0.5} : null]}
                              activeOpacity={0.5}
                              disabled={item.disabled}
                              key={item.name}
                              onPress={() => onPressItem && onPressItem(item.name, item.route)}
                              rippleContainerBorderRadius={ThemeUtils.relativeRealWidth(2.5)}>

                <Image source={item.bg}
                       style={styles.imgServices}/>

                <LinearGradient
                    colors={[Color.TRANSPARENT, Color.PRIMARY]}
                    style={[styles.imgServices, {position: 'absolute'}]}>

                    <CopilotStep text={item.text}
                                 order={item.order}
                                 title={item.title ? item.title : item.name}
                                 name={item.name}>
                        <CustomComponent>
                            <View style={styles.lblCont}>
                                <Icon name={item.icon}
                                      size={ThemeUtils.fontXXLarge}
                                      color={Color.TERNARY_TEXT_COLOR}/>
                                <Label small
                                       font_medium
                                       mt={10}
                                       color={Color.WHITE}>
                                    {item.name}
                                </Label>
                            </View>
                        </CustomComponent>
                    </CopilotStep>


                </LinearGradient>
            </TouchableOpacity>


        )
    };

    const renderAds = (item, index, self) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              style={{marginHorizontal: ThemeUtils.relativeRealWidth(3)}}
                              onPress={() => {
                                  let url = item.redirection_url;
                                  if (url && !(url.startsWith("http://") || url.startsWith("https://"))) {
                                      url = `https://${url}`;
                                  }
                                  openInNewTab(url)
                              }}>
                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.ads_url}`}}
                       resizeMode={'contain'}
                       style={[self.length === 1 ? styles.singleBanner : styles.halfBanner]}/>

            </TouchableOpacity>
        )
    };

    return (
        <ScrollView>
            <View>
                <FlatGrid
                    // ListFooterComponent={props?.ads?.map(renderAds) ?? null}
                    itemDimension={ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(30) : ThemeUtils.relativeRealWidth(45)}
                    data={dashboardData}
                    renderItem={({item, index}) => renderDashboardItem(item, index)}
                />
                {data?.ads_popup_details.map(renderAds)}
            </View>
        </ScrollView>
    )
}

Dashboard.propTypes = {
    dashboardData: PropTypes.array,
    onPressItem: PropTypes.func
};
export default Dashboard;
