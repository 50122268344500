/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  // DownloadOutlined,
  UploadOutlined,
  LoadingOutlined,
  CaretDownFilled,
} from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Upload, Modal, notification, Select, Checkbox } from 'antd';
import BraftEditor from 'braft-editor';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Mutation, Query } from 'react-apollo';
import { useMutation } from 'react-apollo';
import {
  ADMIN_ACTIVE_INACTIVE_AGENT,
  ADMIN_UPDATE_AGENT_DETAILS,
  ADMIN_DELETE_AGENT_CV,
  ADMIN_DELETE_AGENT_DOC,
} from '../../../../../../../../../../mutation/AdminMutation';
import { ADMIN_OFFICES_LIST_ONLY } from '../../../../../../../../../../query/AdminQuery';
import profile from '../../../../../../../../../assets/sales_preparation/book_inspection.png';
import {
  STORAGE_URL,
  USER_TYPE,
  USER_TYPE_SERVER,
} from '../../../../../../../../../utils/constant';
import {formatMessage} from "components/src/utils";

const { TextArea } = Input;

const fileList = [
  {
    uid: '-1',
    name: 'image.png',
    status: 'done',
    url: profile,
    // url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  },
];

const Uploadprops = {
  showUploadList: {
    showDownloadIcon: false,
    // downloadIcon: <DownloadOutlined />,
    showRemoveIcon: true,
    removeIcon: <i className="icon-delete" />,
  },
  // defaultFileList: [
  //   {
  //     uid: '1',
  //     name: 'cv.pdf',
  //     status: 'done',
  //     response: 'Server Error 500', // custom error message to show
  //     url: 'http://www.baidu.com/xxx.png',
  //   },
  // ],
};

const docprops = {
  showUploadList: {
    showDownloadIcon: false,
    // downloadIcon: <DownloadOutlined />,
    showRemoveIcon: true,
    removeIcon: <i className="icon-delete" />,
  },
  //   defaultFileList: [
  //     {
  //       uid: '1',
  //       name: 'doc1.pdf',
  //       status: 'done',
  //       response: 'Server Error 500', // custom error message to show
  //       url: 'http://www.baidu.com/xxx.png',
  //     },
  //     {
  //       uid: '2',
  //       name: 'doc2.pdf',
  //       status: 'done',
  //       response: 'Server Error 500', // custom error message to show
  //       url: 'http://www.baidu.com/xxx.png',
  //     },
  //   ],
};

const AgentDetailForm = props => {
  let router=useHistory();
  const formData = props.objectData;
  console.log('agent details=>', formData);
  const [deleteModal, setdeleteModal] = useState(false);
  const [orderData, setOrderData] = useState('');
  const [imageUrl, setimageUrl] = useState(`${STORAGE_URL}${formData.profile_image}`);
  const [user, setUser] = useState(props.objectData);
  const [isimageUrlChanged, setisimageUrlChanged] = useState(false);
  const [imageobject, setimageObject] = useState('');
  const [editorState, seteditorState] = useState(formData?.user_detail?.about);
  const [isInAdminOffice, setisInAdminOffice] = useState(formData.is_in_admin_office);

  const fileRef = useRef(null);
  const formRef = useRef(null);

  let cvLink = [];
  let docLink = [];
  if (formData.user_detail && formData.user_detail.cv_url) {
    cvLink.push({
      uid: 1,
      name: 'CV',
      media_url: formData.user_detail.cv_url,
    });
  }
  if (formData.user_document && formData.user_document.length > 0) {
    formData.user_document.forEach(item => {
      docLink.push({
        uid: item.id,
        name: `Doc-${item.id}`,
        media_url: item.doc_url,
      });
    });
  }

  const [displayCv, setDisplayCv] = useState(
      formData.user_detail && formData.user_detail.cv_url
          ? [
            {
              uid: 1,
              name: 'CV',
              media_url: formData.user_detail.cv_url,
            },
          ]
          : [],
  );
  const [deleteCV, deleteCVResponse] = useMutation(ADMIN_DELETE_AGENT_CV);
  const [displayOtherDoc, setDisplayOtherDoc] = useState(docLink);
  const [deleteDocument, deleteDocumentResponse] = useMutation(ADMIN_DELETE_AGENT_DOC);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handleChange = info => {
    getBase64(info.file.originFileObj, image => setimageUrl(image));
    setimageObject(info.file.originFileObj);
    setisimageUrlChanged(true);
  };

  const onCheckboxChange = e => {
    console.log(isInAdminOffice);
    console.log('checked:', e.target.checked);
    setisInAdminOffice(!isInAdminOffice);
  };

  console.log('Props Location  Agent Detail Form===>>>', props);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDelete = (deleteID, deletUser) => {
    // const deleteID =  orderData.filter(item =>item.id);
    console.log('delete id ==>', deleteID);
    deletUser({
      variables: {
        id: deleteID,
      },
    }).then(data => {
      console.log('Data submitted===>>>', data);
      if (data && data.active_inactive_agent) {
        notification.success({
          message: 'Megler slettet',
          description: '',
          duration: 3,
        });
      }
      router.push('/admin/allemeglere');
    });
  };

  useEffect(() => {
    if (formRef.current !== null) {
      formRef.current.setFieldsValue({
        summary: BraftEditor.createEditorState(formData && formData?.user_detail?.about),
      });
    }
  }, []);

  const submitSummaryContent = async () => {
    const htmlContent = editorState.toHTML();
  };

  const handleEditorChange = editor => {
    seteditorState(editor);
  };

  const onFinish = (values, editAgentDetailsData) => {
    console.log('Success:', values);

    const otherDocFileList = [];
    if (displayOtherDoc && displayOtherDoc.length > 0) {
      displayOtherDoc.forEach(item => {
        if (item.originFileObj) {
          otherDocFileList.push(item.originFileObj);
        }
      });
    }

    const variables = {
      id: formData.id,
      profile_image: isimageUrlChanged ? imageobject : null,
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      office_id: values.office,
      title: values.title,
      is_in_admin_office: isInAdminOffice,
    };

    if (values.phone) {
      variables.mobile = values.phone;
    }

    if (values.education) {
      variables.education = values.education;
    }

    if (values.summary) {
      variables.about = editorState.toHTML();
    }

    if (values.uploadcv) {
      variables.cv = displayCv && displayCv.length > 0 && displayCv[0].originFileObj;
    }

    if (values.uploadotherdoc) {
      variables.other_doc = otherDocFileList;
    }

    editAgentDetailsData({
      variables,
    }).then(({ data }) => {
      console.log('Data submitted===>>>', data);
      if (data && data.update_agent) {
        notification.success({
          message: `Innspillingen ble oppdatert`,
          description: '',
          duration: 3,
        });
        router.push(`/admin/allemeglere`);
      }
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleCvUpload = e => {
    const file = [];
    file.push(e.file);
    setDisplayCv(file);
  };

  const removeCvUpload = item => {
    if (item && item.media_url) {
      deleteCV({
        variables: {
          agent_id: parseInt(props.objectData.id, 10),
        },
      }).then(({ data, loading }) => {
        if (data && !loading) {
          console.log('Deleted CV==>>', data);
          setUser(data.delete_cv);
          cvLink = [];
          setDisplayCv([]);
        }
      });
    } else {
      console.log('CVLink==>', cvLink);
      if (cvLink && cvLink.length > 0) {
        setDisplayCv(cvLink);
      } else {
        setDisplayCv([]);
      }
    }
  };

  const handleOtherDoc = e => {
    console.log(e);
    const tempList = [...e.fileList, ...displayOtherDoc];
    const tempFiles = new Set(tempList);
    setDisplayOtherDoc(Array.from(tempFiles));
  };

  const removeOtherDoc = (uid, deletUserDoc) => {
    console.log('uid:', docLink);
    if (docLink.filter(i => i.uid === uid.uid).length > 0) {
      deletUserDoc({
        variables: {
          agent_id: props.objectData.id.toString(),
          user_document_id: parseInt(uid.uid, 10),
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        console.log('Data data.delete_user===>>>', data.data.delete_document);
        if (data && data.data.delete_document) {
          setDisplayOtherDoc(displayOtherDoc.filter(i => i.uid !== uid.uid));
          notification.success({
            message: 'Dokument slettet',
            description: '',
            duration: 3,
          });
          // location.reload();
        }
        // }
      });
    } else {
      setDisplayOtherDoc(displayOtherDoc.filter(i => i.uid !== uid.uid));
    }

    /* if (props.objectData.user_document.includes(uid.uid)) {
      const documentList = props.objectData.user_document.filter(item => item.id === uid);
      console.log('documentList:', documentList);
      if (displayOtherDoc.length > 0) {
        deleteDocument({
          variables: {
            agent_id: props.objectData.id,
            user_document_id: uid.uid,
          },
        }).then(({ data, loading }) => {
          if (data && !loading) {
            console.log('Deleted Document==>>', data);
            const fileList = displayOtherDoc.filter(item => item.uid !== uid.uid);
            console.log(
              'Removed==>>',
              fileList.filter(item => item.uid !== uid.uid),
            );
            if (
              data.delete_document.user_document &&
              data.delete_document.user_document.length > 0
            ) {
              //   docLink = [];
              //   data.delete_document.user_document.forEach(item => {
              //     docLink.push({
              //       uid: item?.id,
              //       name: `Doc-${item?.id}`,
              //       media_url: item?.doc_url,
              //     });
              //   });
              //   setUser(data.delete_document);
              setDisplayOtherDoc(fileList);
            } else {
              setDisplayOtherDoc([]);
            }
          }
        });
      } else {
        const fileList = displayOtherDoc.filter(item => item.uid !== uid);
        console.log(
          'Removed==>>',
          fileList.filter(item => item.uid !== uid),
        );
        setDisplayOtherDoc(fileList);
      }
    } else {
      const fileList = displayOtherDoc.filter(item => item.uid !== uid.uid);
      console.log(
        'Removed=a=>>',
        fileList.filter(item => item.uid !== uid),
      );
      setDisplayOtherDoc(fileList);
    } */
  };
  return (
      <>
        <Row>
          <Col xs={24}>
            <Mutation mutation={ADMIN_UPDATE_AGENT_DETAILS}>
              {(editAgentDetailsData, { data, loading }) => {
                return (
                    <>
                      <Form
                          ref={formRef}
                          layout="vertical"
                          className="user-forms"
                          name="editprofile"
                          initialValues={{
                            remember: true,
                            id: formData.id,
                            profilepicture: formData?.profile_image,
                            firstname: formData?.first_name ? formData?.first_name : '',
                            lastname: formData?.last_name ? formData?.last_name : '',
                            email: formData?.email ? formData?.email : '',
                            phone: formData?.mobile ? formData?.mobile : '',
                            title: formData?.title ? formData?.title : '',
                            office: formData?.office?.id ? formData?.office?.id : '',
                            education: formData?.user_detail?.education
                                ? formData?.user_detail?.education
                                : '',
                            summary: formData?.user_detail?.about ? formData?.user_detail?.about : '',
                            upload_cv: formData?.user_detail?.cv_url,
                            uploadotherdoc: formData?.uploadotherdoc,
                          }}
                          colon={false}
                          onFinish={values => onFinish(values, editAgentDetailsData)}
                          onFinishFailed={onFinishFailed}
                      >
                        <Row gutter={16}>
                          <Col xs={24} md={8} lg={4}>
                            <Form.Item
                                name="profilepicture"
                                label={formatMessage({
                                  id: 'component.form.profilepicture',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: `Vennligst skriv inn ${formatMessage({
                                      id: 'component.form.profilepicture',
                                    }).toLowerCase()}`,
                                  },
                                ]}
                            >
                              <Row>
                                <Col xs={24}>
                                  <div className="user-profile-box">
                                    <img
                                        src={imageUrl}
                                        alt={formData?.first_name + formData?.last_name}
                                        className="profile-picture"
                                    />
                                  </div>
                                </Col>
                                <Col xs={24} className="mt-3">
                                  <Upload
                                      accept={['.jpg', '.png', '.jpeg']}
                                      showUploadList={false}
                                      values={imageUrl}
                                      onChange={handleChange}
                                  >
                                    <Button>
                                      <UploadOutlined />{' '}
                                      {formatMessage({
                                        id: 'component.form.upload',
                                      })}
                                    </Button>
                                  </Upload>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} lg={8}>
                            <Form.Item
                                name="firstname"
                                label={formatMessage({
                                  id: 'component.form.firstname',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: `Vennligst skriv inn ${formatMessage({
                                      id: 'component.form.firstname',
                                    }).toLowerCase()}`,
                                  },
                                ]}
                            >
                              <Input
                                  style={{ width: '100%' }}
                                  placeholder={formatMessage({
                                    id: 'component.form.firstname',
                                  })}
                                  size="large"
                                  className="pickerwidth"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={8}>
                            <Form.Item
                                name="lastname"
                                label={formatMessage({
                                  id: 'component.form.lastname',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: `Vennligst skriv inn ${formatMessage({
                                      id: 'component.form.lastname',
                                    }).toLowerCase()}`,
                                  },
                                ]}
                            >
                              <Input
                                  placeholder={formatMessage({
                                    id: 'component.form.lastname',
                                  })}
                                  size="large"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} lg={8}>
                            <Form.Item
                                name="email"
                                label={formatMessage({
                                  id: 'component.form.email',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Vennligst oppgi epost',
                                  },
                                ]}
                            >
                              <Input
                                  disabled
                                  style={{ width: '100%' }}
                                  placeholder={formatMessage({
                                    id: 'component.form.email',
                                  })}
                                  size="large"
                                  className="pickerwidth"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={8}>
                            <Form.Item
                                name="phone"
                                label={formatMessage({
                                  id: 'component.form.phone',
                                })}
                            >
                              <Input
                                  placeholder={formatMessage({
                                    id: 'component.form.phone',
                                  })}
                                  size="large"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} lg={16}>
                            <Form.Item
                                name="title"
                                label={formatMessage({
                                  id: 'component.form.title',
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Vennligst oppgi tittel',
                                  },
                                ]}
                            >
                              <Input
                                  style={{ width: '100%' }}
                                  placeholder={`Angi ${formatMessage({
                                    id: 'component.form.title',
                                  }).toLowerCase()}`}
                                  size="large"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16]}>
                          <Col xs={24} md={24} lg={16}>
                            <Query
                                query={ADMIN_OFFICES_LIST_ONLY}
                                variables={{
                                  status: true,
                                }}
                            >
                              {({ data }) => {
                                if (data && data.offices_list) {
                                  return (
                                      <Form.Item
                                          name="office"
                                          label={`${formatMessage({
                                            id: 'component.form.office',
                                          })}`}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Velg ${formatMessage({
                                                id: 'component.form.office',
                                              })}`,
                                            },
                                          ]}
                                      >
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder={`Velg ${formatMessage({
                                              id: 'component.form.office',
                                            })}`}
                                            size="large"
                                            suffixIcon={<CaretDownFilled className="caret-style" />}
                                            className="pickerwidth"
                                        >
                                          {data.offices_list.map(i => (
                                              <Select.Option value={i.id}>
                                                <span>{i.name}</span>
                                              </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                  );
                                }
                                return (
                                    <div className="loader-wrapper">
                                      <LoadingOutlined />
                                    </div>
                                );
                              }}
                            </Query>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} lg={16}>
                            <Form.Item
                                name="education"
                                label={formatMessage({
                                  id: 'component.form.education',
                                })}
                            >
                          <TextArea
                              rows="3"
                              placeholder={`Angi ${formatMessage({
                                id: 'component.form.education',
                              }).toLowerCase()}`}
                          />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} lg={16}>
                            <Form.Item
                                name="summary"
                                label={formatMessage({
                                  id: 'component.form.summary',
                                })}
                            >
                              <BraftEditor
                                  className="editor-wrapper"
                                  value={editorState}
                                  onChange={handleEditorChange}
                                  language="en"
                                  contentStyle={{ height: 210 }}
                                  placeholder={`Angi ${formatMessage({
                                    id: 'component.form.summary',
                                  }).toLowerCase()}`}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={32}>
                          <Col xs={24} lg={16} md={16}>
                            <div className="label-texts mb-3 mt-3">
                              {formatMessage({ id: 'component.form.uploadcv' })}
                            </div>
                            <Form.Item
                                className="no-margin mb-3"
                                name="upload_cv"
                                rules={[
                                  {
                                    required: false,
                                    message: (
                                        <span className="text-lowercase">{`Vennligst fyll inn ${formatMessage(
                                            {
                                              id: 'component.form.upload.video',
                                            },
                                        )}`}</span>
                                    ),
                                  },
                                ]}
                                valuePropName="file"
                            >
                              <Upload
                                  onChange={e => handleCvUpload(e)}
                                  accept=".pdf,.doc,.docx"
                                  showUploadList={false}
                              >
                                <Button>
                                  <UploadOutlined /> {formatMessage({ id: 'component.form.uploadcv' })}
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>
                        {displayCv.length !== 0 && (
                            <Row gutter={32}>
                              <Col xs={24} lg={16}>
                                {displayCv.map(item => (
                                    <div className="default_list mt-3 mb-3">
                                      <div>
                                        <i className="icon-attachment" />
                                        <span className="ml-2">
                                  {item.originFileObj ? (
                                      <span>{item.name}</span>
                                  ) : (
                                      <a
                                          href={`${STORAGE_URL}${item.media_url}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                      >
                                        {item.name}
                                      </a>
                                  )}
                                </span>
                                      </div>
                                      <div
                                          className="upload-media-delete"
                                          onClick={() => removeCvUpload(item)}
                                      >
                                        {deleteCVResponse.loading ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <i className="icon-delete" />
                                        )}
                                      </div>
                                    </div>
                                ))}
                              </Col>
                            </Row>
                        )}
                        <Row gutter={32} className="mt-3">
                          <Col xs={24} lg={16}>
                            <Form.Item
                                name="uploadotherdoc"
                                label={formatMessage({
                                  id: 'component.form.uploadotherdoc',
                                })}
                            >
                              <Upload
                                  onChange={e => handleOtherDoc(e)}
                                  accept=".pdf,.doc,.docx"
                                  showUploadList={false}
                                  multiple
                              >
                                <Button className="borderradius">
                                  <UploadOutlined />
                                  {formatMessage({
                                    id: 'component.form.uploadotherdoc',
                                  })}
                                </Button>
                              </Upload>
                            </Form.Item>
                            {displayOtherDoc.length !== 0 && (
                                <Row gutter={32}>
                                  <Col xs={24} lg={24}>
                                    {displayOtherDoc.map(item => (
                                        <Mutation mutation={ADMIN_DELETE_AGENT_DOC}>
                                          {deletUserDoc => {
                                            return (
                                                <div className="default_list mt-3 mb-3">
                                                  <div>
                                                    <i className="icon-attachment" />
                                                    <span className="ml-2">
                                            {item.originFileObj ? (
                                                <span>{item.name}</span>
                                            ) : (
                                                <a
                                                    href={`${STORAGE_URL}${item.media_url}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                  {item.name}
                                                </a>
                                            )}
                                          </span>
                                                  </div>
                                                  <div
                                                      className="upload-media-delete"
                                                      onClick={() => removeOtherDoc(item, deletUserDoc)}
                                                  >
                                                    {deleteDocumentResponse.loading ? (
                                                        <LoadingOutlined />
                                                    ) : (
                                                        <i className="icon-delete" />
                                                    )}
                                                  </div>
                                                </div>
                                            );
                                          }}
                                        </Mutation>
                                    ))}
                                  </Col>
                                </Row>
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={24}>
                            <Form.Item name="isAdmin">
                              <Checkbox checked={isInAdminOffice} onChange={onCheckboxChange}>
                                {formatMessage({
                                  id: 'component.form.isadmin',
                                })}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={24}>
                            <div className="flex-container">
                              <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="btn-secondary buttons admin-forms-btn"
                                    size="large"
                                    loading={loading}
                                >
                                  {formatMessage({
                                    id: 'component.form.update',
                                  })}
                                </Button>
                                <Button
                                    className="text-primary admin-forms-btn cancel-btn"
                                    style={{ marginLeft: 8 }}
                                    size="large"
                                    onClick={() => router.goBack()}
                                >
                                  {formatMessage({
                                    id: 'component.form.cancel',
                                  })}
                                </Button>
                              </div>
                              <Button
                                  className="admin-forms-btn delete-btn"
                                  style={{ marginLeft: 8 }}
                                  onClick={() => setdeleteModal(true)}
                                  size="large"
                              >
                                {formatMessage({
                                  id: 'component.form.delete',
                                })}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Modal
                            centered
                            title={formatMessage({
                              id: 'component.allagents.deletemodal.title',
                            })}
                            visible={deleteModal}
                            onOk={() => setdeleteModal(false)}
                            onCancel={() => setdeleteModal(false)}
                            width="450px"
                            footer={[
                              <Mutation mutation={ADMIN_ACTIVE_INACTIVE_AGENT}>
                                {(deletUser, { data, loading }) => {
                                  return (
                                      <Button
                                          key="submit"
                                          type="primary"
                                          className="admin-forms-btn  mr_15"
                                          size="large"
                                          onClick={() => onDelete(formData.id, deletUser)}
                                          loading={loading}
                                      >
                                        {formatMessage({
                                          id: 'component.form.yes',
                                        })}
                                      </Button>
                                  );
                                }}
                              </Mutation>,
                              <Button
                                  className="admin-forms-btn  mr_15"
                                  size="large"
                                  onClick={() => setdeleteModal(false)}
                              >
                                {formatMessage({
                                  id: 'component.form.no',
                                })}
                              </Button>,
                            ]}
                        >
                          <p className="text-primary no-margin">
                            {formatMessage({
                              id: 'component.photographer.deletemodal.text',
                            })}
                          </p>
                        </Modal>
                      </Form>
                    </>
                );
              }}
            </Mutation>
          </Col>
        </Row>
      </>
  );
};
export default AgentDetailForm;
