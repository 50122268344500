// @flow
import {StyleSheet} from 'react-native';
import {ThemeUtils,Color} from "../../../../utils";

export const STEP_NUMBER_RADIUS = 14;
export const STEP_NUMBER_DIAMETER = STEP_NUMBER_RADIUS * 2;
export const ZINDEX = 100;
export const MARGIN = ThemeUtils.relativeRealWidth(1);
export const OFFSET_WIDTH = 4;
export const ARROW_SIZE = 6;

export default StyleSheet.create({
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: ZINDEX,
    },
    arrow: {
        position: 'absolute',
        borderColor: 'transparent',
        borderWidth: ARROW_SIZE,
    },
    tooltip: {
        position: 'absolute',
        paddingTop: 15,
        paddingHorizontal: 15,
        backgroundColor: '#fff',
        borderRadius: 3,
        overflow: 'hidden',
    },
    tooltipText: {
        marginBottom: ThemeUtils.relativeRealHeight(2),
    },
    tooltipContainer: {
        flex: 1,
    },
    stepNumberContainer: {
        position: 'absolute',
        width: STEP_NUMBER_DIAMETER,
        height: STEP_NUMBER_DIAMETER,
        overflow: 'hidden',
        zIndex: ZINDEX + 1,
    },
    stepNumber: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: STEP_NUMBER_RADIUS,
        borderColor: '#FFFFFF',
        backgroundColor: '#27ae60',
    },
    stepNumberText: {
        fontSize: 10,
        backgroundColor: 'transparent',
        color: '#FFFFFF',
    },
    button: {
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: ThemeUtils.relativeRealHeight(1)
    },
    buttonText: {
        color: Color.PRIMARY_TEXT_COLOR,
    },
    bottomBar: {
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    overlayRectangle: {
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.2)',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    },
    overlayContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    },
});
