import { StyleSheet } from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    cont: {
        flex: 1,
        paddingTop:50,
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    flexGrow:{
        flexGrow: 1
    },
    loader:{
        marginTop:45
    }


});
export { styles };
