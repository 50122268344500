import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {Header, Hr, Label, Ripple} from "components/src/components";
//Third party
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Utils
import {Color, CommonStyle, Constants, Icon, IS_WEB, openInNewTab, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';
import {useQuery} from "@apollo/react-hooks";
import {BUYER_DOCS} from "components/src/api/buyerQuery";


function BuyerDocuments(props) {
    const {property, history, navigation} = props;
    const scrollY = new Animated.Value(0)
    console.log(property, "PROS")
    const {loading, data, error} = useQuery(BUYER_DOCS, {
        variables: {
            isBuyerSeller: "BUYER",
            propertyId: property?.property_id,
            status: 1
        }
    })

    const renderList = () => {
        if (loading && !data) {
            return (<ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>)
        }
        if (!data?.property_document_lists?.length) {
            return (
                <Label small
                       font_medium
                       align={'center'}
                       style={CommonStyle.flex}>
                    {"Ingen data"}
                </Label>
            )
        }
        return data?.property_document_lists?.map((item, index) => {
            return (
                <Ripple onPress={() => openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item.doc_url}`)}>
                    <Hr/>
                    <View style={styles.topContainer}>
                        <View style={styles.documentcontainer}>

                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'marketing-material-print'}
                                color={Color.PRIMARY_DARK}/>

                            <Label small
                                   ml={9}
                                   font_medium
                                   align={'left'}>{item.title}</Label>
                            <View style={styles.iconView}>
                                <Icon
                                    size={ThemeUtils.fontLarge}
                                    name={'drop-normal'}
                                    color={Color.ICON_BUTTON_ARROW}/>

                            </View>
                        </View>

                    </View>
                    <Hr/>
                </Ripple>
            );
        });
    };

    return (
        <View style={CommonStyle.container}>
            <Header animatedTitle={Strings.documents}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.labeliconContainer}>
                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'documents'}
                                color={Color.PRIMARY_DARK}/>

                        </View>

                        <Label
                            mt={10}
                            mb={20}
                            font_regular
                            small
                            align={'left'}>
                            {Strings.infoBuyerDocument}
                        </Label>


                    </View>
                    <View>{renderList()}</View>

                </View>
            </Animated.ScrollView>
        </View>
    );


}


const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BuyerDocuments);




