import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    contentCont: {
        marginVertical: 20,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    img: {
        height: ThemeUtils.responsiveScale(220),
        width: ThemeUtils.responsiveScale(220),
        backgroundColor: Color.GRAY
    },
    accordionContent: {
        paddingVertical: 0,
        backgroundColor: Color.WHITE
    },
    accordionHeader:{
        paddingStart:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        backgroundColor: Color.WHITE
    },
    testimonialCont:{
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        alignItems:'center'
    }
})
