import { encryptId ,formatDisplayDateTimeUTC} from '../../../utils/utils';
import { LoadingOutlined } from '@ant-design/icons';
import { Avatar,Spin, Button, Col, List, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { formatMessage } from 'components/src/utils';
import { ADMIN_FAGLIGS_LIST } from '../../../../query/AdminQuery';
import { STORAGE_URL } from '../../../utils/constant';
import placeholderImg from '../../../assets/placeholderimg.png';
// import brokerImage from '../../../assets/News _ Professional/Professional_Small.png';

const { Title, Paragraph } = Typography;

// const data = [
//   {
//     headline: 'News Latest Headline',
//     description:
//       'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit…',
//     date: '27-02-2020',
//     image: brokerImage,
//   },
//   {
//     headline: 'News Latest Headline',
//     description:
//       'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit…',
//     date: '27-02-2020',
//     image: brokerImage,
//   },
//   {
//     headline: 'News Latest Headline',
//     description:
//       'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit…',
//     date: '27-02-2020',
//     image: brokerImage,
//   },
// ];

let recordsCount = 3;
export const Professional = () => {
    const [professionalsList, setProfessionalsList] = useState();

    return (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars

        <Row className="contentshadow">
            <Col xs={24} lg={24}>
                <Title level={3} className="text-primary">
                    {formatMessage({ id: 'component.professional.title' })}
                </Title>
                <p className="text-primary">
                    Her finner du en oversikt over alle faglige artikler publisert til megler sitt dashboard.
                    Du kan også legge til nye.
                </p>
                <Link to="/admin/faglig/leggtilfaglig">
                    <Button
                        type="primary"
                        className="btn-secondary buttons admin-forms-btn mb-20 mt-3"
                        size="large"
                    >
                        {formatMessage({ id: 'component.professional.addprofessional' })}
                    </Button>
                </Link>
                <Query
                    query={ADMIN_FAGLIGS_LIST}
                    variables={{
                        first: 3,
                        page: 1,
                        status: true,
                        orderBy: [
                            {
                                field: 'id',
                                order: 'DESC',
                            },
                        ],
                    }}
                    fetchPolicy="cache-and-network"
                >
                    {({ data, loading, fetchMore, error }) => {
                        console.log(error);
                        if (data && data.professionals.data) {
                            setProfessionalsList(data.professionals);
                            return (
                                <>
                                    <List
                                        className="listViewStyle"
                                        itemLayout="horizontal"
                                        dataSource={data.professionals.data}
                                        renderItem={item => (
                                            <Link
                                                to={{
                                                    pathname: `/admin/faglig/fagligdetalj/${encryptId(item.id)}`,
                                                }}
                                            >
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            item.cover_image_url ? (
                                                                <div className="user-profile-box_news_faglig">
                                                                    <img
                                                                        src={`${STORAGE_URL}${item.cover_image_url}`}
                                                                        alt={item.headline}
                                                                        className="profile-picture"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="user-profile-box_news_faglig">
                                                                    <img
                                                                        src={placeholderImg}
                                                                        alt={item.headline}
                                                                        className="profile-picture"
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        title={<a className="text-bold">{item.title}</a>}
                                                        description={
                                                            <>
                                                                <Paragraph className="mb-0" ellipsis={{ rows: 3 }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                                                </Paragraph>
                                                                <span
                                                                    style={{ opacity: 0.5, marginTop: '4px' }}
                                                                    className="d-block"
                                                                >
                                  {formatDisplayDateTimeUTC(item.created_at)}
                                </span>
                                                            </>
                                                        }
                                                    />
                                                    <Link
                                                        to={{
                                                            pathname: `/admin/faglig/redigerfaglig/${encryptId(item.id)}`,
                                                            state: { orderItem: item },
                                                        }}
                                                    >
                                                        <i className="icon-edit" />
                                                    </Link>
                                                </List.Item>
                                            </Link>
                                        )}
                                    />
                                    {professionalsList &&
                                    professionalsList.data &&
                                    professionalsList.data.length > 0 &&
                                    professionalsList.paginatorInfo.hasMorePages && (
                                        <Row style={{ textAlign: 'center' }}>
                                            <Col lg={24}>
                                                <Button
                                                    type="link"
                                                    className="text-primary label-texts no-margin"
                                                    loading={loading}
                                                    onClick={() => {
                                                        fetchMore({
                                                            variables: {
                                                                page:
                                                                    parseInt(data?.professionals?.paginatorInfo?.currentPage, 10) +
                                                                    1,
                                                            },
                                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                                if (!fetchMoreResult) {
                                                                    return prev;
                                                                }
                                                                const finalResult = Object.assign({}, fetchMoreResult);
                                                                finalResult.professionals.data = [
                                                                    ...prev.professionals.data,
                                                                    ...fetchMoreResult.professionals.data,
                                                                ];
                                                                return finalResult;
                                                            },
                                                        });
                                                    }}
                                                >
                                                    {formatMessage({
                                                        id: 'component.agentphotos.loadmore',
                                                    })}
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            );
                        }
                        if (loading && !error) {
                            return (
                                <div className="loader-wrapper">
                                    <Spin />
                                </div>
                            );
                        }
                        if (!loading && error) {
                            return <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>;
                        }
                        return <React.Fragment />;
                    }}
                </Query>
            </Col>
        </Row>
    );
};

export default Professional;
