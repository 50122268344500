import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = 20;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    bottomNavCont: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: ThemeUtils.relativeWidth(4),
        paddingHorizontal: ThemeUtils.relativeWidth(4),
        backgroundColor: Color.PRIMARY
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labelContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    radioCont: {
        flexDirection: 'row'
    },
    radio: {
        alignItems: 'center'
    },
    chk: {
        marginVertical: 5
    },
    chkSub:{
        marginVertical: 5,
        marginStart:20
    }
});
export default styles;
