import React from 'react'
//Components
import {ScrollView, TouchableOpacity, View} from 'react-native'
import {CustomButton,LogoForHeader, InputField, Label} from "components/src/components";
//Third party
//Network
import {Mutation} from "@apollo/react-components";
//redux
import {connect} from "react-redux";
//Utils
import {styles} from './styles.js';
import {
    Color,
    Constants,
    getUserType,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {FORGOT_PASSWORD} from "components/src/api/mutation";
// import {Button, InputItem} from "@ant-design/react-native";
// import { DatePickerView } from 'antd-mobile';


class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        if (IS_WEB) {
            this.userType = getUserType(props.location.pathname.toLowerCase().split('/')[1])
        }
        this.state = {
            email: '',
            errEmail: null
        }
    }

    sendLink = (sendEmailLink) => {
        this.setState({
            errEmail: validation('email', this.state.email.trim())
        }, () => {
            if (!this.state.errEmail) {
                //TODO: Make API call here
                sendEmailLink({
                    variables: {
                        email: this.state.email.trim(),
                        userTypeId: this.userType
                    }
                })
            }
        })
    };

    handleChangeText = (text, fieldId) => {
        switch (fieldId) {
            case Constants.FieldId.EMAIL:
                this.setState({
                    email: text.length === 1 ? text.toLowerCase() : text,
                    errEmail: null
                });
                break;
        }
    };

    renderHeaderTitle = () => {

        return (
            <View style={styles.headerTitle}>
                <LogoForHeader size={ThemeUtils.responsiveScale(120)}/>

                {/*<Label xxlarge
                       font_medium
                       ml={5}>
                    {Strings.web}
                    <Text style={styles.txtRegular}>
                        {Strings.megling}
                    </Text>
                </Label>*/}
            </View>
        )
    };

    render() {
        return (
            <View style={styles.container}>
                {this.renderHeaderTitle()}
                <ScrollView contentContainerStyle={{flexGrow: 1}}>

                    <View style={styles.topContainer}>
                        <View style={{justifyContent: 'center', alignItems: 'center'}}>

                            <Label xxlarge
                                   font_medium
                                   color={Color.WHITE}
                                   align={'center'}>
                                {Strings.forgotPassword}
                            </Label>

                            <Label small
                                   mt={20}
                                   mb={40}
                                   color={Color.WHITE}
                                   textStyle={styles.inst}
                                   align={'center'}>
                                {Strings.forgotPasswordIns}
                            </Label>
                        </View>
                        <View style={styles.fieldContainer}>
                            <InputField
                                color={Color.WHITE}
                                labelProps={{
                                    color: Color.WHITE
                                }}
                                style={{
                                    borderColor: Color.PRIMARY_LIGHTER
                                }}
                                type="email-address"
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.EMAIL)}
                                onFocus={() => this.setState({errEmail: null})}
                                error={this.state.errEmail}
                                value={this.state.email}
                                labelText={Strings.email}
                                placeholder={Strings.email}/>
                        </View>
                        <View style={styles.btnCont}>

                            <Mutation mutation={FORGOT_PASSWORD}
                                      onCompleted={()=>{

                                      }}
                                      onError={() => {
                                          // Toast.show('SOMETHING WEG',2000,()=>{},false)

                                      }}>
                                {(sendEmailLink, {loading, data, error}) => (
                                    <CustomButton font_medium
                                                  loading={loading}
                                                  onPress={() => this.sendLink(sendEmailLink)}
                                                  title={Strings.send}/>
                                )}
                            </Mutation>

                            <TouchableOpacity activeOpacity={0.5}
                                              onPress={() => {
                                                  if (IS_WEB) {
                                                      this.props.history.replace(Routes.Login)

                                                  } else {
                                                      this.props.navigation.goBack()
                                                  }
                                              }}>
                                <Label small
                                       font_medium
                                       mt={25}
                                       mb={25}
                                       align={'center'}
                                       color={Color.SECONDARY}>
                                    {Strings.signIn}
                                </Label>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </View>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
