import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Animated, Keyboard, Linking, View} from 'react-native'
//Components
import {CustomButton, CustomTextArea, Header, Label} from "components/src/components";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {SELLER_DIGITAL_ASSIGNMENT} from 'components/src/api/sellerQuery'
import {SELLER_APPROVE_DS, SELLER_DS_ADD_COMMENT} from "components/src/api/sellerMutation";
//Utils
import {Color, DateUtils, Icon, IS_WEB, MessageUtils, openInNewTab, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import moment from "moment";
import {connect} from "react-redux";

//Network


function DigitalSalesAssignment(props) {
    const {history, navigation} = props;

    const [dsDetails, setDsDetails] = useState({});
    const [comment, setComments] = useState('');
    const [dsUrl, setDsUrl] = useState('');
    const scrollY = new Animated.Value(0);

    const {data, loading, error} = useQuery(SELLER_DIGITAL_ASSIGNMENT, {
        variables: {
            propertyId: props?.property?.id
        },
        fetchPolicy: 'cache-and-network'
    });

    const [addComment, addCommentMutation] = useMutation(SELLER_DS_ADD_COMMENT);
    const [approveDs, approveDsMutaion] = useMutation(SELLER_APPROVE_DS);

    useEffect(() => {
        if (data) {
            setDsDetails(data.property_digital_sales_assignment);
            let url = data?.property_digital_sales_assignment?.url ?? "";
            if (url && !(url.startsWith("http://") || url.startsWith("https://"))) {
                url = `https://${url}`;
            }
            setDsUrl(url);
            data?.property_digital_sales_assignment?.digital_sales_assi_comment.length && setComments(data?.property_digital_sales_assignment?.digital_sales_assi_comment.slice().pop()?.comment)
        }
    }, [data]);

    const sendComment = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        addComment({
            variables: {
                id: dsDetails.id,
                propertyId: props?.property?.id,
                comment: comment
            },
        }).catch(() => {
        });
    };

    const approveDigitalSales = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        approveDs({
            variables: {
                id: dsDetails.id,
                propertyId: props?.property?.id
            },
        }).catch(() => {
        });
    };


    return (
        <View style={styles.cont}>
            <Header animatedTitle={Strings.digitalSalesAssignment}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 keyboardShouldPersistTaps={'always'}
                                 contentContainerStyle={styles.flexGrow}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.contentCont}>
                    <Icon name={'digital-sales-assignment'}
                          style={styles.icDS}
                          size={ThemeUtils.fontXXLarge}/>

                    <Label small
                           mb={20}
                           style={styles.lblInst}>
                        {Strings.dsInst}
                    </Label>
                    {loading && !data ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) :
                        !dsDetails ? (
                            <>
                                <Label align={'center'}
                                       small
                                       font_medium>
                                    {MessageUtils.Message.dsLinkNotAvailable}
                                </Label>

                            </>
                        ) : (
                            <>
                                {dsUrl ? (
                                    <CustomButton title={Strings.seeDigitalSalesTasks}
                                                  onPress={() => openInNewTab(dsUrl)}/>) : null}
                                {dsDetails?.is_approved ?
                                    (<View style={styles.lblCont}>
                                        <Label small
                                               align={'center'}>
                                            {`${Strings.approvedOn} ${moment(dsDetails.approved_date, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.hh_mm_dd_mm_yyy)}`}

                                        </Label>
                                    </View>)
                                    :
                                    (<>
                                        <CustomTextArea placeholder={Strings.writeYourComments}
                                                        mt={20}
                                                        value={comment}
                                                        onChange={setComments}
                                                        labelText={Strings.comments}/>
                                        <CustomButton title={Strings.send}
                                                      disabled={!comment}
                                                      loading={addCommentMutation.loading}
                                                      onPress={() => sendComment()}
                                                      mt={20}
                                                      mr={ThemeUtils.relativeRealWidth(60)}/>
                                    </>)}

                                <View style={styles.btnApproveCont}>
                                    <CustomButton title={dsDetails?.is_approved ? Strings.okay : Strings.approve}
                                                  loading={approveDsMutaion.loading}
                                                  onPress={() => dsDetails?.is_approved ? IS_WEB ? history.goBack() : navigation.goBack() : approveDigitalSales()}/>
                                </View>
                            </>)
                    }

                </View>
            </Animated.ScrollView>
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStateToProps)(DigitalSalesAssignment);

