import React, {useState} from 'react';
//Components
import {Animated, View} from 'react-native';
import {Header, Hr, IndicatorViewPager, Label, PagerTabIndicator} from "components/src/components";
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import styles from "./styles";
import ContactInfoBank from "./ContactInfoBank";
import OwnerShip from "./OwnerShip";
import HomeBuyerInsurance from "./HomeBuyerInsurance";
import {connect} from "react-redux";

const TABS = [{
    text: Strings.contactInfoBank
}, {
    text: Strings.ownerShip
}, {
    text: Strings.homeBuyerInsurance
}];


function ContractInfo(props) {
    const {history, navigation} = props;
    const scrollY = new Animated.Value(0)

    const [selectedPage, setSelectedPage] = useState(0);
    const renderInfoLabel = (item) => {
        return (
            <View style={styles.lblCont}>
                <Label small
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       font_medium>
                    {item.title}
                </Label>
                <Label small
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mb={10}
                       mt={10}>
                    {item.value}
                </Label>
            </View>
        )
    };

    const renderInfoTitle = (item) => {
        return (
            <View style={styles.lblCont}>
                <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mt={10}
                       mb={10}
                       font_medium>
                    {item.title}
                </Label>
                {item.subText.map(renderInfoLabel)}
                {item.isSection && (<Hr marginTop={10}/>)}
            </View>
        )
    };

    const renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}
                                  scrollEnabled/>;
    };

    return (
        <View style={CommonStyle.container}>

            <Header navigation={IS_WEB ? history : navigation}
                    animatedTitle={Strings.contractInformation}
                    animatedValue={scrollY}/>

            <Animated.View style={{marginTop: 20, flex: 1}}>
                <Icon name={'contract-information'}
                      style={{marginStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN}}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY}/>
                <Label small
                       mt={20}
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                    {/*{'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget` dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'}*/}
                </Label>
                <IndicatorViewPager indicator={renderTabIndicator()}
                                    onPageSelected={setSelectedPage}
                                    style={CommonStyle.flex}>
                    <View>
                        <Animated.ScrollView>
                            <ContactInfoBank {...props}/>
                        </Animated.ScrollView>
                    </View>
                    <View>
                        <Animated.ScrollView>
                            <OwnerShip {...props}/>
                        </Animated.ScrollView>
                    </View>
                    <View>
                        <Animated.ScrollView>
                            <HomeBuyerInsurance {...props}
                                                isSelected={selectedPage?.position === 2}/>
                        </Animated.ScrollView>
                    </View>
                </IndicatorViewPager>
            </Animated.View>


        </View>
    )

}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property
    };
};

export default connect(mapStateToProps)(ContractInfo);
