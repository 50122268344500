import {createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import {Platform} from 'react-native'
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import reducer from '../reducer';




const persistConfig = {
    key: 'root',
    storage:sessionStorage
};


const persistedReducer = persistReducer(persistConfig, reducer);

export let store = createStore(persistedReducer);

export let persistor = persistStore(store, null, () => {
    console.log('rehydrated');
});

