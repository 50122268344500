import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {CustomButton, Header, InputField, Label, Toast} from "components/src/components";
//Third Party
//Network
import {Mutation} from "@apollo/react-components";
import {useQuery} from "@apollo/react-hooks";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    Icon,
    IS_WEB,
    openInNewTab,
    showMessage,
    Strings,
    ThemeUtils
} from "components/src/utils";
import styles from './styles';
import {connect} from "react-redux";
import {BUYER_CONTRACT_DOC_QUERY} from "../../../../api/buyerQuery";
import {BUYER_ADD_CONTRACT_COMMENT} from "../../../../api/buyerMutation";

let toast;

function ContractDocument(props) {

    const {history, navigation} = props;

    const [finnDetails, setFinDetails] = useState(null);
    const [comment, setComments] = useState('');
    const [finnUrl, setFinnUrl] = useState('');

    const scrollY = new Animated.Value(0)

    const {data, loading, error, refetch} = useQuery(BUYER_CONTRACT_DOC_QUERY, {
        variables: {
            status: 1,
            propertyId: props?.property?.property_id,
            commentBy: "BUYER"
        },
        fetchPolicy: 'network-only'
    });


    useEffect(() => {
        if (data?.buyer_contract_doc) {
            setFinDetails(data?.buyer_contract_doc)
            data?.buyer_contract_doc?.contractDocComment.length && setComments(data?.buyer_contract_doc?.contractDocComment[0]?.comment)
            if (data?.buyer_contract_doc?.doc_url) {
                setFinnUrl(`${Constants.APIConfig.STORAGE_URL}${data?.buyer_contract_doc?.doc_url}`);
            }
        }
    }, [data])

    const sendComment = (addComment) => {
        addComment({
            variables: {
                id: finnDetails?.id,
                comment: comment,
                commentBy: "BUYER"
            },
        });
    };

    return (
        <View style={styles.cont}>
            <Toast ref={r => toast = r}/>
            <Header animatedTitle={Strings.purchasingContract}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>

            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={styles.flexGrow}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.contentCont}>
                    <Icon name={'settlement-information'}
                          style={styles.icFinn}
                          size={ThemeUtils.fontXXXLarge}/>
                    <Label small
                           mt={10}
                           mb={20}
                           style={styles.lblInst}>
                        {"Når kontrakten er klar for signering, kan du klikke på knappen nedenfor for å få en elektronisk signatur av både kontrakt og andre dokumenter relatert til salget."}
                    </Label>

                    {loading && !data ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) :
                        !finnDetails ? (
                            <>
                                <Label align={'center'}
                                       small
                                       font_medium
                                       style={CommonStyle.loader}>
                                    {"Ingen data"}
                                </Label>

                            </>

                        ) : (
                            <>

                                <CustomButton title={"Åpne PDF"}
                                              disabled={!finnUrl}
                                              onPress={() => {
                                                  openInNewTab(finnUrl)
                                              }}
                                />

                                <Mutation mutation={BUYER_ADD_CONTRACT_COMMENT}
                                          onCompleted={() =>showMessage("Kommentar ble lagt til",Constants.MessageType.SUCCESS)}
                                          onError={() => {
                                          }}>
                                    {(addComment, {loading, data}) => (
                                        <>
                                            <InputField textArea
                                                        placeholder={Strings.writeYourComments}
                                                        mt={20}
                                                        value={comment}
                                                        onChange={setComments}
                                                        labelText={Strings.comments}/>
                                            <CustomButton title={Strings.send}
                                                          loading={loading}
                                                          onPress={() => sendComment(addComment)}
                                                          mr={ThemeUtils.relativeRealWidth(60)}/>
                                        </>
                                    )}
                                </Mutation>

                            </>
                        )
                    }

                </View>
            </Animated.ScrollView>
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStateToProps)(ContractDocument);

