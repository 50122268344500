import React from 'react'
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import App from './App'
import {BrowserRouter as Router,useLocation} from "react-router-dom";
import firebase from "firebase/app";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
// Generate required css
import iconFont from 'react-native-vector-icons/Fonts/AntDesign.ttf';
const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: AntDesign;
}`;


// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

// Inject stylesheet
document.head.appendChild(style);

let firebaseConfig;
// PROD
firebaseConfig = {
  apiKey: "AIzaSyBRgwwMMxi6qJ_mwQtLNAIiPEhzbrjChek",
  authDomain: "weiendomsmegling.firebaseapp.com",
  databaseURL: "https://weiendomsmegling.firebaseio.com",
  projectId: "weiendomsmegling",
  storageBucket: "weiendomsmegling.appspot.com",
  messagingSenderId: "975891878186",
  appId: "1:975891878186:web:3c807dadb3e4c38dc64e53",
  measurementId: "G-K4ZRYPDP38"
};
//FOR DEV
firebaseConfig = {
  apiKey: "AIzaSyD8TQmLhQX5iY5HYKeeKOJ0N7K_AFdS0Qw",
  authDomain: "weiendomsmegling-dev.firebaseapp.com",
  databaseURL: "https://weiendomsmegling-dev.firebaseio.com",
  projectId: "weiendomsmegling-dev",
  storageBucket: "weiendomsmegling-dev.appspot.com",
  messagingSenderId: "328138271286",
  appId: "1:328138271286:web:1a9e48c4f2b9b7add68697",
  measurementId: "G-GTPLY6H4Y5"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Sentry.init({
  dsn: "https://d8c401cd749e4fae9de23d5dfedaa29a@o455514.ingest.sentry.io/5448811",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));
// registerServiceWorker();
