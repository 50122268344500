import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    contentContainer: {
        flex: 1,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginBottom: ThemeUtils.COMMON_HORIZONTAL_MARGIN,

    },
    icon: {
        marginVertical: 20
    }

});
export default styles;
