export default {
    en: {
        /*----------------Change Password---------------------------*/
        changePassword: "Change Password",
        CurrentPassword: "Current Password",
        enterCurrentPassword: "Enter Current Password",
        enterNewPassword: "Enter New Password",
        enterConfirmPassword: "Enter Confirm Password",
        NewPassword: "New Password",
        ConfirmPassword: "Confirm Password",
        update: "Update",
        cancel: "Cancel",

        /*-----------Buyer Home----------------*/
        appointmentInfo: "Appointment Info.",
        purchasingContract: "Purchasing Contract",
        insurance: "Insurance",
        energy: "Energy",
        propertyUpgrades: "Property Upgrades",
        contractInformation: "Contract Information",

        /*--------------Buyer - Appointment Info-------------------------------------*/
        appointmentInfoInst:
            "Here you will find all contact information for the buyer of your / their property as well as information on what is binding agreed.",
        sellersDetails: "Seller's Details",
        appointmentDetails: "Appointment Details",

        megling: "megling",
        extPhotoPackage: "Extended Photo Package",
        dronePhoto: "Drone Photo",
        orderdetails: "Order Details",
        orderinginformation: "Ordering Information",
        uploadphotos: "Upload Photos",
        enterdate: "Enter Date",
        ProfilePicture: "Profile Picture",
        stylingtype: "Styling Type",
        socialMediaViewingFilms: "Social Media Viewing Films",

        infoAdded: "Info Added",
        orderlist: "Order List",

        taxinformation: "Tax Information",
        photos: "Photos",
        ordered: "Ordered",
        projectCompleted: "Project Completed",
        photoTaken: "Photo Taken",
        photoUploaded: "Photo Uploaded",
        timeBooked: "Time Booked",
        photoselected: " Photo Selected",
        taxdate: "Tax Date",
        taxinformationdata: "Dersom du selv har ordnet med takstmann er du ansvarlig for å legge inn riktig informasjon og laste opp takstdokumentet her.",
        orderaccepteddata: "Dersom du selv har ordnet med takstmann er du ansvarlig for å legge inn riktig informasjon og laste opp takstdokumentet her.",
        entertaxdate: "Enter Tax Date",
        downloadtariffdocument: "Download Tariff Document",
        filter: "Filter",
        uploadnewphoto: "Upload New Photo",
        enterloanno: "Enter Loan No.",
        getbettermortgageterms: "Get Better Mortgage Terms",
        orderaccepted: "Order Accepted!",
        disclaimer: "Disclaimer",
        ordercomments:
            "",
        uploadintroduction:
            "Upload a photo by pressing the button below. We ask that you do not upload images of a larger size than the maximum required for printing images in A5 format.",
        status: "Status",
        zipcode: "Zipcode",
        city: "City",
        attachvideolink: "Attach Video Link",
        attach360viewlink: "Attach 360 View Link",
        apply: "Apply",
        reset: "Reset",

        entercontact: "Enter Contact",
        enterdenominationamount: "Enter Denomination Amount",
        enterresidualdebtamount: "Enter Residual Debt Amount",
        view: "View",
        search: "Search",
        enteraccountnumber: "Enter Account No.",

        uploadenergycertificate: "Upload Energy Certificate",
        selectlettercode: "Select Letter Code",
        colorcode: "Color Code",
        selectcolorcode: "Select Color Code",
        upload: "Upload",
        energycertifiacatepdf: "Energy Certificate.pdf",
        floorPlan: "Floorplan",
        takeover: "Takeover",
        iarrangetheappraiseronmyown: "I arrange the appraiser on my own",
        howisthehouseheated: "How is the House heated?",
        takeoverintroduction:
            "When you go through the home with buyer you have to fill out a digital acquisition protocol. Among other things, it takes you through control of the home, reading of electricity meters and handing over keys",
        startacquisitionbutton: "Start Acquisition",
        totalCosting: "Total Costing",
        photo: "Photo",
        photoServiceInstruction1:
            "Standard photo package is included in your fixed price. Here you can book time for the photographer, and also order extended photo services.",
        photoServiceInstruction2:
            "Please set the preferred window for when you would like the Photographer to visit you. Minimum time window is 4 hours.",
        date: "Date",
        selectDate: "Select Date",
        selectDateone: "Select Reminder Date",
        time: "Time",
        selectTime: "Select Time",
        startTime: "Start Time",
        endTime: "End Time",
        writeYourComment: "Write Your Comments Here...",
        comments: "Comment",
        additionalService: "Additional Services",
        additionalServiceordered: "Additional Services Ordered: ",
        order: "Order",
        oppdragno: "Oppdrag No.",
        ones: "1005619",
        sellername: "Seller Name",
        emiliepettersen: "Emilie Pettersen",

        valuerate: "Value Rate",
        entervaluerate: "Enter Value Rate",
        loanrate: "Loan Rate",
        confirm: "Confirm",
        enterloanrate: "Enter Loan Rate",
        btatitle: "BTA",
        enterbta: "Enter BTA",
        bratitle: "BRA",
        enterbra: "Enter BRA",
        prom: "P-ROM",
        enterprom: "Enter P-ROM",
        constructionyear: "Construction Year",
        enterconstructionyear: "Enter Construction Year",
        uploadtariffdocument: "Upload Tariff Document",

        bookInspectionInstruction1:
            "When we sell your home, it is required by law that one of our brokers comes to your home and maintains your home. You book time for this on this page. The broker will then contact you to confirm the time.",
        bookInspectionInstruction2:
            "Please set the preferred window for when you would like the Broker to visit you. Minimum time window is 4 hours.",
        booksInspection: "Book Inspection",
        energyLabelingInstruction:
            "You as a seller are responsible for the property being sold with an energy certificate. You can do this easily through Enova's energy labeling portal. Click the button below and you will be redirected to the energy labeling login. Once you have completed the process, fill in the information below and upload the energy certificate.",
        energyLabeling: "Energy Labeling",
        loginEnergyMerking: "Login energymerking.no",
        letterCode: "Letter Code",
        FAQ: "FAQ",
        rateInstruction1:
            "We all recommend selling homes with a rate or condition report. If you arrange the appraiser yourself - check this in the form below and you will get a new form for entering the key information and solution to upload the appraisal document. If you want us to communicate with the appraiser, you choose the time and book here. The order is non-binding and you car will be contacted by an appraiser for further clarification on the various appraisar products and confirmation of time. ",
        rateInstruction2:
            "Please set the preferred window for when you would like the Broker to visit you. Minimun time window in 4 hours. ",
        rate: "Rate",
        styling: "Styling",
        signIn: "Sign In",
        send: "Send",
        forgotPassword: "Forgot Password?",
        forgotPasswordIns:
            "Please submit the email address for the account and we will send you an email to reset your password",
        email: "Email",
        password: "Password",
        forgotYourPassword: "Forgot Your Password?",
        problemWithLogin: "Problem with logging in?",
        thanksInstructionstyling: "",
        thanksInstructionrate:
            "The appraiser will now contact you to arrange further details. When the tariff is completed, the assessor will upload the tariff info document and you will find all of the information available here. ",
        thanksForTheOrder: "Thanks for the Order!",
        viewDetails: "View Details",
        taxDate: "Tax Date",
        valueRate: "Value Rate",
        loanRate: "Loan Rate",
        bta: "BTA",
        bra: "BRA",
        pRom: "P-Rom",
        constructionYear: "Construction Year",
        viewPDF: "View PDF",

        documentInstruction:
            "Here you find all relevant documents as they become available.",
        InstructionalContent:
            "Here you find all relevant instructional videos as they become available.",

        salesinformation: "Sales Information",
        salesintroduction:
            "Here you will find all contact information for the buyer of your / their property as well as information on what is binding agreed.",
        salesbuyersdetails: `Buyer's Details`,
        instructionStyling: "",
        instructionStyling2:
            "Please set tge preferred window for when you would like the Styler to visit you. Minimun time window in 4 hours. ",
        ChooseYourStyling: "Choose Your Styling",

        lastName: "Last Name",
        firstName: "First Name",
        Address: "Address",
        City: "City",
        emilieP12: "emilieP12@webmegling.no",
        num: "955-536-65",

        Phone: "Phone",
        Email: "Email",

        PurchasePrice: "Purchase Price",
        TakeoverDate: "Takeover Date",
        Settlementinformation: "Settlement Information",
        NewAddress: "New Address",
        Loan: "Loan",
        DenominationKr: "Denomination Kr",
        ResidualDebtKr: "Residual Debt Kr",
        ReminderonDate: "Reminder on Date",
        Bank: "Bank",
        Contact: "Contact",
        LoanNo: "Loan No.",
        ShouldtheLoanberepaid: "Should the loan be repaid?",
        Receivables: "Receivables",
        AccountNoforTransferofReceivable: "Account No. for Transfer of Receivable",
        Settlementinfodetails:
            "In order for us to complete the settlement, we nedd some information from you.",

        AddmoreLoans: "Add more Loans",
        Save: "Save",
        FinancingTitle: "Financing",
        dateactual: "22-02-2020",

        FinancingInformation:
            "Do you need a mortgage or want to refinance for better conditions?? Let our partners offer you a great deal! ",

        AgreeFinancing:
            "I agree with this to be contacted by mortgage advisors for mortgage information.",
        no: "No",
        yes: "Yes",

        dates: "Date",
        address: "Address",
        times: "Time",

        propertyaddress: "Property Address :",

        comment: "Comment",
        sellersInterview: "Seller's Interview",
        sellersInterviewInst:
            "These are questions that can be important to potential buyers. Not all questions will be as relevant to all types of housing, but answers will be as comprehensive as possible.",
        ownerShip: "Ownership",
        housing: "Housing",
        property: "Property",
        done: "Done",
        next: "Next",
        prev: "Prev.",
        myOrders: "My Orders",
        calendar: "Calendar",
        instructional: "Instructional",
        documents: "Documents",
        rewards: "Reward",
        logout: "Log Out",
        yourSalesProgress: "Your Sales Progress",
        salesPreparation: "Sales Preparation",
        marketingSales: "Marketing & Sales",
        contractSettlements: "Contract & Settlement",
        home: "Home",
        chat: "Chat",
        timeline: "Timeline",
        phone: "Phone",

        profile: "Profile",
        help: "Help",

        enterFirstName: "Enter First Name",
        enterLastName: "Enter Last Name",
        enterEmail: "Enter Email",
        enterPhone: "Enter Phone",
        enterAddress: "Enter Address",
        enterCity: "Enter City",
        enterZipCode: "Enter Zip Code",

        freeConsultation: "Free Consultation Online ",
        consultationInHome: "Consultation in Home ",
        fullStyling: "Full Styling ",
        seeFinnAdd: "See Finn Ad",
        dsAssignment: "DS Assignment",
        digitalSalesAssignment: "Digital Sales Assignment",
        marketingMaterials: "Marketing Materials",
        display: "Display",
        bids: "Bids",
        approve: "Approve",
        approvedOn: "Approved On",
        okay: "Okay",
        notApprovedYet: "Not Approved yet",
        dsInst:
            "Here you can view and approve the draft digital sales assignment. If you have comments, send them to the broker below.",
        finnInst:
            "Here you can view and approve the draft finn ad. If you have comments, send them to the broker below.",
        seeDigitalSalesTasks: "See Digital Sales Tasks",
        seeFinnNoAd: "See Finn.no Ad",
        displayInst:
            "Here you have an overview of your views. If you want a broker to take the view for you, you can order it by pressing the button below.",
        addView: "Add View",
        service: "Service",
        upComingView: "Upcoming View",
        completedView: "Completed View",
        purchasedService: "Purchased Services",
        yourBrokerageServices: "Your Brokerage Services",
        myOrderInst:
            "Here you will find an overview of the services ordered by the seller.",
        addService: "Add Service",
        selectOptions: "Select Options...",

        contract: "Contract",
        timeLineInst:
            "Here you will find a timeline that tells you about the process you are going through in connection with the home sales process.",
        addNewProperty: "Add New Property",
        yourProperties: "Your Properties",
        getStartedWithYourSales: "Get started with your home sales",
        addPropertyInst:
            "Tailor your home sale here, and sign the assignment agreement with BankID - you're done! You do not need to contact a broker in advance, but feel free to use the chat if you are wondering something along the way.",
        fixedPrice: "Fixed Price",

        everythingYouNeedToInclude: "Everything you need is included",
        experiencedAgent: "Experienced Real Estate Agent",
        professionalPhotographer: "Professional Photographer",
        digitalSalesAssignmentFinnAd: "Digital Sales Assignment & Find Ad",
        bidRoundHandledByBroker: "Bid Round Handled by Broker",
        obtainingCheckingDoc: "Obtaining & Checking Documentation",
        smartDigitalMarket: "Smart Digital Marketing",
        displayPackage: "Display Package",
        additionalServiceInst:
            "In addition, you can choose to order additional services such as an expanded marketing or viewing broker. Your home sale your choice.",
        homeSalesFixedLowPrice: "Home Sales Fixed Low Price",
        selfOwner: "Self Owner",
        own: "Own",
        share: "Share",
        housingType: "Housing Type",
        shareApartment: "Share Apartment",
        chooseTheTypeOfRemuneration: "Choose the Type of Remuneration",
        remunerationInst:
            "In addition, you can choose to order additional services such as an expanded marketing or viewing broker. Your home sale your choice.",
        chooseOwner: "Choose the Owner's Form of Ownership",
        getRealEstateInfo: "Get real estate info.",
        registeredProperties: "Registered Properties",
        registeredPropertiesInst:
            "Select the property you want to sell through Webmegling",
        propertyNotMetioned:
            "The property I'm going to sell is not mentioned above",
        registeredPropQue1:
            "The following clients have been working on the same assignment for the past 3 months.",
        registeredPropQue2: "Are you a politically exposed person?",
        nameOfBrokerageFirm: "Name of Brokerage Firm",
        gotoAssignment: "Go to Assignment Agreement",

        congratulations: "Congratulations!",
        contractSigned: "The contract agreement has now been signed.",
        contracting: "Contracting",
        signRemaining: "Co-Owner's Sign Remaining",
        acceptAgreement:
            "I confirm having read and understood the contents of the assignment agreement.",
        signAgreementWithBankID: "Sign Assignment Agreement with BankID",
        mediaNotAvailableForSign:
            "Media is not available to sign now. Email the assignment agreement for co-owner's signature.",

        location: "Location",
        standard: "Standard",
        sammie: "Sameie",
        skinAssociation: "Skin Association",
        veilag: "Veilag",
        rights: "Rights",
        vvs: "VVS",
        technicalInstallations: "Technical installations",
        appliances: "Appliances",
        other: "Other",
        name: "Name",
        enterName: "Enter Name",
        doNotKnow: "do Not Know",

        //Seller InterView Questions
        firstStep: {
            q1: "What is the reason for you to sell the property?",
            q2: "What do you like best about the home?",
        },
        secondStep: {
            q1: "What facilities apply to your home?",
            q2: "What do you like best about the location of the home?",
            q3: "Are there any hiking/ activity areas that should be highlighted?",
            q4:
                'Briefly describe who your closest neighbors are, for example: "in the apartment on the right there lives a nice couple in their mid-30s"',
            q5: "Do many families with children live in the area?",
            q6: "How are the sun conditions",
            airConditioning: "Air Conditioning",
            alarm: "Alarm",
            resorts: "Resorts",
            balconyTerrace: "Balcony/ Terrace",
            kidFriendly: "Kid Friendly",
            broadband: "Broadband",
            batplass: "Batplass",
            communalWashing: "Communal washing",
            fishing: "Fishing",
            garage: "Garage",
            parkingPlace: "Parking place",
            golfCourse: "Golf course",
            garden: "Garden",
            elevator: "Elevator",
            petsAllowed: "Pets allowed",
            noResidents: "No residents",
            cableTV: "Cable TV",
            offWaterAndWasteWater: "Off. water and wastewater",
            parquet: "Parquet",
            fireplace: "Fireplace",
            hearth: "hearth",
            receptionQuietCentral: "Reception quiet central",
            shoreline: "Shoreline",
            roof: "Roof",
            hiking: "Hiking",
            view: "View",
            expandability: "Expandability",
            janitorial: "Janitorial",
            securityService: "security service",
            guestParking: "Guest parking",
            midSummer: "midsummer",
            midWinter: "midwinter",
        },
        thirdStep: {
            q1: "Describe what, when and by whom?",
            q2:
                "Are there any other qualities of the home you would like to highlight?",
            q3:
                "Have any upgrades / maintenance been done that should be highlighted?",
        },
        fourthStep: {
            q1: "Is there a compulsory membership in welfare society?",
            q2: "Contact information for the leader of the welfare society",
            q3: "Contact info for chairman",
            q4: "How much do you pay in joint costs?",
            q5: "What do the common costs include?",
            q6:
                "Are there any related duties such as downing / broyting or the like?",
            perMonth: "Per Month",
            perYear: "Per Year",
            perQuarter: "Per Quarter",
        },
        fifthStep: {
            q1:
                "Are there any agreements, rights or obligations associated with the property?",
            q2: "Is there a garage / parking space attached to the home?",
            q3: "If so, can this be documented?",
            q4:
                "Is it associated with common areas / real estate (playground, ball court etc.)?",
            q5: "What and where this is?",
        },
        sixthStep: {
            q1: "What are the heat sources in the home?",
            q2: "What are the heat sources in the home?",
            q3: "Are pipes in the home changed?",
            q4: "Where is it slated and when?",
            q5: "Where is the hot water tank located?",
            heatOptions: "Heating cables",
            radiator: "Radiator",
            heatPump: "Heat pump",
            balancedVentilation: "Balanced ventilation with heat recovery",
            heaters: "Heaters",
            woodStove: "Wood Stove",
            oilHeating: "Oil Heating",
            waterborneHeat: "Waterborne Heat",
            airToAir: "Air to air",
            airToWater: "Air to water",
            waterToWater: "Water to water",
            bioFirePlace: "Bio-fireplace",
            gasFirePlace: "Gas-fireplace",
        },
        seventhStep: {
            q1: "Who is the provider of the internet?",
            q2: "What kind of internet is it?",
            q3: "Who is the provider of TV?",
            q4: "How are the TV signals delivered?",
            q5: "How high is the annual average power consumption?",
            q6: "Where is the home's security cabinet located?",
            altiBox: "AltiBox",
            nextGenTel: "NextGenTel",
            telenor: "Telenor",
            broadnet: "Broadnet",
            iceNet: "Ice.net",
            breibandNo: "Breiband.no",
            homen: "Homen",
            telio: "Telio",
            eidsivaBroadband: "Eidsiva Broadband",
            globalConnect: "Global Connect",
            viaBroadband: "Via Broadband",
            get: "Get",
            other: "Other",
            broadband: "Broadband",
            fiber: "Fiber",
            canalDigital: "Canal Digital",
            viasat: "Viasat",
            riksTv: "RiksTV",
            cableTv: "Cable TV",
            satelliteDish: "Satellite Dish",
            igniter: "Igniter",
        },
        eighthStep: {
            q1: "Are there any appliances that should not be included in the trade?",
            q2: "Where is it slated and when?",
            q3: "Should a washer or dryer be included in the trade?",
        },
        ninethStep: {
            q1:
                "Norges Eiendomsmeglerforbund has compiled a list of movable property and contents that will accompany the property on sale: https://www.nef.no/losore-og-tilbehor/ Is there anything on this list that should not be included when you are going to sell?",
            q2:
                "Is there any other information you think is relevant to informing a real estate agent and buying about?",
            q3: "When do you want to take over the property?",
        },
        whatCanWeHelp: "What can we help you with?",
        contactInst:
            "Are you wondering what your home is worth or maybe you are a first time buyer and want to know more about the process of buying a home? We will be happy to help you. Send us a message via the form below, or contact us via chat in the bottom right corner. Then we will help you as soon as possible!",
        webMeglingAs: "Webmegling AS",
        financeInst:
            "Do you need a mortgage or want to refinance for better conditions? Let our partners offer you a great deal!",
        newMortage: "New mortgage",
        refinancing: "Refinancing",
        submit: "Submit",
        dashBoard: "Dashboard",
        inspectionApplications: "Inspection Applications",
        yourFeedback: "Your Feedback",
        movingGuide: "Moving Guide",
        seller: "Seller",
        buyer: "Buyer",
        editView: "Edit View",
        description: "Description",
        brokerOnView: "Broker on View",
        /*----------------------Buyer Signup--------------------*/
        readContractDraft: "Read Contract Draft",
        congratulationsOnNewHouse: "Congratulations on New Housing",
        createPassword: "Create Password",
        homeBuyerInsurance: "Home Buyer Insurance",
        nameBuyer1: "Name Buyer 1",
        nameBuyer2: "Name Buyer 2",
        ownershipInterestInHernia: "Ownership Interest in Hernia",
        readMoreAboutInsurance: "Read More about Home Buyer Insurance",
        yesIWantInsurance: "Yes, I want insurance",
        noIDontWantInsurance: "No, I don't want insurance",
        thankYouAreNowDone: "Thank-You are now Done!",
        saveLogin: "Save & Login",
        nameOfBank: "Name of Bank",
        contactBank: "Contact Bank",
        phoneEmailContact: "Phone / Email",
        accountNo: "Account Number",
        contactInfoBank: "Contact info Bank",
        addMoreBank: "Add more Bank",
        additionalBank: "Additional Bank",

        /*-------------Apoointment Info------------------------*/

        municipality: "Municipality",
        gnr: "GNR",
        bnr: "BNR",
        fnr: "FNR",
        orgNr: "Org.Nr",
        snrShareNo: "SNR / Share No",
        bankDetails: "Bank Details",
        webmeglingMoooving: "Webmegling Moovin(g)",
        movingGuideInst:
            "Moving can be an energy and time consuming process. We Webmegling want to help make it as simple and effective as possible. That's why we've created a guide with things to keep in mind about the relocation process, as well as how you can easily obtain offers for services you may need.",

        getDeals: "Get Deals",

        webmeglingMooovingInst:
            "Our digital platform, which we have called moovin, can also be used for mooving. In addition to assisting with the checklist for relocation, we can assist with full service relocation services at a fixed price. We take care of everything from washing up to ...",
        seeMovie: "See Movie",
        bidJournal: "Bid Journal",
        active: "Active",
        history: "History",
        accepted: "Accepted",
        expired: "Expired",
        bid: "Bid",
        amount: "Amount",
        dateTime: "Date & Time",
        expiredOn: "Expired On",
        additionalLoan: "Additional Loan",
        additionalReceivables: "Additional Receivables",
        addMoreReceivables: "Add More Receivables",
        enterPercentage: "Enter Percentage",
        reservation: "Reservation",
        contactMeForMoreInformation: "Contact Me for More Information",
        infoBuyerDocument:
            "Here you will find all relevant documents as they become available.",

        /*-----Purchasing Contract----------*/
        infoBuyerPurchasingContract:
            "When the contract is ready for signing, you can click the button below to get an electronic signature of both contract and other documents related to the sale.",
        buttonPurchasing: "Sign a Purchase Contract",

        /*----Insurance-------*/

        getBetterHomeInsurance: "Get Better Home Insurance",

        /*-----Inspection Applications-----*/

        inspectionReport: "inspection Report",
        Sellersinterview: `Seller's interview`,
        advertiseExtraRoom: "Advertise Extra Rooms",
        otherComment: "Other Comment",
        writehere: "Write Here...",

        EnterLocationPlace: "Enter Location",
        WriteYourCommentHere: "Write Your Comment Here...",
        RecommendedValueAddedUpgrades: "Recommended Value Added Upgrades",
        KeySalesPoints: "Key Sales Points",
        GeneralImpressionofTheProperty: "General Impression of The Property",
        buttonSendReport: "Send Report",
        DialogTitle: "Successful !",

        InspectionReportDesc: "Inspection Report sent successfully!",
        RoomName: "Room Name",
        EnterRoomName: "Enter Room Name",

        EnterFloorType: "Enter Floor Type",
        walls: "Walls",
        EnterWallType: "Enter Wall Type",
        Hamling: "Hamling",
        EnterHamlingType: "Enter HamlingType",
        Importantdetailscomment: "Important details / comment",
        WriteImportantDetails: "Write Important Details / Comment Here...",
        Add: "Add",
        floor: "Floor",
        paraquet: "Paraquet",
        laminate: "Laminate",
        ceramicTile: "Ceramic tile",
        slate: "Slate",
        linoleum: "Linoleum",
        ceiling: "Ceiling",
        paintedPlate: "Painted plate",
        theLoss: "The Loss",
        panel: "Panel",
        tiles: "Tiles",
        takees: "Takess",
        workTop: "Worktop",

        three: "Three",
        concrete: "Concrete",
        marble: "Marble",
        composite: "Composite",
        pottery: "Pottery",
        enterWorkTopType: "Enter Worktop Type",
        applianceIncluded: "Appliances included",
        refrigerator: "Refrigerator",
        oven: "Oven",
        microwaveOven: "Microwave oven",
        dishwasher: "dishwasher",
        enterOtherApplianced: "Enter Other Appliances",
        choosePhoto: "Choose Photo",
        takePhoto: "Take Photo",
        uploadFromGallery: "Upload From Gallery",
        removePhoto: "Remove Photo",
        attach: "Attach",
        noOrder: "No Order Available",
        reSchedule: "Reschedule",
        watchVideo: "Watch Video",
        fullView: "360 View",
        areYouSureYouWantToDelete: "Are you sure you want to delete?",
        noBids: "No bids to show",
        loadMore: "Load More",
        clickForMortageInfo: "Click for mortage info",
        clickForInsuranceInfo: "Click for insurance info",
        gotoMovingGuide: "Goto moving guide",
        bankName: "Bank Name",
        enterBankName: "Enter Bank Name",
        viewAll: "View all",
        deletePhoto: "Delete Photo",
        bidder: "Bidder",
    },
    no: {
        eventDetails: "Aktivitetsdetaljer",
        changePassword: "Bytt passord",
        CurrentPassword: "Nåværende passord",
        enterCurrentPassword: "Skriv inn nåværende passord",
        enterNewPassword: "Skriv inn nytt passord",
        enterConfirmPassword: "Skriv inn bekreft passord",
        NewPassword: "Nytt passord",
        ConfirmPassword: "Bekreft passord",
        update: "Oppdater",
        cancel: "Avbryt",
        purchasingContract: "Utkast kjøpekontrakt",
        insurance: "Forsikring",
        energy: "Energi",
        contractInformation: "Boligkjøperforsikring",
        appointmentInfoInst:
            "Her finner du all kontakt informasjon for kjøperen av din/deres eiendom samt informasjon om hva som er bindende avtalt.",
        appointmentDetails: "Avtaledetaljer",
        orderinginformation: "Bestillingsinformasjon",
        uploadphotos: "Last opp bilder",
        ProfilePicture: "Profilbilde",
        stylingtype: "Stylingtype",
        orderlist: "Bestillingsoversikt",
        taxinformation: "Avgift informasjon",
        photos: "Bilder",
        taxdate: "Takstdato",
        taxinformationdata: "",
        orderaccepteddata: "",
        entertaxdate: "Angi takstdato",
        enterloanno: "Lånenummer",
        disclaimer: "Brukervilkår",
        ordercomments: "",
        uploadintroduction:
            "Last opp alle bildene ved å trykke på knappen nedenfor. Vi ber om at du ikke laster opp bilder i større størrelse en det som maksimalt er nødvendig for print av bilder i A5 format. Merk også at det kan ta litt tid å laste opp alle bildene dersom du laster opp mange på en gang.",
        status: "Status",
        zipcode: "Postnummer ",
        city: "Poststed",
        attachvideolink: "Legg ved videolink",
        entercontact: "Oppgi kontaktperson",
        enterdenominationamount: "Oppgi beløp",
        view: "Utsikt",
        search: "Søk",
        enteraccountnumber: "Oppgi kontonummer",
        uploadenergycertificate: "Last opp energiattest",
        selectlettercode: "Velg bokstavkode",
        colorcode: "Fargekode",
        selectcolorcode: "Velg fargekode",
        upload: "Last opp ",
        takeover: "Overtagelse",
        iarrangetheappraiseronmyown: "Jeg ordner takstmann på egenhånd.",
        startacquisitionbutton: "Start overtagelse",
        photo: "Boligfoto",
        date: "Dato",
        time: "Tid",
        startTime: "Tidligste start tidspunkt",
        endTime: "Seneste slutt tispunkt",
        writeYourComment: "Skriv dine kommentarer her...",
        comments: "Kommentarer",
        additionalService: "Bestill ytterligere tjenester",
        order: "Bestill",
        oppdragno: "Oppdragsnr.",
        sellername: "Selger",
        valuerate: "Verditakst",
        entervaluerate: "Angi verditakst",
        loanrate: "Lånetakst",
        confirm: " Bekreft tidspunkt",
        enterloanrate: "Angi lånetakst",
        btatitle: "BTA",
        enterbta: "Angi BTA",
        bratitle: "BRA",
        enterbra: "Angi BRA",
        prom: "P-rom",
        enterprom: "Angi P-rom",
        constructionyear: "Byggeår",
        enterconstructionyear: "Angi byggeår",
        uploadtariffdocument: "Last opp takstdokument",
        bookInspectionInstruction1:
            "Når vi skal selge boligen din vil det noen ganger være nødvendig at en av våre meglere kommer hjem til deg og befarer boligen din. Du booker tid for dette på denne siden. Megler vil deretter ta kontakt med deg for å bekrefte tidspunktet og avklare nærmere detaljer.",
        booksInspection: "Book befaring",
        energyLabeling: "Energimerking",
        letterCode: "Bokstavkode",
        FAQ: "Ofte stilte spørsmål",
        rate: "Takst",
        styling: "Styling",
        signIn: "Logg inn",
        send: "Send",
        forgotPasswordIns:
            "Send e-postadressen for kontoen, så sender vi deg en e-post for å tilbakestille passordet ditt",
        email: "Epost",
        password: "Passord",
        thanksForTheOrder: "Takk for din bestilling!",
        viewDetails: "Vis detaljer",
        taxDate: "Skattedato",
        valueRate: "Verdifrekvens",
        loanRate: "Lånesats",
        bta: "BTA",
        bra: "BRA",
        pRom: 'P-ROM',

        constructionYear: "Byggeår",
        viewPDF: "Se PDF",
        salesinformation: "Salgsinformasjon",
        salesintroduction:
            "Her finner du all kontakt informasjon for kjøperen av din/deres eiendom samt informasjon om hva som er bindende avtalt.",
        salesbuyersdetails: "Kjøpers detaljer",
        ChooseYourStyling: "Velg din styling",
        lastName: "Etternavn",
        firstName: "Fornavn",
        Address: "Adresse",
        City: "Poststed",
        Phone: "Telefon",
        Email: "Epost",
        PurchasePrice: "Kjøpesum",
        TakeoverDate: "Overtagelsesdato",
        Settlementinformation: "Oppgjørsinformasjon",
        NewAddress: "Ny addresse",
        Loan: "Lån",
        DenominationKr: "Pålydende kr.",
        ResidualDebtKr: "Restgjeld kr.",
        ReminderonDate: "Saldo restgjeld pr.",
        Bank: "Bank",
        Contact: "Kontakt",
        contactPerson: "Kontaktperson",
        LoanNo: "Lånenummer.",
        ShouldtheLoanberepaid: "Skal lånet innfris?",
        Receivables: "Eventuelt tilgodehavende",
        AccountNoforTransferofReceivable:
            "Kontonummer",
        AddmoreLoans: "Legg til flere lån",
        Save: "Lagre",
        FinancingTitle: "Finansiering",
        AgreeFinancing:
            "Jeg samtykker med dette til å bli kontaktet av lånerådgivere for informasjon om boliglån.",
        no: "Nei",
        yes: "Ja",
        dates: "Dato",
        address: "Adresse",
        times: "Tid",
        propertyaddress: "Eiendomsadresse :",
        comment: "Kommentar",
        sellersInterview: "Selgers intervju",
        sellersInterviewInst:
            "Dette er spørsmål som kan være viktig for potensielle kjøpere. Alle spørsmål vil ikke være like relevant for alle boligtyper, men svar så utfyllende som mulig.",
        ownerShip: "Eie",
        next: "Neste",
        skip: "Avslutt",
        finish: "Last",
        myOrders: "Mine bestillinger",
        calendar: "Kalender",
        instructional: "Instruksjonsvideoer",
        documents: "Dokumenter",
        salesPreparation: "Salgsforberedelser",
        marketingSales: "Markedsføring og salg",
        contractSettlements: "Kontrakt & oppgjør",
        chat: "Chat",
        timeline: "Tidslinje",
        moovingTimeline: "Sjekkliste flytting",
        phone: "Telefon",
        help: "Hjelp",
        enterFirstName: "Skriv inn fornavn",
        enterLastName: "Skriv inn etternavn",
        enterEmail: "Skriv inn epost",
        enterPhone: "Skriv inn telefon",
        enterAddress: "Skriv inn adresse",
        enterCity: "Skriv inn poststed",
        seeFinnAdd: "Se finn annonse",
        digitalSalesAssignment: "Digital salgsoppgave",
        marketingMaterials: "Markedsmateriell",
        display: "Visning",
        bids: "Bud",
        approve: "Godkjenn",
        okay: "Greit",
        dsInst:
            "Her kan du se og godkjenne utkastet til den digitale salgsoppgaven. Har du kommentarer sender du de til megler nedenfor.",
        finnInst:
            "Her kan du se og godkjenne utkastet til finn annonsen. Har du kommentarer sender du de til megler nedenfor.",
        seeFinnNoAd: "Se finn.no annonse",
        addView: "Legg til visning",
        service: "Service",
        yourBrokerageServices: "Dine meglertjenester",
        marketing: "Markedsføring",
        contract: "Kontrakt",
        yourProperties: "Dine eiendommer",
        experiencedAgent: "Erfaren eiendomsmegler",
        displayPackage: "Visningspakke",
        location: "Fasiliteter og beliggenhet",
        standard: "Standard",
        vvs: "VVS",
        other: "Annet",
        name: "Navn",
        enterName: "Skriv inn navn",
        whatCanWeHelp: "Hva kan vi hjelpe deg med?",
        webMeglingAs: "Webmegling AS",
        newMortage: "Nytt boliglån",
        submit: "Send inn",
        dashBoard: "Dashboard",
        inspectionApplications: "Inspeksjonsapplikasjoner",
        yourFeedback: "Din tilbakemelding",
        movingGuide: "Flytteveiledning",
        seller: "Selger",
        buyer: "Kjøper",
        brokerOnView: "Megler på visning",
        sellerOnView: 'Selger på visning',
        readContractDraft: "Les utkast til kontrakt",
        congratulationsOnNewHouse: "Gratulerer med nye boliger",
        createPassword: "Lag passord",
        homeBuyerInsurance: "Boligkjøperforsikring",
        nameBuyer1: "Navnekjøper",
        nameBuyer2: "Navnekjøper 2",
        ownershipInterestInHernia: "Eierinteresse i Hernia",
        readMoreAboutInsurance: "Les mer om boligkjøperforsikring",
        thankYouAreNowDone: "Thanak-yau re nova donne!",
        saveLogin: "Lagre og logg inn",
        nameOfBank: "Navn på bank",
        contactBank: "Kontakt bank",
        accountNo: "Kontonummer",
        addMoreBank: "Legg til mer bank",
        additionalBank: "Tilleggsbank",
        gnr: "GNR",
        bnr: "BNR",
        fnr: "FNR",
        bankDetails: "Bankinformasjon",
        webmeglingMoooving: "Komplett flyttepakke",
        movingGuideInst:
            "Flytteprosessen kan være en både energi- og tidskrevende prosess. I W Eiendomsmegling ønsker vi å bidra til at dette kan bli så oversiktlig, effektivt og enkelt som mulig. Derfor har vi laget en sjekkliste med punkter som er viktig å huske på i forbindelse med flytteprosessen, samt tilrettelagt for at dere enkelt kan hente inn tilbud på tjenester som kan bidra til å gjøre dette smertefritt.",
        seeMovie: "Se film",
        active: "Aktiv",
        activeBids: "Budoversikt",
        history: "Historie",
        amount: "Beløp",
        dateTime: "Dato & tid",
        enterPercentage: "Oppgi prosentdel",
        contactMeForMoreInformation: "Kontakt meg for mer informasjon",
        infoBuyerPurchasingContract:
            "Når kontrakten er klar for signering, kan du klikke på knappen nedenfor for å få en elektronisk signatur av både kontrakt og andre dokumenter relatert til salget.",
        buttonPurchasing: "Signer en kjøpekontrakt",
        getBetterHomeInsurance: "Få bedre innboforsikring",
        otherComment: "Annet kommentar",
        writehere: "Skriv her...",
        EnterLocationPlace: "Angi plassering",
        WriteYourCommentHere: "Skriv din kommentar her ...",
        RecommendedValueAddedUpgrades: "Anbefalte verdiøkende oppgraderinger",
        KeySalesPoints: "Viktige salgspoeng",
        GeneralImpressionofTheProperty: "Generelt inntrykk av eiendommen",
        buttonSendReport: "Send rapport",
        DialogTitle: "Vellykket !",
        InspectionReportDesc: "Inspeksjonsrapport ble sendt!",
        RoomName: "Romnavn",
        EnterFloorType: "Angi gulvtype",
        EnterWallType: "Angi veggtype",
        Hamling: "Hamling",
        WriteImportantDetails: "Skriv viktige detaljer / kommentar her ...",
        Add: "Legg til",
        floor: "Gulv",
        linoleum: "Linoleum",
        ceiling: "Tak",
        paintedPlate: "Malt plate",
        panel: "Panel",
        tiles: "Fliser",
        takees: "Takess",
        workTop: "Benkeplate",
        three: "Tre",
        concrete: "Betong",
        marble: "Marmor",
        composite: "Sammensatte",
        pottery: "Keramikk",
        enterWorkTopType: "Angi benkeplate type",
        refrigerator: "Kjøleskap",
        oven: "Stekeovn",
        attach: "Feste",
        reSchedule: "Planlegge på nytt",
        watchVideo: "Se på video",
        loadMore: "Last mer",
        attach360viewlink: "Legg ved 360 graders visningslink",
        firstStep: {
            q1: "Hva er grunnen til at dere skal selge boligen?",
            q2: "Hva liker dere best ved boligen?",
        },
        secondStep: {
            q1: "Hvilke fasiliteter gjelder for din bolig?",
            q2: "Hva liker dere best med beliggenheten til boligen?",
            q3: "Er det noen turområder/aktivitetsmuligheter som bør fremheves?",
            q4:
                "Beskriv kort hvem de nærmeste naboene er, eksempelvis: «i leiligheten til høyre bor det et hyggelig par i midten av 30-årene»",
            q5: "Bor det mange barnefamilier i området?",
            q6: "Hvordan er solforholdene?",

            airConditioning: "Air condition",
            alarm: "Alarm",
            resorts: "Alpinanlegg",
            balconyTerrace: "Balkong/Terrasse",
            kidFriendly: "Barnevennlig",
            broadband: "Bredbånd",
            batplass: "Båtplass",
            communalWashing: "Fellesvask",
            fishing: "Fiskemuligheter",
            garage: "Garasje/P-plass",
            parkingPlace: "Gjesteparkering",
            golfCourse: "Golfbane",
            garden: "Hage",
            elevator: "Heis",
            petsAllowed: "Husdyr tillatt",
            noResidents: "Ingen gjenboere",
            cableTV: "Kabel-TV",
            offWaterAndWasteWater: "Off.vann og avløp",
            parquet: "Parkett",
            fireplace: "Peis/Ildsted",
            hearth: "hearth",
            receptionQuietCentral: "Resepsjon rolig sentralt",
            shoreline: "Strandlinje",
            roof: "Takterasse",
            hiking: "Turterreng",
            view: "Utsikt",
            expandability: "Utvidelsesmuligheter",
            janitorial: "Vaktmester-/vektertjeneste",
            securityService: "security service",
            guestParking: "Gjesteparkering",
            midSummer: "Midtsommers",
            midWinter: "Vinterstid",
        },
        thirdStep: {
            q1: "Beskriv hva, når og av hvem",
            q2: "Er det andre kvaliteter ved boligen dere gjerne vil fremheve?",
            q3: "Er det gjort oppgraderinger/vedlikehold som bør fremheves?",
        },
        fourthStep: {
            q1: "Er det pliktig medlemskap i velforening?",
            q2: "Kontaktinfo til lederen i velforeningen",
            q3: "Kontaktinfo til styreleder",
            q4: "Hvor mye betaler dere i felleskostnader?",
            q5: "Hva inkluderer felleskostnadene?",
            q6: "Er det noen tilhørende plikter som dugnad/brøyting eller lignende?",
            perMonth: "Pr måned",
            perYear: "Pr år",
            perQuarter: "Pr kvartal",
        },
        fifthStep: {
            q1:
                "Foreligger det avtaler, rettigheter eller forpliktelser tilknyttet eiendommen?",
            q2: "Er det tilknyttet garasje-/parkeringsplass til boligen?",
            q3: "Kan dette i så tilfelle dokumenteres?",
            q4: "Er det tilknyttet fellesarealer/realsameie (lekeplass, ballbane etc.)?",
            q5: "Hva og hvor er dette?",
        },
        sixthStep: {
            q1: "Hvilke varmekilder er det i boligen?",
            q2: "Hvilke varmekilder er det i boligen?",
            q3: "Er rør i boligen skiftet?",
            q4: "Hvor er det skifet og når?",
            q5: "Hvor er varmtvannstanken plassert?",
            heatOptions: "Varmekabler",
            radiator: "Radiator",
            heatPump: "Varmepumpe",
            balancedVentilation: "Balansert ventilasjon med varmegjenvinning",
            heaters: "Panelovner",
            woodStove: "Vedovn",
            oilHeating: "Oljefyring",
            waterborneHeat: "Vannboren varme",
            airToAir: "Luft til luft",
            airToWater: "Luft til vann",
            waterToWater: "Vann til vann",
            bioFirePlace: "Biopeis",
            gasFirePlace: "Gasspeis",
        },
        seventhStep: {
            q1: "Hvem er leverandør av internett",
            q2: "Hvilken type internett er det?",
            q3: "Hvem er din TV leverandør?",
            q4: "Hvordan leveres tv signalene?",
            q5: "Hvor høyt er årlig gjennomsnittlig strømforbruk?",
            q6: "Hvor er boligens sikringsskap plassert?",
            altiBox: "AltiBox",
            nextGenTel: "NextGenTel",
            telenor: "Telenor",
            broadnet: "Broadnet",
            iceNet: "Ice.net",
            breibandNo: "Breiband.no",
            homen: "Homen",
            telio: "Telio",
            eidsivaBroadband: "Eidsiva Bredbånd",
            globalConnect: "Global connect",
            viaBroadband: "Via Bredbånd",
            get: "Get",
            other: "Annet",
            broadband: "Bredbånd",
            fiber: "Fiber",
            canalDigital: "Canal digital",
            viasat: "Viasat",
            riksTv: "RiksTV",
            cableTv: "Kabel-TV",
            satelliteDish: "Parabol",
            igniter: "Antenne",
        },
        eighthStep: {
            q1: "Er det hvitevarer som ikke skal medfølge i handelen?",
            q2: "Hvor er det skifet og når?",
            q3: "Skal vaskemaskin eller tørketrommel medfølge i handelen?",
        },
        ninethStep: {
            q1:
                "Norges Eiendomsmeglerforbund har utarbeidet en liste over løsøre og innbo som skal følge med boligen ved salg: Les mer her. Er det noe på denne listen som ikke skal medfølge når dere nå skal selge?",
            q2:
                "Er det andre opplysninger dere mener er relevant å opplyse eiendomsmegler og kjøper om?",
            q3: "Når ønsker dere overtakelse av boligen?",
        },

        appointmentInfo: "Informasjon om avtale",
        enterresidualdebtamount: "Oppgi beløp",
        howisthehouseheated: "Hvordan er boligen varmet opp?",
        takeoverintroduction:
            "Når du/dere går gjennom boligen med kjøper må dere fylle ut en digital overtagelsesprotokoll. Den tar deg blant annet gjennom kontroll av boligen, avlesing av strømmåler og overlevering av nøkler.",

        photoServiceInstruction1:
            "Standard fotopakke er inkludert i den faste prisen. Her kan du bestille tid for fotograf, og også bestille utvidede fototjenester.",
        photoServiceInstruction2:
            "Angi ønsket tidsvindu for når du vil at fotografen skal besøke deg. Minimum tidsvindu er 1 timer. Fotograf vil bekrefte nøyaktig tidspunkt etter bestilling.",

        additionalServiceordered: "Tilleggstjenester bestilt",

        bookInspectionInstruction2:
            "Angi ønsket vindu for når du vil at megleren skal besøke deg. Minimum tidsvindu er 1 timer.",
        energyLabelingInstruction:
            "Som selger er man ansvarlig for at eiendommen som selges er" +
            "energimerket. Dette kan du enkelt gjøre via Enovas energimerkingsportal." +
            "Klikk på knappen nedenfor, så blir du tatt videre til pålogging med BankID." +
            "\n\n" +
            "Her kan du velge mellom to forskjellige måter å energimerke boligen din på." +
            "I forbindelse med salg holder det å velge den enkle varianten.\n\n" +
            "Når du har fullført prosessen kan du under legge inn energikarakter og" +
            "fargekode fra energiattesten, samt laste opp den ferdige energiattesten i" +
            "pdf-format.",
        loginEnergyMerking: "Logg inn på energimerking.no",
        rateInstruction1:
            "Vi anbefaler alle å selge hjem med en pris- eller tilstandsrapport. Hvis du ordner takstmannen ypurself, sjekk dette i skjemaet nedenfor, og du vil gi et nytt skjema for å legge inn nøkkelinformasjonen og løsningen for å laste opp takstdokumentet. Hvis du vil at vi skal kommunisere med takstmann, velger du tid og bestiller her. Bestillingen er uforpliktende og din bil vil bli kontaktet av og takstmann for ytterligere avklaring av de forskjellige takstproduktene og bekreftelse av tid.",
        rateInstruction2:
            "Angi ønsket tidsvindu for når du vil at takstmann skal besøke deg. Minimum tidsvindu er 1 timer. Takstmann vil bekrefte nøyaktig tidspunkt etter bestilling.",
        forgotPassword: "Glemt passord?",
        forgotYourPassword: "Glemt passord?",
        problemWithLogin: "Problemer med innloggingen ?",
        thanksInstructionstyling:
            "Din henvendelse er nå sendt til vår anbefalte boligstylist. Du bli kontaktet for en uforpliktende samtale for å bekrefte nøyaktig tidpsunkt og avklare eventuelle spørsmål du måtte ha.",
        thanksInstructionPhoto:
            "Fotografen vil kontakte deg for å ordne ytterligere detaljer. Når bestillingen er fullført, vil fotografen laste opp bildene, og du finner alle tilgjengelige bilder her. Klikk på OK for å gå tilbake til hovedsiden.",
        back: "Tilbake",
        instructionStyling2:
            "Angi ønsket vindu for når du vil at Taksmann skal besøke deg. Minimum tidsvindu er 1 timer.",

        Settlementinfodetails:
            "For at vi skal kunne gjennomføre oppgjøret trenger vi litt informasjon fra deg.",

        FinancingInformation:
            "Har du behov for boliglån, eller ønsker å refinansiere for å få bedre betingelser? La en av våre samarbeidspartnere gi deg et godt tilbud!",
        housing: "Boligen",
        property: "Eiendom",
        prev: "Forrige",
        rewards: "W Rewards",
        logout: "Logg ut",
        home: "Hjem",
        profile: "Profil",
        enterZipCode: "Skriv inn postnummer",
        freeConsultation: "Gratis online konsultasjon",
        consultationInHome: "Styling konsultasjon i din bolig",

        approvedOn: "Godkjent",
        notApprovedYet: "Ikke godkjent ennå",
        displayInst:
            "Her er oversikt over kommende og gjennomførte visninger. Dersom du ønsker å annonsere/planlegge en ny visning, skriv i chat eller ta kontakt med megler via tlf så finner vi sammen et godt tidspunkt.",
        upComingView: "Kommende visninger",
        completedView: "Gjennomførte visninger",
        myOrderInst: "Her kan du bestille ytterligere tjenester etter at oppdragsavtalen er inngått. Diskuter gjerne med megler i forkant og bestill så det du måtte ønske her.",

        timeLineInst:
            "Her finner du en tidslinje som forteller deg om prosessen du går gjennom i forbindelse med hjemmesalgsprosessen.",
        addNewProperty: "Legg til nye egenskaper",

        professionalPhotographer: "Profesjonell fotograf",
        digitalSalesAssignmentFinnAd: "Digital salgsoppgave og Finn-annonse",
        bidRoundHandledByBroker: "Budrunde håndtert av megler",
        obtainingCheckingDoc: "Innhenting og kontroll av dokumentasjon",
        smartDigitalMarket: "Smart digital markedsføring",

        own: "Eie",
        share: "Share",

        sammie: "Sameie",

        veilag: "Veilag",
        rights: "Rettigheter",

        contactInst:
            "Lurer du på hva boligen din er verdt, eller kanskje du er førstegangskjøper og vil vite mer om prosessen rundt kjøp av bolig? Vi hjelper deg gjerne. Send oss en melding via skjemaet nedenfor, eller kontakt oss via chat nede i høyre hjørne. Så vil vi hjelpe deg så snart som mulig!",
        financeInst:
            "Har du behov for boliglån, eller ønsker å refinansiere for bedre betingelser? La vår samarbeidspartnere gi deg et godt tilbud!",
        refinancing: "Refinansiering",

        yesIWantInsurance: "Ja, jeg vil ha forsikring.",
        noIDontWantInsurance: "Nei, jeg vil ikke ha forsikring",
        phoneEmailContact: "Telefon / e-postkontakt",
        contactInfoBank: "Kontaktinfo bankk",
        municipality: "Kommune",
        orgNr: "Org.Nr",
        snrShareNo: "SNR / share no",

        bidJournal: "Grafisk fremstilling",

        bid: "Bud",

        additionalLoan: "Lån",

        reservation: "Reservasjoner",
        infoBuyerDocument:
            "Her finner du alle relevante dokumenter ifm. salget etterhvert som de blir tilgjengelige.",
        timeLineInstruction:
            "Her er en oversikt over prosessen videre fra budaksept og frem til boligen er tinglyst på deg/dere som nye eiere. Skulle du ha noen spørsmål kontakt megler.",

        inspectionReport: "Inspeksjonsrapport",
        Sellersinterview: "Selgers intervju",

        advertiseExtraRoom: "Annonser ekstra rom",
        EnterRoomName: "Romnavn",
        walls: "Vegger",
        EnterHamlingType: "Tast inn hamling type",
        Importantdetailscomment: "Viktige detaljer / kommentar",
        paraquet: "Paraquet",
        laminate: "Laminatet",
        ceramicTile: "Keramisk flis",
        slate: "Skifer",
        theLoss: "Tapet",
        applianceIncluded: "Hvitevarer inkludert",
        microwaveOven: "Mikrobølgeovn",
        dishwasher: "dishwasher",
        skinAssociation: "Velforening",
        fullView: "360 ​​visning",
        technicalInstallations: "Tekniske installasjoner",
        appliances: "Hvitevarer",
        noBids: "Det er ingen bud på eiendommen enda. Kom tilbake senere.",
        orderdetails: "Ordre detaljer",
        ordered: "Bestilt",
        timeBooked: "Bekreftet",//Tid bestilt
        uploadnewphoto: "Last opp nytt bilde",
        getbettermortgageterms: "Få bedre boliglånsbetingelser",
        apply: "Søke om",
        reset: "Nullstille",
        selectDate: "Velg dato",
        selectDateone: "Velg Påminnelses dato",
        selectTime: "Velg tid",
        documentInstruction:
            "Her finner du alt av relevante dokumenter etter som de blir gjort tilgjengelige.",
        InstructionalContent:
            "Her finner du alle relevante instruksjonsvideoer etter hvert som de blir tilgjengelige.",
        yourSalesProgress: "Salgsprosessen",
        bankName: "Bank navn",
        enterBankName: "Oppgi navn på bank",
        dsAssignment: "Digital salgsoppgave",
        seeDigitalSalesTasks: "Se Digital salgsoppgave",
        purchasedService: "Bestilte produkter",
        addService: "Legg til produkt",
        fixedPrice: "Fastpris",
        doNotKnow: "Vet ikke",
        editView: "Redigere visning",
        description: "Beskrivelse",
        getDeals: "Få tilbud",
        accepted: "Akseptert",
        expired: "Utgått",
        rejected: "Avslått",
        rejectedDate: "Avslått dato",
        expiredOn: "Akseptfrist",
        choosePhoto: "Velg bilde",
        takePhoto: "Ta bilde",
        uploadFromGallery: "Last opp fra bildegalleri",
        removePhoto: "Slett bilde",
        noOrder: "Ingen bestillinger tilgjengelig",
        areYouSureYouWantToDelete: "Er du sikker på at du ønsker å slette?",
        gotoMovingGuide: "Gå til flytteguiden",
        clickForMortageInfo: "Klikk for finansieringinformasjon",
        clickForInsuranceInfo: "Klikk for forsikringinformasjon",
        sellersDetails: "Selgerinformasjon",
        web: "web",
        megling: "megling",
        infoAdded: "Takst levert",
        projectCompleted: "Oppdrag utført",
        photoTaken: "Foto gjennomført",
        photoUploaded: "Bilder levert", //Bilde lastet opp
        photoselected: "Bilde valgt",
        downloadtariffdocument: "Last ned takstdokument",
        filter: "Filter",
        orderaccepted: "Bestille akseptert!",
        floorPlan: "Plantegning",
        totalCosting: "Totalpris",
        done: "Utført",
        viewAll: "Se alle",
        deletePhoto: "Slett bilde",
        bidder: "Budgiver",
        thanksInstructionrate:
            "Takstmannen vil kontakte deg for å ordne ytterligere detaljer. Når taksten er cmplettert, vil assessoren laste opp tariffinfo-dokumentet, og du finner all tilgjengelig informasjon her. Klikk på OK for å gå tilbake til hovedsiden.",
        instructionStyling:
            "Å style boligen før salg gir statistisk bedre resultater ved salg. Alle boliger er forksjellige, og en del av prosessen er å finne den løsningen som er best for din bolig. Ta kontakt nedenfor så vil stylist ta kontakt med deg for en uforpliktende prat.",
        congratulations: "Gratulerer!",
        selectOption: "Velg alternativ",
        addMoreReceivables: "Legg til flere kontonummer",
        additionalReceivables: 'Kontonummer',
        /*----------------_Remaining----------------------------------*/

        addPropertyInst:
            "Skreddersy boligsalget ditt her, og signer oppdragsavtalen med BankID - så er du i gang! Du trenger ikke kontakte megler på forhånd, men bruk gjerne chat'en dersom du lurer på noe underveis.",
        everythingYouNeedToInclude: "Følgende er inkludert",
        chooseTheTypeOfRemuneration: "Velg vederlagstype",
        chooseOwner: "Velg eierens eierform",
        gotoAssignment: "Gå til oppdragsavtale",
        nameOfBrokerageFirm: "Navn på meglerforetak",
        acceptAgreement:
            "Jeg bekrefter jeg har lest og forstått innholdet i oppdragsavtalen",
        additionalServiceInst:
            "I tillegg kan du velge å bestille ekstra tjenester som for eksempel en utvidet markedsføring eller megler på visning. Ditt boligsalg - ditt valg.",
        getStartedWithYourSales: "Kom i gang med ditt boligsalg",
        webmeglingMooovingInst:
            "I våre digitale plattform som håndterer alle aspekter av bolig- salg og kjøp, kan også benyttes i flytteprosessen. I tillegg til sjekklisten under kan vi hjelpe med en komplett service løsning med flyttetjenester til en fast pris. Her tas det hånd om alt fra pakking og utvask til innflytting i ny bolig.",
        mediaNotAvailableForSign:
            "Medeier er ikke tilgjengelig for signering. Send avtalen på epost til medeier.",
        getRealEstateInfo: "Hent eiendomsinformasjon",
        signAgreementWithBankID: "Medeier signer oppdragsavtale med BankID",
        registeredProperties: "Registrerte eiendommer",
        registeredPropertiesInst:
            "Velg eiendommen du vil selge gjennom Webmegling",
        contractSigned: "Oppdragsavtalen er nå signert.",
        contracting: "Kontraktsparter",
        signRemaining: "Signatur av medeier gjenstår",
        homeSalesFixedLowPrice: "Boligsalg fast lav pris",
        fullStyling: "Full styling ",
        selectOptions: "Velg alternativ",
        enterOtherApplianced: "Enter other appliances",
        propertyUpgrades: "Property upgrades",
        services: 'Tjenester/produkter',
        marketingPackages: "Markedspakker",

        deleteEventDesc: "Er du sikker du vil slette denne aktiviteten?",
        serviceImage: "Bilder",
        addEvent: "Legg til aktivitet",
        editEvent: "Rediger aktivitet",
        eventTitle: "Aktivitetsnavn",
        enterEventTitle: "Legg til aktivitetsnavn",
        clickToAddEvent: "Legg til aktivitet",
        addNewEvent: "Legg til aktivitet",
        selectProperty: "Velg oppdrag",
        userType: "User type",
        today: "I dag",
        yesterday: "I går",
        chatPlaceholder: "Skriv en melding",
        serviceName: "Beskrivelse",

        notification: 'Varsler',
        messages: "Meldingers",
        clearAll: "Slett alle",
        permissionRequired: "Permission required",
        allowForPermission: "Please allow for permission",
        selectProfilePic: 'Select avatar',
        uploadOldImage: "Last opp før bilde",
        vederlag: 'Vederlag',
        utlegg: 'Utlegg',
    },
};
