import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  Modal,
  notification,
  Row,
  Typography,
  Upload,
} from "antd";
// import { LoadingOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { Link, useHistory } from "react-router-dom";
// import { ADMIN_NEWS_LIST } from '../../../../query/AdminQuery';
import {
  ADMIN_ADD_AGENT_MEDIA,
  ADMIN_DELETE_AGENT_MEDIA,
  ADMIN_EDIT_AGENT_MEDIA,
} from "../../../../../../../../../../mutation/AdminMutation";
import { STORAGE_URL } from "../../../../../../../../../utils/constant";
import { formatMessage } from "components/src/utils";
// import { toJSONSchema } from 'mockjs';

// import brokerImage from '../../../../../../../assets/News _ Professional/News_Small.png';
// import brokerImage from '../../../assets/home/Fotografering.jpg';

const { Title, Paragraph } = Typography;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let recordsCount = 3;

export const Media = (props) => {
  const { objectData } = props;
  const [addMediaForm] = Form.useForm();
  console.log("inside edit office details=>", props.objectData);
  const [imageUrl, setimageUrl] = useState(
    `${STORAGE_URL}${objectData?.media_url}`
  );
  const [addImageUrl, setAddImageUrl] = useState("");
  const [imageObject, setImageObject] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mediaView, setMediaView] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [mediaList, setMediaList] = useState(objectData?.user_media);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleUploadChange = (info) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (image) => setimageUrl(image));
    setImageObject(info.file.originFileObj);
  };
  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (image) => setAddImageUrl(image));
    setImageObject(info.file.originFileObj);
  };
  const ModalDataAddingFunction = (item) => {
    setCurrentData(item);

    setimageUrl(`${STORAGE_URL}${item.media_url}`);
    setMediaView(true);
  };

  const onMediaFinish = (values, addMedia) => {
    console.log("Success:", values);
    console.log("addMedia:", addMedia);
    addMedia({
      variables: {
        agent_id: objectData.id,
        title: values.title,
        redirection_url: values.redirection_url,
        media_image: values.media_image.fileList[0].originFileObj,
      },
    }).then((data) => {
      console.log("Data submitted===>>>", data);
      if (data && data.data.agent_add_media) {
        notification.success({
          message: `Media ble lagt til`,
          description: "",
          duration: 3,
        });
        setAddImageUrl("");
        setMediaList(data.data.agent_add_media);
        if (addMediaForm) {
          addMediaForm.resetFields();
        }
      }
    });
  };

  const onDelete = (itemId, deleteMedia) => {
    deleteMedia({
      variables: {
        media_id: itemId,
        agent_id: objectData.id,
      },
    }).then((data) => {
      console.log("Data submitted===>>>", data);
      if (data && data.data.delete_agent_media) {
        notification.success({
          message: `Media ble slettet`,
          description: "",
          duration: 3,
        });
        setCurrentData({});
        if (mediaView) {
          setMediaView(false);
        }
        setMediaList(data.data.delete_agent_media);
      }
    });
  };

  const onMediaEditFinish = (values, editMedia) => {
    console.log("Success:", values);
    console.log("\nid:");

    editMedia({
      variables: {
        id: currentData.id,
        agent_id: objectData.id,
        title: values.title,
        redirection_url: values.redirectionalurl,
        media_image: imageObject,
      },
    }).then((data) => {
      console.log("Data submitted===>>>", data);
      if (data && data.data.agent_update_media) {
        notification.success({
          message: `Media ble lagt til`,
          description: "",
          duration: 3,
        });
        setCurrentData({});
        setMediaView(false);
        setMediaList(data.data.agent_update_media);
      }
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">
        {formatMessage({
          id: "component.form.upload",
        })}
      </div>
    </div>
  );

  const onMediaFinishFailed = (errorinfo) => console.log(errorinfo);
  const onMediaEditFinishFailed = (errorinfo) => console.log(errorinfo);

  console.log("");

  return (
    <>
      <Row className="contentshadow" style={{ marginLeft: 0 }}>
        <Col xs={24} lg={24}>
          <Title level={3} className="text-primary">
            Presse
          </Title>
          <Mutation mutation={ADMIN_ADD_AGENT_MEDIA}>
            {(addMedia, { loading }) => (
              <Form
                layout="vertical"
                className="user-forms"
                name="uploadpic"
                form={addMediaForm}
                initialValues={{
                  remember: true,
                }}
                colon={false}
                onFinish={(values) => onMediaFinish(values, addMedia)}
                onFinishFailed={onMediaFinishFailed}
              >
                <Row style={{ marginTop: 5 }}>
                  <Col xs={24}>
                    <Form.Item
                      name="media_image"
                      label={formatMessage({
                        id: "component.form.profilepicture",
                      })}
                      rules={[
                        {
                          required: true,
                          message: `Vennligst skriv inn ${formatMessage({
                            id: "component.form.profilepicture",
                          }).toLowerCase()}`,
                        },
                      ]}
                    >
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={handleChange}
                      >
                        {addImageUrl ? (
                          <img
                            src={addImageUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={24} lg={16}>
                    <Form.Item
                      name="title"
                      className="label-texts"
                      label={formatMessage({
                        id: "component.form.title",
                      })}
                      rules={[
                        {
                          required: true,
                          message: `Vennligst skriv inn  ${formatMessage({
                            id: "component.form.title",
                          }).toLowerCase()}!`,
                        },
                      ]}
                    >
                      <Input.TextArea
                        rows={3}
                        placeholder="Skriv medietittel her..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8}>
                    <Form.Item
                      name="redirection_url"
                      className="label-texts"
                      label={formatMessage({
                        id: "component.form.redirectionalurl",
                      })}
                      rules={[
                        {
                          required: true,
                          message: `Vennligst skriv inn ${"Link til artikkel".toLowerCase()}!`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({
                          id: "component.form.redirectionalurl",
                        })}
                        size="large"
                      />
                    </Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={loading}
                      className="btn-secondary buttons admin-forms-btn mt-3"
                    >
                      {formatMessage({
                        id: "component.form.submit",
                      })}
                    </Button>
                    <Link to="/admin/allemeglere">
                      <Button
                        size="large"
                        // onClick={router.push(`/admin/allemeglere`)}
                        className="buttons admin-forms-btn back-button mt-3 cancel-btn"
                      >
                        {formatMessage({
                          id: "component.form.cancel",
                        })}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Form>
            )}
          </Mutation>
          <Divider />
          <List
            className="listViewStyle"
            itemLayout="horizontal"
            dataSource={mediaList}
            renderItem={(item) => (
              <List.Item height={20} key={Math.random()}>
                <List.Item.Meta
                  onClick={() => {
                    setCurrentData(item);
                    setimageUrl(`${STORAGE_URL}${item.media_url}`);
                    setMediaView(true);
                  }}
                  avatar={<Avatar src={`${STORAGE_URL}${item.media_url}`} />}
                  title={<a className="text-bold">{item.title}</a>}
                  description={
                    <>
                      <Paragraph className="mb-0" ellipsis={{ rows: 3 }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.redirect_url,
                          }}
                        />
                      </Paragraph>
                      <span
                        style={{ opacity: 0.5, marginTop: "4px" }}
                        className="d-block"
                      >
                        {/* {item.created_at} */}
                      </span>
                    </>
                  }
                  // date={item.date}
                />
                <i
                  className="icon-edit"
                  onClick={() => ModalDataAddingFunction(item)}
                />
                <>
                  <Mutation mutation={ADMIN_DELETE_AGENT_MEDIA}>
                    {(deleteMedia, { loading }) =>
                      loading ? (
                        <LoadingOutlined />
                      ) : (
                        <i
                          className="icon-delete"
                          style={{ cursor: "pointer" }}
                          onClick={() => onDelete(item.id, deleteMedia)}
                        />
                      )
                    }
                  </Mutation>
                </>
              </List.Item>
            )}
          />

          {/* {objectData.user_media.lenght > 3 && (
            <a
              className="text-center text-bold mt-20 loadmore"
              style={{ display: "block" }}
              onClick={() => {
                recordsCount += 3;
              }}
            >
              {formatMessage({ id: "component.photosorder.loadmore" })}
            </a>
          )} */}
        </Col>
      </Row>
      <Modal
        destroyOnClose
        centered
        title="Rediger presse"
        visible={mediaView}
        onOk={() => {
          setCurrentData({});
          setMediaView(false);
        }}
        onCancel={() => {
          setCurrentData({});
          setMediaView(false);
        }}
        width="600px"
        footer={false}
      >
        <>
          {console.log("Current Data", currentData)}
          <Mutation mutation={ADMIN_EDIT_AGENT_MEDIA}>
            {(editMedia, { loading }) => (
              <Form
                name="editMediaForm"
                initialValues={
                  currentData
                    ? {
                        remember: true,
                        id: currentData.id,
                        // media_image: (`${STORAGE_URL}${currentData.media_url}`),
                        redirectionalurl: currentData.redirect_url,
                        title: currentData.title,
                      }
                    : {
                        remember: true,
                      }
                }
                onFinish={(values) => onMediaEditFinish(values, editMedia)}
                onFinishFailed={(errorInfo) =>
                  onMediaEditFinishFailed(errorInfo)
                }
                layout="vertical"
                className="user-forms addEditform"
              >
                <Form.Item
                  name="media_image"
                  label={formatMessage({
                    id: "component.news.coverphoto",
                  })}
                >
                  <Row>
                    <Col xs={24}>
                      <div className="user-profile-box">
                        <img
                          src={imageUrl}
                          alt="John Doe"
                          className="profile-picture"
                        />
                      </div>
                    </Col>
                    <Col xs={24} className="mt-3">
                      <Upload
                        showUploadList={false}
                        onChange={handleUploadChange}
                      >
                        <Button>
                          <UploadOutlined />{" "}
                          {formatMessage({
                            id: "component.form.upload",
                          })}
                        </Button>
                      </Upload>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  label={formatMessage({ id: "component.form.title" })}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: `Vennligst skriv inn ${formatMessage({
                        id: "component.form.title",
                      }).toLowerCase()}!`,
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder={formatMessage({ id: "component.form.title" })}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label={formatMessage({
                    id: "component.form.redirectionalurl",
                  })}
                  name="redirectionalurl"
                  rules={[
                    {
                      required: true,
                      message: `Vennligst skriv inn ${formatMessage({
                        id: "component.form.redirectionurl",
                      }).toLowerCase()}!`,
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({
                      id: "component.form.redirectionalurl",
                    })}
                    size="large"
                  />
                </Form.Item>

                <Button
                  key="submit"
                  htmlType="submit"
                  type="primary"
                  className="admin-forms-btn  mr_15"
                  size="large"
                  form="editMediaForm"
                  loading={loading}
                  // onClick={() => onDelete(orderItem.id, deletUser)}
                >
                  {formatMessage({
                    id: "component.form.update",
                  })}
                </Button>
                <Button
                  className="admin-forms-btn  mr_15"
                  size="large"
                  onClick={() => setMediaView(false)}
                >
                  {formatMessage({
                    id: "component.form.cancel",
                  })}
                </Button>
                <Mutation mutation={ADMIN_DELETE_AGENT_MEDIA}>
                  {(deleteFeedback, { loading }) => (
                    <Button
                      className="admin-forms-btn  mr_15"
                      size="large"
                      style={{ float: "right", color: "red" }}
                      loading={loading}
                      onClick={() => onDelete(currentData.id, deleteFeedback)}
                    >
                      {formatMessage({
                        id: "component.form.delete",
                      })}
                    </Button>
                  )}
                </Mutation>
              </Form>
            )}
          </Mutation>
        </>
      </Modal>
    </>
  );
};

export default Media;
