import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {CustomTextArea, InputField, Label, RadioGroup} from "components/src/components";
//Utils
import {Constants, Strings} from "components/src/utils";
import styles from "../styles";
import {setSellerInterViewForm} from "components/src/redux/action";
import {connect} from "react-redux";


const JOINT_COST_OPTIONS = [
    {name: Strings.fourthStep.perMonth, value: Strings.fourthStep.perMonth},
    {name: Strings.fourthStep.perQuarter, value: Strings.fourthStep.perQuarter},
    {name: Strings.fourthStep.perYear, value: Strings.fourthStep.perYear},
]

const Q1 = Strings.fourthStep.q1;
const Q2 = Strings.fourthStep.q2;
const Q3 = Strings.fourthStep.q3;
const Q4 = Strings.fourthStep.q4;
const Q5 = Strings.fourthStep.q5;
const Q6 = Strings.fourthStep.q6;


const INPUTS = [{
    id: Constants.FieldId.NAME,
    title: Strings.name,
    placeholder: Strings.enterName,
    type: 'text',
    validationType: 'firstName'
}, {
    id: Constants.FieldId.EMAIL,
    title: Strings.email,
    placeholder: Strings.enterEmail,
    type: 'email',
    validationType: 'email'
}, {
    id: Constants.FieldId.PHONE,
    title: Strings.phone,
    placeholder: Strings.enterPhone,
    type: 'number',
    validationType: 'phoneNo'
}];

function FourthStep(props) {

    const {setForm, interViewForm} = props;


    const [chairmenInfo, setChairmanInfo] = useState(interViewForm.find(item => item.interview_section === 4 && item.question_no === 11)?.comment[1]?.chairman ?? {
        name:"",
        phone:"",
        email:""
    });
    const [welfareSocietyLeaderInfo, setWelfareSocietyLeaderInfo] = useState(interViewForm.find(item => item.interview_section === 4 && item.question_no === 11)?.comment[0]?.welfaresociety ?? {
        name:"",
        phone:"",
        email:""
    });

    const [membershipRequired, setMembershipRequired] = useState(interViewForm.find(item => item.interview_section === 4 && item.question_no === 11)?.answer ?? 'Ja');
    const [price, setPrice] = useState(interViewForm.find(item => item.interview_section === 4 && item.question_no === 12)?.answer ?? '');
    const [commonCost, setCommonCost] = useState(interViewForm.find(item => item.interview_section === 4 && item.question_no === 13)?.answer ?? '');

    const [jointCostType, setJointCostType] = useState(interViewForm.find(item => item.interview_section === 4 && item.question_no === 12)?.extra ?? 'Ja');
    const [extraField, setExtraField] = useState(interViewForm.find(item => item.interview_section === 4 && item.question_no === 14)?.answer ?? 'Ja');

    const [comment, setComment] = useState(interViewForm.find(item => item.interview_section === 4 && item.question_no === 12)?.comment ?? '');

    const handleChairmenInfoChange = (text, fieldId) => {
        let updatedChairmenInfo = {...chairmenInfo};
        updatedChairmenInfo[fieldId] = text;
        setChairmanInfo(updatedChairmenInfo)
    };

    const handleLeaderInfoChange = (text, fieldId) => {
        let updatedWelfareSocietyLeaderInfo = {...welfareSocietyLeaderInfo};
        updatedWelfareSocietyLeaderInfo[fieldId] = text;
        setWelfareSocietyLeaderInfo(updatedWelfareSocietyLeaderInfo)
    };

    useEffect(() => {

        let answer = [
            {
                "question": Q1,
                "comment_questions": [Q2, Q3],
                "interview_section": 4,
                "question_no": 11,
                "type": "RADIO",
                "answer": membershipRequired,
                "extra": null,
                "comment": [
                    {
                        "welfaresociety": welfareSocietyLeaderInfo
                    },
                    {
                        "chairman": chairmenInfo
                    }
                ]
            }, {
                "question": Q4,
                "interview_section": 4,
                "question_no": 12,
                "type": "TEXT",
                "answer": price,
                "extra": jointCostType,
                "comment": null
            }, {
                "question": Q5,
                "interview_section": 4,
                "question_no": 13,
                "type": "TEXT",
                "answer": commonCost,
                "extra": null,
                "comment": null
            }, {
                "question": Q6,
                "interview_section": 4,
                "question_no": 14,
                "type": "RADIO",
                "answer": extraField,
                "extra": null,
                "comment": comment
            }
        ];

        setForm([...interViewForm.filter(item => item.interview_section !== 4), ...answer])
    }, [
        chairmenInfo,
        welfareSocietyLeaderInfo,
        membershipRequired,
        price,
        commonCost,
        jointCostType,
        extraField,
        comment
    ]);

    const onFocus = (id) => {
        /*let errors = {...this.state.errors};
        errors[id] = null;
        this.setState({
            errors
        })*/
    };

    const renderChairmenInput = (item, index) => {
        return (
            <InputField key={item.id.toString()}
                        type={item.type}
                        labelProps={{
                            font_medium: true,
                        }}
                        onChange={(text) => handleChairmenInfoChange(text, item.id)}
                        onFocus={() => onFocus(item.id)}
                        value={chairmenInfo[item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };

    const renderLeaderInput = (item, index) => {
        return (
            <InputField key={item.id.toString()}
                        type={item.type}
                        labelProps={{
                            font_medium: true,
                            small: true
                        }}
                        onChange={(text) => handleLeaderInfoChange(text, item.id)}
                        onFocus={() => onFocus(item.id)}
                        value={welfareSocietyLeaderInfo[item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };


    return (
        <View>
            <Label small
                   mt={10}
                   font_medium
                   mb={10}>
                {Q1}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS_EXTRA}
                        style={styles.radio}
                        contentStyle={styles.radioCont}
                        selectedOptions={membershipRequired}
                        onSelectOption={setMembershipRequired}/>

            {membershipRequired === 'Ja' ? (
                <>
                    <Label small
                           mt={15}
                           font_medium
                           mb={10}>
                        {Q2}
                    </Label>
                    {INPUTS.map(renderLeaderInput)}
                </>
            ) : null}
            <Label small
                   mt={membershipRequired === 'Ja' ? 0 : 15}
                   font_medium
                   mb={10}>
                {Q3}
            </Label>
            {INPUTS.map(renderChairmenInput)}
            <Label small
                   mt={membershipRequired === 'Ja' ? 0 : 15}
                   font_medium
                   mb={10}>
                {Q4}
            </Label>
            <InputField labelText={'Kr'}
                        type={'number'}
                        value={price}
                        onChange={setPrice}
                        labelProps={{
                            font_medium: true,
                            small: true
                        }}/>
            <RadioGroup options={JOINT_COST_OPTIONS}
                        style={styles.radio}
                        selectedOptions={jointCostType}
                        contentStyle={styles.radioCont}
                        onSelectOption={setJointCostType}/>

            <CustomTextArea labelText={Q5}
                            value={commonCost}
                            onChange={setCommonCost}
                            mt={10}/>

            <Label small
                   mt={15}
                   font_medium
                   mb={10}>
                {Q6}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS}
                        style={[styles.radio,extraField === 'Ja'?null:{marginBottom:40}]}
                        contentStyle={styles.radioCont}
                        selectedOptions={extraField}
                        onSelectOption={setExtraField}/>

            {extraField === 'Ja' ? (
                <CustomTextArea labelText={Strings.comment}
                                value={comment}
                                onChange={setComment}
                                mb={20}
                                mt={10}/>) : null}

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form))
    }

};
export default connect(mapStateToProps, mapDispatchToProps)(FourthStep)
