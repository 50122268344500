import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Image, ScrollView, TouchableOpacity, View} from 'react-native';
import {
    CustomButton,
    CustomTextArea,
    Dialog,
    DropDown,
    Hr,
    InputField,
    Label,
    PopOver,
    RadioGroup,
    SquareButton,
    Toast
} from "components/src/components";
//Network
//redux
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    decryptPassword,
    getBoligDesc,
    getBoligOptions,
    getBoligTitle,
    getDecryptedId,
    Icon,
    IS_WEB,
    isNumeric,
    openInNewTab,
    PasswordValidate,
    Routes, showMessage,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import styles from "./styles";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Query} from "@apollo/react-components";
import {BUYER_SIGNUP} from "../../../../api/buyerMutation";
import qs from 'qs'
import newHousing from 'components/src/image/new_housing.png';
import createPassword from 'components/src/image/create_password.png';
import bankInfo from 'components/src/image/bank_info.png';
import ownership from 'components/src/image/ownership.png';
import signUpInsurance from 'components/src/image/signup_Insurance.png';
import thankYou from 'components/src/image/thank_you.png';
import {BUYER_SIGNUP_QUERY, ORIGIN_EQUITY, PURPOSE_OF_PURCHASE} from "../../../../api/buyerQuery";

//Third party

// import {Button, InputItem} from "@ant-design/react-native";
// import { DatePickerView } from 'antd-mobile';

let viewPager;

const BANK_INPUTS = [{
    id: Constants.FieldId.BANK_NAME,
    title: Strings.nameOfBank,
    placeholder: Strings.nameOfBank,
    type: 'text',
    validationType: 'name'
}, {
    id: Constants.FieldId.BANK_CONTACT,
    title: Strings.contactBank,
    placeholder: Strings.contactBank,
    type: 'text',
    validationType: 'name'
}, {
    id: Constants.FieldId.EMAIL,
    title: Strings.Email,
    placeholder: Strings.Email,
    type: 'email',
    validationType: 'email'
}, {
    id: Constants.FieldId.PHONE,
    title: Strings.Phone,
    placeholder: Strings.Phone,
    type: 'number',
    validationType: 'phoneNo'
}/*, {
    id: Constants.FieldId.ACCOUNT_NO,
    title: Strings.accountNo,
    placeholder: Strings.accountNo,
    type: 'text',
    validationType: 'accountnofortransfer'
}*/];

const BUYER_INPUTS = [{
    id: 'firstName',
    title: Strings.firstName,
    placeholder: Strings.firstName,
    type: 'text',
    validationType: 'name'
}, {
    id: 'lastName',
    title: Strings.lastName,
    placeholder: Strings.lastName,
    type: 'text',
    validationType: 'name'
}, {
    id: "personnummer",
    title: "Personnummer",
    placeholder: "Personnummer",
    type: 'text',
    validationType: 'personnummer'
}, {
    id: 'phone',
    title: Strings.phone,
    placeholder: Strings.phone,
    type: 'number',
    validationType: 'phoneNo'
}, {
    id: 'email',
    title: Strings.email,
    placeholder: Strings.email,
    type: 'email',
    validationType: 'email'
}, {
    id: 'interest',
    title: "Eierandel",
    placeholder: "Eierandel",
    type: 'text',
    disabled:true,
    // validationType: 'eierandel'
}];

const COMPANY_INPUTS = [{
    id: "companyName",
    title: "Selskapsnavn",
    placeholder: "Selskapsnavn",
    type: 'text',
    validationType: 'name'
}, {
    id: "organisationNumber",
    title: "Organisasjonsnummer",
    placeholder: "Organisasjonsnummer",
    type: 'number',
    validationType: 'organizationNo'
}, {
    id: "contactPerson",
    title: "Navn på kontaktperson",
    placeholder: "Navn på kontaktperson",
    type: 'text',
    validationType: 'name'
}, {
    id: "contactPersonSSNNo",
    title: "Fødsel og personnummer kontaktperson",
    placeholder: "Fødsel og personnummer kontaktperson",
    type: 'number',
    validationType: 'securityNo'
}, {
    id: 'phone',
    title: "Mobil",
    placeholder: "Mobil",
    type: 'number',
    validationType: 'phoneNo'
}, {
    id: 'email',
    title: "Epost",
    placeholder: "Epost",
    type: 'email',
    validationType: 'email'
}, {
    id: 'interest',
    title: "Eierandel",
    placeholder: "Eierandel",
    type: 'text',
    disabled:true,
    // validationType: 'eierandel'
}];


let toastRef;

export default function SignUp(props) {
    const {history, navigation} = props;

    const [currentStep, setCurrentStep] = useState(0);

    const [passwordVisible, setPasswordVisible] = useState('password');
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState('password');

    const [dialogVisible, setDialogVisible] = useState(false);
    const [isVisible, setPopOverVisible] = useState(false);
    const [desc, setDesc] = useState("");
    const [popOverRef, setFromView] = useState();
    const [scrollRef, setScrollRef] = useState();
    const [banks, setBanks] = useState([]);
    const [values, setValues] = useState([{}]);
    const [errors, setErrors] = useState([{}]);

    const [buyer, setBuyer] = useState([{
        isBuyer: true,
        inputs: BUYER_INPUTS
    }]);

    const [buyerValues, setBuyerValues] = useState([{
        isBuyer: true,
        value: {}
    }]);
    const [buyerErrors, setBuyerErrors] = useState([{}]);

    const [password, setPassword] = useState('');
    const [errPassword, setErrPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errConfirmPassword, setErrConfirmPassword] = useState(null);


    const [equity, setEquity] = useState('')
    const [errEquity, setErrEquity] = useState(null)

    const [otherOrigin, setOtherOriginEquity] = useState("")
    const [errOtherOrigin, setErrOtherOriginEquity] = useState(null)

    const [otherPurpose, setOtherPurpose] = useState("")
    const [errOtherPurpose, setErrOtherPurpose] = useState(null)

    const [originEquity, setOriginEquity] = useState('')
    const [isNeedLoadWithMortage, setLoanWithMortage] = useState("Ja")

    const [purchasePurpose, setPurposeOfPurchase] = useState('')
    const [politicPerson, setIsPoliticPersion] = useState("Nei")
    const [possibleReceivable, setPossibleReceivable] = useState('')
    const [errPossibleReceivable, setErrPossibleReceivable] = useState('')

    const [comment, setComment] = useState('');

    const [insuranceRequired, setInsuranceRequired] = useState(0);

    const [params, setParams] = useState({});

    const [registerBuyer, {loading, data}] = useMutation(BUYER_SIGNUP)


    const queryDetails = useQuery(BUYER_SIGNUP_QUERY, {
        skip: !Object.keys(params).length,
        variables: {
            propertyId: params?.property_id,
            buyerId: params?.user_id,
        }
    })

    useEffect(()=>{
        if(queryDetails?.error ){
            if(IS_WEB){
                history.replace(`/${Routes.Buyer}/${Routes.SignIn}`)
            }
        }
    },[queryDetails?.error])

    useEffect(() => {
        if (queryDetails?.data?.fetch_buyer_detail) {
            let buyerValues = [];
            console.log(queryDetails?.data?.fetch_buyer_detail.contactType)
            buyerValues.push({
                isBuyer: queryDetails?.data?.fetch_buyer_detail.contactType === 0,
                value: queryDetails?.data?.fetch_buyer_detail?.contactType === 0 ? {
                    firstName: queryDetails?.data?.fetch_buyer_detail?.first_name,
                    lastName: queryDetails?.data?.fetch_buyer_detail?.last_name,
                    personnummer: queryDetails?.data?.fetch_buyer_detail?.personnummer,
                    phone: queryDetails?.data?.fetch_buyer_detail?.phone,
                    email: queryDetails?.data?.fetch_buyer_detail?.email,
                    interest: queryDetails?.data?.fetch_buyer_detail?.eierandel,
                    contactId: queryDetails?.data?.fetch_buyer_detail?.contactId,
                    id: queryDetails?.data?.fetch_buyer_detail?.id,
                } : {
                    companyName: queryDetails?.data?.fetch_buyer_detail?.companyName,
                    organisationNumber: queryDetails?.data?.fetch_buyer_detail?.organisationNumber,
                    phone: queryDetails?.data?.fetch_buyer_detail?.phone,
                    email: queryDetails?.data?.fetch_buyer_detail?.email,
                    contactId: queryDetails?.data?.fetch_buyer_detail?.contactId,
                    id: queryDetails?.data?.fetch_buyer_detail?.id,
                    contactPersonSSNNo: queryDetails?.data?.fetch_buyer_detail?.ssn_number,
                    contactPerson: queryDetails?.data?.fetch_buyer_detail?.contact_person,
                    interest: queryDetails?.data?.fetch_buyer_detail?.eierandel,
                }
            });
            buyerValues = [
                ...buyerValues,
                ...queryDetails?.data?.fetch_buyer_detail?.coBuyerInfo?.map(item => {
                    return {
                        isBuyer: item.contactType === 0,
                        value: item.contactType === 0 ? {
                            firstName: item.first_name,
                            lastName: item.last_name,
                            personnummer: item?.personnummer,
                            phone: item.phone,
                            email: item.email,
                            interest: item?.eierandel,
                            ...item,
                        } : {
                            companyName: item.companyName,
                            organisationNumber: item.organisationNumber,
                            phone: item.phone,
                            email: item.email,
                            contactPersonSSNNo: item?.ssn_number,
                            contactPerson: item.contact_person,
                            interest: item?.eierandel,
                            ...item,
                        }
                    }
                })
            ]
            let updatedBuyer = buyerValues.map(item => ({
                isBuyer: item.isBuyer,
                inputs: item.isBuyer ? BUYER_INPUTS : COMPANY_INPUTS
            }));
            setBuyerErrors(new Array(buyerValues.length).fill({}))
            setBuyerValues(buyerValues)
            console.log(buyerValues)
            setBuyer(updatedBuyer)
        }
    }, [queryDetails.data])

    useEffect(() => {
        if (IS_WEB) {
            const params = qs.parse(props.location.search, {
                ignoreQueryPrefix: true
            });
            /*
            Kj%C3%B8per/meldedegpA?user_id=eyJpdiI6ImJHR256WTlBdGFnZXZJUTFkYkcxM1E9PSIsInZhbHVlIjoiZ1hEbjBKeFJZRFwvdFMwQ3ZhdXhzOUE9PSIsIm1hYyI6IjNmODAxODQwYjFkODA5ZjY4MGQ2MzQyNWY2OWEyNjhkZDRmNzFkYTA1MGNhODQ3M2I3ODNhZGJhZWM4YmY5ZjcifQ==&prop_buyer_info_id=eyJpdiI6ImduXC84dVB5cFBDVVM2SFBcL1wvYURON0E9PSIsInZhbHVlIjoiZTVUSFZNSk1aMjRZckRkdjZKVXVoUT09IiwibWFjIjoiN2E4Njk2MjgwM2IxZDEwOTM0MzI1Y2FkYTlkYTUzZjdlMDQzZjJhYWZjYTE4YmMzOTRhNmIzYjQ5YjE3YmZmOCJ9&property_id=eyJpdiI6IklJWWZXaUU4ZEVURTExbWV3enF4MEE9PSIsInZhbHVlIjoicVdUUzB4UnFjY1NuTUhiSnhKSkJ1dz09IiwibWFjIjoiMmM0NTIzZmY2ZDQ1MTAzNGVjYTU0Y2MyMTlhN2UxOTc4ZTRmZDQ5M2NiMWQyNzhlZWVlNDhkMWNiNzE0M2U3YSJ9&isActive=eyJpdiI6ImtQVFNrTnh0OTB5cXRMRXRGcnJoaFE9PSIsInZhbHVlIjoicHNkTHo5QXcwUkpXM3Rmc1F2cjEwUT09IiwibWFjIjoiZDMwYTU1YmU1MmNjZmZiYzBlOGVhMTE5ZjQyMjhhM2RiNzA0NTMxZTBhMWZlMmIzZWM3ZTZkZmQ5MDY2MzgyNiJ9
            */
            console.log(params)

            setParams({
                user_id: parseInt(getDecryptedId(params.user_id)),
                prop_buyer_info_id: parseInt(getDecryptedId(params.prop_buyer_info_id)),
                property_id: parseInt(getDecryptedId(params.property_id)),
                isActive: parseInt(getDecryptedId(params.isActive)),
            })
        }
    }, []);

    useEffect(() => {
        scrollRef && scrollRef.scrollTo({x:0,y:0,animated:false})
    }, [currentStep])

    const addBuyer = () => {

        let coBuyerRequest = buyerValues.slice(1).map(item => {
            let request = {
                co_contactType: item.isBuyer ? 0 : 1,
                co_buyer_email: item?.value?.email,
                co_buyer_mobile: item?.value?.phone,
                ownership_interest: `1/${buyerValues.length}`,
                co_eierandel: `1/${buyerValues.length}`,
                //co_eierandel: item.value?.interest ?? null
            }
            if (item.value?.id) {
                request.property_co_buyer_info_id = item.value?.id;
            }
            if (item.isBuyer) {
                /*
                person number
                interest
                */
                request = {
                    ...request,
                    co_buyer_first_name: item.value?.firstName,
                    co_buyer_last_name: item.value?.lastName,
                    co_personnummer: item.value?.personnummer,
                }
            } else {
                /*
                contact person
                contact person SSn
                interest
                */
                request = {
                    ...request,
                    co_companyName: item.value?.companyName,
                    co_buyer_first_name: item.value?.companyName,
                    co_buyer_last_name: null,
                    co_organisationNumber: item.value?.organisationNumber,
                    co_contact_person: item?.value?.contactPerson,
                    co_ssn_number: item.value?.contactPersonSSNNo
                }
            }

            request.co_contactId = item.value?.contactId ?? null

            request.co_departmentId = item.value?.departmentId ?? null

            request.co_workPhone = item.value?.workPhone ?? null

            request.co_buyer_address = item.value?.address ?? null

            request.co_buyer_zipcode = item.value?.postalCode ?? null

            request.co_buyer_city = item.value?.city ?? null

            return request
        })

        let buyerRequest = {
            property_buyer_info_id: buyerValues[0]?.value?.id,
            first_name: buyerValues[0]?.isBuyer ? buyerValues[0]?.value?.firstName : buyerValues[0]?.value?.companyName,
            last_name: buyerValues[0]?.isBuyer ? buyerValues[0]?.value?.lastName : null,
            ownership_interest: `1/${buyerValues.length}`,
            // eierandel: buyerValues[0]?.value?.interest,
            eierandel: `1/${buyerValues.length}`,
        }
        if (buyerValues[0]?.isBuyer) {
            buyerRequest = {
                ...buyerRequest,
                personnummer: buyerValues[0]?.value?.personnummer,
            }
        } else {
            buyerRequest = {
                ...buyerRequest,
                contact_person: buyerValues[0]?.value?.contactPerson,
                ssn_number: buyerValues[0].value?.contactPersonSSNNo
            }
        }

        let bankInfoRequest = {
            agencapital: equity,
            opparinelse_id: originEquity,
            pant_boligen: isNeedLoadWithMortage === "Ja" ? 1 : 0,
            pep_status: politicPerson === "Ja" ? 1 : 0,
            purpose_of_purchase_id: purchasePurpose,
            account_number: possibleReceivable,
        }
        if (originEquity === 4) {
            bankInfoRequest.opparinelse_text = otherOrigin
        }
        if (purchasePurpose === 4) {
            bankInfoRequest.purchase_text = otherPurpose
        }
        if (isNeedLoadWithMortage === "Ja") {
            bankInfoRequest.bank_details = [{
                bank_name: values[0][Constants.FieldId.BANK_NAME],
                bank_contact: values[0][Constants.FieldId.BANK_CONTACT],
                bank_email: values[0][Constants.FieldId.EMAIL],
                bank_mobile: values[0][Constants.FieldId.PHONE]
            }, ...banks.map(item => ({
                bank_name: item[Constants.FieldId.BANK_NAME],
                bank_contact: item[Constants.FieldId.BANK_CONTACT],
                bank_email: values[0][Constants.FieldId.EMAIL],
                bank_mobile: values[0][Constants.FieldId.PHONE]
            }))]
        }
        registerBuyer({
            variables: {
                input: {
                    url_param_details: params,
                    userDetails: {
                        password: password
                    },
                    bank_info: bankInfoRequest,
                    buyer_ownership: {
                        primary_buyer: buyerRequest,
                        co_buyer: coBuyerRequest
                    },
                    insurance_info: {
                        want_insurance: insuranceRequired
                    },
                }
            }
        }).then(() => {
            IS_WEB ? props.history.replace(`/${Routes.Buyer}/${Routes.Login}`) : null
        }).catch(() => {

        })
    }

    const addAdditionlBank = () => {
        const updatedBank = banks.slice();
        const updatedValue = values.slice();
        const updatedError = errors.slice();
        updatedValue.push({});
        updatedError.push({});
        setValues(updatedValue)
        setErrors(updatedError)

        updatedBank.push(BANK_INPUTS);
        setBanks(updatedBank)
    };

    const addAdditionlBuyer = () => {
        const updatedBuyer = buyer.slice();
        const updatedValue = buyerValues.slice();
        const updatedError = buyerErrors.slice();
        updatedValue.push({
            isBuyer: true,
            value: {}
        });
        updatedError.push({});
        setBuyerValues(updatedValue)
        setBuyerErrors(updatedError)
        updatedBuyer.push({
            isBuyer: true,
            inputs: BUYER_INPUTS
        });

        setBuyer(updatedBuyer)
    };

    const replaceBuyerWithCompany = (inputs, index, isBuyer, item) => {
        const updatedBuyer = buyer.slice();
        const updatedValue = buyerValues.slice();
        const updatedError = buyerErrors.slice();
        updatedValue[index] = {
            isBuyer: !isBuyer,
            value: {
                id:item.id
            }
        };
        updatedError[index] = {}
        setBuyerValues(updatedValue)
        setBuyerErrors(updatedError)

        updatedBuyer[index] = {
            isBuyer: !isBuyer,
            inputs: inputs
        };
        setBuyer(updatedBuyer)
    };

    const removeBank = (index) => {
        const updatedBank = banks.slice();
        const updatedValue = values.slice();
        const updatedError = errors.slice();
        updatedValue.splice(index, 1);
        updatedError.splice(index, 1);
        setValues(updatedValue)
        setErrors(updatedError)
        updatedBank.splice(index, 1);
        setBanks(updatedBank)
    };

    const removeBuyer = (index) => {
        const updatedBank = buyer.slice();
        const updatedValue = buyerValues.slice();
        const updatedError = buyerErrors.slice();
        updatedValue.splice(index, 1);
        updatedError.splice(index, 1);
        setBuyerValues(updatedValue)
        setBuyerErrors(updatedError)
        updatedBank.splice(index, 1);
        setBuyer(updatedBank)
    };

    const renderHeaderTitle = () => {
        return (
            <View style={styles.headerTitle}>
                <Icon name={'logo-for-placeholder'}
                      size={ThemeUtils.fontNormal * 2}
                      color={Color.COLOR_ACCENT}/>
            </View>
        )
    };

    const validateCurrentStep = () => {
        let isError = false;
        return true;
        switch (currentStep) {
            case 1:
                const errPassword = validation('passwordNoSpecialChar', password);
                const errConfirmPassword = PasswordValidate(password, confirmPassword);
                setErrPassword(errPassword);
                setErrConfirmPassword(errConfirmPassword);
                return !errPassword && !errConfirmPassword;
            case 2:
                const updatedErrors = errors.slice();
                isError = false
                if (isNeedLoadWithMortage === "Ja") {
                    values.map((bankInfo, parentIndex) => {
                        for (let i = 0; i < BANK_INPUTS.length; i++) {
                            let item = BANK_INPUTS[i];
                            let value = bankInfo[item.id] ? bankInfo[item.id].trim() : '';
                            if (item.id === Constants.FieldId.PHONE_EMAIL) {
                                updatedErrors[parentIndex][item.id] = validation(isNumeric(value) ? 'phoneNo' : 'email', value);
                            } else {
                                updatedErrors[parentIndex][item.id] = validation(item.validationType, value);
                            }
                            if (updatedErrors[parentIndex][item.id]) {
                                isError = true;
                            }
                        }
                    });
                }

                let updatedErrQuity = validation('equity', equity);
                let updatedOriginErrEquity;
                if (originEquity === 4) {
                    updatedOriginErrEquity = validation('originEquity', otherOrigin);
                } else {
                    updatedOriginErrEquity = validation('originEquity', originEquity);

                }
                let updateErrPurposeOfPurchase;
                if (purchasePurpose === 4) {
                    updateErrPurposeOfPurchase = validation('purposeOfPurchase', otherPurpose)
                } else {
                    updateErrPurposeOfPurchase = validation('purposeOfPurchase', purchasePurpose);
                }
                let updatedErrPossibleReceivable = validation('receivables', possibleReceivable)

                if (updatedErrQuity || updatedOriginErrEquity || updateErrPurposeOfPurchase || updatedErrPossibleReceivable) {
                    isError = true
                }
                if (isError) {
                    setErrEquity(updatedErrQuity)
                    setErrOtherOriginEquity(updatedOriginErrEquity)
                    setErrOtherPurpose(updateErrPurposeOfPurchase)
                    setErrPossibleReceivable(updatedErrPossibleReceivable)
                    if (purchasePurpose === 4) {
                        showMessage(updateErrPurposeOfPurchase,Constants.MessageType.FAILED)
                        if (originEquity === 4) {
                            showMessage(updatedOriginErrEquity,Constants.MessageType.FAILED)
                        }
                    }
                    setErrors(updatedErrors)
                }
                return !isError
            case 3:
                /*const errBuyer = validation('buyer', buyer1);
                const errInterest = validation('interest', interest1);
                setErrInterest1(errInterest);
                setErrBuyer1(errBuyer);
                return !errBuyer && !errInterest;*/
                const updatedBuyerErrors = buyerErrors.slice();
                isError = false;
                buyerValues.map((bankInfo, parentIndex) => {
                    console.log(bankInfo)
                    for (let i = 0; i < (bankInfo?.isBuyer ? BUYER_INPUTS.length : COMPANY_INPUTS.length); i++) {

                        let item = bankInfo?.isBuyer ? BUYER_INPUTS[i] : COMPANY_INPUTS[i];
                        let value = bankInfo.value[item.id] ? bankInfo.value[item.id].trim() : '';
                        updatedBuyerErrors[parentIndex][item.id] = validation(item.validationType, value);
                        console.log(updatedBuyerErrors[parentIndex][item.id], "ERR")
                        if (updatedBuyerErrors[parentIndex][item.id]) {
                            isError = true;
                        }
                    }
                });
                console.log(updatedBuyerErrors, isError, "ERRORS")
                if (isError) {
                    setBuyerErrors(updatedBuyerErrors)
                }
                return !isError
            default :
                return true;
        }
    };

    const nextStep = () => {
        if (currentStep < 6) {
            if (validateCurrentStep()) {
                if (currentStep === 0 && params.isActive == 1) {
                    setCurrentStep(2)
                } else {
                    setCurrentStep(prevVal => ++prevVal)
                }

            }
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            if (currentStep === 2 && params.isActive == 1) {
                setCurrentStep(0)
            } else {
                setCurrentStep(prevVal => --prevVal)
            }
        }
    };

    const handleChangeText = (text, fieldId, parentIndex) => {
        let updatedValues = values.slice();
        updatedValues[parentIndex] = {
            ...updatedValues[parentIndex],
            [fieldId]: text.split(" ").join("")
        };
        setValues(updatedValues)
    };

    const onFocus = (id, parentIndex) => {
        let updatedErrors = errors.slice();
        updatedErrors[parentIndex][id] = null;
        setErrors(updatedErrors)
    };

    const handleBuyerChangeText = (text, fieldId, parentIndex, isBuyer) => {
        let updatedValues = buyerValues.slice();
        console.log(isBuyer, "ISBUYER")
        updatedValues[parentIndex] = {
            isBuyer: isBuyer,
            value: {
                ...updatedValues[parentIndex].value,
                [fieldId]: text.split(" ").join("")
            }
        };
        setBuyerValues(updatedValues)
    };

    const onBuyerFocus = (id, parentIndex) => {
        let updatedErrors = buyerErrors.slice();
        updatedErrors[parentIndex][id] = null;
        setBuyerErrors(updatedErrors)
    };

    const renderAdditionalBank = (item, parentIndex) => {
        return (
            <View key={parentIndex.toString()}>
                <Hr/>
                <View style={styles.bankLblCont}>
                    <Label small
                           style={CommonStyle.flex}
                           font_medium>
                        {`${Strings.additionalBank} ${parentIndex + 2} :`}
                    </Label>
                    <TouchableOpacity activeOpacity={0.5}
                                      onPress={() => removeBank(parentIndex)}>
                        <Icon name={'order-cancel'}
                              size={ThemeUtils.fontNormal}
                              color={Color.RED}/>
                    </TouchableOpacity>
                </View>
                {item.map((item, index) => renderBankForm(item, index, parentIndex))}
            </View>
        )
    }

    const renderBankForm = (item, index, parentIndex) => {
        return (
            <InputField key={item.id.toString()}
                        textArea={item.type === 'textArea'}
                        type={item.type}
                        labelProps={{
                            small: true,
                            font_medium: true,
                        }}
                        style={{marginTop: 0}}
                        disabled={item.disabled}
                        onChange={(text) => handleChangeText(text, item.id, parentIndex)}
                        onFocus={() => onFocus(item.id, parentIndex)}
                        error={errors[parentIndex][item.id]}
                        value={values[parentIndex][item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };

    const renderAdditionalBuyer = (parentItem, parentIndex) => {

        return (
            <View key={parentIndex.toString()}>
                {parentIndex !== 0 ? (<Hr/>) : null}
                <View style={styles.bankLblCont}>
                    <Label small
                           style={CommonStyle.flex}
                           font_medium>
                        {`${Strings.buyer} ${parentIndex + 1} :`}
                    </Label>
                    {parentIndex !== 0 && !buyerValues[parentIndex]?.value?.id ? (
                        <TouchableOpacity activeOpacity={0.5}
                                          onPress={() => removeBuyer(parentIndex)}>
                            <Icon name={'order-cancel'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.RED}/>
                        </TouchableOpacity>) : null}
                </View>
                <Label small
                       font_medium
                       mb={10}>
                    {"Er eier et aksjeselskap?"}
                </Label>
                <RadioGroup changeOnProp selectedOptions={parentItem.isBuyer ? "Nei" : "Ja"}
                            disabled={parentIndex===0}
                            onSelectOption={(val) => {
                                replaceBuyerWithCompany(val === "Nei" ? BUYER_INPUTS : COMPANY_INPUTS, parentIndex, parentItem.isBuyer,buyerValues[parentIndex]?.value)
                            }}
                            contentStyle={{flexDirection: 'row', marginBottom: 20}}
                            options={Constants.RADIO_OPTIONS}/>
                {parentItem.inputs.map((item, index) => renderBuyerForm(item, index, parentIndex, parentItem.isBuyer))}
            </View>
        )
    }

    const renderBuyerForm = (item, index, parentIndex, isBuyer) => {
        return (
            <InputField key={item.id.toString()}
                        textArea={item.type === 'textArea'}
                        type={item.type}
                        disabled={item.disabled || (parentIndex === 0 && (item.id === "email" || item.id === "phone" || item.id === "personnummer" || item.id === "contactPerson" || item.id === "contactPersonSSNNo" || item.id === "organisationNumber"))}
                        labelProps={{
                            small: true,
                            font_medium: true,
                        }}
                        style={{marginTop: 0}}

                        onChange={(text) => handleBuyerChangeText(text, item.id, parentIndex, isBuyer)}
                        onFocus={() => onBuyerFocus(item.id, parentIndex)}
                        error={buyerErrors[parentIndex][item.id]}
                        value={item.id === "interest" ? `1/${buyer.length}` : buyerValues[parentIndex]?.value[item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };

    const renderFirstStep = () => {
        return (

            <View style={styles.stepCont}>
                <Image source={newHousing}
                       style={styles.imgSignUp}/>
                <Label xxlarge
                       font_medium
                       mt={15}
                       mb={15}
                       color={Color.TERNARY_TEXT_COLOR}
                       align={'center'}>
                    {"Gratulerer med handelen"}
                </Label>
                <Label small
                       mb={15}
                       align={'justify'}>
                    {`Vi viser til Deres kjøpetilbud på ${queryDetails?.data?.fetch_buyer_detail?.property?.street_adress ?? ""} – Gnr. ${queryDetails?.data?.fetch_buyer_detail?.property?.gnr ?? ""}, Bnr. ${queryDetails?.data?.fetch_buyer_detail?.property?.bnr ?? ""}${queryDetails?.data?.fetch_buyer_detail?.property?.fnr ? ", Fnr. " + queryDetails?.data?.fetch_buyer_detail?.property?.fnr ?? "" : ""}${queryDetails?.data?.fetch_buyer_detail?.property?.fnr ? ", Snr. " + queryDetails?.data?.fetch_buyer_detail?.property?.snr ?? "" : ""} i ${queryDetails?.data?.fetch_buyer_detail?.property?.municipality ?? ""}. Vi gratulerer Dem med at budet er akseptert av selger og at det derved er inngått en bindende avtale mellom Dem og selger.\n\nDere vil nå bli tatt gjennom noen steg hvor Dere fyller ut opplysninger som vi trenger for å klargjøre og ferdigstille nødvendige dokumenter. Når alle stegene er ferdig utfylt vil dere få tilgang til deres egen portal med alle relevante opplysninger i forbindelse med handelen.\n\nVi ser frem til å bistå Dem videre på best mulig måte!`}
                </Label>

            </View>

        )
    }

    const renderSecondStep = () => {
        let passwordRef, confirmPasswordRef;

        return (
            <View style={styles.stepCont}>
                <Image source={createPassword}
                       style={styles.imgSignUp}/>
                <Label xxlarge
                       font_medium
                       mt={15}
                       mb={15}
                       color={Color.TERNARY_TEXT_COLOR}
                       align={'center'}>
                    {Strings.createPassword}
                </Label>
                <Label small
                       mb={15}
                       align={'justify'}>
                    {'Dere må her velge et passord for å logge inn på Deres portal. Brukernavnet er epostadressen som Dere er registrert med hos W Eiendomsmegling.'}
                </Label>
                <View style={styles.lblCont}>
                    <Label small
                           font_medium>
                        {`${Strings.password} `}
                    </Label>
                    {/*<TouchableOpacity ref={r => passwordRef = r}
                                      onPress={() => {
                                          setDesc(Strings.password)
                                          setFromView(passwordRef)
                                          setPopOverVisible(true)
                                      }}>
                        <Icon name={'faq'}
                              color={Color.PRIMARY}/>
                    </TouchableOpacity>*/}
                </View>
                <InputField
                    extra={
                        <TouchableOpacity ref={r => confirmPasswordRef = r}
                                          style={{
                                              flex: 1,
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              alignSelf: 'center',
                                              paddingHorizontal: 10
                                          }}
                                          onPress={() => {
                                              if (passwordVisible === "password")
                                                  setPasswordVisible('text')
                                              else
                                                  setPasswordVisible('password')
                                          }}>
                            <Icon name={passwordVisible === "text" ? "view-general-icon" : 'pwd-hide'}
                                  size={ThemeUtils.fontSmall}
                                  color={Color.PRIMARY}/>
                        </TouchableOpacity>
                    }
                    placeholder={Strings.password}
                    onChange={setPassword}
                    error={errPassword}
                    value={password}
                    type={passwordVisible}
                    onFocus={() => setErrPassword(null)}
                    labelProps={{
                        small: true,
                        font_medium: true
                    }}/>
                <View style={styles.lblCont}>
                    <Label small
                           mt={ThemeUtils.relativeRealHeight(1)}
                           font_medium>
                        {`${Strings.ConfirmPassword} `}
                    </Label>
                    {/*<TouchableOpacity ref={r => confirmPasswordRef = r}
                                      onPress={() => {
                                          setDesc(Strings.ConfirmPassword)
                                          setFromView(confirmPasswordRef)
                                          setPopOverVisible(true)
                                      }}>
                        <Icon name={'faq'}
                              color={Color.PRIMARY}/>
                    </TouchableOpacity>*/}
                </View>
                <InputField placeholder={Strings.ConfirmPassword}
                            onChange={setConfirmPassword}
                            extra={
                                <TouchableOpacity ref={r => confirmPasswordRef = r}
                                                  style={{
                                                      flex: 1,
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                      alignSelf: 'center',
                                                      paddingHorizontal: 10
                                                  }}
                                                  onPress={() => {
                                                      if (confirmPasswordVisible === "password")
                                                          setConfirmPasswordVisible('text')
                                                      else
                                                          setConfirmPasswordVisible('password')
                                                  }}>
                                    <Icon
                                        name={confirmPasswordVisible === "text" ? "view-general-icon" : 'pwd-hide'}
                                        size={ThemeUtils.fontSmall}
                                        color={Color.PRIMARY}/>
                                </TouchableOpacity>
                            }
                            error={errConfirmPassword}
                            value={confirmPassword}
                            type={confirmPasswordVisible}
                            onFocus={() => setErrConfirmPassword(null)}
                            labelProps={{
                                small: true,
                                font_medium: true
                            }}/>
            </View>
        )
    }

    const renderThirdStep = () => {
        let ref, accountNoRef;
        return (
            <View>
                <Toast ref={r => toastRef = r}/>
                <View style={styles.stepCont}>
                    <Image source={bankInfo}
                           style={styles.imgSignUp}/>
                    <Label xxlarge
                           font_medium
                           mt={15}
                           mb={15}
                           color={Color.TERNARY_TEXT_COLOR}
                           align={'center'}>
                        {"Finansiering"}
                    </Label>
                    <Label small
                           mb={15}
                           align={'justify'}>
                        {'Vi trenger å vite hvordan Dere vil finansiere kjøpet slik at vi kan sørge for en effektiv dialog med Deres bank og klargjøre oppgjøret i god tid før overtagelse.\n\n' +
                        'Skal kjøpet finansieres med lån fra mer enn én bank, trykk «Jeg/vi har flere llånn».\n\n' +
                        'Skal det ikke tinglyses pant i eiendommen dere her har kjøpt trenger vi likevel opplysningene under for å kunne bekrefte finansiering. Kryss da «nei» ved spørsmålet om det skal tinglyses pant i eiendommen.'}
                    </Label>
                    <View>
                        <Label font_medium
                               mb={ThemeUtils.relativeRealHeight(2)}>
                            {"Finansiering"}
                        </Label>
                        <InputField labelText={"Bank for egenkapital"}
                                    onChange={setEquity}
                                    type={"text"}
                                    onFocus={() => setErrEquity(null)}
                                    value={equity}
                                    error={errEquity}
                                    placeholder={"Bank for egenkapital"}/>
                        <Query query={ORIGIN_EQUITY}
                               variables={{
                                   status: 1
                               }}>
                            {({loading, data, error}) => {

                                if (loading) {
                                    return (
                                        <ActivityIndicator color={Color.PRIMARY}/>
                                    )
                                }
                                return (
                                    <DropDown title={"Opprinnelse egenkapital"}
                                              onSelectOptions={setOriginEquity}
                                              selectedOption={originEquity}
                                              options={data?.opprinnelse_egenkapital_option}/>
                                )
                            }}
                        </Query>

                        {originEquity === 4 ? (
                            <InputField mt={ThemeUtils.relativeRealHeight(2)}
                                        onChange={setOtherOriginEquity}
                                        type={"text"}
                                        onFocus={() => setErrOtherOriginEquity(null)}
                                        value={otherOrigin}
                                        error={errOtherOrigin}
                                        placeholder={"Opprinnelse egenkapital"}/>) : null}
                        <Label font_medium
                               mt={ThemeUtils.relativeRealHeight(3)}
                               mb={ThemeUtils.relativeRealHeight(2)}>
                            {"Lån / Pant i boligen"}
                        </Label>
                        <Label small
                               font_medium>
                            {"Skal du ha lån med pant i boligen?"}
                        </Label>

                        <RadioGroup changeOnProp options={Constants.RADIO_OPTIONS}
                                    onSelectOption={setLoanWithMortage}
                                    contentStyle={{
                                        flexDirection: 'row',
                                        marginTop: ThemeUtils.relativeRealHeight(1),
                                        marginBottom: ThemeUtils.relativeRealHeight(2)
                                    }}
                                    selectedOptions={isNeedLoadWithMortage}
                        />
                    </View>
                    {isNeedLoadWithMortage === "Ja" ? (<View>
                        {BANK_INPUTS.map((item, index) => renderBankForm(item, index, 0))}
                        {banks.length !== 0 ? (
                            <>
                                {banks.map(renderAdditionalBank)}
                            </>
                        ) : null}
                        <CustomButton mb={ThemeUtils.relativeRealHeight(1)}
                                      title={"Jeg/vi har flere lån"}
                                      onPress={addAdditionlBank}/>
                    </View>) : null}

                    <View style={[styles.lblCont, {marginTop: ThemeUtils.relativeRealHeight(2)}]}>
                        <Label font_medium
                               mr={10}>
                            {"Politisk eksponert person (PEP)"}
                        </Label>
                        <TouchableOpacity ref={r => ref = r}
                                          onPress={() => {
                                              setDesc("En politisk eksponert person (PEP) er en fysisk person som er i, eller har hatt, et høytstående offentlig verv eller stilling.")
                                              setFromView(ref)
                                              setPopOverVisible(true)
                                          }}>
                            <Icon name={'faq'}
                                  size={ThemeUtils.fontSmall}
                                  color={Color.PRIMARY}/>
                        </TouchableOpacity>
                    </View>
                    <Label small
                           font_medium>
                        {"Er du/dere en politisk(e) eksponert person(er)?"}
                    </Label>
                    <RadioGroup changeOnProp options={Constants.RADIO_OPTIONS}
                                contentStyle={{
                                    flexDirection: 'row',
                                    marginVertical: ThemeUtils.relativeRealHeight(1)
                                }}
                                selectedOptions={politicPerson}
                                onSelectOption={setIsPoliticPersion}
                    />
                    <Label font_medium
                           mt={ThemeUtils.relativeRealHeight(2)}
                           mb={ThemeUtils.relativeRealHeight(1)}>
                        {"Formål med kjøp"}
                    </Label>

                    <Query query={PURPOSE_OF_PURCHASE}
                           variables={{
                               status: 1
                           }}>
                        {({loading, data, error}) => {

                            if (loading) {
                                return (
                                    <ActivityIndicator color={Color.PRIMARY}/>
                                )
                            }
                            return (
                                <DropDown style={{
                                    marginBottom: ThemeUtils.relativeRealHeight(2)
                                }}
                                          selectedOption={purchasePurpose}
                                          onSelectOptions={setPurposeOfPurchase}
                                          options={data?.purpose_purchase_option ?? []}/>
                            )
                        }}
                    </Query>

                    {purchasePurpose === 4 ? (
                        <InputField onChange={setOtherPurpose}
                                    value={otherPurpose}
                                    type={"text"}
                                    onFocus={() => setErrOtherPurpose(null)}
                                    error={errOtherPurpose}
                                    placeholder={"Formål med kjøp"}/>) : null}

                    <Label font_medium
                           mt={ThemeUtils.relativeRealHeight(1)}
                           mb={ThemeUtils.relativeRealHeight(1)}>
                        {"Eventuelt tilgodehavende"}
                    </Label>

                    <View style={styles.lblCont}>
                        <Label small
                               font_medium
                               mr={5}>
                            {"Overføres til kontonummer"}
                        </Label>
                        <TouchableOpacity ref={r => accountNoRef = r}
                                          onPress={() => {
                                              setDesc("Dersom det innbetales for mye i oppgjøret eller Dere skulle ha krav på å få tilbakebetalt penger, vil dette overføres til kontonummeret Dere skriver inn her.")
                                              setFromView(accountNoRef)
                                              setPopOverVisible(true)
                                          }}>
                            <Icon name={'faq'}
                                  size={ThemeUtils.fontSmall}
                                  color={Color.PRIMARY}/>
                        </TouchableOpacity>
                    </View>
                    <InputField type={"number"}
                                onChange={setPossibleReceivable}
                                error={errPossibleReceivable}
                                onFocus={() => setErrPossibleReceivable(null)}
                                value={possibleReceivable}
                                placeholder={"Overføres til kontonummer"}/>
                </View>
            </View>
        )
    }

    const renderFourthStep = () => {
        return (
            <>
                <View style={styles.stepCont}>
                    <Image source={ownership}
                           style={styles.imgSignUp}/>
                    <Label xxlarge
                           font_medium
                           mt={15}
                           mb={15}
                           color={Color.TERNARY_TEXT_COLOR}
                           align={'center'}>
                        {"Eierforhold"}
                    </Label>
                    <Label small
                           mb={15}
                           align={'justify'}>
                        {'Her fyller dere inn opplysninger om de som skal stå som eier(e) av eiendommen. Opplysningene som er forhåndsutfylt er de samme som Dere fylte inn i budskjema. Vær nøye med å påse at samtlige opplysninger er korrekt og korriger dem hvis nødvendig. Det er spesielt viktig at Deres fulle navn samsvarer med opplysningene i folkeregisteret. Korriger også opplysningene dersom andre eller flere enn budgiver(ne) skal stå som eier(e) av eiendommen. Trykk «legg til ny kjøper» om nødvendig.'}
                    </Label>
                    <Label small
                           mb={5}
                           font_medium>
                        {'Vedrørende eierandel'}
                    </Label>
                    <Label small
                           mb={10}
                           align={'justify'}>
                        {'Dersom det kun er én eier skriver man her 1.\n' +
                        'Ved to eiere som skal eie like stor andel hver skriver man ½ på begge.\n' +
                        'Ved to eiere som ikke skal eie lik andel kan dette eksempelvis skrives på følgende\n' +
                        'måte; 33/100 på den ene og 67/100 på den andre.\n' +
                        'Ved flere enn to eiere kan det eksempelvis skrives på følgende to måter\n' +
                        '- 1/3 på hver\n' +
                        '- 10/100 på den ene, 50/100 på den andre og 40/100 på den tredje (totalt\n' +
                        '100/100)\n\n' +
                        'Er Dere fortsatt usikker, kontakt megler.'}
                    </Label>
                    {/*<InputField labelText={Strings.nameBuyer1}
                            placeholder={Strings.nameBuyer1}
                            onChange={setBuyer1}
                            error={errBuyer1}
                            value={buyer1}
                            type={'text'}
                            onFocus={() => setErrBuyer1(null)}
                            labelProps={{
                                small: true,
                                font_medium: true
                            }}/>
                <View style={styles.lblCont}>
                    <Label small
                           font_medium>
                        {`${Strings.ownershipInterestInHernia} `}
                    </Label>
                    <TouchableOpacity>
                        <Icon name={'faq'}
                              color={Color.PRIMARY}/>
                    </TouchableOpacity>
                </View>
                <InputField placeholder={Strings.ownershipInterestInHernia}
                            onChange={setInterest1}
                            error={errInterest1}
                            value={interest1}
                            type={'text'}
                            onFocus={() => setErrInterest1(null)}
                            labelProps={{
                                small: true,
                                font_medium: true
                            }}/>
                <InputField labelText={Strings.nameBuyer2}
                            placeholder={Strings.nameBuyer2}
                            onChange={setBuyer2}
                            value={buyer2}
                            type={'text'}
                            labelProps={{
                                small: true,
                                font_medium: true
                            }}/>
                <View style={styles.lblCont}>
                    <Label small
                           font_medium>
                        {`${Strings.ownershipInterestInHernia} `}
                    </Label>
                    <TouchableOpacity>
                        <Icon name={'faq'}
                              color={Color.PRIMARY}/>
                    </TouchableOpacity>
                </View>
                <InputField placeholder={Strings.ownershipInterestInHernia}
                            onChange={setInterest2}
                            value={interest2}
                            type={'text'}
                            labelProps={{
                                small: true,
                                font_medium: true
                            }}/>*/}
                    {/* <Label small
                           style={CommonStyle.flex}
                           mb={10}
                           font_medium>
                        {`${Strings.buyer} 1`}
                    </Label>*/}
                    {/*{BUYER_INPUTS.map((item, index) => renderBuyerForm(item, index, 0, true))}*/}
                    {buyer.length !== 0 ? (
                        <>
                            {buyer.map(renderAdditionalBuyer)}
                        </>
                    ) : null}
                    <CustomButton mb={10}
                                  mt={10}
                                  title={"Legg til medeier"}
                                  onPress={addAdditionlBuyer}/>
                </View>
            </>
        )
    }


    const renderBoligOptions = (item, index) => {
        let ref;
        return (
            <View key={index.toString()}
                  style={styles.lblCont}>
                <Label small
                       font_medium
                       mr={5}>
                    {`•  ${item.name}`}
                </Label>
                <TouchableOpacity ref={r => ref = r}
                                  onPress={() => {
                                      setDesc(item.value)
                                      setFromView(ref)
                                      setPopOverVisible(true)
                                  }}>
                    <Icon name={'faq'}
                          size={ThemeUtils.fontSmall}
                          color={Color.PRIMARY}/>
                </TouchableOpacity>

            </View>
        )
    }
    const renderFifthStep = () => {
        return (
            <>
                <View style={styles.stepCont}>
                    <Dialog visible={dialogVisible}
                            title={"Jeg ønsker å være uforsikret"}
                            desc={"Vi anbefaler på det sterkeste at alle våre kunder, både kjøper og selger, tegner forsikring i forbindelse med handelen. Om du likevel" +
                            " ikke ønsker å tegne forsikring er det viktig for oss at du er kjent med risikoen det innebærer å ikke tegne slik forsikring. Les mer under " +
                            "«Trenger jeg boligkjøperforsikring» eller kontakt megler for nærmere informasjon om du er usikker."}
                            buttonTitleFirst={Strings.no}
                            buttonTitleSecond={Strings.yes}
                            onPositivePress={() => setDialogVisible(false)}
                            onNegativePress={() => {
                                setInsuranceRequired(0);
                                setDialogVisible(false)
                            }}/>
                    <Image source={signUpInsurance}
                           style={styles.imgSignUp}/>
                    <Label xxlarge
                           font_medium
                           mt={15}
                           mb={15}
                           color={Color.TERNARY_TEXT_COLOR}
                           align={'center'}>
                        {getBoligTitle(queryDetails?.data?.fetch_buyer_detail?.property?.ownership, parseInt(queryDetails?.data?.fetch_buyer_detail?.property?.usable_area ?? 0, 10))}
                    </Label>
                    <Label small
                           mb={15}
                           align={'justify'}>
                        {getBoligDesc(queryDetails?.data?.fetch_buyer_detail?.property?.ownership, parseInt(queryDetails?.data?.fetch_buyer_detail?.property?.usable_area ?? 0, 10))}
                    </Label>
                    {getBoligOptions(queryDetails?.data?.fetch_buyer_detail?.property?.ownership, parseInt(queryDetails?.data?.fetch_buyer_detail?.property?.usable_area ?? 0, 10))?.map(renderBoligOptions)}
                    <Label small
                           mt={10}
                           mb={20}>
                        {"I tillegg kan du få en vesentlig besparelse fra If på dine øvrige forsikringer hvis ønskelig."}
                    </Label>

                    <CustomButton mb={20}
                                  title={"Trenger jeg boligkjøperforsikring? Les mer her"}/>
                    {/* <CustomButton mb={20}
                                  onPress={() => openInNewTab("https://cdn.weiendomsmegling.no/pdf/Produktark_Boligkjoperpakken.pdf")}
                                  title={"Les mer om boligkjøperpakken"}/>
                    <CustomButton mb={20}
                                  onPress={() => openInNewTab("https://cdn.weiendomsmegling.no/pdf/Produktark_Boligkjoperforsikring.pdf")}
                                  title={"Boligkjøperforsikring"}/>*/}
                    <RadioGroup changeOnProp options={[{
                        name: "Jeg vil tegne boligkjøperpakken",
                        value: 0
                    }, {
                        name: queryDetails?.data?.fetch_buyer_detail?.property?.ownership === 14 || queryDetails?.data?.fetch_buyer_detail?.property?.ownership === 15 ? "Jeg er usikker og ønsker råd fra megler" : "Jeg vil kun tegne boligkjøperforsikring",
                        value: 1
                    }, {
                        name: "Jeg ønsker å være uforsikret",
                        value: 2
                    }, {
                        name: "Jeg kan ikke tegne forsikring ihht vilkår",
                        value: 3
                    }]}
                                selectedOptions={insuranceRequired}
                                style={{marginVertical: 20}}
                                onSelectOption={(val) => {
                                    if (val === 2) {
                                        setDialogVisible(true)
                                    }
                                    setInsuranceRequired(val)
                                }}/>
                </View>
            </>
        )
    }

    const renderSixthStep = () => {
        return (
            <View style={styles.stepCont}>
                <Label xxlarge
                       font_medium
                       mt={15}
                       mb={15}
                       color={Color.TERNARY_TEXT_COLOR}
                       align={'center'}>
                    {Strings.contract}
                </Label>
                <Label small
                       mb={15}
                       align={'center'}>
                    {'Her finner du første utkast til kjøpekontrakt. Kontraktene er standard kontrakter til bruk av kjøp og salg av bolig. Les gjennom for å sjekke at all informasjon er korrekt. Har du kommentarer skriver du de nedenfor.'}
                </Label>
                <CustomButton title={Strings.readContractDraft}
                              onPress={() => openInNewTab("")}/>
                <CustomTextArea mt={20}
                                type={'text'}
                                onChange={setComment}
                                value={comment}
                                placeholder={Strings.writeYourComments}
                                labelText={Strings.comment}/>
            </View>
        )
    }

    const renderSeventhStep = () => {
        return (
            <>
                <View style={styles.stepCont}>
                    <Image source={thankYou}
                           style={styles.imgSignUp}/>
                    <Label xxlarge
                           font_medium
                           mt={15}
                           mb={15}
                           color={Color.TERNARY_TEXT_COLOR}
                           align={'center'}>
                        {"Fullfør"}
                    </Label>
                    <Label small
                           mb={15}
                           align={'justify'}>
                        {'Dere har nå sendt oss den informasjonen vi trenger for å kunne ferdigstille' +
                        'nødvendige dokumenter i forbindelse med Deres kjøp.\n\n' +
                        'Ved å trykke på «Lagre og logg inn» vil dere få tilgang til Deres web-portal. Her vil' +
                        'Dere finne informasjon om selger, dokumenter til signering, øvrige dokumenter om ' +
                        'eiendommen og prosessen videre frem til overtagelse. Dere vil også kunne chatte ' +
                        'direkte med megler og selger.\n\n' +
                        'Vi håper at vår digitale plattform vil gjøre prosessen frem mot overtagelse mer ' +
                        'oversiktlig, samt at Dere alltid vil ha et sted å finne tilbake til relevant informasjon ' +
                        'om handelen.\n\n' +
                        'Samtlige kontraktsdokumenter vil gjøres tilgjengelig for e-signering med BankID i ' +
                        'Deres web-portal. Vi oppfordrer alle våre kunder til å signere på denne måten slik at ' +
                        'vi sammen sørger for en ryddig, sikker og effektiv prosess, samtidig som vi sparer ' +
                        'miljøet. For ordens skyld; e-signering av dokumenter er ikke til hinder for å gjøre ' +
                        'dette sammen med megler på meglers kontor. Kontakt megler dersom dette er ' +
                        'ønskelig.'}
                    </Label>
                    <CustomButton mt={20}
                                  loading={loading}
                                  onPress={addBuyer}
                                  title={Strings.saveLogin}/>
                </View>
            </>
        )
    };

    const getCurrentStep = () => {
        switch (currentStep) {
            case 0:
                return renderFirstStep()
            case 1:
                return renderSecondStep()
            case 2:
                return renderThirdStep()
            case 3:
                return renderFourthStep()
            case 4:
                return renderFifthStep()
            case 5:
                return renderSeventhStep()
        }
    }
    return (
        <View style={styles.container}>
            {renderHeaderTitle()}
            <PopOver isVisible={isVisible}
                     fromView={popOverRef}

                     onRequestClose={() => setPopOverVisible(false)}>
                <View style={{paddingHorizontal: 10}}>

                    <Label small mb={10} mt={10}>
                        {desc}
                    </Label>
                </View>
            </PopOver>

            {/*<ViewPager ref={(ref) => viewPager = ref}
                           style={{flexGrow: 1}}
                >

                    {renderFirstStep()}
                    {renderSecondStep()}
                    {renderThirdStep()}
                    {renderFourthStep()}
                    {renderFifthStep()}
                    {renderSixthStep()}
                    {renderSeventhStep()}

                </ViewPager>*/}
            <View style={CommonStyle.container}>
                {queryDetails?.loading ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>
                ) : (
                    <ScrollView ref={r=>setScrollRef(r)}>
                        {getCurrentStep()}
                    </ScrollView>
                )}
            </View>
            <View style={styles.bottomNavCont}>
                <SquareButton borderColor={Color.PRIMARY}
                              style={{flex: 0.5}}
                              onPress={prevStep}
                              textColor={Color.PRIMARY}
                              backgroundColor={Color.WHITE}>
                    {Strings.prev}
                </SquareButton>
                <Label small
                       font_medium
                       align={'center'}
                       style={CommonStyle.flex}>
                    {`${currentStep + 1} / 6`}
                </Label>

                {currentStep !== 5 ? (<SquareButton borderColor={Color.SECONDARY}
                                                    loading={loading}
                                                    style={{flex: 0.5}}
                                                    onPress={nextStep}
                                                    textColor={Color.WHITE}
                                                    backgroundColor={Color.SECONDARY}>
                    {Strings.next}
                </SquareButton>) : <View style={{flex: 1}}/>}
            </View>
        </View>
    )
}
