/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    CaretDownOutlined,
    MinusCircleOutlined,
    LoadingOutlined,
    UploadOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Typography,
    Space,
    notification,
    Spin,
    Upload,
} from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

import {Mutation, Query} from 'react-apollo';
import {decryptId} from '../../../../utils/utils';
import {STORAGE_URL} from '../../../../utils/constant';
import {ADMIN_DELETE_JOB_CONTACT, ADMIN_UPDATE_JOBS} from '../../../../../mutation/AdminMutation';
import {ADMIN_JOB_DETAIL} from '../../../../../query/AdminQuery';
import {useHistory} from "react-router";
import {formatMessage} from "components/src/utils";
import { isEmpty } from 'lodash';
import PlaceHolderImage from '../../../../assets/defaultuser.png';

const {Title} = Typography;
let fullObject;

let otherExpense = null;
let dataRefetch;
const EditJobs = props => {
    const router=useHistory();
    const formRef = useRef(null);

    const { id } = props.match.params;
    const [editorState, seteditorState] = useState(null);
    const [editorState2, seteditorState2] = useState(null);
    const [editorState3, seteditorState3] = useState(null);
    const [successModal, setSuccessModal] = useState(false);
    const [formValue, setFormValue] = useState();
    const [keysInitial, setkeysInitial] = useState([0]);
    const [initialId, setinitialId] = useState(0);
    const [contactDetail, setcontactDetail] = useState([]);
    const [fullFormData, setfullFormData] = useState();
    const [imageObject, setimageObject] = useState();
    const [imageUrl, setimageUrl] = useState();
    const [isimageUrlChanged, setisimageUrlChanged] = useState(false);
    const [isimageDeleted, setisimageDeleted] = useState(false);

    const optionsData = [
        { value: 'FULLTIME', label: 'Fulltid' },
        { value: 'PARTTIME', label: 'Deltid' },
    ];

    useEffect(() => {
        seteditorState(BraftEditor.createEditorState());
        seteditorState2(BraftEditor.createEditorState());
        seteditorState3(BraftEditor.createEditorState());
    }, []);

    const setEditorContentAsync = async formData => {
        if (formRef.current !== null) {
            const keyArray = Array.from(Array(formData.contact_info).keys());
            setkeysInitial(keyArray);
            formRef.current.setFieldsValue({
                rightsidedescription: BraftEditor.createEditorState(
                    formData && formData?.rightside_description,
                ),

                whatareyoulookingfor: BraftEditor.createEditorState(
                    formData && formData?.looking_for_description,
                ),
                whatyouoffer: BraftEditor.createEditorState(formData && formData?.what_you_offer),
                jobsuser: formData.contact_info.map(
                    e =>
                        e > 1 && {
                            name: e.name,
                            email: e.email,
                            phone: e.phone,
                        },
                ),
            });
        }
    };

    const submitSummaryContent = async () => {
        const htmlContent = editorState.toHTML();
    };

    const submitSummaryContent2 = async () => {
        const htmlContent = editorState2.toHTML();
    };

    const submitSummaryContent3 = async () => {
        const htmlContent = editorState3.toHTML();
    };

    const handleEditorChange = editor => {
        seteditorState(editor);
    };

    const handleEditorChange2 = editor => {
        seteditorState2(editor);
    };

    const handleEditorChange3 = editor => {
        seteditorState3(editor);
    };

    const onFinish = values => {
        console.log('Success:', values);
        console.log('jobsuser:', values?.jobsuser);
        setFormValue(values);
    };

    const onClickOk = (updatejob, values) => {
        console.log('VALUES:', values);
        console.log('values.contact_info:', values.contact_info);
        console.log('Object.keys(values.contact_info):', Object.keys(values.contact_info));

        const contactsData = [];
        Object.keys(values.contact_info).forEach(key => {
            const contactsItem = values.contact_info[key];
            console.log('Receivables==>>', contactsItem);
            const contact = {
                id: contactsItem.id || null,
                job_id: decryptId(id),
                name: contactsItem.name,
                email: contactsItem.email,
                phone: contactsItem.mobile_no,
            };
            contactsData.push(contact);
        });
        const variables = {
            id: decryptId(id),
            title: values.jobtitle,
            location: values.location,
            company_name: values.companyname,
            party: values.party,
            type: values.jobtype,
            looking_for_description: editorState2.toHTML(),
            what_you_offer: editorState3.toHTML(),
            is_image_updated: isimageUrlChanged,
            contact_info: contactsData,
        };
        if (!isEmpty(editorState.toHTML())) {
            variables.rightside_description = editorState.toHTML();
        }
        if (values.jobid) {
            variables.code = values.jobid;
        }

        if (isimageUrlChanged && imageObject && !isimageDeleted) {
            console.log('image:', imageObject);
            variables.image_url = imageObject;
        }
        updatejob({
            variables,
        }).then(({ data }) => {
            console.log('Data submitted===>>>', data);
            if (data && data.update_job) {
                notification.success({
                    message: 'Jobber lagt til',
                    description: '',
                    duration: 3,
                });
                setSuccessModal(false);
                router.push(`/admin/ledigstilling`);
            }
        });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleUploadChange = info => {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, image => setimageUrl(image));
        setimageObject(info.file.originFileObj);
        setisimageUrlChanged(true);
    };

    const addBankForm = () => {
        console.log('initi id:', initialId);
        console.log('keys initial:', keysInitial);
        const temp_id = initialId + 1;
        const temp_keysInitial = keysInitial;
        // temp_id += 1;
        setinitialId(temp_id);
        temp_keysInitial.push(temp_id);
        setkeysInitial(temp_keysInitial);
    };

    const removeBankForm = (removeContact, k) => {
        console.log('K:', contactDetail[k]);
        console.log('K:', k);
        if (contactDetail[k] && contactDetail[k].id) {
            removeContact({
                variables: {
                    id: contactDetail[k].id,
                },
            }).then(({ data }) => {
                console.log('Data submitted===>>>', data);
                if (data && data.delete_job_contact && data.delete_job_contact.meta) {
                    // if (dataRefetch) {
                    //   dataRefetch();
                    // }
                    notification.success({
                        message: data.delete_job_contact.meta.message,
                        description: '',
                        duration: 3,
                    });
                }
            });
        }

        if (keysInitial.length === 0) {
            return;
        }

        const temp_initial = keysInitial.filter(key => key !== k);
        console.log('innn', temp_initial);
        setkeysInitial(temp_initial);
    };
    useEffect(() => {
        if (dataRefetch) {
            dataRefetch();
        }
    }, [dataRefetch]);
    const addContactFormItems = keysInitial.map((k, index) => (
        <>
            {initialId > 0 && index !== 0 ? (
                <Row gutter={24}>
                    <Col xs={24}>
                        <div className="flex-wrapper space-between">
                            <h5 className="text-small text-primary text-bold mt-20 mb-20 text-left">
                                Addition Contact {index}
                            </h5>
                            <Mutation mutation={ADMIN_DELETE_JOB_CONTACT}>
                                {(removeContact, { loading }) => (
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                            removeBankForm(removeContact, k);
                                        }}
                                    >
                                        <i className="icon-order-cancel text-primary" />
                                    </div>
                                )}
                            </Mutation>
                        </div>
                    </Col>
                </Row>
            ) : null}
            <Row gutter={16}>
                <Col lg={0}>
                    <Form.Item name={['contact_info', `${k}`, 'id']}>
                        <Input hidden value={undefined && null} />
                    </Form.Item>
                </Col>
                <Col lg={0}>
                    <Form.Item initialValue={decryptId(id)} name={['contact_info', `${k}`, 'job_id']}>
                        <Input value={decryptId(id)} hidden />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item
                        name={['contact_info', `${k}`, 'name']}
                        label={formatMessage({
                            id: 'component.jobs.contactname',
                        })}
                        rules={[
                            {
                                required: true,
                                message: `Angi ${formatMessage({
                                    id: 'component.jobs.contactname',
                                }).toLowerCase()}`,
                            },
                        ]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder={formatMessage({
                                id: 'component.jobs.contactname',
                            })}
                            size="large"
                            className="pickerwidth"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item
                        name={['contact_info', `${k}`, 'email']}
                        label={formatMessage({
                            id: 'component.jobs.contactemail',
                        })}
                        rules={[
                            {
                                required: true,
                                message: `Angi ${formatMessage({
                                    id: 'component.jobs.contactemail',
                                }).toLowerCase()}`,
                            },
                        ]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder={formatMessage({
                                id: 'component.jobs.contactemail',
                            })}
                            size="large"
                            className="pickerwidth"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item
                        name={['contact_info', `${k}`, 'mobile_no']}
                        label={formatMessage({
                            id: 'component.jobs.contactphone',
                        })}
                        rules={[
                            {
                                required: true,
                                message: `Angi ${formatMessage({
                                    id: 'component.jobs.contactphone',
                                }).toLowerCase()}`,
                            },
                        ]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder={formatMessage({
                                id: 'component.jobs.contactphone',
                            })}
                            size="large"
                            className="pickerwidth"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    ));


    return (
        <>
            <div className="contentshadow">
                <div className="text-primary main">
                    <Row>
                        <Col xs={24}>
                            <Title level={3} className="text-primary">
                                {formatMessage({
                                    id: 'component.jobs.addjobs',
                                })}
                            </Title>
                            {/* <p>
                {formatMessage({
                  id: 'component.jobs.addjobs.description',
                })}
              </p> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Query
                                query={ADMIN_JOB_DETAIL}
                                variables={{
                                    id: decryptId(id),
                                    status: 1,
                                }}
                                fetchPolicy="cache-and-network"
                                onCompleted={responseData => {
                                    fullObject = responseData.joblistdetail;
                                    setTimeout(setEditorContentAsync(fullObject), 3000);
                                    setcontactDetail(responseData.joblistdetail?.contact_info);
                                    setkeysInitial(responseData.joblistdetail.contact_info.map((k, index) => index));
                                    setinitialId(responseData.joblistdetail.contact_info.length);
                                    const tempVar = responseData.joblistdetail.meta_image;
                                    if (tempVar) {
                                        setimageUrl(`${STORAGE_URL}${tempVar}`);
                                    }
                                }}
                            >
                                {({ data, refetch, error, loading }) => {
                                    dataRefetch = refetch;
                                    console.log(error);
                                    if (data && data.joblistdetail) {
                                        const jobItem = data.joblistdetail;
                                        console.log('conatctname-->', data.joblistdetail.contact_info);
                                        return (
                                            <Mutation mutation={ADMIN_UPDATE_JOBS}>
                                                {(updatejob, { loading }) => (
                                                    <Form
                                                        ref={formRef}
                                                        layout="vertical"
                                                        className="user-forms addEditform"
                                                        name="jobs"
                                                        initialValues={
                                                            contactDetail && {
                                                                // remember: true,
                                                                jobtitle: data.joblistdetail?.title,
                                                                jobtype: data.joblistdetail?.type,
                                                                companyname: data.joblistdetail?.company_name,
                                                                party: data.joblistdetail?.party,
                                                                jobid: data.joblistdetail?.code,
                                                                location: data.joblistdetail?.location,
                                                                rightsidedescription: data.joblistdetail?.rightside_description,
                                                                whatareyoulookingfor: data.joblistdetail?.looking_for_description,
                                                                whatyouoffer: data.joblistdetail?.what_you_offer,
                                                                contact_id: data.joblistdetail?.contact_info[0]?.id,
                                                                contact_info: jobItem?.contact_info,
                                                            }
                                                        }
                                                        colon={false}
                                                        onFinish={values => onClickOk(updatejob, values)}
                                                        onFinishFailed={onFinishFailed}
                                                    >
                                                        <Row gutter={16}>
                                                            <Col xs={24}>
                                                                <Form.Item
                                                                    name="jobimage"
                                                                    label={formatMessage({
                                                                        id: 'component.form.profilepicture',
                                                                    })}
                                                                >
                                                                    <div className="user-profile-box">
                                                                        <img
                                                                            src={imageUrl || PlaceHolderImage}
                                                                            alt={jobItem?.title}
                                                                            className="profile-picture mb-3"
                                                                        />
                                                                    </div>
                                                                    <Upload showUploadList={false} onChange={handleUploadChange}>
                                                                        <Button className="mt-2">
                                                                            <UploadOutlined />{' '}
                                                                            {formatMessage({
                                                                                id: 'component.form.upload',
                                                                            })}
                                                                        </Button>
                                                                    </Upload>{' '}
                                                                    <Button
                                                                        className="mt-2"
                                                                        onClick={() => {
                                                                            setimageUrl(``);
                                                                            setisimageDeleted(true);
                                                                            setisimageUrlChanged(true);
                                                                        }}
                                                                    >
                                                                        <DeleteOutlined />{' '}
                                                                        {formatMessage({
                                                                            id: 'component.form.delete',
                                                                        })}
                                                                    </Button>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} lg={8}>
                                                                <Form.Item
                                                                    name="jobtitle"
                                                                    label={formatMessage({
                                                                        id: 'component.jobs.jobtitle',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Vennligst skriv inn jobb tittel',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        style={{ width: '100%' }}
                                                                        placeholder={formatMessage({
                                                                            id: 'component.jobs.jobtitle.placeholder',
                                                                        })}
                                                                        size="large"
                                                                        className="pickerwidth"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} lg={8}>
                                                                <Form.Item
                                                                    name="jobtype"
                                                                    label={formatMessage({
                                                                        id: 'component.jobs.jobtype',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Vennligst skriv inn jobb type',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        suffixIcon={<CaretDownOutlined />}
                                                                        style={{ width: '100%' }}
                                                                        placeholder={formatMessage({
                                                                            id: 'component.jobs.jobtype.placeholder',
                                                                        })}
                                                                        size="large"
                                                                        options={optionsData}
                                                                        className="pickerwidth"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={16}>
                                                            <Col xs={24} lg={8}>
                                                                <Form.Item
                                                                    name="companyname"
                                                                    label={formatMessage({
                                                                        id: 'component.table.companyname',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: `${formatMessage({
                                                                                id: 'component.jobs.company.placeholder',
                                                                            })}`,
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        style={{ width: '100%' }}
                                                                        placeholder={formatMessage({
                                                                            id: 'component.jobs.company.placeholder',
                                                                        })}
                                                                        size="large"
                                                                        className="pickerwidth"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} lg={8}>
                                                                <Form.Item
                                                                    name="party"
                                                                    label={formatMessage({
                                                                        id: 'component.jobs.party',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: `${formatMessage({
                                                                                id: 'component.jobs.party.placeholder',
                                                                            })}`,
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        style={{ width: '100%' }}
                                                                        placeholder={formatMessage({
                                                                            id: 'component.jobs.party.placeholder',
                                                                        })}
                                                                        size="large"
                                                                        className="pickerwidth"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={16}>
                                                            <Col xs={24} lg={8}>
                                                                <Form.Item
                                                                    name="jobid"
                                                                    label={formatMessage({
                                                                        id: 'component.jobs.jobid',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message: `${formatMessage({
                                                                                id: 'component.jobs.jobtype.placeholder',
                                                                            })}`,
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        style={{ width: '100%' }}
                                                                        placeholder={formatMessage({
                                                                            id: 'component.jobs.reqid.placeholder',
                                                                        })}
                                                                        size="large"
                                                                        className="pickerwidth"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} lg={8}>
                                                                <Form.Item
                                                                    name="location"
                                                                    label={formatMessage({
                                                                        id: 'component.jobs.location',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Vennligst skriv inn plassering',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        style={{ width: '100%' }}
                                                                        placeholder={formatMessage({
                                                                            id: 'component.jobs.location.placeholder',
                                                                        })}
                                                                        size="large"
                                                                        className="pickerwidth"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={24}>
                                                                <Form.Item
                                                                    name="rightsidedescription"
                                                                    label={formatMessage({
                                                                        id: 'component.jobs.rightsidedescription',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: false,
                                                                            message: `Vennligst skriv inn ${formatMessage({
                                                                                id: 'component.jobs.rightsidedescription',
                                                                            })}`,
                                                                        },
                                                                    ]}
                                                                >
                                                                    <BraftEditor
                                                                        className="editor-wrapper"
                                                                        value={editorState}
                                                                        onChange={handleEditorChange}
                                                                        onSave={submitSummaryContent}
                                                                        language="en"
                                                                        contentStyle={{ height: 210 }}
                                                                        placeholder={`Angi ${formatMessage({
                                                                            id: 'component.jobs.rightsidedescription',
                                                                        }).toLowerCase()}`}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={24}>
                                                                <Form.Item
                                                                    name="whatareyoulookingfor"
                                                                    label={formatMessage({
                                                                        id: 'component.jobs.whatareyoulookingfor',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Vennligst skriv inn jobb beskrivelse',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <BraftEditor
                                                                        className="editor-wrapper"
                                                                        value={editorState2}
                                                                        onChange={handleEditorChange2}
                                                                        onSave={submitSummaryContent2}
                                                                        language="en"
                                                                        contentStyle={{ height: 210 }}
                                                                        placeholder={`Angi ${formatMessage({
                                                                            id: 'component.jobs.jobdescription',
                                                                        }).toLowerCase()}`}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={24}>
                                                                <Form.Item
                                                                    name="whatyouoffer"
                                                                    label={formatMessage({
                                                                        id: 'component.jobs.whatyouoffer',
                                                                    })}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Vennligst skriv inn jobb beskrivelse',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <BraftEditor
                                                                        className="editor-wrapper"
                                                                        value={editorState3}
                                                                        onChange={handleEditorChange3}
                                                                        onSave={submitSummaryContent3}
                                                                        language="en"
                                                                        contentStyle={{ height: 210 }}
                                                                        placeholder={`Angi ${formatMessage({
                                                                            id: 'component.jobs.jobdescription',
                                                                        }).toLowerCase()}`}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <div id="wrapper" />
                                                        {addContactFormItems}
                                                        <Form.Item>
                                                            <Row className="mb-3">
                                                                <Col lg={24}>
                                                                    <Button
                                                                        type="primary"
                                                                        className="primary buttons admin-forms-btn"
                                                                        onClick={() => addBankForm()}
                                                                        size="large"
                                                                    >
                                                                        {formatMessage({
                                                                            id: 'component.addmore',
                                                                        })}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form.Item>
                                                        <Row>
                                                            <Col lg={24}>
                                                                <Button
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    className="btn-secondary buttons admin-forms-btn"
                                                                    size="large"
                                                                    loading={loading}
                                                                >
                                                                    {formatMessage({
                                                                        id: 'component.form.add',
                                                                    })}
                                                                </Button>
                                                                <Link to="/admin/ledigstilling">
                                                                    <Button
                                                                        className="text-primary admin-forms-btn"
                                                                        style={{ marginTop: 12 }}
                                                                        size="large"
                                                                        onClick={router.goBack}
                                                                    >
                                                                        {formatMessage({
                                                                            id: 'component.form.cancel',
                                                                        })}
                                                                    </Button>
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Mutation>
                                        );
                                    }
                                    if (loading && !error) {
                                        return (
                                            <div className="loader-wrapper">
                                                <Spin />
                                            </div>
                                        );
                                    }
                                    if (!loading && error) {
                                        return (
                                            <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>
                                        );
                                    }
                                    return <React.Fragment />;
                                }}
                            </Query>
                        </Col>
                    </Row>
                    <Modal
                        centered
                        title="Success full "
                        visible={successModal}
                        onOk={successModal}
                        onCancel={successModal}
                        width="450px"
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                className="btn-secondary admin-forms-btn  mr_15"
                                size="large"
                            >
                                {formatMessage({
                                    id: 'component.form.okay',
                                })}
                            </Button>,
                        ]}
                    >
                        <p>
                            {formatMessage({
                                id: 'component.jobs.modal.text',
                            })}
                        </p>
                    </Modal>
                </div>
            </div>
        </>
    );
};
export default EditJobs;

