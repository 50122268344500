import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const style = StyleSheet.create({
    cont:{
        marginTop:5
    },
    lblCont:{
        marginTop:10
    }
});

export default style;
