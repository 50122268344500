export default {
  'component.table.name': 'Navn',
  'component.table.email': 'E-post',
  'component.table.phone': 'Telefon',
  'component.table.action': 'Utfør',
  'component.table.propertyaddress': 'Eiendommens adresse',
  'component.table.assignedzipcode': 'Tildelte postnumre',
  'component.table.title': 'Tittel',
  'component.table.localization': 'Lokalisering',
  'component.table.mainagent': 'Hovedagent',
  'component.table.subagent': 'Saksbehandler 2',
  'component.table.subagent1': 'Underagent 1',
  'component.table.subagent2': 'Underagent 2',
  'component.table.oppdragno': 'Oppdragsnr.',
  'component.table.status': 'Status',
  'component.table.sellername': 'Selger navn',
  'component.table.date&time': 'Dato & tid',
  'component.table.mortgageoption': 'Pantealternativ',
  'component.table.stylisttype': 'Stylist type',
  'component.table.items': 'oppdrag',
  'component.table.propertyinfo': 'Eiendoms informasjon',
  'component.table.bidsms': 'By SMS-varsling',
  'component.table.updatesimilar': 'Oppdateringer på lignende hjem',
  'component.table.expire': 'Utløpe',
  'component.table.officename': 'Kontornavn',
  'component.table.companyname': 'Selskapsnavn',
  'component.table.orgnumber': 'Org.nr',
  'component.table.inactiveoffice': 'Inaktivt kontor',
  'component.table.activate': 'Aktiver',
  'component.table.servicename': 'Tjenester',
  'component.table.servicetype': 'Tjenestetype',
  'component.table.price': 'Pris',
  'component.table.insuranceoption': 'Forsikringsalternativ',
  'component.table.updated': 'Sist oppdatert',
  'component.table.area': 'Område',
  'component.table.award': 'Prisantydning',
  'component.table.city': 'Poststed',
  'component.table.priceSuggestion': 'Prisantydning',
  'component.table.dsupdate': 'Sist oppdatert',
  'component.table.ds': 'Link',
  'component.table.username': 'Username',
  'component.table.installationid': 'Installation ID',
};
