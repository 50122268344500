import React from 'react'
//Components
import {ScrollView, View} from 'react-native'
import {CheckBox, CustomButton, Header, Label,} from "../../../components";
//redux
import {logout, setToken, setUser} from "../../../redux/action";
import {connect} from "react-redux";
//Utils
import {Color, CommonStyle, Constants, Strings, ThemeUtils} from "../../../utils";
import {styles} from './styles.js';

//Third party


const filter = [{
    name: Strings.ordered,
    value: Constants.ORDER_STATUS_SERVER.ORDERED
}, {
    name: Strings.timeBooked,
    value: Constants.ORDER_STATUS_SERVER.TIMEBOOKED
}, {
    name: Strings.projectCompleted,
    value: Constants.ORDER_STATUS_SERVER.PROJECTCOMPLETE
}];

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedFilters: props.filters
        }
    }

    applyFilters = () => {
        this.props.onApplyFilter && this.props.onApplyFilter(this.state.selectedFilters)
    };

    render() {
        const {onClose} = this.props;
        return (
            <View style={CommonStyle.container}>
                <Header style={CommonStyle.blankHeader}
                        onPressLeft={onClose}/>
                <View style={CommonStyle.container}>
                    <ScrollView contentContainerStyle={{flexGrow: 1}}>
                        <View style={styles.container}>
                            <View style={styles.topContainer}>
                                <View style={styles.titleContainer}>
                                    <Label xlarge
                                           font_medium
                                           mt={15}
                                           mb={15}
                                           align={'left'}>
                                        {Strings.filter}
                                    </Label>
                                </View>
                                <Label
                                    mt={10}
                                    small
                                    font_medium
                                    align={'left'}>
                                    {Strings.status}
                                </Label>

                                <View style={styles.checkbox}>
                                    <CheckBox options={filter}
                                              defaultChecked={this.state.selectedFilters}
                                              size={ThemeUtils.fontXLarge}
                                              labelProps={{
                                                  font_regular: true,
                                                  small: true,
                                                  ml: 5
                                              }}
                                              style={styles.energyradio}
                                              onCheckChanged={(val) => this.setState({selectedFilters: val})}/>

                                </View>

                            </View>


                        </View>
                    </ScrollView>
                </View>
                <View style={styles.buttonContainer}>
                    <CustomButton font_medium
                                  onPress={this.applyFilters}
                                  mr={ThemeUtils.relativeWidth(1.5)}
                                  style={{flex: 1}}// Do not remove inline will not work in web
                                  title={Strings.apply}/>

                    <CustomButton onPress={() => this.setState({selectedFilters: []}, this.applyFilters)}
                                  ml={ThemeUtils.relativeWidth(1.5)}
                                  font_medium

                                  style={{
                                      flex: 1,
                                      borderWidth: 1,
                                      borderColor: Color.DARK_GRAY
                                  }}// Do not remove inline will not work in web
                                  textColor={Color.PRIMARY}
                                  bgColor={Color.WHITE}
                                  title={Strings.reset}/>
                </View>

            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
