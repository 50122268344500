import gql from 'graphql-tag';

export const ADMIN_CREATE_SUPER_USER = gql`
  mutation create_super_user(
    $office_id: Int!
    $first_name: String!
    $last_name: String!
    $email: String!
    $mobile: String
    $title: String!
    $education: String
    $about: String
    $cv: Upload
    $other_doc: [Upload!]
    $profile_image: Upload!
    $access_level: String!
    $is_in_admin_office: Boolean!
    $is_office_admin: Boolean
  ) {
    create_super_user(
      office_id: $office_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      mobile: $mobile
      title: $title
      education: $education
      about: $about
      cv: $cv
      other_doc: $other_doc
      profile_image: $profile_image
      access_level: $access_level
      is_in_admin_office: $is_in_admin_office
      is_office_admin: $is_office_admin
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const ADMIN_CREATE_VENORS_USER = gql`
  mutation add_vendor(
  $user_profile: Upload
  $first_name: String!
  $last_name: String!
  $email: String!
  $mobile: String!
  $city: String!
  $address: String!
  $company_name: String!
  $zipcode: Int!
  $user_type: userType!
  $office_ids: [Int]!
) {
  add_vendor(
    user_profile: $user_profile
    first_name: $first_name
    last_name: $last_name
    email: $email
    mobile: $mobile
    city: $city
    address: $address
    company_name: $company_name
    zipcode: $zipcode
    user_type: $user_type
    office_ids: $office_ids
  ) {
    meta {
      status
      message
      code
    }
  }
}

`;

export const ADMIN_UPDATE_VENORS_USER = gql`
  mutation update_vendor(
  $id: ID!
  $user_profile: Upload
  $first_name: String!
  $last_name: String!
  $office_ids: [Int]!
  $email: String!
  $mobile: String!
  $city: String!
  $address: String!
  $company_name: String!
  $user_type_id: Int!
  $zipcode: Int!
) {
  update_vendor(
    id: $id
    user_profile: $user_profile
    first_name: $first_name
    last_name: $last_name
    office_ids: $office_ids
    email: $email
    mobile: $mobile
    city: $city
    address: $address
    company_name: $company_name
    user_type_id: $user_type_id
    zipcode: $zipcode
  ) {
    meta {
      status
      message
      code
    }
  }
}
`;

export const ADMIN_UPDATE_SUPER_USER = gql`
  mutation update_super_user(
    $id: Int!
    $office_id: Int!
    $first_name: String!
    $last_name: String!
    $email: String!
    $mobile: String
    $title: String!
    $education: String
    $about: String
    $cv: Upload
    $other_doc: [Upload!]
    $profile_image: Upload
    $is_in_admin_office: Boolean!
  ) {
    update_super_user(
      id: $id
      office_id: $office_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      mobile: $mobile
      title: $title
      education: $education
      about: $about
      cv: $cv
      other_doc: $other_doc
      profile_image:$profile_image
      is_in_admin_office: $is_in_admin_office
    ) {
      id
      first_name
      last_name
      email
      address
      mobile
      profile_image
      city
      zipcode
      status
      access_level
      office_id
      title
    }
  }
`;

export const ADMIN_DELETE_USER = gql`
  mutation delete_vendor($id: Int!, $user_type_id: Int!) {
    delete_vendor(id: $id, user_type_id: $user_type_id) {
      meta {
        message
        status
        code
      }
    }
  }
`;

export const ADMIN_ACTIVE_INACTIVE_AGENT = gql`
  mutation active_inactive_agent($id: Int!) {
    active_inactive_agent(id: $id) {
      id
      first_name
      last_name
      email
      address
      mobile
    }
  }
`;

export const ADMIN_DELETE_AGENT_TEAM = gql`
  mutation delete_agent_team($id: Int!) {
    delete_agent_team(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_DELETE_AGENT_CV = gql`
  mutation delete_cv($agent_id: Int!) {
    delete_cv(agent_id: $agent_id) {
      id
      first_name
      last_name
      email
      address
    }
  }
`;

export const ADMIN_DELETE_AGENT_DOC = gql`
  mutation delete_document($agent_id: Int!, $user_document_id: Int!) {
    delete_document(agent_id: $agent_id, user_document_id: $user_document_id) {
      id
      first_name
      last_name
      email
      address
    }
  }
`;

export const ADMIN_DELETE_FEEBACK = gql`
  mutation delete_agent_feedback($id: Int!, $agent_id: Int!) {
    delete_agent_feedback(id: $id, agent_id: $agent_id) {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
`;
export const ADMIN_DELETE_AGENT_MEDIA = gql`
  mutation delete_agent_media($agent_id: Int!, $media_id: Int!) {
    delete_agent_media(agent_id: $agent_id, media_id: $media_id) {
      id
      user_id
      title
      redirect_url
      media_url
      status
    }
  }
`;

export const ADMIN_DELETE_PORFESSIONAL = gql`
  mutation delete_professional($id: Int!) {
    delete_professional(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const ADMIN_DELETE_NEWS = gql`
  mutation delete_news($id: Int!) {
    delete_news(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_DELETE_FAGLIG = gql`
  mutation delete_professional($id: Int!) {
    delete_professional(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_ADD_TEAM = gql`
  mutation add_team($input: teamInput) {
    add_team(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const ADMIN_UPDATE_TEAM = gql`
  mutation update_team($input: updateTeamInput) {
    update_team(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_ADD_AGENT_DETAILS = gql`
  mutation add_agent(
    $profile_image: Upload!
    $first_name: String!
    $last_name: String!
    $email: String!
    $office_id: Int!
    $mobile: String
    $title: String!
    $education: String
    $about: String
    $cv: Upload
    $other_doc: [Upload!]
    $is_in_admin_office: Boolean!
  ) {
    add_agent(
      profile_image: $profile_image
      first_name: $first_name
      last_name: $last_name
      email: $email
      office_id: $office_id
      mobile: $mobile
      title: $title
      education: $education
      about: $about
      cv: $cv
      other_doc: $other_doc
      is_in_admin_office:$is_in_admin_office
    ) {
      id
      first_name
      last_name
      email
      address
      mobile
      profile_image
      is_in_admin_office
      city
    }
  }
`;

export const ADMIN_ADD_AGENT_FEEDBACK = gql`
  mutation add_agent_feedback($input: FeedbackInput!) {
    add_agent_feedback(input: $input) {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
`;

export const ADMIN_EDIT_AGENT_FEEDBACK = gql`
  mutation eidt_agent_feedback(
    $id: Int!
    $agent_id: Int!
    $rating: Decimal!
    $feedback: String!
    $given_by: String!
  ) {
    edit_agent_feedback(
      id: $id
      agent_id: $agent_id
      rating: $rating
      feedback: $feedback
      given_by: $given_by
    ) {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
`;
export const ADMIN_ADD_AGENT_MEDIA = gql`
  mutation agent_add_media(
    $agent_id: Int!
    $title: String!
    $redirection_url: String!
    $media_image: Upload!
  ) {
    agent_add_media(
      agent_id: $agent_id
      title: $title
      redirection_url: $redirection_url
      media_image: $media_image
    ) {
      id
      user_id
      title
      redirect_url
      media_url
    }
  }
`;

export const ADMIN_EDIT_AGENT_MEDIA = gql`
  mutation agent_update_media(
    $id: Int!
    $agent_id: Int!
    $title: String!
    $redirection_url: String!
    $media_image: Upload
  ) {
    agent_update_media(
      id: $id
      agent_id: $agent_id
      title: $title
      redirection_url: $redirection_url
      media_image: $media_image
    ) {
      id
      user_id
      title
      redirect_url
      media_url
      status
    }
  }
`;

export const ADMIN_UPDATE_AGENT_DETAILS = gql`
  mutation update_agent(
    $id: Int!
    $office_id: Int!
    $first_name: String!
    $last_name: String!
    $email: String!
    $mobile: String
    $title: String!
    $education: String
    $about: String
    $cv: Upload
    $other_doc: [Upload!]
    $profile_image:Upload
    $is_in_admin_office: Boolean!
    ) {
    update_agent(
      id: $id
      office_id: $office_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      mobile: $mobile
      title: $title
      education: $education
      about: $about
      cv: $cv
      other_doc: $other_doc
      profile_image:$profile_image
      is_in_admin_office:$is_in_admin_office
    ) {
      id
      first_name
      last_name
      email
      mobile
      access_level
      title
      profile_image
      is_in_admin_office
      office {
        id
        name
        company_name
      }
      user_detail {
        id
        user_id
        about
        education
        cv_url
        status
      }
      feedback {
        id
        user_id
        feedback
        given_by
        rating
      }
      user_media {
        id
        user_id
        title
        redirect_url
        media_url
        status
      }
      user_document {
        id
        user_id
        doc_url
        doc_type
      }
    }
  }
`;

export const ADMIN_CREATE_NEWS = gql`
  mutation add_news($input: AddNewsInput!) {
    add_news(input: $input) {
      id
      title
      description
      cover_image_url
      video_url
      status
    }
  }
`;

export const ADMIN_ADD_PROFESSIONAL = gql`
  mutation add_professional($input: AddProfessionalInput!) {
    add_professional(input: $input) {
      id
      title
      description
      cover_image_url
      video_url
      status
    }
  }
`;
export const ADMIN_UPDATE_NEWS = gql`
  mutation update_news($input: UpdateNewsInput!) {
    update_news(input: $input) {
      id
      title
      description
      cover_image_url
      video_url
      status
      created_at
      updated_at
    }
  }
`;
export const ADMIN_UPDATE_FAGLING = gql`
  mutation update_professional($input: UpdateProfessionalInput!) {
    update_professional(input: $input) {
      id
      title
      description
      cover_image_url
      video_url
      status
    }
  }
`;

export const ADMIN_ADD_OFFICE = gql`
  mutation add_office($input: AddOfficeInput) {
    add_office(input: $input) {
      id
      name
      city
      telephone_no
      image_url
      logo_url
      address
      zipcode
      email
      company_name
      org_number
      widget_code
      banner_1
      banner_2
      small_headline
      big_headline
      ingress
      headline_text_section
      text_section
      is_admin_office
    }
  }
`;

export const ADMIN_UPDATE_OFFICE = gql`
  mutation update_office($input: UpdateOfficeInput) {
    update_office(input: $input) {
      id
      name
      city
      telephone_no
      image_url
      logo_url
      address
      zipcode
      email
      company_name
      org_number
      widget_code
      banner_1
      banner_2
      small_headline
      big_headline
      ingress
      headline_text_section
      text_section
      status
      is_admin_office
    }
  }
`;

export const ADMIN_CREATE_JOBS = gql`
  mutation create_job(
    $job_title: String!
    $location: String!
    $company_name: String!
    $party: String!
    $code: String
    $type: JobStatus
    $rightside_description: String
    $looking_for_description: String
    $what_you_offer: String
    $image_url:Upload
    $contact_info:[contact_info]!
  ) {
  create_job(
    job_title: $job_title
    location: $location
    company_name: $company_name
    party: $party
    code: $code
    type: $type
    rightside_description: $rightside_description
    looking_for_description: $looking_for_description
    what_you_offer: $what_you_offer
    image_url:$image_url
    contact_info:$contact_info
  ) {
    meta {
      status
      message
      code
    }
  }
}
`;


export const ADMIN_UPDATE_JOBS = gql`
  mutation update_job(
    $id: Int!
    $title: String
    $location: String
    $company_name: String
    $party: String
    $code: String
    $type: JobStatus
    $rightside_description: String
    $looking_for_description: String
    $what_you_offer: String
    $image_url: Upload
    $is_image_updated: Boolean!
    $contact_info:[update_contact_info]
  ) {
    update_job(
      id: $id
      title: $title
      location: $location
      company_name: $company_name
      party: $party
      code: $code
      type: $type
      rightside_description: $rightside_description
      looking_for_description: $looking_for_description
      what_you_offer: $what_you_offer
      image_url: $image_url
      is_image_updated: $is_image_updated
      contact_info:$contact_info
    ) {
      id
      title
      company_name
      location
      party
      code
      type
      rightside_description
      looking_for_description
      what_you_offer
      status
      contact_info{
        name
        email
        mobile_no
      }
    }
  }
`;

export const ADMIN_UPDATE_OFFICE_STATUS = gql`
  mutation change_office_Status($id: Int!, $status: Boolean!) {
    change_office_status(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const ADMIN_REMOVE_DEPARTMENT_ID = gql`
  mutation remove_department_id($office_id: Int!,$department_id: Int!) {
    remove_department_id(office_id: $office_id, department_id: $department_id) {
     meta{
       status
       message
       code
     }
    }
  }
`;

export const ADMIN_CREATE_ADS_POPUPS = gql`
  mutation create_ads_popup($ads_image: [Upload!]!, $redirect_url: [String]!, $place: AdsType!) {
    create_ads_popup(ads_image: $ads_image, redirect_url: $redirect_url, place: $place) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const ADMIN_UPDATE_EDIT_PROFILE = gql`
  mutation update_admin_profile($input: UpdateAdminProfileInput) {
    update_admin_profile(input: $input) {
      user {
        id
        first_name
        last_name
        email
        address
        profile_image
        mobile
      }
    }
  }
`;

export const ADMIN_UPDATE_MOVING_GUIDE_LINKS = gql`
  mutation add_manage_links($input: ManagLinkInput!) {
    add_manage_links(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_UPLOAD_SEE_MOVIE = gql`
  mutation upload_see_movie($see_movie_video: Upload!) {
    upload_see_movie(see_movie_video: $see_movie_video) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_CREATE_MOOVING_VENDORS = gql`
  mutation create_moving_vendor(
    $first_name: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $company_name: String!
    $company_type_id: Int!
    $service_type_id: Int!
    $assigned_zipcode: [String]!
  ) {
    create_moving_vendor(
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
      company_name: $company_name
      company_type_id: $company_type_id
      service_type_id: $service_type_id
      assigned_zipcode: $assigned_zipcode
    ) {
      meta {
        message
        status
        code
      }
    }
  }
`;

export const ADMIN_UPDATE_MOOVING_VENDORS = gql`
  mutation update_moving_vendor(
    $id: ID!
    $first_name: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $company_name: String!
    $company_type_id: Int!
    $service_type_id: Int!
    $assigned_zipcode: [String]!
  ) {
    update_moving_vendor(
      id: $id
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
      company_name: $company_name
      company_type_id: $company_type_id
      service_type_id: $service_type_id
      assigned_zipcode: $assigned_zipcode
    ) {
      meta {
        message
        status
        code
      }
    }
  }
`;

export const ADMIN_DELETE_MOVING_VENDOR_USER = gql`
  mutation delete_moving_vendor($id: ID!) {
    delete_moving_vendor(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_DOWNLOAD_LOAN_APPLICATION = gql`
  mutation download_loan_application($status: Int!, $file_type: ExportType!) {
    download_loan_application(status: $status, file_type: $file_type) {
      url
    }
  }
`;

export const ADMIN_DOWNLOAD_INSURANCE_LIST = gql`
  mutation download_insurance($status: Int!, $file_type: ExportType!) {
    download_insurance(status: $status, file_type: $file_type) {
      url
    }
  }
`;

export const ADMIN_PURCHASEB2BSERVICES = gql`
  mutation purchase_b2b_services($office_id: Int!, $service_id: [Int]!) {
    purchase_b2b_services(office_id: $office_id, service_id: $service_id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_REMOVE_B2BSERVICES = gql`
  mutation remove_b2b_service($service_subscribe_id: Int!, $office_id: Int!) {
    remove_b2b_service(service_subscribe_id: $service_subscribe_id, office_id: $office_id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_EDIT_FEEDBACK = gql`
  mutation editFeedBack(
    $id: Int!
    $agent_id: Int!
    $rating: Decimal!
    $given_by: String!
    $feedback: String!
  ) {
    edit_agent_feedback(
      id: $id
      agent_id: $agent_id
      rating: $rating
      given_by: $given_by
      feedback: $feedback
    ) {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
`;

export const ADMIN_DELETE_FEEDBACK = gql`
  mutation deleteFeedBack($id: Int!, $user_id: Int!) {
    delete_agent_feedback(id: $id, agent_id: $user_id) {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
`;

export const ADMIN_DELETE_JOB = gql`
  mutation delete_jobs($id: Int!, $status: Boolean!) {
    delete_jobs(id: $id, status: $status) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_DELETE_JOB_CONTACT = gql`
  mutation delete_job_contact($id: Int!) {
    delete_job_contact(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADMIN_UPDATE_METAPAGE = gql`
  mutation update_meta_page(
    $id: Int!
    $name: String!
    $title: String!
    $description: String!
    $image_url: Upload
    $keywords: String!
  ) {
    update_meta_page(
      id: $id
      name: $name
      title: $title
      description: $description
      image_url: $image_url
      keywords: $keywords
    ) {
      id
      name
      title
      description
      image_url
      keywords
      page_type
    }
  }
`;

export const ADMIN_UPDATE_HOMEPAGE_SETTINGS = gql`
  mutation edit_homepage_setting($input: HomeSettingInput) {
  edit_homepage_setting(input: $input) {
    id
    cover_video_url
    home_page_images {
      id
      title
      home_page_setting_id
      image_url
      external_url
    }
  }
}
`;
