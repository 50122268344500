import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
        marginTop: 20
    },
    displayCont: {
        flexDirection: 'row',
        flex: 1
    },
    lblCont: {
        flex: 0.4,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    serviceCont: {
        borderWidth: 1,
        borderRadius: 1,
        borderColor: Color.SECONDARY,
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginVertical: 10
    },
    imgCont: {
        flex: 1,
        flexDirection:'row',
        paddingStart: 15,
        paddingBottom: 15
    },
    scrollCont: {
        paddingBottom: 15
    },
    imgBottom: {
        height: 100,
        width: 120,
        marginStart: 20
    },
    icCont: {
        zIndex: 100,
        position: 'absolute',
        justifyContent: 'center',
        alignItems:'center',
        alignSelf:'center',
        padding: 20,
        marginHorizontal:20,
        backgroundColor:Color.TRANSPARENT_BLACK,
        borderRadius:20,
        height:ThemeUtils.fontXXLarge,
        width:ThemeUtils.fontXXLarge
    },
    icContNext: {
        backgroundColor:Color.TRANSPARENT_BLACK,
        padding: 20,
        marginHorizontal: 20,
        zIndex: 100,
        borderRadius:20,
        alignSelf:'center',
        position: 'absolute',
        end: 0,
        height:ThemeUtils.fontXXLarge,
        width:ThemeUtils.fontXXLarge,
        justifyContent: 'center',
        alignItems:'center'
    }
})
