import { Col, Row, Typography } from 'antd';
import React from 'react';
import { Query } from 'react-apollo';
import { LoadingOutlined } from '@ant-design/icons';
import { formatMessage } from "components/src/utils";
import { ADMIN_DASHBOARD } from '../../../../query/AdminQuery';

const { Title } = Typography;

export const AdminDashboard = () => (
  <div className="contentshadow">
    <div className="text-primary main">
      <Row className="mb-3">
        <Col xs={24} md={24} lg={24}>
          <Title level={3}>
            {formatMessage({
              id: 'component.admindashboard.title',
            })}
          </Title>
        </Col>
      </Row>
      <Query
        query={ADMIN_DASHBOARD}
        fetchPolicy="no-cache"
        // onCompleted={responseData => {
        //   setOrderData(responseData.insurance_lists.data);
        // }}
      >
        {({ data, loading }) => {
          // console.log('user-list', data.users_list);
          if (data && !loading) {
            if (data && data?.admin_dashboard_stat) {
              console.log('insodwe;', data.admin_dashboard_stat);
              return (
                <Row className="mt-3">
                  <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <p className="label-texts no-margin">
                      {formatMessage({
                        id: 'component.admindashboard.companyname',
                      })}
                    </p>
                  </Col>
                  {/* ********************** */}
                  <Col lg={24}>
                    <Row gutter={16}>
                      <Col xs={24} lg={6}>
                        <div className="stats-box grey-tone">
                          <p className="box-title  mb-3">
                            {formatMessage({
                              id: 'component.admindashboard.inspection',
                            })}
                          </p>
                          <div className="stats-container mt-3">
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={6}>
                        <div className="stats-box brown-tone">
                          <Row>
                            <Col lg={24}>
                              <p className="box-title">
                                {formatMessage({
                                  id: 'component.admindashboard.newassignment',
                                })}
                              </p>
                            </Col>
                          </Row>
                          <div className="stats-container mt-3">
                            <Col lg={8}>
                              <div>
                                <div>
                                  <Title level={4}>-</Title>
                                </div>
                                <div>
                                  <p>Måned</p>
                                </div>
                              </div>
                            </Col>
                            <Col lg={8}>
                              <div>
                                <div>
                                  <Title level={4}>-</Title>
                                </div>
                                <div>
                                  <p>Kvartal</p>
                                </div>
                              </div>
                            </Col>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={6}>
                        <div className="stats-box darkgrey-tone">
                          <div className="mb-3">
                            <p className="box-title">
                              {formatMessage({
                                id: 'component.admindashboard.sale',
                              })}
                            </p>
                          </div>
                          <div className="stats-container">
                            <div>
                              <div>
                                <Title level={4}>{data.admin_dashboard_stat?.sale?.month}</Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>{data.admin_dashboard_stat?.sale?.quarter}</Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>{data.admin_dashboard_stat?.sale?.year}</Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={6}>
                        <div className="stats-box black-tone">
                          <p className="box-title mb-3">
                            {formatMessage({
                              id: 'component.admindashboard.omsetning',
                            })}
                          </p>
                          <div className="stats-container mt-3">
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={6}>
                        <div className="stats-box brown-tone">
                          <div className="mb-3">
                            <p className="box-title">
                              {formatMessage({
                                id: 'component.admindashboard.flexlicenserevenue',
                              })}
                            </p>
                          </div>
                          <div className="stats-container">
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={6}>
                        <div className="stats-box darkgrey-tone">
                          <div className="mb-3">
                            <p className="box-title">
                              {formatMessage({
                                id: 'component.admindashboard.mortgages',
                              })}
                            </p>
                          </div>
                          <div className="stats-container">
                            <div>
                              <div>
                                <Title level={4}>
                                  {data.admin_dashboard_stat?.mortgages?.month}
                                </Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>
                                  {data.admin_dashboard_stat?.mortgages?.quarter}
                                </Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>
                                  {data.admin_dashboard_stat?.mortgages?.year}
                                </Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={6}>
                        <div className="stats-box black-tone">
                          <p className="box-title mb-3">
                            {formatMessage({
                              id: 'component.admindashboard.insurances',
                            })}
                          </p>
                          <div className="stats-container mt-3">
                            <div>
                              <div>
                                <Title level={4}>
                                  {data.admin_dashboard_stat?.insurances?.month}
                                </Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>
                                  {data.admin_dashboard_stat?.insurances?.quarter}
                                </Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>
                                  {data.admin_dashboard_stat?.insurances?.year}
                                </Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={6}>
                        <div className="stats-box grey-tone">
                          <p className="box-title">
                            {formatMessage({
                              id: 'component.admindashboard.movingservice',
                            })}
                          </p>
                          <div className="stats-container">
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={8}>
                      <Col xs={24} lg={6}>
                        <div className="stats-box darkgrey-tone">
                          <div className="mb-3">
                            <p className="box-title">
                              {formatMessage({
                                id: 'component.admindashboard.housingalarm',
                              })}
                            </p>
                          </div>
                          <div className="stats-container">
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={6}>
                        <div className="stats-box black-tone">
                          <p className="box-title mb-3">
                            {formatMessage({
                              id: 'component.admindashboard.washout',
                            })}
                          </p>
                          <div className="stats-container mt-3">
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} lg={6}>
                        <div className="stats-box grey-tone">
                          <p className="box-title mb-3">
                            {formatMessage({
                              id: 'component.admindashboard.onlinestore',
                            })}
                          </p>
                          <div className="stats-container mt-3">
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Måned</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>Kvartal</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <Title level={4}>-</Title>
                              </div>
                              <div>
                                <p>År</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            }
            return <div>{formatMessage({ id: 'component.nodata' })}</div>;
          }
          return (
            <div className="loader-wrapper">
              <LoadingOutlined />
            </div>
          );
        }}
      </Query>
    </div>
  </div>
);

export default AdminDashboard;
