import { EditOutlined, MoreOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Table,
  Typography,
  Modal,
  notification,
} from 'antd';
import React, { useEffect,useState, useRef } from 'react';
import { Link,useHistory } from 'react-router-dom';
import { formatMessage } from 'components/src/utils';
import { Query, Mutation } from 'react-apollo';
import { ADMIN_MOOVIN_GUIDE_VENDORS_LIST } from '../../../../../query/AdminQuery';
import { ADMIN_DELETE_MOVING_VENDOR_USER } from '../../../../../mutation/AdminMutation';
// import data from '../../../../../mock/managevendors';
import '../../../../sass/defaultstyles.scss';
import { encryptId } from '../../../../utils/utils';

const { Title } = Typography;
const { Search } = Input;

let dataRefetch;

const menu = (item, deletUser) => (
  // console.log('Item Cliekedddd===>>>', item);
  <Menu>
    <Menu.Item
      onClick={() => {
        // const deleteID =  orderData.filter(item =>item.id);
        deletUser({
          variables: {
            id: item.map(i => i.id).toString(),
          },
        }).then(data => {
          if (dataRefetch) {
            dataRefetch();
            // console.log('Data submitted===>>>', data, loading);
            if (data && data.data.delete_moving_vendor && data.data.delete_moving_vendor.meta) {
              notification.success({
                message: data.data.delete_moving_vendor.meta.message,
                description: '',
                duration: 3,
              });
            }
          }
        });
      }}
    >
      {formatMessage({ id: 'component.form.delete' })}
    </Menu.Item>
  </Menu>
);
export const ManageVendors = () => {
  let router=useHistory();
  // const zipcodesdata =
  //   '0182, 0235, 0178, 0188, 1350, 0183, 4730, 4457, 5073, 1024, 2058, 0048, 1044, 0154, 0882, 4288, 4115, 0015';

  const [zipcodeModal, setzipcodeModal] = useState(false);
  const [zipCodeData, setZipCodeData] = useState('');
  const [orderData, setOrderData] = useState([]);
  const [manageVendorsList, setManageVendorsList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [prevPagination, setprevPagination] = useState({});
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 30,
    total: 1,
  });

  const timerRef = useRef(null);

  const modalFunction = zipData => {
    setZipCodeData(zipData);
    setzipcodeModal(true);
  };

  const usePreviousValue = () => {
    const ref = useRef();
    useEffect(() => {
      ref.current = searchKeyword;
    });
    return ref.current;
  };
  const prevValue = usePreviousValue();

  const searchFunc = e => {
    if (prevValue === '') {
      console.log('prevValue;', prevValue);
      setprevPagination(paginationData);
    }
    if (e !== '') {
      setPaginationData({
        current: 1,
        pageSize: 30,
        total: 1,
      });
    } else {
      setPaginationData(prevPagination);
    }
  };

  const handleTableChange = pagination => {
    console.log('pagination:', pagination);
    if (pagination) {
      setPaginationData(pagination);
      setprevPagination(paginationData);
    }
  };

  useEffect(() => {
    if (searchKeyword === '') {
      setprevPagination(paginationData);
    }
  }, []);

  const columns = [
    {
      title: `${formatMessage({ id: 'component.table.name' })}`,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      render: text => (
          // <Link to="/admin/administrereleverandører/redigereleverandører">
          <a>{text}</a>
          // </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.table.email' })}`,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => {
        const aSplit = a.email.split('@');
        const bSplit = b.email.split('@');
        return aSplit[0].length - bSplit[0].length;
      },
      render: text => (
          // <Link to="/admin/administrereleverandører/redigereleverandører">
          <a>{text}</a>
          // </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.table.phone' })}`,
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone.length - b.phone.length,
      render: text => (
          // <Link to="/admin/administrereleverandører/redigereleverandører">
          <a>{text}</a>
          // </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.managevendors.service' })}`,
      dataIndex: 'service',
      key: 'service',
      sorter: (a, b) => a.service.length - b.service.length,
      render: text => (
          // <Link to="/admin/administrereleverandører/redigereleverandører">
          <a>{text}</a>
          // </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.managevendors.companyname' })}`,
      dataIndex: 'companyname',
      key: 'companyname',
      sorter: (a, b) => a.companyname.length - b.companyname.length,
      render: text => (
          // <Link to="/admin/administrereleverandører/redigereleverandører">
          <a>{text}</a>
          // </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.managevendors.companytype' })}`,
      dataIndex: 'companytype',
      key: 'companytype',
      sorter: (a, b) => a.companytype.length - b.companytype.length,
      render: text => (
          // <Link to="/admin/administrereleverandører/redigereleverandører">
          <a>{text}</a>
          // </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.table.assignedzipcode' })}`,
      dataIndex: 'zipcodes',
      key: 'zipcodes',
      sorter: (a, b) => a.zipcodes.length - b.zipcodes.length,
      render: (zipcodes, name) => {
        // setZipCodeData([])
        const zipCodeToSend = orderData.filter(item => name.key === item.id);
        console.log(
            'zipCodeToSend:',
            zipCodeToSend.map(i => i.vendorZipcodes),
        );
        const zipArray = [];
        const zipList = zipCodeToSend.map(i => i.vendorZipcodes);
        console.log(
            'ZipLIST::>>',
            zipList.map(i => i.map(j => zipArray.push(j.zipcode))),
        );
        return (
            <div
                style={{ color: '#0589CC', cursor: 'pointer' }}
                onClick={() => modalFunction(zipArray)}
            >
              {zipcodes}
            </div>
        );
      },
    },
    {
      title: `${formatMessage({ id: 'component.table.action' })}`,
      key: 'action',
      render: name => {
        const itemToSend = orderData.filter(item => name.key === item.id);

        // console.log('Item to send==>', itemToSend[0]);
        return (
            <span style={{ float: 'right' }}>
            <Link
                to={{
                  pathname: `/admin/administrereleverandører/redigereleverandører/${encryptId(
                      itemToSend.map(i => i.id),
                  )}`,
                  // state: { orderItem: itemToSend[0] },
                }}
            >
              <EditOutlined style={{ marginRight: 8 }} />
            </Link>
            <Mutation mutation={ADMIN_DELETE_MOVING_VENDOR_USER}>
              {(deletUser, { data }) => {
                // if
                return (
                    <Dropdown
                        overlay={menu(itemToSend, deletUser)}
                        placement="bottomCenter"
                        trigger={['click']}
                    >
                      <MoreOutlined />
                    </Dropdown>
                );
              }}
            </Mutation>
          </span>
        );
      },
    },
  ];

  return (
      <div className="contentshadow">
        <div className="text-primary main">
          <Row className="photo-order-wrapper">
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
              <Title level={3} className="text-primary">
                {formatMessage({
                  id: 'component.managevendors.title',
                })}
              </Title>
              <p>
                Her finner du en oversikt over alle registrerte leverandører til foretaket. Du kan
                også legge til nye.
              </p>
              <Link to="/admin/administrereleverandører/leggetilleverandører">
                <Button
                    type="primary"
                    className="btn-secondary admin-forms-btn with-lg-spaces"
                    size="large"
                >
                  {formatMessage({ id: 'component.managevendors.addmanagevendors' })}
                </Button>
              </Link>
              <Row>
                <Col xs={24}>
                  <Query
                      query={ADMIN_MOOVIN_GUIDE_VENDORS_LIST}
                      variables={{
                        status: 1,
                        first: paginationData.pageSize,
                        page: paginationData.current,
                        key: searchKeyword,
                        sorting: {
                          field: 'id',
                          order: 'DESC',
                        },
                      }}
                      fetchPolicy="cache-and-network"
                      onCompleted={responseData => {
                        setOrderData(responseData.moving_guide_vendors_list.data);
                        if (
                            responseData &&
                            responseData.moving_guide_vendors_list &&
                            responseData.moving_guide_vendors_list.paginatorInfo
                        ) {
                          setPaginationData({
                            current: responseData.moving_guide_vendors_list.paginatorInfo.currentPage,
                            pageSize: responseData.moving_guide_vendors_list.paginatorInfo.perPage,
                            total: responseData.moving_guide_vendors_list.paginatorInfo.total,
                          });
                        }
                        if (
                            responseData &&
                            responseData.moving_guide_vendors_list &&
                            responseData.moving_guide_vendors_list.data
                        ) {
                          setManageVendorsList(responseData.moving_guide_vendors_list.data);
                        }
                        // const zipArray = [];
                        // if (responseData) {
                        //   responseData.users_list.data.map(zipItem =>
                        //     zipItem.userZipCodes.map(i => zipArray.push(i.zipcode)),
                        //   );
                        //   setZipCodeData(zipArray);
                        // }
                        // return '';
                        console.log(responseData);
                      }}
                  >
                    {({ data, loading, refetch, error }) => {
                      dataRefetch = refetch;
                      console.log(error);
                      // console.log('user-list', data.users_list);
                      const JobsArray = [];
                      if (data && data.moving_guide_vendors_list.data) {
                        // console.log('insodwe;', data.users_list.data);
                        // eslint-disable-next-line array-callback-return
                        data.moving_guide_vendors_list.data.map(item => {
                          const InspectionUserList = {
                            key: item.id,
                            name: `${item.first_name} ${item.last_name}`,
                            email: item.email,
                            phone: item.phone,
                            companyname: item.company_name,
                            companytype: item.companyType.name,
                            service: item.movingServices && item.movingServices.name,
                            zipcodes: `${item.vendorZipcodes[0].zipcode}, ${item.vendorZipcodes[0].zipcode}`,
                          };
                          JobsArray.push(InspectionUserList);
                        });
                        if (!error)
                          return (
                              <>
                                <Row>
                                  <Col lg={12} xs={24}>
                                    <div className="table-searchbar">
                                      <Search
                                          placeholder={formatMessage({
                                            id: 'component.tablesearch.placeholder',
                                          })}
                                          onSearch={value => setSearchKeyword(value)}
                                          onChange={e => {
                                            const searchText = e.target.value;
                                            searchFunc(searchText);
                                            if (timerRef.current) {
                                              clearTimeout(timerRef.current);
                                            }
                                            timerRef.current = setTimeout(() => {
                                              console.log('On Change===>>>', searchText);
                                              setSearchKeyword(searchText);
                                            }, 2000);
                                          }}
                                          style={{ padding: '8px 15px', marginBottom: '20px' }}
                                          className="table-searchbar"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <div style={{ width: '100%', height: '100%', overflowX: 'auto' }}>
                                  <div className="unbordered-table-wrapper admin-tables">
                                    <Table
                                        columns={columns}
                                        onChange={handleTableChange}
                                        dataSource={JobsArray}
                                        pagination={{
                                          defaultPageSize: 30,
                                          showSizeChanger: true,
                                          showTotal: total =>
                                              `Total ${total} ${formatMessage({
                                                id: 'component.table.items',
                                              })}`,
                                          pageSizeOptions: ['30', '35', '40', '45', '50'],
                                        }}
                                        loading={loading}
                                    />
                                  </div>
                                </div>
                              </>
                          );
                      }
                      if (!loading && error) {
                        return (
                            <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen.</div>
                        );
                      }
                      return <></>;
                    }}
                  </Query>
                  <Modal
                      centered
                      title={`${formatMessage({
                        id: 'component.form.assignedzipcode',
                      })}`}
                      visible={zipcodeModal}
                      onOk={() => setzipcodeModal(false)}
                      onCancel={() => setzipcodeModal(false)}
                      width="450px"
                      footer={[
                        <Button
                            key="submit"
                            type="primary"
                            className="admin-forms-btn  mr_15"
                            size="large"
                            onClick={() => setzipcodeModal(false)}
                        >
                          {formatMessage({
                            id: 'component.form.okay',
                          })}
                        </Button>,
                      ]}
                  >
                    <p>{zipCodeData.toString()}</p>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
  );
};

export default ManageVendors;
