import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {CustomTextArea, Label, RadioGroup} from "components/src/components";
//Utils
import {Constants, Strings} from "components/src/utils";
import styles from "../styles";
import {setSellerInterViewForm} from "components/src/redux/action";
import {connect} from "react-redux";


const Q1 = Strings.eighthStep.q1;
const Q2 = Strings.comment;
const Q3 = Strings.eighthStep.q3;


function EighthStep(props) {

    const {setForm, interViewForm} = props;

    const [appliancesAvailable, setAppliancesAvailable] = useState(interViewForm.find(item => item.interview_section === 8 && item.question_no === 28)?.answer ?? 'Ja');
    const [dishWasherIncluded, setDishWasherIncluded] = useState(interViewForm.find(item => item.interview_section === 8 && item.question_no === 29)?.answer ?? 'Ja');

    const [appliancesComment, setAppliancesComment] = useState(interViewForm.find(item => item.interview_section === 8 && item.question_no === 28)?.comment ?? '')
    const [dishWasherComment, setDishWasherComment] = useState(interViewForm.find(item => item.interview_section === 8 && item.question_no === 29)?.comment ?? '')

    useEffect(() => {
        let answer = [
            {
                "question": Q1,
                "comment_questions": Q2,
                "interview_section": 8,
                "question_no": 28,
                "type": "RADIO",
                "answer": appliancesAvailable,
                "extra": null,
                "comment": appliancesComment
            },
            {
                "question": Q3,
                "interview_section": 8,
                "question_no": 29,
                "type": "RADIO",
                "answer": dishWasherIncluded,
                "extra": null,
                "comment": dishWasherComment
            },
        ]
        setForm([...interViewForm.filter(item => item.interview_section !== 8), ...answer])
    }, [
        appliancesComment,
        dishWasherIncluded,
        appliancesAvailable,
        dishWasherComment
    ])
    return (
        <View>
            <Label small
                   mt={10}
                   font_medium
                   mb={10}>
                {Q1}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS}
                        style={styles.radio}
                        contentStyle={styles.radioCont}
                        selectedOptions={appliancesAvailable}
                        onSelectOption={setAppliancesAvailable}/>

            <CustomTextArea labelText={Q2}
                            value={appliancesComment}
                            onChange={setAppliancesComment}
                            mt={10}/>

            <Label small
                   mt={10}
                   font_medium
                   mb={10}>
                {Q3}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS}
                        style={styles.radio}
                        contentStyle={styles.radioCont}
                        selectedOptions={dishWasherIncluded}
                        onSelectOption={setDishWasherIncluded}/>

            <CustomTextArea labelText={Q2}
                            value={dishWasherComment}
                            onChange={setDishWasherComment}
                            mt={10}/>


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form))
    }

};
export default connect(mapStateToProps, mapDispatchToProps)(EighthStep)
