/* eslint-disable @typescript-eslint/no-unused-vars */
import { decryptId, encryptId } from "../../../utils/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { Button,Spin, Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Query } from "react-apollo";
import { Link,useHistory } from "react-router-dom";
import { formatMessage } from "components/src/utils";
import {
  ADMIN_FAGLIGS_LIST,
  ADMIN_FAGLIGS_LIST_DETAILS,
} from "../../../../query/AdminQuery";
import { STORAGE_URL } from "../../../utils/constant";
// import professionalimage from '../../../assets/News _ Professional/Professional_Big.png';

const { Title } = Typography;
let professionalItem;

export const professionalDetail = props => {
    const { id } = props.match.params;

    let router=useHistory();
    return (
        <>
            <Row className="contentshadow">
                <Query
                    query={ADMIN_FAGLIGS_LIST_DETAILS}
                    variables={{
                        id: decryptId(id),
                        status: 1,
                    }}
                    fetchPolicy="cache-and-network"
                    onCompleted={responseData => {
                        professionalItem = responseData.professional_detail;

                        console.log('professionalItem==>>', professionalItem);
                    }}
                >
                    {({ data, error, loading }) => {
                        console.log(error);
                        if (professionalItem) {
                            return (
                                <Col xs={24} lg={24}>
                                    {professionalItem.cover_image_url && (
                                        <div className="cover-img-box">
                                            <img
                                                src={`${STORAGE_URL}${professionalItem?.cover_image_url}`}
                                                alt="news detail"
                                                className="img-fluid"
                                                style={{ margin: '0px auto 30px', display: 'block' }}
                                            />
                                        </div>
                                    )}
                                    <Title level={3} className="text-primary mt-20">
                                        {professionalItem.title}
                                    </Title>
                                    <div className="text-primary">
                                        <div dangerouslySetInnerHTML={{ __html: professionalItem.description }} />
                                    </div>
                                    {professionalItem.video_url && (
                                        <div className="videoSizing">
                                            <video width="600" height="400" controls className="videostyle mt-20">
                                                <track kind="captions" />
                                                <source
                                                    src={`${STORAGE_URL}${professionalItem.video_url}`}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        </div>
                                    )}
                                    <h5 style={{ opacity: 0.5 }} className="mt-20">
                                        Nyhet er publisert :{' '}
                                        {moment(professionalItem.created_at).format('DD-MM-YYY HH:mm')}
                                    </h5>
                                    <Row>
                                        <Col xs={24}>
                                            <Link
                                                to={{
                                                    pathname: `/admin/faglig/redigerfaglig/${id}`,
                                                    state: { orderItem: professionalItem },
                                                }}
                                            >
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="btn-secondary buttons admin-forms-btn mt-20"
                                                    size="large"
                                                >
                                                    {formatMessage({
                                                        id: 'component.news.editbutton',
                                                    })}
                                                </Button>
                                            </Link>
                                            <Button
                                                className="text-primary admin-forms-btn"
                                                style={{ marginLeft: 8 }}
                                                onClick={router.goBack}
                                                size="large"
                                            >
                                                {formatMessage({
                                                    id: 'component.form.cancel',
                                                })}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            );
                        }
                        if (loading && !error) {
                            return (
                                <div className="loader-wrapper">
                                    <Spin />
                                </div>
                            );
                        }
                        if (!loading && error) {
                            return (
                                <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>
                            );
                        }
                        return <React.Fragment />;
                    }}
                </Query>
            </Row>
        </>
    );
};

export default professionalDetail;
