import {StyleSheet} from 'react-native';
import {Color,ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:Color.SECONDARY_BG_COLOR
    },
    cont: {
        marginVertical:20,
        paddingHorizontal: 20

    },
    ProCont:{
        flex:1,
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical:10
    },
    imgCont:{
        flexDirection:'row',
        alignItems:'center'
    },
    content:{
        flex:1
    }

})
