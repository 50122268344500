import {Button, Col, Input, Row, Table, Typography} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import React, {useRef,useEffect, useState} from 'react';
import {formatMessage} from 'components/src/utils';
import {Mutation, Query} from 'react-apollo';
import {ADMIN_DOWNLOAD_INSURANCE_LIST} from '../../../../mutation/AdminMutation';
import {ADMIN_INSURACE_LIST} from '../../../../query/AdminQuery';
import {STORAGE_URL} from '../../../utils/constant';
// import data from '../../../../mock/officeceodata';
import {useHistory} from "react-router";

const {Title} = Typography;
const {Search} = Input;

export const Insurance = () => {
    let router=useHistory()
    const [searchKeyword, setSearchKeyword] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [orderData, setOrderData] = useState([]);
    const [insuranceList, setInsuranceList] = useState([]);
    const [prevPagination, setprevPagination] = useState({});
    const [paginationData, setPaginationData] = useState({
        current: 1,
        pageSize: 30,
        total: 1,
    });

    const timerRef = useRef(null);

    const usePreviousValue = () => {
        const ref = useRef();
        useEffect(() => {
            ref.current = searchKeyword;
        });
        return ref.current;
    };
    const prevValue = usePreviousValue();

    const searchFunc = e => {
        if (prevValue === '') {
            console.log('prevValue;', prevValue);
            setprevPagination(paginationData);
        }
        if (e !== '') {
            setPaginationData({
                current: 1,
                pageSize: 30,
                total: 1,
            });
        } else {
            setPaginationData(prevPagination);
        }
    };

    const handleTableChange = pagination => {
        console.log('pagination:', pagination);
        if (pagination) {
            setPaginationData(pagination);
            setprevPagination(paginationData);
        }
    };

    useEffect(() => {
        if (searchKeyword === '') {
            setprevPagination(paginationData);
        }
    }, []);

    const columns = [
        {
            title: `${formatMessage({ id: 'component.table.name' })}`,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: `${formatMessage({ id: 'component.table.email' })}`,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => {
                const aSplit = a.email.split('@');
                const bSplit = b.email.split('@');
                return aSplit[0].length - bSplit[0].length;
            },
        },
        {
            title: `${formatMessage({ id: 'component.table.phone' })}`,
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone.length - b.phone.length,
        },
        {
            title: `${formatMessage({ id: 'component.table.insuranceoption' })}`,
            dataIndex: 'insuranceoption',
            key: 'insuranceoption',
            sorter: (a, b) => a.insuranceoption.length - b.insuranceoption.length,
        },
    ];

    const download = (value, downloadFile) => {
        downloadFile({
            variables: {
                status: 1,
                file_type: value,
            },
        }).then(data => {
            console.log('Data submitted===>>>', data);

            if (data && data.data.download_insurance) {
                console.log('URL:::>>>', data.data.download_insurance.url);
                const a = document.createElement('a');
                a.href = `${STORAGE_URL}${data.data.download_insurance.url}`;
                a.download = 'Loan_Application_List';
                a.click();
            }
        });
    };
    const printDoc = printFile => {
        printFile({
            variables: {
                status: 1,
                file_type: 'XLSX',
            },
        }).then(data => {
            console.log('Data submitted===>>>', data);

            if (data && data.data.download_insurance) {
                console.log('URL:::>>>', data.data.download_insurance.url);

                const divToPrint = document.getElementById('insuranceTable');
                const newWin = window.open('');
                newWin.document.write(divToPrint.outerHTML);
                newWin.print();
                newWin.close();
            }
        });
    };

    return (
        <div>
            <div className="contentshadow">
                <div className="text-primary main">
                    <Row className="photo-order-wrapper">
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Title level={3} className="text-primary">
                                {formatMessage({
                                    id: 'component.insurance.title',
                                })}
                            </Title>

                            <Mutation mutation={ADMIN_DOWNLOAD_INSURANCE_LIST}>
                                {downloadFile => {
                                    return (
                                        <>
                                            <Button
                                                type="primary"
                                                className="btn-secondary admin-forms-btn with-lg-spaces"
                                                size="large"
                                                style={{ marginRight: 16 }}
                                                onClick={() => download('CSV', downloadFile)}
                                            >
                                                {formatMessage({ id: 'component.loanapplication.exportascsv' })}
                                            </Button>

                                            <Button
                                                type="primary"
                                                className="btn-secondary admin-forms-btn with-lg-spaces"
                                                style={{ marginRight: 16 }}
                                                size="large"
                                                onClick={() => download('XLSX', downloadFile)}
                                            >
                                                {formatMessage({ id: 'component.loanapplication.exportasexcel' })}
                                            </Button>

                                            <Button
                                                type="primary"
                                                className="btn-secondary admin-forms-btn with-lg-spaces"
                                                size="large"
                                                style={{ marginRight: 16 }}
                                                value="PDF"
                                                onClick={() => download('PDF', downloadFile)}
                                            >
                                                {formatMessage({ id: 'component.loanapplication.exportaspdf' })}
                                            </Button>
                                        </>
                                    );
                                }}
                            </Mutation>
                            <Mutation mutation={ADMIN_DOWNLOAD_INSURANCE_LIST}>
                                {printFile => {
                                    return (
                                        <>
                                            <Button
                                                type="primary"
                                                className="btn-secondary admin-forms-btn with-lg-spaces"
                                                size="large"
                                                style={{ marginRight: 16 }}
                                                onClick={() => printDoc(printFile)}
                                            >
                                                {formatMessage({ id: 'component.loanapplication.printall' })}
                                            </Button>
                                        </>
                                    );
                                }}
                            </Mutation>
                            <Row>
                                <Col xs={24}>
                                    <Query
                                        query={ADMIN_INSURACE_LIST}
                                        variables={{
                                            // input: {
                                            status: 1,
                                            first: paginationData.pageSize,
                                            page: paginationData.current,
                                            key: searchKeyword,
                                            sorting: {
                                                field: 'id',
                                                order: 'DESC',
                                            },
                                            // },
                                        }}
                                        fetchPolicy="no-cache"
                                        onCompleted={responseData => {
                                            setOrderData(responseData.insurance_lists.data);
                                            if (
                                                responseData &&
                                                responseData.insurance_lists &&
                                                responseData.insurance_lists.paginatorInfo
                                            ) {
                                                setPaginationData({
                                                    current: responseData.insurance_lists.paginatorInfo.currentPage,
                                                    pageSize: responseData.insurance_lists.paginatorInfo.perPage,
                                                    total: responseData.insurance_lists.paginatorInfo.total,
                                                });
                                            }
                                            if (
                                                responseData &&
                                                responseData.insurance_lists &&
                                                responseData.insurance_lists.data
                                            ) {
                                                setInsuranceList(responseData.insurance_lists.data);
                                            }
                                        }}
                                    >
                                        {({ data, loading, error }) => {
                                            console.log(error);
                                            const InsuranceDataList = [];
                                            if (data && data.insurance_lists && data.insurance_lists.data) {
                                                console.log('insodwe;', data.insurance_lists.data);
                                                // eslint-disable-next-line array-callback-return
                                                data.insurance_lists.data.map(item => {
                                                    const InsuranceList = {
                                                        key: item.id,
                                                        name: `${item.name}`,
                                                        email: item.email,
                                                        phone: item.phone,
                                                        insuranceoption: item.propInsuranceType.name,
                                                    };
                                                    InsuranceDataList.push(InsuranceList);
                                                });
                                            }
                                            if (!error) {
                                                return (
                                                    <>
                                                        <Row>
                                                            <Col lg={12} xs={24}>
                                                                <div className="table-searchbar">
                                                                    <Search
                                                                        placeholder={formatMessage({
                                                                            id: 'component.tablesearch.placeholder',
                                                                        })}
                                                                        onSearch={value => setSearchKeyword(value)}
                                                                        onChange={e => {
                                                                            const searchText = e.target.value;
                                                                            searchFunc(searchText);
                                                                            if (timerRef.current) {
                                                                                clearTimeout(timerRef.current);
                                                                            }
                                                                            timerRef.current = setTimeout(() => {
                                                                                console.log('On Change===>>>', searchText);
                                                                                setSearchKeyword(searchText);
                                                                            }, 2000);
                                                                        }}
                                                                        style={{ padding: '8px 15px', marginBottom: '20px' }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div style={{ width: '100%', height: '100%', overflowX: 'auto' }}>
                                                            <div className="unbordered-table-wrapper admin-tables">
                                                                <Table
                                                                    id="insuranceTable"
                                                                    columns={columns}
                                                                    onChange={handleTableChange}
                                                                    dataSource={InsuranceDataList}
                                                                    pagination={{
                                                                        current: paginationData.current,
                                                                        pageSize: paginationData.pageSize,
                                                                        total: paginationData.total,
                                                                        showSizeChanger: true,
                                                                        showTotal: () =>
                                                                            `Total ${paginationData.total} ${formatMessage({
                                                                                id: 'component.table.items',
                                                                            })}`,
                                                                        pageSizeOptions: ['30', '35', '40', '45', '50'],
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }
                                            if (!loading && error) {
                                                return (
                                                    <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen.</div>
                                                );
                                            }
                                            return <></>;
                                        }}
                                    </Query>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row>
                <Col lg={24}>
                    <Button
                        type="primary"
                        className="btn-secondary admin-forms-btn with-lg-spaces back-button"
                        size="large"
                        style={{ marginRight: 16 }}
                        onClick={router.goBack}
                    >
                        {formatMessage({ id: 'component.form.back' })}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default Insurance;
