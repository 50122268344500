import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {AddServiceView, Header, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {connect} from "react-redux";
//Network
import {Query} from "@apollo/react-components";
//Utils
import {Color, Constants, DateUtils, formatPrice, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';
import moment from "moment";
import {SELLER_PHOTO_SERVICES} from "../../../../../../api/sellerQuery";
// import {Button, InputItem} from "@ant-design/react-native";
// import { Radio, WhiteSpace } from '@ant-design/react-native';

// import { DatePickerView } from 'antd-mobile';


class ViewDetailsPhotoService extends React.Component {

    constructor(props) {
        super(props)
        this.scrollY = new Animated.Value(0)
        this.state = {
            orderPhoto: IS_WEB ? props.history.location.state?.order ?? props.property?.order_photo ?? {} : props.navigation.getParam('order', props.property?.order_photo ?? {}),
            addComment: false,
            comment: ''
        }
    }

    renderAdditionalServiceItem = (item, index, parentIndex) => {
        return (
            <AddServiceView title={item.name}
                            price={parseInt(item.price) ? `Kr ${formatPrice(item.price)},-` : item.free_text}
                            image={item.image}
                            name={'disclaimer'}
                            extraNote={item.external_url}
                            mb={9}
                            desc={item.description}
                            isSelected={this.state.selectedAddServices[parentIndex] === item.id}
                            onPress={() => this.addOrRemoveItem(parseInt(item.id), parentIndex)}/>)
    };


    renderAdditionalService = (item, index) => {
        let data = item.purchased_office_template.purchased_office_services.filter(item => item.service_selected)
        return (
            <View key={index.toString()}>
                <Label font_medium
                       mb={10}>
                    {item.name}
                </Label>
                {data.map((item, childIndex) => this.renderAdditionalServiceItem(item, childIndex, index))}
            </View>
        )
    };

    render() {
        const item = this.state.orderPhoto
        return (
            <Provider locale={enUS}>
                <Header animatedTitle={Strings.photo}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.viewicon}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}

                                    name={'photo'}
                                    color={Color.PRIMARY_DARK}/>


                            </View>
                            <View>
                                <View style={styles.mainviewdata}>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.status}
                                        </Label>
                                        <Label small
                                               font_regular

                                               align={'left'}>
                                            {item?.order_status}
                                        </Label>
                                    </View> <View style={styles.subviewdata}>
                                    <Label small
                                           font_medium>
                                        {Strings.dates}
                                    </Label>
                                    <Label small
                                           font_regular

                                           align={'left'}>
                                        {moment(item?.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                                    </Label>
                                </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.startTime}
                                        </Label>
                                        <Label
                                            small
                                            font_regular

                                            align={'left'}>
                                            {moment(item?.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                        </Label>
                                    </View>
                                    {item?.order_status === Constants.ORDER_STATUS.ORDERED ?
                                        (<View style={styles.subviewdata}>
                                            <Label small
                                                   font_medium>
                                                {Strings.endTime}
                                            </Label>
                                            <Label small
                                                   font_regular

                                                   align={'left'}>
                                                {moment(item?.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                            </Label>
                                        </View>) : null}
                                </View>
                            </View>


                            {item.comment ? (
                                <>
                                    <View style={styles.commentview}>
                                        <Label small
                                               mb={8}
                                               font_medium
                                               align={'left'}>
                                            {Strings.comments}
                                        </Label>
                                    </View>
                                    <Label
                                        mt={5}
                                        mb={5}
                                        small
                                        font_regular
                                        align={'left'}>
                                        {item?.comment}
                                    </Label>
                                </>) : null}

                            <Label small
                                   mt={10}
                                   mb={15}
                                   font_medium
                                   align={'left'}>
                                {Strings.purchasedService}
                            </Label>
                            <Query query={SELLER_PHOTO_SERVICES}
                                   variables={{
                                       status: 1,
                                       propertyId: this.props.property?.id,
                                   }}>
                                {({data, loading, error}) => {

                                    let additioanlSServices = [];
                                    data?.photo_type_services.map(service => {
                                        if (service.purchased_office_template?.purchased_office_services?.filter(item => item.service_selected).length) {
                                            additioanlSServices.push(service)
                                        }
                                    })
                                    if (loading && !data) {
                                        return <ActivityIndicator color={Color.PRIMARY}/>
                                    }
                                    return (
                                        <View>
                                            {additioanlSServices?.map(this.renderAdditionalService)}
                                        </View>
                                    )
                                }}
                            </Query>
                        </View>
                    </View>

                </Animated.ScrollView>
            </Provider>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(ViewDetailsPhotoService);
