import React from 'react'
import {Image, ScrollView, TouchableOpacity, View} from 'react-native'
import {Header, Label, LinearGradient} from "components/src/components";
import {Color, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';

import photo from 'components/src/image/photo.png';
import ds_assignment from 'components/src/image/ds_assignment.png';
import marketing_material from 'components/src/image/marketing_material.png';
import display from 'components/src/image/Display.jpg';
import see_finn_ad from 'components/src/image/see_finn_ad.png';
import Financing from 'components/src/image/Financing.png';
import Insurance from 'components/src/image/Insurance.png';

const DASHBOARD = [{
    name: `${Strings.photo}/${Strings.styling}`,
    icon: 'photo',
    bg: photo,
    route:Routes.InspectionPhoto
}, {
    name: Strings.digitalMarketing,
    icon: 'digital-sales-assignment',
    bg: ds_assignment
}, {
    name: Strings.printMarketing,
    icon: 'disclaimer',
    bg: marketing_material
}, {
    name: Strings.broker,
    icon: 'profile',
    bg: display,
    route:Routes.InspectionBroker

}, {
    name: Strings.propertyValue,
    icon: 'disclaimer',
    bg: see_finn_ad
}, {
    name: Strings.boligselgerforsikring,
    icon: 'offers-on-insurance',
    bg: Insurance
}/*, {
    name: Strings.calendar,
    icon: 'calendar'
}*/, {
    name: Strings.missionCost,
    icon: 'rate',
    bg: Financing,

}];

function StartInspection(props) {
    const {history, navigation} = props;

    const renderDashboardItem = (item, name) => {
        return (

            <TouchableOpacity activeOpacity={0.7}
                              style={[styles.dashboardItem]}
                              key={item.name}
                              onPress={() => {
                                  if(item.route){
                                      IS_WEB?history.push(item.route):navigation.navigate(item.route)
                                  }
                              }}
                              rippleContainerBorderRadius={ThemeUtils.relativeRealWidth(2.5)}>

                <Image source={item.bg}
                       style={styles.imgServices}/>

                <LinearGradient
                    colors={[Color.TRANSPARENT, Color.PRIMARY]}
                    style={[styles.imgServices, {position: 'absolute'}]}>
                    <View style={styles.lblCont}>
                        <Icon name={item.icon}
                              size={ThemeUtils.fontXXLarge}
                              color={Color.SECONDARY}/>
                        <Label small
                               font_medium
                               mt={10}

                               color={Color.WHITE}>
                            {item.name}
                        </Label>
                    </View>
                </LinearGradient>
            </TouchableOpacity>
        )
    };

    return (
        <View style={styles.container}>
            <Header navigation={IS_WEB ? history : navigation}/>
            <ScrollView>
                <View>
                    <Label xlarge
                           font_medium
                           mt={20}
                           ml={ThemeUtils.relativeRealWidth(3)}>
                        {'EMILY PETTERSEN'}
                    </Label>
                    <Label small
                           font_medium
                           mt={5}
                           mb={10}
                           ml={ThemeUtils.relativeRealWidth(3)}>
                        {'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350'}
                    </Label>


                        <View style={styles.dashBoardCont}>
                            {DASHBOARD.map(renderDashboardItem)}
                        </View>
                </View>
            </ScrollView>
        </View>

    )
}

export default StartInspection
