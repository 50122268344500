import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from '../../../../../../utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 20,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        justifyContent: 'space-between',
        margin: containerHorizontalMargin
    },

    fieldContainer: {
        alignContent: 'center',
    },
    iconContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },

    subviewdata: {
        marginTop: 10
    },
});
export {styles};
