import React from 'react'
//Components
import {Animated, View} from 'react-native'
import {Header, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {connect} from "react-redux";
//Utils
import {Color, Constants, DateUtils, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';
import moment from "moment";
// import {Button, InputItem} from "@ant-design/react-native";
// import { Radio, WhiteSpace } from '@ant-design/react-native';

// import { DatePickerView } from 'antd-mobile';


class ViewDetailsStyling extends React.Component {
    constructor(props) {
        super(props);
        const stylingData = IS_WEB ? props.history.location.state?.stylingData ?? props.property?.orderstyling ?? {} : props.navigation.getParam('stylingData', props.property?.orderstyling ?? {});
        if (!stylingData) {
            if (IS_WEB) {
                props.history.replace(Routes.SalesPreparation)
            }
        }
        this.state = {...stylingData};
        this.scrollY = new Animated.Value(0);

    }


    render() {
        const {order_status, order_date, styling_type, comment, earliest_start_time, latest_start_time} = this.state;

        return (
            <Provider locale={enUS}>
                <Header animatedTitle={Strings.styling}
                        headerRightFirst={'dashboard'}
                        onPressRightFirst={() => IS_WEB ? this.props.history.replace(Routes.SalesPreparation) : this.props.navigation.goBack()}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.viewicon}>
                                <Icon
                                    size={ThemeUtils.fontXLarge}

                                    name={'styling'}
                                    color={Color.PRIMARY_DARK}/>


                            </View>
                            <View>
                                <Label
                                    mt={18}
                                    mb={18}
                                    small
                                    font_regular
                                    align={'left'}>
                                    {'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'}
                                </Label>

                                <View style={styles.mainviewdata}>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium mb={10} mt={10}>
                                            {Strings.stylingtype}
                                        </Label>
                                        <Label small
                                               font_regular
                                               mb={10}
                                               align={'left'}>
                                            {styling_type?.name}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium mb={10}>
                                            {Strings.dates}
                                        </Label>
                                        <Label small
                                               font_regular
                                               mb={10}
                                               align={'left'}>
                                            {moment(order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium mb={10}>
                                            {Strings.startTime}
                                        </Label>
                                        <Label
                                            small
                                            font_regular
                                            mb={10}
                                            align={'left'}>
                                            {moment(earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                        </Label>
                                    </View>
                                    {order_status === Constants.ORDER_STATUS.ORDERED ?
                                        (<View style={styles.subviewdata}>
                                            <Label small mb={10}
                                                   font_medium>
                                                {Strings.endTime}
                                            </Label>
                                            <Label small
                                                   font_regular
                                                   mb={10}
                                                   align={'left'}>
                                                {moment(latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                            </Label>
                                        </View>) : null}
                                    {/*<View style={styles.subviewdata}>
                                        <Label small mb={10}
                                               font_medium>
                                            {Strings.Zipcode}
                                        </Label>
                                        <Label small
                                               font_regular
                                               align={'left'}>
                                            {`${item.Zipcode}`}
                                        </Label>
                                    </View>*/}
                                </View>
                            </View>
                            {comment ? (<View>

                                <View style={styles.commentview}>

                                    <Label small
                                           mb={8}
                                           font_medium
                                           align={'left'}>
                                        {Strings.comments}
                                    </Label>
                                </View>
                                <Label
                                    mt={5}
                                    mb={5}
                                    small
                                    font_regular
                                    align={'left'}>
                                    {comment}
                                </Label>
                            </View>) : null}

                        </View>

                    </View>
                </Animated.ScrollView>
            </Provider>
        );
    }

}

const mapStateToProps = (state) => {

    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(ViewDetailsStyling);
