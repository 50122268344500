import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {
    Attachment,
    CheckBox,
    CustomButton,
    DropDown,
    FilePicker,
    FileType,
    Header,
    Label,
    Toast,
} from "components/src/components";
//Third party
//redux
import {setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {SELLER_HEAT_TYPE, SELLER_PROPERTY_LIST} from "components/src/api/sellerQuery";
import {SELLER_ENERGY_LABELING} from "components/src/api/sellerMutation";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    Icon,
    IS_WEB,
    MessageUtils,
    openInNewTab,
    showMessage,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';


// import {Button, InputItem} from "@ant-design/react-native";
// import { PickerView } from '@ant-design/react-native';
// import { DatePickerView } from 'antd-mobile';

const selectLetter = [{
    name: 'A',
    value: 'A',
}, {
    name: 'B',
    value: 'B',
}, {
    name: 'C',
    value: 'C',
}, {
    name: 'D',
    value: 'D',
}, {
    name: 'E',
    value: 'E',
}, {
    name: 'F',
    value: 'F',
}, {
    name: 'G',
    value: 'G',
}];

const selectColor = [{
    name: 'Mørkegrønn',
    value: 'Morkegronn',
}, {
    name: 'Grønn',
    value: 'Green',
}, {
    name: 'Gul',
    value: 'Gul',
}, {
    name: 'Oransje',
    value: 'Orange',
}, {
    name: 'Rød',
    value: 'Red',
},];


class EnergyLabeling extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.property?.energy_labeling)
        this.state = {
            energyIds: this.props.property?.energy_labeling?.HouseHeatType?.map(item => item.id) ?? [],
            letterCode: this.props.property?.energy_labeling?.letter_code ?? null,
            colorCode: this.props.property?.energy_labeling?.color_code ?? null,
            certificate: []
        };
        this.scrollY = new Animated.Value(0)
    }

    saveEnergyLabeling = async (submitEnergyData) => {
        const {letterCode, colorCode, certificate, energyIds} = this.state;
        const {property} = this.props
        if (letterCode && colorCode && energyIds.length) {
            let variables = {
                property_id: property.id,
                letter_code: letterCode,
                color_code: colorCode,
                heats_ids: energyIds,
            }
            if (certificate.length) {
                variables.energy_certificate_url = certificate[0]
            }
            if (!this.props.property?.energy_labeling?.document && !certificate.length) {
                await showMessage(MessageUtils.Errors.file,Constants.MessageType.FAILED)
            } else {
                submitEnergyData({
                    variables: {
                        input: variables,
                    },
                });
            }
        }
    };

    render() {
        return (
            <View style={CommonStyle.container}>

                <Toast ref={r => this.toast = r}/>
                <Header animatedTitle={Strings.energyLabeling}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>
                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.titleCont}>

                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'energy-labeling'}
                                    color={Color.PRIMARY_DARK}/>
                            </View>


                            <Label
                                mt={16}
                                font_regular
                                small
                                align={'left'}>
                                {Strings.energyLabelingInstruction}
                            </Label>
                            <CustomButton
                                onPress={() => openInNewTab('https://www.energimerking.no/no/energimerking-bygg/energimerking-av-bolig')}
                                mt={18}
                                mb={18}
                                title={Strings.loginEnergyMerking}/>

                            <View>
                                <DropDown options={selectLetter}
                                          title={Strings.letterCode}
                                          selectedOption={this.state.letterCode}
                                          placeholder={Strings.selectlettercode}
                                          style={{boderColor: Color.INPUT_BORDER}}
                                          onSelectOptions={(value) => this.setState({letterCode: value})}/>

                            </View>
                            <View style={{marginVertical: 20}}>

                                <DropDown options={selectColor}
                                          title={Strings.colorcode}
                                          small
                                          selectedOption={this.state.colorCode}
                                          placeholder={Strings.selectcolorcode}
                                          style={{borderColor: Color.INPUT_BORDER}}
                                          onSelectOptions={(value) => this.setState({colorCode: value})}/>
                            </View>
                            <View style={styles.RadiobView}>
                                <Label small
                                       font_medium
                                       mb={10}>
                                    {Strings.howisthehouseheated}
                                </Label>
                                <View style={styles.checkbox}>
                                    {console.log(this.state)}
                                    <Query query={SELLER_HEAT_TYPE}>
                                        {({loading, error, data}) => {
                                            if (loading && !data) {
                                                return <ActivityIndicator color={Color.PRIMARY}/>
                                            }
                                            return (<CheckBox options={data?.heat_types ?? []}
                                                              style={styles.energyradio}
                                                              defaultChecked={this.state.energyIds}
                                                              onCheckChanged={(value) => this.setState({energyIds: value})}/>)
                                        }}
                                    </Query>

                                </View>
                                <View style={styles.titleCont}>
                                    <Label small
                                           font_medium
                                           mb={15}
                                           align={'left'}>
                                        {Strings.uploadenergycertificate}
                                    </Label>
                                </View>

                                <FilePicker type={FileType.PDF}
                                            style={styles.filePicker}
                                            onFilePicked={(data, file) => this.setState({certificate: file})}/>

                                {/*<View style={styles.UploadedMainView}>
                                    <View style={styles.IconView}>
                                        <Icon
                                            size={ThemeUtils.fontSmall}
                                            name={'attachment'}
                                            color={Color.PRIMARY_DARK}/>

                                        <Label small
                                               font_regular
                                               mt={2}
                                               ml={10}
                                               mr={15}
                                               align={'right'}>
                                            {Strings.energycertifiacatepdf}
                                        </Label>
                                    </View>
                                    <View style={styles.IconViews}>

                                        <Icon
                                            size={ThemeUtils.fontSmall}
                                            name={'download'}
                                            style={styles.DownloadIcon}
                                            color={Color.PRIMARY_DARK}/>

                                        <Icon
                                            size={ThemeUtils.fontSmall}
                                            name={'delete'}
                                            style={styles.DeleteIcon}
                                            color={Color.PRIMARY_DARK}/>

                                    </View>


                                </View>*/}
                                {this.state.certificate.map(item => (
                                    <Attachment isDeletable
                                                isDownloadable={false}
                                                onPressDelete={() => this.setState({certificate: []})}
                                                name={this.state.certificate[0].name}/>
                                ))}
                                {!this.state.certificate.length && this.props.property?.energy_labeling?.document ? (
                                    <Attachment onPressDelete={() => this.setState({certificate: []})}
                                                onPressDownload={() => openInNewTab(`${Constants.APIConfig.STORAGE_URL}${this.props.property?.energy_labeling?.document?.doc_url}`)}
                                                name={this.props.property?.energy_labeling?.document?.title}/>
                                ) : null}
                                <Mutation mutation={SELLER_ENERGY_LABELING}
                                          onCompleted={(data) => {
                                              showMessage(data?.property_energy_labeling?.meta?.message,Constants.MessageType.SUCCESS)
                                              IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack()
                                          }}
                                          refetchQueries={[{
                                              query: SELLER_PROPERTY_LIST,
                                              variables: {
                                                  status: 1
                                              }
                                          }]}
                                          onError={() => {

                                          }}>
                                    {(submitEnergyData, {data, loading, error}) => (
                                        <CustomButton font_medium
                                                      mt={18}
                                                      mb={18}
                                                      loading={loading}
                                                      onPress={() => this.saveEnergyLabeling(submitEnergyData)}
                                                      title={Strings.Save}/>
                                    )}

                                </Mutation>

                            </View>

                            <View>
                            </View>
                        </View>

                    </View>
                </Animated.ScrollView>
            </View>

        )
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    console.log(state.property)
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnergyLabeling);
