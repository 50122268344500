/* eslint-disable no-shadow */
import {Button, Col, Form, Input, InputNumber, Modal, notification, Row, Select, Spin, Typography, Upload,} from 'antd';
import React, {useRef, useState} from 'react';
import {formatMessage} from "components/src/utils";
import {CaretDownFilled, LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import {decryptId} from "../../../../../utils";
import {Mutation, Query} from 'react-apollo';
import {STORAGE_URL, USER_TYPE_SERVER} from '../../../../../utils/constant';
import {ADMIN_DELETE_USER, ADMIN_UPDATE_VENORS_USER,} from '../../../../../../mutation/AdminMutation';
import {ADMIN_OFFICES_LIST_ONLY, ADMIN_VENDORS_LIST, ADMIN_ZIPCODE_LIST,} from '../../../../../../query/AdminQuery';

import './editstyling.scss';
import {useHistory} from "react-router";
import PlaceHolderImage from '../../../../../assets/defaultuser.png'
const {Title} = Typography;

let formData;
const EditStyling = props => {
    const {id} = props.match.params;
    // eslint-disable-next-line prefer-destructuring

    let router=useHistory();

    const [imageUrl, setimageUrl] = useState();
    const [imageObject, setimageObject] = useState();
    const [isimageUrlChanged, setisimageUrlChanged] = useState(false);
    const [optionsPoststed, setOptionsPoststed] = useState([]);

    const fileRef = useRef(null);

    const options = [];

    const onFinish = (values, editStylingUser) => {
        console.log('Success:====>>>', values);
        editStylingUser({
            variables: {
                id: decryptId(id),
                user_profile: isimageUrlChanged ? imageObject : null,
                first_name: values.firstname,
                last_name: values.lastname,
                email: values.email,
                mobile: values.phone,
                address: values.address,
                company_name: values.companyName,
                city: values.city,
                office_ids: values.office,
                zipcode: values.zipcode,
                user_type_id: USER_TYPE_SERVER.STYLIST,
            },
        }).then(data => {
            console.log('Data submitted===>>>', data);
            if (data && data.data.update_vendor && data.data.update_vendor.meta) {
                notification.success({
                    message: data.data.update_vendor.meta.message,
                    description: '',
                    duration: 3,
                });
                router.push(`/admin/stylist`);
            }
        });
        console.log('downn');
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const [deleteModal, setdeleteModal] = useState(false);
    const onDelete = (deleteID, deletUser) => {
        setdeleteModal(false);
        console.log('delete id ==>', deleteID);
        deletUser({
            variables: {
                id: deleteID,
                user_type_id: USER_TYPE_SERVER.STYLIST,
            },
        }).then(data => {
            console.log('Data submitted===>>>', data);
            if (data && data.data.delete_vendor && data.data.delete_vendor.meta) {
                notification.success({
                    message: data.data.delete_vendor.meta.message,
                    description: '',
                    duration: 3,
                });
                router.push('/admin/stylist');
            }
        });
    };

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = info => {
        getBase64(info.file.originFileObj, image => setimageUrl(image));
        setimageObject(info.file.originFileObj);
        setisimageUrlChanged(true);
    };

    return (
        <div className="contentshadow">
            <div className="text-primary main">
                <Row>
                    <Col xs={24}>
                        <Title level={3} className="text-primary">
                            {formatMessage({
                                id: 'component.adminstyling.editstyling',
                            })}
                        </Title>
                    </Col>
                </Row>
                <Query
                    query={ADMIN_VENDORS_LIST}
                    variables={{
                        input: {
                            status: true,
                            user_type_id: USER_TYPE_SERVER.STYLIST,
                            page: 1,
                            first: 300,
                        },
                    }}
                    onCompleted={responseData => {
                        let tempVar;
                        const photographerUser = responseData.venders.data.filter(
                            i => i.id === parseInt(decryptId(id), 10),
                        );
                        photographerUser.map(i => (tempVar = i.profile_image));
                        if(tempVar){
                            setimageUrl(`${STORAGE_URL}${tempVar}`);
                        }
                    }}
                    fetchPolicy="cache-and-network"
                >
                    {({data, error, loading}) => {
                        console.log(error);
                        if (data && !loading && !error) {
                            if (data && data.venders.data) {
                                const StylistUser = data.venders.data.filter(
                                    i => i.id === parseInt(decryptId(id), 10),
                                );
                                // eslint-disable-next-line no-return-assign
                                StylistUser.map(i => (formData = i));
                                console.log(formData);
                                return (
                                    <>
                                        <Row>
                                            <Col xs={24}>
                                                <Mutation mutation={ADMIN_UPDATE_VENORS_USER}>
                                                    {(editStylingUser, {loading}) => {
                                                        return (
                                                            <Form
                                                                layout="vertical"
                                                                className="user-forms"
                                                                name="editprofile"
                                                                initialValues={{
                                                                    remember: true,
                                                                    id: formData.id,
                                                                    profilepicutre: formData?.profile_image,
                                                                    firstname: formData?.first_name,
                                                                    lastname: formData?.last_name,
                                                                    email: formData?.email,
                                                                    phone: formData?.mobile,
                                                                    city: formData?.city,
                                                                    address: formData?.address,
                                                                    companyName: formData?.company_name,
                                                                    office: formData?.assign_offices
                                                                        ?.split(',')
                                                                        .map(i => parseInt(i, 10)),
                                                                    zipcode: formData?.zipcode !== null ? formData?.zipcode : [],
                                                                }}
                                                                colon={false}
                                                                onFinish={values => onFinish(values, editStylingUser)}
                                                                onFinishFailed={onFinishFailed}
                                                            >
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Item name="id">
                                                                            <Input hidden/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={24} md={8} lg={4}>
                                                                        <Form.Item
                                                                            name="profile_image"
                                                                            label={formatMessage({
                                                                                id: 'component.form.profilepicture',
                                                                            })}
                                                                        >
                                                                            <Row>
                                                                                <Col xs={24}>
                                                                                    <div className="user-profile-box">
                                                                                        <img
                                                                                            src={imageUrl?imageUrl:PlaceHolderImage}
                                                                                            alt={formData.first_name + formData.last_name}
                                                                                            className="profile-picture"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col xs={24} className="mt-3">
                                                                                    <Upload
                                                                                        showUploadList={false}
                                                                                        values={imageUrl?imageUrl:PlaceHolderImage}
                                                                                        onChange={handleChange}
                                                                                    >
                                                                                        <Button>
                                                                                            <UploadOutlined/>{' '}
                                                                                            {formatMessage({
                                                                                                id: 'component.form.upload',
                                                                                            })}
                                                                                        </Button>
                                                                                    </Upload>
                                                                                </Col>
                                                                            </Row>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={16}>
                                                                    <Col xs={24} lg={16}>
                                                                        <Form.Item
                                                                            name="companyName"
                                                                            label="Selskapsnavn"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: `Vennligst skriv inn selskapsnavn`,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                style={{width: '100%'}}
                                                                                placeholder={`Tast inn selskapsnavn`}
                                                                                size="large"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={16}>
                                                                    <Col xs={24} lg={8}>
                                                                        <Form.Item
                                                                            name="firstname"
                                                                            label={formatMessage({
                                                                                id: 'component.form.firstname',
                                                                            })}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: `Vennligst skriv inn ${formatMessage({
                                                                                        id: 'component.form.firstname',
                                                                                    }).toLowerCase()}`,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                style={{width: '100%'}}
                                                                                placeholder={formatMessage({
                                                                                    id: 'component.form.firstname',
                                                                                })}
                                                                                size="large"
                                                                                className="pickerwidth"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} lg={8}>
                                                                        <Form.Item
                                                                            name="lastname"
                                                                            label={formatMessage({
                                                                                id: 'component.form.lastname',
                                                                            })}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: `Vennligst skriv inn ${formatMessage({
                                                                                        id: 'component.form.lastname',
                                                                                    }).toLowerCase()}`,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                placeholder={formatMessage({
                                                                                    id: 'component.form.lastname',
                                                                                })}
                                                                                size="large"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={16}>
                                                                    <Col xs={24} lg={8}>
                                                                        <Form.Item
                                                                            name="email"
                                                                            label={formatMessage({
                                                                                id: 'component.form.email',
                                                                            })}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Vennligst oppgi epost',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                style={{width: '100%'}}
                                                                                placeholder={formatMessage({
                                                                                    id: 'component.form.email',
                                                                                })}
                                                                                size="large"
                                                                                className="pickerwidth"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} lg={8}>
                                                                        <Form.Item
                                                                            name="phone"
                                                                            label={formatMessage({
                                                                                id: 'component.form.phone',
                                                                            })}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: `Vennligst skriv inn ${formatMessage({
                                                                                        id: 'component.form.phone',
                                                                                    }).toLowerCase()}`,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                placeholder={formatMessage({
                                                                                    id: 'component.form.phone',
                                                                                })}
                                                                                size="large"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={16}>
                                                                    <Col xs={24} lg={16}>
                                                                        <Form.Item
                                                                            name="address"
                                                                            label={formatMessage({
                                                                                id: 'component.form.address',
                                                                            })}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: `Vennligst skriv inn ${formatMessage({
                                                                                        id: 'component.form.address',
                                                                                    }).toLowerCase()}`,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                style={{width: '100%'}}
                                                                                placeholder={`Angi ${formatMessage({
                                                                                    id: 'component.form.address',
                                                                                }).toLowerCase()}`}
                                                                                size="large"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={8}>
                                                                    <Col xs={24} lg={16}>
                                                                        <Query
                                                                            query={ADMIN_ZIPCODE_LIST}
                                                                            variables={{
                                                                                status: 1,
                                                                                user_type_id: USER_TYPE_SERVER.PHOTO,
                                                                                user_id: 1,
                                                                            }}
                                                                            onCompleted={responseData => {
                                                                                setOptionsPoststed([
                                                                                    ...new Map(
                                                                                        responseData.zipcode_list.map(o => [o.poststed, o]),
                                                                                    ).values(),
                                                                                ]);
                                                                            }}
                                                                        >
                                                                            {({data}) => {
                                                                                if (data && data.zipcode_list) {
                                                                                    return (
                                                                                        <>
                                                                                            <Form.Item
                                                                                                name="city"
                                                                                                label={formatMessage({
                                                                                                    id: 'component.table.city',
                                                                                                })}
                                                                                                rules={[
                                                                                                    {
                                                                                                        required: true,
                                                                                                        message: `Vennligst fyll inn ${formatMessage({
                                                                                                            id: 'component.table.city',
                                                                                                        }).toLowerCase()}`,
                                                                                                    },
                                                                                                ]}
                                                                                            >
                                                                                                <Select
                                                                                                    showSearch
                                                                                                    optionFilterProp="children"
                                                                                                    filterOption={(input, option) =>
                                                                                                        option?.children
                                                                                                            ?.toLowerCase()
                                                                                                            ?.indexOf(input.toLowerCase()) >= 0
                                                                                                    }
                                                                                                    size="large"
                                                                                                    style={{width: '100%'}}
                                                                                                    suffixIcon={
                                                                                                        <CaretDownFilled/>}
                                                                                                >
                                                                                                    {optionsPoststed.map(i => (
                                                                                                        <Select.Option
                                                                                                            value={i?.poststed}>
                                                                                                            {i?.poststed}
                                                                                                        </Select.Option>
                                                                                                    ))}
                                                                                                </Select>
                                                                                            </Form.Item>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                                return (
                                                                                    <div
                                                                                        className="loader-wrapper-inline">
                                                                                        <LoadingOutlined/>
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Query>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={8}>
                                                                    <Col xs={24} lg={16}>
                                                                        <Form.Item
                                                                            name="zipcode"
                                                                            label={formatMessage({
                                                                                id: 'component.form.zipcode',
                                                                            })}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: `Vennligst oppgi ${formatMessage({
                                                                                        id: 'component.form.zipcode',
                                                                                    }).toLowerCase()}`,
                                                                                },
                                                                                {
                                                                                    pattern: /^(0|-*[1-9]+[0-9]*)$/,
                                                                                    message: `Vennligst oppgi ${formatMessage({
                                                                                        id: 'component.form.zipcode',
                                                                                    }).toLowerCase()}`,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumber style={{width: "100%"}}
                                                                                placeholder={`Tast inn ${formatMessage({
                                                                                    id: 'component.form.zipcode',
                                                                                }).toLowerCase()}`}
                                                                                size="large"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={[16]}>
                                                                    <Col xs={24} md={24} lg={16}>
                                                                        <Query
                                                                            query={ADMIN_OFFICES_LIST_ONLY}
                                                                            variables={{
                                                                                status: true,
                                                                            }}
                                                                        >
                                                                            {({data}) => {
                                                                                if (data && data.offices_list) {
                                                                                    return (
                                                                                        <Form.Item
                                                                                            name="office"
                                                                                            label={`${formatMessage({
                                                                                                id: 'component.form.office',
                                                                                            })}`}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: `Vennligst velg ${formatMessage({
                                                                                                        id: 'component.form.office',
                                                                                                    }).toLowerCase()}`,
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <Select
                                                                                                mode="multiple"
                                                                                                style={{width: '100%'}}
                                                                                                placeholder={`Velg ${formatMessage({
                                                                                                    id: 'component.form.office',
                                                                                                }).toLowerCase()}`}
                                                                                                size="large"
                                                                                                suffixIcon={
                                                                                                    <CaretDownFilled
                                                                                                        className="caret-style"/>
                                                                                                }
                                                                                                className="pickerwidth"
                                                                                            >
                                                                                                {data.offices_list.map(i => (
                                                                                                    <Select.Option
                                                                                                        value={i.id}>
                                                                                                        <span>{i.name}</span>
                                                                                                    </Select.Option>
                                                                                                ))}
                                                                                            </Select>
                                                                                        </Form.Item>
                                                                                    );
                                                                                }
                                                                                return (
                                                                                    <div
                                                                                        className="loader-wrapper-inline">
                                                                                        <LoadingOutlined/>
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Query>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mt-3">
                                                                    <Col xs={24}>
                                                                        <Button
                                                                            type="primary"
                                                                            htmlType="submit"
                                                                            className="btn-secondary buttons admin-forms-btn"
                                                                            size="large"
                                                                            loading={loading}
                                                                        >
                                                                            {formatMessage({
                                                                                id: 'component.form.update',
                                                                            })}
                                                                        </Button>
                                                                        <Button
                                                                            className="text-primary admin-forms-btn"
                                                                            style={{marginLeft: 8}}
                                                                            size="large"
                                                                            onClick={router.goBack}
                                                                        >
                                                                            {formatMessage({
                                                                                id: 'component.form.cancel',
                                                                            })}
                                                                        </Button>
                                                                        <Button
                                                                            className=" admin-forms-btn notice delete-btn"
                                                                            style={{float: 'right'}}
                                                                            size="large"
                                                                            onClick={() => setdeleteModal(true)}
                                                                        >
                                                                            {formatMessage({
                                                                                id: 'component.form.delete',
                                                                            })}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Modal
                                                                    centered
                                                                    title={formatMessage({
                                                                        id: 'component.adminstyling.deletemodal.title',
                                                                    })}
                                                                    visible={deleteModal}
                                                                    onOk={() => setdeleteModal(false)}
                                                                    onCancel={() => setdeleteModal(false)}
                                                                    width="450px"
                                                                    footer={[
                                                                        <Mutation mutation={ADMIN_DELETE_USER}>
                                                                            {(deletUser, {loading}) => {
                                                                                return (
                                                                                    <Button
                                                                                        key="submit"
                                                                                        type="primary"
                                                                                        className="admin-forms-btn  mr_15"
                                                                                        size="large"
                                                                                        onClick={() => onDelete(formData.id, deletUser)}
                                                                                        loading={loading}
                                                                                    >
                                                                                        {formatMessage({
                                                                                            id: 'component.form.yes',
                                                                                        })}
                                                                                    </Button>
                                                                                );
                                                                            }}
                                                                        </Mutation>,
                                                                        <Button
                                                                            className="admin-forms-btn  mr_15"
                                                                            size="large"
                                                                            onClick={() => setdeleteModal(false)}
                                                                        >
                                                                            {formatMessage({
                                                                                id: 'component.form.no',
                                                                            })}
                                                                        </Button>,
                                                                    ]}
                                                                >
                                                                    <p className="text-primary no-margin">
                                                                        {formatMessage({
                                                                            id: 'component.adminstyling.deletemodal.text',
                                                                        })}
                                                                    </p>
                                                                </Modal>
                                                            </Form>
                                                        );
                                                    }}
                                                </Mutation>
                                            </Col>
                                        </Row>
                                    </>
                                );
                            }
                            return <div>{formatMessage({id: 'component.nodata'})}</div>;
                        }
                        if (loading && !error) {
                            return (
                                <div className="loader-wrapper">
                                    <Spin/>
                                </div>
                            );
                        }
                        if (!loading && error) {
                            return <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>;
                        }
                        return <React.Fragment/>;
                    }}
                </Query>
            </div>
        </div>
    );
};

export default EditStyling;
