export default {
  'component.calenda.event.Modal.title': 'Legg til aktivitet',
  'component.calenda.event.Modal.ok.button': 'Lagre',
  'component.calenda.event.Modal.cancel.button': 'Avbryt',
  'component.calenda.event.Modal.save.button': 'Lagre',
  'component.calenda.event.DatePicker.label': 'Dato',
  // For calendar
  'component.addevent.title': 'Add event',
  'component.editevent.title': 'Edit event',
  'component.event.title': 'Event title',
  'component.event.title.placeholder': 'Enter Event Title',
  'component.calendar.property': 'Property',
  'component.calendar.usertype': 'User type',
  'component.calendar.property.placeholder': 'Select Property',
  'component.calendar.event.detail': 'Event Details',
};
