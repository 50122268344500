/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {encryptId} from "../../../../utils";
import {EditOutlined, MoreOutlined} from '@ant-design/icons';
import {Button, Col, Dropdown, Input, Menu, Modal, notification, Row, Spin, Table, Typography,} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {Mutation, Query} from 'react-apollo';
import {Link, useHistory} from 'react-router-dom';
import {formatMessage} from "components/src/utils";
import {ADMIN_DELETE_USER} from '../../../../../mutation/AdminMutation';
import {ADMIN_VENDORS_LIST} from '../../../../../query/AdminQuery';
import '../../../../sass/defaultstyles.scss';
import {USER_TYPE_SERVER} from '../../../../utils/constant';

const {Title} = Typography;
const {Search} = Input;
let dataRefetch;

export const TakstUser = () => {
    const [zipcodeModal, setzipcodeModal] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [vendorsTakstList, setVendorsTakstList] = useState([]);
    const [zipCodeData, setZipCodeData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [prevPagination, setprevPagination] = useState({});
    const [dataItem, setDataItem] = useState();
    const [deleteModal, setdeleteModal] = useState(false);
    const [paginationData, setPaginationData] = useState({
        current: 1,
        pageSize: 30,
        total: 1,
    });


    const onDelete = (deleteID, deletUser) => {
        setdeleteModal(false);
        console.log('delete id ==>', deleteID);
        deletUser({
            variables: {
                id: deleteID,
                user_type_id: USER_TYPE_SERVER.TAKST,
            },
        }).then(({data}) => {
            console.log('Data submitted===>>>', data);
            if (data && data.delete_vendor && data.delete_vendor.meta) {
                notification.success({
                    message: data.delete_vendor.meta.message,
                    description: '',
                    duration: 3,
                });
                if (dataRefetch) {
                    dataRefetch();
                }
            }
        });
    };

    const menu = (item) => (
        <Menu>
            <Menu.Item
                onClick={() => {
                    setdeleteModal(true);
                    setDataItem(item);
                }}
            >
                {`${formatMessage({id: 'component.form.delete'})}`}
            </Menu.Item>
        </Menu>
    );

    const timerRef = useRef(null);

    const modalFunction = zipData => {
        setZipCodeData(zipData);
        setzipcodeModal(true);
    };

    const usePreviousValue = () => {
        const ref = useRef();
        useEffect(() => {
            ref.current = searchKeyword;
        });
        return ref.current;
    };
    const prevValue = usePreviousValue();

    const searchFunc = e => {
        if (prevValue === '') {
            setprevPagination(paginationData);
        }
        if (e !== '') {
            setPaginationData({
                current: 1,
                pageSize: 30,
                total: 1,
            });
        } else {
            setPaginationData(prevPagination);
        }
    };

    const handleTableChange = pagination => {
        if (pagination) {
            setPaginationData(pagination);
            setprevPagination(paginationData);
        }
    };

    useEffect(() => {
        if (searchKeyword === '') {
            setprevPagination(paginationData);
        }
    }, []);

    const columns = [
        {
            title: `${formatMessage({id: 'component.table.name'})}`,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            render: text => <a>{text}</a>,
        },
        {
            title: `${formatMessage({id: 'component.table.email'})}`,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => {
                const aSplit = a.email.split('@');
                const bSplit = b.email.split('@');
                return aSplit[0].length - bSplit[0].length;
            },
            render: text => <a>{text}</a>,
        },
        {
            title: `${formatMessage({id: 'component.table.phone'})}`,
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone.length - b.phone.length,
            render: text => <a>{text}</a>,
        },
        {
            title: `${formatMessage({id: 'component.allagents.officename'})}`,
            dataIndex: 'officename',
            key: 'officename',
            sorter: (a, b) => a.officename.length - b.officename.length,
            render: text => <a>{text}</a>,
        },
        {
            title: 'Selskapsnavn',
            dataIndex: 'company_name',
            key: 'company_name',
        },
        // {
        //   title: `${formatMessage({ id: 'component.table.assignedzipcode' })}`,
        //   dataIndex: 'zipcodes',
        //   key: 'zipcodes',
        //   sorter: (a, b) => a.zipcodes.length - b.zipcodes.length,
        //   render: (zipcodes, name) => {
        //     const zipCodeToSend = orderData.filter(item => name.key === item.id);
        //     const zipList = zipCodeToSend.map(i => i.userZipCodes);
        //     const zipArray = [];
        //     return (
        //       <div
        //         style={{ color: '#0589CC', cursor: 'pointer' }}
        //         onClick={() => modalFunction(zipArray)}
        //       >
        //         {zipcodes}
        //       </div>
        //     );
        //   },
        // },
        {
            title: `${formatMessage({id: 'component.table.action'})}`,
            key: 'action',
            render: name => {
                const itemToSend = orderData.filter(item => name.key === item.id);
                console.log('Item to send==>', itemToSend[0]);
                return (
                    <span>
            <Link
                to={{
                    pathname: `/admin/takst/redigeretakst/${encryptId(itemToSend.map(i => i.id))}`,
                }}
            >
              <EditOutlined style={{marginRight: 8}}/>
            </Link>
            <Mutation mutation={ADMIN_DELETE_USER}>
              {deletUser => (
                  <Dropdown
                      overlay={menu(itemToSend, deletUser)}
                      placement="bottomCenter"
                      trigger={['click']}
                  >
                      <MoreOutlined/>
                  </Dropdown>
              )}
            </Mutation>
          </span>
                );
            },
        },
    ];

    return (
        <div className="contentshadow">
            <div className="text-primary main">
                <Row className="photo-order-wrapper">
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Title level={3} className="text-primary">
                            {formatMessage({
                                id: 'component.takst.title',
                            })}
                        </Title>
                        <p>
                            Her finner du en oversikt over registrerte takstmenn til foretaker. Du kan også legge
                            til nye.
                        </p>
                        <Link to="/admin/takst/leggetiltakst">
                            <Button
                                type="primary"
                                className="btn-secondary admin-forms-btn with-lg-spaces"
                                size="large"
                            >
                                {formatMessage({id: 'component.takst.addtakst'})}
                            </Button>
                        </Link>

                        <Row>
                            <Col xs={24}>
                                <Query
                                    query={ADMIN_VENDORS_LIST}
                                    variables={{
                                        input: {
                                            status: true,
                                            user_type_id: USER_TYPE_SERVER.TAKST,
                                            page: 1,
                                            first: 10,
                                            key: searchKeyword,
                                            sorting: {
                                                field: 'id',
                                                order: 'DESC',
                                            },
                                        },
                                    }}
                                    fetchPolicy="no-cache"
                                    onCompleted={responseData => {
                                        setOrderData(responseData.venders.data);
                                        if (responseData && responseData.venders && responseData.venders.data) {
                                            setVendorsTakstList(responseData.venders.data);
                                        }
                                        if (
                                            responseData &&
                                            responseData.venders &&
                                            responseData.venders.paginatorInfo
                                        ) {
                                            setPaginationData({
                                                current: responseData.venders.paginatorInfo.currentPage,
                                                pageSize: responseData.venders.paginatorInfo.perPage,
                                                total: responseData.venders.paginatorInfo.total,
                                            });
                                        }
                                    }}
                                >
                                    {({refetch, data, loading, error}) => {
                                        console.log(error);
                                        dataRefetch = refetch;
                                        const TakstUserArray = [];
                                        if (data && data.venders.data) {
                                            console.log('insodwe;', data.venders.data);
                                            // eslint-disable-next-line array-callback-return
                                            data.venders.data.map(item => {
                                                const TakstUserList = {
                                                    key: item.id,
                                                    name: `${item.first_name} ${item.last_name}`,
                                                    officename: item?.office_name,
                                                    email: item.email,
                                                    phone: item.mobile,
                                                    address: item.address,
                                                    company_name:item.company_name
                                                    // zipcodes: `${item?.zipcode}`,
                                                };
                                                TakstUserArray.push(TakstUserList);
                                            });
                                        }
                                        if (!error) {
                                            return (
                                                <>
                                                    <Row>
                                                        <Col lg={12} xs={24}>
                                                            <div className="table-searchbar">
                                                                <Search
                                                                    placeholder={formatMessage({
                                                                        id: 'component.takst.tablesearch',
                                                                    })}
                                                                    onSearch={value => setSearchKeyword(value)}
                                                                    onChange={e => {
                                                                        const searchText = e.target.value;
                                                                        searchFunc(searchText);
                                                                        if (timerRef.current) {
                                                                            clearTimeout(timerRef.current);
                                                                        }
                                                                        timerRef.current = setTimeout(() => {
                                                                            console.log('On Change===>>>', searchText);
                                                                            setSearchKeyword(searchText);
                                                                        }, 1000);
                                                                    }}
                                                                    style={{
                                                                        padding: '8px 15px',
                                                                        marginBottom: '20px',
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{width: '100%', height: '100%', overflowX: 'auto'}}>
                                                        <div className="unbordered-table-wrapper admin-tables">
                                                            <Table
                                                                columns={columns}
                                                                dataSource={TakstUserArray}
                                                                onChange={handleTableChange}
                                                                pagination={{
                                                                    current: paginationData.current,
                                                                    pageSize: paginationData.pageSize,
                                                                    total: paginationData.total,
                                                                    showSizeChanger: true,
                                                                    showTotal: () =>
                                                                        `Total ${paginationData.total} ${formatMessage({
                                                                            id: 'component.table.items',
                                                                        })}`,
                                                                    pageSizeOptions: ['30', '35', '40', '45', '50'],
                                                                }}
                                                                loading={loading}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }
                                        if (!loading && error) {
                                            return (
                                                <div className="loader-wrapper">Noe gikk galt, vennligst prøv
                                                    igjen.</div>
                                            );
                                        }
                                        return <></>;
                                    }}
                                </Query>
                                <Modal
                                    centered
                                    title={`${formatMessage({id: 'component.table.assignedzipcode'})}`}
                                    visible={zipcodeModal}
                                    onOk={() => setzipcodeModal(false)}
                                    onCancel={() => setzipcodeModal(false)}
                                    width="450px"
                                    footer={[
                                        <Button
                                            key="submit"
                                            type="primary"
                                            className="admin-forms-btn  mr_15"
                                            size="large"
                                            onClick={() => setzipcodeModal(false)}
                                        >
                                            {formatMessage({
                                                id: 'component.form.okay',
                                            })}
                                        </Button>,
                                    ]}
                                >
                                    <p>{zipCodeData.toString()}</p>
                                </Modal>
                                <Modal
                                    centered
                                    title={formatMessage({
                                        id: 'component.takst.deletemodal.title',
                                    })}
                                    visible={deleteModal}
                                    onOk={() => setdeleteModal(false)}
                                    onCancel={() => setdeleteModal(false)}
                                    width="450px"
                                    footer={[
                                        <Mutation mutation={ADMIN_DELETE_USER}>
                                            {(deletUser, {loading}) => {
                                                return (
                                                    <Button
                                                        key="submit"
                                                        type="primary"
                                                        className="admin-forms-btn  mr_15"
                                                        size="large"
                                                        onClick={() => onDelete(dataItem[0].id, deletUser)}
                                                        loading={loading}
                                                    >
                                                        {formatMessage({
                                                            id: 'component.form.yes',
                                                        })}
                                                    </Button>
                                                );
                                            }}
                                        </Mutation>,
                                        <Button
                                            className="admin-forms-btn  mr_15"
                                            size="large"
                                            onClick={() => setdeleteModal(false)}
                                        >
                                            {formatMessage({
                                                id: 'component.form.no',
                                            })}
                                        </Button>,
                                    ]}
                                >
                                    <p className="text-primary no-margin">
                                        {formatMessage({
                                            id: 'component.takst.deletemodal.text',
                                        })}
                                    </p>
                                </Modal>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TakstUser;
