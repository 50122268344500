export default [
  {
    key: '1',
    name: 'Team 1',
    mainagent: 'Danial Nielson',
    subagent1: 'Anderson Scott',
    subagent2: 'Lisa Olsen',
    officename: 'Webmegling Kokstad',
    email: 'emilieP12@webmegling.no',
    phone: '955-536-65',
    title: 'Chain Manager',
  },
  {
    key: '2',
    name: 'Team 2',
    mainagent: 'Roxie Astorga',
    subagent1: 'Lani Lyke',
    subagent2: 'Havard Olsen',
    officename: 'Webmegling Technology Oslo',
    email: 'danialn@webmegling.no',
    phone: '955-536-65',
    title: 'Sales Manager',
  },
  {
    key: '3',
    name: 'Team 3',
    mainagent: 'Roxie Astorga',
    subagent1: 'Lani Lyke',
    subagent2: 'Cooking Area 35',
    officename: 'Webmegling Technology Oslo',
    email: 'denm123@webmegling.no',
    phone: '955-536-65',
    title: 'Partner',
  },
  {
    key: '4',
    name: 'Team 4',
    mainagent: 'Roxie Astorga',
    subagent1: 'Lani Lyke',
    subagent2: 'Havard Olsen',
    officename: 'Webmegling Technology Oslo',
    email: 'denm123@webmegling.no',
    phone: '955-536-65',
    title: 'Real Estate MNEF',
  },
];
