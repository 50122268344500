import { StyleSheet } from 'react-native';
import { Color, ThemeUtils } from '../../../../../../utils';

const containerHorizontalMargin = ThemeUtils.relativeWidth(6)


const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    labelCont: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 5
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        marginVertical:10,
        alignContent: 'flex-start'
    },
    flatlisttopContainer: {
        marginHorizontal: containerHorizontalMargin,
    },

    flattopContainer: {
        marginHorizontal: containerHorizontalMargin,

    },


    fieldContainer: {
        alignContent: 'center',
    },
    viewicon: {
        marginTop:10,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    mainviewdata: { flexDirection: 'column' },
    subviewdata: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
        alignItems:'center'
    },
    deviderline: {
        // height: 0,
        flexDirection: 'row',
        borderColor: '#3A173C',
        width: "100%",

        borderWidth: 0.1,
    },
    MainSeocodflatView: { flexDirection: 'row' },
    labelView: { flexDirection: 'column', flex: 1 },
    iconsView: { flexDirection: "column", justifyContent: 'flex-start', marginTop: 10 },
    commentview: {
        marginTop: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    lblCont: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    btnCont: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },


});
export { styles };
