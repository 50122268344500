import React, {useEffect, useState} from 'react'
import {ActivityIndicator, Clipboard, View} from 'react-native';
import {Hr, InputField, Label, Modal, Ripple} from "components/src/components";
import styles from './styles.js'
import PropTypes from 'prop-types';
import {Color, CommonStyle, Icon, Strings, ThemeUtils, validation} from "components/src/utils";

export default function InputDialog(props) {
    const {loading, value, visible, onNegativePress, onPositivePress, title, desc,} = props;
    const [link, setLink] = useState('');
    const [errLink, setErrLink] = useState(null);

    useEffect(() => {
        setLink(value)
    }, [value]);

    useEffect(() => {
        setErrLink(null)
    }, [link])

    const validateLink = () => {
        let error = validation('link', link)
        if (error) {
            setErrLink(error)
        } else {
            setErrLink(null)
            onPositivePress && onPositivePress(link)
        }
    };

    return (
        <Modal transparent
               visible={visible}>
            <View style={styles.container}>
                <View style={styles.dialogCont}>
                    <Label ml={10}
                           mt={10}
                           mr={10}
                           font_medium
                           align={'center'}
                           style={styles.lblTitle}>
                        {title}
                    </Label>
                    <View style={styles.MainView}>
                        <View style={CommonStyle.container}>
                            <InputField
                                align={'center'}
                                ml={10}
                                mr={10}
                                type={'text'}
                                error={errLink}
                                onFocus={props.onFocus}
                                placeholder={props.placeholder}
                                value={link}
                                onChange={setLink}

                            />
                        </View>

                        <Ripple style={styles.IconsView}
                                rippleContainerBorderRadius={4}
                                onPress={() => Clipboard.setString(link)}>
                            <Icon name={'copy'}
                                  size={ThemeUtils.fontXLarge}
                                  color={Color.PRIMARY}/>
                        </Ripple>
                    </View>
                    <Hr/>
                    <View style={styles.btnCont}>
                        <Ripple onPress={onNegativePress}
                                style={styles.btn}>
                            <Label mt={15}
                                   mb={15}
                                   align={'center'}>
                                {Strings.cancel}
                            </Label>
                        </Ripple>
                        <View style={styles.divider}/>
                        <Ripple onPress={() => validateLink()}
                                style={styles.btn}>
                            <Label color={Color.TEXT_COLOR_BLUE}
                                   align={'center'}>
                                {loading ? (
                                    <ActivityIndicator color={Color.PRIMARY}/>
                                ) : Strings.attach
                                }

                            </Label>
                        </Ripple>
                    </View>
                </View>
            </View>
        </Modal>
    )
}
InputDialog.propTypes = {
    visible: PropTypes.bool,
    onPositivePress: PropTypes.func,
    onNegativePress: PropTypes.func,
    title: PropTypes.string,
    desc: PropTypes.string,
    type: PropTypes.string,

    onFocus: PropTypes.func,
    editable: PropTypes.bool,


}
