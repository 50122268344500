import React from 'react'
//Component
import {ActivityIndicator, Animated, View} from 'react-native'
import {Accordion, Label} from "components/src/components";
//Third party
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Query} from "@apollo/react-components";
import {SELLER_FAQ} from "components/src/api/sellerQuery";
//Utils
import {styles} from './styles.js';
import {Color, CommonStyle, Icon, Strings, ThemeUtils} from "components/src/utils";

// import { PickerView } from '@ant-design/react-native';
// import {Button, InputItem} from "@ant-design/react-native";

// import Accordion  from '@ant-design/react-native';
// import List  from '@ant-design/react-native';
// import { DatePickerView } from 'antd-mobile';


const faqQuetionsAns = [
    {
        index: 1,
        headerText: 'What is an API?',
        contentText: 'An API (Application Programming Interface) is a software intermediary that enables two applications to communicate with each other. It comprises a number of subroutine definitions, logs, and tools for creating application software.In an API testing interview, you could be asked to give some API examples, here are the well-known ones: Google Maps API, Amazon Advertising API, Twitter API, YouTube API, etc.'
    },
    {
        index: 2,
        headerText: 'What are main differences between API and Web Service?',
        contentText: `All Web services are APIs but not all APIs are Web services.Web services might not contain all the specifications and cannot perform all the tasks that APIs would perform.A Web service uses only three styles of use: SOAP, REST and XML-RPC for communication whereas API may be exposed to in multiple ways.A Web service always needs a network to operate while APIs don’t need a network for operation.`
    },
    {
        index: 3,
        headerText: 'What are the Limits of API Usage?',
        contentText: `Many APIs have a certain limit set up by the provider. Thus, try to estimate your usage and understand how that will impact the overall cost of the offering. Whether this will be a problem depends in large part on how data is leveraged. Getting caught by a quota and effectively cut-off because of budget limitations will render the service (and any system or process depending on it) virtually useless.`
    },
    {
        index: 4,
        headerText: 'What are some architectural styles for creating a Web API?',
        contentText: `This is one of the fundamental Web API interview questions. Bellows are four common Web API architectural styles:1)HTTP for client-server communication,2)XML/JSON as formatting language,3)Simple URI as the address for the services,4)Stateless communication`
    },
    {
        index: 5,
        headerText: 'Who can use a Web API?',
        contentText: `Web API can be consumed by any clients which support HTTP verbs such as GET, PUT, DELETE, POST. Since Web API services do not require configuration, they can be easily used by any client. In fact, even portable devices such as mobile devices can easily use Web API, which is undoubtedly the biggest advantage of this technology.`
    },
    {
        index: 6,
        headerText: ' What is API Testing?',
        contentText: `API testing is a kind of software testing which determines if the developed APIs meet expectations regarding the functionality, reliability, performance, and security of the application.`
    },
    {
        index: 7,
        headerText: 'What are the advantages of API Testing?',
        contentText: "In an API interview, they are likely to ask about the advantages of API testing. So be prepared with the significant ones such as:Test for Core Functionality: API testing provides access to the application without a user interface. The core and code-level of functionalities of the application will be tested and evaluated early before the GUI tests. This will help detect the minor issues which can become bigger during the GUI testing.Time Effective: API testing usually is less time consuming than functional GUI testing. The web elements in GUI testing must be polled, which makes the testing process slower. Particularly, API test automation requires less code so it can provide better and faster test coverage compared to GUI test automation. These will result in the cost saving for the testing project.Language-Independent: In API testing, data is exchanged using XML or JSON. These transfer modes are completely language-independent, allowing users to select any code language when adopting automation testing services for the project.Easy Integration with GUI: API tests enable highly integrable tests, which is particularly useful if you want to perform functional GUI tests after API testing. For instance, simple integration would allow new user accounts to be created within the application before a GUI test started"
    },
    {
        index: 8,
        headerText: 'Some common protocols used in API testing?',
        contentText: `Many protocols are now available to be used in API testing, such as JMS, REST, HTTP, UDDI and SOAP.`
    },

]

class Faq extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: undefined,

        };

        this.scrollY = new Animated.Value(0)
    }

    render() {
        return (
            <View style={CommonStyle.container}>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={{paddingBottom: ThemeUtils.APPBAR_HEIGHT}}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.titleCont}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'faq'}
                                    color={Color.PRIMARY_DARK}/>

                                <Label xlarge
                                       ml={9}
                                       font_medium
                                       align={'left'}>
                                    {Strings.FAQ}
                                </Label>
                            </View>

                            <Label
                                mt={18}
                                mb={30}
                                font_regular
                                small
                                align={'left'}>
                                {Strings.bookInspectionInstruction1}
                            </Label>
                            <View>
                                <Query query={SELLER_FAQ}
                                       variables={{
                                           status: 1
                                       }}>
                                    {({data, loading, error}) => {
                                        if (loading && !data) {
                                            return <ActivityIndicator color={Color.PRIMARY}/>
                                        }
                                        return (
                                            <>
                                                {data?.faq?.map(item => {
                                                    return (
                                                        <Accordion headerText={item.question}
                                                                   contentText={item.answer}
                                                        />
                                                    )
                                                })}
                                            </>
                                        )
                                    }}

                                </Query>
                            </View>


                        </View>

                    </View>
                </Animated.ScrollView>
            </View>

        )
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Faq);
