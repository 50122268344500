import React, {useState} from 'react'
//Component
import {Dashboard, Dialog} from "components/src/components";
//Utils
import {IS_WEB, Routes, Strings} from 'components/src/utils'
import see_finn_ad from 'components/src/image/SeeFinnad.jpg'
import ds_assignment from 'components/src/image/Digital_Sales_Assignment.jpg'
import marketing_material from 'components/src/image/MarketingMaterials.jpg'
import display from 'components/src/image/Display.jpg'
import {store} from "components/src/redux/store";
import bids from 'components/src/image/Rate.jpg'
import {useQuery} from "@apollo/react-hooks";
import {SELLER_BID_QUERY} from "../../../../api/sellerQuery";
import {connect} from "react-redux";

const DASHBOARD = [{
    name: Strings.seeFinnAdd,
    icon: 'see-finn-ad',
    bg: see_finn_ad,
    route: Routes.SeeFinnAd,
    order: 1,
    text: "Når megler har gjort klar finn.no annonsen kan du se den her. Her kan du også gi kommentarer og godkjenne annonsen når den er ferdig."
}, {
    name: Strings.dsAssignment,
    icon: 'digital-sales-assignment',
    bg: ds_assignment,
    route: Routes.DigitalSalesAssignment,
    order: 2,
    text: "Her finner du den digitale salgsoppgaven når den er klar. På lik linje som med finn-annonsen kan du legge inn kommentarer og godkjenne denne her."

}, {
    name: Strings.marketingMaterials,
    icon: 'marketing-material-print',
    bg: marketing_material,
    route: Routes.MarketingMaterials,
    order: 3,
    text: "Her vil du finne eventuelt annet markedsmateriell som DM, avisannonser, m.m."

}, {
    name: Strings.display,
    icon: 'display-package',
    bg: display,
    route: Routes.Display,
    order: 4,
    text: "Megler vil registrere visning etter avtale med deg, og her vil du til enhver tid ha oversikt over avholdte og planlagte visninger."

}, {
    name: Strings.bids,
    icon: 'bid-round-handled-by-broker',
    bg: bids,
    route: Routes.Bids,
    order: 5,
    text: "Her kan du følge budrunden med både live bud og tidligere bud."

}];

function Home(props) {
    const {navigation,property, history} = props;

    const [noBidVisible, setNoBidVisible] = useState(false)

    const {loading, data, error} = useQuery(SELLER_BID_QUERY, {
        variables: {
            propertyId: property?.id
        }
    });

    const handleItemPress = (name, route) => {
        if (route) {
            if (route === Routes.Bids && !data?.property_bids?.bids?.length) {
                setNoBidVisible(true)
            } else {
                if (IS_WEB) {
                    history.push(route)
                } else {
                    navigation.navigate(route)
                }
            }
        }
    };

    return (
        <>
            <Dialog visible={noBidVisible}
                    onPositivePress={() => {
                        setNoBidVisible(false)
                    }}
                    buttonTitleSecond={Strings.okay}
                    desc={Strings.noBids}/>
            <Dashboard dashboardData={DASHBOARD}
                       place={'MARKETING'}
                       onPressItem={handleItemPress}/>
        </>
    )
}


const mapStateToProps = (state) => {

    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(Home);
