import React, {useEffect, useState} from 'react'

import {ActivityIndicator, TouchableOpacity, View} from 'react-native'
import {Agenda, CustomButton, Header, Hr, Label, LocaleConfig, Modal} from "components/src/components";

import {connect} from "react-redux";

import {Color, CommonStyle, DateUtils, IS_WEB, Strings, ThemeUtils, UserType} from "components/src/utils";

import styles from './styles'
import {useQuery} from "@apollo/react-hooks";

import moment from "moment";
import {SELLER_CALENDAR_EVENT} from "../../api/sellerQuery";
import Routes from "mobile/src/router/routes";
import {STYLIST_CALENDAR_EVENT} from "../../api/stylistQuery";

import {TAKST_CALENDAR_EVENT} from "../../api/takstQuery";
import {PHOTO_CALENDAR_EVENT} from "../../api/photographerQuery";
import {BUYER_CALENDAR_EVENT} from "../../api/buyerQuery";


LocaleConfig.locales['no'] = {
    monthNames: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
    monthNamesShort: [
        'Jan',
        'Febr',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'Aug',
        'Sept',
        'Okt',
        'Nov',
        'Des'],
    dayNames: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    dayNamesShort: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
};
LocaleConfig.defaultLocale = 'no';

function CalendarScreen(props) {
    const {history, user, property, navigation, database} = props;

    const [eventToDisplay, setEvent] = useState();
    const [visible, setDetailsVisible] = useState(false);

    const getUserApi = () => {

        switch (props.user.user_type_id) {
            case UserType.Seller:
                return SELLER_CALENDAR_EVENT;
            case UserType.Buyer:
                return BUYER_CALENDAR_EVENT;
            case UserType.Photographer:
                return PHOTO_CALENDAR_EVENT;
            case UserType.Takst:
                return TAKST_CALENDAR_EVENT;
            case UserType.Stylist:
                return STYLIST_CALENDAR_EVENT;
            default:
                return SELLER_CALENDAR_EVENT
        }

    };

    const getApiField = () => {
        switch (props.user.user_type_id) {
            case UserType.Seller:
                return "seller_calendar_event";
            case UserType.Buyer:
                return "buyer_calendar_event";
            case UserType.Photographer:
                return "photo_calendar_event";
            case UserType.Takst:
                return "takst_calendar_event";
            case UserType.Examination:
                return "inspection_calendar_event";
            case UserType.Stylist:
                return "stylish_calendar_event";
            default:
                return "seller_calendar_event";
        }
    };

    const {loading, data, error} = useQuery(getUserApi());

    const getUserType = (userType) => {
        switch (userType) {
            case 'Seller':
                return Strings.seller
            case 'Buyer':
                return Strings.buyer
            case "All":
                return `${Strings.seller}, ${Strings.buyer}`
        }
    }
    useEffect(() => {
        if (eventToDisplay) {
            setDetailsVisible(true)
        }
    }, [eventToDisplay])

    useEffect(() => {
        if (!visible) {
            setEvent(null)
        }
    }, [visible])

    const renderEvent = (item) => {
        return (
            <View style={styles.eventCont}>
                {item?.data?.map(event => (
                    <TouchableOpacity activeOpacity={0.5}
                                      onPress={() => setEvent(event)}
                                      style={styles.lblCont}>
                        <Label small
                               color={Color.WHITE}>
                            {event?.title}
                        </Label>
                    </TouchableOpacity>
                ))}
                <Hr/>
            </View>
        )
    };

    const renderEmptyData = () => {
        return (

            <Label small
                   align={'center'}
                   mt={20}
                   color={Color.PRIMARY}>
                {`Ingen data`}
            </Label>
        )
    };


    const renderCalenderEventModal = () => {
        return (
            <Modal visible={visible}>
                <Header onPressLeft={() => setDetailsVisible(false)}
                        onRequestClose={() => setDetailsVisible(false)}
                        style={CommonStyle.blankHeader}/>
                <View style={styles.modalCont}>
                    <Label font_medium
                           mt={15}
                           mb={10}>
                        {Strings.eventDetails}
                    </Label>
                    <Label small
                           mt={15}
                           font_medium>
                        {Strings.eventTitle}
                    </Label>
                    <Label small
                           mt={5}>
                        {eventToDisplay?.title}
                    </Label>

                    <Label mt={15}
                           small
                           font_medium>
                        {Strings.property}
                    </Label>
                    <Label small
                           mt={5}>
                        {`${eventToDisplay?.property?.street_adress} ${eventToDisplay?.property?.city} ${eventToDisplay?.property?.zipcode}`}
                    </Label>

                    <Label mt={15}
                           small
                           font_medium>
                        {Strings.userType}
                    </Label>
                    <Label small
                           mt={5}>
                        {getUserType(eventToDisplay?.user_type)}
                    </Label>

                    <Label mt={15}
                           small
                           font_medium>
                        {Strings.date}
                    </Label>
                    <Label small
                           mt={5}
                    >
                        {moment(eventToDisplay?.start_datetime, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.dd_mm_yyyy)}
                    </Label>

                    <Label mt={15}
                           small
                           font_medium>
                        {Strings.time}
                    </Label>
                    <Label small
                           mt={5}
                    >
                        {moment(eventToDisplay?.start_datetime, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.hh_mm)}
                    </Label>

                </View>
                <CustomButton title={Strings.okay}
                              mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                              ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                              mb={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                              onPress={() => setDetailsVisible(false)}/>
            </Modal>
        )
    };

    let eventData = {}
    if (data && data[getApiField()]) {
        /*EXAMPLE DATA*/
        /*{
                        '2012-05-22': [{name: ['item 1 - any js object']}],
                        '2012-05-21': [],
                        '2012-05-23': [{name: new Array(5).fill({name: 1})}],
                        '2012-05-25': [{name: new Array(2).fill({name: 1})}]
                    }*/
        data[getApiField()]?.map(item => {
            let date = moment(item?.start_datetime, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.yyyy_mm_dd)
            if (eventData.hasOwnProperty(date)) {
                eventData[date][0]?.data?.push(item)
            } else {
                eventData[date] = [{data: [item]}]
            }
        })
    }
    return (
        <View style={CommonStyle.flex}>
            <Header navigation={IS_WEB ? history : navigation}
                    style={CommonStyle.blankHeader}/>
            {renderCalenderEventModal()}
            {console.log(eventData, "EVENT DATA")}
            {loading && !data ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <Agenda items={eventData}
                        renderEmptyData={renderEmptyData}
                        minDate={moment().add(1, 'd').format(DateUtils.yyyy_mm_dd)}
                        renderItem={renderEvent}
                        selected={Object.keys(eventData)?.sort()[0] ?? moment().format(DateUtils.yyyy_mm_dd)}/>
            )}

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        property: state.property
    }
}
export default connect(mapStateToProps)(CalendarScreen)
