// import { DownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  Modal,
  notification,
  Rate,
  Row,
  Typography,
} from "antd";
// import { LoadingOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { useHistory } from "react-router";
import { formatMessage } from "components/src/utils";
import {
  ADMIN_ADD_AGENT_FEEDBACK,
  ADMIN_DELETE_FEEBACK,
  ADMIN_EDIT_AGENT_FEEDBACK,
} from "../../../../../../../../mutation/AdminMutation";
import "./editagentfeedback.scss";
import { LoadingOutlined } from "@ant-design/icons";

// const props = {
//   showUploadList: {
//     showDownloadIcon: true,
//     downloadIcon: <DownloadOutlined />,
//     showRemoveIcon: true,
//     removeIcon: <i className="icon-delete" />,
//   },
//   defaultFileList: [
//     {
//       uid: '1',
//       name: 'tariff.png',
//       status: 'done',
//       response: 'Server Error 500', // custom error message to show
//       url: 'http://www.baidu.com/xxx.png',
//     },
//   ],
// };

const { Title } = Typography;

const { TextArea } = Input;

export const EditAgentFeedBack = (props) => {
  let router = useHistory();
  const { fullObject } = props;
  const [addFeedbackForm] = Form.useForm();
  console.log("inside edit office details=>", props.fullObject);

  const [editFeedbackModal, setEditFeedbackModal] = useState(false);
  const [currentData, setCurrentData] = useState("");
  const [feedbackList, setFeedbackList] = useState(fullObject?.feedback);

  const ModalDataAddingFunction = (item) => {
    setCurrentData(item);
    setEditFeedbackModal(true);
    console.log("modal data==>", item);
  };

  const onFeedbackFinish = (values, addFeedbackData) => {
    console.log("Success:", values);
    addFeedbackData({
      variables: {
        input: {
          agent_id: fullObject.id,
          rating: values.rating,
          feedback: values.feedback,
          given_by: values.givenby,
        },
      },
    }).then((data) => {
      console.log("Data submitted===>>>", data);
      if (data && data.data.add_agent_feedback) {
        notification.success({
          message: `Tilbakemelding ble lagt til`,
          description: "",
          duration: 3,
        });
        if (addFeedbackForm) {
          addFeedbackForm.resetFields();
        }
        setFeedbackList(data.data.add_agent_feedback);
      }
    });
  };

  const onDelete = (itemId, deleteFeedback) => {
    deleteFeedback({
      variables: {
        id: itemId,
        agent_id: fullObject.id,
      },
    }).then((data) => {
      console.log("Data submitted===>>>", data);
      if (data && data.data.delete_agent_feedback) {
        notification.success({
          message: `Tilbakemelding ble slettet`,
          description: "",
          duration: 3,
        });
        if (editFeedbackModal) {
          setEditFeedbackModal(false);
        }
        setCurrentData({});
        setFeedbackList(data.data.delete_agent_feedback);
      }
    });
  };

  const onFeedbackEditFinish = (values, editFeedback) => {
    console.log("Success:", values);
    editFeedback({
      variables: {
        id: currentData.id,
        agent_id: fullObject.id,
        rating: values.rating,
        feedback: values.feedback,
        given_by: values.givenby,
      },
    }).then((data) => {
      console.log("Data submitted===>>>", data);
      setEditFeedbackModal(false);
      if (data && data.data.edit_agent_feedback) {
        notification.success({
          message: `Tilbakemelding ble lagt til`,
          description: "",
          duration: 3,
        });
        setCurrentData({});
        setEditFeedbackModal(false);
        setFeedbackList(data.data.edit_agent_feedback);
      }
    });
  };

  const onFeedbackFinishFailed = (errorinfo) => console.log(errorinfo);
  const onFeedbackEditFinishFailed = (errorinfo) => console.log(errorinfo);

  return (
    <div className="contentshadow" style={{ marginLeft: 0, marginRight: 0 }}>
      <div className="text-primary main">
        <Mutation mutation={ADMIN_ADD_AGENT_FEEDBACK}>
          {(addFeedbackData, { loading }) => (
            <Form
              layout="vertical"
              className="user-forms"
              form={addFeedbackForm}
              name="uploadpic"
              initialValues={{
                remember: true,
                rating: currentData.rating,
                feedback: currentData.feedback,
                given_by: currentData.given_by,
              }}
              colon={false}
              onFinish={(values) => onFeedbackFinish(values, addFeedbackData)}
              onFinishFailed={onFeedbackFinishFailed}
            >
              <Row>
                <Col>
                  <Form.Item
                    name="rating"
                    className="label-texts"
                    label={formatMessage({
                      id: "component.allagents.ratings",
                    })}
                    rules={[
                      {
                        required: true,
                        message: `Vennligst Give ${formatMessage({
                          id: "component.allagents.ratings",
                        })}!`,
                      },
                    ]}
                  >
                    <Rate allowHalf />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={24} lg={16}>
                  <Form.Item
                    name="feedback"
                    className="label-texts"
                    label={formatMessage({
                      id: "component.allagents.feedback",
                    })}
                    rules={[
                      {
                        required: true,
                        message: `Vennligst  ${formatMessage({
                          id: "component.form.comment",
                        })}!`,
                      },
                    ]}
                  >
                    <TextArea
                      rows={3}
                      placeholder={formatMessage({
                        id: "component.allagents.feedback.placeholder",
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={16}>
                  <Form.Item
                    name="givenby"
                    className="label-texts"
                    label={formatMessage({
                      id: "component.allagents.feedbackgivenby",
                    })}
                    rules={[
                      {
                        required: true,
                        message: `Vennligst angi ${formatMessage({
                          id: "component.allagents.feedbackgivenby.placeholder",
                        }).toLowerCase()}!`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: "component.allagents.feedbackgivenby.placeholder",
                      })}
                      size="large"
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                    className="btn-secondary buttons admin-forms-btn mt-3"
                  >
                    {formatMessage({
                      id: "component.form.submit",
                    })}
                  </Button>
                  <Button
                    size="large"
                    onClick={router.goBack}
                    className="buttons admin-forms-btn back-button mt-3 cancel-btn"
                  >
                    {formatMessage({
                      id: "component.form.cancel",
                    })}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Mutation>
        <Divider />
        <Row>
          <Col lg={24} xs={24}>
            <Title level={4}>
              {formatMessage({
                id: "component.allagents.allfeedback",
              })}
              {`: `}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col lg={24} xs={24}>
            <List
              className="listViewStyle"
              itemLayout="horizontal"
              dataSource={feedbackList}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    onClick={() => ModalDataAddingFunction(item)}
                    title={
                      <a className="text-bold">
                        <Rate
                          allowHalf
                          disabled
                          defaultValue={item.rating}
                          style={{ color: "#A3875B" }}
                        />
                      </a>
                    }
                    description={
                      <>
                        <p className="mb-0">{item.feedback}</p>
                        <p className="d-block label-texts">{item.given_by}</p>
                      </>
                    }
                  />
                  <i
                    className="icon-edit"
                    onClick={() => ModalDataAddingFunction(item)}
                  />
                  <>
                    <Mutation mutation={ADMIN_DELETE_FEEBACK}>
                      {(deleteFeedback, { loading }) =>
                        loading ? (
                          <LoadingOutlined />
                        ) : (
                          <i
                            className="icon-delete"
                            style={{ cursor: "pointer" }}
                            onClick={() => onDelete(item.id, deleteFeedback)}
                          />
                        )
                      }
                    </Mutation>
                  </>
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <>
          <Modal
            centered
            title="Rediger tilbakemelding"
            visible={editFeedbackModal}
            onOk={() => setEditFeedbackModal(false)}
            onCancel={() => setEditFeedbackModal(false)}
            width="800px"
            footer={false}
          >
            <>
              {console.log("Current Data", currentData)}
              <Mutation mutation={ADMIN_EDIT_AGENT_FEEDBACK}>
                {(editFeedback, { loading }) => (
                  <Form
                    layout="vertical"
                    className="user-forms"
                    name="editFeedbackForm"
                    initialValues={{
                      remember: true,
                      rating: currentData.rating,
                      feedback: currentData.feedback,
                      givenby: currentData.given_by,
                    }}
                    colon={false}
                    onFinish={(values) =>
                      onFeedbackEditFinish(values, editFeedback)
                    }
                    onFinishFailed={onFeedbackEditFinishFailed}
                  >
                    <Row>
                      <Col>
                        <Form.Item name="id" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={24}>
                        <Form.Item
                          name="rating"
                          className="label-texts"
                          label={formatMessage({
                            id: "component.allagents.ratings",
                          })}
                          rules={[
                            {
                              required: true,
                              message: `Vennligst angi ${formatMessage({
                                id: "component.allagents.ratings",
                              }).toLowerCase()}!`,
                            },
                          ]}
                        >
                          <Rate allowHalf />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={24} md={24} lg={16}>
                        <Form.Item
                          name="feedback"
                          className="label-texts"
                          label={formatMessage({
                            id: "component.allagents.feedback",
                          })}
                          rules={[
                            {
                              required: true,
                              message: `Vennligst  ${formatMessage({
                                id: "component.form.comment",
                              }).toLowerCase()}!`,
                            },
                          ]}
                        >
                          <TextArea
                            rows={5}
                            placeholder={formatMessage({
                              id: "component.allagents.feedback.placeholder",
                            })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={16} md={24}>
                        <Form.Item
                          name="givenby"
                          className="label-texts"
                          label={formatMessage({
                            id: "component.allagents.feedbackgivenby",
                          })}
                          rules={[
                            {
                              required: true,
                              message: `Vennligst  ${formatMessage({
                                id:
                                  "component.allagents.feedbackgivenby.placeholder",
                              }).toLowerCase()}!`,
                            },
                          ]}
                        >
                          <Input
                            placeholder={formatMessage({
                              id:
                                "component.allagents.feedbackgivenby.placeholder",
                            })}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="primary"
                          size="large"
                          loading={loading}
                          htmlType="submit"
                          className="btn-secondary buttons admin-forms-btn mt-3"
                        >
                          {formatMessage({
                            id: "component.form.submit",
                          })}
                        </Button>
                        <Button
                          size="large"
                          onClick={router.goBack}
                          className="buttons admin-forms-btn back-button mt-3 cancel-btn"
                        >
                          {formatMessage({
                            id: "component.form.cancel",
                          })}
                        </Button>
                        <Mutation mutation={ADMIN_DELETE_FEEBACK}>
                          {(deleteFeedback, { loading }) => (
                            <Button
                              size="large"
                              loadin={loading}
                              onClick={() =>
                                onDelete(currentData.id, deleteFeedback)
                              }
                              className="admin-forms-btn delete-btn"
                              style={{ float: "right" }}
                            >
                              {formatMessage({
                                id: "component.form.delete",
                              })}
                            </Button>
                          )}
                        </Mutation>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Mutation>
            </>
          </Modal>
        </>
      </div>
    </div>
  );
};

export default EditAgentFeedBack;
