import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {
    AddServiceView,
    CostingAddServiceView,
    CustomButton,
    Header,
    Hr,
    IndicatorViewPager,
    Label,
    PagerTabIndicator
} from "components/src/components";
//Network
import {useMutation, useQuery} from "@apollo/react-hooks";
//Utils
import styles from './styles';

import {Color, CommonStyle, formatPrice, Icon, IS_WEB, Strings, ThemeUtils,Constants} from "components/src/utils";
import {
    MARKETING_PACKAGES,
    PROPERTY_PREFERENCE,
    SELLER_BROKER_ON_VIEW,
    SELLER_GET_SERVICES
} from "components/src/api/sellerQuery";
import {SELLER_PURCHASE_SERVICE} from "components/src/api/sellerMutation";
import {connect} from "react-redux";


const TABS = [{
    text: Strings.purchasedService
}, {
    text: Strings.additionalService
}];


function MyOrders(props) {

    const {history, navigation} = props;

    const scrollY = new Animated.Value(0)

    const [selectedServices, setSelectedServices] = useState([])
    const [selectedVendorServices, setSelectedVendorServices] = useState([])
    const [selectedPackages, setSelectedPackages] = useState([])

    const [purchasedServices, setPurchasedServices] = useState([])
    const [additionalServices, setAdditionalServices] = useState([])

    const [purchasedPackages, setPurchasedPackages] = useState([])
    const [additionalPackages, setAdditionalPackages] = useState([])

    const [purchasedVederlegg, setPurchasedVederlag] = useState([])
    const [purchasedUttlegg, setPurchasedUttlegg] = useState([])
    const [remunration, setRemunration] = useState([])

    const [serviceInPackage, setServiceInPackage] = useState([])

    const [isBrokerOnView, setBrokerOnView] = useState(false);

    const {loading, data, error} = useQuery(SELLER_BROKER_ON_VIEW, {
        skip: true,
        fetchPolicy: 'network-only',
        variables: {
            propertyId: props.property?.id
        }
    });

    const packagesQuery = useQuery(MARKETING_PACKAGES, {
        fetchPolicy:"network-only",
        variables: {
            propertyId: props.property?.id,
        }
    })

    const purchasePhotoServicesQuery = useQuery(SELLER_GET_SERVICES, {
        skip: !packagesQuery.data,
        fetchPolicy:"network-only",
        variables: {
            status: 1,
            property_id: props.property?.id,
        }
    })

    let propertyPreferenceQuery = useQuery(PROPERTY_PREFERENCE, {
        fetchPolicy: 'network-only',
        variables: {
            id: props.property?.id
        }
    })

    const [purchaseService, purchaseServiceMutation] = useMutation(SELLER_PURCHASE_SERVICE)

    useEffect(() => {
        if (purchasePhotoServicesQuery.data?.purchased_services) {
            let allPurchasedServices = [];
            let additioanlSServices = [];
            let purchasedUttlegg = purchasePhotoServicesQuery?.data?.purchased_services?.property_utlegg?.filter(item => item.price_type === Constants.PriceType.UTLEGG && item.usableServiceClass ).sort((a, b) => b.is_default - a.is_default);
            let purchasedVederlegg = purchasePhotoServicesQuery?.data?.purchased_services?.property_utlegg?.filter(item => item.price_type === Constants.PriceType.VEDERLEGG && item.usableServiceClass).sort((a, b) => b.is_default - a.is_default);
            let remunration = purchasePhotoServicesQuery?.data?.purchased_services?.remunerations?.filter(item => item.usableServiceClass )
            purchasePhotoServicesQuery.data?.purchased_services?.purchased_services?.map((service, index) => {
                let vendorServices = []
                if (index === 0) {
                    vendorServices = purchasePhotoServicesQuery.data?.purchased_services?.service_types[0];
                }
                if (index === 1) {
                    // vendorServices = purchasePhotoServicesQuery.data?.purchased_services?.service_types[1];
                    vendorServices=[];
                }
                if (index === 3) {
                    vendorServices = purchasePhotoServicesQuery.data?.purchased_services?.service_types[2];
                }
                if (index === 4) {
                    vendorServices = purchasePhotoServicesQuery.data?.purchased_services?.service_types[3];
                }
                if (vendorServices?.vendor_services?.length) {
                    let purchasedServiceCount = vendorServices?.vendor_services?.filter(item => item.service_selected || serviceInPackage.includes(item.id)).length;
                    if (purchasedServiceCount !== vendorServices?.vendor_services?.length) {
                        additioanlSServices.push(vendorServices)
                        if (purchasedServiceCount !== 0) {
                            allPurchasedServices.push(vendorServices)
                        }
                    } else {
                        allPurchasedServices.push(vendorServices)
                    }
                } else {
                    console.log(service.purchased_office_template?.purchased_office_services,"SERVICES")
                    let purchasedServiceCount = service.purchased_office_template?.purchased_office_services?.filter(item => item.service_selected || serviceInPackage.includes(item.id)).length;
                    if (purchasedServiceCount !== service.purchased_office_template?.purchased_office_services?.length) {
                        additioanlSServices.push(service)
                        if (purchasedServiceCount !== 0) {
                            allPurchasedServices.push(service)
                        }
                    } else {
                        allPurchasedServices.push(service)
                    }
                }
            })
            setPurchasedServices(allPurchasedServices)
            setAdditionalServices(additioanlSServices)
            setRemunration(remunration)
            setPurchasedUttlegg(purchasedUttlegg)
            setPurchasedVederlag(purchasedVederlegg)
        }
    }, [purchasePhotoServicesQuery.data])


    useEffect(() => {
        let service = []
        if (packagesQuery.data?.befaring_packages) {
            let selectedPackage = packagesQuery.data?.befaring_packages?.filter(item => item.property_package)
            if (selectedPackage.length) {
                setPurchasedPackages(selectedPackage)
            } else {
                setAdditionalPackages(packagesQuery.data?.befaring_packages?.filter(item => !item.property_package))
            }
            selectedPackage.map(packageItem => {
                service = [...service, ...packageItem.marketing_package_service.map(item => item.office_service.id)]
            })
            setServiceInPackage(service)
        }
    }, [packagesQuery.data])


    const toggleSelectedService = (id, parentId) => {
        // item.isSelected = !item.isSelected;
        let updatedServices = selectedServices.slice();
        if (updatedServices.includes(id)) {
            updatedServices.splice(updatedServices.indexOf(id), 1)
        } else {
            updatedServices.push(id)
        }
        setSelectedServices(updatedServices)
    };

    const toggleSelectedVendorService = (id, parentId) => {
        let updatedServices = selectedVendorServices.slice();
        if (updatedServices.includes(id)) {
            updatedServices.splice(updatedServices.indexOf(id), 1)
        } else {
            updatedServices.push(id)
        }
        setSelectedVendorServices(updatedServices)
    }

    const toggleSelectedPackage = (id, parentId) => {
        // item.isSelected = !item.isSelected;
        let updatedServices = selectedPackages.slice();
        if (updatedServices.includes(id)) {
            // updatedServices.splice(updatedServices.indexOf(id), 1)
            setSelectedPackages([])
        } else {
            // updatedServices.push(id)
            setSelectedPackages([id])

        }
    };

    const _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };

    const renderMyOrderItem = (item) => {
        return (
            <AddServiceView title={item.name}
                            price={parseInt(item.price) ? `Kr ${formatPrice(item.price)},-` : item.free_text}
                            service={item?.marketing_package_service?.map(item => item.office_service)}
                            image={item.image ? item.image : item.image_url}
                            extraNote={item.external_url}
                            name={'disclaimer'}
                            desc={item.description}
                            mb={9}/>)
    };

    const renderAdditionalServiceItem = (item, index, parentIndex, isVendorService) => {
        return (
            <AddServiceView title={item.name}
                            price={parseInt(item.price) ? `Kr ${formatPrice(item.price)},-` : item.free_text}
                            image={item.image ? item.image : item.image_url}
                            service={item?.marketing_package_service?.map(item => item.office_service)}
                            name={'disclaimer'}
                            extraNote={item.external_url}
                            mb={9}
                            desc={item.description}
                            isSelected={isVendorService ? selectedVendorServices.includes(item.id) : (selectedServices.includes(item.id) || selectedPackages.includes(item.id))}
                            onPress={() => item.marketing_package_service ? toggleSelectedPackage(parseInt(item.id), parentIndex) : isVendorService ? toggleSelectedVendorService(parseInt(item.id), parentIndex) : toggleSelectedService(parseInt(item.id), parentIndex)}/>)
    };

    const renderBillList = (item) => {
        let quantity = 1
        if (item.service_selected) {
            quantity = item?.service_selected?.quantity ?? 1
        }
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView isDeletable={false}
                                       title={item.name}
                                       color={Color.PRIMARY_TEXT_COLOR}
                                       iconColor={Color.DARK_GRAY}
                                       price={!item.is_free ? `Kr ${formatPrice(item?.service_selected ? item?.service_selected?.price : item?.property_package ? item?.property_package?.price : item.price)},- ${quantity && quantity !== 1 ? " X " + item?.service_selected?.quantity : ""}` : item.free_text}/>

            </View>
        )
    };

    const renderService = (item, index) => {
        if (item?.vendor_services?.length) {
            let vendorPurchasedServices = item.vendor_services.filter(item => item.service_selected)
            if (!vendorPurchasedServices.length) {
                return;
            }
            return (
                <View key={index.toString()}>
                    <Label font_medium
                           mb={10}>
                        {item.name}
                    </Label>
                    {vendorPurchasedServices?.map(renderMyOrderItem)}
                </View>
            )

        }
        let data = item.purchased_office_template.purchased_office_services.filter(item => item.service_selected && !serviceInPackage.includes(item.id))
        if (!data?.length) {
            return (<View/>)
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       mb={10}>
                    {item.name}
                </Label>
                {data?.map(renderMyOrderItem)}
            </View>
        )
    }

    const renderAdditionalService = (item, parentIndex) => {
        let selectedPackage = packagesQuery.data?.befaring_packages?.find(item => selectedPackages.includes(item.id))
        let service = []
        if (selectedPackage) {
            service = [...service, ...selectedPackage?.marketing_package_service.map(item => item.office_service.id)]
        }
        if (item?.vendor_services?.length) {
            let vendorAdditionalServices = item.vendor_services.filter(item => !item.service_selected)
            if (!vendorAdditionalServices.length) {
                return;
            }
            return (
                <View key={parentIndex.toString()}>
                    <Label font_medium
                           mb={10}>
                        {item.name}
                    </Label>
                    {vendorAdditionalServices.map((item, index) => renderAdditionalServiceItem(item, index, parentIndex, true))}
                </View>
            )

        }
        let data = item?.purchased_office_template.purchased_office_services.filter(item => !item.service_selected && !service.includes(item.id))
        if (!data?.length) {
            return (<View/>)
        }
        return (
            <View key={parentIndex.toString()}>
                <Label font_medium
                       mb={10}>
                    {item.name}
                </Label>
                {data.map((item, index) => renderAdditionalServiceItem(item, index, parentIndex))}
            </View>
        )
    }


    const renderUttleggItem = item => {
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView isDeletable={false}
                                       title={item.name}
                                       color={Color.PRIMARY_TEXT_COLOR}
                                       iconColor={Color.DARK_GRAY}
                                       price={
                                           parseInt(item.price)
                                               ? `Kr ${formatPrice(item.service_selected ? item.service_selected.price : (item.price * (item?.hours ? parseInt(item.hours, 10) : 1)))},-`
                                               : item.free_text
                                       }
                />
            </View>
        );
    }

    let allPurchasedServices = []

    purchasedServices.map(service => {
        if (service?.vendor_services?.length) {
            service.vendor_services?.filter(item => item.service_selected).map(item => {
                allPurchasedServices.push(item)
            })
        } else {
            service.purchased_office_template?.purchased_office_services?.filter(item => item.service_selected && !serviceInPackage.includes(item.id)).map(item => {
                allPurchasedServices.push(item)
            })
        }
    });
    const packageTotal = purchasedPackages.map(item => parseInt(item?.property_package?.price)).reduce((a, b) => a + b, 0);
    const uttleggTotal = purchasedUttlegg.map(item => parseInt(item?.price)).reduce((a, b) => a + b, 0);
    const vedarleggTotal = purchasedVederlegg.map(item => parseInt(item?.price)).reduce((a, b) => a + b, 0);
    const remunerationTotal = remunration.map(item => item.usableServiceClass ? item.usableServiceClass.price * (item?.usableServiceClass?.quantity ?? 1) : item.price * (item?.hours ?? 1)).reduce((a, b) => a + b, 0);

    let total = allPurchasedServices.map(item => {
        if (item?.service_selected?.quantity) {
            return parseInt(item.price) * item?.service_selected.quantity
        } else {
            return parseInt(item.price)
        }

    }).reduce((a, b) => a + b, 0);


    let brokerOnViewPrice = 0;
    if (data?.office_broker_on_view_service?.service_selected) {
        brokerOnViewPrice = data?.office_broker_on_view_service?.service_selected?.price * data?.office_broker_on_view_service?.service_selected?.quantity
    }
    total += packageTotal;
    total += brokerOnViewPrice;
    total += uttleggTotal;
    total += vedarleggTotal;
    total += remunerationTotal;
    if (propertyPreferenceQuery?.data?.property?.property_preference?.rebate_amount) {
        total -= parseInt(propertyPreferenceQuery?.data?.property?.property_preference?.rebate_amount)
    }
    return (
        <View style={styles.cont}>
            <Header tintColor={Color.SECONDARY}
                    style={CommonStyle.blankHeader}
                    navigation={IS_WEB ? history : navigation}/>


            <View style={styles.contentCont}>
                <View style={styles.titleCont}>
                    <Icon name={'my-order'}
                          size={ThemeUtils.fontXXLarge}/>
                    <Label xlarge
                           font_medium
                           mt={5}
                           ml={10}>
                        {Strings.yourBrokerageServices}
                    </Label>
                </View>
                <Label small
                       mb={20}
                       style={styles.lblInst}>
                    {Strings.myOrderInst}
                </Label>
            </View>

            <IndicatorViewPager
                indicator={_renderTabIndicator()}
                style={{flex: 1, backgroundColor: 'white'}}>
                <View>
                    {loading || packagesQuery?.loading || purchasePhotoServicesQuery.loading ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <Animated.ScrollView scrollEventThrottle={1}
                                             contentContainerStyle={styles.flexGrow}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                                 {useNativeDriver: true})}>
                            <View>
                                {/*{data?.office_broker_on_view_service?.service_selected ? (
                                    <View style={styles.orderCont}>
                                        <AddServiceView image={data?.office_broker_on_view_service?.image}
                                                        isSelected={isBrokerOnView}
                                                        title={data?.office_broker_on_view_service?.name}
                                                        desc={data?.office_broker_on_view_service?.description}
                                            // onPress={() => setBrokerOnView(prevVal => !prevVal)}
                                                        name={'disclaimer'}
                                                        price={`Kr ${formatPrice(data?.office_broker_on_view_service?.service_selected?.price)},-`}/>
                                    </View>) : null}
                                {purchasedPackages.length ? (
                                    <Label font_medium
                                           mt={15}
                                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                        {Strings.marketingPackages}
                                    </Label>
                                ) : null}
                                <View style={styles.orderCont}>
                                    {purchasedPackages.map(renderMyOrderItem)}
                                </View>
                                {purchasedServices.length ? (
                                    <Label font_medium
                                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                        {Strings.services}
                                    </Label>
                                ) : null}
                                <View style={styles.orderCont}>
                                    {purchasedServices.map(renderService)}
                                </View>*/}
                                {purchasedServices.length ?
                                    <>
                                        <View style={styles.billCont}>
                                            {/*<View style={styles.billListCont}>
                                    <CostingAddServiceView isDeletable={false}
                                                           title={'Home sales fixed low price'}
                                                           color={Color.TERNARY_TEXT_COLOR}
                                                           iconColor={Color.DARK_GRAY}
                                                           price={'Kr 35.000,-'}/>
                                </View>*/}
                                            {/*{data?.office_broker_on_view_service?.service_selected ? renderBillList(data?.office_broker_on_view_service) : null}*/}

                                            {remunration.map(renderBillList)}
                                            {purchasedPackages.length ? (
                                                <Label font_medium
                                                       mt={15}
                                                       mb={10}
                                                    // color={Color.TERNARY_TEXT_COLOR}
                                                       ml={ThemeUtils.relativeRealWidth(4)}>
                                                    {Strings.marketingPackages}
                                                </Label>
                                            ) : null}
                                            {purchasedPackages.map(renderBillList)}
                                            <Hr lineStyle={styles.lineStyle}/>
                                            {allPurchasedServices.length ? (
                                                <Label font_medium
                                                    // color={Color.TERNARY_TEXT_COLOR}
                                                       mt={10}
                                                       mb={10}
                                                       ml={ThemeUtils.relativeRealWidth(4)}>
                                                    {Strings.services}
                                                </Label>
                                            ) : null}
                                            {allPurchasedServices.map(renderBillList)}
                                            {purchasedUttlegg?.length || purchasedVederlegg?.length ?
                                                (<Label font_medium
                                                        mt={15}
                                                        mb={10}

                                                        ml={ThemeUtils.relativeRealWidth(4)}>
                                                    {"Andre kostnader"}
                                                </Label>) : null}

                                            {purchasedVederlegg?.length ?
                                                (<Label font_medium
                                                        mt={10}
                                                        mb={5}
                                                        ml={ThemeUtils.relativeRealWidth(4)}>
                                                    {Strings.vederlag}
                                                </Label>) : null}
                                            {purchasedVederlegg?.map(renderUttleggItem)}

                                            {purchasedUttlegg?.length ?
                                                (<Label font_medium
                                                        mt={10}
                                                        mb={5}

                                                        ml={ThemeUtils.relativeRealWidth(4)}>
                                                    {Strings.utlegg}
                                                </Label>) : null}
                                            {purchasedUttlegg?.map(renderUttleggItem)}
                                            {propertyPreferenceQuery?.data?.property?.property_preference?.rebate_amount ? (
                                                <View style={styles.billListCont}>
                                                    <CostingAddServiceView isDeletable={false}
                                                                           title={"Avslag på totalprisen"}
                                                                           color={Color.TERNARY_TEXT_COLOR}
                                                                           iconColor={Color.DARK_GRAY}
                                                                           price={`Kr ${formatPrice(propertyPreferenceQuery?.data?.property?.property_preference?.rebate_amount)},-`}/>

                                                </View>
                                            ) : null}
                                            <Hr lineStyle={styles.lineStyle}/>
                                            <CostingAddServiceView title={Strings.totalCosting}
                                                                   isTotalAmount
                                                                   mt={5}
                                                                   mb={5}
                                                // color={Color.WHITE}
                                                                   price={`Kr ${formatPrice(total)},-`}/>
                                        </View>
                                        <CustomButton title={Strings.okay}
                                                      onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}
                                                      ml={ThemeUtils.relativeRealWidth(3)}
                                                      mt={15}
                                                      mb={15}
                                                      mr={ThemeUtils.relativeRealWidth(3)}/>
                                    </> : null}
                            </View>
                        </Animated.ScrollView>
                    )}


                </View>
                <View>
                    {loading || packagesQuery?.loading || purchasePhotoServicesQuery.loading ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <>
                            <Animated.ScrollView scrollEventThrottle={1}
                                                 contentContainerStyle={styles.flexGrow}
                                                 onScroll={Animated.event(
                                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                                     {useNativeDriver: true})}>
                                <View>
                                    {data?.office_broker_on_view_service && !data?.office_broker_on_view_service?.service_selected ? (
                                        <View style={styles.orderCont}>
                                            <AddServiceView image={data?.office_broker_on_view_service?.image}
                                                            isSelected={isBrokerOnView}
                                                            title={data?.office_broker_on_view_service?.name}
                                                            desc={data?.office_broker_on_view_service?.description}
                                                            onPress={() => setBrokerOnView(prevVal => !prevVal)}
                                                            name={'disclaimer'}
                                                            price={`Kr ${formatPrice(data?.office_broker_on_view_service?.price)},-`}/>
                                        </View>) : null}
                                    {additionalPackages.length ? (
                                        <Label font_medium
                                               mt={15}
                                               ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                            {Strings.marketingPackages}
                                        </Label>
                                    ) : null}
                                    <View style={styles.orderCont}>
                                        {additionalPackages.map(renderAdditionalServiceItem)}
                                    </View>
                                    {additionalServices.length ? (
                                        <Label font_medium
                                               ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                            {Strings.services}
                                        </Label>
                                    ) : null}
                                    <View style={styles.orderCont}>
                                        {additionalServices.map(renderAdditionalService)}
                                    </View>
                                </View>
                            </Animated.ScrollView>

                            {selectedServices.length || selectedPackages.length || isBrokerOnView || selectedVendorServices?.length ? (
                                <CustomButton style={{borderRadius: 0, height: 40}}
                                              onPress={() => {
                                                  let selectedPackage = packagesQuery.data?.befaring_packages?.find(item => selectedPackages.includes(item.id))
                                                  let service = []
                                                  if (selectedPackage) {
                                                      service = [...service, ...selectedPackage?.marketing_package_service.map(item => item.office_service.id)]
                                                  }
                                                  purchaseService({
                                                      variables: {
                                                          input: {
                                                              property_id: props.property?.id,
                                                              service_ids: selectedServices.filter(item => !service.includes(item)),
                                                              marketing_package: selectedPackages,
                                                              broker_on_view: isBrokerOnView,
                                                              vendor_service_ids: selectedVendorServices
                                                          }
                                                      },
                                                  }).then(() => {
                                                      purchasePhotoServicesQuery?.refetch();
                                                      packagesQuery?.refetch()
                                                      setSelectedServices([])
                                                      setSelectedPackages([])
                                                      setSelectedVendorServices([])
                                                      setAdditionalPackages([])
                                                  })
                                              }}
                                              loading={purchaseServiceMutation.loading}
                                              textColor={Color.PRIMARY_TEXT_COLOR}
                                              title={Strings.addService}/>
                            ) : null}
                        </>)}
                </View>
            </IndicatorViewPager>

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStateToProps)(MyOrders);
