import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from '../../utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    inst: {
        lineHeight: 21
    },
    fieldContainer: {
        alignContent: 'center',
    },
    container: {
        flex: 1,
        backgroundColor: Color.PRIMARY_BG_COLOR
    },
    headerTitle: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 40
    },
    txtRegular: {
        fontFamily: 'Maax'
    },
    topContainer: {
        marginTop: 40,
        marginHorizontal: containerHorizontalMargin,
        justifyContent: 'center',
    },
    btnCont: {
        alignSelf: 'stretch',
        marginHorizontal: 70,
        marginTop: 10
    }
});

export {styles};
