import React from 'react'
import {ActivityIndicator, FlatList, Image, TouchableOpacity, View} from 'react-native'
import styles from './styles'
import {Hr, Label} from "components/src/components";

import {withFirebase} from "components/src/HOC/Firebase";
import {connect} from "react-redux";
import {USER_DETAILS_LIST} from "components/src/api/query";

import {
    Color,
    CommonStyle,
    Constants,
    decryptId,
    encryptId,
    getUserString,
    Icon,
    IS_WEB,
    Routes,
    ThemeUtils,
    UserType
} from "components/src/utils";
import userPlaceholder from 'components/src/image/user_placeholder.png'
import {Query} from "@apollo/react-components";

class ChatList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ids: [],
            chatList: [],
            loading: true
        }
    }

    componentDidMount() {
        this.addChatListener();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.property?.id !== this.props.property?.id ||
            prevProps.property?.property_id !== this.props.property?.property_id) {
            this.addChatListener();
            if (IS_WEB) {
                this.props.history.replace(`${encryptId(!this.props.property?.id ? this.props.property?.property_id : this.props.property?.id)}`)
            }
        }
    }

    addChatListener = () => {
        const {user, property, database} = this.props;
        let propertyId = user?.user_type_id === UserType?.Buyer ? property?.property_id : property?.id;
        if (IS_WEB && !propertyId) {
            propertyId = decryptId(this.props.match.params.propertyId)
        }
        this.database && this.database?.off()
        this.database = database().ref("v1")
            .child('users')
            .child(user?.id?.toString())
            .child(propertyId?.toString());
        console.log(propertyId, user)
        this.database.orderByChild("lastMessageTimestamp").on('value', (e) => {
            let chats = [];
            e.forEach((child) => {
                console.log(child.val());
                chats.push(child.val())
            });
            this.setState({
                chatList: chats,
                loading: false,
                ids: chats.map(item => item.receiverId)
            })
        }, () => this.setState({loading: false}))
    }

    componentWillUnmount() {
        this.database && this.database?.off()
    }

    navigateToChat = (receiver) => {
        const {history,user, location, navigation, property} = this.props;
        if (IS_WEB) {
            history.push(`/${location.pathname.split('/')[1]}/${Routes.ChatDetails}/${encryptId(user?.user_type_id === UserType?.Buyer ? property?.property_id : property?.id)}/${encryptId(receiver.receiverId)}`, {
                user: receiver.user
            })
        } else {
            navigation.navigate(Routes.ChatDetails, {receiverId: receiver.receiverId})
        }
    };

    renderChatItem = ({item, name}) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              onPress={() => this.navigateToChat(item)}
                              style={styles.chatCont}>
                <View style={[styles.dot, {backgroundColor: item.isRead ? Color.TRANSPARENT : Color.RED}]}/>
                <Image style={styles.chatImage}
                       source={item?.user?.profile_image ? {uri: `${Constants.APIConfig.STORAGE_URL}${item?.user?.profile_image}`} : userPlaceholder}/>
                <View style={styles.lblCont}>
                    <Label small
                           font_medium>
                        {`${item?.user?.first_name ?? ""} ${item?.user?.last_name ?? ""}`}
                    </Label>
                    <Label small
                           mt={5}>
                        {getUserString(parseInt(item?.user?.user_type_id))}
                    </Label>
                </View>
                <Icon name={'drop-normal'}
                      color={Color.PRIMARY_LIGHT}
                      size={ThemeUtils.fontNormal}/>
            </TouchableOpacity>
        )
    }

    render() {
        const {ids, chatList} = this.state
        return (
            <View>
                <Query query={USER_DETAILS_LIST}
                       skip={!ids.length}
                       variables={{
                           ids: ids
                       }}>
                    {({loading, error, data}) => {
                        let updatedChatList = chatList.slice()
                        updatedChatList = updatedChatList.map(item => {
                            item.user = data?.users_detail_list?.find(user => parseInt(item.receiverId, 10) === parseInt(user.id, 10))
                            return item
                        })
                        return (
                            <>
                                {!data ?
                                    loading || this.state.loading ? (
                                        <ActivityIndicator color={Color.PRIMARY}
                                                           style={CommonStyle.loader}/>) : (
                                        <Label font_medium
                                               align={'center'}
                                               mt={20}>
                                            {"Ingen data"}
                                        </Label>
                                    ) : (
                                        <FlatList data={updatedChatList.slice().reverse()}
                                                  ItemSeparatorComponent={() => <Hr/>}
                                                  renderItem={this.renderChatItem}
                                                  keyExtractor={(item) => item.id?.toString()}
                                        />
                                    )}
                            </>)

                    }}
                </Query>

            </View>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        property: state.property
    }
}
export default connect(mapStateToProps)(withFirebase(ChatList))
