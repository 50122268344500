import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'

import {CustomButton, DatePicker, Header, Hr, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import moment from "moment";
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {STYLISH_ORDER_INFORMATION} from "components/src/api/stylistQuery";
import {STYLIST_UPDATE_ORDER_INFORMATION} from "components/src/api/stylistMutation";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    IS_WEB,
    MessageUtils,
    Routes,
    showMessage,
    Strings
} from "components/src/utils";
import {styles} from './styles.js';
import {decryptId} from "web/src/utils";


class OrderingInformation extends React.Component {
    constructor(props) {
        super(props)
        this.order = IS_WEB ? props.history.location.state?.order ?? {
            id: parseInt(decryptId(props.match.params.id), 10),
        } : props.navigation.getParam('order', {});
        this.state = {
            orderDate: this.order?.order_date ? new Date(moment(this.order.order_date, DateUtils.yyyy_mm_dd)) : undefined,
            startTime: this.order?.earliest_start_time ? new Date(moment(this.order.earliest_start_time, DateUtils.hh_mm_ss)) : undefined,
            endTime: null,

            uploadPhotoShow: false,
            inspectionDate: undefined,
            inspectionDateDisplay: '',

            earlierStartTime: undefined,
            earlierStartTimeDisplay: '',

            latestStartTime: undefined,
            latestStartTimeDisplay: '',

            comments: undefined,


            totalAmount: 0,
            displayAmount: undefined,

            detailsone: [
                {
                    index: 1,
                    Oppdrag: '1005619',
                    sellername: 'Emilie Pettersen',
                    email: 'emilieP12@webmegling.no',
                    phone: '955-536-65',
                    styliingtype: 'Free Consultation Online',
                    date: '22-02-2020',
                    time: '8:00 - 13:00',
                    propertyaddress: 'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350'
                },

            ],

        }
        this.scrollY = new Animated.Value(0)
    }

    OrderingInformation = () => {
        return (
            <View>
                {this.order?.order_status === Constants.ORDER_STATUS.ORDERED ||
                this.order?.order_status === Constants.ORDER_STATUS.TIMEBOOKED ? (
                    <Mutation mutation={STYLIST_UPDATE_ORDER_INFORMATION}
                              refetchQueries={[{
                                  query: STYLISH_ORDER_INFORMATION,
                                  variables: {
                                      id: this.order.id
                                  }
                              }]}
                              onCompleted={(data) => {
                                  if (IS_WEB) {
                                      this.props.history.replace(Routes.StylistOrderAccepted, {
                                          order: data?.updatestylist?.orderstyle
                                      })
                                  } else {
                                      this.props.navigation.navigate(Routes.StylistOrderAccepted, {
                                          order: data?.updatestylist?.orderstyle
                                      })
                                  }
                              }}
                              onError={() => {
                              }}>
                        {(updateOrder, {loading, data, error}) => (
                            <View style={styles.topContainer}>
                                <DatePicker min={new Date(moment().add(1, 'd'))} title={Strings.date}
                                            selectedDate={this.state.orderDate}
                                            onSelectDate={(date) => this.setState({orderDate: date})}
                                            placeholder={Strings.selectDate}/>

                                <DatePicker title={Strings.startTime}
                                            placeholder={Strings.selectTime}
                                            selectedDate={this.state.startTime}
                                            onSelectDate={(date) => this.setState({startTime: date})}
                                            mode={'time'}/>


                                <CustomButton loading={loading}
                                              mt={30}
                                              mb={30}
                                              title={this.order?.order_status === Constants.ORDER_STATUS.ORDERED ? Strings.confirm : Strings.reSchedule}
                                              onPress={() => {
                                                  if (this.state.orderDate) {
                                                      if (this.state.startTime) {
                                                          updateOrder({
                                                              variables: {
                                                                  input: {
                                                                      id: this.order.id,
                                                                      fields: {
                                                                          order_status: Constants.ORDER_STATUS_SERVER.TIMEBOOKED,
                                                                          order_date: moment(this.state.orderDate).format(DateUtils.yyyy_mm_dd),
                                                                          earliest_start_time: moment(this.state.startTime).format(DateUtils.hh_mm_ss),
                                                                      },
                                                                  },
                                                              },
                                                          })
                                                      } else {
                                                          showMessage(MessageUtils.Errors.startTime,Constants.MessageType.FAILED)
                                                      }
                                                  } else {
                                                      showMessage(MessageUtils.Errors.orderDate,Constants.MessageType.FAILED)
                                                  }

                                              }}/>
                                <Hr/>
                            </View>

                        )}
                    </Mutation>
                ) : null}

                <View style={styles.topContainer}>
                    <View style={{marginTop: 30}}>{this.Details()}</View>
                    {this.order?.comment ? (
                        <View>
                            <Label
                                mt={5}
                                font_medium
                                small
                                align={'left'}>{Strings.comment}</Label>
                            <Label
                                mt={12}
                                mb={25}
                                font_regular
                                small
                                align={'left'}>
                                {this.order?.comment}
                            </Label>
                        </View>) : null}


                </View>
            </View>
        );
    }


    Details = () => {

        return (
            <View>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.oppdragno}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{this.order?.property?.oppdrags_nummer}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.sellername}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {`${this.order?.property?.seller?.first_name} ${this.order?.property?.seller?.last_name}`}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Email}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {this.order?.property?.seller?.email}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Phone}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {this.order?.property?.seller?.mobile}
                </Label>


                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.stylingtype}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {this.order?.styling_type?.name}
                </Label>


                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.dates}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {moment(this.order.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.time}</Label>
                {this.order?.order_status === Constants.ORDER_STATUS.ORDERED ?
                    (
                        <Label small
                               mb={20}
                               font_regular
                               align={'left'}>
                            {`${moment(this.order.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)} - ${moment(this.order.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}`}
                        </Label>) : (
                        <Label small
                               mb={20}
                               font_regular
                               align={'left'}>
                            {moment(this.order.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                        </Label>
                    )}
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.propertyaddress}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {`${this.order?.property?.street_adress ?? ""} ${this.order?.property?.city ?? ""} ${this.order?.property?.zipcode ?? ""}`}
                </Label>
            </View>
        );

    };


    render() {
        return (
            <Provider locale={enUS}>
                <Header animatedValue={this.scrollY}
                        animatedTitle={Strings.orderinginformation}
                        initialMarginLeft={10}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>
                <Animated.ScrollView contentContainerStyle={{flexGrow: 1}}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <Query query={STYLISH_ORDER_INFORMATION}
                               onError={() => {

                               }}
                               onCompleted={(data)=>{
                                   this.setState({
                                       orderDate: data?.style_order?.orderstyle?.order_date ? new Date(moment(this.order.order_date, DateUtils.yyyy_mm_dd)) : undefined,
                                       startTime: data?.style_order?.orderstyle?.earliest_start_time ? new Date(moment(this.order.earliest_start_time, DateUtils.hh_mm_ss)) : undefined,
                                   })
                               }}
                               variables={{
                                   id: this.order.id
                               }}>
                            {({loading, data, error}) => {
                                if (loading && !data) {
                                    return (<ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>)
                                }
                                if (data) {
                                    this.order = data?.style_order?.orderstyle
                                }
                                return (
                                    <View>
                                        {this.OrderingInformation(this.order)}
                                    </View>
                                )
                            }}
                        </Query>

                    </View>
                </Animated.ScrollView>
            </Provider>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderingInformation);
