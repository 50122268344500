import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {CustomTextArea, DropDown} from "components/src/components";
//Utils
import {Strings} from 'components/src/utils'
import {setSellerInterViewForm} from "../../../../../../redux/action";
import {connect} from "react-redux";

const QUESTIONS = [{
    id: 'q1',
    text: Strings.seventhStep.q1,
    options: [{
        name: Strings.seventhStep.altiBox,
        value: 'Altibox',
    }, {
        name: Strings.seventhStep.nextGenTel,
        value: 'NextGenTel',
    }, {
        name: Strings.seventhStep.telenor,
        value: 'Telenor',
    }, {
        name: Strings.seventhStep.broadnet,
        value: 'Broadnet',
    }, {
        name: Strings.seventhStep.iceNet,
        value: 'Ice.net',
    }, {
        name: Strings.seventhStep.breibandNo,
        value: 'Breiband.no',
    }, {
        name: Strings.seventhStep.homen,
        value: 'Homen',
    }, {
        name: Strings.seventhStep.telio,
        value: 'Telio',
    }, {
        name: Strings.seventhStep.eidsivaBroadband,
        value: 'Eidsiva Broadband',
    }, {
        name: Strings.seventhStep.globalConnect,
        value: 'Global Connect',
    }, {
        name: Strings.seventhStep.viaBroadband,
        value: 'Via Broadband',
    }, {
        name: Strings.seventhStep.other,
        value: 'Other',
    },]
}, {
    id: 'q2',
    text: Strings.seventhStep.q2,
    options: [{
        name: Strings.seventhStep.broadband,
        value: 'Broadband'
    }, {
        name: Strings.seventhStep.fiber,
        value: 'Fiber'
    }, {
        name: Strings.seventhStep.other,
        value: 'Other'
    },]
}, {
    id: 'q3',
    text: Strings.seventhStep.q3,
    options: [{
        name: Strings.seventhStep.altiBox,
        value: 'Altibox',
    }, {
        name: Strings.seventhStep.nextGenTel,
        value: 'NextGenTel',
    }, {
        name: Strings.seventhStep.telenor,
        value: 'Telenor',
    }, {
        name: Strings.seventhStep.get,
        value: 'Get',
    }, {
        name: Strings.seventhStep.canalDigital,
        value: 'Canal Digital',
    }, {
        name: Strings.seventhStep.iceNet,
        value: 'Ice.net',
    }, {
        name: Strings.seventhStep.viasat,
        value: 'Viasat',
    }, {
        name: Strings.seventhStep.riksTv,
        value: 'RiksTV',
    }, {
        name: Strings.seventhStep.homen,
        value: 'Homen',
    }, {
        name: Strings.seventhStep.other,
        value: 'Other',
    }]
}, {
    id: 'q4',
    text: Strings.seventhStep.q4,
    options: [{
        name: Strings.seventhStep.fiber,
        value: 'Fiber'
    }, {
        name: Strings.seventhStep.cableTv,
        value: 'Cable TV'
    }, {
        name: Strings.seventhStep.satelliteDish,
        value: 'Satellite Dish'
    }, {
        name: Strings.seventhStep.igniter,
        value: 'Igniter'
    }, {
        name: Strings.seventhStep.other,
        value: 'Other'
    }]
}];
const Q5 = Strings.seventhStep.q5;
const Q6 = Strings.seventhStep.q6;

function SeventhStep(props) {

    const {setForm, interViewForm} = props;

    const q1 = interViewForm.find(item => item.interview_section === 7 && item.question_no === 22)?.answer ?? ''
    const q2 = interViewForm.find(item => item.interview_section === 7 && item.question_no === 23)?.answer ?? ''
    const q3 = interViewForm.find(item => item.interview_section === 7 && item.question_no === 24)?.answer ?? ''
    const q4 = interViewForm.find(item => item.interview_section === 7 && item.question_no === 25)?.answer ?? ''
    const [options, setOptions] = useState({
        q1: q1,
        q2: q2,
        q3: q3,
        q4: q4,
    });

    const [annualConsumption, setAnnualConsumption] = useState(interViewForm.find(item => item.interview_section === 7 && item.question_no === 26)?.answer ?? '');
    const [securityCabinLocation, setSecurityCabinLocation] = useState(interViewForm.find(item => item.interview_section === 7 && item.question_no === 27)?.answer ?? '');

    


    useEffect(() => {
        let answer = [
            {
                "question": QUESTIONS[0].text,
                "interview_section": 7,
                "question_no": 22,
                "type": "DROPDOWN",
                "answer": options.q1,
                "extra": null,
                "comment": null
            },
            {
                "question": QUESTIONS[1].text,
                "interview_section": 7,
                "question_no": 23,
                "type": "DROPDOWN",
                "answer": options.q2,
                "extra": null,
                "comment": null
            },
            {
                "question": QUESTIONS[2].text,
                "interview_section": 7,
                "question_no": 24,
                "type": "DROPDOWN",
                "answer": options.q3,
                "extra": null,
                "comment": null
            },
            {
                "question": QUESTIONS[3].text,
                "interview_section": 7,
                "question_no": 25,
                "type": "DROPDOWN",
                "answer": options.q4,
                "extra": null,
                "comment": null
            },
            {
                "question": Q5,
                "interview_section": 7,
                "question_no": 26,
                "type": "TEXT",
                "answer": annualConsumption,
                "extra": null,
                "comment": null
            },
            {
                "question": Q6,
                "interview_section": 7,
                "question_no": 27,
                "type": "TEXT",
                "answer": securityCabinLocation,
                "extra": null,
                "comment": null
            }
        ]
        setForm([...interViewForm.filter(item => item.interview_section !== 7), ...answer])

    }, [
        options,
        annualConsumption,
        securityCabinLocation
    ]);

    const handleSelectOptions = (option, id) => {
        let updatedOptions = {...options};
        updatedOptions[id] = option;
        setOptions(updatedOptions)
    };

    const renderQuestions = (item, index) => {
        return (<DropDown title={item.text}
                          selectedOption={options[item.id]}
                          onSelectOptions={(option) => handleSelectOptions(option, item.id)}
                          options={item.options}/>)
    };

    return (
        <View>
            {QUESTIONS.map(renderQuestions)}
            <CustomTextArea labelText={Q5}
                            mt={10}
                            value={annualConsumption}
                            onChange={setAnnualConsumption}/>

            <CustomTextArea labelText={Q6}
                            mt={10}
                            value={securityCabinLocation}
                            onChange={setSecurityCabinLocation}/>
        </View>
    )
}


const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form))
    }

};
export default connect(mapStateToProps, mapDispatchToProps)(SeventhStep)
