import {StyleSheet} from 'react-native'
import {ThemeUtils} from "components/src/utils";
export default StyleSheet.create({
    cont:{
        flex:1,
        marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    btnCont:{
        flexDirection:'row',
        paddingVertical:20,
    }
})
