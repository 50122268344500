import gql from 'graphql-tag';

export const INSURANCE_LIST = gql`
query insuranceTypeList($status: Int!) {
  insurance_type_list(status: $status) {
    id
    name
    status
  }
}`;

export const APPOINTMENT_DETAILS = gql`
query appointmentInfo($id: ID!) {
  appointment_info(id: $id) {
    id
    seller_id
    oppdrags_nummer
    street_adress
    city
    zipcode
    municipality
    gnr
    bnr
    fnr
    snr
    partOrgNumber
    vitecProperty{
     takeOverDate
     vitecPropertyEstatePrice{
       priceSuggestion
     }
   }
    seller {
      id
      first_name
      last_name
      email
      mobile
      city
      address
      zipcode
    }
  }
}`

export const BUYER_FINANCING_DETAILS = gql`
query buyerFinancingDetails($propertyId: Int!) {
  buyer_financing_detail(property_id: $propertyId) {
    id
    property_id
    is_agree
    financingType {
      buyer_financing_id
      financing_type_id
    }
  }
}`;

export const BUYER_BANK_CONTACT_INFO = gql`
query buyerContactBankInfo($id: Int!, $status: Int!) {
  buyer_bank_contact_info(prop_buyer_info_id: $id, status: $status) {
    id
    prop_buyer_info_id
    agencapital
    opparinelse_id
    opparinelse_text
    pant_boligen
    pep_status
    purpose_of_purchase_id
    purchase_text
    account_number
    buyerBankInfo {
      id
      bank_name
      bank_contact
      bank_email
      bank_mobile
    }
  }
}`;

export const BUYER_OWNERSHIP_INFO = gql`
query buyerOwnershipInfo($id: Int!, $status: Int!) {
  buyer_ownership_info(property_id: $id, status: $status) {
    id
    contactId
    departmentId
    contactType
    companyName
    organisationNumber
    first_name
    last_name
    email
    phone
    address
    city
    zipcode
    ownership_interest
    personnummer
    eierandel
    contact_person
    ssn_number
    coBuyerInfo {
      id
      prop_buyer_info_id
      personnummer
      eierandel
      contact_person
      ssn_number
      contactId
      departmentId
      contactType
      companyName
      organisationNumber
      first_name
      last_name
      email
      phone
      address
      city
      zipcode
      ownership_interest
    }
  }
}
`

export const BUYER_FINANCE_TYPE = gql`
query financeType($status: Int!) {
  financing_type_list(status: $status) {
    id
    name
    status
  }
}
`
export const BUYER_HOME_INSURANCE_INFO = gql`
query homeBuyerInsurance($id:Int!,$status:Int!){
  home_buyer_insurance_info(prop_buyer_info_id:$id,status:$status){
    id
    prop_buyer_info_id
    want_insurance
    status
  }
}`
export const BUYER_HOME_INSURANCE = gql`
query homeBuyerInsurance($property_id: Int!) {
  buyer_prop_insurance_details(property_id: $property_id) {
    is_agree
    propInsuranceType {
      id
      name
    }
  }
}
`


export const BUYER_MOVING_GUIDE = gql`
query buyer_moving_guide_lists($status: Int!, $propertyId: Int!) {
  moving_guide_lists(status: $status, property_id: $propertyId) {
    id
    name
    description
    action
    is_service
    buyerMovingGuide {
      property_id
      user_id
      moving_guide_id
      is_completed
      completed_date
    }
    movingGuideLink {
      id
      moving_guide_id
      title
      link_url
      status
    }
  }
}`

export const BUYER_DOCS = gql`
query propertyBuyerSellerDoc($propertyId:Int!,$isBuyerSeller:String!,$status:Int!){
  property_document_lists(property_id:$propertyId,is_buyer_seller_doc:$isBuyerSeller,status:$status){
    id
    property_id
    title
    doc_url
    doc_type
    is_buyer_seller_doc
    status
    property{
      id
    }
  }
}`

export const BUYER_PROPERTIES = gql`
query buyerProperties($status: Int!) {
  buyer_properties(status: $status) {
    id
    buyer_id
    property_id
    property {
      id
      oppdrags_nummer
      street_adress
      city
      ownership
      zipcode
      property_status
      usable_area
       buyerContractDoc{
        id
      }
    }
  }
}`;

export const BUYER_TIMELINE = gql`
query buyerTimeline($propertyId: ID!, $status: Int!) {
  buyer_property_timeline(property_id: $propertyId, status: $status) {
    id
    is_completed
    completed_date
    updated_at
    timeline {
      id
      name
      description
      icon
      link
    }
  }
}`;

export const BUYER_SEE_MOVIE = gql`
query seeMovieDetails($status:Int!){
  see_movie_detail(status:$status){
    id
    title
    link_url
    type
  }
}`

export const BUYER_CALENDAR_EVENT = gql`
query buyerCalendarEvent {
  buyer_calendar_event {
    id
    property_id
    created_user_id
    title
    description
    start_datetime
    end_datetime
    event_type
    user_type
    property{
        street_adress
        city
        zipcode
    }
  }
}`;


export const BUYER_SIGNUP_QUERY = gql`
query fetchBuyerDetails($propertyId: Int!, $buyerId: Int!) {
  fetch_buyer_detail(property_id: $propertyId, buyer_id: $buyerId) {
    id
    contactId
    departmentId
    contactType
    companyName
    organisationNumber
    property_id
    
    first_name
    last_name
    email
    phone
    address
    city
    zipcode
    personnummer
    eierandel
    contact_person
    ssn_number
    purchase_price
    take_over_date
    reservation_comment
    property {
      id
      street_adress
      municipality
      ownership
      usable_area
      fnr
      gnr
      snr
      bnr
    }
    coBuyerInfo {
      id
      prop_buyer_info_id
      contactId
      departmentId
      contactType
      companyName
      organisationNumber
      first_name
      last_name
      email
      phone
      address
      city
      zipcode
      personnummer
      eierandel
      contact_person
      ssn_number
    }
    buyerPropertyInfo {
      id
      prop_buyer_info_id
      document_id
      purchase
      commune
      gnr
      bnr
      fnr
      org_nr
    }
  }
}`

export const BUYER_CONTRACT_DOC_QUERY = gql`
query buyerContractDoc(
  $propertyId: Int!
  $status: Int!
  $commentBy: commentBy
) {
  buyer_contract_doc(
    property_id: $propertyId
    status: $status
    comment_by: $commentBy
  ) {
    id
    property_id
    title
    doc_url
    doc_type
    status
    contractDocComment {
      id
      buyer_contract_doc_id
      comment
      comment_by
      status
      created_at
    }
  }
}
`;

export const ORIGIN_EQUITY = gql`
query orginEquity($status:Int!){
  opprinnelse_egenkapital_option(status:$status){
    id
    name
    other_text
    status
  }
}`;

export const PURPOSE_OF_PURCHASE = gql`
query purposeOfPurchase($status:Int!){
  purpose_purchase_option(status:$status){
    id
    name
    other_text
    status
  }
}`
