/**
 * Created by Abbas on 2019/10/01.
 */


'use strict'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Animated, ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native'
import IndicatorViewPager from '../IndicatorViewPager'
import {Color, CommonStyle, IS_ANDROID, ThemeUtils} from "../../../../utils";
import {Label} from "../../../index";

export default class PagerTabIndicator extends Component {
    static propTypes = {
        initialPage: PropTypes.number,
        pager: PropTypes.instanceOf(IndicatorViewPager),
        tabs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,

        })).isRequired,
        itemStyle: PropTypes.style,
        selectedItemStyle: PropTypes.style,
        changePageWithAnimation: PropTypes.bool,
    }

    static defaultProps = {
        tabs: [],
        changePageWithAnimation: true
    }

    state = {
        selectedIndex: this.props.initialPage
    }

    render() {
        const maxWidth = 320
        let {
            tabs, pager, scrollEnabled, viewPager, style, itemStyle, selectedItemStyle, iconStyle,
            selectedIconStyle, textStyle, selectedTextStyle, changePageWithAnimation,
            allCaps
        } = this.props
        if (!tabs || tabs.length === 0) return null

        let tabsView = tabs.map((tab, index) => {
            let isSelected = this.state.selectedIndex === index
            return (
                <TouchableOpacity
                    style={[styles.itemContainer, {
                        maxWidth: maxWidth,
                        justifyContent: 'center'
                    }, scrollEnabled ? {width: (ThemeUtils.relativeWidth(100) / 2.2) + 32} : CommonStyle.flex, isSelected ? selectedItemStyle : itemStyle]}
                    activeOpacity={0.6}
                    key={index}
                    onPress={() => {
                        if (!isSelected) {
                            this.setState({
                                selectedIndex: index
                            })
                            if (this.props.changePageWithAnimation)
                                pager.setPage(index);
                            else pager.setPageWithoutAnimation(index);
                        }
                    }}
                >

                    <Label small
                           align={'center'}
                           font_medium
                           color={isSelected ? Color.PRIMARY : Color.SECONDARY_TEXT_COLOR}>
                        {tab.text}
                    </Label>

                </TouchableOpacity>

            )
        })


        let marginStart = viewPager ? viewPager.scrollX.interpolate({
            inputRange: [0, ThemeUtils.relativeWidth(100)],
            outputRange: [0, scrollEnabled ? (ThemeUtils.relativeWidth(100) / 2.2) + 32 : ThemeUtils.relativeWidth(100) / tabs.length > maxWidth ? maxWidth : ThemeUtils.relativeWidth(100) / tabs.length]
        }) : 0


        return (
            <View>

                <View>
                    {scrollEnabled ? (
                        <ScrollView horizontal
                                    ref={r => this.scroll = r}
                                    showsHorizontalScrollIndicator={false}
                                    contentContainerStyle={{alignItems: 'center', flexGrow: 1, paddingBottom: 1}}>
                            <View style={[styles.container, style]}>
                                {tabsView}
                                <Animated.View style={{
                                    height: 2.5,
                                    width: (ThemeUtils.relativeWidth(100) / 2.2) + 32,
                                    bottom: -1,
                                    transform: [{translateX: marginStart}],
                                    zIndex: 1,
                                    position: 'absolute',
                                    backgroundColor: Color.SECONDARY
                                }}/>
                            </View>
                        </ScrollView>
                    ) : (
                        <View style={[styles.container, style]}>
                            {tabsView}
                            <Animated.View style={{
                                height: 2.5,
                                maxWidth: maxWidth,
                                width: (ThemeUtils.relativeWidth(100) / tabs.length),
                                bottom: 0,
                                transform: [{translateX: marginStart}],
                                zIndex: 1,
                                position: 'absolute',
                                backgroundColor: Color.SECONDARY
                            }}/>
                        </View>
                    )}
                </View>
                {IS_ANDROID ? (<View style={styles.androidElevation}/>) : null}

            </View>

        )
    }

    onPageSelected(e) {
        this.setState({selectedIndex: e.position},()=>{
            if (this.props?.scrollEnabled) {
                this?.scroll?.scrollTo({x: (e.position * (ThemeUtils.relativeWidth(100) + 70.4)) / 2, y: 0})
            }
        })
    }
}

const styles = StyleSheet.create({
    androidElevation:{
        height: 1,
        bottom: 1,
        backgroundColor: Color.BLACK,
        opacity: 0.23,
        elevation: 4
    },
    container: {
        flexDirection: 'row',
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        maxHeight: 85,
        backgroundColor: Color.WHITE,
        shadowColor: Color.BLACK,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,

    },
    itemContainer: {
        alignItems: 'center',
    },
    image: {},
    text: {
        marginTop: 4,
        fontSize: 11,
        color: '#999999'
    },
    textSelected: {
        marginTop: 4,
        fontSize: 11,
        color: '#3584F6'
    }
})
