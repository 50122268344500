import React from 'react';
import {ActivityIndicator, View} from 'react-native'

import Cookies from 'js-cookie';

import {Query} from "react-apollo";

import {SELLER_PROPERTY_LIST} from "../../api/sellerQuery";
import {Color, CommonStyle, Constants, IS_WEB, NOTIFICATION_TYPE, Routes, USER_TYPE} from "../../utils";
import {setCurrentProperty} from "../../redux/action";
import {connect} from "react-redux";

class RedirectionForLink extends React.Component {
    constructor(props) {
        super(props);
        console.log("Props from link", props);
        this.state = {
            isValidUser: false
        }
    }

    componentDidMount() {
        const userFromCookie = this.props.user ? this.props.user : JSON.parse(Cookies.get(`${USER_TYPE.SELLER}_user`)).user;
        if (this.props && this.props.match && this.props.match.params) {
            const {userId} = this.props.match.params;
            if (userFromCookie) {
                if (parseInt(userFromCookie?.id, 10) === parseInt(userId, 10)) {
                    console.log("User id matched now call the property list api")
                    this.setState({isValidUser: true})
                } else {
                    //TODO: go to the dashboard because link is for another user
                    this.props.history.replace(`/${Routes.Seller}/${Routes.SalesPreparation}`)
                }
            } else {
                //TODO: Goto login page with return url
                this.props.history.replace(`/${Routes.Seller}/${Routes.Login}`, {
                    route: this.props.location.pathname
                })
            }
        } else {
            this.props.history.replace(`/${Routes.Seller}/${Routes.Login}`)
        }
    }

    getPropertyFromRouteId = propertyList => {
        const {propertyId} = this.props.match.params;
        return propertyList.find(item => parseInt(item.id, 10) === parseInt(propertyId, 10),);
    }

    redirectUser = property => {
        const {notificationType} = this.props.match.params;
        let route;
        switch (parseInt(notificationType, 10)) {
            case NOTIFICATION_TYPE.PHOTO_ORDER_CONFIRM:
            case NOTIFICATION_TYPE.PHOTO_UPLOAD:
                if (!property?.order_photo) {
                    route = Routes.Photos;
                    break;
                }
                if (property?.order_photo?.order_status === Constants.ORDER_STATUS.ORDERED ||
                    property?.order_photo?.order_status === Constants.ORDER_STATUS.TIMEBOOKED) {
                    route = Routes.ThankYouPhotos;
                    break;
                }
                route = Routes.UploadedPhotos;
                break;
            case NOTIFICATION_TYPE.TAKST_ORDER_CONFIRM:
            case NOTIFICATION_TYPE.RATE_DETAILS_ADDED:
                if (!property?.orderrate) {
                    if (property?.order_rate_detail) {
                        route = Routes.ViewDetailsRate
                        break;
                    }
                    route = Routes.Rate;
                    break;
                }
                if (property?.orderrate?.order_status === Constants.ORDER_STATUS.ORDERED ||
                    property?.orderrate?.order_status === Constants.ORDER_STATUS.TIMEBOOKED) {
                    route = Routes.ThankYouRate;
                    break;
                }
                route = Routes.ViewDetailsRate;
                break;
            case NOTIFICATION_TYPE.INSPECTION_ORDER_CONFIRM:
                //TODO: THIS CASE IS FOR WM
                break;
            case NOTIFICATION_TYPE.AGENT_UPLOADED_DOC_SELLER:
                route = Routes.Document
                break;
        }
        if (route) {
            IS_WEB ? this.props.history.replace(`/${location.pathname.split('/')[1]}/${route}`) : route
        } else {
            this.props.history.replace(`/${Routes.Seller}/${Routes.SalesPreparation}`)
        }
    }

    render() {
        return (
            <View style={CommonStyle.flex}>
                <Query
                    query={SELLER_PROPERTY_LIST}
                    fetchPolicy="cache-and-network"
                    skip={!this.state.isValidUser}
                    onCompleted={response => {
                        console.log("Data response is here", response);
                    }}
                >
                    {({data, loading, error}) => {
                        if (data &&
                            data.seller_property_list &&
                            data.seller_property_list.length > 0 &&
                            !loading) {
                            let property = this.getPropertyFromRouteId(data.seller_property_list)
                            if (property) {
                                this.props.setCurrentProperty(property)
                                this.redirectUser(property)
                            } else {
                                //TODO: redirect to the dashboard because no property found from route propertyID
                                this.props.history.replace(`/${Routes.Seller}/${Routes.SalesPreparation}`)
                            }
                        }
                        return (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        );
                    }}
                </Query>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentProperty: (property) => dispatch(setCurrentProperty(property)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RedirectionForLink)



