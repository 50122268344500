import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  Modal,
  notification,
  Row,
  Typography,
  Upload,
} from "antd";
// import { LoadingOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { formatMessage } from "components/src/utils";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Mutation } from "react-apollo";
// import { ADMIN_NEWS_LIST } from '../../../../query/AdminQuery';
import {
  ADMIN_ADD_AGENT_MEDIA,
  ADMIN_DELETE_AGENT_MEDIA,
  ADMIN_EDIT_AGENT_MEDIA,
} from "../../../../../../../../mutation/AdminMutation";
import { STORAGE_URL } from "../../../../../../../utils/constant";
import brokerImage from "../../../../../../../assets/News _ Professional/News_Small.png";
// import { toJSONSchema } from 'mockjs';
// import brokerImage from '../../../assets/home/Fotografering.jpg';

const { Title, Paragraph } = Typography;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let recordsCount = 3;

// let descriptionObject='';
// let descriptionObject1='';
// const data = [
//   {
//     title: 'News Latest Headline',
//     description:
//       'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit…',
//     date: '27-02-2020',
//     image: brokerImage,
//   },
//   {
//     title: 'News Latest Headline',
//     description:
//       'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit…',
//     date: '27-02-2020',
//     image: brokerImage,
//   },
//   {
//     title: 'News Latest Headline',
//     description:
//       'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit…',
//     date: '27-02-2020',
//     image: brokerImage,
//   },
// ];

export const Media = (props) => {
  let router = useHistory();
  const { fullObject } = props;
  const [addMediaForm] = Form.useForm();
  console.log("inside edit office details=>", props.fullObject);
  const [imageUrl, setimageUrl] = useState(brokerImage);
  const [addImageUrl, setAddImageUrl] = useState("");
  const [imageObject, setImageObject] = useState("");
  const [mediaList, setMediaList] = useState(fullObject?.user_media);
  const [mediaView, setMediaView] = useState(false);
  const [currentData, setCurrentData] = useState("");

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleUploadChange = (info) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (image) => setimageUrl(image));
    setImageObject(info.file.originFileObj);
  };
  const handleChange = (info) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (image) => setAddImageUrl(image));
    setImageObject(info.file.originFileObj);
  };
  const ModalDataAddingFunction = (item) => {
    setCurrentData(item);
    setimageUrl(`${STORAGE_URL}${item.media_url}`);
    setMediaView(true);
  };

  const onMediaFinish = (values, addMedia) => {
    console.log("Success:", values);
    addMedia({
      variables: {
        agent_id: fullObject.id,
        title: values.title,
        redirection_url: values.redirection_url,
        media_image: values.media_image.fileList[0].originFileObj,
      },
    }).then((data) => {
      console.log("Data submitted===>>>", data);
      if (data && data.data.agent_add_media) {
        notification.success({
          message: `Media ble lagt til`,
          description: "",
          duration: 3,
        });
        if (addMediaForm) {
          addMediaForm.resetFields();
        }
        setAddImageUrl("");
        setMediaList(data.data.agent_add_media);
      }
    });
  };

  const onDelete = (itemId, deleteFeedback) => {
    deleteFeedback({
      variables: {
        media_id: itemId,
        agent_id: fullObject.id,
      },
    }).then((data) => {
      console.log("Data submitted===>>>", data);
      if (data && data.data.delete_agent_media) {
        notification.success({
          message: `Media ble slettet`,
          description: "",
          duration: 3,
        });
        if (mediaView) {
          setMediaView(false);
        }
        setMediaList(data.data.delete_agent_media);
      }
    });
  };

  const onMediaEditFinish = (values, editMedia) => {
    console.log("Success:", values);

    editMedia({
      variables: {
        id: values.id,
        agent_id: fullObject.id,
        title: values.title,
        redirection_url: values.redirectionalurl,
        media_image: imageObject,
      },
    }).then((data) => {
      console.log("Data submitted===>>>", data);
      if (data && data.data.agent_update_media) {
        notification.success({
          message: `Media ble lagt til`,
          description: "",
          duration: 3,
        });
        setCurrentData({});
        setMediaView(false);
        setMediaList(data.data.agent_update_media);
      }
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">
        {formatMessage({
          id: "component.form.upload",
        })}
      </div>
    </div>
  );

  const onMediaFinishFailed = (errorinfo) => console.log(errorinfo);
  const onMediaEditFinishFailed = (errorinfo) => console.log(errorinfo);

  console.log("");

  return (
    <>
      <Row className="contentshadow" style={{ marginLeft: 0 }}>
        <Col xs={24} lg={24}>
          <Title level={3} className="text-primary">
            Presse
          </Title>
          <Mutation mutation={ADMIN_ADD_AGENT_MEDIA}>
            {(addMedia, { loading }) => {
              return (
                <Form
                  layout="vertical"
                  className="user-forms"
                  name="uploadpic"
                  form={addMediaForm}
                  initialValues={{
                    remember: true,
                  }}
                  colon={false}
                  // eslint-disable-next-line no-undef
                  onFinish={(values) => onMediaFinish(values, addMedia)}
                  onFinishFailed={onMediaFinishFailed}
                >
                  <Row style={{ marginTop: 5 }}>
                    <Col xs={24}>
                      <Form.Item
                        name="media_image"
                        label={formatMessage({
                          id: "component.form.profilepicture",
                        })}
                        rules={[
                          {
                            required: true,
                            message: `Vennligst skriv inn ${formatMessage({
                              id: "component.form.profilepicture",
                            }).toLowerCase()}`,
                          },
                        ]}
                      >
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          onChange={handleChange}
                        >
                          {addImageUrl ? (
                            <img
                              src={addImageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={24} lg={16}>
                      <Form.Item
                        name="title"
                        className="label-texts"
                        label={formatMessage({
                          id: "component.form.title",
                        })}
                        rules={[
                          {
                            required: true,
                            message: `Vennligst skriv inn  ${formatMessage({
                              id: "component.form.title",
                            }).toLowerCase()}!`,
                          },
                        ]}
                      >
                        <Input.TextArea
                          rows={3}
                          placeholder="Skriv medietittel her..."
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <Form.Item
                        name="redirection_url"
                        className="label-texts"
                        label={formatMessage({
                          id: "component.form.redirectionalurl",
                        })}
                        rules={[
                          {
                            required: true,
                            message: `Vennligst skriv inn ${formatMessage({
                              id: "component.form.redirectionalurl",
                            }).toLowerCase()}!`,
                          },
                        ]}
                      >
                        <Input
                          placeholder={formatMessage({
                            id: "component.form.redirectionalurl",
                          })}
                          size="large"
                        />
                      </Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        loading={loading}
                        htmlType="submit"
                        className="btn-secondary buttons admin-forms-btn mt-3"
                      >
                        {formatMessage({
                          id: "component.form.submit",
                        })}
                      </Button>
                      <Link to="/admin/fagansvarlig">
                        <Button
                          size="large"
                          className="buttons admin-forms-btn back-button mt-3 cancel-btn"
                        >
                          {formatMessage({
                            id: "component.form.cancel",
                          })}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Mutation>
          <Divider />
          <List
            className="listViewStyle"
            itemLayout="horizontal"
            dataSource={mediaList}
            renderItem={(item) => (
              <List.Item height={20} key={Math.random()}>
                <List.Item.Meta
                  onClick={() => ModalDataAddingFunction(item)}
                  avatar={<Avatar src={`${STORAGE_URL}${item.media_url}`} />}
                  title={<a className="text-bold">{item.title}</a>}
                  description={
                    <>
                      <Paragraph className="mb-0" ellipsis={{ rows: 3 }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.redirect_url,
                          }}
                        />
                      </Paragraph>
                      <span
                        style={{ opacity: 0.5, marginTop: "4px" }}
                        className="d-block"
                      >
                        {/* {item.created_at} */}
                      </span>
                    </>
                  }
                  // date={item.date}
                />
                <i
                  className="icon-edit"
                  onClick={() => ModalDataAddingFunction(item)}
                />
                <>
                  <Mutation mutation={ADMIN_DELETE_AGENT_MEDIA}>
                    {(deleteFeedback, { loading }) => {
                      return loading ? (
                        <LoadingOutlined />
                      ) : (
                        <i
                          className="icon-delete"
                          style={{ cursor: "pointer" }}
                          onClick={() => onDelete(item.id, deleteFeedback)}
                        />
                      );
                    }}
                  </Mutation>
                </>
              </List.Item>
            )}
          />
          {/* <a
            className="text-center text-bold mt-20 loadmore"
            style={{ display: "block" }}
            onClick={() => {
              recordsCount += 3;
            }}
          >
            {formatMessage({ id: "component.photosorder.loadmore" })}
          </a> */}
        </Col>
      </Row>
      <Modal
        centered
        destroyOnClose
        title="Rediger Presse"
        visible={mediaView}
        onOk={() => setMediaView(false)}
        onCancel={() => setMediaView(false)}
        width="600px"
        footer={false}
      >
        <>
          {console.log("Current Data", currentData)}
          <Mutation mutation={ADMIN_EDIT_AGENT_MEDIA}>
            {(editMedia, { loading }) => {
              return (
                <Form
                  name="editMediaForm"
                  initialValues={{
                    remember: true,
                    id: currentData.id,
                    redirectionalurl: currentData.redirect_url,
                    title1: currentData.redirect_url,
                    title: currentData.title,
                  }}
                  onFinish={(values) => onMediaEditFinish(values, editMedia)}
                  onFinishFailed={(errorInfo) =>
                    onMediaEditFinishFailed(errorInfo)
                  }
                  layout="vertical"
                  className="user-forms addEditform"
                >
                  <Form.Item name="id" />
                  <Form.Item
                    name="media_image"
                    label={formatMessage({
                      id: "component.news.coverphoto",
                    })}
                  >
                    <Row>
                      <Col xs={24}>
                        <div className="user-profile-box">
                          <img
                            src={imageUrl}
                            alt="John Doe"
                            className="profile-picture"
                          />
                        </div>
                      </Col>
                      <Col xs={24} className="mt-3">
                        <Upload
                          showUploadList={false}
                          onChange={handleUploadChange}
                        >
                          <Button>
                            <UploadOutlined /> Upload
                          </Button>
                        </Upload>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    label={formatMessage({ id: "component.form.title" })}
                    name="title"
                  >
                    <Input.TextArea
                      placeholder={formatMessage({
                        id: "component.form.title",
                      })}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={formatMessage({ id: "component.form.redirectionalurl" })}
                    name="redirectionalurl"
                  >
                    <Input
                      placeholder={formatMessage({
                        id: "component.form.redirectionalurl",
                      })}
                      size="large"
                    />
                  </Form.Item>
                  
                  <Button
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    className="admin-forms-btn  mr_15"
                    size="large"
                    loading={loading}
                    form="editMediaForm"
                  >
                    {formatMessage({
                      id: "component.form.update",
                    })}
                  </Button>
                  <Button
                    className="admin-forms-btn  mr_15"
                    size="large"
                    onClick={() => setMediaView(false)}
                  >
                    {formatMessage({
                      id: "component.form.cancel",
                    })}
                  </Button>
                  <Mutation mutation={ADMIN_DELETE_AGENT_MEDIA}>
                    {(deleteFeedback, { loading }) => {
                      return (
                        <Button
                          loading={loading}
                          className="admin-forms-btn  mr_15"
                          size="large"
                          style={{ float: "right", color: "red" }}
                          onClick={() =>
                            onDelete(currentData.id, deleteFeedback)
                          }
                        >
                          {formatMessage({
                            id: "component.form.delete",
                          })}
                        </Button>
                      );
                    }}
                  </Mutation>
                </Form>
              );
            }}
          </Mutation>
        </>
      </Modal>
    </>
  );
};

export default Media;
