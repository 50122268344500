import gql from 'graphql-tag';

export const ADD_UPDATE_BEFARINGSBEKREFTELSE = gql`
  mutation add_update_befaringsbekreftelse($input: BefaringsbekreftelseInput!) {
    add_update_befaringsbekreftelse(input: $input) {
      office_id
      description
    }
  }
`;

export const ABOUT_W = gql`
  mutation add_update_about_w($input: AboutWInput!) {
    add_update_about_w(input: $input) {
      id
      office_id
      headline
      image
      description
    }
  }
`;

export const PREPARATIONS = gql`
  mutation add_update_preparation($input: AboutWInput!) {
    add_update_preparation(input: $input) {
      id
      office_id
      headline
      image
      description
    }
  }
`;

export const QUESTIONS_TO_ASK = gql`
  mutation add_update_question_to_ask($input: AboutWInput!) {
    add_update_question_to_ask(input: $input) {
      id
      office_id
      headline
      image
      description
    }
  }
`;

export const PRODUCT_GALLERY = gql`
  mutation add_update_production_gallery($input: ProductGalleryInput!) {
    add_update_production_gallery(input: $input) {
      id
      office_id
      headline
      ProductGalleryImages {
        id
        product_gallery_id
        url
      }
    }
  }
`;

export const DELETE_PRODUCT_IMAGE = gql`
  mutation delete_gallery_images($ids: [Int!]!, $product_gallery_id: Int!) {
    delete_gallery_images(ids: $ids, product_gallery_id: $product_gallery_id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const REPLACE_PRODUCT_IMAGE = gql`
  mutation replace_gallery_images($id: Int!, $product_gallery_id: Int!, $image: Upload!) {
    replace_gallery_images(id: $id, product_gallery_id: $product_gallery_id, image: $image) {
      id
      office_id
      headline
      ProductGalleryImages {
        id
        product_gallery_id
        url
      }
    }
  }
`;

// for all services (form)
export const SAVE_OFFICE_TEMPLATE_SERVICE = gql`
  mutation updateOfficeTemplate($input: saveTemplateTemplateInput!) {
    update_office_template(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SUPERUSER_PURCHASEB2BSERVICES = gql`
  mutation purchase_b2b_services($office_id: Int!, $service_id: [Int]!) {
    purchase_b2b_services(office_id:$office_id, service_id: $service_id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

// for image & 3D CGI (all image expect 360 and stylng)

export const OFFICE_TEMPLATE_PHOTO_3D_UPLOAD_IMAGES = gql`
  mutation uploadPhotos3dAndPhotos($images: [Upload!], $templateId: Int, $type: PhotoType!) {
    photo_3d_service_media(photos: $images, office_template_id: $templateId, type: $type) {
      id
      media_url
    }
  }
`;

// Upload Images ( stylist iamge)
export const OFFICE_TEMPLATE_STYLING_UPLOAD_IMAGES = gql`
  mutation stylingUploadImages($oldImage: Upload!, $newImage: Upload!, $templateId: Int) {
    styling_service_media(
      old_photo: $oldImage
      new_photo: $newImage
      office_template_id: $templateId
    ) {
      id
      office_template_id
    }
  }
`;

// for single and multiple image deletion (for all image gallery)
export const DELETE_OFFICE_SERVICE_MEDIA = gql`
  mutation deleteServiceMedia($ids: [Int!]!, $templateId: Int) {
    delete_service_media(office_template_id: $templateId, ids: $ids) {
      id
      office_template_id
      media_url
    }
  }
`;

// attach link 360
export const OFFICE_TEMPLATE_PHOTO_360_ATTACH_LINK = gql`
  mutation attach360Link($url: String!, $templateId: Int) {
    view360_service_media(url: $url, office_template_id: $templateId) {
      id
      office_template_id
    }
  }
`;
// for replace photo & 3d (for all except 360/ styling)
export const OFFICE_REPLACE_3D_PHOTO_IMAGE = gql`
  mutation replaceImage($id: Int!, $templateId: Int, $photo: Upload!, $type: PhotoType) {
    replace_photo_3d_media(id: $id, office_template_id: $templateId, photo: $photo, type: $type) {
      id
      office_template_id
    }
  }
`;

// for replace styling image
export const OFFICE_REPLACE_STYLING_IMAGE = gql`
  mutation replaceStyleImage($id: Int!, $templateId: Int, $photo: Upload!, $oldPhoto: Upload!) {
    replace_styling_photo_url(
      id: $id
      office_template_id: $templateId
      old_photo: $oldPhoto
      new_photo: $photo
    ) {
      id
      office_template_id
    }
  }
`;

// oppdrag edits
export const OFFICE_SAVE_OPPDRAG_AGREEMENT = gql`
  mutation saveOppDragAgreement($input: saveOppdragAgreementInput!) {
    save_oppdrag_agreement_edits(input: $input) {
      id
      office_id
    }
  }
`;

export const REMOVE_B2BSERVICES = gql`
  mutation remove_b2b_service($service_subscribe_id: Int!, $office_id: Int!) {
    remove_b2b_service(service_subscribe_id: $service_subscribe_id, office_id:$office_id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ADD_B2C_SERVICE = gql`
  mutation addB2cService($input: saveServiceInput!) {
    add_b2c_service(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const UPDATE_B2C_SERVICE = gql`
  mutation upadateB2cService($id: ID!, $input: updateServiceInput!) {
    update_b2c_service(id: $id, input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const DELETE_B2C_SERVICE = gql`
  mutation deleteB2cService($id: ID!) {
    delete_b2c_service(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const ADD_UTLEGG_SERVICE = gql`
  mutation createUtleggService($input: saveUtleggService!) {
    create_utlegg_service(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const UPDATE_UTLEGG_SERVICE = gql`
  mutation updateUtleggService($id: ID!, $input: saveUtleggService!) {
    update_utlegg_service(id: $id, input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const DELETE_UTLEGG_SERVICE = gql`
  mutation deleteUtleggService($id: ID!) {
    delete_utlegg_service(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const DELETE_MARKETING_PACKAGE = gql`
mutation delete_marketing_package($id: Int!) {
  delete_marketing_package(id: $id) {
    meta {
      status
      message
      code
    }
  }
}

`;

export const ADD_MARKETING_PACKAGE = gql`
mutation create_marketing_package(
  $name: String!
  $price: Decimal
  $description: String
  $image_url: Upload!
  $service_id: [Int!]!
) {
  create_marketing_package(
    name: $name
    price: $price
    description: $description
    image_url: $image_url
    service_id: $service_id
  ) {
    meta {
      status
      message
      code
    }
  }
}
`;

export const EDIT_MARKETING_PACKAGE = gql`
mutation edit_marketing_package(
  $id: Int!
  $name: String!
  $price: Decimal
  $description: String
  $image_url: Upload
  $service_id: [Int]
) {
  edit_marketing_package(
    id: $id
    name: $name
    price: $price
    description: $description
    image_url: $image_url
    service_id: $service_id
  ) {
    meta {
      status
      message
      code
    }
  }
}
`;
