import {StyleSheet} from 'react-native';
import {ThemeUtils,Color} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flexGrow:1,
        backgroundColor:Color.WHITE,
    },
    flexGrow:{
        flexGrow:1
    },
    contentCont:{
        flexGrow:1,
        marginHorizontal: 20,
    },
    icDS: {
        marginVertical: 20,
    },
    lblInst:{
        lineHeight:18,
    },
    lblCont:{
        justifyContent:'center',
        paddingVertical:14,
        backgroundColor:Color.DARK_GRAY,
        borderRadius:4,
        marginVertical:30
    },
    btnApproveCont: {
        flexGrow:1,
        justifyContent:'flex-end',
        marginVertical:20

    }

})
