import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {CheckBox, CustomButton, Header, Label} from "components/src/components";
//Redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {BUYER_HOME_INSURANCE, INSURANCE_LIST} from "components/src/api/buyerQuery";
import {BUYER_ADD_INSURANCE} from "components/src/api/buyerMutation";
//Utils
import {styles} from './styles.js';
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";

const option = [{
    name: "Jeg ønsker å bli kontaktet vedrørende forsikring",
    value: 1
}]


class BuyerFinancing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAgree: [],
            selectedInsuranceType: []
        }
        this.scrollY = new Animated.Value(0)


    }


    validate = (addInsurance) => {
        const {isAgree, selectedInsuranceType} = this.state;

        if (selectedInsuranceType.length) {
            addInsurance({
                variables: {
                    input: {
                        insurance_type: selectedInsuranceType,
                        is_agree: isAgree.includes(1) ? 1 : 0,
                        property_id: this.props.property.id
                    }
                }
            })
        }

    };

    render() {
        return (
            <View style={CommonStyle.container}>

                <Header animatedTitle={Strings.insurance}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={{
                                         flexGrow: 1,
                                         paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2
                                     }}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.labeliconContainer}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'offers-on-insurance'}
                                    color={Color.PRIMARY_DARK}/>

                            </View>
                            <View>
                                <Label small
                                       mt={20}
                                       font_medium
                                       align={'left'}>
                                    {Strings.getBetterHomeInsurance}
                                </Label>
                                <Label
                                    mt={16}
                                    font_regular
                                    mb={18}
                                    small
                                    align={'left'}>{Strings.FinancingInformation}</Label>
                            </View>
                            <Query query={BUYER_HOME_INSURANCE}
                                   variables={{
                                       property_id: this.props.property?.property_id
                                   }}>
                                {(insuranceQuery) => {
                                    if (insuranceQuery?.loading && !insuranceQuery?.data) {
                                        return <ActivityIndicator color={Color.PRIMARY}/>
                                    }
                                    return (
                                        <View style={styles.bottomdownContainer}>

                                            <View style={styles.checkbox}>


                                                <Query query={INSURANCE_LIST}
                                                       variables={{
                                                           status: 1
                                                       }}>
                                                    {({loading, data, error}) => {
                                                        if (loading && !data) {
                                                            return <ActivityIndicator color={Color.PRIMARY}/>
                                                        }
                                                        return (
                                                            <CheckBox options={data?.insurance_type_list ?? []}
                                                                      defaultChecked={insuranceQuery?.data?.buyer_prop_insurance_details?.propInsuranceType.map(item => item.id)}
                                                                      disabled={insuranceQuery?.data?.buyer_prop_insurance_details}
                                                                      style={styles.Financingradio}
                                                                      labelProps={{
                                                                          font_medium: true,
                                                                          small: true
                                                                      }}
                                                                      size={20}
                                                                      onCheckChanged={(val) => this.setState({selectedInsuranceType: val})}/>
                                                        )
                                                    }}
                                                </Query>


                                            </View>


                                            <CheckBox options={option}
                                                      size={ThemeUtils.fontXLarge}
                                                      defaultChecked={[insuranceQuery?.data?.buyer_prop_insurance_details?.is_agree]}
                                                      disabled={insuranceQuery?.data?.buyer_prop_insurance_details}
                                                      labelProps={{
                                                          font_medium: true,
                                                          small: true
                                                      }}
                                                      style={styles.energyradio}
                                                      bold
                                                      onCheckChanged={(val) => this.setState({isAgree: val})}
                                            />

                                            <Mutation mutation={BUYER_ADD_INSURANCE}
                                                      onError={() => {
                                                      }}
                                                      onCompleted={() => IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack()}>
                                                {(addInsurance, {loading}) => (
                                                    <CustomButton loading={loading}
                                                                  disabled={!this.state.isAgree.includes(1) || insuranceQuery?.data?.buyer_prop_insurance_details}
                                                                  mt={18}
                                                                  mb={18}
                                                                  font_medium
                                                                  onPress={() => this.validate(addInsurance)}
                                                                  title={Strings.send}/>
                                                )}
                                            </Mutation>

                                        </View>
                                    )
                                }}
                            </Query>
                        </View>
                    </View>
                </Animated.ScrollView>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BuyerFinancing);
