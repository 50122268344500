const a = {
    Home: 'Home',
    Feed: 'Feed',
    SignIn: 'SignIn',

    EnergyLabeling: 'EnergyLabeling',
    HomeStyling: 'HomeStyling',
    Styling: 'Styling',
    RATE: 'RATE',
    Thankyourate: 'ThankYouRate',
    PhotoService: 'photoService',
    Thankstyling: 'Thankyoustyling',
    ViewDetailsRate: 'ViewDetailsRate',
    FAQ: 'FAQ',
    SellerProfile: 'MyProfile',
    ChangePassword: 'ChangePassword',
    Document: 'document',
    Instructional: 'instructional',
    SalesInformation: 'SalesInformation',
    SettlementInformation: 'SettlementInformation',
    Financing: 'Financing',
    TakeOver: 'TakeOver',
    ViewDetailsStyling: 'ViewDetailsStyling',
    SellerInterview: 'SellerInterview',
    ForgotPassword: 'ForgotPassword',
    ViewSaveRate: 'ViewSaveRate',
    SalesPreparation: 'salesPreparation',
    MarketingMaterials: 'marketingMaterials',
    MarketingMaterialsDetails: 'marketingMaterialsDetails',
    DSAssignment: 'digitalSalesAssignment',
    SeeFinnAd: 'seeFinnAd',
    Display: 'display',
    AddView: 'addView',
    TakstOrderDetails: "TakstOrderDetails",
    PhotoGrapherOrderDetails: 'PhotoGrapherOrderDetails',
    Rewards: 'Rewards',
    Disclaimer: 'Disclaimer',
    InspectionSignIn: 'InspectionSignIn',
    Chat: 'chat',
    Timeline: 'timeline',
    Profile: 'profile',
    Help: 'help',
    ContractSettlements: 'contractSettlements',
    MarketingSales: 'marketingSales',
    MyOrders: 'myOrders',
};

export default {


    PhotoGrapherOrderInfo: 'photograperOrderInfo',
    PhotoGrapherOrderAccepted: 'photographerOrderAccepted',


    StartInspection: 'startInspection',
    Calendar: "kalendar",
    Seller: 'selger',
    Redirection: 'redirection',
    Photographer: 'fotos',
    Admin: 'admin',
    Megler: 'megler',
    Takst: 'takst',
    Stylist: 'stylist',
    Examination: 'undersøkelse',
    Buyer: 'kjøper',
    Login: 'login',
    ForgotPassword: 'glemt-passord',
    FAQ: 'faq',
    Instructional: 'instruksjonsvideoer',
    Photos: 'foto',
    UploadedPhotos: 'foto-bestilling',
    PhotoOrder: 'foto-order',
    BookInspection: 'bokinnlegg',
    EnergyLabeling: 'energimerking',
    Rate: 'vurdere',
    ThankYouRate: 'vurdere-slutte',
    Styling: 'styling',

    MarketingAndSales: 'markedsføringsalg',
    SeeFinnAd: 'sefinn',
    DigitalSalesAssignment: 'digitalsalgsoppgave',
    MarketingMaterials: 'markedsføringsmateriell',
    Display: 'vise',
    MyOrders: 'minbestilling',
    SalesPreparation: 'salgsforberedelser',
    SalesReawards: 'belønninger',
    Chat: 'chat',
    ChatDetails: 'chatDetails',
    Documents: 'dokument',
    EditProfile: 'redigerprofil',
    ChangePassword: 'bytt-passord',
    SalesInfo: 'salgsinformasjon',
    SettlementInfo: 'oppgjørsinformasjon',
    TakeOver: 'overtakelse',
    YourFeedBack: 'dintilbakemelding',
    ContractAndSettlement: 'kontraktogoppgjør',
    Feedback: 'dintilbakemelding',
    Financing: 'finansiering',
    Timeline: 'tidslinje',
    Contact: 'kontakt',
    Finance: 'finansiering',
    Bids: 'bud',
    SellerInterview: 'selgers-intervju',
    SignIn: 'login',
    RegisteredProperties: 'Registrerte eiendommer',
    ContractSettlements: 'kontraktogoppgjør',
    Document: 'dokument',
    SalesInformation: 'salgsinformasjon',
    BuyerMovingGuide: 'flytteveiledning',
    SellerMovingGuide: 'flytteveiledning',
    BuyerDocument: 'dokumenter',
    Rewards: 'belønninger',
    SettlementInformation: 'oppgjørsinformasjon',


    Help: 'hjelp',
    SignUp: 'registrerdeg',

    ThankYouInspection: 'bokinnlegg-slutte',
    ThankYouPhotos: 'foto-slutte',
    ThankYouStyling: 'styling-bekreftet',
    ViewDetailsRate: 'takst-detaljer',
    Disclaimer: 'politikk',
    AppointmentInfo: 'informasjon-om-avtale',
    BuyerFinancing: 'finansiering',
    BuyerInsurance: 'forsikring',
    ContractInfo: 'kontraktsinformasjon',
    InspectionApplication: 'inspeksjonssøknad',
    Dashboard: 'dashboard',

    //Not Described in frontend

    AddView: 'addView',
    AddProperty: 'addProperty',

    MarketingMaterialsDetails: 'marketingMaterialsDetails',
    AddPropertySuccess: 'addPropertySuccess',
    SignAgreement: 'signAgreement',
    SignAgreementCoOwner: 'signAgreementCoOwner',

    ViewDetailsStyling: 'ViewDetailsStyling',

    /*Photo*/

    PhotoGrapherOrderDetails: 'rediger-ordre',
    photoGrapherOrderInfo: 'photographerOrderInfo',
    photoGrapherOrderAccepted: 'photographerOrderAccepted',

    /*Takst*/
    TakstOrderDetails: 'bestillingsinformasjon',
    TakstOrderInfo: 'takstOrderInfo',
    TakstOrderAccepted: 'takstOrderAccepted',

    /*Inspection*/
    InspectionPropertyDetails: 'InspectionPropertyDetails',
    InspectionOrderInfo: 'inspectionOrderInfo',
    InspectionOrderDetails: 'inspectionOrderDetails',
    InspectionOrderAccepted: 'inspectionOrderAccepted',
    AdvertiseExtraRooms: 'advertiseExtraRooms',
    Room: 'room',
    Kitchen: 'kitchen',
    InspectionPhoto: 'inspectionPhoto',
    InspectionBroker: 'inspectionBroker',

    /*Stylist*/
    StylistOrderInfo: 'stylistOrderInfo',
    StylistOrderDetails: 'stylistOrderDetails',
    StylistOrderAccepted: 'stylistOrderAccepted',
    BuyerPurchasingContract: 'buyerPurchasingContract',
    SellerPurchasingContract: 'sellerPurchasingContract',
    BuyerTimeLine: 'buyerTimeLine',
    UploadPhoto: 'uploadPhoto',
    ViewDetailsPhotoService: 'viewDetailsPhotoService',
    SubmitedDetailsRate: 'submitedDetailsRate',
    Notifications:"varsler"


};
