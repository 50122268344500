import React from 'react'
//Components
import {ActivityIndicator, FlatList, ScrollView, TouchableOpacity, View} from 'react-native'

import {CustomButton, Hr, Label, Modal, PopOver, Ripple, Tag,} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {STYLIST_ORDER_LIST} from "components/src/api/stylistQuery";
import {STYLIST_UPDATE_ORDER_INFORMATION} from "components/src/api/stylistMutation";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    encryptId,
    getStatusColor,
    getStatusLabel,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';
import Search from '../Search'
import Filter from '../Filter'
import moment from 'moment'
import {decryptId} from "web/src/utils";


class OrderList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isVisible: false,
            isSearchVisible: false,
            isFilterVisible: false,
            filters: [],
            fromView: null,
            loading: false,
            selectedStatus: null

        }
    }

    navigate = (order) => {
        if (IS_WEB) {
            this.props.history.push(`${Routes.StylistOrderInfo}/${encryptId(order.id)}`, {
                order: order
            })
        } else {
            this.props.navigation.navigate(Routes.StylistOrderInfo, {
                order: order
            })
        }
    };

    navigateToChat = (item) => {
        const {history, location, navigation, property} = this.props;
        if (IS_WEB) {
            history.push(`/${location.pathname.split('/')[1]}/${Routes.Chat}/${encryptId(item?.property_id)}`)
        } else {
            navigation.navigate(Routes.Chat)
        }
    };

    renderOrderItem = ({item, index}) => {
        return (
            <View key={index.toString()}
                  style={CommonStyle.flex}>

                {this.renderOrder({item, index})}
            </View>

        );
    }

    renderOrder = ({item, index}) => {

        let touchable;

        return (
            <TouchableOpacity activeOpacity={0.5}
                              key={index.toString()}
                              onPress={() => this.navigate(item)}>
                <Hr/>
                <View style={{marginTop: 15, marginBottom: 15}}>
                    <View style={styles.topContainer}>
                        <View style={styles.headerCont}>

                            <Tag name={getStatusLabel(item.order_status)}
                                 color={getStatusColor(item.order_status)}/>


                            <View style={styles.headerCont}>
                                <TouchableOpacity onPress={() => this.navigateToChat(item)}
                                                  style={styles.button}>
                                    <View>
                                        <Icon
                                            size={ThemeUtils.fontSmall}
                                            name={'chat'}
                                            color={Color.PRIMARY_DARK}/>
                                    </View>
                                </TouchableOpacity>
                                {item.order_status !== Constants.ORDER_STATUS.PROJECTCOMPLETE ?
                                    (<TouchableOpacity ref={ref => touchable = ref}
                                                       style={styles.button}
                                                       onPress={() => this.showPopover(touchable, item)}>
                                        <View>
                                            <Icon
                                                size={ThemeUtils.fontSmall}
                                                name={'more'}
                                                color={Color.PRIMARY_DARK}/>
                                        </View>
                                    </TouchableOpacity>) : null}
                            </View>
                        </View>
                        <View>
                            <Label small font_medium mt={10} align={'left'}
                                   mb={10}>
                                {`${item?.property?.street_adress ?? ""} ${item?.property?.city ?? ""} ${item?.property?.zipcode ?? ""}`}
                            </Label>
                            {item?.order_status === Constants.ORDER_STATUS.ORDERED ?
                                (
                                    <Label small
                                           mb={20}
                                           font_regular
                                           align={'left'}>
                                        {`${moment(item.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}-${moment(item.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                                    </Label>) : (
                                    <Label small
                                           mb={20}
                                           font_regular
                                           align={'left'}>
                                        {`${moment(item.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                                    </Label>
                                )}
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }


    showPopover = (ref, item) => {
        this.setState({
            isVisible: true,
            fromView: ref,
            selectedStatus: item
        });
    }

    closePopover = () => {
        this.setState({
            isVisible: false,
            fromView: null,
            selectedStatus: null
        });
    }

    renderSearchModal = () => {
        return (
            <Modal visible={this.state.isSearchVisible}>
                <View>
                    <Search onClose={() => this.setState({isSearchVisible: false})}
                            renderItem={this.renderOrder}/>
                </View>
            </Modal>
        )
    };

    renderFilterModal = () => {
        return (
            <Modal visible={this.state.isFilterVisible}>
                <View style={CommonStyle.flex}>
                    <Filter onClose={() => this.setState({isFilterVisible: false})}
                            filters={this.state.filters}
                            onApplyFilter={(val) => {
                                this.setState({
                                    isFilterVisible: false,
                                    filters: val
                                })
                            }}/>
                </View>
            </Modal>
        )
    };

    render() {
        return (
            <Provider locale={enUS}>
                {this.renderSearchModal()}
                {this.renderFilterModal()}
                <ScrollView contentContainerStyle={{flexGrow: 1}}>

                    <View style={styles.container}>
                        <Query query={STYLIST_ORDER_LIST}
                               onError={() => {
                               }}
                               variables={{
                                   input: {
                                       first: 30,
                                       page: 1,
                                       order_status: this.state.filters.toString()
                                   }
                               }}>
                            {({data, refetch, loading, error, fetchMore}) => {
                                if (loading && !data) {
                                    return (<ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>)
                                }
                                return (
                                    <View>
                                        <View style={styles.topContainer}>
                                            <View style={styles.titleContainer}>
                                                <Label xlarge
                                                       font_medium
                                                       style={CommonStyle.flex}
                                                       align={'left'}>
                                                    {Strings.orderlist}
                                                </Label>


                                                <Ripple style={styles.icon}
                                                        onPress={() => this.setState({isSearchVisible: true})}
                                                        rippleContainerBorderRadius={40}>
                                                    <Icon size={ThemeUtils.fontXLarge}
                                                          name={'search'}
                                                          color={Color.PRIMARY_DARK}/>
                                                </Ripple>

                                                <Ripple style={styles.icon}
                                                        onPress={() => this.setState({isFilterVisible: true})}
                                                        rippleContainerBorderRadius={40}>
                                                    <Icon size={ThemeUtils.fontXLarge}
                                                          name={'filter'}
                                                          color={Color.PRIMARY_DARK}/>
                                                </Ripple>

                                            </View>

                                        </View>
                                        <FlatList data={data?.stylish_orders?.data ?? []}
                                                  onRefresh={refetch}
                                                  refreshing={loading}
                                                  renderItem={this.renderOrderItem}
                                                  ListEmptyComponent={
                                                      <View style={CommonStyle.content_center}>
                                                          <Label mt={40}>
                                                              {Strings.noOrder}
                                                          </Label>
                                                      </View>
                                                  }
                                                  ListFooterComponent={
                                                      data?.photo_orders?.paginatorInfo?.hasMorePages ?
                                                          (this.state.loading ? <ActivityIndicator color={Color.PRIMARY}
                                                                                                   style={CommonStyle.loader}/> : (


                                                              <CustomButton bgColor={Color.WHITE}
                                                                            onPress={() => {
                                                                                this.setState({
                                                                                    loading: true
                                                                                })
                                                                                if (!this.state.loading && !loading &&
                                                                                    data?.photo_orders?.paginatorInfo?.hasMorePages) {
                                                                                    fetchMore({
                                                                                        variables: {
                                                                                            input: {
                                                                                                first: 30,
                                                                                                page: parseInt(data?.photo_orders?.paginatorInfo?.currentPage, 10) + 1,
                                                                                                order_status: this.state.filters.toString()
                                                                                            }
                                                                                        },
                                                                                        updateQuery: (prev, {fetchMoreResult}) => {
                                                                                            this.setState({loading: false})
                                                                                            if (!fetchMoreResult) {
                                                                                                return prev;
                                                                                            } else {
                                                                                                let finalResult = Object.assign({}, fetchMoreResult);
                                                                                                finalResult.photo_orders.data = [...prev.photo_orders.data, ...fetchMoreResult.photo_orders.data,];
                                                                                                return finalResult;
                                                                                            }
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }}
                                                                            loading={this.state.loading}
                                                                            ml={ThemeUtils.relativeWidth(30)}
                                                                            mr={ThemeUtils.relativeWidth(30)}
                                                                            loaderColor={Color.PRIMARY}
                                                                            textColor={Color.PRIMARY}
                                                                            title={Strings.loadMore}
                                                                            borderWidth={1}/>

                                                          )) : null
                                                  }/>
                                    </View>
                                )
                            }}
                        </Query>
                        <PopOver
                            isVisible={this.state.isVisible}
                            fromView={this.state.fromView}
                            //   placement={PLACEMENT_OPTIONS.AUTO}
                            onRequestClose={() => this.closePopover()}>

                            <Mutation mutation={STYLIST_UPDATE_ORDER_INFORMATION}
                                      refetchQueries={[{
                                          query: STYLIST_ORDER_LIST,
                                          variables: {
                                              input: {
                                                  first: 30,
                                                  page: 1,
                                                  order_status: this.state.filters.toString()
                                              }
                                          }
                                      }]}
                                      onCompleted={(data) => {
                                          this.setState({
                                              isSearchVisible: false,
                                          })
                                      }}
                                      onError={(e) => {
                                          console.log(e)
                                      }}>
                                {(updateOrder, {data}) => (
                                    <View style={{paddingHorizontal: 10}}>
                                        <TouchableOpacity activeOpacity={0.5}
                                                          onPress={() => {
                                                              updateOrder({
                                                                  variables: {
                                                                      input: {
                                                                          id: this.state.selectedStatus?.id,
                                                                          fields: {
                                                                              order_status: Constants.ORDER_STATUS_SERVER.PROJECTCOMPLETE,
                                                                          },
                                                                      },
                                                                  },
                                                              });
                                                              this.closePopover()
                                                          }}>
                                            <Label small mb={10} mt={10}>{Strings.projectCompleted}</Label>
                                        </TouchableOpacity>
                                    </View>
                                )}
                            </Mutation>

                        </PopOver>

                    </View>
                </ScrollView>
            </Provider>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
