import {EditOutlined} from '@ant-design/icons';
import {Button, Col, Input, Row, Table, Typography} from 'antd';
import React, {useRef,useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {Query} from 'react-apollo';
import {encryptId} from '../../../../utils/utils';
import {ADMIN_ALL_AGENT} from '../../../../../query/AdminQuery';
import '../../../../sass/defaultstyles.scss';
import {formatMessage} from "components/src/utils";
// import data from '../../../../../mock/agentsdata';

const {Title} = Typography;

const {Search} = Input;

export const AllAgents = () => {
    let router = useHistory()
    const [orderData, setOrderData] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [prevPagination, setprevPagination] = useState({});
    const [paginationData, setPaginationData] = useState({
        current: 1,
        pageSize: 30,
        total: 1,
    });

    const timerRef = useRef(null);

    const usePreviousValue = () => {
        const ref = useRef();
        useEffect(() => {
            ref.current = searchKeyword;
        });
        return ref.current;
    };
    const prevValue = usePreviousValue();

    const searchFunc = e => {
        if (prevValue === '') {
            console.log('prevValue;', prevValue);
            setprevPagination(paginationData);
        }
        if (e !== '') {
            setPaginationData({
                current: 1,
                pageSize: 30,
                total: 1,
            });
        } else {
            setPaginationData(prevPagination);
        }
    };

    const handleTableChange = pagination => {
        console.log('pagination:', pagination);
        if (pagination) {
            setPaginationData(pagination);
            setprevPagination(paginationData);
        }
    };

    useEffect(() => {
        if (searchKeyword === '') {
            setprevPagination(paginationData);
        }
    }, []);

    const columns = [
        {
            title: `${formatMessage({id: 'component.table.name'})}`,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            render: text => <a>{text}</a>,
        },
        {
            title: `${formatMessage({id: 'component.table.email'})}`,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => {
                const aSplit = a.email.split('@');
                const bSplit = b.email.split('@');
                return aSplit[0].length - bSplit[0].length;
            },
            render: text => <a>{text}</a>,
        },
        {
            title: `${formatMessage({id: 'component.table.phone'})}`,
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone.length - b.phone.length,
            render: text => <a>{text}</a>,
        },
        {
            title: `${formatMessage({id: 'component.allagents.officename'})}`,
            dataIndex: 'officename',
            key: 'officename',
            render: text => <a>{text}</a>,
        },

        {
            title: `${formatMessage({id: 'component.table.title'})}`,
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.length - b.title.length,
            render: text => <a>{text}</a>,
        },
        {
            title: `${formatMessage({id: 'component.table.action'})}`,
            key: 'action',
            render: name => {
                const itemToSend = orderData.filter(item => name.key === item.id);
                return (
                    <span style={{paddingLeft: 16}}>
            <Link
                to={{
                    pathname: `/admin/allemeglere/meglerdetaljer/${itemToSend.map(i =>
                        encryptId(i.id),
                    )}`,
                    state: {orderItem: itemToSend[0]},
                }}
            >
              <EditOutlined style={{marginRight: 8}}/>
            </Link>
          </span>
                );
            },
        },
    ];

    return (
        <div className="contentshadow">
            <div className="">
                <Row className="photo-order-wrapper">
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Title level={3} className="text-primary">
                            {formatMessage({
                                id: 'component.allagents.title',
                            })}
                        </Title>
                        <p>
                            Her finner du en oversikt over alle registrerte meglere i foretaket. Du kan også legge
                            til nye.
                        </p>
                        <Link to="/admin/allemeglere/leggetilalleagenter">
                            <Button
                                type="primary"
                                className="btn-secondary admin-forms-btn with-lg-spaces"
                                size="large"
                            >
                                {formatMessage({id: 'component.allagents.addallagents'})}
                            </Button>
                        </Link>
                        <Row>
                            <Col xs={24}>
                                <Query
                                    query={ADMIN_ALL_AGENT}
                                    variables={{
                                        input: {
                                            status: true,
                                            first: paginationData.pageSize,
                                            page: paginationData.current,
                                            key: searchKeyword,
                                            sorting: {
                                                field: 'id',
                                                order: 'DESC',
                                            },
                                        },
                                    }}
                                    fetchPolicy="cache-and-network"
                                    onCompleted={responseData => {
                                        console.log('responseData.agent_list:', responseData.agent_list);
                                        setOrderData(responseData.agent_list.data);
                                        if (
                                            responseData &&
                                            responseData.agent_list &&
                                            responseData.agent_list.paginatorInfo
                                        ) {
                                            setPaginationData({
                                                current: responseData.agent_list.paginatorInfo.currentPage,
                                                pageSize: responseData.agent_list.paginatorInfo.perPage,
                                                total: responseData.agent_list.paginatorInfo.total,
                                            });
                                        }
                                        if (responseData && responseData.agent_list && responseData.agent_list.data) {
                                            setAgentList(responseData.agent_list.data);
                                        }
                                        return '';
                                    }}
                                >
                                    {({data, loading, error}) => {
                                        console.log(error);
                                        const agentUser = [];
                                        if (data && data.agent_list.data) {
                                            console.log('insodwe;', data.agent_list.data);
                                            // eslint-disable-next-line array-callback-return
                                            data.agent_list.data.map(item => {
                                                const AgentList = {
                                                    key: item.id,
                                                    name: `${item?.first_name} ${item?.last_name}`,
                                                    officename: item?.office?.name,
                                                    email: item?.email,
                                                    phone: item?.mobile,
                                                    title: item?.title,
                                                };
                                                agentUser.push(AgentList);
                                            });
                                        }
                                        if (!error) {
                                            return (
                                                <>
                                                    <Row>
                                                        <Col lg={12} xs={24}>
                                                            <div className="table-searchbar">
                                                                <Search
                                                                    placeholder={formatMessage({
                                                                        id: 'component.tablesearch.placeholder',
                                                                    })}
                                                                    // className="ant-input-affix-wrapper"
                                                                    onSearch={value => setSearchKeyword(value)}
                                                                    onChange={e => {
                                                                        const searchText = e.target.value;
                                                                        searchFunc(searchText);
                                                                        if (timerRef.current) {
                                                                            clearTimeout(timerRef.current);
                                                                        }
                                                                        timerRef.current = setTimeout(() => {
                                                                            console.log('On Change===>>>', searchText);
                                                                            setSearchKeyword(searchText);
                                                                        }, 1000);
                                                                    }}
                                                                    style={{padding: '8px 15px', marginBottom: '20px'}}
                                                                    className="table-searchbar"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{width: '100%', height: '100%', overflowX: 'auto'}}>
                                                        <div className="unbordered-table-wrapper admin-tables">
                                                            <Table
                                                                columns={columns}
                                                                onChange={handleTableChange}
                                                                dataSource={agentUser}
                                                                loading={loading}
                                                                pagination={{
                                                                    current: paginationData.current,
                                                                    pageSize: paginationData.pageSize,
                                                                    total: paginationData.total,
                                                                    showSizeChanger: true,
                                                                    showTotal: () =>
                                                                        `Total ${paginationData.total} ${formatMessage({
                                                                            id: 'component.table.items',
                                                                        })}`,
                                                                    pageSizeOptions: ['30', '35', '40', '45', '50'],
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }
                                        if (!loading && error) {
                                            return (
                                                <div className="loader-wrapper">Noe gikk galt, vennligst prøv
                                                    igjen.</div>
                                            );
                                        }
                                        return <></>;
                                    }}
                                </Query>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AllAgents;
