/* eslint-disable react-hooks/rules-of-hooks */
import { encryptId } from '../../../../utils/utils';
import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Typography, Spin } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { formatMessage } from "components/src/utils";
import { ADMIN_SUPER_USER_LIST } from '../../../../../query/AdminQuery';
import '../../../../sass/defaultstyles.scss';

const { Title } = Typography;
const { Search } = Input;

export const officecontroller = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderData, setOrderData] = useState([]);
  const [officeControllerData, setOfficeControllerData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [prevPagination, setprevPagination] = useState({});
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 30,
    total: 1,
  });

  const timerRef = useRef(null);

  const usePreviousValue = () => {
    const ref = useRef();
    useEffect(() => {
      ref.current = searchKeyword;
    });
    return ref.current;
  };
  const prevValue = usePreviousValue();

  const searchFunc = e => {
    if (prevValue === '') {
      setprevPagination(paginationData);
    }
    if (e !== '') {
      setPaginationData({
        current: 1,
        pageSize: 30,
        total: 1,
      });
    } else {
      setPaginationData(prevPagination);
    }
  };

  const handleTableChange = pagination => {
    if (pagination) {
      setPaginationData(pagination);
      setprevPagination(paginationData);
    }
  };

  useEffect(() => {
    if (searchKeyword === '') {
      setprevPagination(paginationData);
    }
  }, []);

  const columns = [
    {
      title: `${formatMessage({ id: 'component.table.name' })}`,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.table.email' })}`,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => {
        const aSplit = a.email.split('@');
        const bSplit = b.email.split('@');
        return aSplit[0].length - bSplit[0].length;
      },
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.table.phone' })}`,
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone.length - b.phone.length,
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.allagents.officename' })}`,
      dataIndex: 'officename',
      key: 'officename',
      sorter: (a, b) => a.officename.length - b.officename.length,
      render: text => <a>{text}</a>,
    },
    {
      title: `${formatMessage({ id: 'component.table.action' })}`,
      key: 'action',
      render: name => {
        const itemToSend = orderData.filter(item => name.key === item.id);
        return (
          <span style={{ paddingLeft: 16 }}>
            <Link
              to={{
                pathname: `/admin/fagansvarlig/redigerekontoransvarlig/${encryptId(
                  itemToSend.map(i => i.id),
                )}`,
                state: { orderItem: itemToSend[0] },
              }}
            >
              <EditOutlined style={{ marginRight: 8 }} />
            </Link>
          </span>
        );
      },
    },
  ];

  return (
    <div className="contentshadow">
      <div className="text-primary main">
        <Row className="photo-order-wrapper">
          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
            <Title level={3} className="text-primary">
              {formatMessage({
                id: 'component.officecontroller.title',
              })}
            </Title>
            <p>
              Her finner du en oversikt over registrerte ledere i foretaket. Du kan også legge til
              nye.
            </p>
            <Link to="/admin/fagansvarlig/leggetilkontoransvarlig">
              <Button
                type="primary"
                className="btn-secondary admin-forms-btn with-lg-spaces"
                size="large"
              >
                {formatMessage({ id: 'component.officecontroller.addofficecontroller' })}
              </Button>
            </Link>
            <Row>
              <Col xs={24}>
                <Query
                  query={ADMIN_SUPER_USER_LIST}
                  variables={{
                    input: {
                      access_level: 'CONTROLLER',
                      first: paginationData.pageSize,
                      page: paginationData.current,
                      status: true,
                      key: searchKeyword,
                      sorting: {
                        field: 'id',
                        order: 'DESC',
                      },
                    },
                  }}
                  fetchPolicy="cache-and-network"
                  onCompleted={responseData => {
                    setOrderData(responseData.super_user_list.data);
                    if (
                      responseData &&
                      responseData.super_user_list &&
                      responseData.super_user_list.paginatorInfo
                    ) {
                      setPaginationData({
                        current: responseData.super_user_list.paginatorInfo.currentPage,
                        pageSize: responseData.super_user_list.paginatorInfo.perPage,
                        total: responseData.super_user_list.paginatorInfo.total,
                      });
                    }
                    if (
                      responseData &&
                      responseData.super_user_list &&
                      responseData.super_user_list.data
                    ) {
                      setOfficeControllerData(responseData.super_user_list.data);
                    }
                  }}
                >
                  {({ data, loading, error }) => {
                    console.log(error);
                    const InspectionUserArray = [];
                    if (data && data.super_user_list.data) {
                      // eslint-disable-next-line array-callback-return
                      data.super_user_list.data.map(item => {
                        const InspectionUserList = {
                          key: item.id,
                          title: item.title,
                          name: `${item.first_name} ${item.last_name}`,
                          email: item.email,
                          phone: item.mobile,
                          officename: item.office.name,
                        };
                        InspectionUserArray.push(InspectionUserList);
                      });
                    }
                    if (!error) {
                      return (
                        <>
                          <Row>
                            <Col lg={12} xs={24}>
                              <div className="table-searchbar">
                                <Search
                                  placeholder={formatMessage({
                                    id: 'component.offices.tablesearch',
                                  })}
                                  onSearch={value => setSearchKeyword(value)}
                                  onChange={e => {
                                    const searchText = e.target.value;
                                    searchFunc(searchText);
                                    if (timerRef.current) {
                                      clearTimeout(timerRef.current);
                                    }
                                    timerRef.current = setTimeout(() => {
                                      console.log('On Change===>>>', searchText);
                                      setSearchKeyword(searchText);
                                    }, 2000);
                                  }}
                                  style={{ padding: '8px 15px', marginBottom: '20px' }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div style={{ width: '100%', height: '100%', overflowX: 'auto' }}>
                            <div className="unbordered-table-wrapper admin-tables">
                              <Table
                                columns={columns}
                                dataSource={InspectionUserArray}
                                onChange={handleTableChange}
                                pagination={{
                                  current: paginationData.current,
                                  pageSize: paginationData.pageSize,
                                  total: paginationData.total,
                                  showSizeChanger: true,
                                  showTotal: () =>
                                    `Total ${paginationData.total} ${formatMessage({
                                      id: 'component.table.items',
                                    })}`,
                                  pageSizeOptions: ['30', '35', '40', '45', '50'],
                                }}
                                loading={loading}
                              />
                            </div>
                          </div>
                        </>
                      );
                    }
                    if (!loading && error) {
                      return (
                        <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen.</div>
                      );
                    }
                    return <></>;
                  }}
                </Query>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default officecontroller;
