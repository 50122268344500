import {SET_BUYER_FORM, SET_COLOR, SET_CURRENT_PROPERTY, SET_TOKEN, SET_TOUR_SHOWN, SET_USER,} from './action-types';
import {REHYDRATE} from 'redux-persist/src/constants';

import {LocalColor} from "../utils/Color";

let initial = {
    user: null,
    token: null,
    property:null,
    seller_interview: [],
    colors: LocalColor
};

const reducer = (state = initial, action) => {
    switch (action.type) {
        case SET_USER:
            return Object.assign({}, state, {user: action.user});
        case SET_BUYER_FORM:
            return {...state, ...{seller_interview: action.form}}
        case SET_CURRENT_PROPERTY:
            return {...state, ...{property: action.property}}
        case SET_TOKEN:
            // console.log('TOKEN----------------', action.token);
            return Object.assign({}, state, {token: action.token});
        case SET_TOUR_SHOWN:
            return Object.assign({}, state, {
                tourShown: {
                    ...state.tourShown,
                    ...action.tourShown
                }
            });
        case SET_COLOR:
            return Object.assign({}, state, {colors: action.colors});
        case REHYDRATE:
            return {...state, ...action.payload};
        default:
            return {...state};
    }
};

export default reducer;
