import React from 'react'
import {ActivityIndicator, Animated, View} from 'react-native'
import {Button} from "@ant-design/react-native/lib/button";

import {connect} from "react-redux";
import {Header, Label, SquareButton} from "components/src/components";

import styles from './styles.js';
//Network
import {Mutation, Query} from "@apollo/react-components";

import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SixthStep from "./SixthStep";
import SeventhStep from "./SeventhStep";
import EighthStep from "./EighthStep";
import NinethStep from "./NinethStep";
import {setSellerInterViewForm} from "../../../../../redux/action";
import {SELLER_INTERVIEW_ANSWER} from "../../../../../api/sellerMutation";
import {SELLER_INTERVIEW} from "../../../../../api/sellerQuery";
import Provider from "@ant-design/react-native/lib/provider";
import enUS from "@ant-design/react-native/lib/locale-provider/en_US";
// import {Button, InputItem} from "@ant-design/react-native";


// import { DatePickerView } from 'antd-mobile';
const STEPS = [
    `${Strings.ownerShip}/${Strings.housing}`,
    "Beliggenhet",
    Strings.standard,
    `${Strings.sammie}/ ${Strings.skinAssociation} / ${Strings.veilag} etc`,
    "Rettigheter",
    Strings.vvs,
    Strings.technicalInstallations,
    Strings.appliances,
    Strings.other
];


class SellerInterview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStep: 0,
            answers: {},
            isDataSet: false
        };
        this.scrollY = new Animated.Value(0)
    }

    componentWillUnmount() {
        this.props.setForm([])
    }

    handleChangeText = (id, text) => {
        let answers = {...this.state.answers};
        answers[id] = text;
        this.setState({answers})
    };

    nextStep = (updateAnswer) => {
        updateAnswer({
            variables: {
                input: {
                    last_step: this.state.selectedStep === 8 ? 1 : 0,
                    property_id: this.props.property?.id,
                    answer_json: JSON.stringify(this.props.interViewForm),
                }
            }
        }).then(() => {
            //starts from 0
            if (this.state.selectedStep === 8) {
                IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack()
            }
        }).catch(() => {

        })
        if (this.state.selectedStep < STEPS.length - 1) {
            this.setState(prevState => ({
                selectedStep: prevState.selectedStep + 1
            }), () => {
                this.scroll && this.scroll?._component?.scrollTo({x: 0, y: 0, animated: false})
            })
        }
    };

    prevStep = () => {
        if (this.state.selectedStep > 0) {
            this.setState(prevState => ({
                selectedStep: prevState.selectedStep - 1
            }), () => {
                this.scroll && this.scroll?._component?.scrollTo({x: 0, y: 0, animated: false})
            })
        }
    };

    renderStep = () => {
        switch (this.state.selectedStep) {
            case 0:
                return <FirstStep/>;
            case 1:
                return <SecondStep/>;
            case 2:
                return <ThirdStep/>;
            case 3:
                return <FourthStep/>;
            case 4:
                return <FifthStep/>;
            case 5:
                return <SixthStep/>;
            case 6:
                return <SeventhStep/>;
            case 7:
                return <EighthStep/>;
            case 8:
                return <NinethStep/>

        }
    };

    render() {
        let {selectedStep} = this.state;
        return (
            <View style={CommonStyle.container}>
                <Provider locale={enUS}>

                    <Header animatedTitle={Strings.sellersInterview}
                            navigation={IS_WEB ? this.props.history : this.props.navigation}
                            animatedValue={this.scrollY}/>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         ref={scroll => this.scroll = scroll}
                                         contentContainerStyle={{paddingBottom: ThemeUtils.APPBAR_HEIGHT}}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                             {useNativeDriver: true})}>
                        <View>
                            <Query query={SELLER_INTERVIEW}
                                   fetchPolicy={'network-only'}
                                   onCompleted={(data) => {
                                       console.log("COMEPLTED")
                                       if (data?.moovin_seller_interviews) {
                                           this.props.setForm(JSON.parse(data?.moovin_seller_interviews?.answer_json))
                                           this.setState({
                                               isDataSet: false
                                           })
                                       }
                                   }}
                                   variables={{
                                       status: 1,
                                       propertyId: this.props?.property?.id,
                                   }}>
                                {({loading}) => {
                                    return (
                                        <View style={styles.container}>
                                            <View style={styles.topContainer}>
                                                <View style={styles.labelContainer}>
                                                    <Icon size={ThemeUtils.fontXXLarge}
                                                          name={'seller-interview'}
                                                          color={Color.PRIMARY_DARK}/>
                                                </View>


                                                <Label mt={16}
                                                       small
                                                       align={'left'}>
                                                    {Strings.sellersInterviewInst}
                                                </Label>
                                                {loading && !this.state.isDataSet ? (
                                                    <ActivityIndicator color={Color.PRIMARY}
                                                                       style={CommonStyle.loader}/>
                                                ) : (
                                                    <>
                                                        <View style={styles.labelContainer}>
                                                            <Label font_medium
                                                                   mb={10}
                                                                   align={'left'}>
                                                                {`${selectedStep + 1}. ${STEPS[selectedStep]}`}
                                                            </Label>
                                                        </View>
                                                        {this.renderStep()}
                                                    </>)}
                                            </View>

                                        </View>
                                    )
                                }}
                            </Query>
                        </View>

                    </Animated.ScrollView>
                </Provider>
                <View style={styles.bottomNavCont}>
                    <SquareButton borderColor={Color.LIGHT_GRAY}
                                  style={{flex: 0.5}}
                                  onPress={this.prevStep}
                                  textColor={Color.LIGHT_GRAY}
                                  backgroundColor={Color.TRANSPARENT}>
                        {Strings.prev}
                    </SquareButton>
                    <Label small
                           font_medium
                           align={'center'}
                           style={CommonStyle.flex}
                           color={Color.WHITE}>
                        {`${selectedStep + 1} / ${STEPS.length}`}
                    </Label>
                    <Mutation mutation={SELLER_INTERVIEW_ANSWER}
                              onCompleted={() => {
                              }}
                              onError={() => {
                              }}>
                        {(updateAnswer, {loading, error, data}) => {
                            return (
                                <SquareButton borderColor={Color.LIGHT_GRAY}
                                              style={{flex: 0.5}}
                                              loading={loading}
                                              onPress={() => this.nextStep(updateAnswer)}
                                              textColor={Color.TERNARY_TEXT_COLOR}
                                              backgroundColor={Color.TRANSPARENT}>
                                    {selectedStep === 8 ? Strings.Save : Strings.next}
                                </SquareButton>
                            )
                        }}

                    </Mutation>
                </View>
            </View>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        property: state.property,
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form))
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(SellerInterview);
