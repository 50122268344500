/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import { FileTextOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Carousel,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Table,
  Typography,
} from "antd";
import { formatMessage } from "components/src/utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Mutation, useQuery } from "react-apollo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  CONTACT_BROKER,
  GET_UPDATED,
  SALES_PDF_STATEMENT,
  SIGNUP_FOR_VIEWING,
} from "../../../../mutation/AgentMutation";
import { DS_UNITS } from "../../../../query/AgentQuery";
import {
  formatCurrency,
  handleVideoResponse,
  sorter,
} from "../../../utils/utils";
import {removeHTMLTags} from "../../../utils";
import defaultUser from "../../../assets/default.png";
import { DS_CDN_URL, FRONT_URL } from "../../../utils/constant";

// import './digitalsales.scss';

const { Title } = Typography;

const DigitalSales = (props) => {
  let router = props.history;
  const isContinue = !!(
    props.match.params &&
    props.match.params.id &&
    props.match.params.id
  );
  if (!isContinue) {
    router.replace("/");
  }
  const { loading, data, error } = props;

  useEffect(() => {
    var Tawk_API = Tawk_API || {};
    const Tawk_LoadStart = new Date();
    (function() {
      const s1 = document.createElement("script");
      const s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      // s1.src = 'https://embed.tawk.to/5dcf68d9d96992700fc7b1c1/default';
      s1.src = tawkScript;
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();

    (function() {
      const s1 = document.createElement("script");
      const s0 = document.getElementsByTagName("script")[0];
      s1.textContent = `MwDataLayer = ${JSON.stringify(MwDataLayer)}`;
      s1.id = "mwData";
      s0.parentNode.insertBefore(s1, s0);
    })();
    // (function () {
    //   const s1 = document.createElement("noscript");
    //   const if1 = document.createElement('iframe');
    //   if1.src = "https://www.googletagmanager.com/ns.html?id=GTM-TDCGXPZ";
    //   if1.height = '0';
    //   if1.width = '0';
    //   if1.style = 'display:none;visibility:hidden';
    //   s1.appendChild(if1);
    //   document.head.appendChild(s1);
    // })
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "MwDataLayer", "GTM-TDCGXPZ");
  });
  let doctType;
  let showing;
  let tawkScript;
  let MwDataLayer;
  let docSequence;
  let allImages;
  // old
  // const documentSequence = [44, 1, 5, 11, 10, 52, 40, 28, 9, 46, 24, 45, 16, 17, 43]
  // new
  const documentSequence = [
    44,
    1,
    5,
    11,
    10,
    52,
    40,
    28,
    9,
    46,
    45,
    16,
    17,
    43,
  ];

  const prop = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let carousel = React.createRef();
  let galleryCarousel = React.createRef();

  const next = () => {
    carousel.next();
  };

  const previous = () => {
    carousel.prev();
  };

  const galleryCarouselNext = () => {
    galleryCarousel.next();
  };

  const galleryCarouselPrevious = () => {
    galleryCarousel.prev();
  };

  const columns = [
    {
      title: "Bolig",
      key: "bolig",
      dataIndex: "bolig",
      render: (text) => {
        return (
          <a href={`/eiendom/${text?.oppdragsnummer}`}>
            {text?.apartmentNumber}
          </a>
        );
      },
    },
    {
      title: "Soverom",
      dataIndex: "soverom",
      key: "soverom",
      render: (text) => {
        return (
          <a href={`/eiendom/${text?.oppdragsnummer}`}>{text?.noOfBedRooms}</a>
        );
      },
    },
    {
      title: "Etg",
      dataIndex: "etg",
      key: "etg",
      render: (text) => {
        return <a href={`/eiendom/${text?.oppdragsnummer}`}>{text?.floor}</a>;
      },
    },
    {
      title: "P-rom",
      dataIndex: "prom",
      key: "prom",
      // render: text =>
      //   text ? (
      //     <span>
      //       {text} m<sup>2</sup>
      //     </span>
      //   ) : (
      //     '-'
      //   ),
      render: (text) => {
        return text?.vitecPropertyEstateSize?.primaryRoomArea ? (
          <a href={`/eiendom/${text?.oppdragsnummer}`}>
            {text?.vitecPropertyEstateSize?.primaryRoomArea} m<sup>2</sup>
          </a>
        ) : (
          "-"
        );
      },
    },
    {
      title: "BRA",
      dataIndex: "bra",
      key: "bra",
      // render: text =>
      //   text ? (
      //     <span>
      //       {text} m<sup>2</sup>
      //     </span>
      //   ) : (
      //     '-'
      //   ),

      render: (text) => {
        return text?.vitecPropertyEstateSize?.usableArea ? (
          <a href={`/eiendom/${text?.oppdragsnummer}`}>
            {text?.vitecPropertyEstateSize?.usableArea} m<sup>2</sup>
          </a>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Pris",
      dataIndex: "pris",
      key: "pris",
      // render: text => <span>Kr. {text}</span>,
      render: (text) => {
        return text?.vitecPropertyEstatePrice?.priceSuggestion ? (
          <a href={`/eiendom/${text?.oppdragsnummer}`}>
            Kr.{" "}
            {formatCurrency(text?.vitecPropertyEstatePrice?.priceSuggestion)}
          </a>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Gi Bud",
      dataIndex: "bidLink",
      key: "bidLink",
      render: (text) => {
        return text ? (
          <div className="gi-bud-wrapper">
            <Button
              className="admin-forms-btn bg-secondary"
              size="large"
              type="primary"
            >
              <a href={text} target="_blank" rel="noopener noreferrer nofollow">
                <span className={'text-white'}>Gi Bud</span>
              </a>
            </Button>
          </div>
        ) : null;
      },
    },
  ];

  const [Trigger, setTrigger] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [salesStatement, setSalesStatement] = useState(false);
  const [getUpdated, setGetUpdated] = useState(false);
  const [signUpForm, salesPDF, getUpdatedForm] = Form.useForm();
  const [vitecPropertyId, setVitecPropertyId] = useState(0);
  const [address, setAddress] = useState("");
  const [responsibleBroker, setResponsibleBroker] = useState([]);
  const [videoURL, setVideoURL] = useState([]);
  const [threeDURL, setThreeDURL] = useState([]);
  const [currentImage, setCurrentImage] = useState(1);
  const [viewGallerySlider, setViewGallerySlider] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [contactBroker, setContactBroker] = useState(false);
  const [brokerID, setBrokerID] = useState(0);
  const [calendarModal, setCalendarModal] = useState(false);
  const [currentID, setCurrentID] = useState(0);
  const [unitFirst, setUnitFirst] = useState(10);
  // states for load more

  const [aboutLoadMore, setAboutLoadMore] = useState(true);
  const [innhold, setInnhold] = useState(true);
  const [standard, setStandard] = useState(true);
  const [descriptionItemRenovated, setDescriptionItemRenovated] = useState(
    true
  );
  const [constructionMethod, setConstructionMethod] = useState(true);
  const [adgangForUtleie, setAdgangForUtleie] = useState(true);
  const [diverse, setDiverse] = useState(true);
  const [distinctReservations, setDistinctReservations] = useState(true);
  const [radonmaling, setRadonmaling] = useState(true);
  const [beliggenhet, setBeliggenhet] = useState(true);
  const [parkering, setParkering] = useState(true);
  const [kitchen, setKitchen] = useState(true);
  const [bath, setBath] = useState(true);
  const [cableTelephone, setCabelTelephone] = useState(true);
  const [electricalSystem, setElectricalSystem] = useState(true);
  const [importantInformation, setImportantInformation] = useState(true);
  const [spaceCalculation, setSpaceCalculation] = useState(true);
  const [wardrobe, setWardrobe] = useState(true);
  const [salesCost, setSalesCost] = useState(true);
  const [construction, setConstruction] = useState(true);
  const [facade, setFacade] = useState(true);
  const [roofing, setRoofing] = useState(true);
  const [balconyTerracePatio, setBalconyTerracePatio] = useState(true);
  const [elevators, setElevators] = useState(true);
  const [ventilation, setVentilation] = useState(true);
  const [sanitary, setSanitary] = useState(true);
  const [fireSafety, setFireSafety] = useState(true);
  const [doors, setDoors] = useState(true);
  const [boothSolutions, setBoothSolutions] = useState(true);
  const [surfaces, setSurfaces] = useState(true);
  const [garageDoor, setGarageDoor] = useState(true);
  const [mailboxes, setMailboxes] = useState(true);
  const [doorPhone, setDoorPhone] = useState(true);
  const [oppvarming, setOppvarming] = useState(true);
  const [moneyLaunderingRules, setMoneyLaunderingRules] = useState(true);
  const [options, setOptions] = useState(true);
  const [pricing, setPricing] = useState(true);
  const [financing, setFinancing] = useState(true);
  const [comment, setComment] = useState(true);
  const [jointDebtDescription, setJointDebtDescription] = useState(true);
  const [rentIncludes, setRentIncludes] = useState(true);
  const [legalpant, setLegalpant] = useState(true);
  const [servitutterErklaeringer, setServitutterErklaeringer] = useState(true);
  const [guaranteeFund, setGuranteeFund] = useState(true);
  const [ferdigattest, setFerdigattest] = useState(true);
  const [reguleringsplan, setRegularsPlan] = useState(true);
  const [veiVannAvlop, setVeiVannAvlop] = useState(true);
  const [biddingInfo, setBiddingInfo] = useState(true);
  const [sentraleLoverAsIs, setSentraleLoverAsIs] = useState(true);
  const [eierskifteforsikring, setEierskifteforsikring] = useState(true);
  const [homeOwnersInsurance, setHomeOwnersInsurance] = useState(true);
  const [hvitevarer, setHvitevarer] = useState(true);
  const [paymentConditions, setPaymentConditions] = useState(true);
  const [saleConditions, setSalesConditions] = useState(true);

  const oppdragsnummer = isContinue ? props.match.params.id : "";
  console.log("oppdragsnummer", oppdragsnummer);
  const dsUnits = useQuery(DS_UNITS, {
    variables: {
      input: {
        oppdragsnummer: oppdragsnummer,
        first: unitFirst,
        page: 1,
      },
      skip: !oppdragsnummer,
    },
  });

  useEffect(() => {
    if (data?.ds_property_detail) {
      setVitecPropertyId(data?.ds_property_detail?.id);
      setAddress(data?.ds_property_detail?.streetAdress);
      setResponsibleBroker(
        data?.ds_property_detail?.vitecPropertyEmp?.filter(
          (item) => item?.brokerRole === "2"
        )
      );
      setVideoURL(
        data?.ds_property_detail?.vitecPropertyLinks?.filter(
          (item) => item?.linkType === 1
        )
      );
      setThreeDURL(
        data?.ds_property_detail?.vitecPropertyLinks?.filter(
          (item) => item?.linkType === 2
        )
      );
    }
  }, [data]);

  const renderUnits = () => {
    const boligData = [];
    console.log("ds unit data", dsUnits?.data);
    console.log("ds unit error", dsUnits?.error);
    if (dsUnits?.data && dsUnits?.data?.ds_units?.data?.length > 0) {
      dsUnits?.data?.ds_units?.data.map((item) => {
        // const boligDataItem = {
        //   key: item?.id,
        //   bolig: item,
        //   soverom: item?.noOfBedRooms,
        //   etg: item?.floor,
        //   prom: item?.vitecPropertyEstateSize?.primaryRoomArea,
        //   bra: item?.vitecPropertyEstateSize?.usableArea,
        //   pris: formatCurrency(item?.vitecPropertyEstatePrice?.priceSuggestion),
        // };
        const boligDataItem = {
          key: item?.id,
          bolig: item,
          soverom: item,
          etg: item,
          prom: item,
          bra: item,
          pris: item,
          bidLink: item?.bidLink,
        };
        boligData.push(boligDataItem);
      });
      return (
        <section className="team-sec top-megler-section">
          <div className="detail-container">
            <Row>
              <Col xs={24} lg={{ span: 20, offset: 2 }}>
                <div className="text-center">
                  <h1 className="h1 mtb-36">Boliger</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} lg={{ span: 20, offset: 2 }}>
                <div className="text-center">
                  <div className="unbordered-table-wrapper admin-tables table-responsive">
                    <Table
                      // onChange={handleTableChange}
                      columns={columns}
                      dataSource={boligData}
                      pagination={false}
                      loading={dsUnits?.loading}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {dsUnits?.data?.ds_units?.paginatorInfo?.hasMorePages ? (
              <Row>
                <Col xs={24} lg={{ span: 20, offset: 2 }}>
                  <div className="text-center">
                    <Button
                      className="mt-3 admin-forms-btn bg-secondary ds-modal-btns"
                      size="large"
                      type="primary"
                      loading={dsUnits?.loading}
                      onClick={() => {
                        console.log(
                          "all well",
                          dsUnits?.data?.ds_units?.paginatorInfo?.total
                        ),
                          setUnitFirst(
                            dsUnits?.data?.ds_units?.paginatorInfo?.total
                          );
                      }}
                    >
                      Vis alle boliger
                    </Button>
                  </div>
                </Col>
              </Row>
            ) : null}
          </div>
        </section>
      );
    }
    return <div />;
  };

  const onSignupFinish = (values, signupForViewing) => {
    if (values.private_viewing || values.plan_set || values.is_notify_me) {
      setErrorMsg(false);
      signupForViewing({
        variables: {
          input: {
            vitec_property_id: vitecPropertyId,
            first_name: values.firstname,
            last_name: values.lastname,
            email: values.email,
            phone: values.phone,
            contact_me_private_viewing_time: !!values.private_viewing,
            plan_set_community_shows: !!values.plan_set,
            is_notify_me: !!values.is_notify_me,
          },
        },
      }).then(({ data }) => {
        if (data) {
          if (
            data &&
            data?.signup_for_viewing &&
            data?.signup_for_viewing?.meta
          ) {
            setSignupModal(false);
            notification.success({
              message: data?.signup_for_viewing?.meta?.message,
              description: "",
              duration: 3,
            });
          }
        }
      });
    } else {
      setErrorMsg(true);
    }
  };
  const onSignupFinishFailed = (e) => {
    console.log(e);
    // setErrorMsg(true);
  };

  const onContactFinish = (values, saveContactBroker) => {
    saveContactBroker({
      variables: {
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        phone: values.mobile,
        address,
        oppdragnummer: oppdragsnummer,
        brokerID: brokerID,
      },
    }).then(({ data }) => {
      if (data) {
        if (data && data?.contact_broker && data?.contact_broker?.meta) {
          setContactBroker(false);
          notification.success({
            message: data?.contact_broker?.meta?.message,
            description: "",
            duration: 3,
          });
        }
      }
    });
  };

  const onContactFinishFailed = (e) => {
    console.log(e);
  };

  const onSalesAssignmentFinish = (values, salesPDFSave) => {
    if (
      values.send_info_about_property ||
      values.bid_notification ||
      values.get_update_similar_homes ||
      values.receive_our_newsletter
    ) {
      setErrorMsg(false);
      salesPDFSave({
        variables: {
          input: {
            vitec_property_id: vitecPropertyId,
            first_name: values.firstname,
            last_name: values.lastname,
            email: values.email,
            phone: values.phone,
            send_info_about_property: !!values.send_info_about_property,
            bid_notification: !!values.bid_notification,
            get_update_similar_homes: !!values.get_update_similar_homes,
            receive_our_newsletter: !!values.receive_our_newsletter,
          },
        },
      }).then(({ data }) => {
        if (data) {
          if (
            data &&
            data?.sales_statement_pdf &&
            data?.sales_statement_pdf?.meta
          ) {
            setSalesStatement(false);
            notification.success({
              message: data?.sales_statement_pdf?.meta?.message,
              description: "",
              duration: 3,
            });
            window.open(`${DS_CDN_URL}${doctType?.documentUrl}`, "_blank");
          }
        }
      });
    } else {
      setErrorMsg(true);
    }
  };
  const onSalesAssignmentFinishFailed = () => {
    // console.log(e);
    setErrorMsg(true);
  };

  const onGetUpdatedFinish = (values, getUpdatedSave) => {
    if (
      values.send_info_about_property ||
      values.bid_notification ||
      values.get_update_similar_homes ||
      values.receive_our_newsletter
    ) {
      setErrorMsg(false);
      getUpdatedSave({
        variables: {
          input: {
            vitec_property_id: vitecPropertyId,
            first_name: values.firstname,
            last_name: values.lastname,
            email: values.email,
            phone: values.phone,
            send_info_about_property: !!values.send_info_about_property,
            bid_notification: !!values.bid_notification,
            get_update_similar_homes: !!values.get_update_similar_homes,
            receive_our_newsletter: !!values.receive_our_newsletter,
          },
        },
      }).then(({ data }) => {
        if (data) {
          if (data && data?.get_updated && data?.get_updated?.meta) {
            setGetUpdated(false);
            notification.success({
              message: data?.get_updated?.meta?.message,
              description: "",
              duration: 3,
            });
          }
        }
      });
    } else {
      setErrorMsg(true);
    }
  };
  const onGetUpdatedFinishFailed = () => {
    // console.log(e);
    setErrorMsg(true);
  };

  const handleViewImage = (imgId) => {
    document.body.classList.add("overflow-hidden");
    const getIndexNo = allImages.findIndex((item) => item.id === imgId);
    setCurrentImage(getIndexNo);
    setViewGallerySlider(true);
  };

  const handleGalleryClose = () => {
    document.body.classList.remove("overflow-hidden");
    setCurrentImage(1);
    setViewGallerySlider(false);
  };

  const renderDS = () => {
    if (data && !loading) {
      if (data.ds_property_detail) {
        allImages = data.ds_property_detail?.vitecPropertyImages;
        const imgArray =
          data.ds_property_detail.vitecPropertyImages &&
          data.ds_property_detail.vitecPropertyImages.length > 0 &&
          data.ds_property_detail.vitecPropertyImages
            .filter(
              (item) =>
                item.imageCategoryName > 2 && item.imageCategoryName <= 9
            )
            .sort(sorter("imageCategoryName"));
        const firstImage =
          data.ds_property_detail.vitecPropertyImages?.length > 0 &&
          data.ds_property_detail.vitecPropertyImages.filter(
            (item) => item.imageCategoryName === "1"
          );
        console.log(firstImage);
        const secondImage =
          data.ds_property_detail.vitecPropertyImages &&
          data.ds_property_detail.vitecPropertyImages.length > 0 &&
          data.ds_property_detail.vitecPropertyImages.filter(
            (item) => item.imageCategoryName === "2"
          );
        doctType =
          data.ds_property_detail.vitecPropertyDocument &&
          data.ds_property_detail.vitecPropertyDocument.find(
            (item) => item.docType === 43
          );
        showing =
          data.ds_property_detail.vitecPropertyShowing &&
          data.ds_property_detail.vitecPropertyShowing.length > 0 &&
          data.ds_property_detail.vitecPropertyShowing.filter((item) => {
            const expireDateObj = moment(item.startDate, "YYYY-MM-DD hh:mm:ss");
            return moment().isBefore(expireDateObj);
          });
        tawkScript = data.ds_property_detail.office?.widget_code;
        MwDataLayer = [
          {
            CaseNo: data.ds_property_detail.departmentId,
            ShopNo: oppdragsnummer,
          },
        ];
        docSequence =
          data.ds_property_detail.vitecPropertyDocument.length > 0 &&
          documentSequence.map((item) =>
            data.ds_property_detail.vitecPropertyDocument.filter(
              (subitem) => subitem.docType === item
            )
          );
        console.log(docSequence);
        return (
          <>
            {firstImage.length > 0 ? (
              <section
                className="section-header blackOverlayBG"
                style={{
                  backgroundImage:
                    firstImage &&
                    firstImage.length > 0 &&
                    `url(${DS_CDN_URL}${firstImage[0]?.imageUrl}) `,
                }}
              >
                <div className="introTextBox">
                  <div className="leftLineBox">
                    <span className="line primelinecolor" />
                  </div>
                  <h3 className="prime_hover_txt_1">
                    {data?.ds_property_detail?.heading}
                  </h3>
                  <div className="subBox">
                    <p className="prime_hover_txt_3">
                      W Eiendomsmegling presenterer{" "}
                      {`${
                        data.ds_property_detail.apartmentNumber
                          ? `${data.ds_property_detail.streetAdress} ${
                              data.ds_property_detail.apartmentNumber
                            }.`
                          : `${data.ds_property_detail.streetAdress}.`
                      }`}{" "}
                      Din nye bolig.
                    </p>
                  </div>
                  {data.ds_property_detail.vitecPropertyAds &&
                    data.ds_property_detail.vitecPropertyAds
                      .estateStatus_text === "Sold" && (
                      <div className="subBox">
                        <span className="text-sold">
                          {data.ds_property_detail.vitecPropertyAds &&
                            data.ds_property_detail.vitecPropertyAds
                              .estateStatus_text === "Sold" &&
                            "SOLGT"}
                        </span>
                      </div>
                    )}
                </div>
              </section>
            ) : (
              <section
                className="section-header blackOverlayBG"
                style={{
                  backgroundImage:
                    data.ds_property_detail.vitecPropertyImages &&
                    `url(${DS_CDN_URL}${
                      data.ds_property_detail.vitecPropertyImages[0]?.imageUrl
                    }) `,
                }}
              >
                <div className="introTextBox">
                  <div className="leftLineBox">
                    <span className="line primelinecolor" />
                  </div>
                  <h3 className="prime_hover_txt_1">
                    {data.ds_property_detail.heading}
                  </h3>
                  <div className="subBox">
                    <p className="prime_hover_txt_3">
                      W Eiendomsmegling presenterer
                      {` ${data.ds_property_detail.streetAdress}`}. Din nye
                      bolig.
                    </p>
                  </div>
                  {data.ds_property_detail.vitecPropertyAds &&
                    data.ds_property_detail.vitecPropertyAds
                      .estateStatus_text === "Sold" && (
                      <div className="subBox">
                        <span className="text-sold">
                          {data.ds_property_detail.vitecPropertyAds &&
                            data.ds_property_detail.vitecPropertyAds
                              .estateStatus_text === "Sold" &&
                            "SOLGT"}
                        </span>
                      </div>
                    )}
                </div>
              </section>
            )}
            {secondImage.length > 0 ? (
              <section className="section-arrival">
                <div
                  className="img1stBox"
                  style={{
                    backgroundImage:
                      secondImage &&
                      secondImage.length > 0 &&
                      `url(${DS_CDN_URL}${secondImage[0].imageUrl}) `,
                  }}
                  onClick={() => {
                    handleViewImage(secondImage[0].id);
                  }}
                />
              </section>
            ) : data?.ds_property_detail?.vitecPropertyImages[1] ? (
              <section className="section-arrival">
                <div
                  className="img1stBox"
                  style={{
                    backgroundImage:
                      data.ds_property_detail.vitecPropertyImages &&
                      `url(${DS_CDN_URL}${
                        data.ds_property_detail.vitecPropertyImages[1]?.imageUrl
                      })`,
                  }}
                  onClick={() => {
                    handleViewImage(
                      data.ds_property_detail.vitecPropertyImages[1]?.id
                    );
                  }}
                />
              </section>
            ) : null}

            <div className="ds-stats-box">
              <Row>
                {/* for offset */}
                <Col xs={24} lg={{ span: 24 }}>
                  <Row>
                    <Col xs={24} lg={22}>
                      <div className="stats-container">
                        {data?.ds_property_detail?.pris?.min > 0 &&
                        data?.ds_property_detail?.pris?.max > 0 ? (
                          <div className="stats-card">
                            <h5 className="no-margin">Prisantydning:</h5>
                            <h2 className="no-margin text-right">
                              {formatCurrency(
                                data?.ds_property_detail?.pris?.min
                              )}{" "}
                              &ndash;{" "}
                              {formatCurrency(
                                data?.ds_property_detail?.pris?.max
                              )}
                              ,-
                            </h2>
                          </div>
                        ) : null}
                        {data?.ds_property_detail?.totalpris?.min > 0 &&
                        data?.ds_property_detail?.totalpris?.max > 0 ? (
                          <div className="stats-card">
                            <h5 className="no-margin">Totalpris:</h5>
                            <h2 className="no-margin text-right">
                              {formatCurrency(
                                data?.ds_property_detail?.totalpris?.min
                              )}{" "}
                              &ndash;{" "}
                              {formatCurrency(
                                data?.ds_property_detail?.totalpris?.max
                              )}
                              ,-
                            </h2>
                          </div>
                        ) : null}
                        {data?.ds_property_detail?.ownershipType ===
                          "Selveier" && (
                          <>
                            {data?.ds_property_detail?.tomteareal.min &&
                            data?.ds_property_detail?.tomteareal.max ? (
                              <div className="stats-card">
                                <h5 className="no-margin">Tomteareal:</h5>
                                <h2 className="no-margin text-right">
                                  {formatCurrency(
                                    data?.ds_property_detail?.tomteareal?.min
                                  )}{" "}
                                  &ndash;{" "}
                                  {formatCurrency(
                                    data?.ds_property_detail?.tomteareal.max
                                  )}
                                </h2>
                              </div>
                            ) : null}
                          </>
                        )}
                        {data?.ds_property_detail?.p_rom?.min > 0 &&
                        data?.ds_property_detail?.p_rom?.max > 0 ? (
                          <div className="stats-card">
                            <h5 className="no-margin">P-rom:</h5>
                            {data?.ds_property_detail?.p_rom?.min ===
                            data?.ds_property_detail?.p_rom?.max ? (
                              <h2 className="no-margin">
                                {data?.ds_property_detail?.p_rom?.min} m
                                <sup>2</sup>
                              </h2>
                            ) : (
                              <h2 className="no-margin text-right">
                                {data?.ds_property_detail?.p_rom?.min} &ndash;{" "}
                                {data?.ds_property_detail?.p_rom?.max}m
                                <sup>2</sup>
                              </h2>
                            )}
                          </div>
                        ) : null}
                        {data?.ds_property_detail?.soverom?.min > 0 &&
                        data?.ds_property_detail?.soverom?.max > 0 ? (
                          <div className="stats-card">
                            <h5 className="no-margin">Soverom:</h5>
                            {data?.ds_property_detail?.soverom?.min ===
                            data?.ds_property_detail?.soverom?.max ? (
                              <h2 className="no-margin">
                                {data?.ds_property_detail?.soverom?.min}
                              </h2>
                            ) : (
                              <h2 className="no-margin text-right">
                                {data?.ds_property_detail?.soverom?.min} &ndash;{" "}
                                {data?.ds_property_detail?.soverom?.max}
                              </h2>
                            )}
                          </div>
                        ) : null}
                        {/* {data?.ds_property_detail?.estateType ? (
                          <div className="stats-card">
                            <h5 className="no-margin">Boligtype:</h5>
                            <h2 className="no-margin">{data?.ds_property_detail?.estateType}</h2>
                          </div>
                        ) : null} */}
                        {data?.ds_property_detail?.ownershipType ? (
                          <div className="stats-card">
                            <h5 className="no-margin">Eierform:</h5>
                            <h2 className="no-margin text-right">
                              {data?.ds_property_detail?.ownershipType}
                            </h2>
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    {/* {data.ds_property_detail.bidLink && (
                      <Col xs={24} lg={2}>
                        <div className="gi-bud-wrapper">
                          <Button
                            className="mt-3 admin-forms-btn bg-secondary"
                            size="large"
                            type="primary"
                          >
                            <a
                              href={data.ds_property_detail.bidLink}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              <span>Gi Bud</span>
                            </a>
                          </Button>
                        </div>
                      </Col>
                    )}*/}
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="container-box-ds">
              <Row gutter={32}>
                <Col xs={24} lg={{ span: 10, offset: 2 }}>
                  <div className="onesp-caption">
                    <h1>
                      W Eiendomsmegling ved{" "}
                      {data.ds_property_detail.vitecPropertyEmp.map(
                        (item, index) =>
                          item.brokerRole !== "2" &&
                          item.brokerRole !== "4" &&
                          `${(index > 1 ? " og " : "") + item.name}`
                      )}{" "}
                      har gleden av å presentere{" "}
                      {data.ds_property_detail.streetAdress}
                    </h1>
                    <h1 className="onesp-captionh1">Visninger:</h1>
                    {showing && showing.length > 0 ? (
                      showing.map((item, index) => (
                        <div
                          key={Math.random()}
                          className="time-slot visining_text"
                          onClick={() => {
                            setCalendarModal(true);
                            setCurrentID(index);
                          }}
                        >
                          <div className="flex-wrapper">
                            <p className="time-detail no-margin">
                              <span>
                                {moment(
                                  item.startDate,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("DD-MM-YYYY")}{" "}
                                kl{" "}
                                {moment(
                                  item.startDate,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("HH:mm")}{" "}
                                til{" "}
                                {moment(
                                  item.endDate,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("HH:mm")}
                              </span>
                            </p>
                            <span className="ml-2">
                              <i className="icon-calendar" />
                            </span>
                          </div>
                          <p className="visining_text_footer">
                            Klikk på visningen for å legge til i kalenderen din
                          </p>
                        </div>
                      ))
                    ) : (
                      <p className="visining_text">
                        Det er ikke satt opp tidspunkt for fellesvisninger.
                        Kontakt megler for privatvisning.
                      </p>
                    )}
                    <div className="team-btn-sec visining-buttons">
                      <Row gutter={32}>
                        <Col xs={24} md={12} lg={12}>
                          <Button
                            className="w-100 mt-3 admin-forms-btn bg-secondary ds-modal-btns"
                            size="large"
                            type="primary"
                            onClick={() => setSignupModal(true)}
                          >
                            Meld deg på visning
                          </Button>
                        </Col>
                        {data.ds_property_detail.vitecPropertyDocument &&
                          data.ds_property_detail.vitecPropertyDocument.length >
                            0 &&
                          doctType && (
                            <Col xs={24} md={12} lg={12}>
                              <Button
                                className="w-100 mt-3 admin-forms-btn bg-secondary ds-modal-btns"
                                size="large"
                                type="primary"
                                onClick={() => setSalesStatement(true)}
                              >
                                Salgsoppgave PDF
                              </Button>
                            </Col>
                          )}
                        <Col xs={24} md={12} lg={12}>
                          <Button
                            className="w-100 mt-3 admin-forms-btn bg-secondary ds-modal-btns"
                            size="large"
                            type="primary"
                            onClick={() => setGetUpdated(true)}
                          >
                            Bli oppdatert
                          </Button>
                        </Col>
                        {data.ds_property_detail.bidLink && (
                          <Col xs={24} md={12} lg={12}>
                            {/* <Button
                                        className="w-100 mt-3 admin-forms-btn bg-secondary ds-modal-btns gibud"
                                        size="large"
                                        type="primary"
                                      >
                                        <a
                                          href={data.ds_property_detail.bidLink}
                                          target="_blank"
                                          rel="noopener noreferrer nofollow"
                                        >
                                          <span>Gi Bud</span>
                                        </a>
                                      </Button> */}
                            <Button
                              className="w-100 mt-3 admin-forms-btn bg-secondary ds-modal-btns gibud"
                              size="large"
                              type="primary"
                            >
                              <a href="#section-vedlegg">
                                <span>Vedlegg</span>
                              </a>
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </Col>
                {data?.ds_property_detail?.vitecPropertyProject &&
                data?.ds_property_detail?.vitecPropertyProject
                  ?.vitecPropertyProjectTextfield &&
                data?.ds_property_detail?.vitecPropertyProject
                  ?.vitecPropertyProjectTextfield
                  .vitecPropertyProjectTextfieldDetail &&
                data?.ds_property_detail?.vitecPropertyProject
                  ?.vitecPropertyProjectTextfield
                  .vitecPropertyProjectTextfieldDetail?.aboutTheProject ? (
                  <Col xs={24} lg={12}>
                    <div className="onesp-copy">
                      <h1>
                        {
                          data.ds_property_detail?.vitecPropertyProject
                            ?.projectName
                        }
                      </h1>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield.vitecPropertyProjectTextfieldDetail?.aboutTheProject.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br />"
                          ),
                        }}
                      />
                    </div>
                    {data?.ds_property_detail?.vitecPropertyLinks?.map((item) =>
                      item?.linkType === 3 ? (
                        <Button
                          className="mt-3 admin-forms-btn bg-secondary ds-modal-btns"
                          size="large"
                          type="primary"
                        >
                          <a
                            href={item?.url}
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                          >
                            <span>Prosjektets webside</span>
                          </a>
                        </Button>
                      ) : null
                    )}
                  </Col>
                ) : null}
              </Row>
            </div>
            {renderUnits()}
            {/* image sections */}
            {imgArray &&
              imgArray.length > 0 &&
              imgArray.map((item, index) => (
                <>
                  {parseInt(item.imageCategoryName, 10) % 2 === 0 ? (
                    <div className="section-without-image onesp-row-above">
                      <Row gutter={32} align="top">
                        <Col xs={24} md={{ span: 14, offset: 2 }}>
                          <div className="onsp-image-wrapper">
                            <div
                              className={
                                item.imageCategoryName === "6"
                                  ? `section-${
                                      item.imageCategoryName
                                    }-image img-6 img1stBox`
                                  : `section-${
                                      item.imageCategoryName
                                    }-image onsp-image-div`
                              }
                              // style={item.imageCategoryName === '6' ?
                              //   { backgroundImage: `url(${DS_CDN_URL}${item.imageUrl})` } : {
                              //     backgroundImage: `url(${DS_CDN_URL}${item.imageUrl}) `,
                              //     transition: 'transform 3s',
                              //   }}
                              onClick={() => {
                                handleViewImage(item.id);
                              }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                className="img-fluid img-responsive"
                                src={`${DS_CDN_URL}${item.imageUrl}`}
                                alt={item.imageDescription}
                              />
                              {/* <img src={`${DS_CDN_URL}${item.imageUrl}`} {item.imageDescription} className="img-fluid img-responsive" /> */}
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} md={6}>
                          <div className="onesp-copy">
                            <h2 />
                            <p>{item.imageDescription}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : item.imageCategoryName === "5" ||
                    item.imageCategoryName === "9" ? (
                    <div
                      className={
                        item.imageCategoryName === "9"
                          ? "section-without-image onesp-row-above full-img mb-5"
                          : "section-without-image onesp-row-above full-img"
                      }
                    >
                      <Row gutter={32}>
                        <Col xs={24} lg={{ span: 22, offset: 1 }}>
                          <div className="onsp-image-wrapper">
                            <div
                              className={`section-${
                                item.imageCategoryName
                              }-image onsp-image-div`}
                              // style={{
                              //   backgroundImage: `url(${DS_CDN_URL}${item.imageUrl}) `,
                              //   transition: 'transform 3s',
                              // }}
                              onClick={() => {
                                handleViewImage(item.id);
                              }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                className="img-fluid img-responsive"
                                src={`${DS_CDN_URL}${item.imageUrl}`}
                                alt={item.imageDescription}
                              />

                              {/* <img src={`${DS_CDN_URL}${item.imageUrl}`} alt={item.imageDescription} className="img-fluid img-responsive" /> */}
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} lg={{ span: 8, offset: 14 }}>
                          <div className="onesp-copy mt-5">
                            <h2 />
                            <p>{item.imageDescription}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="section-without-image onesp-row-above">
                      <Row gutter={32}>
                        <Col xs={24} md={{ span: 8, offset: 2 }} lg={6}>
                          <div className="onesp-copy text-right">
                            <h2 />
                            <p>{item.imageDescription}</p>
                          </div>
                        </Col>
                        <Col xs={24} md={14} lg={14}>
                          <div className="onsp-image-wrapper">
                            <div
                              className={`section-${
                                item.imageCategoryName
                              }-image onsp-image-div`}
                              // style={{
                              //   backgroundImage: `url(${DS_CDN_URL}${item.imageUrl}) `,
                              //   transition: 'transform 3s',
                              // }}
                              onClick={() => {
                                handleViewImage(item.id);
                              }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                className="img-fluid img-responsive"
                                src={`${DS_CDN_URL}${item.imageUrl}`}
                                alt={item.imageDescription}
                              />
                              {/* <img src={`${DS_CDN_URL}${item.imageUrl}`} alt={item.imageDescription} className="img-fluid img-responsive" /> */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </>
              ))}
            {viewGallerySlider && (
              <div className="gallery-box-container">
                <div className="gallery-box-wrapper">
                  <div className="gallery-close-btn-box">
                    <div
                      className="close-btn-div"
                      onClick={() => {
                        handleGalleryClose(false);
                      }}
                    >
                      <i className="icon-close" />
                    </div>
                  </div>
                  <div className="">
                    <Row
                      className="slider_wrapper gallery_slider"
                      align="middle"
                    >
                      <Col
                        xs={24}
                        lg={{ span: 22, offset: 1 }}
                        className="slider-column gallery-slider-column ds-slider"
                      >
                        <div className="img-preview-slider">
                          <div
                            className="gallery-arrow-box right-arrow"
                            onClick={() => {
                              galleryCarouselNext();
                            }}
                          >
                            <i className="icon-arrow-r" />
                          </div>
                          <Carousel
                            initialSlide={currentImage}
                            // afterChange={onChange}
                            className="slider-carousel"
                            ref={(node) => {
                              galleryCarousel = node;
                            }}
                            afterChange={(index) => {
                              // eslint-disable-next-line no-unused-expressions
                              setCurrentImage(index);
                            }}
                            {...prop}
                          >
                            {data.ds_property_detail.vitecPropertyImages.map(
                              (item) => (
                                <div className="gallery-img-container">
                                  <LazyLoadImage
                                    effect="blur"
                                    className="property-img-display"
                                    src={`${DS_CDN_URL}${item.imageUrl}`}
                                    alt="DS Property"
                                  />

                                  {/* <LazyImage
                                            src={`${DS_CDN_URL}${item.imageUrl}`}
                                            alt="DS Property"
                                            className="property-img-display"
                                            placeholder={({ imageProps, ref }) => (
                                              <img ref={ref} src={placeholderImg} alt={imageProps.alt} />
                                            )}
                                            actual={({ imageProps }) => <img alt={item.imageDescription} {...imageProps} />}
                                          /> */}

                                  {/* <img
                                            src={`${DS_CDN_URL}${item.imageUrl}`}
                                            alt="DS Property"
                                            className="property-img-display"
                                          /> */}
                                </div>
                              )
                            )}
                          </Carousel>
                          <div
                            className="gallery-arrow-box left-arrow"
                            onClick={() => {
                              galleryCarouselPrevious();
                            }}
                          >
                            <i className="icon-arrow-l" />
                          </div>
                        </div>
                        <div className="slider-footer-dot">
                          <div>
                            {currentImage < 9
                              ? `0${currentImage + 1}`
                              : currentImage + 1}
                          </div>
                          <div className="dot-body" />
                          <div className="total-count">
                            {data.ds_property_detail.vitecPropertyImages
                              .length <= 9
                              ? `0${
                                  data.ds_property_detail.vitecPropertyImages
                                    .length
                                }`
                              : data.ds_property_detail.vitecPropertyImages
                                  .length}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            {/* video section */}
            {data.ds_property_detail.video || videoURL.length > 0 ? (
              data.ds_property_detail.video ? (
                <section
                  className="onesp-row-above detail-container my-48"
                  id="section-bildegalleri"
                >
                  <Row>
                    <Col xs={24}>
                      <div
                        style={{
                          padding: "56.25% 0 0 0",
                          position: "relative",
                        }}
                      >
                        {data.ds_property_detail.video?.video_url
                          .toLowerCase()
                          .indexOf("youtube") > 0 ? (
                          <iframe
                            width="100%"
                            src={handleVideoResponse(
                              data.ds_property_detail.video?.video_url
                            )}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                            }}
                            title={data.ds_property_detail.streetAdress}
                          />
                        ) : (
                          <iframe
                            src={handleVideoResponse(
                              data.ds_property_detail.video?.video_url
                            )}
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                            }}
                            title={data.ds_property_detail.streetAdress}
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen=""
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </section>
              ) : (
                <section
                  className="onesp-row-above detail-container my-48 video-section"
                  id="section-bildegalleri"
                >
                  <Row>
                    <Col xs={24}>
                      <div
                        style={{
                          padding: "56.25% 0 0 0",
                          position: "relative",
                        }}
                      >
                        {videoURL[0]?.url.toLowerCase().indexOf("youtube") >
                        0 ? (
                          <iframe
                            width="100%"
                            src={handleVideoResponse(videoURL[0]?.url)}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                            }}
                            title={data.ds_property_detail.streetAdress}
                          />
                        ) : (
                          <iframe
                            src={handleVideoResponse(videoURL[0]?.url)}
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                            }}
                            title={data.ds_property_detail.streetAdress}
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen=""
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </section>
              )
            ) : null}
            {/* three d url */}
            {data.ds_property_detail.view360 || threeDURL.length > 0 ? (
              data.ds_property_detail.view360 ? (
                <section
                  className="onesp-row-above detail-container my-48"
                  id="section-bildegalleri"
                >
                  <Row>
                    <Col xs={24}>
                      <div>
                        <iframe
                          webkitallowfullscreen
                          mozallowfullscreen
                          allowFullScreen
                          src={data.ds_property_detail.view360?.video_url}
                          title={data.ds_property_detail.streetAdress}
                          className="iframe-class"
                        />
                      </div>
                    </Col>
                  </Row>
                </section>
              ) : (
                <section
                  className="onesp-row-above detail-container my-48"
                  id="section-bildegalleri"
                >
                  <Row>
                    <Col xs={24}>
                      <div>
                        <iframe
                          webkitallowfullscreen
                          mozallowfullscreen
                          allowFullScreen
                          src={threeDURL[0]?.url}
                          title={data.ds_property_detail.streetAdress}
                          className="iframe-class"
                        />
                      </div>
                    </Col>
                  </Row>
                </section>
              )
            ) : null}
            {/* photo gallery */}
            <section
              className="onesp-row-above detail-container"
              id="section-bildegalleri"
            >
              <Row>
                <Col xs={24}>
                  <h1 className="h1 text-center">BILDEGALLERI</h1>
                </Col>
              </Row>
              <Row
                id="#sync1"
                className="slider_wrapper ds_slider_wrapper"
                align="middle"
              >
                <div className="arrow-box left" onClick={previous}>
                  <i className="icon-arrow-l" />
                </div>
                <Col
                  xs={24}
                  lg={{ span: 22, offset: 1 }}
                  className="slider-column"
                >
                  {/*<Carousel
                    // afterChange={onChange}
                    className="slider-carousel"
                    ref={(node) => {
                      carousel = node;
                    }}
                    {...prop}
                    // autoplay
                  >
                    {data.ds_property_detail.vitecPropertyImages.map(
                      (item, index) => (
                        <div className="img-container ds-img-container">
                          <LazyLoadImage
                            effect="blur"
                            src={`${DS_CDN_URL}${item.imageUrl}`}
                            alt="DS Property"
                            onClick={() => handleViewImage(item.id)}
                          />

                           <img src={`${DS_CDN_URL}${item.imageUrl}`} alt="DS Property" />
                        </div>
                      )
                    )}
                  </Carousel>*/}
                  <Carousel
                      // afterChange={onChange}
                      adaptiveHeight
                      className="slider-carousel"
                      ref={(node) => {
                        carousel = node;
                      }}
                      {...prop}
                      // autoplay
                  >
                    {data?.ds_property_detail?.vitecPropertyImages?.sort((a,b)=>a.imageSequence-b.imageSequence)?.map(
                        (item, index) => (
                            <div className="img-container-2 ds-img-container"
                                 onClick={() => handleViewImage(item.id)}>
                              {/* <LazyLoadImage
                                                                        effect="blur"
                                                                        src={`${DS_CDN_URL}${item?.imageUrl}`}
                                                                        alt="DS Property"
                                                                        onClick={() => handleViewImage(item.id)}
                                                                    /> */}

                              <img
                                  src={`${DS_CDN_URL}${item.imageUrl}`}
                                  alt="DS Property"
                              />
                            </div>
                        )
                    )}
                  </Carousel>
                </Col>
                <div className="arrow-box right" onClick={next}>
                  <i className="icon-arrow-r" />
                </div>
              </Row>
            </section>
            {data.ds_property_detail.video && <section />}

            {/* agent top section */}

            {data.ds_property_detail.vitecPropertyEmp &&
              data.ds_property_detail.vitecPropertyEmp.length > 0 && (
                <section className="team-sec top-megler-section">
                  <div className="detail-container">
                    <Row>
                      <Col xs={24}>
                        <h1 className="h1">Eiendomsmeglere</h1>
                        <div className="breakline" />
                      </Col>
                    </Row>
                    <Row gutter={32}>
                      <Col xs={24} lg={24}>
                        <div className="team-blog">
                          <Row gutter={32}>
                            {data.ds_property_detail.vitecPropertyEmp.map(
                              (item) =>
                                item.brokerRole !== "2" &&
                                item.brokerRole !== "4" && (
                                  <Col xs={24} md={12} className="mb-3">
                                    <Row gutter={32} className="team-blog-row">
                                      <Col
                                        xs={24}
                                        xl={12}
                                        className="team-blog-img"
                                      >
                                        {item.photo ? (
                                          // <img
                                          //   src={`${DS_CDN_URL}${item.photo}`}
                                          //   className="img-responsive"
                                          //   alt={item.name}
                                          // />
                                          <LazyLoadImage
                                            effect="blur"
                                            className="img-responsive"
                                            src={`${DS_CDN_URL}${item.photo}`}
                                            alt={item.name}
                                          />
                                        ) : (
                                          <LazyLoadImage
                                            effect="blur"
                                            src={defaultUser}
                                            alt={item.name}
                                            className="img-responsive"
                                          />
                                        )}
                                      </Col>
                                      <Col xs={24} xl={12}>
                                        {console.log("item", item)}
                                        <div className="team-blog-desc">
                                          <h4>{item.name}</h4>
                                          <p className="no-margin">
                                            {item.title}
                                          </p>
                                          <p className="no-margin">
                                            E:{" "}
                                            <a href={`mailto:${item.email}`}>
                                              {item.email}
                                            </a>{" "}
                                          </p>
                                          <p className="no-margin">
                                            M:{" "}
                                            <a href={`tel:${item.mobilePhone}`}>
                                              {item.mobilePhone?.replace(
                                                /(\d{3})(\d{2})(\d)/,
                                                "$1 $2 $3"
                                              )}
                                            </a>
                                          </p>
                                        </div>
                                        <Row className="mt-3">
                                          <Col xs={24} lg={24}>
                                            <Button
                                              className="w-100 mt-3 admin-forms-btn bg-secondary ds-modal-btns"
                                              size="large"
                                              type="primary"
                                              onClick={() => {
                                                console.log("item.id", item.id);
                                                setBrokerID(item.id);
                                                setContactBroker(true);
                                              }}
                                            >
                                              Spørsmål? Kontakt megler
                                            </Button>
                                          </Col>
                                          <Col xs={24} lg={24}>
                                            <Button
                                              className="w-100 mt-3 admin-forms-btn bg-secondary ds-modal-btns"
                                              size="large"
                                              type="primary"
                                              onClick={() =>
                                                setGetUpdated(true)
                                              }
                                            >
                                              Bli oppdatert
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                )
                            )}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="detail-container">
                    <Row>
                      <Col xs={24}>
                        <div className="breakline" />
                      </Col>
                    </Row>
                  </div>
                </section>
              )}
            {/* detail section  */}
            <section
              className="onesp-row-above detail-container"
              id="section-informasjon"
            >
              {data?.ds_property_detail?.vitecPropertyTextField?.beliggenhet ||
              data?.ds_property_detail?.vitecPropertyProject?.miscellaneous ||
              data?.ds_property_detail?.vitecPropertyProject?.commonArea ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.garageParking ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.booth ||
              data?.ds_property_detail?.vitecPropertyTextField
                ?.vitecPropertyTextfieldDetail?.insuranceCompany ? (
                <Row>
                  <Col xs={24}>
                    <h1 className="h1 text-center">Prosjektbeskrivelse</h1>
                  </Col>
                </Row>
              ) : null}
              <Row gutter={32}>
                <Col xs={24} lg={10}>
                  <h2 className="title">ADRESSE</h2>
                </Col>
                <Col xs={24} lg={{ span: 12 }}>
                  <div className="contentSection">
                    {data?.ds_property_detail?.streetAdress}, <br />
                    {data?.ds_property_detail?.zipCode}{" "}
                    {data?.ds_property_detail?.city}
                  </div>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} lg={10}>
                  <h2 className="title">Eiendomsbetegnelse</h2>
                </Col>
                <Col xs={24} lg={{ span: 12 }}>
                  <div className="contentSection">
                    {data.ds_property_detail.municipality ? (
                      <p className="mb-0">
                        Kommune : {data?.ds_property_detail?.municipality}
                      </p>
                    ) : null}
                    {data?.ds_property_detail?.vitecPropertyMatrikkel &&
                      data?.ds_property_detail?.vitecPropertyMatrikkel.map(
                        (item,index) => (
                            <div className={index === 0 ? '' : 'mt-3'}>

                            {item.gnr > 0 ? (
                              <p className="mb-0">Gnr. {item.gnr}</p>
                            ) : null}
                            {item.bnr > 0 ? (
                              <p className="mb-0">Bnr. {item.bnr}</p>
                            ) : null}
                            {item.snr > 0 ? (
                              <p className="mb-0">Snr. {item.snr}</p>
                            ) : null}
                            {item.knr > 0 ? (
                              <p className="mb-0">Knr. {item.knr}</p>
                            ) : null}
                            {item.fnr > 0 ? (
                              <p className="mb-0">Fnr. {item.fnr}</p>
                            ) : null}
                          </div>
                        )
                      )}
                  </div>
                </Col>
              </Row>
              {data?.ds_property_detail?.vitecPropertyTextField &&
              data?.ds_property_detail?.vitecPropertyTextField?.beliggenhet ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Beliggenhet</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyTextField
                        ?.beliggenhet.length > 310 && beliggenhet ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.beliggenhet
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setBeliggenhet(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyTextField?.beliggenhet.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setBeliggenhet(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyTextField
                              ?.beliggenhet.length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject?.miscellaneous ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Generell orientering</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      <p>
                        {
                          data?.ds_property_detail?.vitecPropertyProject
                            ?.miscellaneous
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject?.commonArea ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Fellesareal i bygg</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      <p>
                        {
                          data?.ds_property_detail?.vitecPropertyProject
                            ?.commonArea
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.garageParking ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Garasje/Parkering</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.garageParking.length >
                        310 && parkering ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.garageParking
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setParkering(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.garageParking.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setParkering(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.garageParking
                              .length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.booth ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Boder</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      <p>
                        {
                          data?.ds_property_detail?.vitecPropertyProject
                            ?.vitecPropertyProjectTextfield?.booth
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyTextField &&
              data?.ds_property_detail?.vitecPropertyTextField
                ?.vitecPropertyTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyTextField
                ?.vitecPropertyTextfieldDetail?.insuranceCompany ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">FORSIKRINGSSELSKAP</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      <p>
                        {
                          data?.ds_property_detail?.vitecPropertyTextField
                            ?.vitecPropertyTextfieldDetail?.insuranceCompany
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {/* <Row>
                <Col xs={24}>
                  <h1 className="h1 text-center">Tomteforhold</h1>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} lg={10}>
                  <h2 className="title">Fellesareal/Utomhus/Infrastruktur</h2>
                </Col>
                <Col xs={24} lg={{ span: 12 }}>
                  <div className="contentSection">

                  </div>
                </Col>
              </Row> */}

              {data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.kitchen ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.bath ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.recreationalRooms ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.wardrobe ||
              data?.ds_property_detail?.vitecPropertyTextField?.diverse ? (
                <Row>
                  <Col xs={24}>
                    <h1 className="h1 text-center">Leveransebeskrivelse</h1>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.kitchen ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Kjøkken</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.kitchen.length > 310 &&
                      kitchen ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.kitchen
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setKitchen(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.kitchen.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setKitchen(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.kitchen.length >
                              310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.bath ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Bad</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.bath.length > 310 &&
                      bath ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.bath
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setBath(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.bath.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setBath(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.bath.length >
                              310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.recreationalRooms ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Andre oppholdsromrom</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      <p>
                        {
                          data?.ds_property_detail?.vitecPropertyProject
                            ?.vitecPropertyProjectTextfield?.recreationalRooms
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.wardrobe ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Garderobefasiliteter</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.wardrobe.length >
                        310 && wardrobe ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.wardrobe
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setWardrobe(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.wardrobe.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setWardrobe(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.wardrobe.length >
                              310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}

              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField?.diverse && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">DIVERSE</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.diverse?.length > 310 && diverse ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.diverse
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setDiverse(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.diverse.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setDiverse(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.diverse.length > 310 && `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              {data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.construction ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.facade ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.roofing ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.balconyTerracePatio ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.elevators ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.ventilation ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.sanitary ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.fireSafety ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.doors ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.surfaces ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.boothSolutions ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.garageDoor ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.mailboxes ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.doorPhone ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail
                ?.cableTvBroadbandTelephone ? (
                <Row>
                  <Col xs={24}>
                    <h1 className="h1 text-center">Teknisk beskrivelse</h1>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.construction ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Konstruksjon</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.construction.length >
                        310 && construction ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.construction
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setConstruction(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.construction.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setConstruction(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.construction
                              .length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.facade ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Fasade</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.facade.length > 310 &&
                      facade ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.facade
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setFacade(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.facade.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setFacade(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.facade.length >
                              310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.roofing ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Yttertak</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.roofing.length > 310 &&
                      roofing ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.roofing
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setRoofing(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.roofing.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setRoofing(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.roofing.length >
                              310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.balconyTerracePatio ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Balkonger/Terrasser/Uteplasser</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.balconyTerracePatio
                        .length > 310 && balconyTerracePatio ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.balconyTerracePatio
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setBalconyTerracePatio(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.balconyTerracePatio.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setBalconyTerracePatio(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.balconyTerracePatio.length > 310 &&
                              `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.elevators ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Heiser</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.elevators.length >
                        310 && elevators ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.elevators
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setElevators(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.elevators.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setElevators(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.elevators
                              .length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.ventilation ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Ventilasjon</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.ventilation.length >
                        310 && ventilation ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.ventilation
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setVentilation(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.ventilation.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setVentilation(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.ventilation
                              .length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.sanitary ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Sanitær</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.sanitary.length >
                        310 && sanitary ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.sanitary
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setSanitary(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.sanitary.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setSanitary(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.sanitary.length >
                              310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.fireSafety ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Brannsikring</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.fireSafety.length >
                        310 && fireSafety ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.fireSafety
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setFireSafety(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.fireSafety.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setFireSafety(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.fireSafety
                              .length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.doors ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Dører og vinduer</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.doors.length > 310 &&
                      doors ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.doors
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setDoors(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.doors.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setDoors(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.doors.length >
                              310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield?.surfaces ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Overflater og kledning</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield?.surfaces.length >
                        310 && surfaces ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.surfaces
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setDoors(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.surfaces.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setSurfaces(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield?.surfaces.length >
                              310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.boothSolutions ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Bodløsninger</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.boothSolutions
                        .length > 310 && boothSolutions ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.boothSolutions
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setBoothSolutions(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.boothSolutions.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setBoothSolutions(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail
                              ?.boothSolutions.length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.garageDoor ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Garasjeport</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.garageDoor
                        .length > 310 && garageDoor ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.garageDoor
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setGarageDoor(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.garageDoor.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setGarageDoor(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail?.garageDoor
                              .length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.mailboxes ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Postkasser</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.mailboxes
                        .length > 310 && mailboxes ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.mailboxes
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setMailboxes(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.mailboxes.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setMailboxes(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail?.mailboxes
                              .length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.doorPhone ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Porttelefon og adgangskontroll</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.doorPhone
                        .length > 310 && doorPhone ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.doorPhone
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setDoorPhone(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.doorPhone.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setDoorPhone(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail?.doorPhone
                              .length > 310 && `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail
                ?.cableTvBroadbandTelephone ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Kabel-TV/Bredbånd/Telefoni</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail
                        ?.cableTvBroadbandTelephone.length > 310 &&
                      cableTelephone ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.cableTvBroadbandTelephone
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setCabelTelephone(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.cableTvBroadbandTelephone.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setCabelTelephone(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail
                              ?.cableTvBroadbandTelephone.length > 310 &&
                              `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {(data?.ds_property_detail?.energyLetter &&
                data?.ds_property_detail?.energyColorName) ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.electricalSystem ||
              data?.ds_property_detail?.vitecPropertyTextField?.oppvarming ? (
                <Row>
                  <Col xs={24}>
                    <h1 className="h1 text-center">Energi</h1>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.energyLetter &&
                data?.ds_property_detail?.energyColorName && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">ENERGIMERKE</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        <p>
                          {formatEnergyCode(
                            data?.ds_property_detail?.energyLetter
                          )}{" "}
                          - {data?.ds_property_detail?.energyColorName}
                        </p>
                        {data?.ds_property_detail?.vitecPropertyTextField &&
                          data?.ds_property_detail?.vitecPropertyTextField
                            ?.energyMarking && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.energyMarking.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                          )}
                      </div>
                    </Col>
                  </Row>
                )}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.electricalSystem ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">EL-anlegg</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.electricalSystem
                        .length > 310 && electricalSystem ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.electricalSystem
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setElectricalSystem(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.electricalSystem.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setElectricalSystem(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail
                              ?.electricalSystem.length > 310 &&
                              `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.oppvarming && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">OPPVARMING</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.oppvarming.length > 310 && oppvarming ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.oppvarming
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setOppvarming(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.oppvarming.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setOppvarming(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.oppvarming.length > 310 && `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              {data?.ds_property_detail?.vitecPropertyTextField?.velforening ? (
                <Row>
                  <Col xs={24}>
                    <h1 className="h1 text-center">Økonomi</h1>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyTextField
                ?.velforening && (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">VELFORENING</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      <p>
                        {
                          data?.ds_property_detail?.vitecPropertyTextField
                            ?.velforening
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              )}
              {data?.ds_property_detail?.vitecPropertyTextField
                ?.vitecPropertyTextfieldDetail?.servitutterErklaeringer ||
              data?.ds_property_detail?.vitecPropertyTextField
                ?.vitecPropertyTextfieldDetail?.ferdigattest ||
              data?.ds_property_detail?.vitecPropertyTextField?.radonmaling ||
              data?.ds_property_detail?.vitecPropertyTextField
                ?.vitecPropertyTextfieldDetail?.reguleringsplan ||
              data?.ds_property_detail?.vitecPropertyTextField
                ?.adgangForUtleie ||
              data?.ds_property_detail?.vitecPropertyTextField
                ?.vitecPropertyTextfieldDetail?.veiVannAvlop ||
              data?.ds_property_detail?.vitecPropertyTextField?.legalpant ? (
                <Row>
                  <Col xs={24}>
                    <h1 className="h1 text-center">Offentlige forhold</h1>
                  </Col>
                </Row>
              ) : null}

              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail?.servitutterErklaeringer && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">
                        TINGLYSTE HEFTELSER OG RETTIGHETER
                      </h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.vitecPropertyTextfieldDetail
                          ?.servitutterErklaeringer.length > 310 &&
                        servitutterErklaeringer ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.servitutterErklaeringer
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setServitutterErklaeringer(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.servitutterErklaeringer.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setServitutterErklaeringer(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.vitecPropertyTextfieldDetail
                                ?.servitutterErklaeringer.length > 310 &&
                                `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail?.ferdigattest && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">
                        FERDIGATTEST / MIDLERTIDIG BRUKSTILLATELSE
                      </h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.vitecPropertyTextfieldDetail?.ferdigattest.length >
                          310 && ferdigattest ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.ferdigattest
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setFerdigattest(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.ferdigattest.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setFerdigattest(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.vitecPropertyTextfieldDetail?.ferdigattest
                                .length > 310 && `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.radonmaling && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">RADONMÅLING</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.radonmaling.length > 310 && radonmaling ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.radonmaling
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setRadonmaling(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.radonmaling.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setRadonmaling(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.radonmaling.length > 310 && `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail?.reguleringsplan && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">
                        Reguleringsplan og rammetillatelse
                      </h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.vitecPropertyTextfieldDetail?.reguleringsplan
                          .length > 310 && reguleringsplan ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.reguleringsplan
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setRegularsPlan(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.reguleringsplan.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setRegularsPlan(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.vitecPropertyTextfieldDetail?.reguleringsplan
                                .length > 310 && `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.adgangForUtleie && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">Adgang Til Utleie</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.adgangForUtleie.length > 310 && adgangForUtleie ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.adgangForUtleie
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setAdgangForUtleie(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.adgangForUtleie.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setAdgangForUtleie(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.adgangForUtleie.length > 310 &&
                                `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail?.veiVannAvlop && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">VEI, VANN OG AVLØP</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.vitecPropertyTextfieldDetail?.veiVannAvlop.length >
                          310 && veiVannAvlop ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.veiVannAvlop
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setVeiVannAvlop(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.veiVannAvlop.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setVeiVannAvlop(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.vitecPropertyTextfieldDetail?.veiVannAvlop
                                .length > 310 && `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField?.legalpant && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">Legalpant</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.legalpant.length > 310 && legalpant ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.legalpant
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setLegalpant(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.legalpant.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setLegalpant(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.legalpant.length > 310 && `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              {data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.pricing ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.financing ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.options ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.paymentConditions ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.saleConditions ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.importantInformation ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.saleOfContractPosition ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.spaceCalculation ||
              (data?.ds_property_detail?.vitecPropertyEstatePrice &&
                data?.ds_property_detail?.vitecPropertyEstatePrice
                  ?.salesCostDescription > 0) ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.buyingContract ||
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.distinctReservations ||
              data?.ds_property_detail?.vitecPropertyTextField
                ?.vitecPropertyTextfieldDetail?.moneyLaunderingRules ? (
                <Row>
                  <Col xs={24}>
                    <h1 className="h1 text-center">Kjøpsbetingelser</h1>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.pricing ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Prisinformasjon</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.pricing?.length >
                        310 && pricing ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.pricing
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setPricing(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.pricing.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setPricing(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail?.pricing
                              .length > 310 && `[Les mindre]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.financing ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Finansieringskontroll</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.financing
                        ?.length > 310 && financing ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.financing
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setFinancing(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.financing.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setFinancing(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail?.financing
                              .length > 310 && `[Les mindre]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.options ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Tilvalg og endringer</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.options?.length >
                        310 && options ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.options
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setOptions(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.options.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setOptions(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail?.options
                              .length > 310 && `[Les mindre]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}

              {data?.ds_property_detail?.vitecPropertyProject &&
                data?.ds_property_detail?.vitecPropertyProject
                  ?.vitecPropertyProjectTextfield &&
                data?.ds_property_detail?.vitecPropertyProject
                  ?.vitecPropertyProjectTextfield
                  ?.vitecPropertyProjectTextfieldDetail &&
                data?.ds_property_detail?.vitecPropertyProject
                  ?.vitecPropertyProjectTextfield
                  ?.vitecPropertyProjectTextfieldDetail?.paymentConditions && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">BETALINGSBETINGELSER</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyProject
                          ?.vitecPropertyProjectTextfield
                          ?.vitecPropertyProjectTextfieldDetail
                          ?.paymentConditions?.length > 310 &&
                        paymentConditions ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.paymentConditions
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setPaymentConditions(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.paymentConditions.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setPaymentConditions(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyProject
                                ?.vitecPropertyProjectTextfield
                                ?.vitecPropertyProjectTextfieldDetail
                                ?.paymentConditions.length > 310 &&
                                `[Les mindre]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.saleConditions ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Salgsbetingelser og kjøpetilbud</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.saleConditions
                        ?.length > 310 && saleConditions ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.saleConditions
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setSalesConditions(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.saleConditions.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setSalesConditions(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail
                              ?.saleConditions.length > 310 && `[Les mindre]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}

              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.importantInformation ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Viktig informasjon</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail
                        ?.importantInformation.length > 310 &&
                      importantInformation ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.importantInformation
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setImportantInformation(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.importantInformation.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setImportantInformation(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail
                              ?.importantInformation.length > 310 &&
                              `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}

              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail
                ?.saleOfContractPosition ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Salg av kontraktsposisjoner</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      <p>
                        {
                          data?.ds_property_detail?.vitecPropertyProject
                            ?.vitecPropertyProjectTextfield
                            ?.vitecPropertyProjectTextfieldDetail
                            ?.saleOfContractPosition
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}

              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.spaceCalculation ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Arealberegninger</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail?.spaceCalculation
                        .length > 310 && spaceCalculation ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.spaceCalculation
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setSpaceCalculation(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.spaceCalculation.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setSpaceCalculation(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail
                              ?.spaceCalculation.length > 310 &&
                              `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}

              {data?.ds_property_detail?.vitecPropertyEstatePrice &&
                data?.ds_property_detail?.vitecPropertyEstatePrice
                  ?.salesCostDescription > 0 && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">Prisantydning og omkostninger</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyEstatePrice
                          ?.salesCostDescription?.length > 310 && salesCost ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:removeHTMLTags( data?.ds_property_detail?.vitecPropertyEstatePrice?.salesCostDescription
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setSalesCost(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyEstatePrice?.salesCostDescription.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setSalesCost(true);
                              }}
                            >
                              {data?.ds_property_detail
                                ?.vitecPropertyEstatePrice?.salesCostDescription
                                .length > 310 && `[Les mindre]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.buyingContract ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Kjøpekontrakt</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      <p>
                        {
                          data?.ds_property_detail?.vitecPropertyProject
                            ?.vitecPropertyProjectTextfield
                            ?.vitecPropertyProjectTextfieldDetail
                            ?.buyingContract
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}

              {data?.ds_property_detail?.vitecPropertyProject &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail &&
              data?.ds_property_detail?.vitecPropertyProject
                ?.vitecPropertyProjectTextfield
                ?.vitecPropertyProjectTextfieldDetail?.distinctReservations ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">Forbehold fra utbygger</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      {data?.ds_property_detail?.vitecPropertyProject
                        ?.vitecPropertyProjectTextfield
                        ?.vitecPropertyProjectTextfieldDetail
                        ?.distinctReservations?.length > 310 &&
                      distinctReservations ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:removeHTMLTags( data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.distinctReservations
                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ).match(/.{1,310}/g)[0],
                            }}
                          />
                          <span>...</span>
                          <p
                            className="morelink"
                            onClick={() => {
                              setDistinctReservations(false);
                            }}
                          >
                            [ Les mer ]
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.ds_property_detail?.vitecPropertyProject?.vitecPropertyProjectTextfield?.vitecPropertyProjectTextfieldDetail?.distinctReservations.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}
                          />
                          <p
                            className="morelink"
                            onClick={() => {
                              setDistinctReservations(true);
                            }}
                          >
                            {data?.ds_property_detail?.vitecPropertyProject
                              ?.vitecPropertyProjectTextfield
                              ?.vitecPropertyProjectTextfieldDetail
                              ?.distinctReservations.length > 310 &&
                              `[ Les mindre ]`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}

              {data?.ds_property_detail?.vitecPropertyTextField &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail &&
                data?.ds_property_detail?.vitecPropertyTextField
                  ?.vitecPropertyTextfieldDetail?.moneyLaunderingRules && (
                  <Row gutter={32}>
                    <Col xs={24} lg={10}>
                      <h2 className="title">Hvitvaskingsloven</h2>
                    </Col>
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        {data?.ds_property_detail?.vitecPropertyTextField
                          ?.vitecPropertyTextfieldDetail?.moneyLaunderingRules
                          ?.length > 310 && moneyLaunderingRules ? (
                          <>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: removeHTMLTags(data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.moneyLaunderingRules
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  ).match(/.{1,310}/g)[0],
                              }}
                            />
                            <span>...</span>
                            <p
                              className="morelink"
                              onClick={() => {
                                setMoneyLaunderingRules(false);
                              }}
                            >
                              [ Les mer ]
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.ds_property_detail?.vitecPropertyTextField?.vitecPropertyTextfieldDetail?.moneyLaunderingRules.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                            <p
                              className="morelink"
                              onClick={() => {
                                setMoneyLaunderingRules(true);
                              }}
                            >
                              {data?.ds_property_detail?.vitecPropertyTextField
                                ?.vitecPropertyTextfieldDetail
                                ?.moneyLaunderingRules.length > 310 &&
                                `[ Les mindre ]`}
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

              {data?.ds_property_detail?.ownershipType === "Eierseksjon" ? (
                <>
                  {data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail?.organizationType ||
                  data?.ds_property_detail?.vitecPropertyPartOwnership
                    ?.forretningsforer ||
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail
                    ?.stipulatedMonthlyJointCosts ||
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail
                    ?.stipulatedShareJointDebt ||
                  data?.ds_property_detail?.vitecPropertyPartOwnership
                    ?.statutes ? (
                    <Row>
                      <Col xs={24}>
                        <h1 className="h1 text-center">Om Sameiet</h1>
                      </Col>
                    </Row>
                  ) : null}
                  {data?.ds_property_detail?.vitecPropertyProject &&
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield &&
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail &&
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail?.organizationType ? (
                    <Row gutter={32}>
                      <Col xs={24} lg={10}>
                        <h2 className="title">Organisasjonsform</h2>
                      </Col>
                      <Col xs={24} lg={{ span: 12 }}>
                        <div className="contentSection">
                          <p>
                            {
                              data?.ds_property_detail?.vitecPropertyProject
                                ?.vitecPropertyProjectTextfield
                                ?.vitecPropertyProjectTextfieldDetail
                                ?.organizationType
                            }
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  {data?.ds_property_detail?.vitecPropertyPartOwnership &&
                    data?.ds_property_detail?.vitecPropertyPartOwnership
                      ?.forretningsforer && (
                      <Row gutter={32}>
                        <Col xs={24} lg={10}>
                          <h2 className="title">Forretningsfører</h2>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                          <div className="contentSection">
                            <p>
                              {
                                data?.ds_property_detail
                                  ?.vitecPropertyPartOwnership?.forretningsforer
                              }
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}

                  {data?.ds_property_detail?.vitecPropertyProject &&
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield &&
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail &&
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail
                    ?.stipulatedMonthlyJointCosts ? (
                    <Row gutter={32}>
                      <Col xs={24} lg={10}>
                        <h2 className="title">
                          Stipulering av felleskostnader
                        </h2>
                      </Col>
                      <Col xs={24} lg={{ span: 12 }}>
                        <div className="contentSection">
                          <p>
                            {
                              data?.ds_property_detail?.vitecPropertyProject
                                ?.vitecPropertyProjectTextfield
                                ?.vitecPropertyProjectTextfieldDetail
                                ?.stipulatedMonthlyJointCosts
                            }
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  {data?.ds_property_detail?.vitecPropertyProject &&
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield &&
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail &&
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail
                    ?.stipulatedShareJointDebt ? (
                    <Row gutter={32}>
                      <Col xs={24} lg={10}>
                        <h2 className="title">Stipulering av fellesgjeld</h2>
                      </Col>
                      <Col xs={24} lg={{ span: 12 }}>
                        <div className="contentSection">
                          <p>
                            {
                              data?.ds_property_detail?.vitecPropertyProject
                                ?.vitecPropertyProjectTextfield
                                ?.vitecPropertyProjectTextfieldDetail
                                ?.stipulatedShareJointDebt
                            }
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ) : null}

                  {data?.ds_property_detail?.vitecPropertyPartOwnership &&
                  data?.ds_property_detail?.vitecPropertyPartOwnership
                    ?.statutes ? (
                    <Row gutter={32}>
                      <Col xs={24} lg={10}>
                        <h2 className="title">Vedtekter/Husordensregler</h2>
                      </Col>
                      <Col xs={24} lg={{ span: 12 }}>
                        <div className="contentSection">
                          <p>
                            {
                              data?.ds_property_detail
                                ?.vitecPropertyPartOwnership?.statutes
                            }
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </>
              ) : null}
              {data?.ds_property_detail?.ownershipType === "Andel" ? (
                <>
                  {data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail?.organizationType ||
                  data?.ds_property_detail?.vitecPropertyPartOwnership
                    ?.forretningsforer ||
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail
                    ?.stipulatedMonthlyJointCosts ||
                  data?.ds_property_detail?.vitecPropertyProject
                    ?.vitecPropertyProjectTextfield
                    ?.vitecPropertyProjectTextfieldDetail
                    ?.stipulatedShareJointDebt ||
                  data?.ds_property_detail?.vitecPropertyPartOwnership
                    ?.statutes ? (
                    <Row>
                      <Col xs={24}>
                        <h1 className="h1 text-center">Om Borettslaget</h1>
                      </Col>
                    </Row>
                  ) : null}
                  {data?.ds_property_detail?.vitecPropertyProject &&
                    data?.ds_property_detail?.vitecPropertyProject
                      ?.vitecPropertyProjectTextfield &&
                    data?.ds_property_detail?.vitecPropertyProject
                      ?.vitecPropertyProjectTextfield
                      ?.vitecPropertyProjectTextfieldDetail &&
                    data?.ds_property_detail?.vitecPropertyProject
                      ?.vitecPropertyProjectTextfield
                      ?.vitecPropertyProjectTextfieldDetail
                      ?.organizationType && (
                      <Row gutter={32}>
                        <Col xs={24} lg={10}>
                          <h2 className="title">Organisasjonsform</h2>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                          <div className="contentSection">
                            <p>
                              {
                                data?.ds_property_detail?.vitecPropertyProject
                                  ?.vitecPropertyProjectTextfield
                                  ?.vitecPropertyProjectTextfieldDetail
                                  ?.organizationType
                              }
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                  {data?.ds_property_detail?.vitecPropertyPartOwnership &&
                    data?.ds_property_detail?.vitecPropertyPartOwnership
                      ?.forretningsforer && (
                      <Row gutter={32}>
                        <Col xs={24} lg={10}>
                          <h2 className="title">Forretningsfører</h2>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                          <div className="contentSection">
                            <p>
                              {
                                data?.ds_property_detail
                                  ?.vitecPropertyPartOwnership?.forretningsforer
                              }
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}

                  {data?.ds_property_detail?.vitecPropertyProject &&
                    data?.ds_property_detail?.vitecPropertyProject
                      ?.vitecPropertyProjectTextfield &&
                    data?.ds_property_detail?.vitecPropertyProject
                      ?.vitecPropertyProjectTextfield
                      ?.vitecPropertyProjectTextfieldDetail &&
                    data?.ds_property_detail?.vitecPropertyProject
                      ?.vitecPropertyProjectTextfield
                      ?.vitecPropertyProjectTextfieldDetail
                      ?.stipulatedMonthlyJointCosts && (
                      <Row gutter={32}>
                        <Col xs={24} lg={10}>
                          <h2 className="title">
                            Stipulering av felleskostnader
                          </h2>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                          <div className="contentSection">
                            <p>
                              {
                                data?.ds_property_detail?.vitecPropertyProject
                                  ?.vitecPropertyProjectTextfield
                                  ?.vitecPropertyProjectTextfieldDetail
                                  ?.stipulatedMonthlyJointCosts
                              }
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                  {data?.ds_property_detail?.vitecPropertyProject &&
                    data?.ds_property_detail?.vitecPropertyProject
                      ?.vitecPropertyProjectTextfield &&
                    data?.ds_property_detail?.vitecPropertyProject
                      ?.vitecPropertyProjectTextfield
                      ?.vitecPropertyProjectTextfieldDetail &&
                    data?.ds_property_detail?.vitecPropertyProject
                      ?.vitecPropertyProjectTextfield
                      ?.vitecPropertyProjectTextfieldDetail
                      ?.stipulatedShareJointDebt && (
                      <Row gutter={32}>
                        <Col xs={24} lg={10}>
                          <h2 className="title">Stipulering av fellesgjeld</h2>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                          <div className="contentSection">
                            <p>
                              {
                                data?.ds_property_detail?.vitecPropertyProject
                                  ?.vitecPropertyProjectTextfield
                                  ?.vitecPropertyProjectTextfieldDetail
                                  ?.stipulatedShareJointDebt
                              }
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}

                  {data?.ds_property_detail?.vitecPropertyPartOwnership &&
                  data?.ds_property_detail?.vitecPropertyPartOwnership
                    ?.statutes ? (
                    <Row gutter={32}>
                      <Col xs={24} lg={10}>
                        <h2 className="title">Vedtekter/Husordensregler</h2>
                      </Col>
                      <Col xs={24} lg={{ span: 12 }}>
                        <div className="contentSection">
                          <p>
                            {
                              data?.ds_property_detail
                                ?.vitecPropertyPartOwnership?.statutes
                            }
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </>
              ) : null}
              {responsibleBroker.length > 0 ||
              data.ds_property_detail.department ? (
                <Row>
                  <Col xs={24}>
                    <h1 className="h1 text-center">Megler</h1>
                  </Col>
                </Row>
              ) : null}
              {responsibleBroker.length > 0 ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">ANSVARLIG MEGLER</h2>
                  </Col>
                  {responsibleBroker.map((item) => (
                    <Col xs={24} lg={{ span: 12 }}>
                      <div className="contentSection">
                        <p className="no-margin">{item.name}</p>
                        <p className="no-margin">{item.title}</p>
                        <p className="no-margin">
                          Tlf:{" "}
                          {item.mobilePhone?.replace(
                            /(\d{3})(\d{2})(\d)/,
                            "$1 $2 $3"
                          )}
                        </p>
                        <p className="no-margin">
                          Epost:{" "}
                          <a href={`mailto:${item.email}`}>{item.email}</a>
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : null}
              {data.ds_property_detail.department ? (
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">MEGLERFORETAK</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection">
                      <p className="no-margin">
                        {data.ds_property_detail.department.legalName}
                      </p>
                      <p className="no-margin">
                        {data.ds_property_detail.department.streetAddress}{" "}
                      </p>
                      <p className="no-margin">
                        {data.ds_property_detail.department.postalCode}{" "}
                        {data.ds_property_detail.department.city}
                      </p>
                      <p className="no-margin">
                        Org.nr.{" "}
                        {data.ds_property_detail.department.organisationNumber}
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </section>
            {data.ds_property_detail.vitecPropertyDocument &&
            data.ds_property_detail.vitecPropertyDocument.length > 0 ? (
              <section
                className="onesp-row-above detail-container section-kontakt"
                id="section-vedlegg"
              >
                {/* <div className="detail-container">
                              <Row>
                                <Col xs={24}>
                                  <div className="breakline" />
                                </Col>
                              </Row>
                            </div> */}
                <Row gutter={32}>
                  <Col xs={24} lg={10}>
                    <h2 className="title">VEDLEGG</h2>
                  </Col>
                  <Col xs={24} lg={{ span: 12 }}>
                    <div className="contentSection mb-0">
                      {docSequence.length > 0 &&
                        docSequence.map((docArray) =>
                          docArray.map((item) => (
                            <p className="no-margin" key={Math.random()}>
                              <a
                                href={`${DS_CDN_URL}${item.documentUrl}`}
                                rel="noopener noreferrer nofollow"
                                className="flex-wrapper"
                                target="_blank"
                              >
                                <FileTextOutlined className="fileicon-style" />
                                <div className="textbox-style ml-2">
                                  <h5 className="text-ds-link no-margin">
                                    {item.head}
                                  </h5>
                                </div>
                              </a>
                            </p>
                          ))
                        )}
                    </div>
                  </Col>
                </Row>
              </section>
            ) : null}
            {data.ds_property_detail.vitecPropertyEmp &&
            data.ds_property_detail.vitecPropertyEmp.length > 0 ? (
              <section className="team-sec bottom-contact">
                <div className="detail-container">
                  <Row>
                    <Col xs={24}>
                      <div className="breakline" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} lg={24}>
                      <div className="team-blog mb-0">
                        <Row gutter={32}>
                          {data.ds_property_detail.vitecPropertyEmp.map(
                            (item) =>
                              item.brokerRole !== "2" &&
                              item.brokerRole !== "4" && (
                                <Col xs={24} md={12} className="mb-3">
                                  <Row gutter={32} className="team-blog-row">
                                    <Col
                                      xs={24}
                                      xl={12}
                                      className="team-blog-img"
                                    >
                                      {item.photo ? (
                                        <LazyLoadImage
                                          effect="blur"
                                          className="img-responsive"
                                          src={`${DS_CDN_URL}${item.photo}`}
                                          alt={item.name}
                                        />
                                      ) : (
                                        <LazyLoadImage
                                          effect="blur"
                                          className="img-responsive"
                                          src={defaultUser}
                                          alt={item.name}
                                        />
                                      )}
                                    </Col>
                                    <Col xs={24} xl={12}>
                                      <div className="team-blog-desc">
                                        <h4>{item.name}</h4>
                                        <p className="no-margin">
                                          {item.title}
                                        </p>
                                        <p className="no-margin">
                                          E:{" "}
                                          <a href={`mailto:${item.email}`}>
                                            {item.email}
                                          </a>{" "}
                                        </p>
                                        <p className="no-margin">
                                          M:{" "}
                                          <a href={`tel:${item.mobilePhone}`}>
                                            {item.mobilePhone?.replace(
                                              /(\d{3})(\d{2})(\d)/,
                                              "$1 $2 $3"
                                            )}
                                          </a>
                                        </p>
                                      </div>
                                      <Row className="mt-3">
                                        <Col xs={24} lg={24}>
                                          <Button
                                            className="w-100 mt-3 admin-forms-btn ds-modal-btns bg-secondary"
                                            size="large"
                                            type="primary"
                                            onClick={() => {
                                              console.log("item.id", item.id);
                                              setBrokerID(item.id);
                                              setContactBroker(true);
                                            }}
                                          >
                                            Spørsmål? Kontakt megler
                                          </Button>
                                        </Col>
                                        <Col xs={24} lg={24}>
                                          <Button
                                            className="w-100 mt-3 admin-forms-btn ds-modal-btns bg-secondary"
                                            size="large"
                                            type="primary"
                                            onClick={() => setGetUpdated(true)}
                                          >
                                            Bli oppdatert
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              )
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="detail-container">
                  <Row>
                    <Col xs={24}>
                      <div className="breakline" />
                    </Col>
                  </Row>
                </div>
              </section>
            ) : null}
            <section className="section-kontakt social" id="section-Kontakt">
              <Row>
                <Col xs={24}>
                  <ul className="connect-link">
                    <li>
                      <a
                        href="https://www.facebook.com/W-Lilledal-156334337878748/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        <i className="icon-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/eiendomsmegler_lilledal/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        <i className="icon-instagram" />
                      </a>
                    </li>
                  </ul>
                  <p>
                    Siste generert:{" "}
                    {moment(
                      data.ds_property_detail.changedDate,
                      "YYYY-MM-DD"
                    ).format("DD.MM.YYYY")}{" "}
                    kl{" "}
                    {moment(
                      data.ds_property_detail.changedDate,
                      "HH:mm:ss"
                    ).format("HH:mm")}
                  </p>
                </Col>
              </Row>
            </section>
            <div className={Trigger ? "fab open" : "fab"}>
              <div className="actions">
                {/*{data.ds_property_detail.bidLink ? (
                  <a
                    className="flex-wrapper flex-end"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href={data.ds_property_detail.bidLink}
                  >
                    <div className="tooltip">Gi Bud</div>
                    <i className="icon-make-an-offer" />
                  </a>
                ) : null}*/}
                <a
                  className="flex-wrapper flex-end"
                  href="#section-bildegalleri"
                >
                  <div className="tooltip">Bildegalleri</div>
                  <i className="icon-gallery" />
                </a>
                <a
                  className="flex-wrapper flex-end"
                  href="#section-informasjon"
                >
                  <div className="tooltip">Informasjon</div>
                  <i className="icon-sales-information" />
                </a>
                <a className="flex-wrapper flex-end" href="#section-vedlegg">
                  <div className="tooltip">Vedlegg</div>
                  <i className="icon-attachment" />
                </a>
                <a className="flex-wrapper flex-end" href="#section-Kontakt">
                  <div className="tooltip">Kontakt</div>
                  <i className="icon-ds-contact" />
                </a>
              </div>
              <div
                className={Trigger ? "trigger open" : "trigger"}
                onClick={() => {
                  setTrigger(!Trigger);
                }}
              >
                <i className={Trigger ? "icon-close plus" : "icon-add plus"} />
              </div>
            </div>
            {/* sign up for view */}
            <Modal
              width={720}
              className="modalwidth custom-padding"
              centered
              visible={signupModal}
              title={
                <strong>
                  {formatMessage({
                    id: "component.ds.view.signup.title",
                  })}
                </strong>
              }
              onCancel={() => {
                setSignupModal(false);
              }}
              footer={null}
              destroyOnClose
            >
              <div className="pt-24 px-24">
                <Row>
                  <Col xs={24}>
                    {formatMessage({
                      id: "component.ds.view.signup.description",
                    })}
                  </Col>
                </Row>
                <Row gutter={48}>
                  <Col xs={24} className="mt-3">
                    <Title level={4} className="text-primary mb">
                      {formatMessage({
                        id: "component.ds.view.signup.formtitle",
                      })}
                    </Title>
                  </Col>
                </Row>
              </div>
              <Mutation mutation={SIGNUP_FOR_VIEWING}>
                {(signupForViewing, { loading }) => (
                  <Form
                    form={signUpForm}
                    layout="vertical"
                    className="user-forms modal-forms"
                    name="signup_for_viewing"
                    initialValues={{
                      remember: true,
                      id: vitecPropertyId,
                    }}
                    colon={false}
                    onFinish={(values) =>
                      onSignupFinish(values, signupForViewing)
                    }
                    onFinishFailed={onSignupFinishFailed}
                  >
                    <Row gutter={32} className="form-rows no-bottompadding">
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.firstname",
                          })}
                          className="labels-text"
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn fornavn",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.firstname",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.lastname",
                          })}
                          className="labels-text"
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn etternavn",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.lastname",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.email",
                          })}
                          className="labels-text"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn epost",
                            },
                          ]}
                        >
                          <Input
                            type="email"
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.email",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.phone",
                          })}
                          className="labels-text"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn telefon",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.phone",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          className="labels-text no-margin"
                          name="private_viewing"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox value>
                                {formatMessage({
                                  id: "component.ds.view.signup.checkbox1",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          className="labels-text no-margin"
                          name="plan_set"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox value>
                                {formatMessage({
                                  id: "component.ds.view.signup.checkbox2",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          className="labels-text no-margin"
                          name="notify"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox value>
                                {formatMessage({
                                  id: "component.ds.view.signup.checkbox3",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      {errorMsg && (
                        <Col xs={24}>
                          <div className="error-msg">
                            Velg en av avmerkingsboksene
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Divider />
                    <Row className="form-rows no-toppadding">
                      <Col xs={24}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-secondary buttons admin-forms-btn"
                          size="large"
                          loading={loading}
                        >
                          {formatMessage({
                            id: "component.form.register",
                          })}
                        </Button>
                        <Button
                          className="buttons admin-forms-btn margin-left-button"
                          size="large"
                          onClick={() => {
                            setSignupModal(false);
                            setErrorMsg(false);
                          }}
                        >
                          {formatMessage({
                            id: "component.form.cancel",
                          })}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Mutation>
            </Modal>
            {/* sales assignment pdf for view */}
            <Modal
              width={720}
              className="modalwidth custom-padding"
              centered
              visible={salesStatement}
              title={
                <strong>
                  {formatMessage({
                    id: "component.ds.sales.assignment.title",
                  })}
                </strong>
              }
              onCancel={() => {
                setSalesStatement(false);
              }}
              footer={null}
              destroyOnClose
            >
              <div className="pt-24 px-24">
                <Row>
                  <Col xs={24}>
                    {formatMessage({
                      id: "component.ds.sales.assignment.description",
                    })}
                  </Col>
                </Row>
                <Row gutter={48}>
                  <Col xs={24} className="mt-3">
                    <Title level={4} className="text-primary mb">
                      {formatMessage({
                        id: "component.ds.sales.assignment.formtitle",
                      })}
                    </Title>
                  </Col>
                </Row>
              </div>
              <Mutation mutation={SALES_PDF_STATEMENT}>
                {(salesPDFSave, { loading }) => (
                  <Form
                    form={salesPDF}
                    layout="vertical"
                    className="user-forms modal-forms"
                    name="sales_assignment"
                    initialValues={{
                      remember: true,
                    }}
                    colon={false}
                    onFinish={(values) =>
                      onSalesAssignmentFinish(values, salesPDFSave)
                    }
                    onFinishFailed={onSalesAssignmentFinishFailed}
                  >
                    <Row gutter={32} className="form-rows no-bottompadding">
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.firstname",
                          })}
                          className="labels-text"
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn fornavn",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.firstname",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.lastname",
                          })}
                          className="labels-text"
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn etternavn",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.lastname",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.email",
                          })}
                          className="labels-text"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn epost",
                            },
                          ]}
                        >
                          <Input
                            type="email"
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.email",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.phone",
                          })}
                          className="labels-text"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn telefon",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.phone",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          className="labels-text no-margin"
                          name="send_info_about_property"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox
                                value={formatMessage({
                                  id: "component.ds.sales.assignment.checkbox1",
                                })}
                              >
                                {formatMessage({
                                  id: "component.ds.sales.assignment.checkbox1",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          className="labels-text no-margin"
                          name="send_info_about_property"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox
                                value={formatMessage({
                                  id: "component.ds.sales.assignment.checkbox2",
                                })}
                              >
                                {formatMessage({
                                  id: "component.ds.sales.assignment.checkbox2",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          className="labels-text no-margin"
                          name="get_update_similar_homes"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox
                                value={formatMessage({
                                  id: "component.ds.sales.assignment.checkbox3",
                                })}
                              >
                                {formatMessage({
                                  id: "component.ds.sales.assignment.checkbox3",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          className="labels-text no-margin"
                          name="receive_our_newsletter"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox
                                value={formatMessage({
                                  id: "component.ds.sales.assignment.checkbox4",
                                })}
                              >
                                {formatMessage({
                                  id: "component.ds.sales.assignment.checkbox4",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      {errorMsg && (
                        <Col xs={24}>
                          <div className="error-msg">
                            Velg en av avmerkingsboksene
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Divider />
                    <Row className="form-rows no-toppadding">
                      <Col xs={24}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-secondary buttons admin-forms-btn"
                          size="large"
                          loading={loading}
                        >
                          {formatMessage({
                            id: "component.form.send",
                          })}
                        </Button>
                        <Button
                          className="buttons admin-forms-btn margin-left-button"
                          size="large"
                          onClick={() => {
                            setSalesStatement(false);
                            setErrorMsg(false);
                          }}
                        >
                          {formatMessage({
                            id: "component.form.cancel",
                          })}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Mutation>
            </Modal>
            {/* get upto date for view */}
            <Modal
              width={720}
              className="modalwidth custom-padding"
              centered
              visible={getUpdated}
              title={
                <strong>
                  {formatMessage({
                    id: "component.ds.get.updated.title",
                  })}
                </strong>
              }
              onCancel={() => {
                setGetUpdated(false);
              }}
              footer={null}
              destroyOnClose
            >
              <div className="pt-24 px-24">
                <Row>
                  <Col xs={24}>
                    {formatMessage({
                      id: "component.ds.get.updated.description",
                    })}
                  </Col>
                </Row>
                <Row gutter={48}>
                  <Col xs={24} className="mt-3">
                    <Title level={4} className="text-primary mb">
                      {formatMessage({
                        id: "component.ds.sales.assignment.formtitle",
                      })}
                    </Title>
                  </Col>
                </Row>
              </div>
              <Mutation mutation={GET_UPDATED}>
                {(getUpdatedSave, { loading }) => (
                  <Form
                    form={getUpdatedForm}
                    layout="vertical"
                    className="user-forms modal-forms"
                    name="get_updated"
                    initialValues={{
                      remember: true,
                    }}
                    colon={false}
                    onFinish={(values) =>
                      onGetUpdatedFinish(values, getUpdatedSave)
                    }
                    onFinishFailed={onGetUpdatedFinishFailed}
                  >
                    <Row gutter={32} className="form-rows no-bottompadding">
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.firstname",
                          })}
                          className="labels-text"
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn fornavn",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.firstname",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.lastname",
                          })}
                          className="labels-text"
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn etternavn",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.lastname",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.email",
                          })}
                          className="labels-text"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn epost",
                            },
                          ]}
                        >
                          <Input
                            type="email"
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.email",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.phone",
                          })}
                          className="labels-text"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn telefon",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.phone",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          className="labels-text no-margin"
                          name="send_info_about_property"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox
                                value={formatMessage({
                                  id: "component.ds.sales.assignment.checkbox1",
                                })}
                              >
                                {formatMessage({
                                  id: "component.ds.sales.assignment.checkbox1",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          className="labels-text no-margin"
                          name="send_info_about_property"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox
                                value={formatMessage({
                                  id: "component.ds.sales.assignment.checkbox2",
                                })}
                              >
                                {formatMessage({
                                  id: "component.ds.sales.assignment.checkbox2",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          className="labels-text no-margin"
                          name="get_update_similar_homes"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox
                                value={formatMessage({
                                  id: "component.ds.sales.assignment.checkbox3",
                                })}
                              >
                                {formatMessage({
                                  id: "component.ds.sales.assignment.checkbox3",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item
                          className="labels-text no-margin"
                          name="receive_our_newsletter"
                        >
                          <Row>
                            <Col xs={24}>
                              <Checkbox
                                value={formatMessage({
                                  id: "component.ds.sales.assignment.checkbox4",
                                })}
                              >
                                {formatMessage({
                                  id: "component.ds.sales.assignment.checkbox4",
                                })}
                              </Checkbox>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      {errorMsg && (
                        <Col xs={24}>
                          <div className="error-msg">
                            Velg en av avmerkingsboksene
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Divider />
                    <Row className="form-rows no-toppadding">
                      <Col xs={24}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-secondary buttons admin-forms-btn"
                          size="large"
                          loading={loading}
                        >
                          {formatMessage({
                            id: "component.form.download",
                          })}
                        </Button>
                        <Button
                          className="buttons admin-forms-btn margin-left-button"
                          size="large"
                          onClick={() => {
                            setGetUpdated(false);
                            setErrorMsg(false);
                          }}
                        >
                          {formatMessage({
                            id: "component.form.cancel",
                          })}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Mutation>
            </Modal>
            {/* get upto date for view */}
            <Modal
              width={520}
              className="modalwidth custom-padding"
              centered
              visible={contactBroker}
              title={
                <strong>
                  {formatMessage({
                    id: "component.ds.contact.broker.title",
                  })}
                </strong>
              }
              onCancel={() => {
                setContactBroker(false);
              }}
              footer={null}
              destroyOnClose
            >
              <div className="pt-24 px-24">
                <Row>
                  <Col xs={24}>
                    {formatMessage({
                      id: "component.ds.get.updated.description",
                    })}
                  </Col>
                </Row>
                <Row gutter={48}>
                  <Col xs={24} className="mt-3">
                    <Title level={4} className="text-primary mb">
                      {formatMessage({
                        id: "component.ds.sales.assignment.formtitle",
                      })}
                    </Title>
                  </Col>
                </Row>
              </div>
              <Mutation mutation={CONTACT_BROKER}>
                {(saveContactBroker, { loading }) => (
                  <Form
                    layout="vertical"
                    className="user-forms modal-forms"
                    name="contact"
                    colon={false}
                    onFinish={(values) =>
                      onContactFinish(values, saveContactBroker)
                    }
                    onFinishFailed={onContactFinishFailed}
                  >
                    <Row gutter={32} className="form-rows no-bottompadding">
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.firstname",
                          })}
                          className="labels-text"
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn fornavn",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.firstname",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.lastname",
                          })}
                          className="labels-text"
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn etternavn",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.lastname",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.email",
                          })}
                          className="labels-text"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn epost",
                            },
                          ]}
                        >
                          <Input
                            type="email"
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.email",
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          label={formatMessage({
                            id: "component.form.phone",
                          })}
                          className="labels-text"
                          name="mobile"
                          rules={[
                            {
                              required: true,
                              message: "Vennligst fyll inn telefon",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={formatMessage({
                              id: "component.form.phone",
                            })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider />
                    <Row className="form-rows no-toppadding">
                      <Col xs={24}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-secondary buttons admin-forms-btn"
                          size="large"
                          loading={loading}
                        >
                          {formatMessage({
                            id: "component.form.send",
                          })}
                        </Button>
                        <Button
                          className="buttons admin-forms-btn margin-left-button"
                          size="large"
                          onClick={() => {
                            setContactBroker(false);
                          }}
                        >
                          {formatMessage({
                            id: "component.form.cancel",
                          })}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Mutation>
            </Modal>

            <Modal
              centered
              title="Hvilken kalender bruker du?"
              visible={calendarModal}
              onOk={() => setCalendarModal(false)}
              onCancel={() => setCalendarModal(false)}
              width="450px"
              footer={null}
              destroyOnClose
            >
              <Row gutter={32}>
                <Col xs={24}>
                  <p>Velg din foretrukne kalender:</p>
                </Col>
                {showing && showing.length > 0 && (
                  <>
                    <Col xs={24} lg={6}>
                      {console.log("currentID", currentID)}
                      <a
                        href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${
                          data.ds_property_detail.streetAdress
                        }&dates=${moment(
                          showing[currentID].startDate,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("YYYYMMDDTHHmmss[Z]")}/${moment(
                          showing[currentID].endDate,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("YYYYMMDDTHHmmss[Z]")}&details=${
                          data.ds_property_detail.heading
                        }:${FRONT_URL}eiendom/${oppdragsnummer}&location=${
                          data.ds_property_detail.municipality
                        }&sprop=&sprop=name:`}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Google
                      </a>
                    </Col>
                    <Col xs={24} lg={6}>
                      <a
                        href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:${FRONT_URL}eiendom/${oppdragsnummer}%0ADTSTART:${moment(
                          showing[currentID].startDate,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("YYYYMMDDTHHmmss[Z]")}%0ADTEND:${moment(
                          showing[currentID].endDate,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("YYYYMMDDTHHmmss[Z]")}%0ASUMMARY:${
                          data.ds_property_detail.heading
                        }:${FRONT_URL}eiendom/${oppdragsnummer}%0ALOCATION:${
                          data.ds_property_detail.municipality
                        }%0AEND:VEVENT%0AEND:VCALENDAR`}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        iCal
                      </a>
                    </Col>
                    <Col xs={24} lg={6}>
                      <a
                        href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:${FRONT_URL}eiendom/${oppdragsnummer}/%0ADTSTART:${moment(
                          showing[currentID].startDate,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("YYYYMMDDTHHmmss[Z]")}%0ADTEND:${moment(
                          showing[currentID].endDate,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("YYYYMMDDTHHmmss[Z]")}%0ASUMMARY:${
                          data.ds_property_detail.heading
                        }:${FRONT_URL}eiendom/${oppdragsnummer}%0ALOCATION::${
                          data.ds_property_detail.municipality
                        }%0AEND:VEVENT%0AEND:VCALENDAR`}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Outlook
                      </a>
                    </Col>
                    <Col xs={24} lg={6}>
                      <a
                        href={`http://calendar.yahoo.com/?v=60&view=d&type=20&title=${
                          data.ds_property_detail.heading
                        }:${FRONT_URL}eiendom/${oppdragsnummer}&in_loc=:${
                          data.ds_property_detail.municipality
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Yahoo
                      </a>
                    </Col>
                  </>
                )}
              </Row>
            </Modal>
          </>
        );
      }
      window.location = "https://weiendomsmegling.no/";
      // router.replace('https://weiendomsmegling.no/')
    }
    return (
      <div className="loader-wrapper">
        <LoadingOutlined />
      </div>
    );
  };
  return (
    <Row>
      <Col xs={24}>
        <div className="ds-wrapper">{renderDS()}</div>
      </Col>
    </Row>
  );
};

export default DigitalSales;
