import React from 'react'
//Component
import {ActivityIndicator, Animated, View} from 'react-native'
import {Accordion, Header, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";


import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Query} from "@apollo/react-components";
import {SELLER_FAQ} from "components/src/api/sellerQuery";
//Utils
import {styles} from './styles.js';
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";

// import { PickerView } from '@ant-design/react-native';
// import {Button, InputItem} from "@ant-design/react-native";

// import Accordion  from '@ant-design/react-native';
// import List  from '@ant-design/react-native';
// import { DatePickerView } from 'antd-mobile';





class Faq extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: undefined,

        };

        this.scrollY = new Animated.Value(0)
    }

    render() {
        return (
            <View style={CommonStyle.container}>

                <Header animatedTitle={Strings.FAQ}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={{paddingBottom: ThemeUtils.APPBAR_HEIGHT}}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.titleCont}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'faq'}
                                    color={Color.PRIMARY_DARK}/>

                                {/*  <Label xlarge
                                    ml={9}
                                    font_medium
                                    align={'left'}>
                                    {Strings.FAQ}
                                </Label>*/}
                            </View>

                            <Label
                                mt={18}
                                mb={30}
                                font_regular
                                small
                                align={'left'}>
                                {Strings.bookInspectionInstruction1}
                            </Label>
                            <View>
                                <Query query={SELLER_FAQ}
                                       variables={{
                                           status: 1
                                       }}>
                                    {({data, loading, error}) => {
                                        if (loading && !data) {
                                            return <ActivityIndicator color={Color.PRIMARY}/>
                                        }
                                        return data?.faq?.map(item => {
                                            return (
                                                <Accordion headerText={item.question}
                                                           key={item.question}
                                                           contentText={item.answer}
                                                />
                                            )
                                        })
                                    }}

                                </Query>
                            </View>


                        </View>

                    </View>
                </Animated.ScrollView>
            </View>

        )
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Faq);
