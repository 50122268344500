import React from 'react'
import {View} from 'react-native'
import {Header, IndicatorViewPager, PagerTabIndicator} from "components/src/components";
import {CommonStyle, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import PhotographyTab from "./Tab/Photography";
import PhotographyMobile from "./Mobile/Photography";
import StylingMobile from "./Mobile/Styling";
import StylingTab from "./Tab/Styling";
import CGIMobile from "./Mobile/CGI";
import CGITab from "./Tab/CGI";
import FullViewMobile from "./Mobile/FullView";
import FullViewTab from "./Tab/FullView";
import DroneMobile from "./Mobile/Drone";
import DroneTab from "./Tab/Drone";
import VideoMobile from "./Mobile/Video";
import VideoTab from "./Tab/Video";

const TABS = [{
    text: Strings.PHOTO,
}, {
    text: Strings.STYLING,
}, {
    text: Strings.CGI,
}, {
    text: Strings.FULL_VIEW,
}, {
    text: Strings.DRONE,
}, {
    text: Strings.VIDEO,
}];

function Photo(props) {
    const {history, navigation} = props;


    const renderTabIndicator = () => {
        return (
            <PagerTabIndicator tabs={TABS}
                               scrollEnabled={!ThemeUtils.isTablet()}/>
        )
    }


    return (
        <View style={styles.container}>
            <Header navigation={IS_WEB ? history : navigation}/>
            <IndicatorViewPager indicator={renderTabIndicator()}
                                style={CommonStyle.flex}>
                <View>
                    {ThemeUtils.isTablet() ? <PhotographyTab/> : <PhotographyMobile/>}
                </View>
                <View>
                    {ThemeUtils.isTablet() ? <StylingTab/> : <StylingMobile/>}
                </View>
                <View>
                    {ThemeUtils.isTablet() ? <CGITab/> : <CGIMobile/>}
                </View>
                <View>
                    {ThemeUtils.isTablet() ? <FullViewTab/> : <FullViewMobile/>}
                </View>
                <View>
                    {ThemeUtils.isTablet() ? <DroneTab/> : <DroneMobile/>}
                </View>
                <View>
                    {ThemeUtils.isTablet() ? <VideoTab/> : <VideoMobile/>}
                </View>
            </IndicatorViewPager>
        </View>

    )
}

export default Photo
