import { Col, Row, Typography, Form, Upload, Input,Spin, Button } from 'antd';
import { PlusOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { Mutation, Query } from 'react-apollo';
import { ADMIN_HOMEPAGE_SETTINGS } from '../../../../../query/AdminQuery';
import { ADMIN_UPDATE_HOMEPAGE_SETTINGS } from '../../../../../mutation/AdminMutation';
import { STORAGE_URL } from '../../../../utils/constant';
import {formatMessage} from "components/src/utils";
import {useHistory} from "react-router-dom";

const { Title } = Typography;
// const { TabPane } = Tabs;
let dataRefetch;
const uploadButton = (
  <div>
    <PlusOutlined style={{ fontSize: '40px', opacity: 0.3 }} />
    <div className="ant-upload-text label-texts">
      {formatMessage({
        id: 'component.form.upload',
      })}
    </div>
  </div>
);
export const HomeSettings = () => {
  let router=useHistory();
  const [orderData, setOrderData] = useState();
  const [displayVideo, setdisplayVideo] = useState([]);
  const [imageUrl, setimageUrl] = useState('');
  const [imageUrl2, setimageUrl2] = useState('');
  const [imageUrl3, setimageUrl3] = useState('');
  const [imageUrl4, setimageUrl4] = useState('');
  const [imageObject, setimageObject] = useState();
  const [imageObject2, setimageObject2] = useState();
  const [imageObject3, setimageObject3] = useState();
  const [imageObject4, setimageObject4] = useState();
  const [imageUrlIsChanged, setimageUrlIsChanged] = useState(false);
  const [imageUrl2IsChanged, setimageUrl2IsChanged] = useState(false);
  const [imageUrl3IsChanged, setimageUrl3IsChanged] = useState(false);
  const [imageUrl4IsChanged, setimageUrl4IsChanged] = useState(false);

  function getBase64(img, callback) {
    const reader1 = new FileReader();
    reader1.addEventListener('load', () => callback(reader1.result));
    reader1.readAsDataURL(img);
  }

  function getBase(img1, callback) {
    const reader2 = new FileReader();
    reader2.addEventListener('load', () => callback(reader2.result));
    reader2.readAsDataURL(img1);
  }

  const handleChangeImage1 = info => {
    getBase64(info.file.originFileObj, image => setimageUrl(image));
    setimageUrlIsChanged(true);
    setimageObject(info.file.originFileObj);
  };

  const handleChangeImage2 = info => {
    getBase(info.file.originFileObj, image2 => setimageUrl2(image2));
    setimageUrl2IsChanged(true);
    setimageObject2(info.file.originFileObj);
  };

  const handleChangeImage3 = info => {
    getBase64(info.file.originFileObj, image3 => setimageUrl3(image3));
    setimageUrl3IsChanged(true);
    setimageObject3(info.file.originFileObj);
  };

  const handleChangeImage4 = info => {
    getBase(info.file.originFileObj, image4 => setimageUrl4(image4));
    setimageUrl4IsChanged(true);
    setimageObject4(info.file.originFileObj);
  };

  const handleUploadVideo = e => {
    const file = [];
    file.push(e.file);
    console.log('CV File:', file);
    setdisplayVideo(file);
  };

  const onFinishSales = (values, updateHomePageSettings) => {
    console.log('Success:', values);
    //   console.log('updateHomePageSettings:', updateHomePageSettings);

    updateHomePageSettings({
      variables: {
        input: {
          id: orderData.id,
          cover_video_url: displayVideo && displayVideo.length > 0 && displayVideo[0].originFileObj,
          image_1: imageUrlIsChanged ? imageObject : null,
          image_2: imageUrl2IsChanged ? imageObject2 : null,
          image_3: imageUrl3IsChanged ? imageObject3 : null,
          image_4: imageUrl4IsChanged ? imageObject4 : null,
        },
      },
    }).then(({ data }) => {
      console.log('Data submitted===>>>', data);
      if (data && data.edit_homepage_setting) {
        notification.success({
          message: 'Homepage settings Updated Succesfully',
          description: '',
          duration: 3,
        });
        router.push(`/admin/dashboard`);
      }
    });
  };

  const onFinishFailedSales = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
      <div className="contentshadow">
        <div className="text-primary main">
          <Title level={4} className="text-primary">
            {formatMessage({ id: 'component.homesettings.title' })}
          </Title>
          <Row>
            <Query
                query={ADMIN_HOMEPAGE_SETTINGS}
                variables={{
                  status: 1,
                }}
                fetchPolicy="cache-and-network"
                onCompleted={responseData => {
                  console.log('responseData:-', responseData.homepage_detail);
                  setOrderData(responseData.homepage_detail);
                  // setimageUrl(responseData.homepage_detail.data);
                  setdisplayVideo(
                      responseData.homepage_detail.cover_video_url &&
                      responseData.homepage_detail.cover_video_url
                          ? [
                            {
                              uid: 1,
                              name: 'Film',
                              media_url: responseData.homepage_detail.cover_video_url,
                            },
                          ]
                          : [],
                  );
                  setimageUrl(
                      `${STORAGE_URL}${responseData.homepage_detail.home_page_images[0].image_url}`,
                  );
                  setimageUrl2(
                      `${STORAGE_URL}${responseData.homepage_detail.home_page_images[1].image_url}`,
                  );
                  setimageUrl3(
                      `${STORAGE_URL}${responseData.homepage_detail.home_page_images[2].image_url}`,
                  );
                  setimageUrl4(
                      `${STORAGE_URL}${responseData.homepage_detail.home_page_images[3].image_url}`,
                  );
                }}
            >
              {({ data, refetch, loading, error }) => {
                dataRefetch = refetch;
                // console.log('user-list', data.users_list);
                // if(data && !loading){
                if (data && data.homepage_detail) {
                  console.log('insodwe;', data.homepage_detail);

                  return (
                      <Col lg={24}>
                        <Mutation mutation={ADMIN_UPDATE_HOMEPAGE_SETTINGS}>
                          {updateHomePageSettings => {
                            return (
                                <Form
                                    layout="vertical"
                                    className="user-forms"
                                    name="homesettings"
                                    initialValues={{
                                      remember: true,
                                      image1_sales: data.homepage_detail.home_page_images[0].image_url,
                                      image2_sales: data.homepage_detail.home_page_images[1].image_url,
                                      image3_sales: data.homepage_detail.home_page_images[2].image_url,
                                      image4_sales: data.homepage_detail.home_page_images[3].image_url,
                                      title1: data.homepage_detail.home_page_images[0].title,
                                      title2: data.homepage_detail.home_page_images[1].title,
                                      title3: data.homepage_detail.home_page_images[2].title,
                                      title4: data.homepage_detail.home_page_images[3].title,
                                      linktoarticle1: data.homepage_detail.home_page_images[0].external_url,
                                      linktoarticle2: data.homepage_detail.home_page_images[1].external_url,
                                      linktoarticle3: data.homepage_detail.home_page_images[2].external_url,
                                      linktoarticle4: data.homepage_detail.home_page_images[3].external_url,
                                    }}
                                    colon={false}
                                    // eslint-disable-next-line no-undef
                                    onFinish={values => onFinishSales(values, updateHomePageSettings)}
                                    onFinishFailed={onFinishFailedSales}
                                >
                                  <Row gutter={32}>
                                    <Col xs={24} lg={16} md={16}>
                                      <div className="label-texts mb-3">
                                        {formatMessage({ id: 'component.form.uploadcovervideo' })}
                                      </div>
                                      <Form.Item
                                          className="no-margin"
                                          name="uploadcv"
                                          rules={[
                                            {
                                              required: false,
                                              message: (
                                                  <span className="text-lowercase">{`Vennligst fyll inn ${formatMessage(
                                                      {
                                                        id: 'component.form.upload.video',
                                                      },
                                                  )}`}</span>
                                              ),
                                            },
                                          ]}
                                          // getValueFromEvent={handleUploadList}
                                          valuePropName="file"
                                      >
                                        <Upload
                                            onChange={e => handleUploadVideo(e)}
                                            accept=".mp4"
                                            showUploadList={false}
                                        >
                                          <Button className="mb-3">
                                            <UploadOutlined />{' '}
                                            {formatMessage({ id: 'component.form.upload' })}
                                          </Button>
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  {console.log('displayVideo.length:', displayVideo.length)}
                                  {displayVideo.length !== 0 && (
                                      <Row gutter={32} className="mb-3">
                                        <Col xs={24} lg={16}>
                                          {displayVideo.map(item => (
                                              <div className="default_list mt-3 mb-3">
                                                <div>
                                                  <i className="icon-attachment" />
                                                  <span className="ml-2">
                                          {item.originFileObj ? (
                                              <span>{item.name}</span>
                                          ) : (
                                              <a
                                                  href={`${STORAGE_URL}${item.media_url}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                              >
                                                {item.name}
                                              </a>
                                          )}
                                        </span>
                                                </div>
                                                <div
                                                    className="upload-media-delete"
                                                    onClick={() => console.log(item)}
                                                >
                                                  <i className="icon-delete" />
                                                </div>
                                              </div>
                                          ))}
                                        </Col>
                                      </Row>
                                  )}
                                  <Row>
                                    <Col lg={24} md={24} xs={24}>
                                      <Form.Item
                                          name="image1_sales"
                                          label={`${formatMessage({
                                            id: 'component.form.image',
                                          })} 1`}
                                          rules={[
                                            {
                                              required: false,
                                              message: 'Vennligst last opp bilde',
                                            },
                                          ]}
                                      >
                                        <Upload
                                            name="sales_avatar1"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            onChange={handleChangeImage1}
                                        >
                                          {imageUrl ? (
                                              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                                          ) : (
                                              uploadButton
                                          )}
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={16} md={24} xs={24}>
                                      <Form.Item
                                          name="title1"
                                          label={`${formatMessage({
                                            id: 'component.form.title',
                                          })} 1`}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Vennligst fyll inn ${formatMessage({
                                                id: 'component.form.title',
                                              }).toLowerCase()} 1`,
                                            },
                                          ]}
                                      >
                                        <Input
                                            size="large"
                                            placeholder={formatMessage({
                                              id: 'component.form.title',
                                            })}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={16} md={24} xs={24}>
                                      <Form.Item
                                          name="linktoarticle1"
                                          label={formatMessage({
                                            id: 'component.adsandpopups.redirectionurl',
                                          })}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Vennligst fyll inn ${formatMessage({
                                                id: 'component.form.redirectionurl',
                                              }).toLowerCase()}`,
                                            },
                                          ]}
                                      >
                                        <Input
                                            size="large"
                                            placeholder={formatMessage({
                                              id: 'component.adsandpopups.redirectionurl.placeholder',
                                            })}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={24} md={24} xs={24}>
                                      <Form.Item
                                          name="image2_sales"
                                          className="label-texts no-margin"
                                          label={`${formatMessage({
                                            id: 'component.form.image',
                                          })} 2`}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Vennligst last opp bilde eller GIF',
                                            },
                                          ]}
                                      >
                                        <Upload
                                            name="sales-avtar2"
                                            listType="picture-card"
                                            className="avatar-uploader sm_center"
                                            showUploadList={false}
                                            onChange={handleChangeImage2}
                                        >
                                          {imageUrl2 ? (
                                              <img src={imageUrl2} alt="avatar" style={{ width: '100%' }} />
                                          ) : (
                                              uploadButton
                                          )}
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={16} md={24} xs={24}>
                                      <Form.Item
                                          name="title2"
                                          label={`${formatMessage({
                                            id: 'component.form.title',
                                          })} 2`}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Vennligst fyll inn ${formatMessage({
                                                id: 'component.form.title',
                                              }).toLowerCase()} 2`,
                                            },
                                          ]}
                                      >
                                        <Input
                                            size="large"
                                            placeholder={formatMessage({
                                              id: 'component.form.title',
                                            })}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={16} md={24} xs={24}>
                                      <Form.Item
                                          name="linktoarticle2"
                                          label={formatMessage({
                                            id: 'component.adsandpopups.redirectionurl',
                                          })}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Vennligst fyll inn ${formatMessage({
                                                id: 'component.form.redirectionurl',
                                              }).toLowerCase()}`,
                                            },
                                          ]}
                                      >
                                        <Input
                                            size="large"
                                            placeholder={formatMessage({
                                              id: 'component.adsandpopups.redirectionurl.placeholder',
                                            })}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={24} md={24} xs={24}>
                                      <Form.Item
                                          name="image3_sales"
                                          className="label-texts no-margin"
                                          label={`${formatMessage({
                                            id: 'component.form.image',
                                          })} 3`}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Vennligst last opp bilde eller GIF',
                                            },
                                          ]}
                                      >
                                        <Upload
                                            name="sales-avtar3"
                                            listType="picture-card"
                                            className="avatar-uploader sm_center"
                                            showUploadList={false}
                                            onChange={handleChangeImage3}
                                        >
                                          {imageUrl3 ? (
                                              <img src={imageUrl3} alt="avatar" style={{ width: '100%' }} />
                                          ) : (
                                              uploadButton
                                          )}
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={16} md={24} xs={24}>
                                      <Form.Item
                                          name="title3"
                                          label={`${formatMessage({
                                            id: 'component.form.title',
                                          })} 3`}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Vennligst fyll inn ${formatMessage({
                                                id: 'component.form.title',
                                              }).toLowerCase()} 3`,
                                            },
                                          ]}
                                      >
                                        <Input
                                            size="large"
                                            placeholder={formatMessage({
                                              id: 'component.form.title',
                                            })}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={16} md={24} xs={24}>
                                      <Form.Item
                                          name="linktoarticle3"
                                          label={`${formatMessage({
                                            id: 'component.adsandpopups.redirectionurl',
                                          })} 3`}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Vennligst fyll inn ${formatMessage({
                                                id: 'component.form.redirectionurl',
                                              }).toLowerCase()} 3`,
                                            },
                                          ]}
                                      >
                                        <Input
                                            size="large"
                                            placeholder={formatMessage({
                                              id: 'component.adsandpopups.redirectionurl.placeholder',
                                            })}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={24} md={24} xs={24}>
                                      <Form.Item
                                          name="image4_sales"
                                          className="label-texts no-margin"
                                          label={`${formatMessage({
                                            id: 'component.form.image',
                                          })} 4`}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Vennligst last opp bilde eller GIF',
                                            },
                                          ]}
                                      >
                                        <Upload
                                            name="sales-avtar4"
                                            listType="picture-card"
                                            className="avatar-uploader sm_center"
                                            showUploadList={false}
                                            onChange={handleChangeImage4}
                                        >
                                          {imageUrl4 ? (
                                              <img src={imageUrl4} alt="avatar" style={{ width: '100%' }} />
                                          ) : (
                                              uploadButton
                                          )}
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={16} md={24} xs={24}>
                                      <Form.Item
                                          name="title4"
                                          label={`${formatMessage({
                                            id: 'component.form.title',
                                          })} 4`}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Vennligst fyll inn ${formatMessage({
                                                id: 'component.form.title',
                                              }).toLowerCase()} 4`,
                                            },
                                          ]}
                                      >
                                        <Input
                                            size="large"
                                            placeholder={formatMessage({
                                              id: 'component.form.title',
                                            })}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={16} md={24} xs={24}>
                                      <Form.Item
                                          name="linktoarticle4"
                                          label={formatMessage({
                                            id: 'component.adsandpopups.redirectionurl',
                                          })}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Vennligst fyll inn ${formatMessage({
                                                id: 'component.form.redirectionurl',
                                              }).toLowerCase()}`,
                                            },
                                          ]}
                                      >
                                        <Input
                                            size="large"
                                            placeholder={formatMessage({
                                              id: 'component.adsandpopups.redirectionurl.placeholder',
                                            })}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={24} md={24} xs={24}>
                                      <Button
                                          key="submit"
                                          type="primary"
                                          htmlType="submit"
                                          className="admin-forms-btn  mr_15"
                                          size="large"
                                      >
                                        {formatMessage({
                                          id: 'component.form.save',
                                        })}
                                      </Button>
                                      <Link to="/admin/dashboard">
                                        <Button className="admin-forms-btn  mr_15" size="large">
                                          {formatMessage({
                                            id: 'component.form.cancel',
                                          })}
                                        </Button>
                                      </Link>
                                    </Col>
                                  </Row>
                                </Form>
                            );
                          }}
                        </Mutation>
                      </Col>
                  );
                }
                if (loading && !error) {
                  return (
                      <div className="loader-wrapper">
                        <Spin />
                      </div>
                  );
                }
                if (!loading && error) {
                  return <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>;
                }
                return <React.Fragment />;
              }}
            </Query>
          </Row>
        </div>
      </div>
  );
};
export default HomeSettings;
