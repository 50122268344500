import React, {useState} from 'react';
//components
import {ActivityIndicator, Animated, TouchableOpacity, View} from 'react-native';
import {CustomButton, Header, IndicatorViewPager, Label, PagerTabIndicator, Toast} from "components/src/components";
//Third Party
//Utils
import styles from './styles';
import {
    Color,
    CommonStyle,
    Constants,
    Icon,
    IS_WEB,
    openInNewTab,
    showMessage,
    Strings,
    ThemeUtils
} from 'components/src/utils';
import {useMutation, useQuery} from "@apollo/react-hooks";

import {BUYER_SEE_MOVIE} from "components/src/api/buyerQuery";
import {BUYER_MOVING_CONTACT_ME} from "components/src/api/buyerMutation";
import {connect} from "react-redux";
import {SELLER_MOVING_GUIDE} from "../../../../../api/sellerQuery";
import {SELLER_MOVING_GUIDE_COMPLETE_STEP, SELLER_MOVING_GUIDE_GET_DEALS} from "../../../../../api/sellerMutation";


const TABS = [{
    text: Strings.moovingTimeline,
}, {
    text: Strings.webmeglingMoooving,
}]

function MovingGuide(props) {
    const {history, property, navigation} = props;

    const scrollY = new Animated.Value(0);
    const [currentIndex, setIndex] = useState(0)
    const [toast, setToast] = useState()

    const seeMovieQuery = useQuery(BUYER_SEE_MOVIE, {
        variables: {
            status: 1
        }
    });
    const [contactMe, contactMeMutation] = useMutation(BUYER_MOVING_CONTACT_ME, {
        onCompleted: (data) => {
            showMessage(data?.contact_me_notification?.meta?.message,Constants.MessageType.SUCCESS)
        }
    });

    const [completeStep, completeStepMutation] = useMutation(SELLER_MOVING_GUIDE_COMPLETE_STEP, {
        refetchQueries: [{
            query: SELLER_MOVING_GUIDE,
            variables: {
                status: 1,
                propertyId: property?.id
            }
        }]
    });

    const [getDeals, getDealsMutation] = useMutation(SELLER_MOVING_GUIDE_GET_DEALS);

    const {loading, data, error} = useQuery(SELLER_MOVING_GUIDE, {
        variables: {
            status: 1,
            propertyId: property?.id
        }
    });

    const renderButtons = (item) => {

        return (
            <CustomButton title={item.title}
                          disabled={!item?.link_url}
                          onPress={() => {
                              let url = item?.link_url;
                              if (url) {
                                  if (!(url.startsWith("http://") || url.startsWith("https://"))) {
                                      url = `https://${url}`;
                                  }
                                  openInNewTab(url)
                              }
                          }}
                          style={{alignSelf: 'flex-start', minWidth: ThemeUtils.relativeRealWidth(55)}}
                          mt={15}/>
        )
    };

    const renderStepperCustom = (item, index) => {
        return (
            <View style={styles.stepperCont}>
                <View style={styles.dotCont}>
                    {completeStepMutation.loading && index === currentIndex ? (
                        <ActivityIndicator size={ThemeUtils.fontXXLarge} color={Color.PRIMARY}/>
                    ) : (
                        <TouchableOpacity activeOpacity={item?.sellerMovingGuide?.is_completed ? 1 : 0.6}
                                          onPress={() => {
                                              if (!item?.sellerMovingGuide?.is_completed) {
                                                  setIndex(index)
                                                  completeStep({
                                                      variables: {
                                                          id: item.id,
                                                          propertyId: props?.property?.id,
                                                      }
                                                  }).catch(() => {

                                                  })
                                              }
                                          }}>
                            <Icon name={'tickmark'}
                                  size={ThemeUtils.fontXXLarge}
                                  color={item?.sellerMovingGuide?.is_completed ? Color.PRIMARY : Color.PRIMARY_LIGHTER}/>
                        </TouchableOpacity>
                    )}

                    {index !== data?.seller_moving_guide_lists?.length - 1 ? (<View style={styles.line}/>) : null}
                </View>
                <View style={styles.lblCont}>


                    <Label large
                           font_medium>
                        {item.name}
                    </Label>

                    <View style={styles.btnCont}>
                        {item.action === 'LINK' ? item.movingGuideLink.map(renderButtons) : null}
                        {item.action === 'ACTION' ? (
                            <CustomButton title={Strings.getDeals}
                                          loading={getDealsMutation.loading && currentIndex === index}
                                          onPress={() => {
                                              setIndex(index)
                                              getDeals({
                                                  variables: {
                                                      id: item.id
                                                  }
                                              }).then((data) => {
                                                  showMessage(data?.data?.get_deals_mail?.meta?.message,Constants.MessageType.SUCCESS)

                                              }).catch((e) => {
                                                  showMessage(e?.graphQLErrors[0]?.message,Constants.MessageType.FAILED)

                                              })
                                          }}
                                          style={{alignSelf: 'flex-start', minWidth: ThemeUtils.relativeRealWidth(55)}}
                                          mt={15}/>
                        ) : null}
                    </View>
                    <Label small
                           style={CommonStyle.flex}
                           mt={15}
                           mb={20}>
                        {item.description}
                    </Label>
                </View>
            </View>
        );
    };

    const renderIndicator = () => {
        return (
            <PagerTabIndicator tabs={TABS}
                               scrollEnabled/>
        )
    }

    return (
        <View style={CommonStyle.container}>

            <Header animatedTitle={Strings.movingGuide}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Toast ref={(r) => setToast(r)}/>

            <View style={styles.icCont}>
                <Icon name={'moving-guide'}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY}/>
                <Label small
                       mt={15}
                       mb={10}>
                    {Strings.movingGuideInst}
                </Label>
            </View>
            <IndicatorViewPager indicator={renderIndicator()}
                                style={CommonStyle.flex}>

                <View>
                    <Animated.ScrollView scrollEventThrottle={1}>
                        <View style={styles.cont}>
                            {loading && !data ? (
                                    <ActivityIndicator color={Color.PRIMARY}
                                                       style={CommonStyle.loader}/>
                                ) :
                                data?.seller_moving_guide_lists?.map(renderStepperCustom)
                            }

                        </View>
                    </Animated.ScrollView>
                </View>
                <View>
                    <Animated.ScrollView scrollEventThrottle={1}>
                        <View style={styles.cont}>
                            <Label small
                                   mt={15}
                                   mb={10}>
                                {Strings.webmeglingMooovingInst}
                            </Label>
                            <CustomButton title={Strings.order}

                                          onPress={() => {
                                              openInNewTab("https://weiendomsmegling.no/")
                                          }}/>
                            <CustomButton title={Strings.seeMovie}
                                          disabled={!seeMovieQuery?.data?.see_movie_detail}
                                          loading={seeMovieQuery.loading}
                                          borderColor={Color.PRIMARY_LIGHT}
                                          textColor={Color.PRIMARY}
                                          borderWidth={1}
                                          mt={15}
                                          onPress={() => seeMovieQuery.loading ? null : openInNewTab(`${Constants.APIConfig.STORAGE_URL}${seeMovieQuery?.data?.see_movie_detail?.link_url}`)}
                                          bgColor={Color.WHITE}/>

                            <CustomButton title={Strings.contactMeForMoreInformation}
                                          loading={contactMeMutation.loading}
                                          onPress={() => contactMeMutation.loading ? null : contactMe({
                                              variables: {
                                                  propertyId: props.property?.id
                                              }

                                          }).then((response) => {

                                          }).catch(() => {

                                          })}
                                          borderColor={Color.PRIMARY_LIGHT}
                                          textColor={Color.PRIMARY}
                                          borderWidth={1}
                                          mt={15}
                                          mb={15}
                                          bgColor={Color.WHITE}/>

                        </View>
                    </Animated.ScrollView>
                </View>
            </IndicatorViewPager>
        </View>
    )

}


const mapStateToProps = (state) => {
    console.log(state)
    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(MovingGuide);
