import { Col, Layout, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import LogoGreenWM from '../../assets/webmegling_inner_logo.svg';
import './adminFooter.scss';
import {getWmeglingWhiteInnerLogo} from "../../utils/utils";
import moment from 'moment'
const { Footer } = Layout;

export const AdminFooter = () => (
  <Footer className="app-footer">
    <Row>
      <Col xs={24} lg={24}>
        <div className="admin-footer-wrapper">
          <div className="logo-box">
            <Link to="#">
              <img
                  src={getWmeglingWhiteInnerLogo()}
                  className="footer-logo"
                  alt="W Eiendomsmegling"
              />            </Link>
          </div>
          <div>&copy; W Eiendomsmegling Technology AS {moment().year()}</div>
        </div>
      </Col>
    </Row>
  </Footer>
);

export default AdminFooter;
