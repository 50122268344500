import {Dimensions, PixelRatio, Platform} from 'react-native';

const {width, height} = Dimensions.get('window');
const realWidth = height > width ? width : height;
const realHeight = height > width ? height : width;

const isIphoneX = () => {
    return (
        // This has to be iOS duh
        Platform.OS === 'ios' &&
        !Platform.isPad &&
        !Platform.isTVOS &&

        // Accounting for the height in either orientation
        (height === 812 || width === 812)
    );
};

const isIphoneXOrAbove = () => {
    return (
        // This has to be iOS duh
       /* Platform.OS === 'ios' &&
        !Platform.isPad &&
        !Platform.isTVOS &&*/

        // Accounting for the height in either orientation
        ((height === 812 || width === 812) || (height === 896 || width === 896))
    );
};

const getStatusBarHeight = () => {
    return Platform.select({
        ios: isIphoneX() ? 44 : 20,
        android: 0,
    });
};

const responsiveHeight = (scale) => {
    if (!isTablet()) {
        return scale;
    } else {
        return (scale + (scale * 0.25));
    }
};

export const responsiveScale = (num) => {
    if (isTablet()) {
        return responsiveHeight(num);
    } else {
        return relativeRealWidth((num * 2) / 10);
    }
};
const APPBAR_HEIGHT = Platform.OS === 'ios' ? 56 : 56;
const STATUSBAR_HEIGHT = getStatusBarHeight();
const NAV_HEIGHT = APPBAR_HEIGHT + STATUSBAR_HEIGHT;
const myWidth = Dimensions.get('window').width;
const myHeight = Dimensions.get('window').height;

const relativeWidth = num => (myWidth * num) / 100;
const relativeHeight = num => (myHeight * num) / 100;
const relativeRealWidth = (num) => {
    return (realWidth * num) / 100;
};
const relativeRealHeight = num => (realHeight * num) / 100;

const fontBaseXXSmall = 10;
const fontBaseXSmall = 12;
const fontBaseSmall = 13;
const fontBaseNormal = 17;
const fontBaseLarge = 19;
const fontBaseXLarge = 21;
const fontBaseXXLarge = 26;
const fontBaseXXXLarge = 30;

const isTablet = () => {
    let pixelDensity = PixelRatio.get();
    let adjustedWidth = width * pixelDensity;
    let adjustedHeight = height * pixelDensity;
    if (pixelDensity < 2 && (adjustedWidth >= 1000 || adjustedHeight >= 1000)) {
        return true;
    } else {
        return pixelDensity === 2 && (adjustedWidth >= 1920 || adjustedHeight >= 1920);
    }
};

const responsiveFontSize = (fontSize) => {
    let divider = isTablet() ? 600 : 375;
    return Math.round(fontSize * realWidth / divider);
};

const fontXXSmall = responsiveScale(fontBaseXXSmall);
const fontXSmall = responsiveFontSize(fontBaseXSmall);
const fontSmall = responsiveFontSize(fontBaseSmall);
const fontNormal = responsiveFontSize(fontBaseNormal);
const fontLarge = responsiveFontSize(fontBaseLarge);
const fontXLarge = responsiveFontSize(fontBaseXLarge);
const fontXXLarge = responsiveFontSize(fontBaseXXLarge);
const fontXXXLarge = responsiveFontSize(fontBaseXXXLarge);

const CAROUSAL_ITEM_WIDTH = relativeRealWidth(90);
const PROPERTY_CARD_WIDTH = relativeRealWidth(94);
const CAROUSAL_ITEM_HEIGHT = relativeRealHeight(42);


export const circleStyle = {
    height: responsiveScale(10),
    width: responsiveScale(10),
    borderRadius: responsiveScale(5),
};

const HEADER_LEFT_MARGIN = 50;

const NAVIGATION_TAB_BAR_HEIGHT = 50;

const TOP_TAB_NAVIGATOR_HEIGHT = 50;

const HEADER_LEFT_RIGHT_ICON_MARGIN = Platform.OS === 'android' ? 5 : 0;
const COMMON_HORIZONTAL_MARGIN=20;
export default {
    fontXSmall,
    fontSmall,
    fontNormal,
    fontLarge,
    fontXLarge,
    fontXXLarge,
    fontXXXLarge,
    NAV_HEIGHT,
    responsiveHeight,
    relativeWidth,
    relativeHeight,
    STATUSBAR_HEIGHT,
    isIphoneX,
    isIphoneXOrAbove,
    getStatusBarHeight,
    responsiveFontSize,
    relativeRealWidth,
    relativeRealHeight,
    responsiveScale,
    CAROUSAL_ITEM_WIDTH,
    PROPERTY_CARD_WIDTH,
    CAROUSAL_ITEM_HEIGHT,
    NAVIGATION_TAB_BAR_HEIGHT,
    fontXXSmall,
    APPBAR_HEIGHT,
    HEADER_LEFT_RIGHT_ICON_MARGIN,
    TOP_TAB_NAVIGATOR_HEIGHT,
    HEADER_LEFT_MARGIN,
    COMMON_HORIZONTAL_MARGIN,
    isTablet,
};
