import React, {useState} from 'react'
import {Animated, Image, View} from 'react-native'
import {IndicatorViewPager, Label, Ripple} from "components/src/components";
import {Color, CommonStyle, Strings} from "components/src/utils";
import styles from './styles';

import photo from 'components/src/image/photo.png';
import ds_assignment from 'components/src/image/ds_assignment.png';
import marketing_material from 'components/src/image/marketing_material.png';
import display from 'components/src/image/Display.jpg';
import see_finn_ad from 'components/src/image/see_finn_ad.png';
import Financing from 'components/src/image/Financing.png';
import Insurance from 'components/src/image/Insurance.png';

const IMAGES = [photo, ds_assignment, marketing_material, display, see_finn_ad, Financing, Insurance];
const TABS = [{
    text: Strings.PHOTO,
}, {
    text: Strings.STYLING,
}, {
    text: Strings.CGI,
}, {
    text: Strings.FULL_VIEW,
}, {
    text: Strings.DRONE,
}, {
    text: Strings.VIDEO,
}];

const MOCK_SERVICES = [{
    id: "1",
    name: 'Standard Photo Package',
    price: 'Kr 5.000,-',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo.'
}, {
    id: "2",
    name: 'Evening Photo',
    price: 'Kr 3.000,-',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo.'
}];

function FullView(props) {

    const [selectedService, setSelectedService] = useState();
    const [selectedImage, setSelectedImage] = useState(1);


    const renderService = (item, index) => {
        return (
            <Ripple
                style={[styles.serviceCont, selectedService === index ? {backgroundColor: Color.TERNARY_TEXT_COLOR} : null]}
                onPress={() => setSelectedService(index)}
                key={index.toString()}>
                <Label small
                       font_medium
                       color={selectedService === index ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item.name}
                </Label>
                <Label small
                       font_medium
                       color={selectedService === index ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item.price}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {item.description}
                </Label>
            </Ripple>
        )
    };

    return (
        <View style={styles.container}>


            <Animated.ScrollView>
                <View>
                    <View style={styles.imgCont}>
                        <View style={styles.imgCountCont}>
                            <Label small
                                   color={Color.WHITE}>
                                {` ${selectedImage} / ${IMAGES.length} `}
                            </Label>
                        </View>
                        <IndicatorViewPager style={CommonStyle.flex}
                                            onPageSelected={(e) => setSelectedImage(e.position + 1)}>
                            {IMAGES.map(item => (
                                <View>
                                    <Image source={item}
                                           style={CommonStyle.flex}/>
                                </View>
                            ))}
                        </IndicatorViewPager>

                    </View>


                    <View style={styles.lblCont}>
                        <Label large
                               font_medium>
                            {Strings.FULL_VIEW}
                        </Label>
                        <Label small color={Color.SECONDARY_TEXT_COLOR}
                               mt={10}
                               mb={10}>
                            {'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo.'}
                        </Label>
                        {MOCK_SERVICES.map(renderService)}
                    </View>

                </View>
            </Animated.ScrollView>
        </View>

    )
}

export default FullView
