// internal app notifications key

import Strings from "./Strings";

export const notificationKey = {
    LOGOUT: 'LOGOUT',
};

const APP_URL = {
    Android: 'https://play.google.com/store/apps/details?id=com.weiendomsmegling.wmt.android',
    Ios: '',
};

export const Permissions = {
    PHOTO: 'photo',
    CAMERA: 'camera',
    LOCATION: 'location',
    MICROPHONE: 'microphone',
    CONTACTS: 'contacts',
    EVENTS: 'event',
    STORAGE: 'storage',
    PHONE_CALL: 'callPhone',
    READ_SMS: 'readSms',
    RECEIVE_SMS: 'receiveSms',

    //support only IOS
    MOTION: 'motion',
    MEDIA_LIBRARY: 'mediaLibrary',
    SPEECH_RECOGNITION: 'speechRecognition',
    PUSH_NOTIFICATION: 'notification',

    //Android Permission
    READ_EXTERNAL_STORAGE: 'READ_EXTERNAL_STORAGE',
    WRITE_EXTERNAL_STORAGE: 'WRITE_EXTERNAL_STORAGE',
};

export const APIConfig = {

    //Local
    /*BASE_URL: 'https://e3f40a4b6a51.ngrok.io/graphql',
    STORAGE_URL: 'https://e3f40a4b6a51.ngrok.io/storage/',
    DOMAIN:"http://localhost:3000/",*/
    //Dev
    BASE_URL: 'https://devapi.weiendomsmegling.no/graphql',
    STORAGE_URL: 'https://duqywbqe47ltg.cloudfront.net/',
    DOMAIN:"https://agentm.weiendomsmegling.no/",
    FRONT:"https://beta.weiendomsmegling.no/",
    //Live
   /* BASE_URL: 'https://api.weiendomsmegling.no/graphql',
    STORAGE_URL: 'https://cdn.weiendomsmegling.no/',
    DOMAIN: "https://weiendomsmegling.no/",
    FRONT: "https://weiendomsmegling.no/"*/
};

const PriceType = {
    VEDERLEGG: "Vederlagg",
    UTLEGG: "Utlegg"
};


const ORDER_STATUS_SERVER = {
    ORDERED: 1,
    TIMEBOOKED: 2,
    PHOTOTAKEN: 3,
    PHTOUPLOAD: 4,
    PROJECTCOMPLETE: 5,
};

const ORDER_STATUS = {
    ORDERED: 'Ordered',
    TIMEBOOKED: 'Time Booked',
    PHOTOTAKEN: 'Photo Taken',
    PHOTOUPLOAD: 'Photo Uploaded',
    PROJECTCOMPLETE: 'Project Complete',
    INFOADDED: 'Info Added'
};

export const RequestCode = {};

// api response codes
export const ResponseCode = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    UNPROCESSABLE_REQUEST: 422,
    INTERNAL_SERVER_ERROR: 500,
    TOKEN_INVALID: 503,
    NO_INTERNET: 522,
    BAD_GATEWAY: 502,
};

const FieldId = {
    NAME: 'name',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'laseName',
    PASSWORD: 'password',
    EMAIL: 'email',
    ADDRESS: 'address',
    ZIP_CODE: 'zipCode',
    PHONE: 'phone',
    FULL_NAME: 'fullName',
    CITY: 'city',
    OLD_PASSWORD: 'oldPassword',
    CONFIRM_PASSWORD: 'confirmPassword',
    NEW_PASSWORD: 'newPassword',
    BANK_NAME: 'bankName',
    BANK_CONTACT: 'bankContact',
    PHONE_EMAIL: 'phoneEmail',
    ACCOUNT_NO: 'accountNo',
    OTHER_COMM: 'other_comment',
    GENERAL_IMPRESION: 'General Impression of The Property',
    LOCATION: 'Location',
    RECOMMENDED: "Recommended Value Added Upgrades",
    KEYPOINT: 'Key Sales Points',

    VALUE_RATE: 'valueRate',
    LOAN_RATE: 'loadRate',
    BTA: 'BTA',
    BRA: 'BRA',
    PROM: 'PROM',
    CONST_YEAR: 'constructionYear',

    DENOMINATION: 'denomination',
    RESIDUAL_DEB_TKR: 'reschedule_deb',
    CONTACT: 'contact',
    LOAN_NO: 'loadNo',
    ACCOUNT_NO_FOR_TRANSFER: 'accountNoForTransfer',
    PERCENTAGE: 'percentage',
    ROOM_NAME: 'room_name',
    FLOOR: 'floor',
    HAMLING: 'hamling',


};

const MessageType = {
    SUCCESS: 'success',
    FAILED: 'error',
    INFO: 'info',
};

const ServiceType = {
    Photo: 'PHOTO',
    Other: 'OTHER'
}

const RADIO_OPTIONS = [
    {name: Strings.no.yes, value: 'Ja'},
    {name: Strings.no.no, value: 'Nei'},
];

const RADIO_OPTIONS_EXTRA = [
    {name: Strings.no.yes, value: 'Ja'},
    {name: Strings.no.no, value: 'Nei'},
    {name: Strings.no.doNotKnow, value: 'Do Not Know'},
];

const CHAT_TYPE = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    TEXT: 'TEXT',
    LOCATION: 'LOCATION',
};
const CHAT_KEY = {
    CONTENT: 'content',
    SENDER_ID: 'senderId',
    RECEIVER_ID: 'receiverId',
    TIMESTAMP: 'timestamp',
    MESSAGE_TYPE: 'messageType',
    IS_READ: 'isRead',
    PROPERTY_ID: 'propertyId',
    CHAT_ID: 'chatId',
    LAST_MESSAGE: 'lastMessage',
    LAST_MESSAGE_TIMESTAMP: 'lastMessageTimestamp',
};

const TIME_GAP = 1;
export default {
    CHAT_KEY,
    TIME_GAP,
    CHAT_TYPE,
    MessageType,
    APP_URL,
    ServiceType,
    APIConfig,
    notificationKey,
    ResponseCode,
    RequestCode,
    Permissions,
    FieldId,
    RADIO_OPTIONS,
    RADIO_OPTIONS_EXTRA,
    ORDER_STATUS_SERVER,
    ORDER_STATUS,
    PriceType
};
