import gql from 'graphql-tag';

export const ADMIN_PHOTO_USER = gql`
  query user_list($status: Int!, $user_type_id: Int!, $access_level: Int!, $first: Int!) {
    users_list(
      status: $status
      user_type_id: $user_type_id
      access_level: $access_level
      first: $first
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        profile_image
        first_name
        last_name
        email
        mobile
        address
        zipcode
        userZipCodes {
          user_id
          zipcode
        }
      }
    }
  }
`;

export const ADMIN_ZIPCODE_LIST = gql`
  query zipcode_list($status: Int!, $user_type_id: Int!, $user_id: Int!) {
    zipcode_list(status: $status, user_type_id: $user_type_id, user_id: $user_id) {
      id
      postnummer
      poststed
    }
  }
`;
export const ADMIN_AGENT_TEAM = gql`
  query team_lists($status: Int!, $first: Int!) {
    team_lists(status: $status, first: $first) {
      paginatorInfo {
        count
        currentPage
        count
      }
      data {
        id
        title
        status
        teamMembers {
          id
          team_id
          agent_id
          is_primary_agent
          status
          agentUser {
            id
            first_name
            last_name
          }
        }
      }
    }
  }
`;
export const ADMIN_ALL_AGENT = gql`
  query agent_list($input: OfficePropInput) {
    agent_list(input: $input) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
        mobile
        access_level
        title
        profile_image
        is_in_admin_office
        office {
          id
          name
          company_name
        }
        user_detail {
          id
          user_id
          about
          education
          cv_url
          status
        }
        feedback {
          id
          user_id
          feedback
          given_by
          rating
        }
        user_media {
          id
          user_id
          title
          redirect_url
          media_url
          status
        }
        user_document {
          id
          user_id
          doc_url
          doc_type
        }
      }
    }
  }
`;

export const ADMIN_AGENT_DETAIL = gql`
  query agent_detail($id: Int!) {
    agent_detail(id: $id) {
      id
      first_name
      last_name
      email
      mobile
      access_level
      title
      profile_image
      is_in_admin_office
      office {
        id
        name
        company_name
      }
      user_detail {
        id
        user_id
        about
        education
        cv_url
        status
      }
      feedback {
        id
        user_id
        feedback
        given_by
        rating
      }
      user_media {
        id
        user_id
        title
        redirect_url
        media_url
        status
      }
      user_document {
        id
        user_id
        doc_url
        doc_type
      }
    }
  }
`;

export const ADMIN_NEWS_LIST = gql`
  query news($status: Boolean, $orderBy: [OrderByClause!], $first: Int!, $page: Int) {
    news(status: $status, orderBy: $orderBy, first: $first, page: $page) {
      data {
        id
        cover_image_url
        title
        description
        created_at
        video_url
        created_at
        status
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        lastItem
        lastPage
        perPage
      }
    }
  }
`;

export const ADMIN_NEWS_DEATILS = gql`
  query news_detail($status: Int!, $id: Int!) {
    news_detail(id: $id, status: $status) {
      id
      title
      description
      cover_image_url
      video_url
      created_at
      status
    }
  }
`;

export const ADMIN_FAGLIGS_LIST = gql`
  query professionals($first: Int!, $page: Int, $status: Boolean, $orderBy: [OrderByClause!]) {
    professionals(first: $first, page: $page, status: $status, orderBy: $orderBy) {
      data {
        id
        title
        description
        cover_image_url
        video_url
        created_at
        status
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        lastItem
        lastPage
        perPage
      }
    }
  }
`;
export const ADMIN_FAGLIGS_LIST_DETAILS = gql`
  query professional_detail($id: Int!, $status: Int!) {
    professional_detail(id: $id, status: $status) {
      cover_image_url
      title
      description
      created_at
      updated_at
      video_url
    }
  }
`;

export const ADMIN_NEWS_LIST_DETAILS = gql`
  query news_detail($id: Int!, $status: Int!) {
    news_detail(id: $id, status: $status) {
      cover_image_url
      title
      description
      created_at
      updated_at
      video_url
    }
  }
`;

export const ADMIN_FEEDBACK_LIST = gql`
  query feedback_lists($user_id: Int!, $status: Int!, $first: Int!) {
    feedback_lists(user_id: $user_id, status: $status, first: $first) {
      data {
        id
        user_id
        rating
        feedback
        given_by
      }
    }
  }
`;

export const ADMIN_OFFICES_LIST = gql`
  query offices($input: OfficeInput!) {
    offices(input: $input) {
      data {
        id
        name
        city
        image_url
        logo_url
        address
        zipcode
        email
        telephone_no
        call_us
        company_name
        org_number
        widget_code
        banner_1
        banner_2
        small_headline
        big_headline
        ingress
        headline_text_section
        text_section
        status
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const ADMIN_OFFICES_LIST_ONLY = gql`
  query offices_list($status: Boolean!) {
    offices_list(status: $status) {
        id
        name
        city
        image_url
        logo_url
        address
        zipcode
        email
        telephone_no
        call_us
        company_name
        org_number
        widget_code
        banner_1
        banner_2
        small_headline
        big_headline
        ingress
        headline_text_section
        text_section
        status

    }
  }
`;
export const ADMIN_OFFICE_DETAILS = gql`
  query office_detail($id: ID!) {
    office_detail(id: $id) {
      id
      name
      city
      image_url
      logo_url
      address
      zipcode
      email
      telephone_no
      call_us
      company_name
      org_number
      slug
      widget_code
      banner_1
      banner_2
      small_headline
      big_headline
      ingress
      headline_text_section
      text_section
      is_admin_office
      department_id
      officeVitec{
          username
          password
        }
    }
  }
`;

export const ADMIN_VENDORS_LIST = gql`
  query venders($input: VendorInput) {
  venders(input: $input) {
    data {
      id
      first_name
      last_name
      email
      address
      mobile
      profile_image
      city
      zipcode
      status
      access_level
      user_type_id
      office_id
      assign_offices
      office_name
      company_name
      userZipCodes {
        zipcode
      }
      office {
        id
        name
        company_name
      }
      user_detail {
        id
        user_id
        education
        about
        cv_url
        status
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}

`;

export const ADMIN_INSURACE_LIST = gql`
  query insurance_lists(
    $status: Int!
    $first: Int!
    $page: Int!
    $key: String
    $sorting: OrderByClause
  ) {
    insurance_lists(status: $status, first: $first, page: $page, key: $key, sorting: $sorting) {
      data {
        id
        property_id
        name
        email
        phone
        is_agree
        status
        propInsuranceType {
          id
          name
          status
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const ADMIN_LOAN_APPLICATION_LIST = gql`
  query loan_application_list(
    $status: Int!
    $first: Int!
    $page: Int!
    $key: String
    $sorting: OrderByClause
  ) {
    loan_application_list(
      status: $status
      first: $first
      page: $page
      key: $key
      sorting: $sorting
    ) {
      data {
        applicant_name
        email
        phone
        financing_types
        co_applicant_name
        loan_amount
        comments
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const ADMIN_SUPER_USER_LIST = gql`
  query super_user_list($input: userInputs) {
    super_user_list(input: $input) {
      data {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        zipcode
        status
        access_level
        user_type_id
        office_id
        title
        is_in_admin_office
        user_detail {
          id
          user_id
          education
          about
          cv_url
          status
        }
        user_media {
          id
          user_id
          title
          redirect_url
          media_url
          status
        }
        user_document {
          id
          user_id
          doc_url
          doc_type
        }
        office {
          id
          name
        }
        feedback {
          id
          user_id
          rating
          feedback
          given_by
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const ADMIN_MOOVIN_GUIDE_MANAGE_LINKS = gql`
  query manage_link_moving_guide_list($status: Int!) {
    manage_link_moving_guide_list(status: $status) {
      id
      name
      description
      action
      is_service
      buyerMovingGuide {
        property_id
        user_id
        moving_guide_id
        is_completed
        completed_date
      }
      movingGuideLink {
        id
        title
        moving_type
        moving_guide_id
        link_url
      }
    }
  }
`;


export const ADMIN_COMPANY_TYPE_LIST = gql`
  query company_type_list($status: Int!) {
  company_type_list(status:$status){
    id
    name
    status
  }
}
`;

export const ADMIN_MOOVIN_GUIDE_MANAGE_LINK_SEE_MOVIE = gql`
  query see_movie_detail($status: Int!) {
    see_movie_detail(status: $status) {
      id
      title
      link_url
      type
    }
  }
`;

export const ADMIN_MOOVIN_GUIDE_VENDORS_LIST = gql`
  query moving_guide_vendors_list(
    $status: Int!
    $first: Int!
    $page: Int!
    $key: String
    $sorting: OrderByClause
  ) {
    moving_guide_vendors_list(
      status: $status
      first: $first
      page: $page
      key: $key
      sorting: $sorting
    ) {
      data {
        id
        company_type_id
        service_type_id
        first_name
        last_name
        email
        phone
        company_name
        status
        companyType {
          name
        }
        movingServices {
          name
        }
        vendorZipcodes {
          moving_vendor_id
          zipcode
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const ADMIN_OFFICES_B2B_SERIVICES = gql`
  query office_b2b_services($office_id: Int!, $status: Int!) {
    office_b2b_services(office_id: $office_id, status: $status) {
      id
      name
      description
      image
      icon
      price
      is_default
      is_use
      is_free
      free_text
      status
      office_service {
        id
        office_id
        service_subscribe_id
        price
        quantity
        status
      }
    }
  }
`;

export const ADMIN_META_LISTING = gql`
  query meta_page_listing($first: Int!, $page: Int!, $key: String, $sorting: OrderByClause) {
    meta_page_listing(first: $first, page: $page, key: $key, sorting: $sorting) {
      data {
        id
        name
        title
        description
        image_url
        keywords
        page_type
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const ADMIN_META_PAGE_DETAIL = gql`
  query meta_page_detail($page_type: Int!) {
    meta_page_detail(page_type: $page_type) {
      id
      name
      title
      description
      image_url
      keywords
      page_type
    }
  }
`;

export const ADMIN_DASHBOARD = gql`
  query admin_dashboard_stat {
    admin_dashboard_stat {
      sale {
        quarter
        month
        year
      }
      mortgages {
        quarter
        month
        year
      }
      insurances {
        quarter
        month
        year
      }
    }
  }
`;

export const ADMIN_JOBS_LIST = gql`
    query joblist(
      $status: Int!,
      $first: Int!,
      $page: Int!,
      $key: String,
      $sorting: OrderByClause)
      {
    joblist(
      status:$status, first:$first, page:$page,key:$key, sorting:$sorting){
      data{
        id
        title
        company_name
        meta_image
        location
        party
        code
        type
        rightside_description
        looking_for_description
        what_you_offer
        status
        contact_info
        {
          id
          job_id
          name
          email
          mobile_no
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;


export const ADMIN_JOB_DETAIL = gql`
  query joblistdetail($status: Int!, $id: Int!) {
    joblistdetail(status: $status, id: $id) {
      id
      title
      company_name
      location
      party
      code
      meta_image
      type
      rightside_description
      looking_for_description
      what_you_offer
      status
      contact_info{
        id
        job_id
        name
        email
        mobile_no
    }
    }
  }
`;

export const ADMIN_HOMEPAGE_SETTINGS = gql`
  query homepage_detail($status: Int!){
  homepage_detail(status:$status){
    id
    cover_video_url
    home_page_images{
      id
      title
      home_page_setting_id
      image_url
      external_url
    }
  }
}
`;
