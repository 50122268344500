import gql from 'graphql-tag';

export const STYLIST_ORDER_LIST = gql`
  query stylistOrders($input: OrderListInput!) {
  stylish_orders(input: $input) {
    data {
      id
      property_id
      stylish_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      order_status
      styling_type {
        id
        name
      }
      property {
        id
        seller_id
        oppdrags_nummer
        street_adress
          city
          zipcode
        seller {
          id
          first_name
          last_name
          email
          address
          mobile
          profile_image
          city
          zipcode
          status
        }
        orderrate {
          id
          property_id
          takst_id
          order_date
          earliest_start_time
          latest_start_time
          comment
          order_status
        }
      }
      stylish {
        id
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        zipcode
        status
      }
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}`;

export const STYLISH_ORDER_INFORMATION = gql`
  query style_order($id: ID!) {
    style_order(id: $id) {
      orderstyle {
        id
        property_id
        order_date
        order_status
        earliest_start_time
        latest_start_time
        comment
        styling_type {
          id
          name
        }
        property {
          id
          oppdrags_nummer
          street_adress
          city
          zipcode
          seller {
            id
            first_name
            last_name
            mobile
            email
          }
        }
      }
    }
  }
`;


export const STYLIST_CALENDAR_EVENT = gql`
query stylistCalendarEvent {
  stylish_calendar_event {
    id
    property_id
    created_user_id
    title
    description
    start_datetime
    end_datetime
    event_type
    user_type
    property{
        street_adress
        city
        zipcode
    }
  }
}`;

