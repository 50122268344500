import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.PRIMARY_LIGHT,
        justifyContent: 'center'
    },
    dialogCont: {
        marginHorizontal: 20,
        borderRadius: 8,
        backgroundColor: Color.WHITE
    },
    divider: {
        width: 1,
        backgroundColor: Color.PRIMARY_LIGHTER
    },
    btnCont: {
        flexDirection: 'row',
    },
    lblTitle: {
        paddingTop: 10
    },
    IconsView: {
        backgroundColor: Color.GRAY,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf:'flex-start',
        padding:10,
        marginEnd:10,
        borderRadius:4
    },
    MainView: {
        marginTop:10,
        flexDirection: 'row',

    },
    btn:{
        flex:1,
        height:40,
        alignItems: 'center',
        justifyContent:'center'
    }

})
