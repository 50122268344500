import Cookie from 'js-cookie';
import moment from 'moment';
import pathRegexp from 'path-to-regexp';
import { parse } from 'querystring';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = path => reg.test(path);
export const isAntDesignPro = () => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};
export const getPageQuery = () => parse(window.location.href.split('?')[1]);
/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */

export const getAuthorityFromRouter = (router = [], pathname) => {
  const authority = router.find(
    ({ routes, path = '/' }) =>
      (path && pathRegexp(path).exec(pathname)) ||
      (routes && getAuthorityFromRouter(routes, pathname)),
  );
  if (authority) return authority;
  return undefined;
};
export const getRouteAuthority = (path, routeData) => {
  let authorities;
  routeData.forEach(route => {
    // match prefix
    if (pathRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
      if (route.authority) {
        authorities = route.authority;
      } // exact match

      if (route.path === path) {
        authorities = route.authority || authorities;
      } // get children authority recursively

      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes) || authorities;
      }
    }
  });
  return authorities;
};

export const handleVideoResponse = url => {
  if (url.toLowerCase().indexOf("youtube") > 0) {
    return url.replace("watch?v=", "embed/").replace('&feature=youtu.be','?controls=0&rel=0')
  }
  const videoID = url.split("/").pop();
  return `https://player.vimeo.com/video/${videoID}?title=0&byline=0&portrait=0`;
}

export const setSignupData = data => {
  let dataFromCookie = localStorage.getItem('signupData');
  if (dataFromCookie === null || dataFromCookie === '' || dataFromCookie === undefined) {
    dataFromCookie = '{}';
  }
  const oldData = JSON.parse(dataFromCookie);

  oldData[data.key] = data.value;
  Cookie.set('signupData', oldData);
  localStorage.setItem('signupData', JSON.stringify(oldData));
  console.log('Data in localStorage==>>', oldData);
};
export const getSignupData = () => JSON.parse(localStorage.getItem('signupData'));

export const getSignupDataForServer = () => {
  const signupData = getSignupData();
  console.log('Singup Datain local==>>', signupData);
  const dataForServer = {
    form_one: {
      services: signupData.services,
      selectedServiceList: signupData.selectedServiceList,
      remuneration: signupData.remuneration,
      ownership_id: signupData.ownership_id,
      email: signupData.loginData.email,
      mobile: signupData.loginData.mobile,
      password: signupData.loginData.password,
      initial_session: signupData.initial_session,
      assigned_agent: signupData.propertyData.assigned_agent,
    },
    propertyData: signupData.propertyData.propertiesData,
    owner_number: 1,
    selected_property: signupData.selected_property,
    owners_list: signupData.owners_list,
    ownerAgreement_sign: signupData.ownerAgreement_sign,
    coOwnerDetails: signupData.coOwnerDetails,
  };
  console.log('Data For Server===>>>', dataForServer);
  return dataForServer;
};

export const clearSignupData = () => localStorage.removeItem('signupData');

export const encryptId = id => btoa(btoa(id));

export const decryptId = id => atob(atob(id));

export const removeHTMLTags = str => {
  if ((str===null) || (str===''))
    return false;
  else
    str = str.toString();
  // return str.replace( /(<([^>]+)>)/ig, '');
  return str.replace(/<(?!br\s*\/?)[^>]+>/g, '');
}

export const formatPhoneNo = number => {
  if (number) {
    return number.replace(/(\d{3})(\d{3})(\d)/, '$1-$2-$3');
  }
  return '-';
};

export const formatCurrency = price => {
  if (price !== undefined && price !== null) {
     return price.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return price;
};

export const formatDisplayDate = date => {
  if (date) {
    return moment
      .utc(date)
      .local()
      .format('DD-MM-YYYY');
  }
  return '-';
};

export const formatDisplayTime = time => {
  if (time) {
    return moment
      .utc(time, 'HH:mm:ss')
      .local()
      .format('HH:mm');
  }
  return '-';
};

const colorCode = [
  { code: 1, letter: 'A' },
  { code: 2, letter: 'B' },
  { code: 3, letter: 'C' },
  { code: 4, letter: 'D' },
  { code: 5, letter: 'E' },
  { code: 6, letter: 'F' },
  { code: 7, letter: 'G' },
  { code: 8, letter: 'H' },
  { code: 9, letter: 'I' },
];

// eslint-disable-next-line consistent-return
export const formatEnergyCode = energyCode => {
  if (energyCode > 0) {
    return colorCode.find(item => item.code === energyCode && item.letter).letter;
  }
};

export const setSelectedProperty = item => {
  if (item) {
    localStorage.setItem('selectedProperty', JSON.stringify(item));
  }
};

export const getSelectedProperty = () => JSON.parse(localStorage.getItem('selectedProperty'));


export const sorter = (key, order = "asc") => function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  }
