import React from 'react'
import {ActivityIndicator, Animated, FlatList, View} from 'react-native'

import {withFirebase} from "components/src/HOC/Firebase";
import {Query} from "@apollo/react-components";
import {connect} from "react-redux";
import moment from 'moment'

import {
    Color,
    CommonStyle,
    Constants,
    encryptId,
    getDateTimeForNotification,
    Icon,
    IS_WEB,
    NOTIFICATION_TYPE,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
    UserType
} from "components/src/utils";
import {Header, Hr, IndicatorViewPager, Label, PagerTabIndicator, Ripple} from "components/src/components";
import styles from './styles'
import 'moment/locale/nb'
import {SELLER_PROPERTY_LIST} from "components/src/api/sellerQuery";
import {BUYER_PROPERTIES} from "components/src/api/buyerQuery";
import {setCurrentProperty} from "components/src/redux/action";

moment.locale('nb')


/*BAkI*/
/*
* [
  'SELLER_ENERGY_LABELING',
  'SELLER_RATE_ORDER',
  'SELLER_INSPECTION_ORDER',
  'SELLER_STYLING_ORDER',
  'SELLER_PHOTO_ORDER',
  'SELLER_INTERVIEW',
  'INSPECTION_ORDER_CONFIRM',
  'PHOTO_ORDER_COMPLETED',
  'RATE_ORDER_COMPLETED',
  'INSPECTION_ORDER_COMPLETED',
  'SELLER_ADDED_VIEW',
  'SELLER_APPROVED_FINN_NO',
  'SELLER_APPROVED_DS',
  'SELLER_APPROVED_MARKETING_MATERIAL',
  'SELLER_ADDED_COMMENT_FINN_NO',
  'SELLER_ADDED_COMMENT_DS',
  'SELLER_ADDED_COMMENT_MARKETING_MATERIAL',
  'AGENT_UPLOADED_DOC_BUYER',
  'SELLER_ADD_ADDITIONAL_SERVICES',
  'ADMIN_ADDED_FEGLING',
  'ADMIN_ADDED_NEWS',
  'CUSTOM_NOTIFICATION',
  'SEND_BY_AGENT',
  'SELLER_BOOK_INSPECTION_NOTIFY',
  'SELLER_SUBMIT_SETTLEMENT_INFO',
  'BUYER_REGISTER',
  'AGENT_PHOTO_DELIVER',
  'AGENT_TAKST_DELIVER',
  'INSPECTION_DELIVER'
]*/

const getRouteForSellerNotification = (item, property) => {
    let route;
    switch (item.notificationType) {
        case NOTIFICATION_TYPE.PHOTO_ORDER_CONFIRM:
        case NOTIFICATION_TYPE.PHOTO_UPLOAD:
        case NOTIFICATION_TYPE.SELLER_PHOTO_COMMENT:
        case NOTIFICATION_TYPE.AGENT_PHOTO_DELIVER:
        case NOTIFICATION_TYPE.SELLER_ORDER_PHOTO_NOTIFY:
            if (!property?.order_photo) {
                route = Routes.Photos;
                break;
            }
            if (property?.order_photo?.order_status === Constants.ORDER_STATUS.ORDERED ||
                property?.order_photo?.order_status === Constants.ORDER_STATUS.TIMEBOOKED) {
                route = Routes.ThankYouPhotos;
                break;
            }
            route = Routes.UploadedPhotos;
            break;

        case NOTIFICATION_TYPE.TAKST_ORDER_CONFIRM:
        case NOTIFICATION_TYPE.RATE_DETAILS_ADDED:
        case NOTIFICATION_TYPE.SELLER_RATE_DETAILS_COMMENT:
        case NOTIFICATION_TYPE.AGENT_TAKST_DELIVER:
        case NOTIFICATION_TYPE.SELLER_ORDER_TAKST_NOTIFY:
            if (!property?.orderrate) {
                if (property?.order_rate_detail) {
                    route = Routes.ViewDetailsRate
                    break;
                }
                route = Routes.Rate;
                break;
            }
            if (property?.orderrate?.order_status === Constants.ORDER_STATUS.ORDERED ||
                property?.orderrate?.order_status === Constants.ORDER_STATUS.TIMEBOOKED) {
                route = Routes.ThankYouRate;
                break;
            }
            route = Routes.ViewDetailsRate;
            break;
        case NOTIFICATION_TYPE.AGENT_UPLOADED_DOC_SELLER:
            route = Routes.Document
            break;
        case NOTIFICATION_TYPE.MOVING_GUIDE_CONTACT_ME_NOTIFY:
            route = Routes.BuyerMovingGuide
            break;
        case NOTIFICATION_TYPE.SELLER_APPROVE_DS_NOTIFY:
        case NOTIFICATION_TYPE.AGENT_ADDED_DS:
            route = Routes.DigitalSalesAssignment
            break;
        case NOTIFICATION_TYPE.SELLER_APPROVE_FINN_NOTIFY:
        case NOTIFICATION_TYPE.AGENT_ADDED_FINN_NO:
            route = Routes.SeeFinnAd
            break;
        case NOTIFICATION_TYPE.SELLER_APPROVE_MARKETING_MATERIAL_NOTIFY:
        case NOTIFICATION_TYPE.AGENT_ADDED_MARKETING_MATERIAL:
            route = Routes.MarketingMaterials
            break;
        case NOTIFICATION_TYPE.SELLER_ENERGY_LABELING_NOTIFY:
            route = Routes.EnergyLabeling
            break;
        case NOTIFICATION_TYPE.SELLER_SETTLEMENT_INFO_NOTIFY:
            route = Routes.SettlementInformation
            break;
        case NOTIFICATION_TYPE.SELLER_SUBMIT_INTERVIEW_NOTIFY:
            route = Routes.SellerInterview
            break;
        default:
            return `#`;
    }
    return IS_WEB ? `/${location.pathname.split('/')[1]}/${route}` : route
}

const getRouteForStylistNotification = (item) => {
    switch (item.notificationType) {
        case NOTIFICATION_TYPE.SELLER_STYLING_ORDER:
            return IS_WEB ? `/${location.pathname.split('/')[1]}/${Routes.StylistOrderInfo}/${encryptId(item.referenceId)}` : Routes.StylistOrderInfo
        default:
            return `#`;
    }
}

const getRouteForTakstNotification = item => {
    console.log(item.notificationType)
    switch (item.notificationType) {
        case NOTIFICATION_TYPE.SELLER_RATE_ORDER:
        case NOTIFICATION_TYPE.SELLER_RATE_DETAILS_COMMENT:
            return IS_WEB ? `/${location.pathname.split('/')[1]}/${Routes.TakstOrderDetails}/${encryptId(item.referenceId)}/${encryptId(item.propertyId)}` : Routes.TakstOrderDetails
        default:
            return "#";
    }
};


const getRouteForPhotoNotification = item => {
    switch (item.notificationType) {
        case NOTIFICATION_TYPE.SELLER_PHOTO_ORDER:
        case NOTIFICATION_TYPE.SELLER_PHOTO_COMMENT:
        case NOTIFICATION_TYPE.PHOTO_UPLOAD:
            return IS_WEB ? `/${location.pathname.split('/')[1]}/${Routes.PhotoGrapherOrderDetails}/${encryptId(item.referenceId)}` : Routes.PhotoGrapherOrderDetails
        default:
            return "#";
    }
};


const getRouteForBuyerNotification = item => {
    switch (item.notificationType) {
        case NOTIFICATION_TYPE.AGENT_UPLOADED_DOC_BUYER:
            return IS_WEB ? `/${location.pathname.split('/')[1]}/${Routes.BuyerDocument}` : Routes.BuyerDocument
        default:
            return `#`;
    }
};


class Notifications extends React.Component {

    constructor(props) {
        super(props)
        this.database = props.database().ref('v1');
        this.state = {
            notifications: [],
            messages: [],
            generalNotificationCount: 0,
            chatNotificationCount: 0,
            generalNotificationLoading: true,
            chatNotificationLoading: true,
            selectedPage: 0
        }
    }

    getRouteForNotification = (item, property) => {
        switch (this.props.user?.user_type_id) {
            case UserType.Seller:
                return getRouteForSellerNotification(item, property)
            case UserType.Stylist:
                return getRouteForStylistNotification(item)
            case UserType.Takst:
                return getRouteForTakstNotification(item)
            case UserType.Photographer:
                return getRouteForPhotoNotification(item)
            case UserType.Buyer:
                return getRouteForBuyerNotification(item)
        }

    };

    openRoute = (item, properties) => {
        console.log(item,"ITEM")
        let property = this.props.property;
        if (item.notificationType) {
            if (!item.isRead) {
                this.database.child('generalNotifications').child(`${this.props.user.id}`).child(item.key).update({isRead: true})
            }
            if (property?.id !== parseInt(item.propertyId, 10)) {
                property = properties.find(property => property.id === parseInt(item.propertyId, 10) || property.property_id === parseInt(item.propertyId, 10));
                this.props.setCurrentProperty(property)
                if (IS_WEB) {
                    localStorage.setItem('selectedProperty', JSON.stringify(property))
                }
                showMessage("Selected property is changed",Constants.MessageType.INFO)
            }
            if (IS_WEB) {
                this.props.history.push(this.getRouteForNotification(item, property))
            } else {
                this.props.navigation.navigate(this.getRouteForNotification(item, property))
            }
        } else {
            this.database.child('notificationList').child(`${this.props.user.id}`).child(item.key).update({isRead: true})
            if (IS_WEB) {
                this.props.history.push(`/${location.pathname.split('/')[1]}/${Routes.ChatDetails}/${encryptId(item.propertyId)}/${encryptId(item.senderId)}`)
            } else {
                this.props.navigation.navigate(Routes.ChatDetails, {
                    receiverId: item.receiverId
                })

            }
        }
        /* switch (notificationId) {
             case NOTIFICATIONS.SellerEnergyLabeling:
                 return Routes.EnergyLabeling
         }*/
    }

    componentDidMount() {
        this.generalMessageRef = this.database.child('generalNotifications').child(`${this.props.user.id}`).orderByChild("timestamp");
        this.chatMessageRef = this.database.child('notificationList').child(`${this.props.user.id}`).orderByChild("timestamp");
        this.generalMessageRef.on('value', (e) => {
            let notifications = []
            e.forEach((child) => {
                notifications.push({
                    ...child.val(),
                    key: child.key
                })
            });
            console.log(this.props.user, "NOTIFI")
            this.setState({
                notifications: notifications.slice().reverse(),
                generalNotificationLoading: false,
                generalNotificationCount: notifications.filter(item => !item.isRead).length
            })
        }, () => {
            this.setState({
                generalNotificationLoading: false,
            })
        })
        this.chatMessageRef.on('value', (e) => {
            let notifications = []
            e.forEach((child) => {
                notifications.push({
                    ...child.val(),
                    key: child.key
                })
            });
            this.setState({
                messages: notifications.slice().reverse(),
                chatNotificationLoading: false,
                chatNotificationCount: notifications.filter(item => !item.isRead).length
            })
        }, () => {
            this.setState({
                chatNotificationLoading: false,
            })
        })
    }

    componentWillUnmount() {
        this.generalMessageRef && this.generalMessageRef.off()
        this.chatMessageRef && this.chatMessageRef.off()
    }

    clearAll = () => {
        this.database.child('generalNotifications').child(`${this.props.user.id}`).remove()
    }

    renderNotification = (item, index, properties) => {
        return (
            <Ripple key={item.key}
                    onPress={() => this.openRoute(item, properties)}>
                <View style={[styles.notiCont]}
                      key={index.toString()}>
                    <View style={[styles.dot, {backgroundColor: item.isRead ? Color.TRANSPARENT : Color.RED}]}/>
                    <View style={CommonStyle.container}>
                        <Label small
                               style={CommonStyle.flex}>
                            {item.title}
                        </Label>
                        <Label small
                               mt={5}
                               color={Color.DARK_GRAY}>
                            {/*{moment(item.timestamp).diff(moment(), 'd') > 2 ? moment(item.timestamp).format(DateUtils.MMM_dd_mm_yyyy) : moment(item.timestamp).fromNow(true)}*/}
                            {getDateTimeForNotification(item.timestamp)}
                        </Label>
                    </View>
                </View>
                <Hr/>
            </Ripple>
        )
    }

    _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={[{
            text: `${Strings.notification} ${this.state.generalNotificationCount ? '( ' + this.state.generalNotificationCount + ' )' : ""}`,
        }, {
            text: `${Strings.messages} ${this.state.chatNotificationCount ? '( ' + this.state.chatNotificationCount + ' )' : ""}`,
        }]}/>;
    };

    renderClearAll = () => {
        if (this.state.selectedPage) {
            return null
        }
        return (
            <Label color={Color.TERNARY_TEXT_COLOR}>
                {Strings.clearAll}
            </Label>
        )
    }

    renderEmptyNotification = () => {
        return (
            <Label small
                   font_medium
                   mt={20}
                   align={'center'}>
                {'Ingen data'}
            </Label>
        )
    }

    render() {
        const {messages, notifications} = this.state
        let currentUser = this.props.user.user_type_id

        return (
            <View style={CommonStyle.container}>
                <Header navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedTitle={Strings.notification}
                        animatedValue={new Animated.Value(0)}
                        onPressRightFirst={this.clearAll}
                        renderHeaderFirst={() => this.renderClearAll()}/>
                <Query query={currentUser === UserType.Seller ? SELLER_PROPERTY_LIST : BUYER_PROPERTIES}
                       variables={{
                           status: 1
                       }}
                       skip={currentUser !== UserType.Seller && currentUser !== UserType.Buyer}>

                    {({loading, data, error}) => {
                        return (
                            <>
                                <View style={styles.icCont}>
                                    <Icon size={ThemeUtils.fontXXLarge}
                                          name={'notifications'}
                                          color={Color.PRIMARY_DARK}/>
                                </View>
                                <IndicatorViewPager indicator={this._renderTabIndicator()}
                                                    onPageSelected={(e) => this.setState({selectedPage: e.position})}
                                                    style={CommonStyle.flex}>
                                    <View>
                                        {this.state.generalNotificationLoading || loading ? (
                                            <ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>
                                        ) : (
                                            <FlatList data={notifications}
                                                      ListEmptyComponent={this.renderEmptyNotification}
                                                      renderItem={({item, index}) => this.renderNotification(item, index, currentUser === UserType.Seller ? data?.seller_property_list ?? [] : data?.buyer_properties ?? [])}/>
                                        )}

                                    </View>
                                    <View>
                                        {this.state.chatNotificationLoading ? (
                                            <ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>
                                        ) : (
                                            <FlatList data={messages}
                                                      ListEmptyComponent={this.renderEmptyNotification}
                                                      renderItem={({item, index}) => this.renderNotification(item, index, currentUser === UserType.Seller ? data?.seller_property_list ?? [] : data?.buyer_properties ?? [])}/>
                                        )}
                                    </View>
                                </IndicatorViewPager>
                            </>
                        )
                    }}

                </Query>
            </View>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        property: state.property
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentProperty: (property) => dispatch(setCurrentProperty(property)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(Notifications))
