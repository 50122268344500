import {StyleSheet} from 'react-native';
import {Color} from '../../../utils';

const containerHorizontalMargin = 20;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    titleContainer: {
        paddingVertical:20,
        flexDirection: 'row',
        alignItems: 'center'
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center',
        justifyContent: 'space-around',
    },
    deviderline: {
        flexDirection: 'row',
        borderColor: '#3A173C',
        width: "100%",
        borderWidth: 0.5,
    },
    MainView: {
        flex: 1,
        justifyContent: 'center',

        flexDirection: 'row'

    },
    OrderedView: {
        alignSelf: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5,
        borderWidth: 2,
        justifyContent: 'center',
        alignContent: 'center',

        borderRadius: 4
    },
    TimeView: {
        alignSelf: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        borderWidth: 2, padding: 5,
        borderColor: Color.BORDER_ORANGE,
        justifyContent: 'center',
        alignContent: 'center',

        borderRadius: 4
    },
    PhotoView: {
        alignSelf: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        borderWidth: 2, padding: 5,
        borderColor: Color.BORDER_BLUE,
        justifyContent: 'center',
        alignContent: 'center',

        borderRadius: 4
    },
    ProjectView: {
        alignSelf: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        borderWidth: 2, padding: 5,
        borderColor: Color.BORDER_GREEN,
        justifyContent: 'center',
        alignContent: 'center',

        borderRadius: 4
    },
    IconView: {
        flexDirection: 'column'
    },

    icon: {
        padding: 5,
        marginStart:15
    }


});

export {styles};
