/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
    InputNumber,
  Checkbox,
  Col,
  Divider,
    Spin,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Upload,
} from 'antd';
import React, { useState } from 'react';
// import { decryptId } from '@/utils/utils';
import { Mutation, Query } from 'react-apollo';
// import { decryptId } from '@/utils/utils';
import { useHistory } from 'react-router-dom';
// import { decryptId } from '@/utils/utils';
import { formatMessage } from 'components/src/utils';
import { decryptPassword } from '../../../../../../utils/utils';
import {
  ADMIN_REMOVE_DEPARTMENT_ID,
  ADMIN_UPDATE_OFFICE,
  ADMIN_UPDATE_OFFICE_STATUS,
} from '../../../../../../../mutation/AdminMutation';
import { ADMIN_OFFICE_DETAILS } from '../../../../../../../query/AdminQuery';
import { STORAGE_URL } from '../../../../../../utils/constant';
import '../../editofficeceo.scss';

// import { isDateProfilesEqual } from '@fullcalendar/core/DateProfileGenerator';

// import { from } from 'apollo-link';

// let optionsCeo = [];
// let optionsController = [];
// let optionsAgent = [];
// { label: 'Emmily Peterson', value: '1' },
// { label: 'Denver Mazur', value: '2' },
// { label: 'Danial Neilson', value: '3' },
// ];
let dataRefetch;
const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};
// const uploadButton = (
//   <div>
//     <PlusOutlined className="uploadplus" />
//     <div className="ant-upload-text label-texts">
//       {formatMessage({
//         id: 'component.form.upload',
//       })}
//     </div>
//   </div>
// );

let formData;
const EditOfficesDetails = props => {
  let router=useHistory();
  const [orderData, setOrderData] = useState('');

  const { id } = props;
  console.log('props:', props);

  const [imageUrl, setimageUrl] = useState(``);
  const [logoUrl, setLogoUrl] = useState(``);
  const [logoObject, setLogoObject] = useState(``);
  const [imageObject, setimageObject] = useState(``);
  const [bannerUrl1, setbannerUrl1] = useState(``);
  const [bannerUrl2, setbannerUrl2] = useState(``);
  const [bannerObject1, setbannerObject1] = useState(``);
  const [bannerObject2, setbannerObject2] = useState(``);
  const [deleteModal, setdeleteModal] = useState(false);
  const [isimageUrlChanged, setisimageUrlChanged] = useState(false);
  const [islogoUrlChanged, setisLogoUrlChanged] = useState(false);
  const [isB1Chnaged, setisB1Chnaged] = useState(false);
  const [isB2Chnaged, setisB2Chnaged] = useState(false);
  const [isAdminOffice, setisAdminOffice] = useState(false);
  const [departmentIdValue, setdepartmentIdValue] = useState();
  const [departmentList, setdepartmentList] = useState([]);
  const [displayList, setdisplayList] = useState([]);
  const [stroreDepartmentID, setStroreDepartmentID] = useState([]);
  const [listFromApi, setlistFromApi] = useState([]);

  let departmentIdList = [];

  const addDepartmentIdList = ids => {
    if (ids) {
      departmentIdList = departmentList;
      departmentIdList = [];
      departmentIdList.push(ids);
      const display = [...departmentIdList, ...displayList];
      console.log('departmentIdList-', departmentIdList);
      setdepartmentList(departmentIdList);
      setdisplayList(display);

      console.log('a-', display); // [1,2,3]
      console.log('b-', listFromApi); // [1,2]

      const a = [];
      display.map(da => {
        const index = listFromApi.findIndex((currentValue, index, array) => currentValue === da);
        console.log('index[]:', index);
        if (index === -1) {
          a.push(da);
        }
      });
      console.log('A[]:', a);

      const notPresentInData = displayList.filter(val => !listFromApi.includes(val));
      console.log('notPresentInData:', notPresentInData);

      console.log('filtered,', a);
      setStroreDepartmentID(a);
      setdepartmentIdValue();
    }
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handleChange = info => {
    getBase64(info.file.originFileObj, image => setimageUrl(image));
    setimageObject(info.file.originFileObj);
    setisimageUrlChanged(true);
  };
  const handleChangeOfficeLogo = info => {
    getBase64(info.file.originFileObj, image => setLogoUrl(image));
    setLogoObject(info.file.originFileObj);
    setisLogoUrlChanged(true);
  };
  const handleChangeBanner1 = info => {
    getBase64(info.file.originFileObj, image => setbannerUrl1(image));
    setbannerObject1(info.file.originFileObj);
    setisB1Chnaged(true);
  };
  const handleChangeBanner2 = info => {
    getBase64(info.file.originFileObj, image => setbannerUrl2(image));
    setbannerObject2(info.file.originFileObj);
    setisB2Chnaged(true);
  };
  const onCheckboxChange = e => {
    console.log(isAdminOffice);
    console.log('checked:', e.target.checked);
    setisAdminOffice(!isAdminOffice);
  };

  const onFinish = (values, updateOffices) => {
    // TODO: add the data from the success form response below
    const inputValue = {
      id,
      name: values.officename,
      city: values.city,
      image_url: isimageUrlChanged ? imageObject : null,
      logo_url: islogoUrlChanged ? logoObject : null,
      banner_1: isB1Chnaged ? bannerObject1 : null,
      banner_2: isB2Chnaged ? bannerObject2 : null,
      company_name: values.companyname,
      org_number: values.orgnumber,
      slug: values.officeurl,
      widget_code: values.tawktowidget,
      small_headline: values.smallheadline,
      big_headline: values.bigheadline,
      ingress: values.ingress,
      headline_text_section: values.headlinetextsection,
      text_section: values.textsection,
      address: values.address,
      zipcode: values.zipcode,
      telephone_no: values.phone,
      email: values.sendemail,
      call_us: values.callus,
      send_email: values.sendemail,
      is_admin_office: isAdminOffice,
    };

    console.log('Success:', values);
    console.log('isInt(values.departmentId):', [...stroreDepartmentID].toString());
    // eslint-disable-next-line no-lone-blocks
    /* if (stroreDepartmentID.length > 0 && isInt(values.departmentId)) {
      inputValue.department_id = [...stroreDepartmentID, ...[values.departmentId]].toString();
    } else if (stroreDepartmentID.length > 0) {
      inputValue.department_id = stroreDepartmentID.toString();
    } else if (isInt(values.departmentId)) {
      inputValue.department_id = values.departmentId.toString();
    } */
    if (stroreDepartmentID.length > 0) {
      inputValue.department_id = stroreDepartmentID.toString();
    }
    if (values.vitecusername && values.password && values.installationid) {
      inputValue.username = values.vitecusername;
      inputValue.password = values.password;
      inputValue.installer_id = values.installationid;
    }
    if (values.vitecusername && values.password && !values.installationid) {
      inputValue.username = values.vitecusername;
      inputValue.password = values.password;
    }
    if (values.vitecusername && !values.password && values.installationid) {
      inputValue.username = values.vitecusername;
      inputValue.installer_id = values.installationid;
    }
    if (values.vitecusername && !values.password && values.installationid) {
      inputValue.username = values.vitecusername;
      inputValue.installer_id = values.installationid;
    }
    if (!values.vitecusername && values.password && values.installationid) {
      inputValue.password = values.password;
      inputValue.installer_id = values.installationid;
    }
    if (values.vitecusername && !values.password && !values.installationid) {
      inputValue.username = values.vitecusername;
    }
    if (!values.vitecusername && values.password && !values.installationid) {
      inputValue.password = values.password;
    }
    if (!values.vitecusername && !values.password && values.installationid) {
      inputValue.installer_id = values.installationid;
    }

    // eslint-disable-next-line no-unused-expressions

    updateOffices({
      variables: {
        input: inputValue,
      },
    }).then(data => {
      console.log('Data submitted===>>>', data);

      if (data && data.data.update_office) {
        notification.success({
          message: 'Innspillingen ble oppdatert',
          description: '',
          duration: 3,
        });
        router.push('/admin/kontorer');
      }
    });
  };

  const onRemove = (value, removeDepartmentId) => {
    if (listFromApi.includes(value)) {
      console.log('value::', listFromApi.indexOf(value));
      console.log('includes?::', displayList.includes(value));
      console.log('delete id ==>', value);
      console.log('office id ==>', id);
      removeDepartmentId({
        variables: {
          office_id: id,
          department_id: value,
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (dataRefetch) {
          dataRefetch();
        }
        if (data && data.remove_department_id && data.remove_department_id.meta) {
          notification.success({
            message: data.remove_department_id.meta.message,
            description: '',
            duration: 3,
          });
        }
        setdisplayList(displayList.filter(i => i !== value));
      });
    }else{
      const list = displayList.filter(
          i => displayList.indexOf(value) !== displayList.indexOf(i),
      );
      console.log('list->', stroreDepartmentID.filter(i=>i!==value));
      setStroreDepartmentID(stroreDepartmentID.filter(i=>i!==value));
      setdisplayList(list);
    }
  };

  const onDelete = (deleteID, updateStatus) => {
    console.log('delete id ==>', deleteID);
    updateStatus({
      variables: {
        id: deleteID,
        status: false,
      },
    }).then(data => {
      console.log('Data submitted===>>>', data);
      if (data && data.change_office_Status) {
        notification.success({
          message: 'Inactive office',
          description: '',
          duration: 3,
        });
      }
    });
    router.push('/admin/kontorer');
  };

  const onFormLoad = () => {
    console.log('orderData', orderData);

    setOrderData(orderData);
    console.log(orderData.company_name);
  };

  return (
      <>
        <Query
            query={ADMIN_OFFICE_DETAILS}
            variables={{
              id,
            }}
            fetchPolicy="cache-and-network"
            onCompleted={responseData => {
              console.log('inside->id', responseData.office_detail);

              const currentData = responseData.office_detail;
              setOrderData(currentData);
              setimageUrl(`${STORAGE_URL}${currentData?.image_url}`);
              setLogoUrl(`${STORAGE_URL}${currentData?.logo_url}`);
              setbannerUrl1(`${STORAGE_URL}${currentData?.banner_1}`);
              setbannerUrl2(`${STORAGE_URL}${currentData?.banner_2}`);
              setisAdminOffice(currentData?.is_admin_office);
              responseData.office_detail.department_id &&
              setdisplayList(responseData?.office_detail?.department_id.split(','));
              if (responseData.office_detail.department_id) {
                setlistFromApi(responseData.office_detail.department_id.split(','));
              } else {
                setlistFromApi([]);
              }
            }}
        >
          {({ data, refetch, error, loading }) => {
            console.log(error);
            dataRefetch = refetch;
            console.log('id=', id);

            if (data && data.office_detail) {
              formData = data.office_detail;
              const ar = data.office_detail?.department_id?.split(','); // split string on comma space
              console.log('Department_id:', ar);
              console.log('vitec password:', decryptPassword(formData?.officeVitec?.password));

              // eslint-disable-next-line no-unused-expressions
              return (
                  <div className="text-primary main">
                    <Row>
                      <Col xs={24}>
                        <Mutation mutation={ADMIN_UPDATE_OFFICE}>
                          {(updateOffices, { loading }) => (
                              <Form
                                  layout="vertical"
                                  className="user-forms"
                                  name="officeceo"
                                  onLoad={onFormLoad}
                                  initialValues={{
                                    remember: true,
                                    officeimage: `${STORAGE_URL}${formData?.image_url}`
                                        ? `${STORAGE_URL}${formData?.image_url}`
                                        : '',
                                    officelogo: `${STORAGE_URL}${formData?.logo_url}`
                                        ? `${STORAGE_URL}${formData?.logo_url}`
                                        : '',
                                    officename: formData.name ? formData?.name : '',
                                    companyname: formData.company_name ? formData?.company_name : '',
                                    city: formData.city ? formData.city : '',
                                    zipcode: formData.zipcode ? formData?.zipcode : '',
                                    phone: formData.telephone_no ? formData?.telephone_no : '',
                                    address: formData?.address ? formData?.address : '',
                                    email: formData?.email ? formData?.email : '',
                                    callus: formData?.call_us ? formData?.call_us : '',
                                    sendemail: formData?.email ? formData?.email : '',
                                    orgnumber: formData?.org_number ? formData?.org_number : '',
                                    bannerimage1: `${STORAGE_URL}${formData?.banner_1}`
                                        ? `${STORAGE_URL}${formData?.banner_1}`
                                        : '',
                                    bannerimage2: `${STORAGE_URL}${formData?.banner_2}`
                                        ? `${STORAGE_URL}${formData?.banner_2}`
                                        : '',
                                    officeurl: formData?.slug,
                                    tawktowidget: formData?.widget_code ? formData?.widget_code : '',
                                    smallheadline: formData?.small_headline ? formData?.small_headline : '',
                                    bigheadline: formData?.big_headline ? formData?.big_headline : '',
                                    headlinetextsection: formData?.headline_text_section
                                        ? formData?.headline_text_section
                                        : '',
                                    textsection: formData?.text_section ? formData?.text_section : '',
                                    ingress: formData?.ingress ? formData?.ingress : '',
                                    is_admin_office: formData?.is_admin_office,
                                    vitecusername: formData?.officeVitec?.username,
                                    password: decryptPassword(formData?.officeVitec?.password),
                                  }}
                                  colon={false}
                                  onFinish={values => onFinish(values, updateOffices)}
                                  onFinishFailed={onFinishFailed}
                              >
                                {console.log('cn=', orderData.name)}
                                <Row>
                                  <Col xs={24} md={8} lg={4}>
                                    <Form.Item
                                        name="officeimage"
                                        label={formatMessage({
                                          id: 'component.form.officeimage',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.officeimage',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Row>
                                        <Col xs={24}>
                                          <div className="user-profile-box">
                                            <img
                                                src={imageUrl}
                                                alt="John Doe"
                                                className="profile-picture"
                                            />
                                          </div>
                                          {console.log('image url====>', imageUrl)}
                                        </Col>
                                        <Col xs={24} className="mt-3">
                                          <Upload
                                              accept={['.jpeg', '.jpg', '.png', '.gif']}
                                              showUploadList={false}
                                              values={imageUrl}
                                              onChange={handleChange}
                                          >
                                            <Button>
                                              <UploadOutlined />{' '}
                                              {formatMessage({
                                                id: 'component.form.upload',
                                              })}
                                            </Button>
                                          </Upload>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={8} lg={4}>
                                    <Form.Item
                                        name="officelogo"
                                        label={formatMessage({
                                          id: 'component.form.officelogo',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.officelogo',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Row>
                                        <Col xs={24}>
                                          <div className="user-profile-box">
                                            <img
                                                src={logoUrl}
                                                alt="John Doe"
                                                className="profile-picture"
                                            />
                                          </div>
                                          {console.log('logo url====>', logoUrl)}
                                        </Col>
                                        <Col xs={24} className="mt-3">
                                          <Upload
                                              showUploadList={false}
                                              accept={['.jpeg', '.jpg', '.png', '.gif']}
                                              values={logoUrl}
                                              onChange={handleChangeOfficeLogo}
                                          >
                                            <Button>
                                              <UploadOutlined />{' '}
                                              {formatMessage({
                                                id: 'component.form.upload',
                                              })}
                                            </Button>
                                          </Upload>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="officename"
                                        label={formatMessage({
                                          id: 'component.table.officename',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.table.officename',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          style={{ width: '100%' }}
                                          placeholder={formatMessage({
                                            id: 'component.table.officename',
                                          })}
                                          size="large"
                                          className="pickerwidth"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="companyname"
                                        label={formatMessage({
                                          id: 'component.table.companyname',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.table.companyname',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={formatMessage({
                                            id: 'component.table.companyname',
                                          })}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="orgnumber"
                                        label={formatMessage({
                                          id: 'component.table.orgnumber',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.table.orgnumber',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={formatMessage({
                                            id: 'component.table.orgnumber',
                                          })}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="address"
                                        label={formatMessage({
                                          id: 'component.form.address',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.address',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          style={{ width: '100%' }}
                                          placeholder={formatMessage({
                                            id: 'component.form.address',
                                          })}
                                          size="large"
                                          className="pickerwidth"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="city"
                                        label={formatMessage({
                                          id: 'component.form.city',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.city',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={formatMessage({
                                            id: 'component.form.city',
                                          })}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="zipcode"
                                        label={formatMessage({
                                          id: 'component.form.zipcode',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.zipcode',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={formatMessage({
                                            id: 'component.form.zipcode',
                                          })}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row gutter={16}>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="phone"
                                        label={formatMessage({
                                          id: 'component.form.phone',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.phone',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          style={{ width: '100%' }}
                                          placeholder={formatMessage({
                                            id: 'component.form.phone',
                                          })}
                                          size="large"
                                          className="pickerwidth"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="email"
                                        label={formatMessage({
                                          id: 'component.form.email',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Vennligst oppgi epost',
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={formatMessage({
                                            id: 'component.form.email',
                                          })}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="callus"
                                        label={formatMessage({
                                          id: 'component.form.callus',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.callus',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          placeholder={formatMessage({
                                            id: 'component.form.callus',
                                          })}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="sendemail"
                                        label={formatMessage({
                                          id: 'component.form.sendemail',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.sendemail',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          style={{ width: '100%' }}
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.email',
                                          })}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={16}>
                                    <Form.Item
                                        name="officeurl"
                                        label={formatMessage({
                                          id: 'component.form.officeurl',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.officeurl',
                                            }).toLowerCase()}`,
                                          },
                                          {
                                            whitespace: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.officeurl',
                                            }).toLowerCase()}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          className="antPrefix"
                                          prefix="https://weiendomsmegling.no/kontorer/"
                                          style={{ width: '100%', marginRight: '0px' }}
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.officeurl',
                                          }).toLowerCase()}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={16}>
                                    <Form.Item
                                        name="tawktowidget"
                                        label={formatMessage({
                                          id: 'component.contentmanagement.towidgetcode',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.contentmanagement.towidgetcode',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input.TextArea
                                          rows={3}
                                          style={{ width: '100%' }}
                                          placeholder="Skriv her"
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Divider />
                                <Row>
                                  <Col xs={24} md={8} lg={4}>
                                    <Form.Item
                                        name="bannerimage1"
                                        label={formatMessage({
                                          id: 'component.form.bannerimage1',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.bannerimage1',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Row>
                                        <Col xs={24}>
                                          <div className="user-profile-box">
                                            {/* {Uploadprops.defaultFileList.pop[0]} */}
                                            <img
                                                src={bannerUrl1}
                                                alt="John Doe"
                                                className="profile-picture"
                                            />
                                          </div>
                                          {console.log('image url====>', bannerUrl1)}
                                        </Col>
                                        <Col xs={24} className="mt-3">
                                          <Upload
                                              showUploadList={false}
                                              values={bannerUrl1}
                                              accept={['.jpeg', '.jpg', '.png', '.gif']}
                                              onChange={handleChangeBanner1}
                                          >
                                            <Button>
                                              <UploadOutlined />{' '}
                                              {formatMessage({
                                                id: 'component.form.upload',
                                              })}
                                            </Button>
                                          </Upload>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={8} lg={4}>
                                    <Form.Item
                                        name="bannerimage2"
                                        label={formatMessage({
                                          id: 'component.form.bannerimage2',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.bannerimage2',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Row>
                                        <Col xs={24}>
                                          <div className="user-profile-box">
                                            {/* {Uploadprops.defaultFileList.pop[0]} */}
                                            <img
                                                src={bannerUrl2}
                                                alt="John Doe"
                                                // alt={user.first_name + user.last_name}
                                                className="profile-picture"
                                            />
                                          </div>
                                          {/* {Uploadprops.defaultFileList.push(imageUrl)} */}
                                          {console.log('image url====>', bannerUrl2)}
                                        </Col>
                                        <Col xs={24} className="mt-3">
                                          <Upload
                                              showUploadList={false}
                                              values={bannerUrl2}
                                              accept={['.jpeg', '.jpg', '.png', '.gif']}
                                              onChange={handleChangeBanner2}
                                          >
                                            <Button>
                                              <UploadOutlined />{' '}
                                              {formatMessage({
                                                id: 'component.form.upload',
                                              })}
                                            </Button>
                                          </Upload>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={16}>
                                    <Form.Item
                                        name="smallheadline"
                                        label={formatMessage({
                                          id: 'component.form.smallheadline',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.smallheadline',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          style={{ width: '100%' }}
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.writehere',
                                          })}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={16}>
                                    <Form.Item
                                        name="bigheadline"
                                        label={formatMessage({
                                          id: 'component.form.bigheadline',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst skriv inn ${formatMessage({
                                              id: 'component.form.bigheadline',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input
                                          style={{ width: '100%' }}
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.bigheadline',
                                          })}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={16}>
                                    <Form.Item
                                        name="headlinetextsection"
                                        label={formatMessage({
                                          id: 'component.form.headlinetextsection',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst angi  ${formatMessage({
                                              id: 'component.form.headlinetextsection',
                                            })}`,
                                          },
                                        ]}
                                    >
                                      <Input.TextArea
                                          rows={3}
                                          style={{ width: '100%' }}
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.writehere',
                                          }).toLowerCase()}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={16}>
                                    <Form.Item
                                        name="textsection"
                                        label={formatMessage({
                                          id: 'component.form.textsection',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst angi  ${formatMessage({
                                              id: 'component.form.textsection',
                                            }).toLowerCase()}`,
                                          },
                                        ]}
                                    >
                                      <Input.TextArea
                                          rows={3}
                                          style={{ width: '100%' }}
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.writehere',
                                          }).toLowerCase()}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={16}>
                                    <Form.Item
                                        name="ingress"
                                        label={formatMessage({
                                          id: 'component.form.ingress',
                                        })}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Vennligst angi  ${formatMessage({
                                              id: 'component.form.ingress',
                                            }).toLowerCase()}`,
                                          },
                                        ]}
                                    >
                                      <Input.TextArea
                                          rows={3}
                                          style={{ width: '100%' }}
                                          placeholder={`${formatMessage({
                                            id: 'component.form.writehere',
                                          })}`}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={24}>
                                    <Form.Item name="isAdmin">
                                      <Checkbox checked={isAdminOffice} onChange={onCheckboxChange}>
                                        {formatMessage({
                                          id: 'component.form.isadmin',
                                        })}
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="vitecusername"
                                        label={formatMessage({
                                          id: 'component.form.vitecusername',
                                        })}
                                    >
                                      <Input
                                          style={{ width: '100%' }}
                                          placeholder={`Angi ${formatMessage({
                                            id: 'component.form.vitecusername',
                                          }).toLowerCase()}`}
                                          size="large"
                                          className="pickerwidth"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="password"
                                        label={formatMessage({
                                          id: 'component.form.password',
                                        })}
                                    >
                                      <Input
                                          placeholder={formatMessage({
                                            id: 'component.form.password',
                                          })}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                        name="installationid"
                                        label={formatMessage({
                                          id: 'component.form.installationid',
                                        })}
                                    >
                                      <Input
                                          placeholder={formatMessage({
                                            id: 'component.form.installationid',
                                          })}
                                          size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row gutter={16}>
                                  <Col xs={24} lg={8}>
                                    <Row gutter={[16]}>
                                      <Col lg={20} xs={24}>
                                        <Row>
                                          <Col lg={24}>
                                            {displayList.map(i => (
                                                <div className="total-box" key={Math.random()}>
                                                  <div>
                                                    <Row className="mb-2">
                                                      <Col lg={20}>
                                                        <>{i}</>
                                                      </Col>
                                                      <Col lg={4}>
                                                        <>
                                                          <Mutation mutation={ADMIN_REMOVE_DEPARTMENT_ID}>
                                                            {removeDepartmentId => (
                                                                <i
                                                                    className="iconList-delete icon-order-cancel"
                                                                    onClick={() =>
                                                                        onRemove(i, removeDepartmentId)
                                                                    }
                                                                />
                                                            )}
                                                          </Mutation>
                                                        </>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                            ))}
                                          </Col>
                                        </Row>
                                        <Form.Item
                                            name="departmentId"
                                            label="Department id"
                                            rules={[
                                              {
                                                required: false,
                                                message: `Vennligst skriv inn department id`,
                                              },
                                              // {
                                              //   whitespace: true,
                                              //   message: `Vennligst skriv inn department id`,
                                              // },
                                              {
                                                pattern: /^(0|[1-9][0-9]*)$/,
                                                message: `Vennligst skriv inn department id`,
                                              },
                                            ]}
                                        >
                                          <Row>
                                            <Col lg={16} xs={24}>
                                              <Input
                                                  placeholder="Angi department id"
                                                  size="large"
                                                  value={departmentIdValue}
                                                  onChange={e => {
                                                    console.log('EE:', e);
                                                    e && isInt(e) ? setdepartmentIdValue(e) : null;
                                                  }}
                                              />
                                            </Col>
                                            <Col lg={4}>
                                              <Button
                                                  className="btn-secondary buttons admin-forms-btn"
                                                  style={{ marginTop: 8 }}
                                                  size="large"
                                                  onClick={() => addDepartmentIdList(departmentIdValue)}
                                              >
                                                {formatMessage({
                                                  id: 'component.addmore',
                                                })}
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col xs={24}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btn-secondary buttons admin-forms-btn"
                                        size="large"
                                        loading={loading}
                                    >
                                      {formatMessage({
                                        id: 'component.form.save',
                                      })}
                                    </Button>
                                    <Button
                                        className="text-primary admin-forms-btn"
                                        style={{ marginLeft: 8 }}
                                        size="large"
                                        onClick={()=>router.goBack()}
                                    >
                                      {formatMessage({
                                        id: 'component.form.cancel',
                                      })}
                                    </Button>
                                    <Button
                                        className=" admin-forms-btn notice delete-btn"
                                        style={{ cssFloat: 'right' }}
                                        size="large"
                                        onClick={() => setdeleteModal(true)}
                                    >
                                      {formatMessage({ id: 'component.table.inactiveoffice' })}
                                    </Button>
                                  </Col>
                                </Row>
                                <Modal
                                    centered
                                    title={formatMessage({ id: 'component.table.inactiveoffice' })}
                                    visible={deleteModal}
                                    onOk={() => setdeleteModal(false)}
                                    onCancel={() => setdeleteModal(false)}
                                    width="450px"
                                    footer={[
                                      <Mutation mutation={ADMIN_UPDATE_OFFICE_STATUS}>
                                        {updateStatus => (
                                            <Button
                                                key="submit"
                                                type="primary"
                                                className="admin-forms-btn  mr_15"
                                                size="large"
                                                onClick={() => onDelete(formData.id, updateStatus)}
                                            >
                                              {formatMessage({
                                                id: 'component.form.yes',
                                              })}
                                            </Button>
                                        )}
                                      </Mutation>,
                                      <Button
                                          className="admin-forms-btn  mr_15"
                                          size="large"
                                          onClick={() => setdeleteModal(false)}
                                      >
                                        {formatMessage({
                                          id: 'component.form.no',
                                        })}
                                      </Button>,
                                    ]}
                                >
                                  <p className="text-primary no-margin">
                                    {formatMessage({
                                      id: 'component.offices.deletemodal.text',
                                    })}
                                  </p>
                                </Modal>
                              </Form>
                          )}
                        </Mutation>
                      </Col>
                    </Row>
                  </div>
              );
            }
            if (loading && !error) {
              return (
                  <div className="loader-wrapper">
                    <Spin />
                  </div>
              );
            }
            if (!loading && error) {
              return <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen</div>;
            }
            return <React.Fragment />;
          }}
        </Query>
      </>
  );
};
export default EditOfficesDetails;
