/* eslint-disable array-callback-return */
import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, Menu, Row, Table, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatMessage } from "components/src/utils";


let vitechData=[
  {
    key: '1',
    name: 'Steve Hansen',
    username: 'SteveHansen',
    password:'aaaaaa',
    installationid:'1',
  },
];
// import data from '../../../../../mock/photographerdata';

const { Title } = Typography;
const { Search } = Input;

export const Photographers = () => {
  const menu = () => {
    return (
        <Menu>
          <Menu.Item
              onClick={() => {
                console.log('Inactive');
              }}
          >
            Inactive
          </Menu.Item>
        </Menu>
    );
  };

  const columns = [
    {
      title: `${formatMessage({ id: 'component.table.name' })}`,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      render: text => (
          <a>{text}</a>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.table.username' })}`,
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username - b.username,
      render: text => (
          <a>{text}</a>
      ),
    },

    {
      title: `${formatMessage({ id: 'component.table.installationid' })}`,
      dataIndex: 'installationid',
      key: 'installationid',
      sorter: (a, b) => a.installationid.length - b.installationid.length,
      render: text => (
          <a>{text}</a>
      ),
    },

    {
      title: `${formatMessage({ id: 'component.table.action' })}`,
      key: 'action',
      render: () => {
        return (
            <span style={{ paddingLeft: 16 }}>

            <Link to="/admin/vitec/redigerevitec">
              <EditOutlined style={{ marginRight: 8 }} />
            </Link>

            <Dropdown
                overlay={menu}
                placement="bottomCenter"
                trigger={['click']}
            >
              <MoreOutlined />
            </Dropdown>

          </span>
        );
      },
    },
  ];

  return (
      <div className="contentshadow">
        <div className="text-primary main">
          <Row className="photo-order-wrapper">
            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
              <Title level={3} className="text-primary">
                {formatMessage({
                  id: 'component.vitecmanagement.title',
                })}
              </Title>
              {/* <p>
              Her finner du en oversikt over registrerte fotografer til foretaker. Du kan også legge
              til nye.
            </p> */}
              <Link to="/admin/vitec/leggetilvitec">
                <Button
                    type="primary"
                    className="secondary admin-forms-btn with-lg-spaces btn-secondary"
                    size="large"
                >
                  {formatMessage({
                    id: 'component.vitecmanagement.addvitec',
                  })}
                </Button>
              </Link>
              <Row>
                <Col xs={24}>
                  <Row>
                    <Col lg={12} xs={24}>
                      <div className="table-searchbar">
                        <Search
                            placeholder={`${formatMessage({
                              id: 'component.tablesearch.placeholder',
                            })}`}
                            onSearch={value => console.log(value)}
                            // onSearch={value => setSearchKeyword(value)}
                            // onChange={e => {
                            //   const searchText = e.target.value;
                            //   if (timerRef.current) {
                            //     clearTimeout(timerRef.current);
                            //   }
                            //   timerRef.current = setTimeout(() => {
                            //     console.log('On Change===>>>', searchText);
                            //     setSearchKeyword(searchText);
                            //   }, 2000);
                            // }}
                            style={{  padding: '8px 15px', marginBottom: '20px' }}
                        />
                      </div>
                    </Col>
                  </Row>
                  {/* <Query
                  query={ADMIN_VENDORS_LIST}
                  variables={{
                    input: {
                      status: true,
                      user_type_id: USER_TYPE_SERVER.PHOTO,
                      page: 1,
                      first: 10,
                      key: searchKeyword,
                      sorting: {
                        field: 'id',
                        order: 'DESC',
                      },
                    },
                  }}
                  fetchPolicy="no-cache"
                  onCompleted={responseData => {
                    setOrderData(responseData.venders.data);
                    // const zipArray = [];
                    // if (responseData) {
                    //   responseData.venders.data.map(zipItem =>
                    //     zipItem.userZipCodes.map(i => zipArray.push(i.zipcode)),
                    //   );
                    //   setZipCodeData(zipArray);
                    // }
                    // return '';
                  }}
                >
                  {({ data, refetch, loading }) => {
                    dataRefetch = refetch;
                    // console.log('user-list', data.users_list);
                    if (data && data.venders.data) {
                      console.log('insodwe;', data.venders.data);
                      const photographerUser = [];
                      data.venders.data.map(item => {
                        const PhotoUserList = {
                          key: item.id,
                          name: `${item.first_name} ${item.last_name}`,
                          officename: item.office && `${item.office.name}`,
                          email: item.email,
                          phone: item.mobile,
                          address: item.address,
                          zipcodes: `${item.userZipCodes[0].zipcode}, ${item.userZipCodes[1].zipcode}`,
                        };
                        photographerUser.push(PhotoUserList);
                      });
                      return ( */}
                  <div className="unbordered-table-wrapper admin-tables">
                    <Table
                        columns={columns}
                        dataSource={vitecData}
                        pagination={{
                          defaultPageSize: 5,
                          showSizeChanger: true,
                          showTotal: total =>
                              `Total ${total} ${formatMessage({
                                id: 'component.table.items',
                              })}`,
                          pageSizeOptions: ['5', '10', '15'],
                        }}
                        // loading={loading}
                    />
                  </div>
                  {/* );
                    }
                    return (
                      <div className="loader-wrapper">
                        <LoadingOutlined />
                      </div>
                    );
                  }}
                </Query> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
  );
};

export default Photographers;
