import React, {useEffect} from 'react';
//components
import {ActivityIndicator, ScrollView, TouchableOpacity, View} from 'react-native';
import {Label} from "components/src/components";
//Utils
import styles from './styles';
import {Color, CommonStyle, DateUtils, Icon, IS_WEB, Routes, Strings, ThemeUtils} from 'components/src/utils';
import {UPDATE_BUYER_TIMELINE} from "../../../../api/buyerMutation";
import {BUYER_TIMELINE} from "../../../../api/buyerQuery";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {connect} from "react-redux";
import moment from "moment";


function TimeLine(props) {

    const {property} = props;
    console.log(props, 'console data');

    const [updateBuyerTimeline] = useMutation(UPDATE_BUYER_TIMELINE);

    const {data, loading, error, refetch} = useQuery(BUYER_TIMELINE, {
        variables: {
            propertyId: property?.property_id,
            status: 1
        }
    });

    useEffect(() => {
        updateBuyerTimeline({
            variables: {
                propertyId: property?.property_id,
                status: 1
            }
        }).then(() => {
            refetch && refetch()
        }).catch(() => {
        })
    }, []);

    const renderButton = (item) => {
        switch (parseInt(item.id)) {
            case 3:
                return (
                    <TouchableOpacity onPress={() => buttonclick(Routes.BuyerInsurance)}>
                        <View style={styles.buttontimeline}>
                            <Label xsmall
                                   color={Color.TERNARY_TEXT_COLOR}>
                                {Strings.clickForInsuranceInfo}
                            </Label>
                        </View>
                    </TouchableOpacity>
                );
            case 4:
                return (
                    <TouchableOpacity onPress={() => buttonclick(Routes.BuyerInsurance)}>
                        <View style={styles.buttontimeline}>
                            <Label xsmall
                                   color={Color.TERNARY_TEXT_COLOR}>
                                {Strings.clickForInsuranceInfo}
                            </Label>
                        </View>
                    </TouchableOpacity>
                );

            case 5:
                return (
                    <TouchableOpacity onPress={() => buttonclick(Routes.BuyerMovingGuide)}>
                        <View style={styles.buttontimeline}>
                            <Label xsmall
                                   color={Color.TERNARY_TEXT_COLOR}>
                                {Strings.gotoMovingGuide}
                            </Label>
                        </View>
                    </TouchableOpacity>
                )
        }
    };

    const buttonclick = (route, index) => {
        if (IS_WEB) {
            props.history.push(route)
        } else {
            props.navigation.navigate(route);
        }
    };

    const renderStepperCustom = (item, index, self) => {
        return (
            <View style={styles.stepperCont}
                  key={index.toString()}>
                <View style={styles.dotCont}>
                    <View style={styles.dot}/>
                    {index === self.length - 1 ? null : (<View style={styles.line}/>)}
                </View>
                <View style={styles.lblCont}>
                    <View style={styles.stepperTitleCont}>
                        <Icon name={item?.timeline?.icon?.replace("icon-", "")}
                              color={Color.SECONDARY}
                              size={ThemeUtils.fontXLarge}/>
                        <Label large
                               ml={10}
                               style={CommonStyle.flex}
                               font_medium>
                            {item?.timeline?.name}
                        </Label>
                    </View>
                    {renderButton(item)}
                    {item.completed_date ?
                        (<View style={styles.lblCompleted}>
                            <Label xsmall
                                   color={Color.PRIMARY}>
                                {`${Strings.date} : ${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                            </Label>
                        </View>) : null}
                    {/* <Label small
                           style={CommonStyle.flex}
                           mt={15}
                           mb={20}>
                        {item?.timeline?.description}
                    </Label>*/}
                </View>
            </View>
        );
    };

    return (
        <ScrollView>
            <View style={styles.cont}>
                <View style={styles.SubViewofIconandLabel}>
                    <Icon name={'timeline'}
                          size={ThemeUtils.fontXLarge}
                          color={Color.PRIMARY_DARK}/>
                    <Label xlarge
                           font_medium
                           ml={12}>
                        {Strings.timeline}
                    </Label>
                </View>
                <Label small
                       style={CommonStyle.flex}
                       mb={20}
                       mt={10}>
                    {Strings.timeLineInstruction}
                </Label>

                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}/>
                ) : data?.buyer_property_timeline.map(renderStepperCustom)}
            </View>
        </ScrollView>
    )

}

const mapStateToProps = (state) => {

    return {
        property: state.property,
    };
};

export default connect(mapStateToProps)(TimeLine);
