import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
// import StaticHTML from 'components/src/screens/StaticHTML';
import {Routes} from "components/src/utils";

import DigitalSales from "web/src/pages/digitalSales";
import DigitalSalesDemo from "web/src/pages/digitalSalesDemo";
import DigitalSalesNewDev from "web/src/pages/digitalSalesParent";
import DigitalSalesNewDemo from "web/src/pages/digitalSalesParentDemo";
import {
    AddView,
    AppointmentInfo,
    Bids,
    BuyerDocument,
    BuyerFinancing,
    BuyerInsurance,
    BuyerMovingGuide,
    BuyerPurchasingContract,
    BuyerRewards,
    BuyerTimeLine,
    Calendar,
    ChangePassword,
    ChatDetails,
    ChatListVendor,
    ContractInfo,
    Disclaimer,
    Display,
    Documents,
    Drawer,
    DSAssignment,
    EnergyLabeling,
    FAQ,
    Financing,
    ForgotPassword,
    Instructional,
    KitchenForm,
    MarketingMaterial,
    MarketingMaterialDetails,
    MyOrders,
    Notifications,
    PhotographerDashboard,
    PhotographerOrderAccepted,
    PhotographerOrderDetails,
    PhotographerOrderInfo,
    PhotoService,
    Rate,
    Redirection,
    SalesInformation,
    SeeFinnAd,
    SellerInterview,
    SellerMovingGuide, SellerPurchaseContract,
    SettlementInformation,
    SignIn,
    SignUp,
    Styling,
    StylistDashboard,
    StylistOrderAccepted,
    StylistOrderingInformation,
    StylistViewDetails,
    SubmitedDetailsRate,
    TakeOver,
    TakstDashboard,
    TakstOrderAccepted,
    TakstOrderDetails,
    TakstOrderInfo,
    ThankYouPhoto,
    ThankYouRate,
    ThankYouStyling,
    UploadedPhotos,
    ViewDetailsPhotoService,
    ViewDetailsRate,
    ViewDetailsStyling,
    YourFeedBack,
} from "components/src/screens";
import AdminLayout from "../layouts/AdminLayout";
import EditProfile from "../pages/admin/EditProfile";
import AdminDashboard from "../pages/admin/dashboard";
import AllAgents from '../pages/admin/agents/allagents';
import AllAgentsAdd from '../pages/admin/agents/allagents/addagent';
import AllAgentsDetails from '../pages/admin/agents/allagents/details';

import News from '../pages/admin/news';
import NewsDetail from '../pages/admin/news-detail';
import EditNews from '../pages/admin/news/components/editnews';
import AddNews from '../pages/admin/news/components/addnews'
import Professional from '../pages/admin/professional';
import professionalDetail from '../pages/admin/professional-detail';
import EditProfessional from '../pages/admin/professional/components/editprofessional';
import AddProfessional from '../pages/admin/professional/components/addprofessional';
import Officeceo from '../pages/admin/superuser/officeceo';
import AddOfficeceo from '../pages/admin/superuser/officeceo/addofficeceo';
import EditOfficeceo from '../pages/admin/superuser/officeceo/editofficeceo/Components';
import Officecontroller from '../pages/admin/superuser/officecontroller';
import AddOfficecontroller from '../pages/admin/superuser/officecontroller/addofficecontroller';
import EditOfficecontroller from '../pages/admin/superuser/officecontroller/editofficecontroller/Components';
import Photographer from '../pages/admin/vendors/photographer';
import Addphotographer from '../pages/admin/vendors/photographer/addphotographer';
import Editphotographer from '../pages/admin/vendors/photographer/editphotographer';
import Takst from '../pages/admin/vendors/takst';
import AddTakst from '../pages/admin/vendors/takst/addtakst';
import EditTakst from '../pages/admin/vendors/takst/edittakst';
import Stylist from '../pages/admin/vendors/styling';
import AddStylist from '../pages/admin/vendors/styling/addstyling';
import EditStylist from '../pages/admin/vendors/styling/editstyling';
import Loanapplication from '../pages/admin/loanapplication';
import Insurance from '../pages/admin/insurance';
import Jobs from '../pages/admin/jobs';
import AddJobs from '../pages/admin/jobs/addjobs';
import EditJobs from '../pages/admin/jobs/editjobs';
import Homesettings from '../pages/admin/website-edits/home-settings';
import HomeSettings from '../pages/admin/website-edits/home-settings';
import Metalisting from '../pages/admin/website-edits/metalisting';
import MetaListing from '../pages/admin/website-edits/metalisting';
import EditMetalisting from '../pages/admin/website-edits/metalisting/editmetalisting';
import EditMetaListing from '../pages/admin/website-edits/metalisting/editmetalisting';
import Managevendors from '../pages/admin/moovinguide/managevendors';
import AddManagevendors from '../pages/admin/moovinguide/managevendors/addmanagevendors';
import EditManagevendors from '../pages/admin/moovinguide/managevendors/editmanagevendors';
import ManageLinks from "../pages/admin/moovinguide/managelinks";
import Adsandpopups from "../pages/admin/adsandpopups";
import Offices from "../pages/admin/offices";
import AddOffices from "../pages/admin/offices/addoffices";
import EditOffices from "../pages/admin/offices/editoffices";
/*const ProtectedRoute = (rest) => {
    return rest.token || rest.isOpen ?
        <MainRoute {...rest} /> : <Redirect to={{pathname: '/selger/login'}}/>
};

const mapStateToProps = (state) => {
    return {
        token: state.token
    }
};

const Route = connect(mapStateToProps)(ProtectedRoute);*/
import Team from '../pages/admin/agents/team'
import AddTeam from '../pages/admin/agents/team/addteam'
import EditTeam from '../pages/admin/agents/team/editteam'

import Vitec from '../pages/admin/vitec-management'
import AddVitec from '../pages/admin/vitec-management/addvitec'
import EditVitec from '../pages/admin/vitec-management/editvitec'


const withLayout = (WrappedComponents) => {
    function Layout(props) {
        return (
            <AdminLayout>
                <WrappedComponents {...props} />
            </AdminLayout>
        );
    }

    return Layout;
};

function createSwitchNavigator() {
    return (
        <>
          {/*  <Route
                key={"DS"}
                path={"/eiendom/:id"}
                exact={true}
                component={DigitalSales}
            />*/}
            <Route
                key={"NEW DS"}
                path={"/eiendom/:id"}
                exact={true}
                component={DigitalSalesNewDev}
            />
            <Route
                key={"DS Demo"}
                path={"/eiendom/godkjenning/:id"}
                exact={true}
                component={DigitalSalesNewDemo}
            />
            {/* ------ADMIN------ */}
            <Switch>

                <Route
                    key={"vitech"}
                    path={"/admin/vitec"}
                    exact={true}
                    component={withLayout(Vitec)}
                />

                <Route
                    key={"addVitech"}
                    path={"/admin/leggetilvitec"}
                    exact={true}
                    component={withLayout(AddVitec)}
                />

                <Route
                    key={"editVitech"}
                    path={"/admin/redigerevitec"}
                    exact={true}
                    component={withLayout(EditVitec)}
                />


                <Route
                    key={"Team"}
                    path={"/admin/team"}
                    exact={true}
                    component={withLayout(Team)}
                />

                <Route
                    key={"addTeam"}
                    path={"/admin/leggetilteam"}
                    exact={true}
                    component={withLayout(AddTeam)}
                />

                <Route
                    key={"editTeam"}
                    path={"/admin/redigereteam"}
                    exact={true}
                    component={withLayout(EditTeam)}
                />

                <Route
                    key={"Admin redigerprofil"}
                    path={"/admin/redigerprofil"}
                    exact={true}
                    component={withLayout(EditProfile)}
                />

                <Route
                    key={"Admin Dashboard"}
                    path={"/admin/dashboard"}
                    exact={true}
                    component={withLayout(AdminDashboard)}
                />
                <Route
                    key={'Alle agenter'}
                    path={"/admin/allemeglere"}
                    exact={true}
                    component={withLayout(AllAgents)}
                />

                <Route
                    key={'Alle agenter add'}
                    path={"/admin/allemeglere/leggetilalleagenter"}
                    exact={true}
                    component={withLayout(AllAgentsAdd)}
                />

                <Route
                    key={'Alle agenter detail'}
                    path={"/admin/allemeglere/meglerdetaljer/:id"}
                    exact={true}
                    component={withLayout(AllAgentsDetails)}
                />
                {/* <Route
					key={'Legge til alleagenter'}
					path={"/admin/allemeglere/leggtil"}
					exact={true}
					component={withLayout(AgentDetailAddForm)}
				/> */}

                <Route
                    key={'Legge til kontorer'}
                    path={"/admin/kontorer/leggetilkontorer"}
                    exact={true}
                    component={withLayout(AddOffices)}
                />
                <Route
                    key={'Redigere kontorer'}
                    path={"/admin/kontorer/redigerekontorer/:id"}
                    exact={true}
                    component={withLayout(EditOffices)}
                />
                <Route
                    key={'Kontorer'}
                    path={"/admin/kontorer"}
                    exact={true}
                    component={withLayout(Offices)}
                />
                <Route
                    key={'Nyheter'}
                    path={"/admin/nyheter"}
                    exact={true}
                    component={withLayout(News)}
                />
                <Route
                    key={'Nyhet detaljer'}
                    path={"/admin/nyheter/nyhetsdetalj/:id"}
                    exact={true}
                    component={withLayout(NewsDetail)}
                />
                <Route
                    key={'Rediger'}
                    path={"/admin/nyheter/redigernyheter/:id"}
                    exact={true}
                    component={withLayout(EditNews)}
                />
                <Route
                    key={'Leggtilnyheter'}
                    path={"/admin/nyheter/leggtilnyheter"}
                    exact={true}
                    component={withLayout(AddNews)}
                />
                <Route
                    key={'Faglig'}
                    path={"/admin/faglig"}
                    exact={true}
                    component={withLayout(Professional)}
                />
                <Route
                    key={'Faglig detalj'}
                    path={"/admin/faglig/fagligdetalj/:id"}
                    exact={true}
                    component={withLayout(professionalDetail)}
                />
                <Route
                    key={'Rediger faglig'}
                    path={"/admin/faglig/redigerfaglig/:id"}
                    exact={true}
                    component={withLayout(EditProfessional)}
                />
                <Route
                    key={'Legg til Faglig'}
                    path={"/admin/faglig/leggtilfaglig"}
                    exact={true}
                    component={withLayout(AddProfessional)}
                />
                <Route
                    key={'Dagligleder'}
                    path={"/admin/dagligleder"}
                    exact={true}
                    component={withLayout(Officeceo)}
                />
                <Route
                    key={'Leggetilkontordirektør'}
                    path={"/admin/dagligleder/leggetilkontordirektør"}
                    exact={true}
                    component={withLayout(AddOfficeceo)}
                />
                <Route
                    key={'Redigerekontordirektør'}
                    path={"/admin/dagligleder/redigerekontordirektør/:id"}
                    exact={true}
                    component={withLayout(EditOfficeceo)}
                />
                <Route
                    key={'Fagansvarlig'}
                    path={"/admin/fagansvarlig"}
                    exact={true}
                    component={withLayout(Officecontroller)}
                />
                <Route
                    key={'Leggetilkontoransvarlig'}
                    path={"/admin/fagansvarlig/leggetilkontoransvarlig"}
                    exact={true}
                    component={withLayout(AddOfficecontroller)}
                />
                <Route
                    key={'redigerekontoransvarlig'}
                    path={"/admin/fagansvarlig/redigerekontoransvarlig/:id"}
                    exact={true}
                    component={withLayout(EditOfficecontroller)}
                />
                <Route
                    key={'Fotografer'}
                    path={"/admin/fotografer"}
                    exact={true}
                    component={withLayout(Photographer)}
                />
                <Route
                    key={'Leggetilfotografer'}
                    path={"/admin/fotografer/leggetilfotografer"}
                    exact={true}
                    component={withLayout(Addphotographer)}
                />
                <Route
                    key={'Leggetilfotografer'}
                    path={"/admin/fotografer/redigerefotografer/:id"}
                    exact={true}
                    component={withLayout(Editphotographer)}
                />
                <Route
                    key={'Takst'}
                    path={"/admin/takst"}
                    exact={true}
                    component={withLayout(Takst)}
                />
                <Route
                    key={'Leggetiltakst'}
                    path={"/admin/takst/leggetiltakst"}
                    exact={true}
                    component={withLayout(AddTakst)}
                />
                <Route
                    key={'Redigeretakst'}
                    path={"/admin/takst/redigeretakst/:id"}
                    exact={true}
                    component={withLayout(EditTakst)}
                />
                <Route
                    key={'Stylist'}
                    path={"/admin/stylist"}
                    exact={true}
                    component={withLayout(Stylist)}
                />
                <Route
                    key={'Leggetilstylist'}
                    path={"/admin/stylist/leggetilstylist"}
                    exact={true}
                    component={withLayout(AddStylist)}
                />
                <Route
                    key={'Redigerestylist'}
                    path={"/admin/stylist/redigerestylist/:id"}
                    exact={true}
                    component={withLayout(EditStylist)}
                />
                <Route
                    key={'Lånesøknad'}
                    path={"/admin/lånesøknad"}
                    exact={true}
                    component={withLayout(Loanapplication)}
                />
                <Route
                    key={'Forsikring'}
                    path={"/admin/forsikring"}
                    exact={true}
                    component={withLayout(Insurance)}
                />
                <Route
                    key={'Ledigstilling'}
                    path={"/admin/ledigstilling"}
                    exact={true}
                    component={withLayout(Jobs)}
                />
                <Route
                    key={'Leggetilarbeidsplasser'}
                    path={"/admin/ledigstilling/leggtil"}
                    exact={true}
                    component={withLayout(AddJobs)}
                />
                <Route
                    key={'Redigerearbeidsplasser'}
                    path={"/admin/ledigstilling/rediger/:id"}
                    exact={true}
                    component={withLayout(EditJobs)}
                />
                <Route
                    key={'Homesettings'}
                    path={"/admin/homesettings"}
                    exact={true}
                    component={withLayout(Homesettings)}
                />
                <Route
                    key={'Metalisting'}
                    path={"/admin/metalisting"}
                    exact={true}
                    component={withLayout(Metalisting)}
                />
                <Route
                    key={'Redigeremetalisting'}
                    path={"/admin/metalisting/redigeremetalisting/:id"}
                    exact={true}
                    component={withLayout(EditMetalisting)}
                />
                <Route
                    key={'Administrereleverandører'}
                    path={"/admin/administrereleverandører"}
                    exact={true}
                    component={withLayout(Managevendors)}
                />
                <Route
                    key={'Leggetilleverandører'}
                    path={"/admin/administrereleverandører/leggetilleverandører"}
                    exact={true}
                    component={withLayout(AddManagevendors)}
                />
                <Route
                    key={'redigereleverandører'}
                    path={"/admin/administrereleverandører/redigereleverandører/:id"}
                    exact={true}
                    component={withLayout(EditManagevendors)}
                />
                <Route
                    key={'administrer-koblinger'}
                    path={"/admin/administrer-koblinger"}
                    exact={true}
                    component={withLayout(ManageLinks)}
                />
                <Route
                    key={'Annonserogpopups'}
                    path={"/admin/annonserogpopups"}
                    exact={true}
                    component={withLayout(Adsandpopups)}
                />

                <Route
                    key={'website'}
                    path={"/admin/website-edits"}
                    exact={true}
                    component={withLayout(HomeSettings)}
                />

                <Route
                    key={'metalisting'}
                    path={"/admin/website-edits/metalisting"}
                    exact={true}
                    component={withLayout(MetaListing)}
                />

                <Route
                    key={'editmetalisting'}
                    path={"/admin/website-edits/editmetalisting"}
                    exact={true}
                    component={withLayout(EditMetaListing)}
                />
            </Switch>
            {/*<Route
        exact
        key={Routes.Contact}
        path={`/${Routes.Contact}`}
        component={Contact}
      />
      <Route
        exact
        key={Routes.Finance}
        path={`/${Routes.Finance}`}
        component={Finance}
      />*/}
            <Route exact
                   key={Routes.BuyerPurchasingContract}
                   path={`/${Routes.Seller}/${Routes.SellerPurchasingContract}`}
                   component={SellerPurchaseContract}
            />
            <Route
                isOpen
                key={Routes.Login}
                path={[
                    `/${Routes.Photographer}/${Routes.Login}`,
                    `/${Routes.Buyer}/${Routes.Login}`,
                    `/${Routes.Admin}/${Routes.Login}`,
                    `/${Routes.Stylist}/${Routes.Login}`,
                    `/${Routes.Takst}/${Routes.Login}`,
                    `/${Routes.Seller}/${Routes.Login}`,
                ]}
                exact={true}
                component={SignIn}
            />
            <Route
                exact
                isOpen
                key={Routes.ForgotPassword}
                path={[
                    `/${Routes.Seller}/${Routes.ForgotPassword}`,
                    `/${Routes.Admin}/${Routes.ForgotPassword}`,
                    `/${Routes.Buyer}/${Routes.ForgotPassword}`,
                    `/${Routes.Stylist}/${Routes.ForgotPassword}`,
                    `/${Routes.Takst}/${Routes.ForgotPassword}`,
                    `/${Routes.Photographer}/${Routes.ForgotPassword}`,
                ]}
                component={ForgotPassword}
            />
            <Route
                exact
                key={"Drawer"}
                path={[
                    `/${Routes.Seller}/${Routes.Timeline}`,
                    `/${Routes.Seller}/${Routes.EditProfile}`,
                    `/${Routes.Seller}/${Routes.SalesPreparation}`,
                    `/${Routes.Seller}/${Routes.ContractSettlements}`,
                    `/${Routes.Seller}/${Routes.MarketingAndSales}`,
                    `/${Routes.Seller}/${Routes.Chat}/:propertyId`,
                    `/${Routes.Seller}/${Routes.Timeline}`,
                    `/${Routes.Seller}/${Routes.Help}`,
                    `/${Routes.Buyer}/${Routes.Dashboard}`,
                    `/${Routes.Buyer}/${Routes.Chat}/:propertyId`,
                    `/${Routes.Buyer}/${Routes.Timeline}`,
                    `/${Routes.Buyer}/${Routes.EditProfile}`,
                ]}
                component={Drawer}
            />
            <Route
                exact
                key={Routes.AddView}
                path={`/${Routes.Seller}/${Routes.AddView}`}
                component={AddView}
            />
            <Route
                exact
                key={Routes.MyOrders}
                path={`/${Routes.Seller}/${Routes.MyOrders}`}
                component={MyOrders}
            />
            <Route
                exact
                key={Routes.EnergyLabeling}
                path={`/${Routes.Seller}/${Routes.EnergyLabeling}`}
                component={EnergyLabeling}
            />
            {/*<Route
            exact
            key={Routes.RegisteredProperties}
            path={`/${Routes.Seller}/${Routes.RegisteredProperties}`}
            component={RegisteredProperties}
        />*/}
            <Route
                exact
                key={Routes.Styling}
                path={`/${Routes.Seller}/${Routes.Styling}`}
                component={Styling}
            />
            <Route
                exact
                key={Routes.Rate}
                path={`/${Routes.Seller}/${Routes.Rate}`}
                component={Rate}
            />
            {/*NO Use*/}
            <Route
                exact
                key={Routes.SubmitedDetailsRate}
                path={`/${Routes.Seller}/${Routes.SubmitedDetailsRate}`}
                component={SubmitedDetailsRate}
            />
            <Route
                exact
                key={Routes.ThankYouRate}
                path={`/${Routes.Seller}/${Routes.ThankYouRate}`}
                component={ThankYouRate}
            />
            <Route
                exact
                key={Routes.Photos}
                path={`/${Routes.Seller}/${Routes.Photos}`}
                component={PhotoService}
            />
            <Route
                exact
                key={Routes.ThankYouPhotos}
                path={`/${Routes.Seller}/${Routes.ThankYouPhotos}`}
                component={ThankYouPhoto}
            />
            <Route
                exact
                key={Routes.ViewDetailsPhotoService}
                path={`/${Routes.Seller}/${Routes.ViewDetailsPhotoService}`}
                component={ViewDetailsPhotoService}
            />
            <Route
                exact
                key={Routes.UploadedPhotos}
                path={`/${Routes.Seller}/${Routes.UploadedPhotos}`}
                component={UploadedPhotos}
            />
            <Route
                exact
                key={Routes.ViewDetailsRate}
                path={`/${Routes.Seller}/${Routes.ViewDetailsRate}`}
                component={ViewDetailsRate}
            />
            <Route
                exact
                key={Routes.FAQ}
                path={`/${Routes.Seller}/${Routes.FAQ}`}
                component={FAQ}
            />
            <Route
                exact
                key={Routes.ChangePassword}
                path={[
                    `/${Routes.Admin}/${Routes.ChangePassword}`,
                    `/${Routes.Seller}/${Routes.ChangePassword}`,
                    `/${Routes.Stylist}/${Routes.ChangePassword}`,
                    `/${Routes.Takst}/${Routes.ChangePassword}`,
                    `/${Routes.Photographer}/${Routes.ChangePassword}`,
                ]}
                component={ChangePassword}
            />
            <Route
                exact
                key={`/${Routes.Seller}/${Routes.Documents}`}
                path={`/${Routes.Seller}/${Routes.Documents}`}
                component={Documents}
            />
            <Route
                exact
                key={Routes.Instructional}
                path={`/${Routes.Seller}/${Routes.Instructional}`}
                component={Instructional}
            />
            {/*<Route
                exact
                key={Routes.AddProperty}
                path={`/${Routes.Seller}/${Routes.AddProperty}`}
                component={AddProperty}
            />*/}
            <Route
                exact
                key={"SalesInformation"}
                path={`/${Routes.Seller}/${Routes.SalesInformation}`}
                component={SalesInformation}
            />
            <Route
                exact
                key={Routes.SettlementInfo}
                path={`/${Routes.Seller}/${Routes.SettlementInfo}`}
                component={SettlementInformation}
            />
            <Route
                exact
                key={Routes.SellerInterview}
                path={`/${Routes.Seller}/${Routes.SellerInterview}`}
                component={SellerInterview}
            />
            <Route
                exact
                key={Routes.Financing}
                path={`/${Routes.Seller}/${Routes.Financing}`}
                component={Financing}
            />
            <Route
                exact
                key={Routes.TakeOver}
                path={`/${Routes.Seller}/${Routes.TakeOver}`}
                component={TakeOver}
            />
            <Route
                exact
                key={Routes.YourFeedBack}
                path={`/${Routes.Seller}/${Routes.YourFeedBack}`}
                component={YourFeedBack}
            />
            <Route
                exact
                key={Routes.ThankYouStyling}
                path={`/${Routes.Seller}/${Routes.ThankYouStyling}`}
                component={ThankYouStyling}
            />
            <Route
                exact
                key={Routes.ViewDetailsStyling}
                path={`/${Routes.Seller}/${Routes.ViewDetailsStyling}`}
                component={ViewDetailsStyling}
            />
            <Route
                exact
                key={Routes.MarketingMaterials}
                path={`/${Routes.Seller}/${Routes.MarketingMaterials}`}
                component={MarketingMaterial}
            />
            <Route
                exact
                key={Routes.MarketingMaterialsDetails}
                path={`/${Routes.Seller}/${Routes.MarketingMaterialsDetails}`}
                component={MarketingMaterialDetails}
            />
            <Route
                exact
                key={Routes.DigitalSalesAssignment}
                path={`/${Routes.Seller}/${Routes.DigitalSalesAssignment}`}
                component={DSAssignment}
            />
            <Route
                exact
                key={Routes.SeeFinnAd}
                path={`/${Routes.Seller}/${Routes.SeeFinnAd}`}
                component={SeeFinnAd}
            />
            <Route
                exact
                key={Routes.Display}
                path={`/${Routes.Seller}/${Routes.Display}`}
                component={Display}
            />
            <Route
                exact
                key={Routes.Disclaimer}
                path={`/${Routes.Seller}/${Routes.Disclaimer}`}
                component={Disclaimer}
            />
            {/* <Route
                exact
                key={Routes.AddPropertySuccess}
                path={`/${Routes.Seller}/${Routes.AddPropertySuccess}`}
                component={AddPropertySuccess}
            />*/}
            {/*<Route
                exact
                key={Routes.SignAgreement}
                path={`/${Routes.Seller}/${Routes.SignAgreement}`}
                component={SignAgreement}
            />
            <Route
                exact
                key={Routes.SignAgreementCoOwner}
                path={`/${Routes.Seller}/${Routes.SignAgreementCoOwner}`}
                component={SignAgreementCoOwner}
            />*/}

            <Route
                exact
                key={Routes.SellerMovingGuide}
                path={`/${Routes.Seller}/${Routes.SellerMovingGuide}`}
                component={SellerMovingGuide}
            />
            <Route
                exact
                key={Routes.Bids}
                path={`/${Routes.Seller}/${Routes.Bids}`}
                component={Bids}
            />

            {/* ------STYLIST------ */}

            <Route
                exact
                key={"StylistDashboard"}
                path={[
                    `/${Routes.Stylist}/${Routes.Dashboard}`,
                    `/${Routes.Stylist}/${Routes.EditProfile}`,
                ]}
                component={StylistDashboard}
            />

            <Route
                exact
                key={"StylistOrderingInformation"}
                path={`/${Routes.Stylist}/${Routes.StylistOrderInfo}/:id`}
                component={StylistOrderingInformation}
            />
            <Route
                exact
                key={"StylistOrderAccepted"}
                path={`/${Routes.Stylist}/${Routes.StylistOrderAccepted}`}
                component={StylistOrderAccepted}
            />
            <Route
                exact
                key={"StylistViewDetails"}
                path={`/${Routes.Stylist}/${Routes.StylistOrderDetails}`}
                component={StylistViewDetails}
            />

            {/* ------TAKST------ */}

            <Route
                exact
                key={"TakstDashboard"}
                path={[
                    `/${Routes.Takst}/${Routes.Dashboard}`,

                    `/${Routes.Takst}/${Routes.EditProfile}`,
                ]}
                component={TakstDashboard}
            />

            <Route
                exact
                key={"TakstOrderDetails"}
                path={`/${Routes.Takst}/${Routes.TakstOrderDetails}/:id/:propertyId`}
                component={TakstOrderDetails}
            />
            <Route
                exact
                key={Routes.TakstOrderInfo}
                path={`/${Routes.Takst}/${Routes.TakstOrderInfo}`}
                component={TakstOrderInfo}
            />
            <Route
                exact
                key={Routes.TakstOrderAccepted}
                path={`/${Routes.Takst}/${Routes.TakstOrderAccepted}`}
                component={TakstOrderAccepted}
            />

            <Route
                exact
                key={"PhotographerDashboard"}
                path={[
                    `/${Routes.Photographer}/${Routes.Dashboard}`,

                    `/${Routes.Photographer}/${Routes.EditProfile}`,
                ]}
                component={PhotographerDashboard}
            />

            <Route
                exact
                key={Routes.PhotoGrapherOrderDetails}
                path={`/${Routes.Photographer}/${Routes.PhotoGrapherOrderDetails}/:id`}
                component={PhotographerOrderDetails}
            />

            <Route
                exact
                key={Routes.PhotoGrapherOrderInfo}
                path={`/${Routes.Photographer}/${Routes.PhotoGrapherOrderInfo}`}
                component={PhotographerOrderInfo}
            />

            <Route
                exact
                key={Routes.PhotoGrapherOrderAccepted}
                path={`/${Routes.Photographer}/${Routes.PhotoGrapherOrderAccepted}`}
                component={PhotographerOrderAccepted}
            />

            <Route
                exact
                key={Routes.Calendar}
                path={[
                    `/${Routes.Photographer}/${Routes.Calendar}`,
                    `/${Routes.Buyer}/${Routes.Calendar}`,
                    `/${Routes.Stylist}/${Routes.Calendar}`,
                    `/${Routes.Takst}/${Routes.Calendar}`,
                    `/${Routes.Seller}/${Routes.Calendar}`,
                ]}
                component={Calendar}
            />

            <Route
                exact
                key={Routes.ChatDetails}
                path={[
                    `/${Routes.Photographer}/${Routes.ChatDetails}/:propertyId/:receiverId`,
                    `/${Routes.Buyer}/${Routes.ChatDetails}/:propertyId/:receiverId`,
                    `/${Routes.Stylist}/${Routes.ChatDetails}/:propertyId/:receiverId`,
                    `/${Routes.Takst}/${Routes.ChatDetails}/:propertyId/:receiverId`,
                    `/${Routes.Seller}/${Routes.ChatDetails}/:propertyId/:receiverId`,
                ]}
                component={ChatDetails}
            />
            <Route
                exact
                key={'chat vendors'}
                path={[
                    `/${Routes.Photographer}/${Routes.Chat}/:propertyId`,
                    `/${Routes.Stylist}/${Routes.Chat}/:propertyId`,
                    `/${Routes.Takst}/${Routes.Chat}/:propertyId`,
                ]}
                component={ChatListVendor}
            />

            <Route
                exact
                key={'notification'}
                path={[
                    `/${Routes.Seller}/${Routes.Notifications}`,
                    `/${Routes.Buyer}/${Routes.Notifications}`,
                    `/${Routes.Stylist}/${Routes.Notifications}`,
                    `/${Routes.Takst}/${Routes.Notifications}`,
                    `/${Routes.Photographer}/${Routes.Notifications}`,
                ]}
                component={Notifications}
            />
            {/*-------------------------Buyer----------------------------------*/}
            <Route exact
                   key={'BuyerSignUp'}
                   path={`/${Routes.Buyer}/${Routes.SignUp}`}
                   component={SignUp}
            />
            <Route exact
                   key={Routes.AppointmentInfo}
                   path={`/${Routes.Buyer}/${Routes.AppointmentInfo}`}
                   component={AppointmentInfo}
            />
            <Route exact
                   key={Routes.ContractInfo}
                   path={`/${Routes.Buyer}/${Routes.ContractInfo}`}
                   component={ContractInfo}
            />
            <Route exact
                   key={Routes.BuyerMovingGuide}
                   path={`/${Routes.Buyer}/${Routes.BuyerMovingGuide}`}
                   component={BuyerMovingGuide}
            />
            <Route exact
                   key={Routes.BuyerDocument}
                   path={`/${Routes.Buyer}/${Routes.BuyerDocument}`}
                   component={BuyerDocument}
            />

            <Route exact
                   key={Routes.ChangePassword}
                   path={`/${Routes.Buyer}/${Routes.ChangePassword}`}
                   component={ChangePassword}
            />
            <Route exact
                   key={Routes.BuyerPurchasingContract}
                   path={`/${Routes.Buyer}/${Routes.BuyerPurchasingContract}`}
                   component={BuyerPurchasingContract}
            />
            <Route exact
                   key={Routes.BuyerFinancing}
                   path={`/${Routes.Buyer}/${Routes.BuyerFinancing}`}
                   component={BuyerFinancing}
            />
            <Route exact
                   key={Routes.BuyerInsurance}
                   path={`/${Routes.Buyer}/${Routes.BuyerInsurance}`}
                   component={BuyerInsurance}
            />

            <Route exact
                   key={Routes.BuyerTimeLine}
                   path={`/${Routes.Buyer}/${Routes.BuyerTimeLine}`}
                   component={BuyerTimeLine}
            />
            <Route exact
                   key={`/${Routes.Buyer}/${Routes.Rewards}`}
                   path={`/${Routes.Buyer}/${Routes.Rewards}`}
                   component={BuyerRewards}
            />
            {/*--------------------Buyer-----------------------------------*/}
            <Route
                exact
                key={'redirection'}
                path={`/${Routes.Seller}/${Routes.Redirection}/:userId/:propertyId/:notificationType`}
                component={Redirection}
            />
        </>
    );
}

export default createSwitchNavigator();
