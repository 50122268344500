const Errors = {

    nameBlank: 'Venligst oppgi navn',
    nameLength: 'Maksimalt antall tegn på navn',
    nameInvalid: 'Navn er ikke gylidg',
    emailBlank: 'Angi gyldig epost',
    emailValidity: 'Epost ikke gyldig',
    pwdBlank: 'Vennligst oppgi passord',
    pwdInvalid: 'Passord må inneholde en kombinasjon av tall, små og store bokstaver. Passord må være på minst 8 tegn.',
    pwdLengthMinimum: 'Passord må være på minst 8 tegn',
    pwdMisMatch: 'Passordene matcher ikke',
    address: 'Oppgi addresse',


    accountnofortransfer: 'Oppgi kontonnumer',
    phoneBlank: 'Vennligst fyll inn Telefon',
    phoneInvalid: 'Telefonnummer er ikke gylidg.',
    phoneTooLong: 'Telefonnummer har for mange sifre.',
    phoneTooShort: 'Oppgi et gyldig telefonnummer.',
    contacts: 'Oppgi kontaktperson.',
    serverError: 'Noe gikk galt vennligst prøv igjen.',
    internetError: 'Internett er ikke tilgjengelig, sjekk din internett tilkobling.',
    invalidToken: ' Bruker er ikke autorisert. Logg inn om igjen.',
    requestTimeOut: 'Noe gikk galt, vennligst prøv igjen.',

    zipCode: 'Oppgi postnummer',
    zipCodeLength: 'Postnummer kan ikke være mer en fire siffer',
    defaultCity: 'Vennligst fyll inn poststed',

    cbConsideration: 'Vennligst velg et alternativ',



    lastNameRequired: 'Vennligst oppgi etternavn',
    nameLengthMin: 'Navn må inneholde minst to tegn',
    nameLengthMax: 'Navn kan være på maksimalt 30 tegn',
    valuerate: 'Oppgi verditakst',
    loanrate: 'Oppgi lånetakst',
    bta: 'Oppgi BTA',
    bra: 'Oppgi BRA',
    prom: 'Oppgi P-rom',
    constyears: 'Oppgi byggeår',

    buyerRequired: 'Angi navn på kjøper',


    floorType: 'Please Enter Floor Type.',
    hamling: 'Please enter hamling.',
    wallType: 'Please Enter Wall Type.',
    workTop: 'Please Enter Work Top Type.',
    appliances: 'Please Enter Appliance.',
    orderDate: 'Velg dato!',
    time:"Velg tid!",
    taxDate:'Oppgi takst dato',
    startTime: 'Oppgi tidligst starttid',
    endTime: 'Oppgi senest sluttid',
    file:'Last opp vedlegg',
    fashion:"Velg din styling",
    errTimeGap:"Du må ha minimum 1 timer mellom start og sluttid for ønsket tidsvindu.",
    linkRequired: 'Angi link',
    linkInvalid: 'Angi gyldig link',

    interestRequired: 'Vennligst fyll inn eierinteresse i hernia',
    percentageRequired: 'Vennligst angi fordelingen i prosent.',
    commentBlank:'Please enter comment',
    denominationkr: '',
    residualDebtkr: '',
    loanNo: 'Oppgi lånenummer',
    userType:"Please select user type",
    property:"Please select property",
    //Not in use
    descriptionRequired: 'Vennligst oppgi beskrivelse.',
    descriptionRequiredLengthMin: 'Description must be of at least 10 characters.',
    descriptionRequiredLengthMax: 'Description should not be more than 160 characters.',
    question: 'Please write your question.',
    saveSearchName: 'Please enter search name.',
    saveSearchNameLength: 'Search name should be of 3 characters.',
    recommendation: 'Please fill your recommendation.',

/*----------------------------------------*/
    personNumber:"Vennligst velg person personnummer",
    ownership:"Vennligst velg eierandel",
    organizationNo:"Vennligst velg organisasjonsnummer",
    securityNo:"Vennligst velg  fødsel og personnummer kontaktperson",

    equity:"Vennligst velg bank for egenkapital",
    originEquity:"Vennligst velg opprinnelse egenkapital",
    purposeOfPurchase:"Vennligst velg formål med kjøp",
    receivables:"Vennligst velg overføres til kontonummer",
    receivablesInvalid:"Kontonummer må bestå av 11 tall og ingen bokstaver",

};

const Message={
    profileUpdatedSuccess:'Profilinformasjon oppdatert',
    noSalesInfo:'Ingen data',
    finnAdLinkNotAvailable:'Finn.no annonsen er ikke klar for gjennomgang enda. Så fort megler laster den opp til din gjennomgang vil du få melding om dette.',
    dsLinkNotAvailable:'Den digitale salgsoppgaven er ikke klar for gjennomgang enda. Så fort megler laster den opp til din gjennomgang vil du få melding om dette.',
    noMarketingMaterialAvailable:'Det foreligger ikke noe markedsmateriell som er klart for gjennomgang enda. Så fort megler laster det opp vil du få melding om dette.'
}
const PermissionMessage = {
    cancel: 'Cancel',
    ok: 'Okay',
    openSetting: 'Open Settings',
    authorized: 'authorized',
    denied: 'denied',
    undetermined: 'undetermined',
    restricted: 'restricted',
    granted: 'granted',
    never_ask_again: 'never_ask_again',

    //Camera
    cameraPermissionTitle: 'Camera',
    cameraPermissionMessage: "This lets you use the app to take photos, you'll need to allow camera access in Settings.",

    //Photo
    photoPermissionTitle: 'Photo',
    photoPermissionMessage: "This lets you use the app to upload photos, you'll need to allow photo access in Settings.",

    //Location
    locationPermissionTitle: 'Location',
    locationPermissionMessage: "HouzQuest needs the location access to show you nearby properties, you'll need to allow location access in Settings.",


    //Notification
    notificationPermissionTitle: 'Notification',
    notificationPermissionMessage: 'Notification may include alerts, sounds and icon badges, this can be configured in Settings.',

    //Storage
    storagePermissionTitle: 'Storage',
    storagePermissionMessage: "We need access so you can upload your files, please open this app's setting and allow storage access.",

    //Contacts
    contactPermissionTitle: 'Contact',
    contactPermissionMessage: "We need access so we can get your contacts, please open this app's setting and allow contacts access.",

    //Phone Call
    phoneCallPermissionTitle: 'PhoneCall',
    phoneCallPermissionMessage: "We need access so we can allow you to call, please open this app's setting and allow phone call access.",

    //Read Sms
    readSmsPermissionTitle: 'ReadSms',
    readSmsPermissionMessage: "We need access so we can get your sms, please open this app's setting and allow message access.",
};
export default {Errors, PermissionMessage,Message};
