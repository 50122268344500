import React from 'react';
//Components
import {Animated, Image, navigation, View} from 'react-native';
import {Label, Ripple} from "components/src/components";
//third party
import PropTypes from 'prop-types';
//Utils
import {Color, CommonStyle, Icon, IS_WEB, ThemeUtils} from 'components/src/utils'
import styles from './styles';


const AnimatedIcon = Animated.createAnimatedComponent(Icon);
const MarginLeft = IS_WEB ? 10 + ThemeUtils.fontXXLarge + ThemeUtils.fontXXSmall : 20 + ThemeUtils.fontXXLarge + ThemeUtils.fontXXSmall

class Header extends React.Component {

    getHeaderTitleMarginTop = () => {
        return this.props.animatedValue.interpolate({
            inputRange: [0, 62],
            outputRange: [IS_WEB ? 79 : 78, ThemeUtils.isTablet() ? 14 : 18],
            extrapolateRight: 'clamp'
        })
    };

    getHeaderTitleMarginStart = () => {
        return this.props.animatedValue.interpolate({
            inputRange: [0, 62],
            outputRange: [this.props.initialMarginLeft, MarginLeft],
            extrapolate: 'clamp'

        })
    };

    getOpacityUp = () => {
        return this.props.animatedValue.interpolate({
            inputRange: [0, 35],
            outputRange: [0, 1],
            extrapolate: 'clamp'
        })
    };

    getOpacityDown = () => {
        return this.props.animatedValue.interpolate({
            inputRange: [0, 35],
            outputRange: [1, 0],
            extrapolate: 'clamp'
        })
    };

    render() {
        const {
            headerLeft,
            headerRightFirst,
            headerRightSecond,
            renderHeaderThird,
            title,
            image,
            navigation,
            onPressLeft,
            onPressRightFirst,
            onPressRightSecond,
            onPressRightThird,
            renderHeaderFirst,
            animatedTitle,
            badge,
            renderTitle,
            animatedValue,
            style,
            titleStyle,
            tintColor,

        } = this.props;

        const headerTitleMarginStart = animatedValue !== undefined ? this.getHeaderTitleMarginStart() : 80;
        const headerTitleMarginTop = animatedValue !== undefined ? this.getHeaderTitleMarginTop() : 80;
        const opacityUp = animatedValue !== undefined ? this.getOpacityUp() : 1;
        const opacityDown = animatedValue !== undefined ? this.getOpacityDown() : 1;

        return (
            <View style={styles.container}>
                <Animated.View style={[styles.headerBg, style, {opacity: opacityUp}]}/>
                <Animated.View style={[styles.headerCont]}>
                    {image || renderTitle ? (
                        <View style={[styles.headerTitleCont, titleStyle]}>
                            {image ? (
                                <Image source={image}
                                       style={{
                                           height: 20,
                                           width: 20
                                       }}/>
                            ) : renderTitle()}
                        </View>) : null}


                    <Ripple rippleColor={Color.WHITE}
                            onPress={() => onPressLeft ? onPressLeft() : navigation && navigation.goBack()}
                            rippleContainerBorderRadius={20}
                            style={styles.icHeader}>
                        {animatedTitle ?
                            (<AnimatedIcon name={headerLeft}
                                           style={{position: 'absolute', zIndex: 10, opacity: opacityUp}}
                                           color={Color.WHITE}
                                           size={20}/>) : null}
                        <AnimatedIcon name={headerLeft}
                                      style={{opacity: opacityDown}}
                                      color={tintColor}
                                      size={20}/>
                    </Ripple>

                    <View style={styles.icHeaderRightCont}>

                        {renderHeaderThird ? (
                            <Ripple rippleColor={Color.WHITE}
                                    onPress={onPressRightThird}
                                    rippleContainerBorderRadius={20}
                                    style={styles.icHeader}>
                                {renderHeaderThird()}
                            </Ripple>) : null}

                        {headerRightFirst ?
                            (<Ripple rippleColor={Color.WHITE}
                                     onPress={onPressRightFirst}
                                     rippleContainerBorderRadius={20}
                                     style={styles.icHeader}>
                                {animatedTitle ? <AnimatedIcon name={headerRightFirst}
                                                               style={{
                                                                   position: 'absolute',
                                                                   zIndex: 10,
                                                                   opacity: opacityUp
                                                               }}
                                                               size={20}
                                                               color={Color.WHITE}/> : null}
                                <AnimatedIcon name={headerRightFirst}
                                              size={20}
                                              style={{opacity: opacityDown}}
                                              color={tintColor}/>
                            </Ripple>) : renderHeaderFirst ? (
                                <Ripple rippleColor={Color.WHITE}
                                        onPress={onPressRightFirst}
                                        rippleContainerBorderRadius={20}
                                        style={styles.icHeader}>
                                    {renderHeaderFirst()}
                                </Ripple>) : null}

                        {headerRightSecond ? (
                            <Ripple rippleColor={Color.WHITE}
                                    onPress={onPressRightSecond}
                                    rippleContainerBorderRadius={20}
                                    style={styles.icHeader}>

                                {animatedTitle ? <AnimatedIcon name={headerRightSecond}
                                                               style={{
                                                                   position: 'absolute',
                                                                   zIndex: 10,
                                                                   opacity: opacityUp
                                                               }}
                                                               size={20}
                                                               color={Color.WHITE}/> : null}
                                <AnimatedIcon name={headerRightSecond}
                                              size={20}
                                              style={{opacity: opacityDown}}
                                              color={tintColor}/>
                                {badge ? (
                                    <View style={CommonStyle.dot}/>
                                ) : null}
                            </Ripple>) : null}


                    </View>
                </Animated.View>
                {animatedTitle ? (

                    <Animated.View style={[
                        styles.animatedTitleCont, {
                            transform: [{
                                translateY: headerTitleMarginTop
                            }, {
                                translateX: headerTitleMarginStart
                            },],
                        },
                    ]}>
                        <Label xlarge
                               font_medium
                               style={{
                                   opacity: opacityDown
                               }}
                               color={Color.PRIMARY_TEXT_COLOR}
                               align={'left'}>
                            {animatedTitle}
                        </Label>
                        <Label large
                               font_medium
                               style={{
                                   position: 'absolute',
                                   opacity: opacityUp
                               }}
                               color={Color.WHITE}
                               align={'left'}>
                            {animatedTitle}
                        </Label>
                    </Animated.View>
                ) : null}
            </View>
        );
    }
}

export default Header;

Header.defaultProps = {
    headerLeft: 'arrow-l',
    tintColor: Color.SECONDARY,
    initialMarginLeft: MarginLeft
};

Header.propTypes = {
    headerLeft: PropTypes.string,
    initialMarginLeft: PropTypes.number,
    navigation: PropTypes.object.isRequired,
    onPressLeft: PropTypes.func,
    renderHeaderFirst: PropTypes.func,
    onPressRightFirst: PropTypes.func,
    onPressRightSecond: PropTypes.func,
    renderTitle: PropTypes.func,
    headerRightFirst: PropTypes.string,
    headerRightSecond: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.number,
    animatedValue: PropTypes.object,
    animatedTitle: PropTypes.string,
    tintColor: PropTypes.string
};
