import React from 'react';
//Components
import {Animated, BackHandler, ScrollView, StatusBar, TouchableOpacity, View} from 'react-native'
import {
    Accordion,
    Dialog,
    Header,
    Label,
    LogoForHeader,
    Modal,
    PopOver,
    PropertyList,
    Ripple,
    Toast
} from "components/src/components";
import Drawer from "@ant-design/react-native/lib/drawer";
import TabBar from "@ant-design/react-native/lib/tab-bar";
//Utils
import styles from './styles';
import {
    Color,
    CommonStyle,
    getUserName,
    Icon,
    IS_IOS,
    IS_WEB,
    openInNewTab,
    Routes,
    Strings,
    ThemeUtils,
    UserType
} from "components/src/utils";
//Screen
import Home from './Seller/SalesPreparation';
import BuyerHome from './Buyer';
import MarketingSales from "./Seller/MarketingSales";
import TimeLine from "./Seller/Timeline";
import MyProfile from "./Seller/MyProfile";
import {PDF} from "../../components/ui/FilePicker";
import ContractSettlements from "./Seller/ContractSettlement";
import {logout, setCurrentProperty, setToken, setTourShown, setUser} from "../../redux/action";
import {connect} from "react-redux";
import BuyerMyProfile from "./Buyer/MyProfile";
import FAQ from "./Seller/Help";
import {Mutation, Query} from "@apollo/react-components";

import {SELLER_PROPERTY_LIST} from "../../api/sellerQuery";
import {LOG_OUT, SWITCH_ACCOUNT} from "../../api/mutation";
import Cookie from 'js-cookie'
import {encryptId} from "web/src/utils";
import {withFirebase} from "../../HOC/Firebase";
import {copilot, CopilotStep} from "components/src/components/ui/CoPilot";

import {BUYER_PROPERTIES} from "../../api/buyerQuery";
import BuyerTimeLine from "./Buyer/Timeline";
import ChatList from '../Chat/ChatList'


const SELLER_DRAWER = [{
    name: Strings.myOrders,
    icon: 'my-order',
    route: Routes.MyOrders,

}, /*{
    name: Strings.calendar,
    icon: 'calendar',
    route: Routes.Calendar
},*/ {
    name: Strings.instructional,
    icon: 'instructional',
    route: Routes.Instructional,
    order: 10,
    isStep: true,
    text: "Her finner du instruksjonsvideoer som viser hva du skal gjøre i stegene i prosessen. Dersom du har spørsmål kan du skrive til oss på chat, eller kontakte megler på tlf."
}, {
    name: Strings.documents,
    icon: 'documents',
    route: Routes.Documents,
}, {
    name: Strings.disclaimer,
    icon: 'disclaimer',
    route: Routes.Disclaimer,
}, {
    name: Strings.FAQ,
    icon: 'faq',
    route: Routes.FAQ
}, {
    name: Strings.rewards,
    icon: 'rewards',
    route: Routes.Rewards,

}, {
    name: Strings.logout,
    icon: 'logout'
},];

const salesProgress = [{
    id: 1,
    index: 0,
    name: Strings.salesPreparation,
    route: Routes.SalesPreparation,
    screen: Home
}, {
    id: 1,
    index: 1,
    name: Strings.marketingSales,
    route: Routes.MarketingAndSales,
    screen: MarketingSales
}, {
    id: 1,
    index: 2,
    name: Strings.contractSettlements,
    route: Routes.ContractSettlements,
    screen: ContractSettlements
}];

const SELLER_TABS = [{
    id: 1,
    name: Strings.home,
    icon: 'home',
    route: Routes.SalesPreparation,
    screen: Home,

}, {
    id: 2,
    name: Strings.chat,
    icon: 'chat',
    route: Routes.Chat,
    isStep: true,
    text: "Her kan du starte en chat med eiendomsmegler.",
    screen: ChatList,
    order:7

}, {
    id: 3,
    name: Strings.timeline,
    icon: 'timeline',
    route: Routes.Timeline,
    screen: TimeLine,
    isStep: true,
    text: "Her finner du en tidslinje over salgsprosessen.",
    order: 8
}, {
    id: 4,
    name: Strings.profile,
    icon: 'profile',
    route: Routes.EditProfile,
    screen: MyProfile,
    isStep: true,
    text: "Her kan du redigere kontaktinformasjon, profilbilde, endre passord, m.m.",
    order: 6
}, {
    id: 5,
    name: Strings.help,
    icon: 'faq',
    route: Routes.Help,
    screen: FAQ
}];

const BUYER_TABS = [{
    id: 1,
    name: Strings.home,
    icon: 'home',
    screen: BuyerHome,
    route: Routes.Dashboard
}, {
    id: 2,
    name: Strings.chat,
    icon: 'chat',
    route: Routes.Chat,
    screen: ChatList
}, {
    id: 3,
    name: Strings.timeline,
    icon: 'timeline',
    route: Routes.Timeline,
    screen: BuyerTimeLine
}, {
    id: 4,
    name: Strings.profile,
    icon: 'profile',
    screen: BuyerMyProfile,
    route: Routes.EditProfile
}];

const BUYER_DRAWER = [{
    name: Strings.dashBoard,
    icon: 'dashboard',
    route: Routes.Dashboard
},/* {
    name: Strings.calendar,
    icon: 'calendar',
    route: Routes.Calendar
}, {
    name: Strings.rewards,
    icon: 'rewards',
    route: Routes.Rewards
},*/ {
    name: Strings.logout,
    icon: 'logout',
},];

const SELLER = 1;
const BUYER = 2;

const USER_TYPE = {
    ADMIN: 'Admin',
    SELLER: 'Seller',
    AGENT: 'Agent',
    PHOTO: 'Photographer',
    TAKST: 'Takst',
    STYLIST: 'Stylish',
    BOOK_INSPECTION: 'BookInspection',
    BUYER: 'Buyer',
    SUPERUSER: 'Superuser',
};

const WrraperComponent = ({style, isStep, children, copilot}) => {
    if (!isStep) {
        return children
    }
    return (<View {...copilot} style={style}>
        {children}
    </View>)

};


class DrawerNavigation extends React.Component {

    constructor(props) {
        super(props);
        let selectedTab = null;
        let currentUserType = SELLER;
        let tabIndex = 0;
        if (IS_WEB) {
            currentUserType = props.location.pathname.toLowerCase().split('/')[1] === Routes.Buyer ? BUYER : SELLER;
            let tabs = currentUserType === SELLER ? SELLER_TABS : BUYER_TABS;
            selectedTab = [...tabs, ...salesProgress].find(item => {
                return `/${Routes.Seller}/${item.route.toLowerCase()}` === props.location.pathname.toLowerCase() ||
                    props.location.pathname.startsWith(`/${Routes.Seller}/${item.route.toLowerCase()}`) ||
                    `/${Routes.Buyer}/${item.route.toLowerCase()}` === props.location.pathname.toLowerCase() ||
                    props.location.pathname.startsWith(`/${Routes.Buyer}/${item.route.toLowerCase()}`)

            });
            tabIndex = salesProgress.findIndex(item => `/${Routes.Seller}/${item.route.toLowerCase()}` === props.location.pathname.toLowerCase())

        } else {
            currentUserType = this.props.user?.user_type_id === UserType.Seller ? SELLER : BUYER
            selectedTab = currentUserType === SELLER ? SELLER_TABS[0] : BUYER_TABS[0];
        }

        this.scrollX = new Animated.Value(currentUserType === SELLER ? 0 : 100);

        this.refetchProperties = () => {
        }
        this.state = {
            isBuyerNotAvailableVisible: false,
            isOppGjorVisible: false,
            descForBuyerDialog: "",
            loading: true,
            isStartTour: false,
            isPopOverVisible: false,
            selectedTab: selectedTab ? selectedTab : SELLER_TABS[0],
            tabIndex: tabIndex !== -1 ? tabIndex : 0,
            propertyListVisible: false,
            currentUserType: currentUserType,
            isNewNotification: false,
            isNewMessage: false,
        }
    }

    componentDidMount() {
        if (!this.props.tourShown[this.props.user?.email] && this.props.user) {
            if (this.state.currentUserType === SELLER) {
                this.toggleTour();
            }
            this.props.setTourShown({[this.props.user?.email]: true})
        }
        this.database = this.props.database().ref('v1/generalNotifications').child(`${this.props.user.id}`)
        this.chatDbRef = this.props.database().ref('v1/notificationList').child(`${this.props.user.id}`)

        if (!IS_WEB) {
            this.unsubscribe = this.props.navigation.addListener('didFocus', () => {
                this.refetchProperties()
            });
            BackHandler.addEventListener('hardwareBackPress', this.handleHardwereBackPress);
        }

        this.props.copilotEvents.on("stepChange", this.handleStepChange);
        this.props.copilotEvents.on("stop", this.onTourStop);

        this.database.on('value', (e) => {
            let notifcation = e.val();
            if (notifcation) {
                this.setState({
                    isNewNotification: Object.values(notifcation).filter(item => !item.isRead)?.length !== 0
                })
            }
        })
        this.chatDbRef.on('value', (e) => {
            let notifcation = e.val();
            if (notifcation) {
                this.setState({
                    isNewMessage: Object.values(notifcation).filter(item => !item.isRead)?.length !== 0
                })
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.property?.id !== this.props.property?.id) {
            console.log(this.props.property?.property_buyer_info , this.props.property?.settlementInfo)
            if (this.props.property?.property_buyer_info && !this.props.property?.settlementInfo) {
                IS_WEB ? this.props.history.push(Routes.SettlementInfo) : this.props.navigation.push(Routes.SettlementInfo)
            }
        }
    }

    componentWillUnmount() {
        this.database && this.database.off()
        this.chatDbRef && this.chatDbRef.off()
        this.props.copilotEvents.off("stop");
        this.props.copilotEvents.off("stepChange");
        this.unsubscribe && this.unsubscribe.remove();
        if (!IS_WEB) {
            BackHandler.removeEventListener("hardwareBackPress", this.handleHardwereBackPress);
        }
    }

    handleHardwereBackPress = () => {
        if (this.state.selectedTab.id !== 1) {
            this.onChangeTab(this.state.currentUserType === SELLER ? SELLER_TABS[0] : BUYER_TABS[0])
            return true;
        }
        return false
    };

    handleStepChange = (step) => {
        if (step.name === Strings.salesPreparation || step.name === Strings.instructional) {
            this.drawer && this.drawer.drawer.openDrawer();
        } else {
            this.drawer && this.drawer.drawer.closeDrawer();
        }
    }

    onTourStop = (step) => {
        this.drawer && this.drawer.drawer.closeDrawer();
    }


    togglePopOver = (callback) => {
        this.setState(prevState => ({isPopOverVisible: !prevState.isPopOverVisible}), callback)
    }

    toggleTour = (callback) => {
        setTimeout(()=>{
            this.setState(prevState => ({isStartTour: !prevState.isStartTour}), () => {
                callback && callback()
            })
        },1000)
    }

    toggleBuyerModal = () => {
        this.setState(prevState => ({isBuyerNotAvailableVisible: !prevState.isBuyerNotAvailableVisible}))
    }


    toggleOppgjorModal = () => {
        this.setState(prevState => ({isOppGjorVisible: !prevState.isOppGjorVisible}))
    }

    openNotification = () => {
        IS_WEB ? this.props.history.push(`/${location.pathname.split('/')[1]}/${Routes.Notifications}`) : this.props.navigation.navigate(Routes.Notifications);
    }

    togglePropertyList = () => {
        this.setState(prevState => ({
            propertyListVisible: !prevState.propertyListVisible
        }))
    };

    addNewProperty = () => {
        this.togglePropertyList();
        if (IS_WEB) {
            this.props.history.push(Routes.AddProperty)
        } else {
            this.props.history.navigate(Routes.AddProperty)
        }
    };

    onChangeTab = (tab) => {
        let updatedTab = {...tab};
        //tabIndex = 0 Sales Prep.
        //tabIndex = 1 Marketing
        //tabIndex = 2 Contract
        //id 1 represents home
        this.drawer && this.drawer.drawer.closeDrawer();
        //Checks if tab is previously selected and again change to home but not from the home it self
        if (this.state.tabIndex > 0 && tab.id === 1 && this.state.selectedTab.id !== tab.id) {
            updatedTab = salesProgress[this.state.tabIndex]
        }
        let updatedState = {
            selectedTab: updatedTab,
        };
        //Update tab index if screen changes in home tab it self
        if (updatedTab.id === 1) {
            updatedState.tabIndex = updatedTab.index ? updatedTab.index : 0
        }
        if (IS_WEB) {
            if (updatedTab.route === Routes.Chat) {
                this.props.history.replace(`/${this.state.currentUserType === SELLER ? Routes.Seller : Routes.Buyer}/${updatedTab.route}/${encryptId(this.state.currentUserType === SELLER?this.props.property?.id:this.props.property?.property_id)}`)

            } else {
                this.props.history.replace(`/${this.state.currentUserType === SELLER ? Routes.Seller : Routes.Buyer}/${updatedTab.route}`)
            }
        }
        this.setState(updatedState)
    };

    handleOnPressDrawerItem = (route, name, logout) => {
        const {navigation, history} = this.props;
        if (route === Routes.Rewards) {
            // openInNewTab("http://wrewards.no/")
            openInNewTab("http://rewards.weiendomsmegling.no")
        } else if (name !== Strings.logout || route) {
            if (IS_WEB) {
                history.push(`/${this.props.location.pathname.split('/')[1]}/${route}`)
            } else if (route) {
                navigation.navigate(route)
            }

        } else {
            logout()
            if (IS_WEB) {
                localStorage.clear();
                Cookie.remove(`${this.state.currentUserType === SELLER ? USER_TYPE.SELLER : USER_TYPE.BUYER}_token`);
                Cookie.remove(`${this.state.currentUserType === SELLER ? USER_TYPE.SELLER : USER_TYPE.BUYER}_user`);
                history.replace(`/${location.pathname.split('/')[1]}/${Routes.Login}`)
            } else {
                let id = 'react-navigation'
                let {NavigationActions, StackActions} = require(`${id}`)
                this.props.messaging()
                    .unsubscribeFromTopic(`W_CHAT_${this.props.user.id}`)
                navigation.dispatch(StackActions.reset({
                    key: null,
                    index: 0,
                    actions: [
                        NavigationActions.navigate({routeName: Routes.UnAuthenticated}),
                    ],
                }))
            }
            try {
                this.props.logout();
                this.drawer && this.drawer.drawer && this.drawer.drawer.closeDrawer();
            } catch (e) {
                console.log(e, "ERRRR================")
            }
        }

    };

    getBgViewMarginLeft = () => {
        return this.scrollX.interpolate({
            inputRange: [0, 100],
            outputRange: ['0%', '50%']
        })
    };

    changeToSeller = (switchAccount) => {
        Animated.timing(this.scrollX, {
            toValue: 0,
            duration: 250
        }).start(() => {
            this.drawer && this.drawer.drawer.closeDrawer();

            this.setState({
                currentUserType: SELLER,
                selectedTab: SELLER_TABS[0]
            }, () => {
                IS_WEB && this.props.history.replace(`/${Routes.Seller}/${Routes.SalesPreparation}`)
            })
        })

    };

    changeToBuyer = (switchAccount) => {
        Animated.timing(this.scrollX, {
            toValue: 100,
            duration: 250
        }).start(() => {
            this.drawer && this.drawer.drawer.closeDrawer();
            this.setState({
                currentUserType: BUYER,
                selectedTab: BUYER_TABS[0]
            }, () => {

                if (IS_WEB) {
                    this.props.history.replace(`/${Routes.Buyer}/${Routes.Dashboard}`)
                }
            })
        })

    };

    getIntroText = () => {
        switch (this.state.tabIndex) {
            case 0:
                return "Velkommen - la oss gi deg en rask innføring"
            case 1:
                return "Neste steg i salgsprosessen er Markedsføring & Salg. La oss ta deg gjennom steg for steg hva denne delen består av."
            case 2:
                return "Når du er kommet til Kontrakt & Oppgjør er boligen din solgt. Gratulerer! La oss ta deg gjennom steg for steg hva denne siste delen av salgsprosessen består av."

        }
    };

    //Render Accordion in drawer
    renderAccordionHeader = () => {
        return (
            <CopilotStep
                active={this.state.tabIndex === 0}
                text={"Vi har delt salgsprosessen inn i tre deler. Salgsforberedelser, Markedsføring & Salg, Kontrakt & Oppgjør."}
                order={9}
                title={Strings.salesPreparation}
                name={Strings.salesPreparation}>
                <WrraperComponent isStep style={CommonStyle.flex}>
                    <View style={[CommonStyle.flex, styles.drawerItemCont]}>
                        <Icon name={'sales-process'}
                              size={ThemeUtils.fontXLarge}
                              color={Color.TERNARY_TEXT_COLOR}/>
                        <Label font_medium
                               style={CommonStyle.flex}
                               color={Color.TERNARY_TEXT_COLOR}
                               ml={10}>
                            {Strings.yourSalesProgress}
                        </Label>
                    </View>
                </WrraperComponent>
            </CopilotStep>
        )
    };

    //Render Accordion in drawer
    renderAccordionContent = () => {
        return salesProgress.map((item, index) => {
            return (
                <Ripple
                    style={[styles.accordionItem, this.state.tabIndex === index && this.state.selectedTab.name === item.name ? styles.selectedAccordionItem : null]}
                    key={item.name}
                    onPress={() => this.onChangeTab(item)}>
                    <Label font_medium
                           color={Color.SECONDARY_TEXT_COLOR}
                           ml={ThemeUtils.relativeWidth(15)}>
                        {item.name}
                    </Label>
                </Ripple>
            )
        })
    };

    //render Drawer item
    renderDrawerItems = () => {
        const {currentUserType} = this.state;
        const drawer = currentUserType === SELLER ? SELLER_DRAWER : BUYER_DRAWER;
        return drawer.map((item, index) => {
            return (
                <Mutation mutation={LOG_OUT}>
                    {(logout, {loading, data}) => (

                        <CopilotStep text={item.text}
                                     active={this.state.tabIndex === 0}
                                     order={item.order}
                                     title={item.name}
                                     name={item.name}>
                            <WrraperComponent isStep={item.isStep}>
                                <Ripple
                                    style={[styles.drawerItemCont, currentUserType === BUYER && index === 0 ? {backgroundColor: Color.PRIMARY} : null]}
                                    onPress={() => this.handleOnPressDrawerItem(item.route, item.name, logout)}
                                    key={item.name}>
                                    <Icon name={item.icon}
                                          size={ThemeUtils.fontLarge}
                                          color={currentUserType === BUYER && index === 0 ? Color.COLOR_ACCENT : Color.PRIMARY}/>
                                    <Label font_medium
                                           ml={10}
                                           color={currentUserType === BUYER && index === 0 ? Color.COLOR_ACCENT : Color.PRIMARY}>
                                        {item.name}
                                    </Label>
                                </Ripple>
                            </WrraperComponent>
                        </CopilotStep>
                    )}
                </Mutation>

            )
        })
    };

    //render Drawer it self
    renderSideBar = () => {
        const bgViewMarginLeft = this.getBgViewMarginLeft();
        const {currentUserType} = this.state;
        return (
            <ScrollView contentContainerStyle={{paddingBottom: 30}}>
                <View style={styles.cont}>
                    <View style={styles.drawerHeader}>
                        {/*<Icon name={'about-w'}
                              size={ThemeUtils.fontXXLarge}
                              color={Color.SECONDARY}/>*/}
                        <LogoForHeader size={ThemeUtils.responsiveScale(80)}/>

                        {/*<Label xlarge
                               font_medium
                               ml={5}>
                            {Strings.web}
                            <Text style={styles.txtRegular}>
                                {Strings.megling}
                            </Text>
                        </Label>*/}
                    </View>
                    <Mutation mutation={SWITCH_ACCOUNT}
                              onCompleted={(data) => {
                                  let user = {...data.switch_account.user, ...{userType: this.state.currentUserType === BUYER ? UserType.Seller : UserType.Buyer}};
                                  if (IS_WEB) {
                                      Cookie.set('token', data.switch_account.token.access_token);
                                      Cookie.set(`${getUserName(this.props.location.pathname.toLowerCase().split('/')[1])}_token`, data.switch_account.token.access_token);
                                      const userData = {
                                          user: user,
                                          userType: getUserName(this.props.location.pathname.toLowerCase().split('/')[1]),
                                      };
                                      Cookie.set(`${getUserName(this.props.location.pathname.toLowerCase().split('/')[1])}_user`, userData);
                                  }
                                  this.props.setUser(user);
                                  this.props.setCurrentProperty(null);
                                  localStorage.removeItem('selectedProperty')
                                  this.props.setToken(data.switch_account.token);
                              }}
                    >
                        {(switchAccount, {loading}) => (
                            <View style={styles.toggleBtnCont}>
                                <Animated.View style={[styles.bgView, {
                                    marginStart: bgViewMarginLeft,
                                    // transform: [{translateX: }],
                                }]}/>
                                <TouchableOpacity activeOpacity={0.6}
                                                  style={styles.btnToggle}
                                                  onPress={() => {
                                                      if (this.state.currentUserType === BUYER && !loading) {
                                                          switchAccount().then(() => {
                                                              this.changeToSeller()
                                                          }).catch(() => {
                                                              // this.toggleBuyerModal();
                                                              this.changeToBuyer()
                                                          });
                                                      }

                                                  }}>
                                    <Label small
                                           font_medium
                                           color={Color.SECONDARY}
                                           align={'center'}>
                                        {Strings.seller}
                                    </Label>
                                </TouchableOpacity>
                                <TouchableOpacity activeOpacity={0.6}
                                                  style={styles.btnToggle}
                                                  onPress={() => {
                                                      if (this.state.currentUserType === SELLER && !loading) {

                                                          switchAccount().then(() => {

                                                              this.changeToBuyer()
                                                          }).catch((e) => {
                                                              this.toggleBuyerModal();
                                                              this.changeToSeller()
                                                          });
                                                      }
                                                  }}>
                                    <Label small
                                           font_medium
                                           align={'center'}
                                           color={Color.SECONDARY}
                                           style={CommonStyle.flex}>
                                        {Strings.buyer}
                                    </Label>
                                </TouchableOpacity>
                            </View>
                        )}

                    </Mutation>
                    {currentUserType === SELLER ? (
                        <Accordion isExpanded={true}
                                   iconColor={Color.TERNARY_TEXT_COLOR}
                                   renderHeader={this.renderAccordionHeader}
                                   renderContent={this.renderAccordionContent}
                                   contentContainerStyle={styles.accordionContainer}
                                   headerContainerStyle={styles.accordionHeader}/>) : null}
                    {this.renderDrawerItems()}
                </View>
            </ScrollView>
        )
    };

    //render bottom tabs icon
    renderTabs = (item) => {
        return (

            <TabBar.Item key={item.id}
                         title={item.name}
                         icon={
                             <CopilotStep text={item.text}
                                          active={this.state.tabIndex === 0}
                                          order={item.order}
                                          title={item.name}
                                          name={item.name}>
                                 <WrraperComponent isStep={item.isStep}>
                                     <Icon name={item.icon}
                                           size={16}
                                           style={{marginBottom: 3}}
                                           color={Color.DARK_GRAY}/>
                                 </WrraperComponent>
                             </CopilotStep>}
                         selectedIcon={
                             <CopilotStep text={item.text}
                                          active={this.state.tabIndex === 0}
                                          order={item.order}
                                          title={item.name}
                                          name={item.name}>
                                 <WrraperComponent isStep={item.isStep}>
                                     <Icon name={item.icon}
                                           size={18}
                                           style={{marginBottom: 3}}
                                           color={Color.TERNARY_TEXT_COLOR}/>
                                 </WrraperComponent>
                             </CopilotStep>}
                         selected={this.state.selectedTab.id === item.id}
                         onPress={() => this.onChangeTab(item)}>
                {this.renderDrawerScreens()}
            </TabBar.Item>

        )
    };

    //render header icon
    renderHeaderTitle = () => {
        return (
            <LogoForHeader size={ThemeUtils.responsiveScale(60)}/>

        )
    };

    //renderThirdIconInHeader
    renderTourIcon = () => {
        return (
            <CopilotStep text={"Her kan du starte denne guiden om igjen skulle du ønske det"}
                         order={10}
                         name={"Intro"}>
                <WrraperComponent isStep style={CommonStyle.content_center}>
                    <View ref={r => this.popOver = r}>
                        <Icon name={'disclaimer'}
                              size={20}
                              color={Color.WHITE}/>
                    </View>
                </WrraperComponent>
            </CopilotStep>

        )
    };

    renderPopOverItem = (item, index) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              key={item.name}
                              onPress={() => {
                                  this.togglePopOver(() => {
                                      this.onChangeTab(item)
                                      this.toggleTour();
                                  })
                              }}
                              style={styles.popOverLblCont}>
                <Label>
                    {item.name}
                </Label>
            </TouchableOpacity>

        )
    };

    //render drawer screens
    renderDrawerScreens = () => {
        let Screen = this.state.selectedTab.screen;
        /*if (!this.props.property) {
            return (
                <ActivityIndicator color={Color.PRIMARY}/>
            )
        }*/
        if (Screen) {
            return <Screen {...this.props}/>
        }
        return (
            <View style={[CommonStyle.flex, CommonStyle.content_center]}>
                <Label>
                    {`Under Development ${this.state.selectedTab.name}`}
                </Label>
            </View>
        )
    };

    renderPropertyListModal = () => {
        const {propertyListVisible, currentUserType} = this.state;
        if (currentUserType === BUYER) {
            return (
                <Query query={BUYER_PROPERTIES}
                       variables={{
                           status: 1
                       }}>
                    {({loading, data, error}) => {
                        if (data?.buyer_properties?.length) {
                            if (!this.props.property) {
                                if (IS_WEB && localStorage.getItem('selectedProperty')) {
                                    try {
                                        let property = JSON.parse(localStorage.getItem('selectedProperty'))
                                        this.props.setCurrentProperty(property)
                                    } catch (e) {

                                    }
                                } else {
                                    if (IS_WEB) {
                                        localStorage.setItem('selectedProperty', JSON.stringify(data?.buyer_properties[0]))
                                    }
                                    this.props.setCurrentProperty(data?.buyer_properties[0])
                                }
                            } else if (this.props.user) {
                                let property = data.buyer_properties.find(item => item.property?.id === this.props.property?.property?.id)
                                if (property) {
                                    if (IS_WEB) {
                                        localStorage.setItem('selectedProperty', JSON.stringify(property))
                                    }
                                    this.props.setCurrentProperty(property)
                                }

                            }
                        }
                        return (
                            <PropertyList visible={propertyListVisible}
                                          loading={loading}
                                          data={data?.buyer_properties ?? []}
                                          onAddNewProperty={this.addNewProperty}
                                          onClose={this.togglePropertyList}/>
                        )
                    }}
                </Query>
            )
        }
        return (
            <Query query={SELLER_PROPERTY_LIST}
                   fetchPolicy={'network-only'}
                   variables={{
                       status: 1
                   }}>
                {({loading, data, error, refetch}) => {
                    this.refetchProperties = refetch
                    if (data?.seller_property_list?.length && this.props.user) {
                        if (!this.props.property) {
                            if (IS_WEB && localStorage.getItem('selectedProperty')) {
                                try {
                                    let property = JSON.parse(localStorage.getItem('selectedProperty'))
                                    this.props.setCurrentProperty(property)
                                } catch (e) {

                                }
                            } else {
                                if (IS_WEB) {
                                    localStorage.setItem('selectedProperty', JSON.stringify(data?.seller_property_list[0]))
                                }
                                this.props.setCurrentProperty(data?.seller_property_list[0])
                            }
                        } else if (this.props.user) {
                            if (IS_WEB) {
                                localStorage.setItem('selectedProperty', JSON.stringify(data.seller_property_list.find(item => item.id === this.props.property.id)))
                            }
                            this.props.setCurrentProperty(data.seller_property_list.find(item => item.id === this.props.property.id))
                        }
                    }
                    return (
                        <PropertyList visible={propertyListVisible}
                                      loading={loading}
                                      data={data?.seller_property_list}
                                      onAddNewProperty={this.addNewProperty}
                                      onClose={this.togglePropertyList}/>
                    )
                }}
            </Query>
        )
    };

    render() {
        const {currentUserType, isBuyerNotAvailableVisible, isOppGjorVisible, descForBuyerDialog, isPopOverVisible, isStartTour} = this.state;
        const tabs = currentUserType === SELLER ? SELLER_TABS : BUYER_TABS;

        return (
            <View style={CommonStyle.flex}>
                <Toast ref={(r) => this.toast = r}/>
                <Dialog visible={isBuyerNotAvailableVisible}
                        buttonTitleSecond={Strings.okay}
                        onPositivePress={this.toggleBuyerModal}
                        onNegativePress={this.toggleBuyerModal}
                        desc={"Du vil først få tilgang til kjøperplattformen når du har kjøpt en bolig gjennom W Eiendomsmegling. Da vil alle opplysninger i forbindelse med kjøpet og salget ditt være tilgjengelig her på din side."}/>

                <Modal visible={isStartTour}
                       onRequestClose={()=>this.toggleTour()}
                       transparent>
                    <View style={styles.modal}>
                        <View style={styles.modalCont}>
                            <Label small
                                   align={'center'}
                                   mt={ThemeUtils.relativeRealHeight(1)}>
                                {this.getIntroText()}
                            </Label>
                            <View style={styles.introModalBtnCont}>
                                <TouchableOpacity style={styles.btnCont}
                                                  onPress={()=>this.toggleTour()}>
                                    <Label>
                                        {Strings.skip}
                                    </Label>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.btnCont, styles.btn]}
                                                  onPress={() => {
                                                      this.toggleTour(()=>{
                                                          this.props.start()
                                                      });

                                                  }}>
                                    <Label color={Color.WHITE}>
                                        {Strings.next}
                                    </Label>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal>

                <Drawer ref={ref => this.drawer = ref}
                        drawerWidth={ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(60) : undefined}
                        drawerBackgroundColor={Color.WHITE}
                        sidebar={this.renderSideBar()}>
                    {this.renderPropertyListModal()}
                    <PopOver isVisible={isPopOverVisible}
                             placement={'bottom'}
                             onRequestClose={() => this.togglePopOver()}
                             backgroundStyle={{backgroundColor: Color.TRANSPARENT}}
                             fromView={this.popOver}>
                        <View style={styles.popOverCont}>
                            {salesProgress.map(this.renderPopOverItem)}
                        </View>
                    </PopOver>
                    <Header tintColor={Color.WHITE}
                            badge={this.state.isNewNotification || this.state.isNewMessage}
                            navigation={IS_WEB ? this.props.history : this.props.navigation}
                            onPressLeft={() => this.drawer.drawer.openDrawer()}
                            renderTitle={this.renderHeaderTitle}
                            headerLeft={'menu'}
                            headerRightFirst={'ds-property'}
                            onPressRightFirst={this.togglePropertyList}
                            headerRightSecond={'notifications'}
                            onPressRightSecond={this.openNotification}
                            renderHeaderThird={currentUserType === SELLER ? this.renderTourIcon : null}
                            onPressRightThird={() => this.togglePopOver()}

                    />

                    <TabBar unselectedTintColor={Color.DARK_GRAY}
                            styles={{
                                tabs: {
                                    height: 56,
                                    borderStyle: 'solid',
                                    flexDirection: 'row'
                                }
                            }}
                            tintColor={Color.SECONDARY}
                            barTintColor={Color.PRIMARY}>
                        {tabs.map(this.renderTabs)}
                    </TabBar>
                </Drawer>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        property: state.property,
        user: state.user,
        tourShown: state.tourShown || {}
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout()),
        setCurrentProperty: (property) => dispatch(setCurrentProperty(property)),
        setTourShown: (isShown) => dispatch(setTourShown(isShown)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(copilot({
    backdropColor: Color.PRIMARY_TRANSPARENT,
    animated: true,
    verticalOffset: IS_WEB || IS_IOS ? 0 : StatusBar.currentHeight,
    labels: {
        previous: Strings.back,
        next: Strings.next,
        skip: Strings.skip,
        finish: Strings.finish
    }
})(withFirebase(DrawerNavigation)));
