import gql from 'graphql-tag';


export const APP_UPDATE = gql`
query appVersion($type: AppType!, $appType: UserAppType!, $version: String!) {
  app_version(app_type: $type, user_app_type: $appType, version: $version) {
    is_force_update
    recommended_update
  }
}`;


export const SELLER_INTERVIEW = gql`
query sellerInterview($propertyId: Int!, $status: Int!) {
  moovin_seller_interviews(property_id: $propertyId, status: $status) {
    id
    seller_id
    property_id
    answer_json
    status
  }
}`;

export const USER_DETAILS=gql`
query user($id: ID!) {
  user(id: $id) {
    id
    first_name
    last_name
    email
    address
    mobile
    profile_image
    city
    zipcode
    status
    user_type_id
  }
}`;

export const PURCHASED_SERVICES = gql`
query purchased_services(
  $status: Int!
  $property_id: Int!
  $service_category: ServiceCatrgory
) {
  purchased_services(
    status: $status
    service_category: $service_category
    property_id: $property_id
  ) {
    service_types {
      id
      name
      vendor_services {
        id
        vendor_id
        service_type_id
        name
        image
        description
        free_text
        price
        is_free
        service_selected {
          id
          property_id
          usable_service_id
          usable_service_class
          price
          quantity
        }
      }
    }
    purchased_services {
      id
      name
      purchased_office_template {
        id
        office_id
        service_type_id
        headline
        description
        purchased_office_services {
          id
          name
          image
          description
          price
          is_free
          free_text
          external_url
          service_selected {
            id
            price
            quantity
          }
        }
        office_service_media {
          id
          media_url
          media_new_url
        }
        agent_service_media {
          media_url
          media_new_url
        }
      }
    }
  }
}
`



export const PROPERTY_DOC=gql`
query propertyDocuments($propertyId: Int!, $status: Int!) {
  property_documents(property_id: $propertyId, status: $status) {
    id
    property_id
    title
    doc_url
    doc_type
    is_buyer_seller_doc
    status
  }
}`


export const USER_DETAILS_LIST=gql`
query userDetails($ids: [Int]) {
  users_detail_list(ids: $ids) {
    id
    first_name
    last_name
    user_type_id
    email
    profile_image
  }
}`
