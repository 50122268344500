/* eslint-disable array-callback-return */
import {EditOutlined, LoadingOutlined} from '@ant-design/icons';
import {Col, Input, Row, Table, Typography,} from 'antd';
import React, {useEffect,useRef, useState} from 'react';
import {useHistory,Link} from 'react-router-dom';

import {Query} from 'react-apollo';
import {encryptId} from '../../../../utils/utils';
import {ADMIN_META_LISTING} from '../../../../../query/AdminQuery';
import {formatMessage} from "components/src/utils";
// import data from '../../../../../mock/photographerdata';

const {Title} = Typography;
const {Search} = Input;

export const MetaListing = () => {
    let router=useHistory();
    const [orderData, setOrderData] = useState([]);
    const [metaListingData, setMetaListingData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');

    const timerRef = useRef(null);

    const [prevPagination, setprevPagination] = useState({});
    const [paginationData, setPaginationData] = useState({
        current: 1,
        pageSize: 30,
        total: 1,
    });

    const usePreviousValue = () => {
        const ref = useRef();
        useEffect(() => {
            ref.current = searchKeyword;
        });
        return ref.current;
    };
    const prevValue = usePreviousValue();

    const searchFunc = e => {
        if (prevValue === '') {
            setprevPagination(paginationData);
        }
        if (e !== '') {
            setPaginationData({
                current: 1,
                pageSize: 30,
                total: 1,
            });
        } else {
            setPaginationData(prevPagination);
        }
    };

    const handleTableChange = pagination => {
        if (pagination) {
            setPaginationData(pagination);
            setprevPagination(paginationData);
        }
    };

    useEffect(() => {
        if (searchKeyword === '') {
            setprevPagination(paginationData);
        }
    }, []);

    // const menu = () => {

    const columns = [
        {
            title: `${formatMessage({ id: 'component.form.pagename' })}`,
            dataIndex: 'pagename',
            key: 'pagename',
            sorter: (a, b) => a.pagename - b.pagename,
            render: text => <a>{text}</a>,
        },

        {
            title: `${formatMessage({ id: 'component.form.pagetitle' })}`,
            dataIndex: 'pagetitle',
            key: 'pagetype',
            sorter: (a, b) => a.pagetitle - b.pagetitle,
            render: text => <a>{text}</a>,
        },
        {
            title: `${formatMessage({ id: 'component.form.description' })}`,
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description - b.description,
            render: text => <a>{text}</a>,
        },

        {
            title: `${formatMessage({ id: 'component.table.action' })}`,
            key: 'action',
            render: name => {
                const itemToSend = orderData.filter(item => name.key === item.id);
                console.log('Item to send==>', itemToSend[0]);
                return (
                    <span style={{ paddingLeft: 16 }}>
            <Link
                to={{
                    pathname: `/admin/metalisting/redigeremetalisting/${encryptId(
                        itemToSend.map(i => i.id),
                    )}`,
                    state: { pageType: itemToSend.map(i => i.page_type) },
                }}
            >
              <EditOutlined style={{ marginRight: 8 }} />
            </Link>
          </span>
                );
            },
        },
    ];

    return (
        <div className="contentshadow">
            <div className="text-primary main">
                <Row className="photo-order-wrapper">
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Title level={3} className="text-primary">
                            {formatMessage({
                                id: 'component.metalisting.title',
                            })}
                        </Title>

                        <Row>
                            <Col xs={24}>
                                <Query
                                    query={ADMIN_META_LISTING}
                                    variables={{
                                        page: paginationData.current,
                                        first: paginationData.pageSize,
                                        key: searchKeyword,
                                        sorting: {
                                            field: 'id',
                                            order: 'DESC',
                                        },
                                    }}
                                    fetchPolicy="no-cache"
                                    onCompleted={responseData => {
                                        setOrderData(responseData.meta_page_listing.data);
                                        if (
                                            responseData &&
                                            responseData.meta_page_listing &&
                                            responseData.meta_page_listing.data
                                        ) {
                                            setMetaListingData(responseData.meta_page_listing.data);
                                        }
                                        if (
                                            responseData &&
                                            responseData.meta_page_listing &&
                                            responseData.meta_page_listing.paginatorInfo
                                        ) {
                                            setPaginationData({
                                                current: responseData.meta_page_listing.paginatorInfo?.currentPage,
                                                pageSize: responseData.meta_page_listing.paginatorInfo?.perPage,
                                                total: responseData.meta_page_listing.paginatorInfo?.total,
                                            });
                                        }
                                    }}
                                >
                                    {({ data, refetch, loading, error }) => {
                                        console.log(error);
                                        const metaListersList = [];
                                        if (data && data.meta_page_listing.data) {
                                            console.log('insodwe;', data.meta_page_listing.data);
                                            data.meta_page_listing.data.map(item => {
                                                const MetaListingList = {
                                                    key: item?.id,
                                                    pagename: item?.name,
                                                    pagetitle: item?.title,
                                                    description: item?.description,
                                                };
                                                metaListersList.push(MetaListingList);
                                            });
                                        }
                                        if (!error) {
                                            return (
                                                <>
                                                    <Row>
                                                        <Col lg={12} xs={24}>
                                                            <div className="table-searchbar">
                                                                <Search
                                                                    placeholder={`${formatMessage({
                                                                        id: 'component.metalisting.tablesearch',
                                                                    })}`}
                                                                    onSearch={value => setSearchKeyword(value)}
                                                                    onChange={e => {
                                                                        const searchText = e.target.value;
                                                                        searchFunc(searchText);
                                                                        if (timerRef.current) {
                                                                            clearTimeout(timerRef.current);
                                                                        }
                                                                        timerRef.current = setTimeout(() => {
                                                                            console.log('On Change===>>>', searchText);
                                                                            setSearchKeyword(searchText);
                                                                        }, 2000);
                                                                    }}
                                                                    style={{ padding: '8px 15px', marginBottom: '20px' }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className="unbordered-table-wrapper admin-tables">
                                                        <Table
                                                            columns={columns}
                                                            dataSource={metaListersList}
                                                            onChange={handleTableChange}
                                                            pagination={{
                                                                current: paginationData?.current,
                                                                pageSize: paginationData?.pageSize,
                                                                total: paginationData?.total,
                                                                showSizeChanger: true,
                                                                showTotal: () =>
                                                                    `Total ${paginationData.total} ${formatMessage({
                                                                        id: 'component.table.items',
                                                                    })}`,
                                                                pageSizeOptions: ['30', '35', '40', '45', '50'],
                                                            }}
                                                            loading={loading}
                                                        />
                                                    </div>
                                                </>
                                            );
                                        }
                                        if (!loading && error) {
                                            return (
                                                <div className="loader-wrapper">Noe gikk galt, vennligst prøv igjen.</div>
                                            );
                                        }
                                        return <></>;
                                    }}
                                </Query>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default MetaListing;
