import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.relativeWidth(6)

const styles = StyleSheet.create({
    container: {
        flex: 1,

        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        flex:1,

        marginHorizontal:containerHorizontalMargin

    },
    bottomdownContainer: {
        flex: 1,
        justifyContent: 'space-between'
    },
    checkbox:{alignItems:'flex-start',flex:1,flexDirection:'column',marginTop:15,alignContent:'center',},
    radioView:{flexDirection:'row',marginTop:5,marginBottom:10},

    fieldContainer: {
        alignContent: 'center',
    },
    labeliconContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',


    },

    labeliconContainers:{
        marginTop: 20,
         flexDirection: 'row',
        justifyContent: 'flex-start',
         overflow:'scroll'
    },
    Financingradio:{marginBottom:25}


});
export {styles};
