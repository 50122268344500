import { EditOutlined, MoreOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Typography, Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { formatMessage } from 'components/src/utils';
// import { Query } from 'react-apollo';
// import { ADMIN_AGENT_TEAM } from '../../../../../query/AdminQuery';
import data from '../../../../../mock/agentsdata';
import '../../../../sass/defaultstyles.scss';

const { Title } = Typography;
const { Search } = Input;

const menu = (
  <Menu>
    <Menu.Item>
      {formatMessage({
        id: 'component.form.delete',
      })}
    </Menu.Item>
  </Menu>
);

export const Team = () => {
  const [orderData, setOrderData] = useState([]);

  const columns = [
    {
      title: `${formatMessage({ id: 'component.table.name' })}`,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      render: text => (
        <Link to="/admin/team/redigereteam">
          <a>{text}</a>
        </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.table.mainagent' })}`,
      dataIndex: 'mainagent',
      key: 'mianagent',
      sorter: (a, b) => a.mianagent - b.mianagent,
      render: text => (
        <Link to="/admin/team/redigereteam">
          <a>{text}</a>
        </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.table.subagent1' })}`,
      dataIndex: 'subagent1',
      key: 'subagent1',
      sorter: (a, b) => a.subagent1.length - b.subagent1.length,
      render: text => (
        <Link to="/admin/team/redigereteam">
          <a>{text}</a>
        </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.table.subagent2' })}`,
      dataIndex: 'subagent2',
      key: 'subagent2',
      sorter: (a, b) => a.subagent2.length - b.subagent2.length,
      render: text => (
        <Link to="/admin/team/redigereteam">
          <a>{text}</a>
        </Link>
      ),
    },
    {
      title: `${formatMessage({ id: 'component.table.action' })}`,
      key: 'action',
      render: name => {
        const itemToSend = orderData.filter(item => name.key === item.id);
        console.log('Item to send==>', itemToSend[0]);

        return (
          <span style={{ paddingLeft: 16 }}>
            <Link
              to={{
                pathname: '/admin/team/redigereteam',
                state: { orderItem: itemToSend[0] },
              }}
            >
              <EditOutlined style={{ marginRight: 8 }} />
            </Link>
            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
              <MoreOutlined />
            </Dropdown>
          </span>
        );
      },
    },
  ];

  return (
    <div className="contentshadow">
      <div className="text-primary main">
        <Row className="photo-order-wrapper">
          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
            <Title level={3} className="text-primary">
              {formatMessage({
                id: 'component.team.title',
              })}
            </Title>
            <p>
              {formatMessage({
                id: 'component.team.description',
              })}
            </p>
            <Link to="/admin/team/leggetilteam">
              <Button
                type="primary"
                className="btn-secondary admin-forms-btn with-lg-spaces"
                size="large"
              >
                {formatMessage({ id: 'component.team.addteam' })}
              </Button>
            </Link>
            <Row>
              <Col xs={24}>
                <Search
                  placeholder={formatMessage({
                    id: 'component.team.tablesearch',
                  })}
                  onSearch={value => console.log(value)}
                  style={{ width: 550, padding: '8px 15px', marginBottom: '20px' }}
                  className="table-searchbar"
                />

                        <div className="unbordered-table-wrapper admin-tables">
                          <Table locale={{emptyText: 'Ingen data'}}
                            columns={columns}
                            dataSource={data}
                            pagination={{
                              defaultPageSize: 5,
                              showSizeChanger: true,
                              pageSizeOptions: ['5', '10', '15'],
                            }}
                          />
                        </div>

              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Team;
