import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {CheckBox, CustomTextArea, Label, RadioGroup} from "components/src/components";
//Utils
import {Constants, Strings} from "components/src/utils";
import styles from "../styles";
import {setSellerInterViewForm} from "components/src/redux/action";
import {connect} from "react-redux";

const Q1 = Strings.sixthStep.q1;
const Q2 = Strings.sixthStep.q2;
const Q3 = Strings.sixthStep.q3;
const Q4 = Strings.sixthStep.q4;
const Q5 = Strings.sixthStep.q5;


const HEAT_OPTIONS = [
    {name: Strings.sixthStep.heatOptions, value: 'Heating cables'},
    {name: Strings.sixthStep.waterborneHeat, value: 'Waterborne Heat'},
    {name: Strings.sixthStep.radiator, value: 'Radiator'},
    {name: Strings.sixthStep.heatPump, value: 'Heat pump'},

];
const HEAT_OPTIONS2 = [
    {name: Strings.sixthStep.balancedVentilation, value: 'Balanced ventilation with heat recovery'},
    {name: Strings.sixthStep.heaters, value: 'Heaters'},
    {name: Strings.sixthStep.woodStove, value: 'Wood Stove'},
    {name: Strings.secondStep.fireplace, value: 'Fireplace'},
]
const HEAT_OPTIONS3 = [
    {name: Strings.sixthStep.oilHeating, value: 'Oil heating'},
]

const FIRE_PLACES = [{
    name: Strings.sixthStep.bioFirePlace, value: 'Bio-fireplace'
}, {
    name: Strings.sixthStep.gasFirePlace, value: 'Gas-fireplace'
}];

const HEAT_PUMPS = [{
    name: Strings.sixthStep.airToAir, value: 'Air to air',
}, {
    name: Strings.sixthStep.airToWater, value: 'Air to water',
}, {
    name: Strings.sixthStep.waterToWater, value: 'Water to water',
}]



function SixthStep(props) {

    const {setForm, interViewForm} = props;
    const [heatOptions, setHeatOptions] = useState(interViewForm.find(item => item.interview_section === 6 && item.question_no === 18)?.answer[2]?.berth ?? []);

    const [fireplace, setFireplaceType] = useState(interViewForm.find(item => item.interview_section === 6 && item.question_no === 18)?.answer[1]?.fireplace ?? []);

    const [heatPump, setHeatPumpType] = useState(interViewForm.find(item => item.interview_section === 6 && item.question_no === 18)?.answer[0]?.heat_pump ?? []);

    const [isPipesChanged, setPipeChange] = useState(interViewForm.find(item => item.interview_section === 6 && item.question_no === 19)?.answer ?? 'Ja');

    const [heatOptComment, setHeatOptComment] = useState(interViewForm.find(item => item.interview_section === 6 && item.question_no === 18)?.comment ?? '');
    const [whenPipeChange, setPipeChangeTime] = useState(interViewForm.find(item => item.interview_section === 6 && item.question_no === 20)?.answer ?? '');
    const [waterTankLocation, setWaterTankLocation] = useState(interViewForm.find(item => item.interview_section === 6 && item.question_no === 21)?.answer ?? '');

    useEffect(() => {

        let answer = [
            {
                "question": Q1,
                "interview_section": 6,
                "question_no": 18,
                "type": "CHECKBOX",
                "answer": [
                    {
                        "heat_pump": heatPump
                    },
                    {
                        "fireplace": fireplace
                    },
                    {
                        "berth": heatOptions
                    }
                ],
                "extra": null,
                "comment": heatOptComment
            }, {
            "question":Q3,
                "interview_section": 6,
                "question_no": 19,
                "type": "RADIO",
                "answer": isPipesChanged,
                "extra": null,
                "comment": null
            }, {
                "question":Q4,
                "interview_section": 6,
                "question_no": 20,
                "type": "TEXT",
                "answer": whenPipeChange,
                "extra": null,
                "comment": null
            }, {
                "question":Q5,
                "interview_section": 6,
                "question_no": 21,
                "type": "TEXT",
                "answer": waterTankLocation,
                "extra": null,
                "comment": null
            }
        ];
        setForm([...interViewForm.filter(item => item.interview_section !== 6), ...answer])

    }, [
        heatOptions,
        heatPump,
        fireplace,
        isPipesChanged,
        heatOptComment,
        whenPipeChange,
        waterTankLocation
    ]);

    const filterAndSetHeatOption = (options) => {
        setHeatOptions(val => [...new Set([...val, ...options])])
    };

    return (
        <View>
            <Label small
                   font_medium
                   mb={10}>
                {Q1}
            </Label>
            <CheckBox onCheckChanged={filterAndSetHeatOption}
                      style={styles.chk}
                      defaultChecked={heatOptions}
                      options={HEAT_OPTIONS}/>

            {heatOptions.includes('Heat pump') ? (
                <CheckBox onCheckChanged={setHeatPumpType}
                          defaultChecked={heatPump}
                          style={styles.chkSub}
                          options={HEAT_PUMPS}/>
            ) : null}

            <CheckBox onCheckChanged={filterAndSetHeatOption}
                      style={styles.chk}
                      defaultChecked={heatOptions}
                      options={HEAT_OPTIONS2}/>

            {heatOptions.includes('Fireplace') ? (
                <CheckBox onCheckChanged={setFireplaceType}
                          style={styles.chkSub}
                          defaultChecked={fireplace}
                          options={FIRE_PLACES}/>
            ) : null}
            <CheckBox onCheckChanged={filterAndSetHeatOption}
                      style={styles.chk}
                      defaultChecked={heatOptions}
                      options={HEAT_OPTIONS3}/>

            <CustomTextArea mt={10}
                            value={heatOptComment}
                            onChange={setHeatOptComment}/>

            <Label small
                   mt={10}
                   font_medium
                   mb={10}>
                {Q3}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS}
                        style={styles.radio}
                        contentStyle={styles.radioCont}
                        selectedOptions={isPipesChanged}
                        onSelectOption={setPipeChange}/>


            {isPipesChanged === 'Ja' ? (<CustomTextArea labelText={Q4}
                                                           value={whenPipeChange}
                                                           onChange={setPipeChangeTime}
                                                           mt={10}/>) : null}

            <CustomTextArea mt={10}
                            value={waterTankLocation}
                            onChange={setWaterTankLocation}
                            labelText={Q5}/>


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form))
    }

};
export default connect(mapStateToProps, mapDispatchToProps)(SixthStep)

