import React from 'react'
import {ActivityIndicator, Animated, View} from 'react-native'
//Network
import {Mutation, Query} from "@apollo/react-components";
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "../../../../../utils";
// import { Checkbox, Radio,WhiteSpace } from '@ant-design/react-native/lib/';
// import List from "@ant-design/react-native/lib/list";
import {logout, setToken, setUser} from "../../../../../redux/action";
import {connect} from "react-redux";
import {CheckBox, CustomButton, Header, Hr, Label} from "../../../../../components";

import {styles} from './styles.js';
import {SELLER_ADD_FINANCING} from "../../../../../api/sellerMutation";
import {SELLER_FINANCE_TYPE, SELLER_SELECTED_FINANCE} from "../../../../../api/sellerQuery";
// import {Button, InputItem} from "@ant-design/react-native";
// import { Radio, WhiteSpace } from '@ant-design/react-native';
// import { DatePickerView } from 'antd-mobile';

const option = [{
    index: 1,
    name: Strings.AgreeFinancing,
    value: 1
}]

class Financing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            isAgree: [],
            financeType: []
        }
        this.scrollY = new Animated.Value(0)


    }

    addFinance = (addFinance) => {

        if (this.state.financeType.length) {
            addFinance({
                variables: {
                    financingType: this.state.financeType,
                    isAgree: this.state.isAgree.includes(1) ? 1 : 0,
                    propertyId: this.props.property?.id
                }
            })
        }

    };

    render() {
        return (
            <View style={CommonStyle.container}>
                <Header animatedTitle={Strings.FinancingTitle}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={{
                                         flexGrow: 1,
                                         paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2
                                     }}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>
                    <Query query={SELLER_SELECTED_FINANCE}
                           fetchPolicy={'network-only'}
                           variables={{
                               propertyId: this.props.property?.id
                           }}>
                        {(selectedQuery) => (
                            <View style={styles.container}>
                                {console.log(selectedQuery.data, "DATA")}
                                <View style={styles.topContainer}>
                                    <View style={styles.labeliconContainer}>
                                        <Icon
                                            size={ThemeUtils.fontXXLarge}
                                            name={'rate'}
                                            color={Color.PRIMARY_DARK}/>

                                    </View>
                                    <View>
                                        <Label small
                                               mt={20}
                                               font_medium
                                               align={'left'}>
                                            {Strings.getbettermortgageterms}
                                        </Label>
                                        <Label
                                            mt={16}
                                            font_regular
                                            mb={18}
                                            small
                                            align={'left'}>{Strings.FinancingInformation}</Label>
                                    </View>
                                    <View style={styles.bottomdownContainer}>


                                        <Query query={SELLER_FINANCE_TYPE}
                                               variables={{
                                                   status: true
                                               }}>
                                            {({loading, data, error}) => {
                                                if (selectedQuery.loading || (loading && !data)) {
                                                    return <ActivityIndicator color={Color.PRIMARY}/>
                                                }
                                                let selected = selectedQuery?.data?.seller_financing_details?.financing_types.map(item => item.id) ?? []
                                                return (<>
                                                    <View style={styles.checkbox}>

                                                        <CheckBox options={data?.financing_type ?? []}
                                                                  defaultChecked={selected}
                                                                  disabled={selected.length}
                                                                  style={styles.Financingradio}
                                                                  size={20}
                                                                  onCheckChanged={(val) => this.setState({financeType: val})}/>
                                                    </View>
                                                    <Hr marginBottom={20}/>
                                                    <CheckBox options={option}
                                                              size={ThemeUtils.fontXLarge}
                                                              defaultChecked={selected?.length ? [1] : [0]}
                                                              disabled={selected.length}
                                                              labelProps={{
                                                                  bolder: true,
                                                                  small: true
                                                              }}
                                                              style={styles.energyradio}
                                                              bold
                                                              onCheckChanged={(val) => {
                                                                  this.setState({isAgree: val})
                                                              }}
                                                    />
                                                </>)
                                            }}
                                        </Query>


                                        <Mutation mutation={SELLER_ADD_FINANCING}
                                                  onCompleted={(data) => {
                                                      console.log(data,"DAT")
                                                      IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack()
                                                  }}
                                                  onError={(e) => {
console.log(e,"ERR")
                                                  }}>
                                            {(addFinance, {loading,error, data}) => (
                                                <CustomButton
                                                    disabled={!this.state.isAgree.includes(1)}
                                                    mt={18}
                                                    mb={18}
                                                    loading={loading}
                                                    font_medium
                                                    onPress={() => this.addFinance(addFinance)}
                                                    title={Strings.send}/>
                                            )}

                                        </Mutation>
                                    </View>
                                </View>

                            </View>
                        )}

                    </Query>
                </Animated.ScrollView>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Financing);
