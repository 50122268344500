import {StyleSheet,Dimensions} from 'react-native'
import {Color, IS_WEB, ThemeUtils} from "../../../utils";

export default StyleSheet.create({
    dashboardItem: {
        height: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(30) : ThemeUtils.relativeRealWidth(45),
        borderRadius: ThemeUtils.relativeRealWidth(2),

    },
    imgServices: {
        flex: 1,
        top: 0,
        bottom: 0,
        end: 0,
        start: 0,
        justifyContent: 'flex-end',
        borderRadius: ThemeUtils.relativeRealWidth(2),
        width: IS_WEB ? undefined : ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(30) : ThemeUtils.relativeRealWidth(45)

    },
    lblCont: {
        flex: 1,
        justifyContent: 'flex-end',
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: ThemeUtils.relativeRealHeight(1)
    },
    dashBoardCont: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    singleBanner: {
        marginBottom: 15,
        height: ThemeUtils.relativeRealWidth(100) * 0.17,
        width: "100%",
    },
    halfBanner:{
        marginBottom: 15,
        height: ThemeUtils.relativeRealWidth(100) * 0.35,
        width: "100%",
    }
})
