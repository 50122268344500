import {StyleSheet} from 'react-native';
import {Color} from '../../../../../utils';

const containerHorizontalMargin = 20;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    titleContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    titleContainers: {
        marginTop: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center',
        justifyContent: 'space-around',
    },

    fieldContainer: {
        alignContent: 'center',
    },
    deviderline:{
        marginBottom: 18,
        marginTop: 18,
        flexDirection: 'row',
        borderColor: '#9C8B9D',
        width: "100%",
        borderWidth: 0.5,
    }
});

export {styles};
