/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  Row,
  Typography,
  Upload,
} from "antd";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { isNull } from "lodash";
import React from "react";
import { Mutation } from "react-apollo";
import { withRouter,Link } from "react-router-dom";
import { formatMessage } from "components/src/utils";
import { ADMIN_ADD_PROFESSIONAL } from "../../../../../../mutation/AdminMutation";
import { STORAGE_URL } from "../../../../../utils/constant";

const { Title } = Typography;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Du kan kun laste opp JPG/PNG filer.");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Bilder må være mindre en 2MB");
  }
  return isJpgOrPng && isLt2M;
}
class AddProfessional extends React.Component {
  state = {
    editorState: BraftEditor.createEditorState(),
    loading: false,
    professionalVideo: [],
    imageObject: '',
  };

  componentDidMount() {
    this.isLivinig = true;
    setTimeout(this.setEditorContentAsync, 3000);
  }

  componentWillUnmount() {
    this.isLivinig = false;
  }

  handleChange = (editorState, info) => {
    this.setState({
      editorState,
    });

  };

  handleUploadChange = info => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
          imageObject: info.file.originFileObj,
        }),
    );
  };

  setEditorContentAsync = () => {
    // eslint-disable-next-line no-unused-expressions
    this.isLivinig &&
    this.setState({
      editorState: BraftEditor.createEditorState(),
    });
  };

  render() {
    const uploadButton = (
        <div>
          {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div className="ant-upload-text">{formatMessage({ id: 'component.form.upload' })}</div>
        </div>
    );
    const { editorState, imageUrl, professionalVideo, imageObject } = this.state;

    const onFinish = (values, createProfessional) => {
      console.log('Success:', values, '\n newsDesc==>', editorState.isEmpty());
      console.log('professionalVideo:', isNull(editorState));

      const inputField = {
        title: values.professionaltitle,
        description: editorState.toHTML()
      };

      if (imageObject) {
        inputField.cover_image = imageObject;
      }
      if (professionalVideo.length > 0) {
        inputField.video = professionalVideo[0].originFileObj;
      }

      createProfessional({
        variables: {
          input: inputField,
        },
      }).then(data => {
        console.log('Data submitted===>>>', data);
        if (data && data.data.add_professional) {
          notification.success({
            message: `Posten ble lagt til`,
            description: '',
            duration: 3,
          });
          this.props.history.push(`/admin/faglig`);
        }
      });
    };

    const normFile = e => {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return (
        <>
          <Row className="contentshadow">
            <Col xs={24} lg={24}>
              <Title level={3} className="text-primary">
                {formatMessage({ id: 'component.professional.addprofessional' })}
              </Title>
              <p className="text-primary">
                Legg til en faglig relatert artikkel eller annen faglig informasjon.
              </p>
              <Mutation mutation={ADMIN_ADD_PROFESSIONAL}>
                {(createProfessional, { loading, data }) => (
                    <Form
                        name="addprofessional"
                        initialValues={{ remember: true }}
                        onFinish={values => onFinish(values, createProfessional)}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        className="user-forms addEditform"
                    >
                      <Form.Item
                          name="cover_photo"
                          label={formatMessage({
                            id: 'component.news.coverphoto',
                          })}
                          rules={[
                            {
                              required: false,
                              message: 'Vennligst skriv inn profilbilde',
                            },
                          ]}
                      >
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            // beforeUpload={beforeUpload}
                            onChange={this.handleUploadChange}
                            accept={['.jpg', '.jpeg', '.png', '.svg']}
                        >
                          {imageUrl ? (
                              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                          ) : (
                              uploadButton
                          )}
                        </Upload>
                      </Form.Item>
                      <Form.Item
                          label={formatMessage({ id: 'component.professional.professionaltitle' })}
                          name="professionaltitle"
                          rules={[
                            {
                              required: true,
                              message: `Vennligst skriv inn ${formatMessage({
                                id: 'component.professional.professionaltitle',
                              }).toLowerCase()}!`,
                            },
                          ]}
                      >
                        <Input
                            placeholder={formatMessage({
                              id: 'component.professional.professionaltitleplaceholder',
                            })}
                            size="large"
                        />
                      </Form.Item>
                      <Form.Item
                          label={formatMessage({ id: 'component.professional.professionaldesc' })}
                          name="professionaldesc"
                          rules={[
                            {
                              required: true,
                              message: `Vennligst skriv inn ${formatMessage({
                                id: 'component.professional.professionaldesc',
                              }).toLowerCase()}!`,
                            },
                          ]}
                      >
                        <BraftEditor
                            className="editor-wrapper"
                            language="en"
                            value={editorState}
                            onChange={this.handleChange}
                            contentStyle={{ height: 210 }}
                            placeholder={formatMessage({
                              id: 'component.professional.professionaldescplaceholder',
                            })}
                        />
                      </Form.Item>
                      <Form.Item
                          name="cover_video"
                          label={formatMessage({ id: 'component.news.uploadvideo' })}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          rules={[
                            {
                              required: false,
                              message: `Vennligst velg ${formatMessage({
                                id: 'component.news.uploadvideo',
                              }).toLowerCase()}!`,
                            },
                          ]}
                      >
                        <Upload
                            name="logo"
                            action="/upload.do"
                            listType="picture"
                            showUploadList={false}
                            accept={['.mp4']}
                            fileList={false}
                            onChange={({ fileList }) => {
                              console.log('video-->', fileList[0]);
                              const file = [];
                              file.push(fileList[0]);
                              this.setState({
                                professionalVideo: file,
                              });
                            }}
                        >
                          <Button size="large">
                            <UploadOutlined /> {formatMessage({ id: 'component.form.upload' })}
                          </Button>
                        </Upload>
                      </Form.Item>
                      {professionalVideo.length !== 0 && (
                          <Row gutter={32}>
                            <Col xs={24} lg={16}>
                              {professionalVideo?.map(item => (
                                  <div className="default_list mt-3 mb-3">
                                    <div>
                                      <i className="icon-attachment" />
                                      <span className="ml-2">
                                {item.originFileObj ? (
                                    <span>{item.name}</span>
                                ) : (
                                    <a
                                        href={`${STORAGE_URL}${item.media_url}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                      {item.name}
                                    </a>
                                )}
                              </span>
                                    </div>
                                    <div
                                        className="upload-media-delete"
                                    >

                                      <i
                                          className="icon-delete"
                                          onClick={() => this.setState({ professionalVideo: [] })}
                                      />
                                    </div>
                                  </div>
                              ))}
                            </Col>
                          </Row>
                      )}
                      <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          className="btn-secondary buttons admin-forms-btn mt-3"
                          loading={loading}
                      >
                        {formatMessage({
                          id: 'component.news.publish',
                        })}
                      </Button>
                      <Link to="/admin/faglig">
                        <Button size="large" className="buttons admin-forms-btn back-button mt-3">
                          {formatMessage({
                            id: 'component.form.cancel',
                          })}
                        </Button>
                      </Link>
                    </Form>
                )}
              </Mutation>
            </Col>
          </Row>
        </>
    );
  }
}
export default withRouter(AddProfessional);
