import { StyleSheet } from 'react-native';
import {Color, ThemeUtils} from '../../../utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop:40,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        // alignContent: 'center'
    },
    titleCont: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },

    deviderline:{
        flexDirection: 'row',
        borderColor: '#3A173C',
        width: "100%",
        borderWidth: 0.5,

    },
    fieldContainer: {
        alignContent: 'center',
    },
    labeliconView: {

        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    labelTopView:{alignItems:'flex-start',flex:1,flexDirection:'row',marginTop:15,alignContent:'center',justifyContent:'space-between'},

    energyradio:{
        marginBottom:15,
        fontSize:12,
        alignContent:'center',

    },
    fieldView:
    {
        marginTop: 20,
         flexDirection: 'row',
          justifyContent: 'flex-start',
    alignItems: 'center'
},
uploadView:
    {
        flexDirection:'row',
        width:'125px',
        height:'48px',
        alignItems:'center',
        borderWidth:2,
        borderColor:Color.INPUT_BORDER,
        justifyContent:'center',
        alignContent:'center',
        marginBottom:15,
        borderRadius:6
    },

});
export { styles };
