// import userPlaceholder from './../assets/defaultuser.png';
import userPlaceholder from './../../../assets/defaultuser.png';
import {STORAGE_URL, USER_TYPE} from '../../../utils/constant';
import {LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, notification, Row, Typography} from 'antd';
import Cookies from 'js-cookie';
import React, {useRef, useState} from 'react';
import {Mutation,useMutation} from 'react-apollo';
import {Link, useHistory} from "react-router-dom";
import {formatMessage} from "components/src/utils";
// import profile from '../../../assets/sales_preparation/book_inspection.png';
import {CHANGE_PROFILE_PIC} from '../../../../mutation/SellerMutation';
import {ADMIN_UPDATE_EDIT_PROFILE} from '../../../../mutation/AdminMutation';
import '../../../sass/defaultstyles.scss';
import './editprofile.scss';

const {Title} = Typography;

// const onFinish = values => {

const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
};

const EditProfile = () => {
    let router=useHistory();
    const { user } = JSON.parse(Cookies.get(`${USER_TYPE.ADMIN}_user`));

    const [changeProfilePic, profileImageResponse] = useMutation(CHANGE_PROFILE_PIC);

    const [users, setUsers] = useState(user);
    const fileRef = useRef(null);

    const onFinish = (values, updateProfile) => {
        console.log('Success:', values);
        // TODO: add the data from the success form response below
        const input = {
            first_name: values.firstname,
            last_name: values.lastname,
        };

        if (values.phone) {
            input.mobile = values.phone;
        }
        updateProfile({
            variables: {
                input,
            },
        }).then(({ data, loading }) => {
            console.log('Data submitted===>>>', data, loading);
            if (data && data.update_admin_profile) {
                notification.success({
                    message: formatMessage({ id: 'component.editprofile.success' }),
                    description: '',
                    duration: 3,
                });
                const userData = {
                    user: data.update_admin_profile.user,
                    userType: USER_TYPE.ADMIN,
                };
                Cookies.set(`${USER_TYPE.ADMIN}_user`, userData);
                window.dispatchEvent(new Event('updateAdminProfile'));
                router.goBack();
            }
        });
    };

    return (
        <div className="contentshadow">
            <div className="text-primary main">
                <Row>
                    <Col xs={24}>
                        <Title level={3} className="text-primary">
                            {formatMessage({
                                id: 'component.editprofile.title',
                            })}
                        </Title>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>

                        <Row>
                            <Col xs={24}>
                                <div className="user-profile-box">
                                    <img
                                        src={
                                            user.profile_image
                                                ? `${STORAGE_URL}${users.profile_image}`
                                                : userPlaceholder
                                        }
                                        alt={users.first_name + users.last_name}
                                        className="profile-picture"
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={24}
                                className="mt-3"
                                onClick={() => {
                                    if (fileRef) {
                                        fileRef.current.click();
                                    }
                                }}
                            >
                                <input
                                    type="file"
                                    multiple
                                    accept=".jpg,.png,.jpeg"
                                    disabled={profileImageResponse.loading}
                                    onChange={e => {
                                        console.log('Files===>>>', e.target.files);
                                        changeProfilePic({
                                            variables: {
                                                file: e.target.files[0],
                                            },
                                        }).then(({ data, loading }) => {
                                            if (data && !loading) {
                                                const tempUser = JSON.parse(Cookies.get(`${USER_TYPE.ADMIN}_user`))
                                                    .user;
                                                tempUser.profile_image = data.change_profile_pic;
                                                const userData = {
                                                    user: tempUser,
                                                    userType: USER_TYPE.ADMIN,
                                                };
                                                Cookies.set(`${USER_TYPE.ADMIN}_user`, userData);
                                                window.dispatchEvent(new Event('updateAdminProfile'));
                                                setUsers(tempUser);
                                                console.log(user);
                                            }
                                        });
                                    }}
                                    ref={fileRef}
                                    className="d-none"
                                />
                                <Button>
                                    <UploadOutlined /> {profileImageResponse.loading ? "Laster opp..." : "Last opp"}
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Mutation mutation={ADMIN_UPDATE_EDIT_PROFILE}>
                            {(updateProfile, { loading }) => {
                                return (
                                    <Form
                                        layout="vertical"
                                        className="user-forms mt-3"
                                        name="editprofile"
                                        initialValues={{
                                            remember: true,
                                            firstname: user.first_name,
                                            lastname: user.last_name,
                                            email: user.email,
                                            phone: user.mobile,
                                        }}
                                        colon={false}
                                        onFinish={values => onFinish(values, updateProfile)}
                                        // onFinish={values => onFinish(values)}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Row gutter={16}>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="firstname"
                                                    label={formatMessage({
                                                        id: 'component.form.firstname',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: `Vennligst fyll inn ${formatMessage({
                                                                id: 'component.form.firstname',
                                                            }).toLowerCase()}`,
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        defaultValue="Emile"
                                                        style={{ width: '100%' }}
                                                        placeholder={formatMessage({
                                                            id: 'component.form.firstname',
                                                        })}
                                                        size="large"
                                                        className="pickerwidth"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="lastname"
                                                    label={formatMessage({
                                                        id: 'component.form.lastname',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Vennligst fyll inn etternavn',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        defaultValue="Pettersen"
                                                        placeholder={formatMessage({
                                                            id: 'component.form.lastname',
                                                        })}
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="email"
                                                    label={formatMessage({
                                                        id: 'component.form.email',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Vennligst oppgi epost',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        disabled
                                                        style={{ width: '100%' }}
                                                        placeholder={formatMessage({
                                                            id: 'component.form.email',
                                                        })}
                                                        size="large"
                                                        className="pickerwidth"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={8}>
                                                <Form.Item
                                                    name="phone"
                                                    label={formatMessage({
                                                        id: 'component.form.phone',
                                                    })}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Vennligst fyll inn telefon',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder={formatMessage({
                                                            id: 'component.form.phone',
                                                        })}
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={24}>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="btn-secondary buttons admin-forms-btn"
                                                    size="large"
                                                    loading={loading}
                                                >
                                                    {formatMessage({
                                                        id: 'component.form.save',
                                                    })}
                                                </Button>
                                                <Link to="/admin/dashboard">
                                                    <Button
                                                        className="text-primary admin-forms-btn"
                                                        style={{ marginLeft: 8 }}
                                                        size="large"
                                                    >
                                                        {formatMessage({
                                                            id: 'component.form.cancel',
                                                        })}
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                );
                            }}
                        </Mutation>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default EditProfile;
