import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from '../../../../../utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    topContainer: {
        margin: containerHorizontalMargin,
        alignContent: 'center'
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labeliconContainer: {
        marginTop: 20,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    SettlementRadio: {
        marginVertical: 5
    },
    deviderline: {
        flexDirection: 'row',
        borderColor: '#3A173C',
        width: "100%",
        borderWidth: 0.5,
    },
    fieldView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    checkbox: {flexDirection: 'row', alignItems: 'center'},
    receivablesCont: {
        marginHorizontal: containerHorizontalMargin
    },
    fraDateCont: {
        marginHorizontal: containerHorizontalMargin,
        marginBottom: 10
    }

});
export {styles};
