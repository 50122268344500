import React from 'react'
//Components
import {Keyboard, ScrollView, TouchableOpacity, View} from 'react-native'
import {CustomButton, InputField, Label, LogoForHeader, RadioGroup, Toast} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//Network
import {Mutation, Query} from "@apollo/react-components";
//redux
import {logout, setCurrentProperty, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Utils
import {styles} from './styles.js';
// import {Toast} from 'antd-mobile'
import {
    Color,
    Constants,
    getUserName,
    getUserType,
    IS_WEB,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
    UserType,
    validation
} from "components/src/utils";
import {LOGIN} from "../../api/mutation";
// import {Button, InputItem} from "@ant-design/react-native";
// import { DatePickerView } from 'antd-mobile';
import Cookie from 'js-cookie'
import {withFirebase} from "../../HOC/Firebase";
import {SELLER_PROPERTY_LIST} from "../../api/sellerQuery";

class SignIn extends React.Component {

    constructor(props) {
        super(props);

        if (IS_WEB) {
            this.userType = getUserType(props.location.pathname.toLowerCase().split('/')[1])
        } else {
            this.userType = UserType.Seller
        }
        this.state = {
            loading: false,
            isLoginSuccess: false,
            email: '',
            errEmail: null,

            password: '',
            errPassword: null
        }
    }

    componentDidMount() {
        if (!IS_WEB) {
            this.requestUserPermission();
        }


    }

    requestUserPermission = async () => {
        const authStatus = await messaging().requestPermission();
    }

    handleChangeText = (text, fieldId) => {
        switch (fieldId) {
            case Constants.FieldId.EMAIL:
                this.setState({
                    email: text.length === 1 ? text.toLowerCase() : text,
                    errEmail: null
                });
                break;
            case Constants.FieldId.PASSWORD:
                this.setState({
                    password: text,
                    errPassword: null
                });
                break;
        }
    };

    doSignIn = (doSignIn) => {
        const {email, password} = this.state;
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        this.setState({
            errEmail: validation('email', email.trim()),
            errPassword: validation('passwordBlank', password),
        }, () => {
            if (!this.state.errEmail && !this.state.errPassword) {
                this.setState({
                    loading: true
                })
                doSignIn({
                    variables: {
                        input: {
                            email: email.trim(),
                            password: password,
                            user_type_id: this.userType,
                        },
                    },
                })
            }
        })
    };

    renderHeaderTitle = () => {
        /*if (IS_WEB && false) {
            return <WebHeader/>
        }*/
        return (
            <View style={styles.headerTitle}>
                <LogoForHeader size={ThemeUtils.responsiveScale(120)}/>
                {/*<Label xxlarge
                       font_medium
                       ml={5}>
                    {Strings.web}
                    <Text style={styles.txtRegular}>
                        {Strings.megling}
                    </Text>
                </Label>*/}
            </View>
        )
    };

    render() {

        let isSeller = false;
        let isRedirectionUrl = false;
        if (IS_WEB && this.props.history.location?.state?.route) {
            isSeller = this.props.location?.pathname?.split('/')[1] === Routes.Seller;
            isRedirectionUrl = this.props.history.location?.state?.route?.split('/')[2] === Routes.Redirection;
        }
        return (
            <Provider locale={enUS}>
                <View style={styles.container}>
                    <ScrollView contentContainerStyle={{flexGrow: 1}}
                                keyboardShouldPersistTaps={'always'}>
                        {this.renderHeaderTitle()}
                        <View style={styles.topContainer}>

                            <Label xxlarge
                                   font_medium
                                   mb={20}
                                   mt={ThemeUtils.relativeRealHeight(2)}
                                   color={Color.WHITE}
                                   align={'center'}>
                                {Strings.signIn}
                            </Label>
                            <View style={styles.fieldContainer}>
                                <InputField color={Color.WHITE}
                                            labelProps={{
                                                color: Color.WHITE
                                            }}
                                            style={{
                                                borderColor: Color.PRIMARY_LIGHTER
                                            }}
                                            type={"email-address"}
                                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.EMAIL)}
                                            onFocus={() => this.setState({errEmail: null})}
                                            error={this.state.errEmail}
                                            value={this.state.email}
                                            labelText={Strings.email}
                                            placeholder={Strings.email}/>
                                <InputField color={Color.WHITE}
                                            labelProps={{
                                                color: Color.WHITE
                                            }}
                                            style={{
                                                borderColor: Color.PRIMARY_LIGHTER
                                            }}
                                            type="password"
                                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.PASSWORD)}
                                            onFocus={() => this.setState({errPassword: null})}
                                            error={this.state.errPassword}
                                            value={this.state.password}
                                            labelText={Strings.password}
                                            placeholder={Strings.password}/>
                            </View>
                            <View style={{alignItems: 'center'}}>

                                <TouchableOpacity activeOpacity={0.5}
                                                  onPress={() => {
                                                      if (IS_WEB) {
                                                          this.props.history.push(Routes.ForgotPassword)

                                                      } else {
                                                          this.props.navigation.push(Routes.ForgotPassword)
                                                      }
                                                  }}>
                                    <Label small
                                           color={Color.TERNARY_TEXT_COLOR}>
                                        {Strings.forgotYourPassword}
                                    </Label>
                                </TouchableOpacity>

                                <Query query={SELLER_PROPERTY_LIST}
                                       skip={!this.state.isLoginSuccess}>
                                    {({loading, data, error}) => {
                                        if (IS_WEB && this.props.history?.location?.state?.route &&
                                            this.state.isLoginSuccess &&
                                            data?.seller_property_list &&
                                            !loading) {
                                            let route = this.props.history.location?.state?.route
                                            if (IS_WEB) {
                                                localStorage.setItem('selectedProperty', JSON.stringify(data?.seller_property_list[0]))
                                            }
                                            this.props.setCurrentProperty(data?.seller_property_list[0])
                                            this.props.history.replace(route)
                                        }

                                        return (
                                            <View style={styles.btnCont}>
                                                {IS_WEB ? null : (
                                                    <RadioGroup options={[{
                                                        name: Strings.seller,
                                                        value: UserType.Seller
                                                    }, {
                                                        name: Strings.buyer,
                                                        value: UserType.Buyer
                                                    }]}
                                                                checkedColor={Color.SECONDARY}
                                                                unCheckedColor={Color.SECONDARY_LIGHT}
                                                                textColor={Color.WHITE}
                                                                contentStyle={{
                                                                    flexDirection: 'row',
                                                                    alignSelf: 'center',
                                                                    marginBottom: ThemeUtils.relativeRealHeight(4)
                                                                }}
                                                                selectedOptions={UserType.Seller}
                                                                onSelectOption={v => this.userType = v}/>)}
                                                <Mutation mutation={LOGIN}
                                                          onCompleted={(data) => {
                                                              if (data.login && data.login.firebase_token) {
                                                                  this.props.auth().signInWithCustomToken(data.login.firebase_token)
                                                                      .then(firebaseData => {
                                                                          let user = {...data.login.user, ...{userType: this.userType}};
                                                                          this.props.setUser(user);
                                                                          this.props.setToken(data.login.token);
                                                                          this.setState({
                                                                              loading: false,
                                                                          })
                                                                          if (IS_WEB) {
                                                                              Cookie.set('token', data.login.token.access_token);
                                                                              Cookie.set(`${getUserName(this.props.location.pathname.toLowerCase().split('/')[1])}_token`, data.login.token.access_token);
                                                                              const userData = {
                                                                                  user: data.login.user,
                                                                                  userType: getUserName(this.props.location.pathname.toLowerCase().split('/')[1]),
                                                                              };
                                                                              Cookie.set(`${getUserName(this.props.location.pathname.toLowerCase().split('/')[1])}_user`, userData);
                                                                              if (this.props.history.location?.state?.route) {
                                                                                  if (!isRedirectionUrl && isSeller) {
                                                                                      this.setState({
                                                                                          isLoginSuccess: true
                                                                                      })
                                                                                  } else {
                                                                                      this.props.history.replace(this.props.history.location?.state?.route)
                                                                                  }
                                                                              } else {
                                                                                  this.props.history.replace(this.userType === UserType.Seller ? Routes.SalesPreparation : Routes.Dashboard)
                                                                              }
                                                                          } else {
                                                                              let id = 'react-navigation'
                                                                              let {NavigationActions, StackActions} = require(`${id}`)
                                                                              this.props.messaging().subscribeToTopic(`W_CHAT_5`).then(() => {
                                                                                  console.log("SUB SCRIBEED")
                                                                              })
                                                                              this.props.navigation.dispatch(StackActions.reset({
                                                                                  index: 0,
                                                                                  actions: [
                                                                                      NavigationActions.navigate({routeName: Routes.Authenticated}),
                                                                                  ],
                                                                              }))
                                                                          }
                                                                      }).catch(error => {
                                                                      this.setState({
                                                                          loading: false
                                                                      })
                                                                      console.log("Error in firebase", error)
                                                                      showMessage("Midlertidig server feil. Vennligst prøv igjen.",Constants.MessageType.FAILED)
                                                                  })
                                                              }
                                                          }}
                                                          onError={(e) => {
                                                              this.setState({
                                                                  loading: false
                                                              })
                                                          }}>
                                                    {(doSignIn, {loading, error, data}) => (
                                                        <CustomButton font_medium
                                                                      onPress={() => this.doSignIn(doSignIn)}
                                                                      loading={this.state.loading || loading}
                                                                      title={Strings.signIn}/>
                                                    )}

                                                </Mutation>
                                            </View>
                                        )
                                    }}

                                </Query>

                            </View>
                        </View>
                        {/*{IS_WEB && false? <UserFooter/> : null}*/}
                    </ScrollView>

                </View>
            </Provider>)
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout()),
        setCurrentProperty: (property) => dispatch(setCurrentProperty(property)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(SignIn));
