import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {CustomButton, CustomTextArea, DatePicker, Label, RadioGroup} from "components/src/components";
//Utils
import {Constants, DateUtils, openInNewTab, Strings} from "components/src/utils";
import styles from "../styles";
import {setSellerInterViewForm} from "components/src/redux/action";
import {connect} from "react-redux";
import moment from "moment";


const Q1 = Strings.ninethStep.q1;
const Q2 = Strings.ninethStep.q2;
const Q3 = Strings.ninethStep.q3;

function NinethStep(props) {

    const {setForm, interViewForm} = props;

    const [extraOnListForSale, setExtraOnListForSale] = useState(interViewForm.find(item => item.interview_section === 9 && item.question_no === 30)?.answer ?? 'Ja');
    const [extraOnListComment, setExtraOnListComment] = useState(interViewForm.find(item => item.interview_section === 9 && item.question_no === 30)?.comment ?? '');

    const [extraInfo, setExtraInfo] = useState(interViewForm.find(item => item.interview_section === 9 && item.question_no === 31)?.answer ?? '');

    const [takeOverDate, setTakeOverDate] = useState(interViewForm.find(item => item.interview_section === 9 && item.question_no === 32)?.answer ?? '');
    const [takeOverPropertyComment, setTakeOverPropertyComment] = useState(interViewForm.find(item => item.interview_section === 9 && item.question_no === 32)?.comment ?? '');


    useEffect(() => {
        let answer = [
            {
                "question": Q1,
                "interview_section": 9,
                "question_no": 30,
                "type": "RADIO",
                "answer": extraOnListForSale,
                "extra": null,
                "comment": extraOnListComment
            },
            {
                "question": Q2,
                "interview_section": 9,
                "question_no": 31,
                "type": "TEXT",
                "answer": extraInfo,
                "extra": null,
                "comment": null
            },
            {
                "question": Q3,
                "interview_section": 9,
                "question_no": 32,
                "type": "DATEPICKER",
                "answer": !takeOverDate || typeof takeOverDate === 'string' ? takeOverDate : moment(takeOverDate).format(DateUtils.yyyy_mm_dd),
                "extra": null,
                "comment": takeOverPropertyComment
            }
        ]
        setForm([...interViewForm.filter(item => item.interview_section !== 9), ...answer])
    }, [
        extraInfo,
        takeOverDate,
        takeOverPropertyComment,
        extraOnListComment,
        extraOnListForSale
    ]);

    return (
        <View>
            <Label small
                   mt={10}
                   font_medium
                   onPress={() => openInNewTab("https://www.nef.no/losore-og-tilbehor/")}
                   mb={10}>
                {Q1}
            </Label>
            <RadioGroup options={Constants.RADIO_OPTIONS}
                        style={styles.radio}
                        contentStyle={styles.radioCont}
                        selectedOptions={extraOnListForSale}
                        onSelectOption={setExtraOnListForSale}/>

            {extraOnListForSale === 'Ja' ? (
                <CustomTextArea labelText={Strings.comment}
                                value={extraOnListComment}
                                onChange={setExtraOnListComment}
                                mt={10}/>
            ) : null}
            <CustomButton title={"Les NEF sin liste"}
                          mt={10}
                          mb={10}
                          onPress={() => openInNewTab("https://www.nef.no/losore-og-tilbehor/")}/>
            <CustomTextArea labelText={Q2}
                            value={extraInfo}
                            onChange={setExtraInfo}
                            mt={10}/>

            <DatePicker min={new Date(moment().add(1, 'd'))}
                        title={Q3}
                        selectedDate={takeOverDate}
                        onSelectDate={setTakeOverDate}
                        placeholder={Strings.selectDate}/>

            <CustomTextArea labelText={Strings.comment}
                            value={takeOverPropertyComment}
                            onChange={setTakeOverPropertyComment}
                            mt={10}/>


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForm: (form) => dispatch(setSellerInterViewForm(form))
    }

};
export default connect(mapStateToProps, mapDispatchToProps)(NinethStep)
