/* eslint-disable no-shadow */
import { Button, Col, Form, Input, Row, Typography, Modal, Select, notification } from 'antd';
import React, { useState } from 'react';
// import { router } from 'umi';
import { formatMessage } from 'components/src/utils';
// import './EditTeam.scss';
import { CaretDownFilled, LoadingOutlined } from '@ant-design/icons';
import { Mutation, Query } from 'react-apollo';
import { ADMIN_AGENT_TEAM } from '../../../../../../query/AdminQuery';
import {
  ADMIN_UPDATE_TEAM,
  ADMIN_DELETE_AGENT_TEAM,
} from '../../../../../../mutation/AdminMutation';
// import { from } from 'apollo-link';

const { Title } = Typography;
const { Option } = Select;
const finalListName = [];
let finalListId = [];

const onFinish = (values, editTeamData) => {
  console.log('Success:', values);
  editTeamData({
    variables: {
      input: {
        id: values.id,
        team_name: values.teamname,
        main_agent_id: values.mainagent,
        sub_agent_id: [values.subagent1, values.subagent2],
      },
    },
  });
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};
// const optionlist = [{ value: '' }];

const EditTeam = props => {
  const [deleteModal, setdeleteModal] = useState(false);
  const { orderItem } = props.location.state;

  const [subAgent, setSubAgent] = useState('');

  console.log('Props Location===>>>', orderItem);

  const onDelete = (deleteID, deletUser) => {
    // const deleteID =  orderData.filter(item =>item.id);
    console.log('delete id ==>', deleteID);
    deletUser({
      variables: {
        id: deleteID,
      },
    });
    props.history.push('/admin/fotografer');
  };

  return (
    <div className="contentshadow">
      <div className="text-primary main">
        <Row>
          <Col xs={24}>
            <Title level={3} className="text-primary">
              {formatMessage({
                id: 'component.team.editteam',
              })}
            </Title>
            <p>
              {formatMessage({
                id: 'component.team.editteam.description',
              })}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Mutation mutation={ADMIN_UPDATE_TEAM}>
              {(editTeamData, { loading, data }) => {
                if (data) {
                  console.log('Data submitted===>>>', data, loading);
                  if (data && data.update_team && data.update_team.meta) {
                    notification.success({
                      message: data.update_team.meta.message,
                      description: '',
                      duration: 3,
                    });
                    props.history.push(`/admin/team`);
                  }
                }
                return (
                  <Form
                    layout="vertical"
                    className="user-forms"
                    name="team"
                    initialValues={{
                      remember: true,
                      id: orderItem.id,
                      teamname: orderItem.title,
                      mainagent: `${orderItem.teamMembers[0].agentUser.id}`,
                      subagent1: `${orderItem.teamMembers[1].agentUser.id}`,
                      subagent2: `${orderItem.teamMembers[2].agentUser.id}`,
                    }}
                    colon={false}
                    onFinish={values => onFinish(values, editTeamData)}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Form.Item name="id">
                          <Input hidden />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={8}>
                        <Form.Item
                          name="teamname"
                          label={formatMessage({
                            id: 'component.team.teamtitle',
                          })}
                          rules={[
                            {
                              required: true,
                              message: `Vennligst skriv inn ${formatMessage({
                                id: 'component.team.teamtitle',
                              })}`,
                            },
                          ]}
                        >
                          <Input
                            placeholder={`Angi ${formatMessage({
                              id: 'component.team.teamtitle',
                            })}`}
                            size="large"
                          />
                          {/* <Upload action={profile} listType="picture-card" fileList={fileList} /> */}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Query
                      query={ADMIN_AGENT_TEAM}
                      variables={{
                        status: 1,
                        user_type_id: 7,
                        access_level: 2,
                        first: 10,
                      }}
                      onCompleted={responseData => {
                        if (responseData) {
                          console.log('response=>>>', responseData);
                          const arrayList = responseData.team_lists.data.map(i => i.teamMembers);
                          console.log('aarrlist', arrayList);
                          // eslint-disable-next-line array-callback-return
                          const listIdSet = new Set();
                          // eslint-disable-next-line array-callback-return
                          arrayList.map(element => {
                            element.forEach(i => {
                              // console.log('e', new Set(i.agentUser));
                              listIdSet.add(i.agentUser);

                              // finalListId=new Set(finalListId)
                              console.log('name', finalListName);
                            });
                          });
                          finalListId = Array.from(listIdSet);
                          console.log('Final List==>>', finalListId);
                          // console.log('final list', zipArray)
                          // zipArray.push(arrayList)
                          // finalset=(new Set(finalListId))
                          // console.log('final set==>>', finalset.map(i=>i))
                          setSubAgent(finalListId);
                        }

                        return '';
                      }}
                    >
                      {({ data }) => {
                        // console.log('user-list', data.team_lists);
                        // console.log('======>', finalListId)
                        if (data && data.team_lists.data) {
                          console.log('IN======>', Array.subAgent);
                          // console.log('sss=>',subAgent,"\nfinal:",finalList)
                          console.log('sss=>', subAgent);
                          return (
                            <div>
                              <Row gutter={16}>
                                <Col xs={24} lg={8}>
                                  <Form.Item
                                    name="mainagent"
                                    label={formatMessage({
                                      id: 'component.team.mainagent',
                                    })}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Vennligst velg hovedmegler',
                                      },
                                    ]}
                                  >
                                    <Select
                                      style={{ width: '100%' }}
                                      placeholder={`Select ${formatMessage({
                                        id: 'component.team.mainagent',
                                      })}`}
                                      size="large"
                                      suffixIcon={<CaretDownFilled className="caret-style" />}
                                      className="pickerwidth"
                                    >
                                      {finalListId &&
                                        finalListId.map(item => {
                                          console.log('dropdownItem===>>>', item);
                                          return (
                                            <Option key={item.id}>
                                              <span>
                                                {item.first_name} {item.last_name}
                                              </span>
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col xs={24} lg={8}>
                                  <Form.Item
                                    name="subagent1"
                                    label={formatMessage({
                                      id: 'component.team.subagent1',
                                    })}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Vennligst velg medhjelper 1',
                                      },
                                    ]}
                                  >
                                    <Select
                                      style={{ width: '100%' }}
                                      placeholder={`Select ${formatMessage({
                                        id: 'component.team.subagent1',
                                      })}`}
                                      size="large"
                                      suffixIcon={<CaretDownFilled className="caret-style" />}
                                      className="pickerwidth"
                                      // options={finalListName.forEach(i=>(i))}
                                    >
                                      {finalListId &&
                                        finalListId.map(item => {
                                          console.log('dropdownItem===>>>', item);
                                          return (
                                            <Option key={item.id}>
                                              <span>
                                                {item.first_name} {item.last_name}
                                              </span>
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} lg={8}>
                                  <Form.Item
                                    name="subagent2"
                                    label={formatMessage({
                                      id: 'component.team.subagent2',
                                    })}
                                    rules={[
                                      {
                                        required: true,
                                        message: `Vennligst velg ${formatMessage({
                                          id: 'component.team.subagent2',
                                        })}`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      style={{ width: '100%' }}
                                      placeholder={`Select ${formatMessage({
                                        id: 'component.team.subagent2',
                                      })}`}
                                      size="large"
                                      suffixIcon={<CaretDownFilled className="caret-style" />}
                                      className="pickerwidth"
                                      // options={finalListId.map(i => i.first_name)}
                                    >
                                      {finalListId &&
                                        finalListId.map(item => {
                                          console.log('dropdownItem===>>>', item);
                                          return (
                                            <Option key={item.id}>
                                              <span>
                                                {item.first_name} {item.last_name}
                                              </span>
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                        return (
                          <div className="loader-wrapper">
                            <LoadingOutlined />
                          </div>
                        );
                      }}
                    </Query>
                    <Row className="mt-3">
                      <Col xs={24}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-secondary buttons admin-forms-btn"
                          size="large"
                        >
                          {formatMessage({
                            id: 'component.form.add',
                          })}
                        </Button>
                        <Button
                          className="text-primary admin-forms-btn"
                          style={{ marginLeft: 8 }}
                          size="large"
                          onClick={props.history.goBack}
                        >
                          {formatMessage({
                            id: 'component.form.cancel',
                          })}
                        </Button>
                        <Button
                          className="notice admin-forms-btn delete-btn"
                          style={{ float: 'right' }}
                          size="large"
                          onClick={() => setdeleteModal(true)}
                        >
                          {formatMessage({
                            id: 'component.form.delete',
                          })}
                        </Button>
                      </Col>
                    </Row>
                    <Modal
                      centered
                      title={formatMessage({
                        id: 'component.team.deletemodal.title',
                      })}
                      visible={deleteModal}
                      onOk={() => setdeleteModal(false)}
                      onCancel={() => setdeleteModal(false)}
                      width="450px"
                      footer={[
                        <Mutation mutation={ADMIN_DELETE_AGENT_TEAM}>
                          {deletUser => {
                            if (data) {
                              console.log('Data submitted===>>>', data, loading);
                              if (data && data.delete_agent_team && data.delete_agent_team.meta) {
                                notification.success({
                                  message: data.delete_agent_team.meta.message,
                                  description: '',
                                  duration: 3,
                                });
                              }
                            }
                            return (
                              <Button
                                key="submit"
                                type="primary"
                                className="admin-forms-btn  mr_15"
                                size="large"
                                onClick={() => onDelete(orderItem.id, deletUser)}
                              >
                                {formatMessage({
                                  id: 'component.form.yes',
                                })}
                              </Button>
                            );
                          }}
                        </Mutation>,
                        <Button
                          className="admin-forms-btn  mr_15"
                          size="large"
                          onClick={() => setdeleteModal(false)}
                        >
                          {formatMessage({
                            id: 'component.form.no',
                          })}
                        </Button>,
                      ]}
                    >
                      <p className="text-primary no-margin">
                        {formatMessage({
                          id: 'component.team.deletemodal.text',
                        })}
                      </p>
                    </Modal>
                  </Form>
                );
              }}
            </Mutation>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default EditTeam;
